import { ContentWrapper } from 'common/components/General';
import { media } from 'styleguide/helpers/media';
import styled from 'styled-components';

export const TitleWrapper = styled.div`
	width: 100%;
	height: 100%;
	max-width: 1200px;
	margin: 0 auto;
	position: relative;
`;

export const UserPageTitle = styled.h1`
	${props => props.theme.typography.heading};
	font-size: 40px;
	text-transform: uppercase;
	color: ${props => props.theme.colors.white};
	margin: 0;
	max-width: 640px;
	position: absolute;
	left: 50%;
	bottom: 80px;
	transform: translateX(-50%);

	${media.tablet`
		font-size: 60px;
	`};
`;

export const TitleBlockWrapper = styled(ContentWrapper)`
	margin-bottom: 30px;

	@media print {
		display: none;
	}
`;

export const UserPageContentsWrapper = styled(ContentWrapper)`
	margin-bottom: 60px;
`;
