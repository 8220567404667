import * as React from 'react';
import OrderFooter from './OrderFooter';
import { useForm } from 'react-hook-form/dist/react-hook-form.ie11';
import { useIntl, FormattedMessage } from 'react-intl';
import { OrderStatus, DeliveryFormShape, OrderDelivery } from '../interfaces';
import styled from 'styled-components';
import MediumHeader from 'styleguide/components/Typography/MediumHeader';
import Input from 'styleguide/components/Inputs/Input';
import { media } from 'styleguide/helpers';
import { InputSize } from 'styleguide/types';
import Checkbox from 'styleguide/components/Inputs/Checkbox';
import Alert, { AlertType } from 'styleguide/components/Typography/Alert';
import { ValidationPatterns } from 'common/components/Forms/helpers';
import { ORDER_URL_HASH_BY_STATUS } from '../constants';

const Form = styled.form`
	width: 100%;

	.medium-header {
		margin: 0;
		text-align: left;
	}

	.form-error {
		margin-top: ${({ theme }) => theme.grid.gutterInPx(3)};
	}
`;

const FieldsContainer = styled.div`
	margin-top: ${({ theme }) => theme.grid.gutterInPx(3)};

	${media.tablet`
		display: flex;
		flex-wrap: wrap;
	`};

	.form-field {
		margin-top: ${({ theme }) => theme.grid.gutterInPx(2)};

		${media.tablet`
			flex: 1 0 auto;
			flex: 1 0 calc(50% - ${({ theme }) => theme.grid.gutterInPx(2)});
			width: calc(50% - ${({ theme }) => theme.grid.gutterInPx(2)});

			&:nth-child(even) {
				margin-left: ${({ theme }) => theme.grid.gutterInPx(2)};
			}
		`};
	}
`;

const TermsContainer = styled.div`
	margin-top: ${({ theme }) => theme.grid.gutterInPx(3)};
	background: ${({ theme }) => theme.colors.contentSectionHiglighted};
	padding: ${({ theme }) => theme.grid.gutterInPx(2)};
	border-radius: 4px;

	${media.tablet`
		padding: ${({ theme }) => theme.grid.gutterInPx(3)};
	`};

	.checkbox-label {
		font-size: 14px;
		line-height: 18px;

		${media.tablet`
			font-size: 16px;
			line-height: 22px;
		`};

		a {
			color: ${({ theme }) => theme.colors.linkText};
			text-decoration: none;

			&:hover {
				color: ${({ theme }) => theme.colors.linkTextHover};
			}
		}
	}
`;

interface Props {
	delivery: OrderDelivery;
	orderCart: (formData: DeliveryFormShape) => void;
	onUpdateDeliveryDetails: (deliveryData: DeliveryFormShape) => void;
	onSetOrderStatus: (status: OrderStatus) => void;
	onReplaceHistoryState: (hash?: string) => void;
}

const OrderDelivery: React.FC<Props> = ({
	delivery: { data, isLoading, error },
	orderCart,
	onUpdateDeliveryDetails,
	onSetOrderStatus,
	onReplaceHistoryState,
}) => {
	const intl = useIntl();

	const { firstName, lastName, email, phoneNumber, streetAddress, zipCode, city, companyName, termsAccepted } =
		data ?? {};

	React.useEffect(() => {
		if (error) {
			onReplaceHistoryState(ORDER_URL_HASH_BY_STATUS.DELIVERY);
		}
	}, [error]);

	const { register, errors, handleSubmit, getValues } = useForm<DeliveryFormShape>({
		defaultValues: {
			firstName,
			lastName,
			email,
			phoneNumber,
			streetAddress,
			zipCode,
			city,
			companyName,
			termsAccepted,
		},
	});

	const onSubmit = (fieldValues: DeliveryFormShape) => {
		onUpdateDeliveryDetails(fieldValues);
		orderCart(fieldValues);
		onReplaceHistoryState();
	};

	const onBackClick = () => {
		onUpdateDeliveryDetails(getValues());
		onSetOrderStatus('CART');
	};

	return (
		<Form onSubmit={handleSubmit(onSubmit)}>
			<MediumHeader className="medium-header">
				<FormattedMessage id="materials_search_delivery_title" />
			</MediumHeader>
			<FieldsContainer>
				<Input
					type="text"
					name="firstName"
					label={`${intl.formatMessage({ id: 'materials_search_delivery_first_name_label' })}*`}
					validationError={errors.firstName?.message}
					inputSize={InputSize.medium}
					ref={register({
						required: intl.formatMessage({ id: 'materials_search_delivery_first_name_required' }),
						maxLength: {
							value: 50,
							message: intl.formatMessage({ id: 'forms_validation_max_length' }, { maxLength: 50 }),
						},
					})}
					inlineLabel
				/>
				<Input
					type="text"
					name="lastName"
					label={`${intl.formatMessage({ id: 'materials_search_delivery_last_name_label' })}*`}
					validationError={errors.lastName?.message}
					inputSize={InputSize.medium}
					ref={register({
						required: intl.formatMessage({ id: 'materials_search_delivery_last_name_required' }),
						maxLength: {
							value: 50,
							message: intl.formatMessage({ id: 'forms_validation_max_length' }, { maxLength: 50 }),
						},
					})}
					inlineLabel
				/>
				<Input
					type="email"
					name="email"
					label={`${intl.formatMessage({ id: 'materials_search_delivery_email_label' })}*`}
					validationError={errors.email?.message}
					inputSize={InputSize.medium}
					ref={register({
						required: intl.formatMessage({ id: 'materials_search_delivery_email_required' }),
						maxLength: {
							value: 50,
							message: intl.formatMessage({ id: 'forms_validation_max_length' }, { maxLength: 50 }),
						},
						pattern: {
							value: new RegExp(ValidationPatterns.EMAIL),
							message: intl.formatMessage({ id: 'forms_validation_email_invalid' }),
						},
					})}
					inlineLabel
				/>
				<Input
					type="text"
					name="phoneNumber"
					label={`${intl.formatMessage({ id: 'materials_search_delivery_phone_label' })}*`}
					validationError={errors.phoneNumber?.message}
					inputSize={InputSize.medium}
					ref={register({
						required: intl.formatMessage({ id: 'materials_search_delivery_phone_required' }),
						maxLength: {
							value: 30,
							message: intl.formatMessage({ id: 'forms_validation_max_length' }, { maxLength: 30 }),
						},
					})}
					inlineLabel
				/>
				<Input
					type="text"
					name="streetAddress"
					label={`${intl.formatMessage({ id: 'materials_search_delivery_address_label' })}*`}
					validationError={errors.streetAddress?.message}
					inputSize={InputSize.medium}
					ref={register({
						required: intl.formatMessage({ id: 'materials_search_delivery_address_required' }),
						maxLength: {
							value: 500,
							message: intl.formatMessage({ id: 'forms_validation_max_length' }, { maxLength: 500 }),
						},
					})}
					inlineLabel
				/>
				<Input
					type="text"
					name="zipCode"
					label={`${intl.formatMessage({ id: 'materials_search_delivery_post_code_label' })}*`}
					validationError={errors.zipCode?.message}
					inputSize={InputSize.medium}
					ref={register({
						required: intl.formatMessage({ id: 'materials_search_delivery_post_code_required' }),
						maxLength: {
							value: 5,
							message: intl.formatMessage({ id: 'forms_validation_max_length' }, { maxLength: 5 }),
						},
						minLength: {
							value: 5,
							message: intl.formatMessage({ id: 'forms_validation_min_length' }, { minLength: 5 }),
						},
					})}
					inlineLabel
				/>
				<Input
					type="text"
					name="city"
					label={`${intl.formatMessage({ id: 'materials_search_delivery_city_label' })}*`}
					validationError={errors.city?.message}
					inputSize={InputSize.medium}
					ref={register({
						required: intl.formatMessage({ id: 'materials_search_delivery_city_required' }),
						maxLength: {
							value: 50,
							message: intl.formatMessage({ id: 'forms_validation_max_length' }, { maxLength: 50 }),
						},
					})}
					inlineLabel
				/>
				<Input
					type="text"
					name="companyName"
					label={`${intl.formatMessage({ id: 'materials_search_delivery_company_label' })}*`}
					validationError={errors.companyName?.message}
					inputSize={InputSize.medium}
					ref={register({
						required: intl.formatMessage({ id: 'materials_search_delivery_company_required' }),
						maxLength: {
							value: 256,
							message: intl.formatMessage({ id: 'forms_validation_max_length' }, { maxLength: 256 }),
						},
					})}
					inlineLabel
				/>
			</FieldsContainer>
			<TermsContainer>
				<Checkbox
					name="termsAccepted"
					isInvalid={!!errors.termsAccepted?.message?.length}
					validationError={errors.termsAccepted?.message}
					ref={register({
						validate: value => value === true || intl.formatMessage({ id: 'materials_search_delivery_terms_required' }),
					})}>
					<FormattedMessage
						id="materials_search_delivery_terms_label"
						values={{
							a: (msg: string) => (
								<a
									href="/ammattilaiset/materiaalipankin-tilaus--ja-toimitusehdot/"
									target="_blank"
									rel="noopener noreferrer">
									{msg}
								</a>
							),
						}}
					/>
				</Checkbox>
			</TermsContainer>
			{error && (
				<Alert
					type={AlertType.Error}
					highlightedText={intl.formatMessage({ id: 'materials_search_delivery_error_title' })}
					className="form-error">
					{intl.formatMessage({ id: error })}
				</Alert>
			)}
			<OrderFooter
				backButtonLabel={intl.formatMessage({ id: 'materials_search_order_back_to_cart' })}
				continueDisabled={isLoading}
				continueLabel={intl.formatMessage({ id: 'materials_search_order_send' })}
				onBackClick={onBackClick}
				showContinue
			/>
		</Form>
	);
};

export default OrderDelivery;
