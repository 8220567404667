import * as React from 'react';
import styled from 'styled-components';
import { CloseIcon } from '../../Icons';

const AlertWrapper = styled.div`
	display: block;
	width: 100%;
	border-radius: 4px;
	font-family: ${props => props.theme.fonts.secondary};
	font-size: 18px;
	position: relative;
	height: auto;
	max-height: 300px;
	transition: all ease-out 250ms;
	overflow: hidden;
`;

const TextWrapper = styled.div`
	padding: 20px 45px 20px 30px;

	> .highlighted {
		font-weight: 900;
	}

	&.success {
		background-color: ${props => props.theme.colors.actionSuccessBackground};

		> .highlighted {
			color: ${props => props.theme.colors.actionSuccessText};
		}
	}

	&.info {
		background-color: ${props => props.theme.colors.actionInfoBackground};
		> .highlighted {
			color: ${props => props.theme.colors.actionInfoText};
		}
	}

	&.error {
		background-color: ${props => props.theme.colors.actionErrorBackground};
		> .highlighted {
			color: ${props => props.theme.colors.actionErrorText};
		}
	}
`;

const HighlightedWrapper = styled.span`
	margin-right: 8px;
`;

const DismissWrapper = styled.div`
	position: absolute;
	top: 12px;
	right: 12px;
	cursor: pointer;
`;

export enum AlertType {
	Error = 'error',
	Info = 'info',
	Success = 'success',
}

export interface AlertProps {
	type: AlertType;
	highlightedText?: string;
	dismissable?: boolean;
}

const onDismissClick = (e: React.MouseEvent<HTMLDivElement>) => {
	const { parentElement } = e.currentTarget;
	if (parentElement) {
		parentElement.style.maxHeight = '0';
	}
};

const Alert: React.FC<AlertProps & React.HTMLAttributes<HTMLElement>> = ({
	type,
	children,
	className,
	highlightedText,
	dismissable,
	...rest
}) => (
	<AlertWrapper className={className} {...rest}>
		<TextWrapper className={type}>
			{highlightedText && <HighlightedWrapper className="highlighted">{highlightedText}</HighlightedWrapper>}
			{children}
		</TextWrapper>
		{dismissable && (
			<DismissWrapper onClick={onDismissClick}>
				<CloseIcon />
			</DismissWrapper>
		)}
	</AlertWrapper>
);

export default Alert;
