import { fetchJson } from 'utils/service';
import { UserApiShape } from '../interfaces';

const fetchCurrentUser = async () => {
	return await fetchJson<UserApiShape>('/api/v2/session');
};

const userApi = {
	fetchCurrentUser,
};

type UserApi = typeof userApi;

export { userApi, UserApi };
