export const SEARCH_ALL_RECIPES_PAGE_SIZE = 3;
export const SEARCH_ALL_PRODUCTS_PAGE_SIZE = 3;
export const SEARCH_ALL_ARTICLES_PAGE_SIZE = 3;
export const SEARCH_RECIPES_PAGE_SIZE = 24;
export const SEARCH_PRODUCTS_PAGE_SIZE = 24;
export const SEARCH_ARTICLES_PAGE_SIZE = 12;
export const SEARCH_COMBINED_PAGE_SIZE = 12;

export const SEARCH_RESULTS_GRID_MARGIN = 6;

export const RECIPE_FILTER_NAME_QUICK = 'valmistusaikaMax';
export const RECIPE_FILTER_VALUE_QUICK = '40';

export const PRODUCT_FILTER_NAME_NEW = 'uutuudet';
export const PRODUCT_FILTER_VALUE_NEW = 'nayta';

export const AUTHOR_FILTER_MAPPINGS = {
	valio_koekeittio: 'search_filter_author_valio_kitchen',
	vieraskauha: 'search_filter_author_cooperation_short',
	kayttajat: 'search_filter_author_user_recipe_short',
};

export const COOKINGPREFERENCE_FILTER_MAPPINGS = {
	'1': 'search_filter_preference_easy',
	'40': 'search_recipe_type_quick',
	'2,3': 'search_filter_preference_hard',
};

export const CONTEXT_FILTER_MAPPINGS = {
	foodservice: 'global_food_service',
	teollisuus: 'global_bakery_and_industry',
};

export const SEARCH_SORT_VALUE_BEST_MATCH = 'olennaisimmat';
export const SEARCH_SORT_VALUE_NEWEST = 'julkaisu';
export const SEARCH_SORT_VALUE_FAVOURITE = 'suosituimmat';
export const SEARCH_SORT_VALUE_ALPHABETICAL = 'aakkosjarjestys';

export const SEARCH_API_URL_SORT_PARAMETER = 'jarjestys';
export const SEARCH_API_URL_SEARCH_QUERY = 'haku';
export const SEARCH_API_URL_SEARCH_SUBJECT = 'aihe';
export const SEARCH_HASH_PAGE_PARAMETER = 'sivu';
export const SEARCH_HASH_SCROLL_PARAMETER = 'scroll';
