import { call, put } from 'redux-saga/effects';
import { takeLatest } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { staticHighlightActions } from '../reducers/static-highlights';
import { staticHighlightApi, StaticHighlightApi } from '../services/static-highlights';

function* fetchStaticHiglights(
	api: StaticHighlightApi,
	{ payload: { siteUrlId, resourceUrl } }: ReturnType<typeof staticHighlightActions.fetchStaticHighlights>
) {
	const highlightsResult = yield call(api.getStaticHighlights, siteUrlId, resourceUrl);
	yield put(staticHighlightActions.setStaticHighlights(highlightsResult));
}

export function* staticHighlightsSaga() {
	yield takeLatest(getType(staticHighlightActions.fetchStaticHighlights), fetchStaticHiglights, staticHighlightApi);
}
