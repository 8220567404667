import * as React from 'react';
import styled from 'styled-components';
import FoodSolutionsIcon from '../FoodSolutionsIcon';

const PaddedIcon = styled.span`
	display: inline-block;
	> img {
		margin: 35px;
		max-width: 45px;
	}
`;

const PaddedFoodSolutionsIcon: React.FC = () => {
	return (
		<PaddedIcon>
			<FoodSolutionsIcon />
		</PaddedIcon>
	);
};

export default PaddedFoodSolutionsIcon;
