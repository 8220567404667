import { Reducer } from 'redux';
import { getType } from 'typesafe-actions';
import { appActions, AppActions } from '../../App/reducers';
import { globalSearchActions, GlobalSearchActions } from './actions';
import { RecipeSearchResult } from '../services/recipe-search';
import { ProductSearchResult } from '../services/product-search';
import { ArticleSearchResult } from '../services/article-search';
import { NavLinkItemProps } from '../../../components/Navigation/interfaces';

export interface GlobalSearchState {
	isSearching: boolean;
	recipeResult?: RecipeSearchResult;
	professionalRecipeResult?: RecipeSearchResult;
	productResult?: ProductSearchResult;
	articleResult?: ArticleSearchResult;
	searchSuggestions: NavLinkItemProps[];
}

export const initialGlobalSearchState: GlobalSearchState = {
	isSearching: false,
	recipeResult: {
		count: 0,
		items: [],
	},
	searchSuggestions: [],
};

export const globalSearchReducer: Reducer<GlobalSearchState, GlobalSearchActions | AppActions> = (
	state = initialGlobalSearchState,
	action
) => {
	switch (action.type) {
		case getType(appActions.setSettings):
			return { ...state, searchSuggestions: action.payload.searchSuggestions };

		case getType(globalSearchActions.search):
			return { ...state, isSearching: true };

		case getType(globalSearchActions.searchRecipeResult):
			return { ...state, recipeResult: action.payload.searchResult };

		case getType(globalSearchActions.searchProfessionalRecipeResult):
			return { ...state, professionalRecipeResult: action.payload.searchResult };

		case getType(globalSearchActions.searchProductResult):
			return { ...state, productResult: action.payload.searchResult };

		case getType(globalSearchActions.searchArticleResult):
			return { ...state, articleResult: action.payload.searchResult };

		case getType(globalSearchActions.searchSucceeded):
			return { ...state, isSearching: false };

		default:
			return state;
	}
};
