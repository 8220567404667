import { RecipeSearchApiResult, ProductSearchApiResult, ArticleSearchApiResult, ProductInfo } from './interfaces';
import { fetchJson, throwMissingSiteUrlIdError } from 'utils/service';
import { SEARCH_SORT_VALUE_NEWEST, SEARCH_SORT_VALUE_BEST_MATCH } from './constants';
import { parseQuery } from 'utils/query-string';

const searchRecipes = async (
	siteUrlId: string,
	professional: boolean,
	query: string,
	pageSize: number,
	pageNumber: number = 1
): Promise<RecipeSearchApiResult> => {
	if (!siteUrlId) {
		throwMissingSiteUrlIdError();
	}
	const pro = `ammattilaiset=${professional ? 1 : 0}`;
	const queryParts = parseQuery(query);
	if (!queryParts.jarjestys) {
		query += `&jarjestys=${professional ? SEARCH_SORT_VALUE_NEWEST : SEARCH_SORT_VALUE_BEST_MATCH}`;
	}
	return await fetchJson<RecipeSearchApiResult>(
		`/api/sites/${siteUrlId}/search/recipe/?${pro}${query ? `&${query}` : ''}&sivunkoko=${pageSize}&sivu=${pageNumber}`
	);
};

const searchProducts = async (
	siteUrlId: string,
	query: string,
	pageSize: number,
	pageNumber: number,
	onlyItems: boolean
): Promise<ProductSearchApiResult> => {
	if (!siteUrlId) {
		throwMissingSiteUrlIdError();
	}
	const urlPrefix = `/api/sites/${siteUrlId}/search/product/`;
	return await fetchJson<ProductSearchApiResult>(
		`${urlPrefix}?${query ? `${query}&` : ''}sivunkoko=${pageSize}&sivu=${pageNumber}&VainHaku=${onlyItems ? 1 : 0}`
	);
};

const searchArticles = async (
	siteUrlId: string,
	resourceId: string,
	query: string,
	pageSize?: number,
	pageNumber: number = 1
): Promise<ArticleSearchApiResult> => {
	if (!siteUrlId) {
		throwMissingSiteUrlIdError();
	}
	return await fetchJson<ArticleSearchApiResult>(
		`/api/sites/${siteUrlId}/articlesearch/Search?resourceId=${resourceId}${
			query ? `&${query}` : ''
		}&sivunkoko=${pageSize}&sivu=${pageNumber}`
	);
};

const getProductInfoBySlug = async (slug: string): Promise<ProductInfo> => {
	return await fetchJson<ProductInfo>(`/api/product-info-by-slug/${encodeURIComponent(slug)}`);
};

export const searchApi = {
	searchRecipes,
	searchProducts,
	searchArticles,
	getProductInfoBySlug,
};

export type SearchApi = typeof searchApi;
