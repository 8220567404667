import { call, put, fork, takeLatest } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { SettingsApi, settingsApi, getSiteUrlId, AppSettingsAM } from './services';
import { appActions } from './reducers';
import { navigationActions } from '../Navigation/reducers';

export function* fetchSettingsAndNavigation(
	api: SettingsApi,
	{ payload }: ReturnType<typeof appActions.fetchSettingsAndNavigation>
) {
	try {
		const settingsResult: AppSettingsAM = yield call(api.getSettings);

		yield put(appActions.setSettings(settingsResult));
		const siteUrlId = getSiteUrlId(payload, settingsResult.sites);
		yield put(navigationActions.fetchNavigation({ siteUrlId: siteUrlId || '', routing: payload }));
	} catch (e) {
		yield put(appActions.fetchSettingsFailed());
	}
}

export function* watchFetchSettingsSaga() {
	yield takeLatest(getType(appActions.fetchSettingsAndNavigation), fetchSettingsAndNavigation, settingsApi);
}

export default function* appSaga() {
	yield fork(watchFetchSettingsSaga);
}
