import * as React from 'react';
import loadable from '@loadable/component';

const BrandHighlight = loadable(() => import(/* webpackChunkName: "widget-product" */ './BrandHighlight'));
const ProductHighlight = loadable(() => import(/* webpackChunkName: "widget" */ './ProductHighlight'));
const StaticHighlight = loadable(() => import(/* webpackChunkName: "widget" */ './StaticContentHighlight'));
const ProductGroupHighlight = loadable(() =>
	import(/* webpackChunkName: "widget-product" */ './ProductGroupHighlight')
);
const RecipeHighlight = loadable(() => import(/* webpackChunkName: "widget" */ './RecipeHighlight'));
const HighlightedLink = loadable(() => import(/* webpackChunkName: "widget" */ './HighlightedLink'));
const Divider = loadable(() => import(/* webpackChunkName: "widget" */ './Divider'));
const RecipeAspectGroupHighlight = loadable(() =>
	import(/* webpackChunkName: "widget" */ './RecipeAspectGroupHighlight')
);
const Quotation = loadable(() => import(/* webpackChunkName: "widget" */ './Quotation'));
const EmbeddedVideo = loadable(() => import(/* webpackChunkName: "widget" */ './EmbeddedVideo'));
const ImagesInContent = loadable(() => import(/* webpackChunkName: "widget" */ './ImagesInContent'));
const Table = loadable(() => import(/* webpackChunkName: "markdown" */ './Table'));
const Text = loadable(() => import(/* webpackChunkName: "markdown" */ './Text'));
const Tip = loadable(() => import(/* webpackChunkName: "markdown" */ './Tip'));
const FramedImageBanner = loadable(() => import(/* webpackChunkName: "widget" */ './FramedImageBanner'));
const CrossLinkBanners = loadable(() => import(/* webpackChunkName: "widget" */ './CrossLinkBanners'));
const LargeBannerWithImage = loadable(() => import(/* webpackChunkName: "widget" */ './LargeBannerWithImage'));
const ArticleHighlight = loadable(() => import(/* webpackChunkName: "widget" */ './ArticleHighlight'));
const Link = loadable(() => import(/* webpackChunkName: "widget" */ 'common/components/Widgets/Link'));
const EmbeddedArticle = loadable(() =>
	import(/* webpackChunkName: "widget" */ 'common/components/Widgets/EmbeddedArticle')
);
const VideoList = loadable(() => import(/* webpackChunkName: "widget" */ 'common/components/Widgets/VideoList'));
const ChessSquares = loadable(() => import(/* webpackChunkName: "widget" */ 'common/components/Widgets/ChessSquares'));
const LinkGroup = loadable(() => import(/* webpackChunkName: "widget" */ 'common/components/Widgets/LinkGroup'));
const Applet = loadable(() => import(/* webpackChunkName: "applet" */ 'common/components/Widgets/Applet'));
const Wayfinder = loadable(() => import(/* webpackChunkName: "widget" */ 'common/components/Widgets/Wayfinder'));
const WellnessCalculatorBanners = loadable(() =>
	import(/* webpackChunkName: "applet" */ 'common/components/Widgets/WellnessCalculatorBanners')
);
const MainTopicHighlight = loadable(() =>
	import(/* webpackChunkName: "widget-home" */ 'common/components/Widgets/MainTopicHighlight')
);
const ArticleText = loadable(() => import(/* webpackChunkName: "markdown" */ './ArticleText'));
const ResourceList = loadable(() =>
	import(/* webpackChunkName: "widget-home" */ 'common/components/Widgets/ResourceList')
);
const ComNewsList = loadable(() =>
	import(/* webpackChunkName: "widget-home" */ 'common/components/Widgets/ResourceList')
);
const InfoColumns = loadable(() => import(/* webpackChunkName: "markdown" */ 'common/components/Widgets/InfoColumns'));
const ResourceHighlight = loadable(() => import(/* webpackChunkName: "widget" */ './ResourceHighlight'));
const HeroBanner = loadable(() => import(/* webpackChunkName: "widget-home" */ './HeroBanner'));
const Popup = loadable(() => import(/* webpackChunkName: "widget-misc" */ './Popup'));
const Flockler = loadable(() => import(/* webpackChunkName: "widget-misc" */ './Flockler'));
const OpenPositions = loadable(() => import(/* webpackChunkName: "widget-misc" */ './OpenPositions'));
const ContactPersons = loadable(() => import(/* webpackChunkName: "widget-misc" */ './ContactPersons'));
const TextOnImageColumns = loadable(() => import(/* webpackChunkName: "widget-misc" */ './TextOnImageColumns'));
const CountrySelector = loadable(() => import(/* webpackChunkName: "widget-misc" */ './CountrySelector'));
const NewsletterOrder = loadable(() => import(/* webpackChunkName: "widget-misc" */ './NewsletterOrder'));
const Infobox = loadable(() => import(/* webpackChunkName: "widget-misc" */ './Infobox'));
const Form = loadable(() => import(/* webpackChunkName: "widget" */ 'common/components/Widgets/Form'));
const GenericHighlight = loadable(() =>
	import(/* webpackChunkName: "widget" */ 'common/components/Widgets/GenericHighlight')
);

const ShareContent = loadable(() => import(/* webpackChunkName: "widget" */ 'common/components/Widgets/ShareContent'));

export const knownWidgetTypes: { [type: string]: React.ComponentType<any> } = {
	BrandHighlight,
	ProductHighlight,
	StaticHighlight,
	ProductGroupHighlight,
	RecipeHighlight,
	HighlightedLink,
	Divider,
	RecipeAspectGroupHighlight,
	Quotation,
	EmbeddedVideo,
	ImagesInContent,
	Text,
	Tip,
	FramedImageBanner,
	CrossLinkBanners,
	LargeBannerWithImage,
	ArticleHighlight,
	Link,
	EmbeddedArticle,
	VideoList,
	ChessSquares,
	LinkGroup,
	Applet,
	Table,
	Wayfinder,
	WellnessCalculatorBanners,
	MainTopicHighlight,
	ArticleText,
	ResourceList,
	ComNewsList,
	InfoColumns,
	ResourceHighlight,
	HeroBanner,
	Popup,
	Flockler,
	OpenPositions,
	ContactPersons,
	TextOnImageColumns,
	CountrySelector,
	NewsletterOrder,
	Infobox,
	Form,
	GenericHighlight,
	ShareContent,
};
