import * as React from 'react';
import HorizontalRecipeCard from 'styleguide/components/Cards/HorizontalRecipeCard/HorizontalRecipeCard';
import { RecipeCreatorType } from 'styleguide/types/recipeTypes';
import styled from 'styled-components';
import { media } from 'styleguide/helpers/media';
import CloseIcon from 'styleguide/components/Icons/NormalIcons/CloseIcon/CloseIcon';
import IconButton from 'styleguide/components/Buttons/IconButton/IconButton';
import RecipeIndicator from './ShoppingListRecipeIndicator';
import { ShoppingListApiRecipeItem } from '../services/shopping-list';
import injectRoutable, { InjectedRoutableProps } from 'common/components/Routing/Routable';
import { ShoppingListColours } from '../types';
import PortionCalculator from 'common/components/Inputs/PortionCalculator';

const ShoppingListRecipeWrapper = styled.div`
	border-top: 1px solid ${props => props.theme.colors.border};
	padding: 10px 0;
	width: 100%;
	position: relative;

	${media.tablet`
		padding: 10px 20px 10px 10px;
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
	`};

	@media print {
		display: block;
		padding: 3mm 0;
		width: 50%;
	}

	.recipe-indicator {
		@media print {
			left: 0;
			top: 19mm;
		}
	}

	.recipe-card {
		margin-bottom: 20px;

		${media.tablet`
			margin: 0 20px 0 0;
			flex: 0 1 350px;
		`};

		@media print {
			height: 20mm;
			margin-bottom: 0;
		}

		img {
			@media print {
				width: 30mm;
				height: 20mm;
			}
		}
	}

	.shopping-list-portion-calculator {
		${media.tablet`
			flex: 1 1 170px;
		`};
	}

	.close-icon {
		position: absolute;
		right: 10px;
		bottom: 10px;

		svg {
			width: 20px;
		}

		${media.tablet`
			position: relative;
			right: auto;
			bottom: auto;
			margin-left: 20px;
		`};

		@media print {
			display: none;
		}
	}
`;

const PrintPortions = styled.div`
	display: none;

	@media print {
		display: block;
		font-family: ${props => props.theme.fonts.secondary};
		font-size: 11pt;
		font-weight: 600;
		color: ${props => props.theme.colors.brandPrimary};
		white-space: nowrap;
		position: absolute;
		left: 39mm;
		top: 15mm;
	}
`;
interface ShoppingListRecipeProps extends ShoppingListApiRecipeItem {
	recipeIndexesById: object;
	isReadOnly?: boolean;
	onRecipeRemove?: (recipeId: string) => void;
	onRecipePortionChange?: (recipeId: string, quantity: number) => void;
}

class ShoppingListRecipe extends React.Component<ShoppingListRecipeProps & InjectedRoutableProps> {
	public render() {
		const {
			isReadOnly,
			title,
			url,
			portions,
			portionUnit,
			recipeIndexesById,
			id,
			onLinkClick,
			imageUrl,
			totalTime,
		} = this.props;
		const recipe = {
			type: RecipeCreatorType.valio,
			url,
			recipeName: title,
			imageUrl: `${imageUrl}/358x231-recipe-highlight`,
			totalTime: { minutes: totalTime },
			rating: null,
		};

		const itemColour = ShoppingListColours[recipeIndexesById[id]] || ShoppingListColours[0];

		return (
			<ShoppingListRecipeWrapper>
				<RecipeIndicator showBorder={true} itemColour={itemColour} className="recipe-indicator" />
				<HorizontalRecipeCard
					className="recipe-card"
					recipesData={[recipe]}
					isSmall={true}
					onClick={onLinkClick}
					print="minimal"
				/>
				<PrintPortions>
					{portions} {portionUnit}
				</PrintPortions>
				{!isReadOnly && (
					<>
						<PortionCalculator
							className="shopping-list-portion-calculator"
							portionQuantity={portions}
							title={portionUnit}
							onPortionChange={this.onPortionChange}
						/>
						<IconButton icon={<CloseIcon />} className="close-icon" onClick={this.onRecipeRemove} />
					</>
				)}
			</ShoppingListRecipeWrapper>
		);
	}

	private onPortionChange = (qty: number) => {
		const { onRecipePortionChange, id } = this.props;
		if (onRecipePortionChange) {
			onRecipePortionChange(id, qty);
		}
	};

	private onRecipeRemove = () => {
		const { onRecipeRemove, id } = this.props;
		if (onRecipeRemove) {
			onRecipeRemove(id);
		}
	};
}

export default injectRoutable(ShoppingListRecipe);
