import * as React from 'react';
import { LabeledIconButtonWrapper, LabeledIconButtonProps } from './common';

const Button = LabeledIconButtonWrapper('button');

const LabeledIconButton: React.FC<LabeledIconButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>> = ({
	icon,
	iconPlacement,
	children,
	...props
}) => {
	const iconProps = {
		size: 18,
	};

	return (
		<Button {...props} iconPlacement={iconPlacement}>
			{icon && (iconPlacement === 'left' || !iconPlacement) && React.cloneElement(icon, iconProps)}
			{children}
			{icon && iconPlacement === 'right' && React.cloneElement(icon, iconProps)}
		</Button>
	);
};

LabeledIconButton.defaultProps = {
	buttonStyle: 'primary',
	type: 'button',
};

export default LabeledIconButton;
