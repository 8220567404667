import * as React from 'react';
import styled from 'styled-components';

const StyledBodyText = styled('p')<BodyTextProps>`
	font-family: ${props => props.theme.fonts.secondary};
	line-height: 1.11;
	font-size: ${props => (props.size === 'small' ? '15px' : '18px')};
	color: ${props =>
		props.color === BodyTextColour.blue ? props.theme.colors.brandPrimary : props.theme.colors.bodyText};
`;

export enum BodyTextColour {
	blue = 'blue',
	default = 'default',
}

export type BodyTextColours = BodyTextColour.blue | BodyTextColour.default;

export type BodyTextSizes = 'small';

export interface BodyTextProps {
	color?: BodyTextColours;
	size?: BodyTextSizes;
}

const BodyText: React.FC<BodyTextProps & React.HTMLAttributes<HTMLParagraphElement>> = ({
	children,
	className,
	color,
	size,
}) => (
	<StyledBodyText color={color} size={size} className={className}>
		{children}
	</StyledBodyText>
);

BodyText.defaultProps = {
	color: BodyTextColour.default,
};

export default BodyText;
