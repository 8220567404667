import * as React from 'react';
import NormalIconWrapper, { NormalIconWrapperProps } from '../NormalIconWrapper';

const CalendarIcon: React.FC<NormalIconWrapperProps & React.SVGAttributes<SVGElement>> = ({ color, ...props }) => {
	return (
		<NormalIconWrapper {...props} fill="none">
			<path d="M8 13H6V14H8V13Z" stroke="#003878" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="square" />
			<path d="M14 13H12V14H14V13Z" stroke="#003878" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="square" />
			<path d="M8 18H6V19H8V18Z" stroke="#003878" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="square" />
			<path d="M14 18H12V19H14V18Z" stroke="#003878" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="square" />
			<path d="M20 13H18V14H20V13Z" stroke="#003878" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="square" />
			<path d="M8 13H6V14H8V13Z" fill="#003878" />
			<path d="M14 13H12V14H14V13Z" fill="#003878" />
			<path d="M8 18H6V19H8V18Z" fill="#003878" />
			<path d="M14 18H12V19H14V18Z" fill="#003878" />
			<path d="M20 13H18V14H20V13Z" fill="#003878" />
			<path
				d="M22 4H4C2.89543 4 2 4.89543 2 6V21C2 22.1046 2.89543 23 4 23H22C23.1046 23 24 22.1046 24 21V6C24 4.89543 23.1046 4 22 4Z"
				stroke="#003878"
				strokeWidth="2"
				strokeMiterlimit="10"
				strokeLinecap="square"
			/>
			<path d="M7 2V5" stroke="#003878" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="square" />
			<path d="M19 2V5" stroke="#003878" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="square" />
			<path d="M2 9H24" stroke="#003878" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="square" />
		</NormalIconWrapper>
	);
};

export default CalendarIcon;
