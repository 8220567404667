import { createStandardAction, getType, ActionType } from 'typesafe-actions';
import { combineReducers } from 'redux';
import { StaticHighlightsState, StaticHighlight } from '../interfaces/StaticContentHighlight';

export const staticHighlightActions = {
	setStaticHighlights: createStandardAction('RESOURCE/SET_STATIC_HIGHLIGHTS')<StaticHighlight[]>(),
	fetchStaticHighlights: createStandardAction('RESOURCE/FETCH_STATIC_HIGHLIGHTS')<{
		siteUrlId: string;
		resourceUrl: string;
	}>(),
};

export const staticHighlightReducers = combineReducers<StaticHighlightsState, StaticHighlightActions>({
	isLoading: (state = true, action) => {
		return action.type !== getType(staticHighlightActions.setStaticHighlights);
	},

	highlights: (state = [], action) => {
		switch (action.type) {
			case getType(staticHighlightActions.setStaticHighlights): {
				return action.payload;
			}
			default:
				return state;
		}
	},
});

export type StaticHighlightActions = ActionType<typeof staticHighlightActions>;
