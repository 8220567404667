import { withWindow } from 'styleguide/helpers/window';

type Optional<T> = {
	[P in keyof T]: T[P] | undefined;
};

export interface CommonLoadData {
	event: 'pageLoad';
	pageType: 'recipe' | 'product' | 'article' | 'event' | 'other' | 'recipe guest';
	pageCategory: string | null;
}

export interface ArticleLoadData {
	pageLabel: string;
}

export interface RecipeLoadData {
	recipeTotalTime: string;
	recipeIngredients: string;
	recipeRate: number;
	recipeFoodType: string;
	recipeFoodSeason: string;
	recipeDiet: string;
	recipeMealType: string;
	recipeDifficultyLevel: string;
	productEAN: string;
}

export interface ProductLoadData {
	productEAN: string;
	productCategory: string;
}

export type PageLoadEvent = CommonLoadData &
	Optional<ArticleLoadData> &
	Optional<RecipeLoadData> &
	Optional<ProductLoadData>;

export function emptyArticleLoadData(): Optional<ArticleLoadData> {
	return {
		pageLabel: undefined,
	};
}

export function emptyRecipeLoadData(): Optional<RecipeLoadData> {
	return {
		recipeTotalTime: undefined,
		recipeIngredients: undefined,
		recipeRate: undefined,
		recipeFoodType: undefined,
		recipeFoodSeason: undefined,
		recipeDiet: undefined,
		recipeMealType: undefined,
		recipeDifficultyLevel: undefined,
		productEAN: undefined,
	};
}

export function emptyProductLoadData(): Optional<ProductLoadData> {
	return {
		productEAN: undefined,
		productCategory: undefined,
	};
}

export function pushPageLoad(event: PageLoadEvent) {
	withWindow(w => {
		w.dataLayer = w.dataLayer || [];
		w.dataLayer.push(event);
	});
}

export function pushOtherPageLoad() {
	const event: PageLoadEvent = {
		event: 'pageLoad',
		pageType: 'other',
		pageCategory: null,
		...emptyArticleLoadData(),
		...emptyProductLoadData(),
		...emptyRecipeLoadData(),
	};
	pushPageLoad(event);
}
