import * as React from 'react';
import { OrderStatus, MaterialSearchState, Order, DeliveryFormShape } from '../interfaces';
import { ContentWrapper } from 'common/components/General';
import styled from 'styled-components';
import { AdultIcon, CheckIcon } from 'styleguide/components/Icons';
import { media } from 'styleguide/helpers';
import { FormattedMessage } from 'react-intl';
import OrderCart from './OrderCart';
import OrderDelivery from './OrderDelivery';
import OrderConfirmation from './OrderConfirmation';
import OrderSteps from './OrderSteps';
import { connect, MapStateToProps } from 'react-redux';
import { materialSearchActions } from '../reducers';
import { State } from 'common/reducers';
import { routingActions, RoutingState } from 'common/components/Routing/reducers';
import { ORDER_URL_HASH_BY_STATUS } from '../constants';

const OrderContentWrapper = styled(ContentWrapper)`
	max-width: 780px;
	padding-top: ${({ theme }) => theme.grid.gutterInPx(4)};

	${media.desktop`
		padding-top: ${({ theme }) => theme.grid.gutterInPx(6)};
	`};
`;

const Header = styled.header`
	margin-bottom: ${({ theme }) => theme.grid.gutterInPx(5)};
	width: 100%;
`;

const Title = styled.h2`
	${({ theme }) => theme.typography.heading};
	font-size: 36px;
	line-height: 36px;
	color: ${({ theme }) => theme.colors.brandPrimary};
	text-align: center;
	margin: 0;

	${media.desktop`
		font-size: 48px;
		line-height: 48px;
	`};

	img,
	svg {
		display: block;
		margin: 0 auto;
		width: 100px;
		height: 100px;
	}

	svg {
		padding: 20px;
		path {
			fill: ${({ theme }) => theme.colors.brandPrimary};
		}
	}
`;
type Props = MaterialsStateProps & MaterialSearchOrderDispatchProps;

const MaterialSearchOrder: React.FC<Props> = ({
	order,
	removeFromCart,
	updateCartItemQuantity,
	updateDeliveryDetails,
	orderCart,
	clearOrder,
	updateHash,
	replaceHistoryState,
	routing,
}) => {
	const { items, status, delivery } = order;

	const onUpdateCartItemQuantity = (id: string, quantity: number) => {
		updateCartItemQuantity({ id, quantity });
	};

	const onUpdateDeliveryDetails = (deliveryData: DeliveryFormShape) => {
		updateDeliveryDetails(deliveryData);
	};

	const onRemoveFromCart = (id: string) => {
		removeFromCart(id);
	};

	const onSetOrderStatus = (orderStatus: OrderStatus) => {
		updateHash(ORDER_URL_HASH_BY_STATUS[orderStatus]);
	};

	const onClearOrder = () => {
		clearOrder();
		updateHash(ORDER_URL_HASH_BY_STATUS.SEARCH);
	};

	const onReplaceHistoryState = (hash?: string) => {
		replaceHistoryState({
			...routing,
			hash,
		});
	};

	return (
		<OrderContentWrapper>
			<Header>
				<Title>
					{status !== 'CONFIRMATION' && (
						<>
							<AdultIcon />
							<FormattedMessage id="materials_search_cart" />
						</>
					)}
					{status === 'CONFIRMATION' && (
						<>
							<CheckIcon />
							<FormattedMessage id="materials_search_order_confirmation_title" />
						</>
					)}
				</Title>
				<OrderSteps status={status} />
			</Header>
			{status === 'CART' && (
				<OrderCart
					items={items}
					onUpdateCartItemQuantity={onUpdateCartItemQuantity}
					onSetOrderStatus={onSetOrderStatus}
					onRemoveFromCart={onRemoveFromCart}
				/>
			)}
			{status === 'DELIVERY' && (
				<OrderDelivery
					delivery={delivery}
					onUpdateDeliveryDetails={onUpdateDeliveryDetails}
					orderCart={orderCart}
					onSetOrderStatus={onSetOrderStatus}
					onReplaceHistoryState={onReplaceHistoryState}
				/>
			)}
			{status === 'CONFIRMATION' && <OrderConfirmation order={order} onClearOrder={onClearOrder} />}
		</OrderContentWrapper>
	);
};

interface MaterialsStateProps {
	order: Order;
	routing: RoutingState;
}

interface MaterialSearchOrderDispatchProps {
	updateCartItemQuantity: typeof materialSearchActions.updateCartItemQuantity.request;
	updateDeliveryDetails: typeof materialSearchActions.updateDeliveryDetails;
	removeFromCart: typeof materialSearchActions.removeFromCart.request;
	orderCart: typeof materialSearchActions.orderCart.request;
	clearOrder: typeof materialSearchActions.clearOrder;
	updateHash: typeof routingActions.updateHash;
	replaceHistoryState: typeof routingActions.replaceHistoryState;
}

const mapStateToProps: MapStateToProps<MaterialsStateProps, {}, State> = ({ resource, routing }) => {
	const { order } = resource.content as MaterialSearchState;

	return {
		routing,
		order,
	};
};

export default connect(mapStateToProps, {
	updateCartItemQuantity: materialSearchActions.updateCartItemQuantity.request,
	updateDeliveryDetails: materialSearchActions.updateDeliveryDetails,
	removeFromCart: materialSearchActions.removeFromCart.request,
	orderCart: materialSearchActions.orderCart.request,
	clearOrder: materialSearchActions.clearOrder,
	updateHash: routingActions.updateHash,
	replaceHistoryState: routingActions.replaceHistoryState,
})(MaterialSearchOrder);
