import * as React from 'react';
import { ValioMessages } from 'translations/messages.default';
import * as icons from 'styleguide/components/Icons';
import { IconWrapperProps } from '../styleguide/interfaces';

export const Icon = ({ iconName, ...rest }: { iconName?: string | null } & IconWrapperProps) => {
	if (iconName) {
		const IconComponent = icons[iconName];
		if (IconComponent) {
			return <IconComponent {...rest} />;
		}
	}
	return null;
};

export async function styleguideImport(icon: string | null): Promise<React.FC | React.FunctionComponent | null> {
	if (!icon || !icon.length) {
		return null;
	}
	const styleGuideIcons = await import(/* webpackMode: "eager" */ 'styleguide/components/Icons');
	return styleGuideIcons[icon];
}

export async function loadMessages(locale: string): Promise<ValioMessages | null> {
	let localeMessages;

	try {
		localeMessages = await import(/* webpackChunkName: "[request]" */ `../translations/messages.${locale}`);
	} catch (e) {
		appInsights.trackException(e);
		return null;
	}

	if (localeMessages) {
		return localeMessages.default;
	}

	return null;
}

export async function loadMessagesThrow(locale: string): Promise<ValioMessages> {
	let localeMessages;

	localeMessages = await import(/* webpackChunkName: "[request]" */ `../translations/messages.${locale}`);

	if (localeMessages) {
		return localeMessages.default;
	}

	throw new Error(`Failed to load messages for locale ${locale}`);
}
