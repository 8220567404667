import * as React from 'react';
import { ReactNode } from 'react';

interface UnderlineTextProps {
	children: ReactNode;
}

export const UnderlineText: React.FC<UnderlineTextProps> = ({ children }) => {
	return <span style={{ textDecoration: 'underline' }}>{children}</span>;
};
