import * as React from 'react';
import styled, { css } from 'styled-components';

export interface SmallHeaderProps {
	tagName?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
}

const SmallHeader: React.FC<SmallHeaderProps & React.HTMLAttributes<HTMLElement>> = ({
	children,
	tagName,
	...rest
}) => {
	const HeaderComponent = getComponent(tagName);
	return <HeaderComponent {...rest}>{children}</HeaderComponent>;
};

function getComponent(tagName: SmallHeaderProps['tagName']) {
	switch (tagName) {
		case 'h1':
			return Header1;
		case 'h2':
			return Header2;
		case 'h4':
			return Header4;
		case 'h5':
			return Header5;
		case 'h6':
			return Header6;
		case 'h3':
		default:
			return Header3;
	}
}

const Header1 = styled.h1`
	${getCommonHeaderStyles()}
`;

const Header2 = styled.h2`
	${getCommonHeaderStyles()}
`;

const Header3 = styled.h3`
	${getCommonHeaderStyles()}
`;

const Header4 = styled.h4`
	${getCommonHeaderStyles()}
`;

const Header5 = styled.h5`
	${getCommonHeaderStyles()}
`;

const Header6 = styled.h6`
	${getCommonHeaderStyles()}
`;

function getCommonHeaderStyles() {
	return css`
		${props => props.theme.typography.heading};
		line-height: 1;
		color: ${props => props.theme.colors.heading};
		font-size: 15px;
		text-transform: uppercase;
		margin: 0;
		margin-bottom: 10px;
	`;
}

export default SmallHeader;
