import { call, put, fork, takeLatest, select } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { ProductAspectSearchApi, productAspectSearchApi } from './services';
import { productFrontActions } from './reducers/reducers';
import { resourceActions } from 'common/components/Resource/reducers/actions';
import { ResourceType } from 'common/components/Resource/types';
import { getSiteUrlId } from 'common/components/App/services';
import { State } from 'common/reducers';

function* searchProductAspects(api: ProductAspectSearchApi) {
	const { routing, sites } = yield select((s: State) => ({ routing: s.routing, sites: s.app.settings!.sites }));
	const result = yield call(api.searchProductAspects, getSiteUrlId(routing, sites));
	yield put(productFrontActions.setProductAspects(result));
}

function* watchSearchProductAspects() {
	yield takeLatest(getType(productFrontActions.searchProductAspects), searchProductAspects, productAspectSearchApi);
}

function* searchProductAspectsForResource(
	api: ProductAspectSearchApi,
	{ payload }: ReturnType<typeof resourceActions.setResource>
) {
	const { resource } = payload;
	if (resource.type === ResourceType.productFront) {
		yield fork(searchProductAspects, api);
	}
}

function* watchProductFrontResourceRequests() {
	yield takeLatest(getType(resourceActions.setResource), searchProductAspectsForResource, productAspectSearchApi);
}

export function* productFrontSaga() {
	yield fork(watchSearchProductAspects);
	yield fork(watchProductFrontResourceRequests);
}
