import * as React from 'react';
import NormalIconWrapper, { NormalIconWrapperProps } from '../NormalIconWrapper';
import { ThemeContext } from 'styled-components';

const CloseIcon: React.FC<NormalIconWrapperProps & React.SVGAttributes<SVGElement>> = ({ color, ...props }) => {
	const theme = React.useContext(ThemeContext);
	return (
		<NormalIconWrapper {...props}>
			<path
				fill={color ?? theme.colors.brandPrimary}
				d="M14.414 13l8.293-8.293a.999.999 0 1 0-1.414-1.414L13 11.586 4.707 3.293a.999.999 0 1 0-1.414 1.414L11.586 13l-8.293 8.293a.999.999 0 1 0 1.414 1.414L13 14.414l8.293 8.293a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414L14.414 13z"
			/>
		</NormalIconWrapper>
	);
};

export default CloseIcon;
