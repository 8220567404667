import * as React from 'react';
import styled from 'styled-components';
import { media } from 'styleguide/helpers/media';
import Toggle from 'styleguide/components/Inputs/Toggle/Toggle';
import ModalFilters from './ModalFilters';
import SearchResultsCount from './SearchResultsCount';
import SelectedFilterPills from './SelectedFilterPills';
import SortOrderSelect, { SortOrder } from 'common/components/SortOrder/SortOrderSelect';
import { SearchType } from 'pagetypes/Search/types';
import { NameAndValue } from 'common/interfaces/common';
import noop from 'lodash/noop';
import { ToggleMedia, toggleMedia } from 'common/components/Media';
import includes from 'lodash/includes';
import { SearchFilterSection } from 'pagetypes/Search/interfaces';

const SearchActionsContainer = styled.div`
	display: flex;
	flex-flow: row wrap;
	justify-content: space-between;
	width: 100%;
	border-bottom: 1px solid ${props => props.theme.colors.border};
	margin: 4px auto 20px;
	padding: 12px 0 16px;

	${media.desktop`
    margin: 0;
		padding: 0 10px;
    border: none;
    justify-content: flex-start;
	`};

	${media.desktop1440`
		padding: 0 40px;
	`};
`;

const SearchFiltersContainer = styled.div<{ inlineMobileFilters?: boolean }>`
	width: ${props => (props.inlineMobileFilters ? '100%' : 'calc(50% - 6px)')};
	order: 2;

	${media.desktop`
    order: 0;
    width: 100%;
	`};

	.modal-filter-trigger {
		height: 28px;
		font-size: 12px;
		width: 100%;
	}
`;

const EmptySortWrapper = styled.div`
	width: calc(50% - 6px);
	order: 2;
`;

const ToggleWrapper = styled.div`
	order: 3;
	padding: 15px 10px 0;

	${toggleMedia('block', 'hide', 'desktop')};
`;

interface SearchActionsProps {
	className?: string;
	renderFilters: () => React.ReactNode;
	renderListAction?: () => React.ReactNode;
	renderListDescription?: () => React.ReactNode;
	resultsCount: number;
	searchType: SearchType;
	defaultSortValue?: string;
	sortOptions?: SortOrder[];
	onSortClick?: (sort: string) => void;
	selectedFilters?: NameAndValue[];
	onFilterClick?: (filterName: string, filterValue: string, selected: boolean) => void;
	isProfessional?: boolean;
	inlineMobileFilters?: boolean;
	filters?: SearchFilterSection[];
}

const SearchActions: React.FC<SearchActionsProps> = ({
	resultsCount,
	searchType,
	defaultSortValue,
	sortOptions,
	onSortClick,
	renderFilters,
	renderListAction,
	renderListDescription,
	selectedFilters,
	onFilterClick,
	isProfessional,
	inlineMobileFilters,
	className,
	filters,
}) => {
	const renderSort = () => {
		if (searchType !== SearchType.PRODUCT && sortOptions && onSortClick !== undefined) {
			const selectedSort = sortOptions.find(so => !!so.selected);
			const selectedSortValue = selectedSort ? selectedSort.value : defaultSortValue;

			return <SortOrderSelect sortOptions={sortOptions} onSortClick={onSortClick} value={selectedSortValue} />;
		}

		if (searchType === SearchType.ARTICLE) {
			return null;
		}

		return <EmptySortWrapper />;
	};

	const quickFilter =
		searchType === SearchType.RECIPE
			? filters?.find(f => f.type === 'OnlyQuick')
			: filters?.find(f => f.type === 'Novelty');

	const onQuickFilterClick = (isActive: boolean) => {
		(onFilterClick || noop)(quickFilter!.slug, quickFilter!.value, !isActive);
	};

	return (
		<SearchActionsContainer className={className}>
			<SearchFiltersContainer inlineMobileFilters={inlineMobileFilters}>
				{!inlineMobileFilters && (
					<ToggleMedia displayType="block" toggleType="hide" breakpoint="desktop">
						<ModalFilters filters={renderFilters()} />
					</ToggleMedia>
				)}
				{inlineMobileFilters && (
					<ToggleMedia displayType="block" toggleType="hide" breakpoint="desktop">
						{renderFilters()}
					</ToggleMedia>
				)}
				<ToggleMedia displayType="block" toggleType="show" breakpoint="desktop">
					{renderFilters()}
				</ToggleMedia>
			</SearchFiltersContainer>

			<SearchResultsCount count={resultsCount} searchType={searchType} />
			{!includes([SearchType.ARTICLE, SearchType.MATERIAL], searchType) && <SelectedFilterPills />}
			{renderListDescription !== undefined && renderListDescription()}
			{renderListAction === undefined && renderSort()}
			{renderListAction !== undefined && renderListAction()}
			{!inlineMobileFilters && quickFilter && (
				<ToggleWrapper>
					{(isProfessional && searchType !== SearchType.RECIPE) ||
						(!isProfessional && (
							<Toggle
								className="toggle-filter"
								initialState={selectedFilters && selectedFilters.find(f => f.name === quickFilter.slug) !== undefined}
								onToggleClick={onQuickFilterClick}
								size="small">
								{quickFilter.title}
							</Toggle>
						))}
				</ToggleWrapper>
			)}
		</SearchActionsContainer>
	);
};

export default SearchActions;
