import { all } from 'redux-saga/effects';
import recipeSaga from 'pagetypes/Recipe/sagas/sagas';
import { shoppingListSaga } from 'pagetypes/User/sagas/shopping-list';
import { recipeCookbookSaga } from 'pagetypes/User/sagas/cookbook';
import { productSearchSaga } from '../components/GlobalSearch/sagas/product-search';
import { staticHighlightsSaga } from 'common/components/Widgets/sagas/static-highlights';
import { currentUserSaga } from 'pagetypes/User/sagas/sagas';
import { userRecipesSaga } from 'pagetypes/User/sagas/userRecipes';
import { currentProfileSaga } from 'pagetypes/User/sagas/profile';
import appSaga from '../components/App/sagas';
import resourceSaga from '../components/Resource/sagas';
import { navigationSaga } from '../components/Navigation/sagas';
import { weeklyMenuSaga } from '../components/WeeklyMenu/sagas';
import { productFrontSaga } from 'pagetypes/ProductFront/sagas';
import { globalSearchSaga } from '../components/GlobalSearch/sagas/global-search';
import { loginSaga } from '../components/Login/sagas';
import { productRelatedItemsSaga } from 'pagetypes/Product/sagas';
import { commentsSaga } from '../components/Comments/sagas';
import searchSaga from 'pagetypes/Search/sagas';
import newsArchiveSaga from 'pagetypes/NewsArticle/sagas';
import materialSearchSaga from 'pagetypes/MaterialSearch/sagas';

export default function* rootSaga() {
	yield all([
		appSaga(),
		resourceSaga(),
		recipeSaga(),
		navigationSaga(),
		commentsSaga(),
		recipeCookbookSaga(),
		shoppingListSaga(),
		productSearchSaga(),
		productRelatedItemsSaga(),
		staticHighlightsSaga(),
		weeklyMenuSaga(),
		productFrontSaga(),
		currentUserSaga(),
		globalSearchSaga(),
		userRecipesSaga(),
		loginSaga(),
		currentProfileSaga(),
		searchSaga(),
		newsArchiveSaga(),
		materialSearchSaga(),
	]);
}
