import * as React from 'react';
import { knownWidgetTypes } from '.';
import { WidgetConfiguration, WidgetCommonProps } from './interfaces/interfaces';

export interface WidgetRendererProps {
	widget: WidgetConfiguration;
	escapeHtml?: boolean;
}

const WidgetRenderer: React.FC<WidgetRendererProps> = ({ widget, escapeHtml }) => {
	const actualWidgetName = widget.widgetName.replace('Frodget', '').replace('Widget', '');
	const Widget = knownWidgetTypes[actualWidgetName];
	if (Widget) {
		const commonProps: WidgetCommonProps = { zone: widget.zone, widgetId: widget.widgetId };
		const props: WidgetCommonProps = { ...commonProps, ...widget.configuration, escapeHtml };
		return <Widget {...props} />;
	}
	return null;
};

export default WidgetRenderer;
