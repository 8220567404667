import { RoutingParams } from 'common/components/Routing/reducers';
import includes from 'lodash/includes';
import { withWindow } from '../styleguide/helpers';

export const composeRoute = (params: RoutingParams) => {
	const { pathname, search = '', hash = '' } = params;
	const searchValue = search.length ? (includes(search, '?') ? search : `?${search}`) : '';
	return `${pathname}${searchValue}${hash}`;
};

const initiallyRenderedPathStorageKey = 'initiallyRenderedPath';
let initiallyRenderedPath = withWindow(w => w.sessionStorage.getItem(initiallyRenderedPathStorageKey));

export function setInitiallyRenderedPath(pathname: string) {
	if (initiallyRenderedPath === null) {
		initiallyRenderedPath = pathname;
		withWindow(w => w.sessionStorage.setItem(initiallyRenderedPathStorageKey, pathname));
	}
}

export function getInitiallyRenderedPath() {
	return initiallyRenderedPath;
}
