import * as React from 'react';
import { Fragment } from 'react';
import { generate } from 'shortid';
import styled, { css } from 'styled-components';
import { RecipeCardInterface } from '../../../interfaces';
import { default as HighlightCardWrapper, HighlightCardWrapperProps } from '../Card/HighlightCardWrapper';
import { formatTotalTime } from '../utils';
import HighlightCardImage from '../Card/HighlightCardImage';
import HighlightCardContent from '../Card/HighlightCardContent';
import { default as Stars, StarSize } from '../Stars';
import { mediaBreakpoints } from '../../../helpers';
import { ResponsiveValues } from 'common/components/Media';

const StarsAndMinutesWrapper = styled('div')<HighlightCardWrapperProps>`
	display: flex;
	align-items: center;
	margin-top: 14px;

	@media (max-width: ${mediaBreakpoints.phone320}px) {
		display: block;
		margin-top: 0;
	}

	${props =>
		props.print === 'minimal'
			? css`
					@media print {
						display: none;
					}
			  `
			: ''};
`;

interface CookingTimeProps {
	spaceBefore: boolean;
}

const CookingTime = styled('div')<CookingTimeProps>`
	font-family: ${props => props.theme.fonts.secondary};
	font-size: 13px;
	font-weight: 600;
	text-align: left;
	color: ${props => props.theme.colors.bodyText};
	margin-left: ${props => (props.spaceBefore ? '10px' : '0')};

	@media (max-width: ${mediaBreakpoints.phone320}px) {
		font-size: 12px;
		margin-left: 0;
	}
`;

export interface HorizontalRecipeCardProps extends HighlightCardWrapperProps {
	recipesData: RecipeCardInterface[];
	isVisible?: ResponsiveValues<boolean>;
}

const HorizontalRecipeCard: React.FC<HorizontalRecipeCardProps & React.HTMLAttributes<HTMLAnchorElement>> = ({
	isSmall,
	hasBorder,
	recipesData,
	isVisible,
	print,
	...rest
}) => {
	return (
		<Fragment>
			{recipesData &&
				recipesData.map((recipe: RecipeCardInterface) => {
					const { url, imageUrl, recipeName, totalTime, rating, badgeText } = recipe;
					return (
						<HighlightCardWrapper
							key={generate()}
							isSmall={isSmall}
							hasBorder={hasBorder}
							isVisible={isVisible}
							href={url}
							{...rest}>
							<HighlightCardImage
								isSmall={isSmall}
								hasBorder={hasBorder}
								altText={recipeName}
								imgSrc={imageUrl}
								badgeText={badgeText}
							/>
							<HighlightCardContent isSmall={isSmall} title={recipeName}>
								<StarsAndMinutesWrapper print={print}>
									{rating !== null && (
										<Stars
											size={isSmall ? StarSize.small : { default: StarSize.small, tablet: StarSize.medium }}
											rating={rating || 0}
										/>
									)}
									<CookingTime spaceBefore={rating !== null}>{formatTotalTime(totalTime)}</CookingTime>
								</StarsAndMinutesWrapper>
							</HighlightCardContent>
						</HighlightCardWrapper>
					);
				})}
		</Fragment>
	);
};

export default HorizontalRecipeCard;
