import { TotalTime, RecipeCardInterface } from 'styleguide/interfaces/cardInterfaces';
import { formatTotalTime } from 'styleguide/components/Cards/utils';
import {
	RecipeDisplay,
	RecipeAuthor,
	RecipePortion,
	RecipeApiSegment,
	IngredientSegment,
	RecipeApiIngredient,
	Ingredient,
	RecipeApiAlternativeIngredient,
	AlternativeProduct,
	RecipeApiStep,
	Instruction,
	RecipeApiImage,
	InstructionImage,
	RecipeApiTutorial,
	Tutorial,
	CookingTime,
	RecipeApiShape,
	ProfessionalRecipeApiShape,
	RecipeDifficulty,
} from './interfaces';
import { RecipePortionApiResponseData } from './services/services';
import { addNonBreakingSpaces } from 'utils/strings';
import { RECIPE_DIFFICULTY_MAP } from './constants';

export const parseRecipe = (resource: RecipeApiShape | ProfessionalRecipeApiShape): RecipeDisplay => {
	const {
		id,
		status,
		title,
		type,
		description,
		portionCount,
		portionWeigth: portionWeight,
		portionsTitle,
		segments,
		statistics,
		userGenerated,
		author,
		publicationTime,
		image,
		difficulty,
		totalTime,
		activeWorkTimeMinutes,
		preparationTimes,
		steps,
		hints,
		diets,
		dishes,
		seasons,
		mealTypes,
		groups,
		nutritionSet,
		linkedRecipes,
		energyKcalPer100Grams,
		isIndustrial,
		targetGroup,
		storeRibbonColor,
		stores,
	} = resource.content;

	return {
		id,
		isProfessionalRecipe: resource.type === 'ProfessionalRecipe',
		status,
		title,
		type,
		description,
		portion: parsePortion(portionCount, portionsTitle, portionWeight),
		userGenerated,
		author: parseAuthor(author),
		publicationTime,
		image,
		difficulty,
		totalTime,
		preparationTimes: parseCookingTimes(activeWorkTimeMinutes, preparationTimes),
		activeWorkTimeMinutes,
		statistics,
		segments: parseSegments(segments),
		steps: parseSteps(steps),
		hints: hints.filter(h => h.type === 'Action').map(h => h.title),
		diets,
		dishes,
		seasons,
		mealTypes,
		groups,
		nutritionSet,
		portionNutritients: { isLoading: false, nutrients: nutritionSet.nutrients },
		linkedRecipes: parseLinkedRecipes(linkedRecipes),
		energyKcalPer100Grams,
		isIndustrial,
		targetGroup,
		storeRibbonColor,
		stores,
	};
};

const parseLinkedRecipes = (linkedRecipes: RecipeCardInterface[]): RecipeCardInterface[] => {
	return linkedRecipes.map(recipe => ({
		...recipe,
		badgeText: recipe.type,
		imageUrl: recipe.imageUrl,
	}));
};

const parseAuthor = (author: RecipeAuthor): RecipeAuthor => {
	const { name, imageUrl, isChefOfTheYear, isAimo } = author;
	return {
		name,
		imageUrl,
		isChefOfTheYear,
		isAimo,
	};
};

const parsePortion = (portionCount: number, portionsTitle: string, portionWeight: number): RecipePortion => {
	return {
		basePortion: portionCount,
		portionCount,
		portionsTitle,
		basePortionWeight: portionWeight,
		portionWeight,
		isLoading: false,
	};
};

const parseSegments = (segments: RecipeApiSegment[]): IngredientSegment[] => {
	if (segments && segments.length > 0) {
		return segments.map(
			({ title, ingredients }: RecipeApiSegment): IngredientSegment => {
				return {
					title,
					ingredients: parseIngredients(ingredients),
				} as IngredientSegment;
			}
		);
	}
	return [];
};

const parseIngredients = (ingredients: RecipeApiIngredient[]): Ingredient[] => {
	if (ingredients && ingredients.length > 0) {
		return ingredients.map(
			(recipeIngredient: RecipeApiIngredient): Ingredient => {
				const {
					title,
					titleExtra,
					quantity,
					preparationStyle,
					productPackage,
					alternativeIngredients,
					weight,
					quantityAsFraction,
					brackets,
				} = recipeIngredient;

				let { quantityUnit, quantityUnitExtra } = recipeIngredient;

				if (quantity === null && quantityUnitExtra !== null) {
					quantityUnit = null;
					quantityUnitExtra = addNonBreakingSpaces(quantityUnitExtra);
				}

				if ((quantity || quantityUnit) && quantityUnitExtra) {
					quantityUnitExtra = ` (${addNonBreakingSpaces(quantityUnitExtra)})`;
				}

				return {
					title,
					titleExtra,
					quantityAsFraction,
					quantityUnit,
					quantityUnitExtra,
					preparationStyle,
					productPackage,
					link: (productPackage && productPackage.url) || '',
					alternatives: parseAlternativeIngredients(alternativeIngredients),
					weight,
					brackets,
				} as Ingredient;
			}
		);
	}
	return [];
};

const parseAlternativeIngredients = (
	alternativeIngredients: RecipeApiAlternativeIngredient[]
): AlternativeProduct[] => {
	return alternativeIngredients.map(({ title, productPackage, quantity, quantityUnit }) => {
		let result: AlternativeProduct = { title, quantity, quantityUnit };
		if (productPackage !== null) {
			const { url, imageUrl } = productPackage;
			result = {
				...result,
				url,
				imageUrl,
			};
		}
		return result;
	});
};

const parseSteps = (steps: RecipeApiStep[]): Instruction[] => {
	if (steps && steps.length > 0) {
		let order = 0;
		return steps.map(
			({ type, images, title, tutorials }: RecipeApiStep): Instruction => {
				type === 'Action' && order++;

				const instructionImages = images.map(
					({ url }: RecipeApiImage): InstructionImage => {
						return { url } as InstructionImage;
					}
				);

				return {
					title,
					type,
					order,
					images: instructionImages,
					tutorials: parseInstructionTutorials(tutorials),
				} as Instruction;
			}
		);
	}
	return [];
};

const parseInstructionTutorials = (tutorials: RecipeApiTutorial[]): Tutorial[] => {
	if (tutorials && tutorials.length > 0) {
		return tutorials.map(
			({ title, videoId }: RecipeApiTutorial): Tutorial => {
				return {
					title,
					videoId,
				} as Tutorial;
			}
		);
	}
	return [];
};

const parseCookingTimes = (activeWorkTimeMinutes: number | null, preparationTimes: TotalTime[]): CookingTime[] => {
	const result: CookingTime[] = [];
	if (activeWorkTimeMinutes !== null) {
		result.push({ title: 'recipe_cooking_time', time: formatTotalTime({ minutes: activeWorkTimeMinutes }) });
	}
	if (preparationTimes && preparationTimes.length > 0) {
		result.push(
			...preparationTimes.map(time => ({ title: time.title || '', time: formatTotalTime({ minutes: time.minutes }) }))
		);
	}
	return result;
};

export const parseRecipePortionData = (
	recipe: RecipeDisplay,
	ingredientSegments: RecipePortionApiResponseData[],
	quantity: number,
	weight?: number
): RecipeDisplay => {
	return {
		...recipe,
		portion: {
			...recipe.portion,
			portionCount: quantity,
			portionWeight: weight,
			isLoading: false,
			error: '',
		},
		segments: parsePortionSegments(ingredientSegments),
	};
};

const parsePortionSegments = (segments: RecipePortionApiResponseData[]): IngredientSegment[] => {
	return segments.map(
		({ title, ingredients }: RecipePortionApiResponseData): IngredientSegment => {
			return {
				title,
				ingredients: parseIngredients(ingredients),
			} as IngredientSegment;
		}
	);
};

export const getRecipeDifficultyMessageId = (difficulty: RecipeDifficulty) => {
	if (!difficulty) {
		return null;
	}

	const { type, level } = difficulty;

	if (RECIPE_DIFFICULTY_MAP[type] && RECIPE_DIFFICULTY_MAP[type][level]) {
		return RECIPE_DIFFICULTY_MAP[type][level];
	}

	return null;
};
