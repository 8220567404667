import * as React from 'react';
import { Helmet } from 'react-helmet';
import loadable from '@loadable/component';
import { MapStateToProps, connect } from 'react-redux';
import { composeRoute } from 'utils/routing';
import { getResourceIdentification } from 'utils/resource';
import LoadingSpinner from 'common/components/Loading/LoadingSpinner';
import ResourceLoadError from './components/ResourceLoadError';
import { ResourceType } from './types';
import { PageWrapper } from '../General';
import { ResourceState } from './reducers/reducers';
import { RoutingState } from '../Routing/reducers';
import { UserState } from 'pagetypes/User/reducers/reducers';
import { State } from '../../reducers';
import { resourceActions } from './reducers/actions';
import { ContentThemeProvider } from 'common/contexts/ContentThemeContext';
import { getSite, getSiteUrlId, getSiteUrlPrefix, SiteId } from '../App/services';
import { withWindow } from 'styleguide/helpers';
import MyContent from 'pagetypes/MyContent/MyContent';
import ShoppingList from 'pagetypes/ShoppingList/ShoppingList';
import MaterialSearch from 'pagetypes/MaterialSearch/MaterialSearch';

import FourOhFour from './404';

// Main resource types
const CmsContent = loadable(() => import(/* webpackChunkName: "cmscontent" */ 'pagetypes/CmsContent/CmsContent'));
const Event = loadable(() => import(/* webpackChunkName: "event" */ 'pagetypes/Event/Event'));
const Recipe = loadable(() => import(/* webpackChunkName: "recipe" */ 'pagetypes/Recipe/Recipe'));
const RecipeAspectGroup = loadable(() =>
	import(/* webpackChunkName: "recipe" */ 'pagetypes/RecipeAspectGroup/RecipeAspectGroup')
);
const RecipeFront = loadable(() => import(/* webpackChunkName: "recipe" */ 'pagetypes/RecipeFront/RecipeFront'));
const Search = loadable(() => import(/* webpackChunkName: "search" */ 'pagetypes/Search/Search'));
const Product = loadable(() => import(/* webpackChunkName: "product" */ 'pagetypes/Product/Product'));
const ProductFront = loadable(() => import(/* webpackChunkName: "product" */ 'pagetypes/ProductFront/ProductFront'));
const KitchenMenu = loadable(() => import(/* webpackChunkName: "other" */ 'pagetypes/KitchenMenu/KitchenMenu'));
const AdminToolbar = loadable(() => import(/* webpackChunkName: "navigation" */ '../AdminToolbar/AdminToolbar'));
const ProductGroup = loadable(() => import(/* webpackChunkName: "other" */ 'pagetypes/ProductGroup/ProductGroup'));
const Brand = loadable(() => import(/* webpackChunkName: "other" */ 'pagetypes/Brand/Brand'));
const NewsArticle = loadable(() => import(/* webpackChunkName: "newsarticle" */ 'pagetypes/NewsArticle/NewsArticle'));
const Home = loadable(() => import(/* webpackChunkName: "home" */ 'pagetypes/Home/Home'));
const VideoContent = loadable(() => import(/* webpackChunkName: "other" */ 'pagetypes/VideoContent/VideoContent'));

const resourceMap = {
	[ResourceType.home]: Home,
	[ResourceType.video]: VideoContent,
	[ResourceType.brand]: Brand,
	[ResourceType.cmsContent]: CmsContent,
	[ResourceType.event]: Event,
	[ResourceType.myContent]: MyContent,
	[ResourceType.newsArticle]: NewsArticle,
	[ResourceType.recipe]: Recipe,
	[ResourceType.professionalRecipe]: Recipe,
	[ResourceType.recipeAspectGroup]: RecipeAspectGroup,
	[ResourceType.recipeFront]: RecipeFront,
	[ResourceType.recipeSearch]: Search,
	[ResourceType.product]: Product,
	[ResourceType.productFront]: ProductFront,
	[ResourceType.productGroup]: ProductGroup,
	[ResourceType.productSearch]: Search,
	[ResourceType.kitchenMenu]: KitchenMenu,
	[ResourceType.articleSearch]: Search,
	[ResourceType.shoppingList]: ShoppingList,
	[ResourceType.materialSearch]: MaterialSearch,
};

const sitetitleMap = {
	[SiteId.finc]: 'Finlandia Cheese',
	[SiteId.og]: 'Oddlygood',
	[SiteId.ogNl]: 'Oddlygood',
	[SiteId.ogUK]: 'Oddlygood',
	[SiteId.ogUs]: 'Oddlygood',
	[SiteId.ogFi]: 'Oddlygood',
	[SiteId.ogSe]: 'Oddlygood',
	[SiteId.ogEe]: 'Oddlygood',
	[SiteId.ogEs]: 'Oddlygood',
	[SiteId.ogIe]: 'Oddlygood',
	[SiteId.ogPl]: 'Oddlygood',
	[SiteId.majal]: ' ', // Attention: this is a single space character intentionally
};

function setDataLayer(user: UserState | null) {
	if (!user || (!user.cluster && !user.gaUserId)) {
		return;
	}
	const clusterInfo = {
		event: 'clusterInfo',
		cluster: user.cluster,
	};
	const userInfo = {
		userId: user.gaUserId,
	};
	withWindow(w => {
		w.dataLayer = w.dataLayer || [];
		if (user.cluster) {
			w.dataLayer.push(clusterInfo);
		}
		if (user.gaUserId) {
			w.dataLayer.push(userInfo);
		}
	});
}

class Resource extends React.Component<ResourceStateProps & ResourceDispatchProps, {}> {
	constructor(props: ResourceStateProps & ResourceDispatchProps) {
		super(props);
		const { siteUrlId, resource, routing } = this.props;
		if (siteUrlId) {
			if (!resource.id || resource.relativeUrl !== routing.pathname) {
				this.loadResource();
			}
		}
	}

	public componentDidMount() {
		setDataLayer(this.props.user);
	}

	public componentDidUpdate(prevProps: ResourceStateProps & ResourceDispatchProps) {
		const pathChanged = prevProps.routing.pathname !== this.props.routing.pathname;
		const siteChanged = prevProps.siteUrlId !== this.props.siteUrlId;
		const prevUserId = prevProps.user ? prevProps.user.id : null;
		const userId = this.props.user ? this.props.user.id : null;
		const userChanged = prevUserId !== userId;
		if ((pathChanged || siteChanged) && this.props.siteUrlId) {
			this.loadResource();
		} else if (userChanged) {
			setDataLayer(this.props.user);
		}
	}

	public render() {
		const { resource, user, siteId } = this.props;

		const { isLoading, title, metaDescription, structuredData, noindex, themeColor, ampUrl } = resource;

		const defaultTitle = 'Valio';
		const pageTitle = !isLoading && title ? title : defaultTitle;
		const theme = resource.theme;
		const siteTitle = siteId && sitetitleMap[siteId] ? sitetitleMap[siteId] : theme === 'aimo' ? 'Valio Aimo' : 'Valio';

		return (
			<ContentThemeProvider contentTheme={themeColor ? { color: themeColor } : undefined}>
				<Helmet>
					<title>
						{resource.notFound ? '404' : pageTitle} {siteTitle && siteTitle.trim().length > 0 ? '| ' + siteTitle : ''}
					</title>
					<meta name="description" content={metaDescription} />
					{noindex && <meta name="robots" content="noindex" />}
					{ampUrl && <link rel="amphtml" href={ampUrl} />}
					<body className={getResourceIdentification(resource)} />
				</Helmet>
				{structuredData && this.renderStructuredData(structuredData)}
				{user && user.isAdmin && <AdminToolbar />}
				<PageWrapper isLoading={isLoading}>
					{isLoading && <LoadingSpinner />}
					{!isLoading && this.renderResource(siteId)}
				</PageWrapper>
			</ContentThemeProvider>
		);
	}

	private renderStructuredData(structuredData: string) {
		return <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: structuredData }} />;
	}

	private loadResource() {
		const { fetchResource, routing, siteUrlId } = this.props;
		const confirmComment = routing.query && routing.query['vahvista-kommentti'];
		if (siteUrlId) {
			fetchResource(siteUrlId, composeRoute(routing), confirmComment ? ((confirmComment as any) as string) : null);
		}
	}

	private renderResource(siteId: SiteId | undefined) {
		const { resource } = this.props;

		if (resource.error) {
			return <ResourceLoadError />;
		}

		if (resource.notFound) {
			return <FourOhFour siteId={siteId} urlPrefix={this.props.urlPrefix || '/'} />;
		}

		if (resource.type) {
			const ResourceComponent = resourceMap[resource.type];
			if (ResourceComponent) {
				return <ResourceComponent layout={resource.layout} />;
			}
		}

		return null;
	}
}

interface ResourceStateProps {
	resource: ResourceState;
	siteUrlId: string | undefined;
	urlPrefix: string | undefined;
	routing: RoutingState;
	user: UserState | null;
	siteId: SiteId | undefined;
}

const mapStateToProps: MapStateToProps<ResourceStateProps, {}, State> = ({
	app,
	resource,
	routing,
	user,
}): ResourceStateProps => {
	const siteUrlId = app.settings ? getSiteUrlId(routing, app.settings.sites) : undefined;
	const urlPrefix = app.settings ? getSiteUrlPrefix(routing, app.settings.sites) : undefined;
	const sites = (app.settings && app.settings.sites) || [];
	const site = getSite(routing, sites);
	return {
		urlPrefix,
		siteUrlId,
		resource,
		routing,
		user,
		siteId: site?.id,
	};
};

interface ResourceDispatchProps {
	fetchResource: typeof resourceActions.fetchResource;
}

export default connect(mapStateToProps, {
	fetchResource: resourceActions.fetchResource,
})(Resource);
