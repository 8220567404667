import { State } from 'common/reducers';
import { Locales } from 'common/types/locale';
import { getSiteSpecs } from 'common/components/App/services';

export function localeSelector(state: State): Locales {
	const locale = localeSelectorNoDefault(state);
	return locale || Locales.en;
}

export function localeSelectorThrow(state: State): Locales {
	const locale = localeSelectorNoDefault(state);
	if (locale) {
		return locale;
	} else {
		throw new Error('Failed to determine locale.');
	}
}

export function localeSelectorNoDefault(state: State): Locales | null {
	const { app, routing, resource } = state;

	if (resource && resource.language) {
		return resource.language;
	}

	const sites = (app.settings && app.settings.sites) || [];
	const site = getSiteSpecs(routing, sites);

	return (site && site.site.defaultLanguage) || null;
}
