import * as React from 'react';
import InlineVideo from '../InlineVideo';
import { VideoSource } from 'styleguide/types/mediaTypes';
import { withWindow, media } from 'styleguide/helpers';
import throttle from 'lodash/throttle';
import { isInViewport } from 'utils/element';
import styled from 'styled-components';
import { getScrollbarWidth } from 'utils/document';

const Wrapper = styled.div`
	position: absolute;
	left: 0;
	width: 100%;
	overflow: hidden;
	z-index: -1;

	${media.desktop`
		width: calc(100vw - ${getScrollbarWidth()}px);
		margin: 0 calc(50% - 50vw + ${Math.floor(getScrollbarWidth() / 2)}px);
	`};

	video {
		min-width: 100%;
	}
`;

export interface BackgroundVideoProps {
	loop: boolean;
	loopDelay: number;
	sources: VideoSource[];
	playOnMount?: boolean;
}

class BackgroundVideo extends React.Component<BackgroundVideoProps & React.HTMLAttributes<HTMLVideoElement>> {
	public static defaultProps = {
		loop: true,
		loopDelay: 1000,
	};

	private wrapperRef: React.RefObject<HTMLDivElement>;
	private videoRef: React.RefObject<InlineVideo>;

	private onScroll = throttle(() => this.playIfVisible(), 500);

	constructor(props: BackgroundVideoProps & React.HTMLAttributes<HTMLVideoElement>) {
		super(props);

		this.wrapperRef = React.createRef();
		this.videoRef = React.createRef();
	}

	public componentDidMount() {
		withWindow(w => {
			w.addEventListener('scroll', this.onScroll);
		});

		if (this.props.playOnMount) {
			this.playIfVisible();
		}
	}

	public componentWillUnmount() {
		withWindow(w => {
			w.removeEventListener('scroll', this.onScroll);
		});
	}

	public render() {
		const { sources, loop, loopDelay, playOnMount, className, ...rest } = this.props;

		return (
			<Wrapper className={className} ref={this.wrapperRef}>
				<InlineVideo
					ref={this.videoRef}
					sources={sources}
					{...rest}
					controls={false}
					hideCustomControls={true}
					playsInline={true}
					onEnded={this.onEnded}
				/>
			</Wrapper>
		);
	}

	private onEnded = () => {
		const { loop, loopDelay } = this.props;

		if (loop) {
			setTimeout(() => {
				this.playIfVisible();
			}, loopDelay);
		}
	};

	private playIfVisible = () => {
		if (this.wrapperRef.current && isInViewport(this.wrapperRef.current)) {
			this.videoRef.current?.play();
		}
	};
}

export default BackgroundVideo;
