import * as React from 'react';
import styled, { css } from 'styled-components';

const SLIDER_WIDTH = {
	small: 40,
	large: 60,
};
const SLIDER_HEIGHT = {
	small: 20,
	large: 30,
};
const LEVER_SIZE = {
	small: 16,
	large: 24,
};

interface SizeProps {
	size: 'small' | 'large';
}

interface ActiveProps {
	isActive: boolean;
}

const ComponentWrapper = styled.div`
	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-start;
	align-content: center;
`;

const InputWrapper = styled('div')<SizeProps>`
	position: relative;
	width: ${props => SLIDER_WIDTH[props.size]}px;
	order: 2;
`;

const ChildWrapper = styled('label')<SizeProps>`
	font-family: ${props => props.theme.fonts.secondary};
	font-size: 15px;
	font-weight: 600;
	line-height: ${props => SLIDER_HEIGHT[props.size]}px;
	color: ${props => props.theme.colors.brandPrimary};
	margin-right: 8px;
	order: 1;
`;

const Slider = styled('div')<SizeProps & ActiveProps>`
	position: absolute;
	top: 0;
	left: 0;
	width: ${props => SLIDER_WIDTH[props.size]}px;
	height: ${props => SLIDER_HEIGHT[props.size]}px;
	background-color: ${props => (props.isActive ? props.theme.colors.selectedItem : props.theme.colors.border)};
	border-radius: ${props => SLIDER_HEIGHT[props.size] / 2}px;
	box-shadow: 0 1px 4px ${props => props.theme.colors.shadow} inset;
`;

const Lever = styled('div')<SizeProps & ActiveProps>`
  transition: all ease-out 250ms;
	position: absolute;
  top: ${props => (props.size === 'large' ? 3 : 2)}px;
  ${props =>
		props.isActive
			? css`
					left: ${props.size === 'large' ? 33 : 22}px;
			  `
			: css`
					left: ${props.size === 'large' ? 3 : 2}px;
			  `}
	height: ${props => LEVER_SIZE[props.size]}px;
	width: ${props => LEVER_SIZE[props.size]}px;
	border-radius: 50%;
	background-color: #fff;
	box-shadow: 0 2px 4px ${props => props.theme.colors.shadow};
`;

export interface ToggleProps extends SizeProps {
	initialState?: boolean;
	onToggleClick: (isActive: boolean) => void;
}

type Props = ToggleProps & React.HTMLAttributes<HTMLDivElement>;

class Toggle extends React.Component<Props, { isActive: boolean }> {
	public static defaultProps = {
		size: 'small',
	};

	constructor(props: Props) {
		super(props);
		this.state = {
			isActive: props.initialState || false,
		};
	}

	public render() {
		// @ts-ignore unused props
		const { initialState, size, children, onToggleClick, ...rest } = this.props;
		const { isActive } = this.state;
		return (
			<ComponentWrapper {...rest} onClick={this.onToggleClick}>
				<InputWrapper className="toggle-switch" size={size}>
					<Slider size={size} isActive={isActive} />
					<Lever size={size} isActive={isActive} />
				</InputWrapper>
				<ChildWrapper className="toggle-label" size={size}>
					{children}
				</ChildWrapper>
			</ComponentWrapper>
		);
	}

	private onToggleClick = () => {
		this.setState({ isActive: !this.state.isActive });
		this.props.onToggleClick(this.state.isActive);
	};
}

export default Toggle;
