import * as React from 'react';
import styled from 'styled-components';
import { css } from 'styled-components';

export interface PillElementProps {
	amount?: number;
	selected?: boolean;
	disabled?: boolean;
}

const PillElement = styled('button')<PillElementProps>`
	border-radius: 2px;
	font-weight: 800;
	font-size: 13px;
	line-height: 24px;
	text-align: center;
	padding: 0 12px;
	text-decoration: none;
	white-space: nowrap;
	display: inline-block;
	font-family: ${props => props.theme.fonts.primary};
	background-color: ${props => props.theme.colors.brandPrimaryFeatureBackground};
	color: ${props => props.theme.colors.brandPrimary};

	position: relative;
	background-color: ${props =>
		props.disabled
			? props.theme.colors.inputDisabledBackground
			: props.selected
			? props.theme.colors.brandPrimaryFeatureBackground
			: props.theme.colors.white};
	border: 1px solid
		${props =>
			props.disabled ? props.theme.colors.inputDisabledBackground : props.theme.colors.brandPrimaryFeatureBackground};
	color: ${props => (props.disabled ? props.theme.colors.inputDisabledText : props.theme.colors.brandPrimary)};
	cursor: pointer;

	${props => {
		if (props.amount !== undefined) {
			return css`
				> span {
					&:last-child {
						padding-left: 10px;
						font-size: 12px;
						font-weight: 300;
						color: ${props.theme.colors.bodyText};
						font-family: ${props.theme.fonts.secondary};
					}
				}
			`;
		}

		return null;
	}}

	&:focus,
	&:active {
		outline: none;
	}

	&:hover {
		${props =>
			props.disabled
				? css`
						cursor: default;
				  `
				: css`
						cursor: pointer;
				  `};
	}
`;

export interface FilterPillProps {
	amount?: number;
	itemId: string;
	selected?: boolean;
	disabled?: boolean;
	onPillClick: (itemId: string, selected: boolean) => void;
}

const FilterPill: React.FC<FilterPillProps & React.HTMLAttributes<HTMLElement>> = props => {
	const { onPillClick, itemId, ...rest } = props;

	const onClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		const { selected } = props;
		event.currentTarget.blur();
		onPillClick(itemId, !selected);
	};

	return (
		<PillElement {...rest} onClick={onClick}>
			<span>{props.children}</span>
			{props.amount !== undefined && <span>{props.amount}</span>}
		</PillElement>
	);
};

export default FilterPill;
