import * as React from 'react';
import { InputShape } from 'common/components/Forms/interfaces';
import { validateRequired, validateEmail, ValidationPatterns } from 'common/components/Forms/helpers';
import injectForm, { InjectedFormProps } from 'common/components/Forms/Form';
import InputWithIcon from 'styleguide/components/Inputs/InputWithIcon';
import { SendToMailIcon, UserIcon } from 'styleguide/components/Icons';
import { useIntl, FormattedMessage } from 'react-intl';
import { ProfileShape } from '../reducers/profile';
import { OutlinedButton } from 'styleguide/components/Buttons/OutlinedButton';
import Alert, { AlertType } from 'styleguide/components/Typography/Alert';
import styled from 'styled-components';
import SmallHeader from 'styleguide/components/Typography/SmallHeader';
import { media } from 'styleguide/helpers';

const ContactForm = styled.form`
	${media.tablet`
		margin-right: 15px;
	`};

	& > .form-field + .form-field {
		margin-top: ${({ theme }) => theme.grid.gutterInPx(2)};
	}

	& > button {
		margin-top: ${({ theme }) => theme.grid.gutterInPx(2)};
		width: 100%;
	}
`;

const Notification = styled(Alert)`
	margin-top: ${({ theme }) => theme.grid.gutterInPx(2)};
`;

export interface UserContactFormFields {
	firstName: InputShape<string>;
	lastName: InputShape<string>;
	email: InputShape<string>;
}

export interface UserContactFormValidators {
	firstName: Array<(value: string) => string>;
	lastName: Array<(value: string) => string>;
	email: Array<(value: string) => string>;
}

export interface Props {
	userProfile: ProfileShape;
}

const UserContactForm: React.FC<Props & InjectedFormProps<UserContactFormFields>> = ({
	userProfile,
	fields,
	onInputFocus,
	onInputChange,
	onInputBlur,
	onSubmit,
	isValid,
}) => {
	if (userProfile.isLoading) {
		return null;
	}

	const intl = useIntl();

	const onFocus = (event: React.FormEvent<HTMLInputElement>) => {
		const { value, name } = event.currentTarget;
		onInputFocus(value, name);
	};

	const onBlur = (event: React.FormEvent<HTMLInputElement>) => {
		const { value, name } = event.currentTarget;
		onInputBlur(value, name);
	};

	const onChange = (event: React.FormEvent<HTMLInputElement>) => {
		const { value, name } = event.currentTarget;
		onInputChange(value, name, true);
	};

	const { firstName, lastName, email } = fields;
	const { profile, notifications } = userProfile;

	if (!profile) {
		return null;
	}

	return (
		<ContactForm onSubmit={onSubmit} noValidate>
			<SmallHeader>
				<FormattedMessage id="user_profile_personal_information" />
			</SmallHeader>
			<InputWithIcon icon={<UserIcon />} type="text" disabled={true} name="username" defaultValue={profile.username} />
			<InputWithIcon
				icon={<UserIcon />}
				type="text"
				name="firstName"
				placeholder={intl.formatMessage({ id: 'user_profile_first_name' })}
				value={firstName.value}
				validationError={firstName.touched && !firstName.focused && firstName.error ? firstName.error : ''}
				required={true}
				onChange={onChange}
				onFocus={onFocus}
				onBlur={onBlur}
			/>
			<InputWithIcon
				icon={<UserIcon />}
				type="text"
				name="lastName"
				placeholder={intl.formatMessage({ id: 'user_profile_last_name' })}
				value={lastName.value}
				validationError={lastName.touched && !lastName.focused && lastName.error ? lastName.error : ''}
				required={true}
				onChange={onChange}
				onFocus={onFocus}
				onBlur={onBlur}
			/>
			<InputWithIcon
				icon={<SendToMailIcon />}
				type="email"
				name="email"
				placeholder={intl.formatMessage({ id: 'email_address' })}
				value={email.value}
				validationError={email.touched && !email.focused && email.error ? email.error : ''}
				required={true}
				pattern={ValidationPatterns.EMAIL}
				onChange={onChange}
				onFocus={onFocus}
				onBlur={onBlur}
			/>

			{notifications &&
				((notifications.contactInfoSuccess && (
					<Notification
						type={AlertType.Success}
						highlightedText={intl.formatMessage({ id: notifications.contactInfoSuccess })}
					/>
				)) ||
					(notifications.contactInfoError && (
						<Notification type={AlertType.Error} highlightedText={notifications.contactInfoError.title}>
							{notifications.contactInfoError.message}
						</Notification>
					)))}

			<OutlinedButton buttonStyle="secondary" type="submit" disabled={!isValid} size="medium">
				<FormattedMessage id="user_profile_save" />
			</OutlinedButton>
		</ContactForm>
	);
};

const userContactFields: UserContactFormFields = {
	firstName: { value: '' },
	lastName: { value: '' },
	email: { value: '' },
};

const userContactValidators: UserContactFormValidators = {
	firstName: [validateRequired('user_profile_first_name_validation_required')],
	lastName: [validateRequired('user_profile_last_name_validation_required')],
	email: [validateRequired('user_profile_email_validation_required'), validateEmail],
};

export default injectForm(userContactFields, userContactValidators)(UserContactForm);
