import * as React from 'react';
import NormalIconWrapper, { NormalIconWrapperProps } from '../NormalIconWrapper';
import { ThemeContext } from 'styled-components';

const WorldIcon: React.FC<NormalIconWrapperProps & React.SVGAttributes<SVGElement>> = ({ color, ...props }) => {
	const theme = React.useContext(ThemeContext);

	return (
		<NormalIconWrapper {...props}>
			<path
				fill={color ?? theme.colors.brandPrimary}
				d="M21.71 19.705a13.32 13.32 0 0 0 .222-2.83l1.585-.655a10.9 10.9 0 0 1-.354.989c-.376.907-.87 1.738-1.452 2.496zM7.84 22.714l1.59-.658a14.193 14.193 0 0 0 1.639 1.474c.174.132.349.254.523.372a11.055 11.055 0 0 1-2.801-.74 11.643 11.643 0 0 1-.95-.448zm-4.575-4.6l2.753-1.14A20.22 20.22 0 0 0 8.08 20.45l-2.217.919a10.895 10.895 0 0 1-2.6-3.253zm1.024-11.82a13.31 13.31 0 0 0-.221 2.829l-1.585.657c.103-.333.219-.664.354-.99.376-.906.87-1.737 1.452-2.495zm13.87-3.008l-1.59.658a14.02 14.02 0 0 0-1.638-1.474c-.181-.138-.363-.253-.544-.376.958.124 1.907.364 2.823.743.325.136.642.286.949.449zm4.576 4.599l-2.753 1.14a20.22 20.22 0 0 0-2.063-3.474l2.217-.92a10.895 10.895 0 0 1 2.599 3.254zm-1.99 2.989L23.5 9.733c.423 1.35.575 2.75.463 4.138l-2.21.915a20.335 20.335 0 0 0-1.007-3.912zm-4.908 6.362l-1.53-3.695 4.592-1.902c.477 1.342.78 2.678.925 3.946l-3.987 1.65zm2.23 5.384l-1.464-3.536 3.312-1.372c-.08 2.167-.714 3.943-1.848 4.908zm-3.312-2.77l1.467 3.54c-1.19.097-2.552-.396-3.943-1.453-.286-.217-.566-.46-.84-.713l3.316-1.374zm-2.296-5.543l1.53 3.695-3.994 1.654a17.803 17.803 0 0 1-2.13-3.447l4.594-1.902zm-6.283-3.892l3.987-1.651 1.53 3.695L7.1 14.36a17.861 17.861 0 0 1-.925-3.946zM7.933 3.38l1.465 3.535-3.313 1.373c.079-2.167.714-3.943 1.848-4.908zm3.312 2.77L9.782 2.618c.096-.008.189-.027.287-.027 1.118 0 2.374.5 3.652 1.47.286.219.566.46.84.715L11.246 6.15zm4.76.194a17.825 17.825 0 0 1 2.13 3.446l-4.594 1.903-1.531-3.695 3.995-1.654zM2.5 16.267a10.884 10.884 0 0 1-.463-4.138l2.209-.915a20.354 20.354 0 0 0 1.008 3.912L2.5 16.266zm22.51-8.242C22.268 1.403 14.65-1.753 8.025.99 1.403 3.732-1.753 11.352.99 17.975 3.06 22.975 7.913 26 13.017 26c1.654 0 3.337-.318 4.958-.99 6.622-2.742 9.778-10.362 7.035-16.985z"
			/>
		</NormalIconWrapper>
	);
};

export default WorldIcon;
