import { ImageSource } from 'styleguide/interfaces';
import { VideoFormat, ImageFormat, MediaType, VideoSource } from 'styleguide/types/mediaTypes';
import { getImageSources } from './image';

export interface HeroMediaSource {
	mediaType: MediaType;
	mediaSrc: ImageSource[] | VideoSource[] | null;
}

export function parseMediaType(media?: string): MediaType | null {
	if (media) {
		const mediaParts = media.split('/');
		if (mediaParts.length === 2) {
			return mediaParts[0] as MediaType;
		}
	}
	return null;
}

export function parseMediaFormat(media?: string): VideoFormat | ImageFormat | null {
	if (media) {
		const mediaParts = media.split('/');
		if (mediaParts.length === 2) {
			return mediaParts[1] as VideoFormat | ImageFormat;
		}
	}
	return null;
}

export function getVideoSources(desktopUrl?: string, mobileUrl?: string, mediaType?: string): VideoSource[] | null {
	const mediaFormat = parseMediaFormat(mediaType) as VideoFormat;

	if ((desktopUrl || mobileUrl) && mediaType) {
		const sources: VideoSource[] = [];

		if (mobileUrl) {
			sources.push({
				src: mobileUrl,
				type: mediaFormat,
				targetMedia: {
					toggleType: 'hide',
					breakpoint: 'tablet',
				},
			});
		}

		if (desktopUrl) {
			sources.push({
				src: desktopUrl,
				type: mediaFormat,
				targetMedia: {
					toggleType: 'show',
					breakpoint: 'tablet',
				},
			});
		}

		return sources;
	}
	return null;
}

export function getHeroMediaSource(
	heroMediaType: string | undefined,
	heroImageDesktop: string | undefined,
	heroImageMobile: string | undefined,
	imagePresetSuffix: string
): HeroMediaSource {
	const mediaType = parseMediaType(heroMediaType) ?? 'image';

	const mediaSrc =
		mediaType === 'video'
			? getVideoSources(heroImageDesktop, heroImageMobile, heroMediaType)
			: getImageSources(heroImageDesktop, heroImageMobile, imagePresetSuffix);

	return {
		mediaType,
		mediaSrc,
	};
}
