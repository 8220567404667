import { createStandardAction, createCustomAction, getType, ActionType } from 'typesafe-actions';
import { Reducer } from 'redux';
import { CommentsState, CommentsDisplay } from './interfaces';
import { FetchCommentsApiShape, CommentApiComment } from './services';
import { CommentReportReason } from './types';

export const commentActions = {
	fetchComments: createCustomAction(
		'COMMENT/FETCH_COMMENT_REQUEST',
		type => (resourceTypeId: string, skip: number, pageLength: number) => ({
			type,
			payload: {
				resourceTypeId,
				skip,
				pageLength,
			},
		})
	),

	setComments: createStandardAction('COMMENT/FETCH_COMMENT_SUCCESS')<FetchCommentsApiShape>(),

	fetchCommentsFailed: createStandardAction('COMMENT/FETCH_COMMENT_FAILURE')<string>(),

	reportComment: createCustomAction(
		'COMMENT/REPORT_COMMENT',
		type => (commentId: string, reason: CommentReportReason) => ({
			type,
			payload: { commentId, reason },
		})
	),

	reportCommentSucceeded: createStandardAction('COMMENT/REPORT_COMMENT_SUCCESS')<string>(),

	reportCommentFailed: createStandardAction('COMMENT/REPORT_COMMENT_FAILURE')<string>(),

	addComment: createCustomAction(
		'COMMENT/ADD_COMMENT',
		type => (resourceId: string, comment: string, email?: string) => ({
			type,
			payload: { resourceId, comment, email },
		})
	),

	addCommentSucceeded: createStandardAction('COMMENT/ADD_COMMENT_SUCCESS')(),

	addCommentFailed: createStandardAction('COMMENT/ADD_COMMENT_FAILURE')(),
};

export const initialCommentsState = {
	comments: undefined,
	totalCount: 0,
	isLoading: false,
	skip: 0,
	commentAddState: null,
};

export const commentReducer: Reducer<CommentsState, CommentActions> = (
	state = initialCommentsState,
	action
): CommentsState => {
	switch (action.type) {
		case getType(commentActions.fetchComments):
			return { ...state, isLoading: true };

		case getType(commentActions.setComments):
			return {
				...state,
				isLoading: false,
				skip: action.payload.skip + action.payload.pageLength,
				pageLength: action.payload.pageLength,
				totalCount: action.payload.totalCount,
				comments: commentsReducer(state.comments, action),
			};

		case getType(commentActions.fetchCommentsFailed):
			return {
				...state,
				comments: state.comments,
				isLoading: false,
				error: action.payload,
			};

		case getType(commentActions.reportCommentSucceeded):
			return {
				...state,
				commentReportState: {
					...state.commentReportState,
					[action.payload]: true,
				},
			};

		case getType(commentActions.reportCommentFailed):
			return {
				...state,
				commentReportState: {
					...state.commentReportState,
					[action.payload]: false,
				},
			};

		case getType(commentActions.addCommentFailed):
			return {
				...state,
				commentAddState: false,
			};

		case getType(commentActions.addCommentSucceeded):
			return {
				...state,
				commentAddState: true,
			};

		default:
			return state;
	}
};

const commentsReducer: Reducer<CommentsDisplay[], CommentActions> = (state = [], action) => {
	switch (action.type) {
		case getType(commentActions.setComments):
			return state.concat(
				action.payload.comments.map(
					(comment: CommentApiComment): CommentsDisplay => ({
						commentId: comment.id,
						userName: comment.creatorName,
						timeStamp: comment.creationTimeString,
						isValioComment: comment.isValioComment,
						message: comment.text,
					})
				)
			);
		default:
			return state;
	}
};

export type CommentActions = ActionType<typeof commentActions>;
