import * as React from 'react';
import styled, { css } from 'styled-components';
import { getOutlinedButtonColor, getOutlinedButtonHoverColor, getButtonColor } from '../utils';
import { OutlinedButtonProps } from '../../../interfaces';
import { NormalIconWrapperProps } from '../../Icons/NormalIcons/NormalIconWrapper';
import { baseButtonCss, baseOutlinedButtonCss, baseFilledButtonCss } from '../common';

export interface LabeledIconButtonProps extends OutlinedButtonProps {
	/** Icon instance */
	icon?: React.ReactElement<NormalIconWrapperProps & React.SVGAttributes<SVGElement>>;
	/** Icon placement: `left` (default) | `right` */
	iconPlacement?: 'left' | 'right';
	/** Whether outlined of filled */
	isFilled?: boolean;
}

export const LabeledIconButtonWrapper = <T extends keyof JSX.IntrinsicElements>(tagName: T) => styled(tagName)<
	LabeledIconButtonProps
>`
	${baseButtonCss};

	${props =>
		props.isFilled
			? css`
					${baseFilledButtonCss};

					&:hover,
					&:focus,
					&:active {
						path,
						g {
							fill: ${getButtonColor(props)};
						}
					}

					path,
					g {
						fill: ${getButtonColor(props)};
					}
			  `
			: css`
					${baseOutlinedButtonCss};

					padding: 0 20px 0 15px;

					&:hover,
					&:focus,
					&:active {
						path,
						g {
							fill: ${getOutlinedButtonHoverColor(props)};
						}
					}

					path,
					g {
						fill: ${getOutlinedButtonColor(props)};
					}
			  `};

	& > svg,
	& > img {
		flex: 0 0 auto;

		${props =>
			props.iconPlacement === 'right'
				? css`
						margin-left: 10px;
				  `
				: `margin-right: 10px;`};

		${props => {
			switch (props.size) {
				case 'extra-large':
					return css`
						width: 26px;
						height: 26px;
					`;
				case 'large':
				case 'medium':
					return css`
						width: 24px;
						height: 24px;
					`;
				default:
					return null;
			}
		}};
		display: block;
	}
`;
