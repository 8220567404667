import * as React from 'react';
import * as oddlyGoodLogo from './oddlyGoodLogo.svg';
import * as oddlyGoodLogoInverted from './oddlyGoodLogoInverted.svg';

export interface OddlyGoodLogoIconProps {
	disableScaling?: boolean;
	inverted?: boolean;
	logoSize?: string;
}

const OddlyGoodLogoIcon: React.FC<OddlyGoodLogoIconProps> = ({ disableScaling, logoSize, inverted }) => {
	return (
		<img
			src={inverted ? oddlyGoodLogoInverted : oddlyGoodLogo}
			alt="OddlyGood"
			width={disableScaling && logoSize ? logoSize : '100%'}
		/>
	);
};

export default OddlyGoodLogoIcon;
