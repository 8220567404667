import { fork, put, call, takeLatest } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { LoginApi, loginApi } from './services';
import { loginActions } from './reducers/actions';
import { userActions } from 'pagetypes/User/reducers/actions';
import { LoginModalFormType } from './types';
import { routingActions } from '../Routing/reducers';

export function* doLogin(api: LoginApi, { payload }: ReturnType<typeof loginActions.doLogIn>) {
	try {
		const { email, password, rememberLogin } = payload;
		const loginResult = yield call(api.userLogin, email, password, rememberLogin);
		if (loginResult === 0) {
			yield put(loginActions.loginSuccess(0));
			yield put(userActions.fetchCurrentUser());
		}
		if (loginResult === 1) {
			yield put(loginActions.loginFail(1));
		}
	} catch (e) {
		yield put(loginActions.loginFail(1));
	}
}

export function* watchLogin() {
	yield takeLatest(getType(loginActions.doLogIn), doLogin, loginApi);
}

export function* doLogout(api: LoginApi) {
	try {
		yield call(api.userLogout);
		yield put(userActions.fetchCurrentUser());
	} catch (e) {
		// What now?
	}
}

export function* watchLogout() {
	yield takeLatest(getType(loginActions.doLogOut), doLogout, loginApi);
}

export function* doFacebookLogin(api: LoginApi, { payload }: ReturnType<typeof loginActions.doFacebookLogIn>) {
	try {
		const loginResult = yield call(api.sendFacebookLogin, payload);
		if (loginResult === 0 || loginResult === 6) {
			yield put(loginActions.facebookLoginSuccess(6));
			yield put(userActions.fetchCurrentUser());
		}
		if (loginResult === 7) {
			yield put(loginActions.facebookLoginFail(7));
		}
	} catch (e) {
		yield put(loginActions.loginFail(1));
	}
}

export function* watchFacebookLogin() {
	yield takeLatest(getType(loginActions.doFacebookLogIn), doFacebookLogin, loginApi);
}

export function* requestRegisterUser(api: LoginApi, { payload }: ReturnType<typeof loginActions.requestRegisterUser>) {
	try {
		yield call(api.requestRegisterUser, payload);
		yield put(loginActions.requestRegisterUserSuccess());
		yield put(loginActions.changeLoginFormType(LoginModalFormType.REGISTRATION_REQUEST_SENT));
	} catch (e) {
		yield put(loginActions.requestRegisterUserError(e));
	}
}

export function* watchRequestRegisterUser() {
	yield takeLatest(getType(loginActions.requestRegisterUser), requestRegisterUser, loginApi);
}

export function* registerUser(api: LoginApi, { payload }: ReturnType<typeof loginActions.registerUser>) {
	try {
		const { email, userName, password, subscribeToNewsletter, hash, siteUrlId } = payload;
		yield call(api.registerUser, email, userName, password, subscribeToNewsletter, hash);
		yield put(loginActions.registerUserSuccess());
		yield put(userActions.fetchCurrentUser());
		yield put(routingActions.updateUrl({ pathname: '/', search: '', hash: '' }, siteUrlId));
	} catch (e) {
		yield put(loginActions.registerUserError(e));
	}
}

export function* watchRegisterUser() {
	yield takeLatest(getType(loginActions.registerUser), registerUser, loginApi);
}

export function* getUniqueUserName(api: LoginApi, { payload }: ReturnType<typeof loginActions.getUniqueUserName>) {
	try {
		const uniqueUserName = yield call(api.getUniqueUserName, payload);
		yield put(loginActions.getUniqueUserNameSuccess(uniqueUserName));
	} catch (e) {
		yield put(loginActions.getUniqueUserNameError(e));
	}
}

export function* watchGetUniqueUserName() {
	yield takeLatest(getType(loginActions.getUniqueUserName), getUniqueUserName, loginApi);
}

export function* forgotPassword(api: LoginApi, { payload }: ReturnType<typeof loginActions.forgotPassword>) {
	try {
		yield call(api.forgotPassword, payload);
		yield put(loginActions.forgotPasswordSuccess());
	} catch (e) {
		yield put(loginActions.forgotPasswordError(e));
	}
}

export function* watchForgotPassword() {
	yield takeLatest(getType(loginActions.forgotPassword), forgotPassword, loginApi);
}

export function* resetPassword(api: LoginApi, { payload }: ReturnType<typeof loginActions.resetPassword>) {
	try {
		const { email, password, hash } = payload;
		yield call(api.resetPassword, email, password, hash);
		yield put(loginActions.resetPasswordSuccess());
	} catch (e) {
		yield put(loginActions.resetPasswordError(e));
	}
}

export function* watchResetPassword() {
	yield takeLatest(getType(loginActions.resetPassword), resetPassword, loginApi);
}

export function* loginSaga() {
	yield fork(watchLogin);
	yield fork(watchLogout);
	yield fork(watchFacebookLogin);
	yield fork(watchGetUniqueUserName);
	yield fork(watchRegisterUser);
	yield fork(watchRequestRegisterUser);
	yield fork(watchForgotPassword);
	yield fork(watchResetPassword);
}
