import * as React from 'react';
import NormalIconWrapper, { NormalIconWrapperProps } from '../NormalIconWrapper';
import { ThemeContext } from 'styled-components';

const FamilyPortionIcon: React.FC<NormalIconWrapperProps & React.SVGAttributes<SVGElement>> = ({ color, ...props }) => {
	const theme = React.useContext(ThemeContext);
	const fillColor = color ?? theme.colors.brandPrimary;

	return (
		<NormalIconWrapper {...props}>
			<path
				fill={fillColor}
				d="M21.25 21.25V14.8a.886.886 0 00-.47-.783l-3.28-1.76a.75.75 0 01.71-1.322l3.277 1.76A2.386 2.386 0 0122.75 14.8V22a.75.75 0 01-.75.75h-8.182a.75.75 0 110-1.5h7.432zm-7.722-10.309a.75.75 0 11.706 1.324L12.31 13.29a.75.75 0 01-.706-1.324l1.923-1.025z"
			/>
			<path
				fill={fillColor}
				d="M17.937 10.573c.55-.55.859-1.295.859-2.073v-.818a2.932 2.932 0 00-5.864 0V8.5a2.932 2.932 0 005.005 2.073zM11.432 8.5v-.818a4.432 4.432 0 018.864 0V8.5a4.432 4.432 0 01-8.864 0zm-4.83 8.08c.104-.052 1.274 1.024 1.39 1.024l2.023.002c.117 0 1.096-1.079 1.2-1.026l2.033 1.024c.81.404 1.32 1.23 1.32 2.135V22a.75.75 0 01-.75.75H4a.75.75 0 01-.75-.75v-2.261c0-.904.51-1.73 1.316-2.133l2.035-1.026zM5.24 18.947c-.3.15-.49.457-.49.792v1.511h8.318v-1.511a.887.887 0 00-.492-.794L10.7 18H7.117l-1.877.947z"
			/>
			<path
				fill={fillColor}
				d="M11.431 14.636v-.805a2.523 2.523 0 00-2.522-2.479 2.52 2.52 0 00-2.523 2.466v.818a2.523 2.523 0 105.045 0zm-2.52 4.024a4.023 4.023 0 01-4.025-4.024v-.831a4.023 4.023 0 018.045.013v.818c0 2.221-1.8 4.023-4.02 4.024z"
			/>
		</NormalIconWrapper>
	);
};

export default FamilyPortionIcon;
