import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { media } from 'styleguide/helpers/media';
import Button from 'styleguide/components/Buttons/Button/Button';
import Divider from 'styleguide/components/Divider/Divider';
import { DividerSize, DividerDirection } from 'styleguide/components/Divider/Divider';
import MediumHeader from 'styleguide/components/Typography/MediumHeader/MediumHeader';

const LoginHeader = styled.header`
	text-align: center;
	margin-bottom: 20px;

	${media.desktop`
		text-align: left;
		padding: 0 25px;
		margin-bottom: 0;
	`};

	.medium-header {
		margin: 0;
	}
`;

const LoginWrapper = styled.div`
	text-align: center;
	margin-bottom: 30px;

	${media.desktop`
		text-align: left;
		display: flex;
		align-items: stretch;
		padding: 25px;
		margin-bottom: 0;
	`};

	& > form {
		width: 100%;

		${media.desktop`
			width: auto;
			flex: 1 1 auto;
			min-width: 320px;			
		`};
	}

	.facebook-login-wrapper {
		width: 100%;
		margin-top: 20px;

		${media.desktop`
			flex: 1 1 auto;
			width: auto;
			min-width: 260px;
			margin: 0 0 0 40px;
		`};
	}
`;

const LoginFooter = styled.footer`
	text-align: center;
	margin-top: 30px;
	padding: 0 20px;

	${media.tablet`
		display: flex;
		justify-content: center;
		align-items: center;
	`};

	${media.desktop`
		padding: 0 40px;
	`};

	& > span {
		font-family: ${props => props.theme.fonts.secondary};
		font-size: 18px;
		color: ${props => props.theme.colors.bodyText};
	}

	button {
		width: 100%;
		margin-top: 20px;

		${media.tablet`
			margin: 0 0 0 20px;
			flex: 1;			
		`};
	}
`;

export interface LoginDialogProps {
	onCreateLogin: () => void;
	children: React.ReactNode;
}

class LoginDialog extends React.Component<LoginDialogProps, {}> {
	public render() {
		const { onCreateLogin, children } = this.props;
		return (
			<>
				<LoginHeader>
					<MediumHeader className="medium-header">
						<FormattedMessage id="user_log_in" />
					</MediumHeader>
				</LoginHeader>
				<LoginWrapper>{children}</LoginWrapper>
				<Divider size={DividerSize.full} direction={DividerDirection.horizontal} />
				<LoginFooter>
					<FormattedMessage id="user_no_login" />
					<Button onClick={onCreateLogin} size="medium">
						<FormattedMessage id="user_create_login" />
					</Button>
				</LoginFooter>
			</>
		);
	}
}

export default LoginDialog;
