import * as React from 'react';
import styled from 'styled-components';

interface FilteredImageProps {
	isTextIcon?: boolean;
}

const FilteredImage = styled.img<FilteredImageProps>`
	filter: ${({ isTextIcon, theme }) =>
		isTextIcon ? theme.filters.textIconFilter || theme.filters.iconFilter : theme.filters.iconFilter};
`;

interface Props {
	icon: string;
	isTextIcon?: boolean;
}

const FilteredIconImage: React.FC<Props & React.HTMLAttributes<HTMLImageElement>> = ({ icon, isTextIcon, ...rest }) => {
	return <FilteredImage src={icon} isTextIcon={isTextIcon} width="80" height="80" {...rest} />;
};

export default FilteredIconImage;
