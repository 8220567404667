export enum Locales {
	fi = 'fi',
	en = 'en',
	es = 'es',
	pl = 'pl',
	sv = 'sv',
	et = 'et',
	lt = 'lt',
	lv = 'lv',
	ru = 'ru',
}
