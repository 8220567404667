import { css } from 'styled-components';
import { ButtonProps, OutlinedButtonProps } from '../../interfaces';
import {
	getButtonHeight,
	getButtonPadding,
	getButtonFontSize,
	getOutlinedButtonBorderColor,
	getOutlinedButtonHoverColor,
	getOutlinedButtonBorderHoverColor,
	getOutlinedButtonBackgroundHoverColor,
	getOutlinedButtonColor,
	getOutlinedButtonBackgroundColor,
	getButtonFontStyle,
	getButtonFontWeight,
	getButtonBackgroundColor,
	getButtonColor,
	getButtonHoverColor,
	getButtonBackgroundHoverColor,
	getButtonVerticalPadding,
	getButtonBorderColor,
	getButtonBorderHoverColor,
} from './utils';
import { media } from '../../helpers';
import { ButtonSizes, ButtonFontStyles } from 'styleguide/types';
import { ResponsiveValues } from 'common/components/Media';

const responsiveButtonCss = (size?: ButtonSizes, fontStyle?: ButtonFontStyles) => {
	return css`
		min-height: ${getButtonHeight(size)};
		padding: ${getButtonVerticalPadding(size)} ${getButtonPadding(size)};
		font-size: ${!size || size === 'small' || size === 'medium' ? '11px' : getButtonFontSize({ size, fontStyle })};

		${media.phone<ButtonProps>`
		font-size: ${getButtonFontSize({ size, fontStyle })};
	`};
	`;
};

export const baseButtonCss = css<ButtonProps>`
	height: 10px; // IE 11 Hack to workaround min-height align-items: center bug
	border-radius: 4px;
	font-family: ${props => getButtonFontStyle(props)};
	font-weight: ${props => getButtonFontWeight(props.fontStyle)};
	text-align: center;
	text-transform: uppercase;
	display: flex;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
	text-decoration: none;
	line-height: 1.1;

	${props => {
		if (typeof props.size === 'object') {
			const sizeMap = props.size as ResponsiveValues<ButtonSizes>;

			return css`
				${Object.keys(sizeMap).map(key => {
					if (key === 'default') {
						return responsiveButtonCss(sizeMap.default, props.fontStyle);
					}
					return css`
						${media[key]`
							${responsiveButtonCss(sizeMap[key], props.fontStyle)}
						`};
					`;
				})};
			`;
		}

		return responsiveButtonCss(props.size, props.fontStyle);
	}};

	&:hover,
	&:focus,
	&:active {
		outline: none;
		cursor: pointer;
	}

	&:disabled {
		background-color: ${props => props.theme.colors.buttonDisabled} !important;
		color: ${props => props.theme.colors.white} !important;
		border: none !important;
		cursor: not-allowed !important;
	}
`;

export const baseFilledButtonCss = css<ButtonProps>`
	border: none;
	background-color: ${props => getButtonBackgroundColor(props)};
	color: ${props => getButtonColor(props)};

	${props => {
		const borderColor = getButtonBorderColor(props);
		if (borderColor) {
			return `border: solid 1px ${borderColor};`;
		}
		return null;
	}}

	&:hover,
	&:focus,
	&:active {
		color: ${props => getButtonHoverColor(props)};
		background-color: ${props => getButtonBackgroundHoverColor(props)};
		${props => {
			const borderColor = getButtonBorderHoverColor(props);
			if (borderColor) {
				return `border: solid 1px ${borderColor};`;
			}
			return null;
		}}
	}
`;

export const baseOutlinedButtonCss = css<OutlinedButtonProps>`
	border: solid 1px ${props => getOutlinedButtonBorderColor(props)};
	background-color: ${props => getOutlinedButtonBackgroundColor(props)};
	color: ${props => getOutlinedButtonColor(props)};

	&:hover,
	&:focus,
	&:active {
		outline: none;
		color: ${props => getOutlinedButtonHoverColor(props)};
		border-color: ${props => getOutlinedButtonBorderHoverColor(props)};
		background-color: ${props => getOutlinedButtonBackgroundHoverColor(props)};
	}
`;
