import { getType } from 'typesafe-actions';
import { call, put, fork, takeLatest } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { SearchApi, searchApi } from './services';
import { SearchType } from 'pagetypes/Search/types';
import { searchActions } from './reducers/search-actions';
import { ProductSearchApi, productSearchApi } from 'common/components/GlobalSearch/services/product-search';

function* doSearchArticles(
	api: SearchApi,
	siteUrlId: string,
	resourceId: string,
	query: string,
	pageSize: number,
	pageNumber: number
) {
	try {
		const articles = yield call(api.searchArticles, siteUrlId, resourceId, query, pageSize, pageNumber);
		yield put(searchActions.searchArticlesSucceeded(articles, pageNumber));
	} catch (e) {
		yield put(searchActions.searchArticlesFailed(e));
	}
}

function* searchArticles(api: SearchApi, { payload }: ReturnType<typeof searchActions.searchArticles>) {
	yield call(
		doSearchArticles,
		api,
		payload.siteUrlId,
		payload.resourceId,
		payload.query,
		payload.pageSize,
		payload.pageNumber
	);
}

export function* watchSearchArticles() {
	yield takeLatest(getType(searchActions.searchArticles), searchArticles, searchApi);
}

export function* watchLoadMoreArticles() {
	yield takeLatest(getType(searchActions.loadMoreArticles), searchArticles, searchApi);
}

function* doSearchRecipes(
	api: SearchApi,
	siteUrlId: string,
	professional: boolean,
	query: string,
	pageSize: number,
	pageNumber: number
) {
	try {
		const recipes = yield call(api.searchRecipes, siteUrlId, professional, query, pageSize, pageNumber);
		yield put(searchActions.searchRecipesSucceeded(recipes, pageNumber));
	} catch (e) {
		yield put(searchActions.searchRecipesFailed(e));
	}
}

function* searchRecipes(api: SearchApi, { payload }: ReturnType<typeof searchActions.searchRecipes>) {
	yield call(
		doSearchRecipes,
		api,
		payload.siteUrlId,
		payload.professional,
		payload.query,
		payload.pageSize,
		payload.pageNumber
	);
}

export function* watchSearchRecipes() {
	yield takeLatest(getType(searchActions.searchRecipes), searchRecipes, searchApi);
}

export function* watchLoadMoreRecipes() {
	yield takeLatest(getType(searchActions.loadMoreRecipes), searchRecipes, searchApi);
}

function* doSearchProducts(
	api: SearchApi,
	siteUrlId: string,
	query: string,
	pageSize: number,
	pageNumber: number,
	onlyItems: boolean
) {
	try {
		const products = yield call(api.searchProducts, siteUrlId, query, pageSize, pageNumber, onlyItems);
		yield put(searchActions.searchProductsSucceeded(products, pageNumber));
	} catch (e) {
		yield put(searchActions.searchProductsFailed(e));
	}
}

function* searchProducts(api: SearchApi, { payload }: ReturnType<typeof searchActions.searchProducts>) {
	yield call(
		doSearchProducts,
		api,
		payload.siteUrlId,
		payload.query,
		payload.pageSize,
		payload.pageNumber,
		payload.onlyItems
	);
}

export function* watchSearchProducts() {
	yield takeLatest(getType(searchActions.searchProducts), searchProducts, searchApi);
}

export function* watchLoadMoreProducts() {
	yield takeLatest(getType(searchActions.loadMoreProducts), searchProducts, searchApi);
}

function* setQuery(api: SearchApi, { payload }: ReturnType<typeof searchActions.setQuery>) {
	const { siteUrlId, professional, resourceId, query, searchType, pageSize, pageNumber, onlyItems } = payload;
	yield delay(500);

	if (searchType === SearchType.ARTICLE) {
		yield call(doSearchArticles, api, siteUrlId, resourceId, query, pageSize, pageNumber);
	}

	if (searchType === SearchType.RECIPE) {
		yield call(doSearchRecipes, api, siteUrlId, professional, query, pageSize, pageNumber);
	}

	if (searchType === SearchType.PRODUCT) {
		yield call(doSearchProducts, api, siteUrlId, query, pageSize, pageNumber, onlyItems);
	}
}

export function* watchSetQuery() {
	yield takeLatest(getType(searchActions.setQuery), setQuery, searchApi);
}

function* doRecipeProductFilterSearchProducts(
	api: ProductSearchApi,
	siteUrlId: string,
	searchTerm: string,
	maxResults: number
) {
	yield delay(200);
	try {
		const products = yield call(api.searchProductsInRecipes, siteUrlId, searchTerm, maxResults);
		yield put(searchActions.recipeProductFilterSearchProductsSuccess(products));
	} catch (e) {
		// yield put(searchActions.searchProductsFailed(e));
	}
}

function* recipeProductFilterSearchProducts(
	api: ProductSearchApi,
	{ payload }: ReturnType<typeof searchActions.recipeProductFilterSearchProducts>
) {
	yield call(doRecipeProductFilterSearchProducts, api, payload.siteUrlId, payload.searchTerm, payload.maxResults);
}

export function* watchRecipeProductFilterSearchProducts() {
	yield takeLatest(
		getType(searchActions.recipeProductFilterSearchProducts),
		recipeProductFilterSearchProducts,
		productSearchApi
	);
}

function* doRecipeProductFilterLoadProduct(api: SearchApi, slug: string) {
	try {
		const info = yield call(api.getProductInfoBySlug, slug);
		yield put(searchActions.recipeProductFilterLoadProductSuccess(info));
	} catch (e) {
		// yield put(searchActions.searchProductsFailed(e));
	}
}

function* recipeProductFilterLoadProduct(
	api: SearchApi,
	{ payload }: ReturnType<typeof searchActions.recipeProductFilterLoadProduct>
) {
	yield call(doRecipeProductFilterLoadProduct, api, payload.slug);
}

export function* watchRecipeProductFilterLoadProduct() {
	yield takeLatest(getType(searchActions.recipeProductFilterLoadProduct), recipeProductFilterLoadProduct, searchApi);
}

export default function* searchSaga() {
	yield fork(watchSearchArticles);
	yield fork(watchLoadMoreArticles);
	yield fork(watchSearchRecipes);
	yield fork(watchLoadMoreRecipes);
	yield fork(watchSearchProducts);
	yield fork(watchLoadMoreProducts);
	yield fork(watchSetQuery);
	yield fork(watchRecipeProductFilterSearchProducts);
	yield fork(watchRecipeProductFilterLoadProduct);
}
