import { Middleware } from 'redux';
import { getType } from 'typesafe-actions';
import { withWindow } from 'styleguide/helpers/window';
import { composeRoute } from 'utils/routing';
import { getConfig } from 'config/config';
import { RoutingState, routingActions } from 'common/components/Routing/reducers';
import { ActionTypes, State } from 'common/reducers';
import router from 'common/components/Routing/router';
import { resourceActions } from 'common/components/Resource/reducers/actions';
import { Resource } from 'common/components/Resource/types';
import { getSiteUrlInfoOrDefault, getSiteUrlId } from 'common/components/App/services';

function queryChanged(r1: RoutingState, r2: RoutingState): boolean {
	if (r1.pathname !== r2.pathname) {
		// More than just the query changed here
		return false;
	}

	return r1.query !== r2.query;
}

export const routingMiddleware: Middleware = store => next => (action: ActionTypes) => {
	const state = store.getState() as State;
	const {
		routing,
		app: { settings },
	} = state;

	if (action.type === getType(routingActions.updateUrl)) {
		withWindow(w => {
			const newRouting: RoutingState = { ...routing, ...action.payload };
			const siteUrlInfo = getSiteUrlInfoOrDefault(newRouting, settings ? settings.sites : []);
			const routeResult = router(siteUrlInfo, newRouting);
			if (routeResult || queryChanged(newRouting, routing)) {
				w.history.pushState(null, '', composeRoute(action.payload));
			}

			const { preventScroll } = action.payload;

			if (!preventScroll) {
				w.scrollTo(0, 0);
				document.body.scrollTop = 0;
				document.body.scrollLeft = 0;
			}
		});
	} else if (action.type === getType(resourceActions.setResource)) {
		withWindow(w => {
			const config = getConfig();
			const isDevelopment = config.isDevelopment;
			const resource = action.payload ? (action.payload.resource as Resource) : undefined;
			const isFrontendRendered = resource ? resource.isFrontendRendered : false;

			const url = composeRoute(routing);

			if (!isFrontendRendered) {
				if (!isDevelopment) {
					w.location.href = url;
				}
				return;
			}
		});
	} else if (action.type === getType(routingActions.updateSearch)) {
		withWindow(w => {
			if (routing.search !== action.payload.search) {
				w.history.pushState(
					null,
					'',
					composeRoute({
						pathname: routing.pathname,
						search: action.payload.search,
						hash: routing.hash,
						preventScroll: true,
					})
				);
			}
		});
	} else if (action.type === getType(routingActions.updateHash)) {
		withWindow(w => {
			if (routing.hash !== action.payload.hash) {
				w.history.pushState(
					null,
					'',
					composeRoute({
						pathname: routing.pathname,
						search: routing.search,
						hash: action.payload.hash,
						preventScroll: true,
					})
				);
			}
		});
	} else if (action.type === getType(routingActions.replaceHistoryState)) {
		withWindow(w => {
			if (action.payload) {
				w.history.replaceState(null, '', composeRoute(action.payload));
			}
		});
	} else if (action.type === getType(routingActions.historyStateChanged)) {
		const newRouting: RoutingState = { ...routing, ...action.payload };
		const siteUrlId = getSiteUrlId(newRouting, settings ? settings.sites : []);
		const newAction = Object.assign({}, action, {
			payload: {
				...action.payload,
				siteUrlId,
			},
		});
		return next(newAction);
	}

	return next(action);
};
