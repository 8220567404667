import extend from './extend';
import valio from './valio';

const themeColors = {
	maidonjalostajatBlue: '#004b8b',
};

const maidonjalostajat = extend(valio, {
	colors: {
		brandPrimary: themeColors.maidonjalostajatBlue,
	},
});

export default maidonjalostajat;
