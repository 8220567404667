export enum RecipeCreatorType {
	valio = 'Valio',
	cooperation = 'Vieraskauha',
	user = 'User',
	countrySites = 'CountrySites',
}

export type RecipeCreatorTypes =
	| RecipeCreatorType.valio
	| RecipeCreatorType.cooperation
	| RecipeCreatorType.user
	| RecipeCreatorType.countrySites;
