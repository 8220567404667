import { getType, ActionType, createStandardAction } from 'typesafe-actions';
import { Reducer } from 'redux';

export const domActions = {
	setFlatten: createStandardAction('DOM/HEADER_SET_FLATTEN')<boolean>(),
	showNavigation: createStandardAction('DOM/HEADER_SHOW_NAVIGATION')(),
	hideNavigation: createStandardAction('DOM/HEADER_HIDE_NAVIGATION')(),
	setVisibleScrollTarget: createStandardAction('DOM/SET_VISIBLE_SCROLL_TARGET')<string>(),
	registerScrollTarget: createStandardAction('DOM/REGISTER_SCROLL_TARGET')<string>(),
	deregisterScrollTarget: createStandardAction('DOM/DEREGISTER_SCROLL_TARGET')<string>(),
};

export type DomActions = ActionType<typeof domActions>;

export interface HeaderState {
	isFlattened: boolean;
	isScrolled: boolean;
}

export interface DomState {
	header: HeaderState;
	currentVisibleScrollTarget?: string;
}

export const initialDomState: DomState = {
	header: {
		isFlattened: false,
		isScrolled: false,
	},
};

const domReducer: Reducer<DomState, DomActions> = (state: DomState = initialDomState, action: DomActions): DomState => {
	switch (action.type) {
		case getType(domActions.setFlatten):
			return { ...state, header: { ...state.header, isFlattened: action.payload } };
		case getType(domActions.showNavigation):
			return { ...state, header: { ...state.header, isScrolled: false } };
		case getType(domActions.hideNavigation):
			return { ...state, header: { ...state.header, isScrolled: true } };
		case getType(domActions.setVisibleScrollTarget):
			return { ...state, currentVisibleScrollTarget: action.payload };

		default:
			return state;
	}
};

export default domReducer;
