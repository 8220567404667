import * as React from 'react';
import NormalIconWrapper, { NormalIconWrapperProps } from '../NormalIconWrapper';
import { ThemeContext } from 'styled-components';

const ContactIcon: React.FC<NormalIconWrapperProps & React.SVGAttributes<SVGElement>> = ({ color, ...props }) => {
	const theme = React.useContext(ThemeContext);

	return (
		<NormalIconWrapper {...props}>
			<path
				fill={color ?? theme.colors.brandPrimary}
				d="M18.278 24.899c-.31 0-.625-.021-.948-.064-1.038-.111-2.153-.436-3.198-.94a.999.999 0 0 1-.468-1.333 1.002 1.002 0 0 1 1.334-.47 8.001 8.001 0 0 0 2.569.758c.94.123 1.766.007 2.479-.341a4.011 4.011 0 0 0 .783-.492c.799-.67 1.059-1.404.833-2.401l-4.264-1.946-1.69 1.15a2.07 2.07 0 0 1-1.203.352 1.973 1.973 0 0 1-1.2-.458c-1.107-.93-2.207-2.042-3.268-3.307a26.87 26.87 0 0 1-2.69-3.794 1.975 1.975 0 0 1-.242-1.258c.059-.42.256-.82.555-1.125l1.428-1.464L7.91 3.229c-.942-.396-1.711-.268-2.51.403a3.457 3.457 0 0 0-.363.362c-.081.089-.142.169-.204.249-.52.716-.777 1.51-.817 2.432-.102 1.507.299 3.189 1.099 4.64.573 1.04 1.915 3.474 3.462 5.317.85 1.015 1.96 2.097 3.392 3.309a1.001 1.001 0 0 1-1.293 1.526c-1.52-1.286-2.706-2.447-3.631-3.55-1.482-1.765-2.746-3.94-3.681-5.636-.995-1.804-1.473-3.835-1.344-5.717.056-1.298.44-2.457 1.143-3.423l.073-.103c.108-.14.206-.263.312-.38.167-.19.357-.382.568-.558C5.66.805 7.383.653 9.236 1.649c.246.132.425.36.495.63l1.434 5.53a1 1 0 0 1-.252.95L9.09 10.627c.654 1.138 1.492 2.319 2.479 3.495a25.006 25.006 0 0 0 3.022 3.062l2.144-1.485a.997.997 0 0 1 .979-.083l5.198 2.373a1 1 0 0 1 .535.597c.659 1.998.21 3.667-1.334 4.963a5.54 5.54 0 0 1-.662.47 5.688 5.688 0 0 1-.5.274 6.02 6.02 0 0 1-2.674.606"
			/>
		</NormalIconWrapper>
	);
};

export default ContactIcon;
