import * as React from 'react';
import { media } from 'styleguide/helpers';
import styled from 'styled-components';

const StyledRecipeCardOverlay = styled('div')<RecipeCardOverlayProps>`
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	display: flex;
	flex-direction: column;
	justify-content: ${props => (props.showBadge ? 'space-between' : 'flex-end')};

	.organic-icon {
		position: absolute;
		top: 10px;
		right: 0px;
	}
	.novelty-icon {
		position: absolute;
		left: -14px;
		bottom: 10px;
		width: 60px;
		height: 60px;

		${media.tablet`
			width: 80px;
			height: 80px;
			bottom: 3px;
		`};
	}
`;

export interface RecipeCardOverlayProps {
	showBadge: boolean;
}

const RecipeCardOverlay: React.FC<RecipeCardOverlayProps> = ({ showBadge, children }) => (
	<StyledRecipeCardOverlay showBadge={showBadge}>{children}</StyledRecipeCardOverlay>
);

RecipeCardOverlay.defaultProps = {
	showBadge: false,
};

export default RecipeCardOverlay;
