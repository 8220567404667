import { scaleReducer, ScaleActions } from './scale';
import { composeReducers } from 'utils/composition';
import { recipeDetailsReducer } from './recipe-details';
import { RecipeRatingActions, recipeRatingReducer } from './recipe-rating';
import { StaticHighlightActions, staticHighlightReducers } from 'common/components/Widgets/reducers/static-highlights';
import { combineReducers } from 'redux';
import { RecipeShoppingListActions, recipeShoppingListReducer } from './shopping-list';
import { RecipeHighlightState, RatingState, RecipeShoppingListState, RecipeDetails } from '../interfaces';
import { CommentActions, commentReducer } from 'common/components/Comments/reducers';
import { CommentsState } from 'common/components/Comments/interfaces';
import { ResourceState } from 'common/components/Resource/reducers/reducers';
import { ResourceType } from 'common/components/Resource/types';
import { PortionNutrientsActions, portionNutrientsReducer } from './portion-nutrients';

export type RecipeActions =
	| CommentActions
	| ScaleActions
	| PortionNutrientsActions
	| RecipeRatingActions
	| StaticHighlightActions
	| RecipeShoppingListActions;

const highlightsReducer = combineReducers<RecipeHighlightState>({
	staticHighlights: staticHighlightReducers,
});

const recipeAndScale = composeReducers(
	{
		isLoading: false,
	},
	recipeDetailsReducer,
	scaleReducer,
	portionNutrientsReducer
);

export interface RecipeResourceState extends ResourceState {
	type: ResourceType.recipe;
	content: RecipeState;
}

export interface RecipeState {
	recipeDetails: RecipeDetails;
	commentsState: CommentsState;
	ratingState: RatingState;
	highlights: RecipeHighlightState;
	shoppingList: RecipeShoppingListState;
}

export interface ProfessionalRecipeState {
	recipeDetails: RecipeDetails;
	shoppingList: RecipeShoppingListState;
}

export const recipeReducer = combineReducers<RecipeState>({
	shoppingList: recipeShoppingListReducer,
	recipeDetails: recipeAndScale,
	ratingState: recipeRatingReducer,
	highlights: highlightsReducer,
	commentsState: commentReducer,
});
