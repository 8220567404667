import * as React from 'react';
import { Fragment } from 'react';
import SearchResultGroup from './SearchResultGroup';
import { useIntl } from 'react-intl';
import Divider from 'styleguide/components/Divider/Divider';
import { DividerSize, DividerDirection } from 'styleguide/components/Divider/Divider';
import { SearchResultCollection, NavLinkItemProps } from '../../interfaces';
import { SearchType } from 'pagetypes/Search/types';
import { connect, MapStateToProps } from 'react-redux';
import { State } from 'common/reducers';
import { addSiteUrlPrefix, getSettingValue } from 'common/components/App/services';

export interface SearchCategoriesProps {
	keyword: string;
	loading?: boolean;
	recipeResults?: SearchResultCollection;
	professionalRecipeResults?: SearchResultCollection;
	productResults?: SearchResultCollection;
	contentResults?: SearchResultCollection;
	hideSearchCategoryTitle?: boolean;
	categoryClicked?: () => void;
	itemClicked?: (item: NavLinkItemProps, searchType?: SearchType) => void;
	siteUrlPrefix: string;
	searchParams?: string;
}

export interface SearchCategoriesStateProps {
	recipeSearchUrl: string;
	productSearchUrl: string;
	searchQuery: string;
}

const getResults = (coll?: SearchResultCollection) => {
	if (!coll) {
		return {
			count: 0,
			items: [],
			show: false,
		};
	}

	return {
		...coll,
		show: true,
	};
};

const SearchCategories: React.FC<SearchCategoriesProps & SearchCategoriesStateProps> = ({
	keyword,
	recipeResults,
	professionalRecipeResults,
	productResults,
	contentResults,
	loading,
	itemClicked,
	categoryClicked,
	hideSearchCategoryTitle,
	siteUrlPrefix,
	searchParams,
	searchQuery,
	productSearchUrl,
	recipeSearchUrl,
}) => {
	const intl = useIntl();
	const keywordEncoded = encodeURIComponent(keyword);
	const groups = [
		{
			searchType: SearchType.RECIPE,
			searchUrl: addSiteUrlPrefix(
				siteUrlPrefix,
				`${recipeSearchUrl}?${searchQuery}=${keywordEncoded}${!!searchParams ? `&${searchParams}` : ''}`
			),
			titleKey: 'search_search_recipes_by_keyword',
			...getResults(recipeResults),
		},
		{
			searchType: SearchType.PROFESSIONAL_RECIPE,
			searchUrl: `${siteUrlPrefix}ammattilaiset/reseptit/haku/?${searchQuery}=${keywordEncoded}`,
			titleKey: 'search_search_pro_recipes_by_keyword',
			...getResults(professionalRecipeResults),
		},
		{
			searchType: SearchType.PRODUCT,
			searchUrl: addSiteUrlPrefix(siteUrlPrefix, `${productSearchUrl}?${searchQuery}=${keywordEncoded}`),
			titleKey: 'search_search_products_by_keyword',
			...getResults(productResults),
		},
		{
			searchType: SearchType.ARTICLE,
			searchUrl: addSiteUrlPrefix(siteUrlPrefix, `${siteUrlPrefix}artikkelit/?${searchQuery}=${keywordEncoded}`),
			titleKey: 'search_search_content_by_keyword',
			...getResults(contentResults),
		},
	];

	const visibleGroups = groups.filter(g => g.show);

	return (
		<Fragment>
			{visibleGroups.map((g, i) => {
				const countText = loading ? '' : ` (${g.count})`;
				return (
					<Fragment key={i}>
						<SearchResultGroup
							href={g.searchUrl.toLowerCase()}
							title={intl.formatMessage({ id: g.titleKey }, { keyword, count: g.count }) + countText}
							items={g.items}
							itemClicked={itemClicked}
							categoryClicked={categoryClicked}
							hideSearchCategory={hideSearchCategoryTitle}
							searchType={g.searchType}
						/>
						{i < visibleGroups.length - 1 ? (
							<Divider size={DividerSize.full} direction={DividerDirection.horizontal} />
						) : null}
					</Fragment>
				);
			})}
		</Fragment>
	);
};

const mapStateToProps: MapStateToProps<SearchCategoriesStateProps, SearchCategoriesProps, State> = (
	state
): SearchCategoriesStateProps => {
	return {
		productSearchUrl: getSettingValue(state, 'ProductSearch', 'Url') || '/tuotteet/haku/',
		recipeSearchUrl: getSettingValue(state, 'RecipeSearch', 'Url') || '/reseptit/haku/',
		searchQuery: getSettingValue(state, 'Search', 'SearchQuery') || 'haku',
	};
};

export default connect(mapStateToProps, {})(SearchCategories);
