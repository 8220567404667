import * as React from 'react';
import styled from 'styled-components';
import { media } from '../../../helpers';
import { LazyImage } from '../../../components/LazyImage';
import { HighlightCardWrapperProps } from './HighlightCardWrapper';
import { ImageSource } from '../../../interfaces';

const ImageWrapper = styled('div')<HighlightCardWrapperProps>`
	flex: 0 0 auto;
	width: 135px;
	height: ${props => (props.hasBorder ? '90px' : '92px')};
	position: relative;

	${media.tablet<HighlightCardWrapperProps>`
		${props =>
			props.isSmall
				? ''
				: `
			width: 176px;
			height: ${props.hasBorder ? '118px' : '120px'};
		`}
	`};

	img {
		width: 100%;
		height: 100%;
		border-radius: 4px;
	}
`;

const BadgeTextWrapper = styled.div`
	position: absolute;
	background-color: ${props => props.theme.colors.brandTertiary};
	height: 20px;
	border-radius: 4px 0 4px 0;
	text-align: left;
	top: 0;
	left: 0;
`;

const BadgeText = styled.div`
	${props => props.theme.typography.heading};
	padding: 0 10px;
	color: ${props => props.theme.colors.white};
	font-size: 12px;
	line-height: 20px;
	text-transform: uppercase;
`;

export interface HighlightCardImageProps extends HighlightCardWrapperProps {
	badgeText?: string;
	imgSrc: string | ImageSource[];
	altText: string;
}

const HighlightCardImage: React.FC<HighlightCardImageProps> = ({ isSmall, hasBorder, imgSrc, altText, badgeText }) => {
	return (
		<ImageWrapper isSmall={isSmall} hasBorder={hasBorder}>
			{badgeText && (
				<BadgeTextWrapper>
					<BadgeText>{badgeText}</BadgeText>
				</BadgeTextWrapper>
			)}
			<LazyImage src={imgSrc} alt={altText} />
		</ImageWrapper>
	);
};

export default HighlightCardImage;
