import * as React from 'react';
import styled from 'styled-components';
import BackgroundVideo from 'styleguide/components/PicturesAndVideos/BackgroundVideo';
import { media } from 'styleguide/helpers';
import { VideoSource } from 'styleguide/types/mediaTypes';
import { ToggleMediaProps, toggleMedia } from 'common/components/Media';
import { BackgroundVideoProps } from 'styleguide/components/PicturesAndVideos/BackgroundVideo/BackgroundVideo';

interface StyledBackgroundVideoProps {
	targetMedia?: ToggleMediaProps;
}

const StyledBackgroundVideo = styled(({ targetMedia, ...rest }) => <BackgroundVideo {...rest} />)<
	StyledBackgroundVideoProps & BackgroundVideoProps & React.HTMLAttributes<HTMLVideoElement>
>`
	${({ targetMedia }) => {
		if (targetMedia) {
			return toggleMedia('block', targetMedia.toggleType, targetMedia.breakpoint);
		}
		return null;
	}};

	height: 100%;

	& > div {
		height: 100%;
		position: relative;
	}

	video {
		max-height: 100%;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		object-fit: cover;

		${media.desktop`
		max-width: 100%;
		max-height: none;
	`};

		${media.desktop1440`
		max-height: 100%;
	`}
	}
`;

interface Props {
	sources: VideoSource[];
}

const HeroBackgroundVideo: React.FC<Props> = ({ sources }) => {
	return (
		<>
			{sources.map((source, index) => (
				<StyledBackgroundVideo
					key={`bg-video-${source.src}-${index}`}
					targetMedia={source.targetMedia}
					sources={[source]}
					playOnMount
				/>
			))}
		</>
	);
};

export default HeroBackgroundVideo;
