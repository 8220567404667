import * as React from 'react';
import styled from 'styled-components';
import { media } from '../../../../helpers';
import { RecipeCreatorType, RecipeCreatorTypes } from '../../../../types';

const StyledRecipeCardImageBadgeWrapper = styled('div')<RecipeCardImageBadgeWrapperProps>`
	${props => props.theme.typography.heading};
	height: 20px;
	border-radius: 2px;
	color: ${props =>
		props.type === RecipeCreatorType.user ? props.theme.colors.brandPrimary : props.theme.colors.white};
	font-size: 11px;
	text-align: left;
	text-transform: uppercase;
	${media.phone`font-size: 12px;`};
`;

export interface RecipeCardImageBadgeWrapperProps {
	type: RecipeCreatorTypes;
}

const RecipeCardImageBadgeWrapper: React.FC<RecipeCardImageBadgeWrapperProps> = ({ type, children }) => (
	<StyledRecipeCardImageBadgeWrapper type={type}>{children}</StyledRecipeCardImageBadgeWrapper>
);

export default RecipeCardImageBadgeWrapper;
