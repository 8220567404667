import { Reducer } from 'redux';
import { getType, createStandardAction, ActionType } from 'typesafe-actions';
import { NavigationItemWithLinks, NavigationShape, SecondaryNavigationApiShape } from './services';
import { RoutingActions, routingActions, RoutingParams } from '../Routing/reducers';
import { LoginActions, loginActions } from '../Login/reducers/actions';
import { getNewState } from 'common/reducers/utils';

export type NavigationLayoutType =
	| ''
	| 'ValioFi'
	| 'ValioComGroup'
	| 'ValioComCompanies'
	| 'ValioComCountrySites'
	| 'CountrySite'
	| 'Oddlygood'
	| 'Professional'
	| 'FinlandiaCheese';

export interface NavigationState {
	layout: NavigationLayoutType;
	primaryNavigation: NavigationItemWithLinks[];
	secondaryNavigation?: SecondaryNavigationApiShape;
	isLoading: boolean;
	isOpen: boolean;
	error?: string | null;
	topOffset: number;
}

export const initialNavigationState: NavigationState = {
	layout: '',
	primaryNavigation: [],
	isLoading: true,
	error: null,
	isOpen: false,
	topOffset: 0,
};

const navigationReducer: Reducer<NavigationState, NavigationActions | RoutingActions | LoginActions> = (
	state = initialNavigationState,
	action
): NavigationState => {
	switch (action.type) {
		case getType(navigationActions.fetchNavigation):
			return { ...state, isLoading: state.primaryNavigation?.length === 0, error: null };

		case getType(navigationActions.setNavigation):
			const newState = {
				...state,
				isLoading: false,
				primaryNavigation: getNewState(state.primaryNavigation, action.payload.primaryNavigation),
				secondaryNavigation: getNewState(state.secondaryNavigation, action.payload.secondaryNavigation),
				layout: action.payload.layout,
			};
			return getNewState(state, newState);

		case getType(navigationActions.fetchFailed):
			return { ...state, isLoading: false, error: action.payload };

		case getType(navigationActions.openNavigation):
			return { ...state, isOpen: true };

		case getType(navigationActions.closeNavigation):
			return { ...state, isOpen: false };

		case getType(navigationActions.setTopOffset):
			return { ...state, topOffset: action.payload };

		case getType(routingActions.updateUrl):
		case getType(loginActions.openLogin):
		case getType(loginActions.doLogOut):
			return { ...state, isOpen: false };

		default:
			return state;
	}
};

export default navigationReducer;

export const navigationActions = {
	openNavigation: createStandardAction('NAVIGATION/OPEN_NAVIGATION')(),
	closeNavigation: createStandardAction('NAVIGATION/CLOSE_NAVIGATION')(),
	fetchNavigation: createStandardAction('NAVIGATION/FETCH_NAVIGATION')<{ siteUrlId: string; routing: RoutingParams }>(),
	setNavigation: createStandardAction('NAVIGATION/SET_NAVIGATION')<NavigationShape>(),
	fetchFailed: createStandardAction('NAVIGATION/FETCH_NAVIGATION_FAILED')<string>(),
	setTopOffset: createStandardAction('NAVIGATION/SET_TOP_OFFSET')<number>(),
};

export type NavigationActions = ActionType<typeof navigationActions>;
