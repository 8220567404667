import { ResourceType } from 'common/components/Resource/types';
import { Reducer } from 'redux';
import { ResourceActions, resourceActions } from 'common/components/Resource/reducers/actions';
import { getType } from 'typesafe-actions';
import { CmsContentState } from './interfaces';

const cmsContentInitialState: CmsContentState = {
	contentFamily: '',
	leadText: '',
	author: null,
	isHealthCareContent: false,
};

export const cmsContentReducer: Reducer<CmsContentState, ResourceActions> = (
	state = cmsContentInitialState,
	action
): CmsContentState => {
	switch (action.type) {
		case getType(resourceActions.setResource):
			if (action.payload.resource.type === ResourceType.cmsContent) {
				const { contentFamily, leadText, author, isHealthCareContent } = action.payload.resource.content;
				return {
					...state,
					contentFamily,
					leadText,
					author,
					isHealthCareContent,
				};
			}
		default:
			return state;
	}
};
