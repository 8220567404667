import * as React from 'react';
import { SearchFilterItem } from 'pagetypes/Search/interfaces';
import { NumberMap, NameAndValue } from 'common/interfaces/common';
import { isFilterSelected } from 'pagetypes/Search/utils';
import FilterPill from 'styleguide/components/Selection/FilterPill/FilterPill';

interface Props {
	filters: SearchFilterItem[] | null;
	filterCount?: NumberMap;
	selectedFilters: NameAndValue[];
	onFilterClick: (filterName: string, filterValue: string, selected: boolean) => void;
	isResultsLoading?: boolean;
	disableCount?: boolean;
}

const PillFilters: React.FC<Props> = ({
	filters,
	filterCount,
	selectedFilters,
	isResultsLoading,
	disableCount,
	onFilterClick,
}) => {
	if (!filters) {
		return null;
	}

	const createClickHandler = (filterName: string) => {
		return (filterValue: string, selected: boolean) => {
			onFilterClick(filterName, filterValue, selected);
		};
	};

	return (
		<>
			{filters.map(filter => {
				const { title, name, value } = filter;
				const count = disableCount ? undefined : (filterCount && filterCount[value]) || 0;
				const selected = isFilterSelected(filter, selectedFilters);

				return (
					<FilterPill
						key={`${title}-${value}`}
						itemId={value}
						onPillClick={createClickHandler(name)}
						selected={selected}
						disabled={count === 0 || (!selected && !!isResultsLoading)}
						amount={count}>
						{title}
					</FilterPill>
				);
			})}
		</>
	);
};

export default PillFilters;
