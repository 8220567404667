import * as React from 'react';
import { InputShape } from 'common/components/Forms/interfaces';
import { validateRequired } from 'common/components/Forms/helpers';
import injectForm, { InjectedFormProps } from 'common/components/Forms/Form';
import InputWithIcon from 'styleguide/components/Inputs/InputWithIcon';
import { KeyIcon } from 'styleguide/components/Icons';
import { useIntl, FormattedMessage } from 'react-intl';
import { ProfileShape } from '../reducers/profile';
import { OutlinedButton } from 'styleguide/components/Buttons/OutlinedButton';
import Alert, { AlertType } from 'styleguide/components/Typography/Alert';
import styled from 'styled-components';
import SmallHeader from 'styleguide/components/Typography/SmallHeader';
import { media } from 'styleguide/helpers';

const PasswordForm = styled.form`
	margin-top: 30px;

	${media.tablet`
    margin-left: 15px;
    margin-top: 0;
  `};

	& > .form-field + .form-field {
		margin-top: ${({ theme }) => theme.grid.gutterInPx(2)};

		&.separate {
			margin-top: ${({ theme }) => theme.grid.gutterInPx(4)};
		}
	}

	& > button {
		margin-top: ${({ theme }) => theme.grid.gutterInPx(2)};
		width: 100%;
	}
`;

const Notification = styled(Alert)`
	margin-top: ${({ theme }) => theme.grid.gutterInPx(2)};
`;

export interface PasswordChangeFormFields {
	oldPassword: InputShape<string>;
	newPassword: InputShape<string>;
	confirmPassword: InputShape<string>;
}

export interface PasswordChangeFormValidators {
	oldPassword: Array<(value: string) => string>;
	newPassword: Array<(value: string) => string>;
	confirmPassword: Array<(value: string) => string>;
}

export interface Props {
	userProfile: ProfileShape;
}

const PasswordChangeForm: React.FC<Props & InjectedFormProps<PasswordChangeFormFields>> = ({
	userProfile,
	fields,
	onInputFocus,
	onInputChange,
	onInputBlur,
	onSubmit,
	isValid,
}) => {
	if (userProfile.isLoading) {
		return null;
	}

	const intl = useIntl();

	const onFocus = (event: React.FormEvent<HTMLInputElement>) => {
		const { value, name } = event.currentTarget;
		onInputFocus(value, name);
	};

	const onBlur = (event: React.FormEvent<HTMLInputElement>) => {
		const { value, name } = event.currentTarget;
		onInputBlur(value, name);
	};

	const onChange = (event: React.FormEvent<HTMLInputElement>) => {
		const { value, name } = event.currentTarget;
		onInputChange(value, name, true);
	};

	const { oldPassword, newPassword, confirmPassword } = fields;
	const { profile, notifications } = userProfile;

	if (!profile) {
		return null;
	}

	return (
		<PasswordForm onSubmit={onSubmit} noValidate>
			<SmallHeader>
				<FormattedMessage id="user_profile_change_password" />
			</SmallHeader>
			<InputWithIcon
				icon={<KeyIcon />}
				type="password"
				name="oldPassword"
				placeholder={intl.formatMessage({ id: 'user_profile_old_password' })}
				value={oldPassword.value}
				validationError={oldPassword.touched && !oldPassword.focused && oldPassword.error ? oldPassword.error : ''}
				required={true}
				onChange={onChange}
				onFocus={onFocus}
				onBlur={onBlur}
			/>
			<InputWithIcon
				icon={<KeyIcon />}
				type="password"
				className="separate"
				name="newPassword"
				placeholder={intl.formatMessage({ id: 'user_profile_new_password' })}
				value={newPassword.value}
				validationError={newPassword.touched && !newPassword.focused && newPassword.error ? newPassword.error : ''}
				required={true}
				onChange={onChange}
				onFocus={onFocus}
				onBlur={onBlur}
			/>
			<InputWithIcon
				icon={<KeyIcon />}
				type="password"
				name="confirmPassword"
				placeholder={intl.formatMessage({ id: 'user_profile_confirm_password' })}
				value={confirmPassword.value}
				validationError={
					confirmPassword.touched && !confirmPassword.focused && confirmPassword.error ? confirmPassword.error : ''
				}
				required={true}
				onChange={onChange}
				onFocus={onFocus}
				onBlur={onBlur}
			/>

			{notifications &&
				((notifications.changePasswordSuccess && (
					<Notification
						type={AlertType.Success}
						highlightedText={intl.formatMessage({ id: notifications.changePasswordSuccess })}
					/>
				)) ||
					(notifications.changePasswordError && (
						<Notification type={AlertType.Error} highlightedText={notifications.changePasswordError.title}>
							{notifications.changePasswordError.message}
						</Notification>
					)))}

			<OutlinedButton buttonStyle="secondary" type="submit" disabled={!isValid} size="medium">
				Tallenna
			</OutlinedButton>
		</PasswordForm>
	);
};

export const passwordAgainValidator = (value: string, fields?: PasswordChangeFormFields): string => {
	if (fields && fields.newPassword) {
		if (value !== fields.newPassword.value) {
			return 'component_login_registration_validation_password_again_invalid';
		}
	}
	return '';
};

const passwordChangeFields: PasswordChangeFormFields = {
	oldPassword: { value: '' },
	newPassword: { value: '' },
	confirmPassword: { value: '' },
};

const passwordChangeValidators: PasswordChangeFormValidators = {
	oldPassword: [validateRequired('user_profile_password_change_enter_old_password')],
	newPassword: [validateRequired('user_profile_password_change_enter_new_password')],
	confirmPassword: [validateRequired('user_profile_password_change_enter_new_password_again'), passwordAgainValidator],
};

export default injectForm(passwordChangeFields, passwordChangeValidators)(PasswordChangeForm);
