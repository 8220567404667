import * as React from 'react';
import styled from 'styled-components';
import EmergencyNotificationItem from './EmergencyNotificationItem';
import { EmergencyNotification } from '../Resource/interfaces';

const EmergencyNotificationsWrapper = styled.div`
	width: 100%;
	position: relative;
	z-index: ${props => props.theme.zIndices.navigation + 10};
	pointer-events: auto;
`;

export interface EmergencyNotificationsProps {
	notifications: EmergencyNotification[];
	onDismissNotification: (notificationId: string) => void;
}

class EmergencyNotifications extends React.Component<EmergencyNotificationsProps> {
	public render() {
		return <EmergencyNotificationsWrapper>{this.renderNotifications()}</EmergencyNotificationsWrapper>;
	}

	private renderNotifications() {
		const { notifications, onDismissNotification } = this.props;
		return notifications.map((notification, index) => {
			if (notification.isDismissed) {
				return null;
			}
			return <EmergencyNotificationItem key={index} {...notification} onDismiss={onDismissNotification} />;
		});
	}
}

export default EmergencyNotifications;
