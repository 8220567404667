import * as React from 'react';
import SearchCategoryTitle from './SearchCategoryTitle';
import styled from 'styled-components';
import ArrowRightIcon from 'styleguide/components/Icons/NormalIcons/ArrowRightIcon/ArrowRightIcon';
import { SearchType } from 'pagetypes/Search/types';
import injectRoutable, { InjectedRoutableProps } from '../../../Routing/Routable';

const SearchCategoryWrapper = styled.a`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	text-decoration: none;
`;

export interface SearchCategoryProps {
	href: string;
	title: string;
	searchType: SearchType;
	categoryClicked?: () => void;
}

const SearchCategory: React.FC<SearchCategoryProps & InjectedRoutableProps> = ({
	title,
	href,
	onLinkClick,
	searchType,
	categoryClicked,
}) => {
	const onClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
		if ([SearchType.RECIPE, SearchType.PRODUCT].indexOf(searchType) > -1) {
			onLinkClick(event);
			categoryClicked && categoryClicked();
		}
	};

	return (
		<SearchCategoryWrapper href={href} onClick={onClick}>
			<SearchCategoryTitle>{title}</SearchCategoryTitle>
			<ArrowRightIcon />
		</SearchCategoryWrapper>
	);
};

export default injectRoutable(SearchCategory);
