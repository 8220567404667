import * as React from 'react';
import { Component } from 'react';
import { connect, MapStateToProps } from 'react-redux';
import { State } from 'common/reducers';
import { LayoutProps } from 'common/layouts/interfaces';
import Recipes from './layouts/Recipes';
import ShoppingList from './layouts/ShoppingList';
import Details from './layouts/Details';
import { Layouts } from 'common/layouts/types';
import WidgetZone from 'common/components/Widgets/WidgetZone';
import { MyContentResourceState } from './interfaces';
import { Helmet } from 'react-helmet';
import { PageTitleWrapper } from 'common/components/General';
import Hero from 'styleguide/components/HeroBanners/Hero';
import { getHeroMediaSource } from 'utils/media';
import { UserPageContentsWrapper, TitleWrapper, UserPageTitle, TitleBlockWrapper } from 'pagetypes/User/UserPage';
import ContentCard from 'common/components/General/ContentCard';
import Tabs from 'styleguide/components/Tabs/Tabs';
import { UserPageSlug } from 'common/constants/user';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import injectRoutable, { InjectedRoutableProps } from 'common/components/Routing/Routable';
import HeartIcon from 'styleguide/components/Icons/NormalIcons/HeartIcon/HeartIcon';
import UserIcon from 'styleguide/components/Icons/NormalIcons/UserIcon/UserIcon';
import ShoppinglistIcon from 'styleguide/components/Icons/NormalIcons/ShoppinglistIcon/ShoppinglistIcon';
import { UserState } from 'pagetypes/User/reducers/reducers';
import { loginActions } from 'common/components/Login/reducers/actions';
import { LoginModalFormType } from 'common/components/Login/types';
import { RoutingState } from 'common/components/Routing/reducers';
import { pushOtherPageLoad } from 'utils/dataLayer';
import styled from 'styled-components';
import { media } from 'styleguide/helpers';

type SupportedLayouts = 'Recipes' | 'ShoppingList' | 'Details';

const HeroLower = styled(Hero)`
	${media.tablet`
		height: 350px;
	`};
	${media.desktop`
		height: 350px;
	`};
	${media.desktop1440`
		height: 350px;
  `};
`;

const layouts: Layouts<SupportedLayouts> = {
	['Recipes']: Recipes,
	['ShoppingList']: ShoppingList,
	['Details']: Details,
};

export interface MyContentStateProps extends LayoutProps {
	title?: string;
	heroImageDesktop?: string;
	heroImageMobile?: string;
	heroImageOffsetY: number;
	heroMediaType?: string;
	userSession: UserState | null;
}

type Props = MyContentStateProps & MyContentDispatchProps & InjectedRoutableProps & WrappedComponentProps;

class MyContent extends Component<Props> {
	public componentDidMount() {
		pushOtherPageLoad();
	}

	public componentDidUpdate(prevProps: Props) {
		if (!prevProps.userSession && this.props.userSession) {
			const { userSession, openLogin } = this.props;
			if (userSession && !userSession.isLoggedIn) {
				openLogin(LoginModalFormType.LOGIN);
			}
		}
		const prevSlug = this.getSlug(prevProps.routing);
		const slug = this.getSlug(this.props.routing);
		if (prevSlug !== slug) {
			pushOtherPageLoad();
		}
	}

	public render() {
		const { layout, title, heroImageDesktop, heroImageMobile, heroImageOffsetY, heroMediaType, ...rest } = this.props;
		const Layout = layout ? layouts[layout] : layouts.Details;
		const slug = this.getSlug(this.props.routing);
		const heroMedia = getHeroMediaSource(heroMediaType, heroImageDesktop, heroImageMobile, 'my-content-hero');

		return (
			<>
				<Helmet>
					<meta name="robots" content="noindex" />
				</Helmet>
				<HeroLower src={heroMedia.mediaSrc} mediaType={heroMedia.mediaType} offsetY={heroImageOffsetY}>
					<TitleWrapper>
						<UserPageTitle>{title}</UserPageTitle>
					</TitleWrapper>
				</HeroLower>
				<ContentCard />
				<TitleBlockWrapper>
					<PageTitleWrapper>
						<Tabs size="large" tabs={this.getTabs(slug)} onTabClick={this.onTabClick} useExternalProps={true} />
					</PageTitleWrapper>
				</TitleBlockWrapper>
				<UserPageContentsWrapper>
					<Layout {...rest} />
				</UserPageContentsWrapper>
				<WidgetZone name="BottomFullWidth" isFullWidth={true} />
			</>
		);
	}

	private withoutTrailingSlash(s: string) {
		return s.endsWith('/') ? s.substring(0, s.length - 1) : s;
	}

	private getSlug(routing: RoutingState) {
		const path = routing.pathname;
		const parts = this.withoutTrailingSlash(path).split('/');
		const isDraft = parts[parts.length - 1] === 'luonnos';
		const slug = isDraft ? parts[parts.length - 2] : parts[parts.length - 1];
		return slug as UserPageSlug;
	}

	private getFullUrl(url: string) {
		const path = this.withoutTrailingSlash(this.props.routing.pathname);
		const parts = path.split('/');
		const isDraft = parts[parts.length - 1] === 'luonnos';
		const fullUrl =
			(isDraft
				? [...parts.slice(0, parts.length - 2), url, 'luonnos']
				: [...parts.slice(0, parts.length - 1), url]
			).join('/') + '/';
		return fullUrl;
	}

	private onTabClick = (url: string) => {
		const fullUrl = this.getFullUrl(url);
		this.props.navigateTo({ pathname: fullUrl });
	};

	private getTabs = (slug: string) => [
		{
			label: this.props.intl.formatMessage({ id: 'global_recipes' }),
			url: UserPageSlug.recipes,
			isActive: slug === UserPageSlug.recipes,
			icon: HeartIcon,
		},
		{
			label: this.props.intl.formatMessage({ id: 'user_shopping_list' }),
			url: UserPageSlug.shoppingList,
			isActive: slug === UserPageSlug.shoppingList,
			icon: ShoppinglistIcon,
			identifier: 'shoppinglist-target',
		},
		{
			label: this.props.intl.formatMessage({ id: 'user_user_details' }),
			url: UserPageSlug.userDetails,
			isActive: slug === UserPageSlug.userDetails,
			icon: UserIcon,
		},
	];
}

const mapStateToProps: MapStateToProps<MyContentStateProps, {}, State> = ({
	user,
	resource,
}: State): MyContentStateProps => {
	const {
		title,
		heroImageDesktop,
		heroImageMobile,
		heroImageOffsetY,
		heroMediaType,
	} = resource as MyContentResourceState;
	return {
		title,
		heroImageDesktop,
		heroImageMobile,
		heroImageOffsetY,
		heroMediaType,
		userSession: user,
	};
};

interface MyContentDispatchProps {
	openLogin: typeof loginActions.openLogin;
}

export default connect(mapStateToProps, {
	openLogin: loginActions.openLogin,
})(injectRoutable(injectIntl(MyContent)));
