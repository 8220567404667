export const lighten = (hexColor: string, amount: number) => {
	const hsl = toHsl(hexColor);
	if (hsl === null) {
		return hexColor;
	}
	hsl.lightness += amount;
	return toHslString(hsl);
};

export const isDarkColor = (hexColor: string): boolean => {
	const hsl = toHsl(hexColor);
	return !!hsl && hsl.lightness < 35;
};

export const darken = (hexColor: string, amount: number): string => {
	const hsl = toHsl(hexColor);
	if (hsl === null) {
		return hexColor;
	}
	hsl.lightness -= amount;
	return toHslString(hsl);
};

export const toRgb = (hex: string): { red: number; green: number; blue: number } | null => {
	const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

	if (result === null) {
		return null;
	}

	const red = parseInt(result[1], 16);
	const green = parseInt(result[2], 16);
	const blue = parseInt(result[3], 16);

	return { red, green, blue };
};

export const toRgbString = (hex: string, alpha?: number): string => {
	const rgb = toRgb(hex);

	if (rgb) {
		const { red, green, blue } = rgb;
		const alphaVal = alpha ? alpha / 100 : 1;
		return `rgba(${red}, ${green}, ${blue}, ${alphaVal})`;
	}

	return '';
};

export const toHslString = (hsl: { hue: number; saturation: number; lightness: number }): string => {
	return hsl ? `hsl(${hsl.hue},${hsl.saturation}%,${hsl.lightness}%)` : '';
};

export const toHsl = (hex: string): { hue: number; saturation: number; lightness: number } | null => {
	const rgb = toRgb(hex);

	if (rgb === null) {
		return null;
	}

	const { red, green, blue } = rgb;

	const rgbMax = Math.max(red, green, blue) / 255;
	const rgbMin = Math.min(red, green, blue) / 255;

	const actualRed = red / 255;
	const actualGreen = green / 255;
	const actualBlue = blue / 255;

	const hsl = {
		hue: 0,
		saturation: 0,
		lightness: (rgbMax + rgbMin) / 2,
	};

	if (rgbMax === rgbMin) {
		hsl.hue = hsl.saturation = 0; // achromatic
	} else {
		const maxMinSub = rgbMax - rgbMin;
		hsl.saturation = hsl.lightness > 0.5 ? maxMinSub / (2 - rgbMax - rgbMin) : maxMinSub / (rgbMax + rgbMin);

		switch (rgbMax) {
			case actualRed:
				hsl.hue = (actualGreen - actualBlue) / maxMinSub + (actualGreen < actualBlue ? 6 : 0);
				break;
			case actualGreen:
				hsl.hue = (actualBlue - actualRed) / maxMinSub + 2;
				break;
			case actualBlue:
				hsl.hue = (actualRed - actualGreen) / maxMinSub + 4;
				break;
		}
		hsl.hue /= 6;
	}

	hsl.saturation = hsl.saturation * 100;
	hsl.saturation = Math.round(hsl.saturation);
	hsl.lightness = hsl.lightness * 100;
	hsl.lightness = Math.round(hsl.lightness);
	hsl.hue = Math.round(360 * hsl.hue);

	return hsl;
};
