import { getType } from 'typesafe-actions';
import {
	SearchState,
	ProductSearchApiProduct,
	ArticleSearchApiArticle,
	RecipeSearchApiRecipe,
	RecipeProductFilterState,
	SearchProductCard,
} from '../interfaces';
import { Reducer } from 'redux';
import { RecipeCardInterface } from 'styleguide/interfaces/cardInterfaces';
import { RecipeCreatorType } from 'styleguide/types/recipeTypes';
import { SearchType } from 'pagetypes/Search/types';
import { getRoutingStateFromCurrentUrl } from 'common/components/Routing/reducers';
import { ResourceType } from 'common/components/Resource/types';
import { ResourceActions, resourceActions } from 'common/components/Resource/reducers/actions';
import { SearchActions, searchActions } from './search-actions';
import { NumberMap } from 'common/interfaces/common';
import includes from 'lodash/includes';
import { parseProductSearchFilterToAvailableFilter } from '../utils';
import { ArticleCardInterface } from 'styleguide/interfaces/cardInterfaces';

export const inititialSearchState: SearchState = {
	isLoading: true,
	isResultsLoading: false,
	query: getInitialQuery(),
	count: 0,
	searchType: SearchType.ARTICLE,
	recipes: [],
	products: [],
	articles: [],
	availableFilters: [],
	isProfessionalSearch: false,
	filterCount: {},
	otherFilters: [],
};

export function getInitialQuery() {
	const routingState = getRoutingStateFromCurrentUrl();
	if (routingState.query && routingState.query.hasOwnProperty('haku')) {
		const query = routingState.query as { haku: string[] };
		return query.haku[0];
	}
	return '';
}

function resourceTypeToSearchType(resourceType: ResourceType) {
	switch (resourceType) {
		case ResourceType.recipeSearch:
			return SearchType.RECIPE;
		case ResourceType.productSearch:
			return SearchType.PRODUCT;
		case ResourceType.articleSearch:
			return SearchType.ARTICLE;
		default:
			return undefined;
	}
}

export const searchReducer: Reducer<SearchState, SearchActions | ResourceActions> = (
	state = inititialSearchState,
	action
) => {
	if (action.type === getType(searchActions.setQuery)) {
		return { ...state, query: action.payload.query };
	}

	if (action.type === getType(resourceActions.setResource)) {
		const searchType = resourceTypeToSearchType(action.payload.resource.type);

		if (action.payload.resource.type === ResourceType.articleSearch) {
			return {
				...state,
				searchType: searchType || SearchType.ARTICLE,
				availableFilters: action.payload.resource.content.searchFilters,
				isProfessionalSearch: action.payload.resource.content.isProfessionalSearch || false,
				language: action.payload.resource.language,
			};
		}

		if (action.payload.resource.type === ResourceType.recipeSearch) {
			return {
				...state,
				searchType: searchType || SearchType.RECIPE,
				availableFilters: action.payload.resource.content.searchFilters,
				isProfessionalSearch: action.payload.resource.content.isProfessionalSearch || false,
				aspectFilters: action.payload.resource.content.aspectFilters,
				language: action.payload.resource.language,
			};
		}

		if (action.payload.resource.type === ResourceType.productSearch) {
			return {
				...state,
				searchType: searchType || SearchType.PRODUCT,
				availableFilters: action.payload.resource.content.searchFilters.map(parseProductSearchFilterToAvailableFilter),
				language: action.payload.resource.language,
			};
		}
	}

	if (action.type === getType(searchActions.setSearchType)) {
		return { ...state, searchType: action.payload };
	}

	if (action.type === getType(searchActions.searchArticles)) {
		return { ...state, isLoading: true };
	}

	if (action.type === getType(searchActions.loadMoreArticles)) {
		return {
			...state,
			isResultsLoading: true,
		};
	}

	if (action.type === getType(searchActions.searchArticlesSucceeded)) {
		return {
			...state,
			isLoading: false,
			isResultsLoading: false,
			count: action.payload.result.count,
			articles: searchResultsArticlesReducer(state.articles as ArticleCardInterface[], action),
			filterCount: searchResultFilterCount(state.filterCount, action),
		};
	}

	if (action.type === getType(searchActions.searchRecipes)) {
		return {
			...state,
			isLoading: true,
		};
	}

	if (action.type === getType(searchActions.loadMoreRecipes)) {
		return {
			...state,
			isResultsLoading: true,
		};
	}

	if (action.type === getType(searchActions.searchRecipesSucceeded)) {
		return {
			...state,
			isLoading: false,
			isResultsLoading: false,
			count: action.payload.result.count,
			recipes: searchResultsRecipesReducer(state.recipes as RecipeCardInterface[], action),
			otherFilters: action.payload.result.otherFilters,
			filterCount: searchResultFilterCount(state.filterCount, action),
		};
	}

	if (action.type === getType(searchActions.searchProducts)) {
		return {
			...state,
			isLoading: true,
		};
	}

	if (action.type === getType(searchActions.loadMoreProducts)) {
		return {
			...state,
			isResultsLoading: true,
		};
	}

	if (action.type === getType(searchActions.searchProductsSucceeded)) {
		return {
			...state,
			isLoading: false,
			isResultsLoading: false,
			count: action.payload.result.count,
			products: searchResultsProductsReducer(state.products as SearchProductCard[], action),
			filterCount: searchResultFilterCount(state.filterCount, action),
		};
	}

	if (action.type === getType(searchActions.recipeProductFilterSearchProducts)) {
		return { ...state, recipeProductFilter: recipeProductFilterReducer(state.recipeProductFilter, action) };
	}

	if (action.type === getType(searchActions.recipeProductFilterSearchProductsSuccess)) {
		return { ...state, recipeProductFilter: recipeProductFilterReducer(state.recipeProductFilter, action) };
	}

	if (action.type === getType(searchActions.recipeProductFilterSetProduct)) {
		return { ...state, recipeProductFilter: recipeProductFilterReducer(state.recipeProductFilter, action) };
	}

	if (action.type === getType(searchActions.recipeProductFilterLoadProductSuccess)) {
		return { ...state, recipeProductFilter: recipeProductFilterReducer(state.recipeProductFilter, action) };
	}

	return state;
};

const searchResultFilterCount: Reducer<NumberMap, SearchActions> = (state = {}, action) => {
	if (
		(action.type === getType(searchActions.searchRecipesSucceeded) ||
			action.type === getType(searchActions.searchProductsSucceeded)) &&
		action.payload.result.searchFunctionalityData
	) {
		const filterCount: NumberMap = {};
		action.payload.result.searchFunctionalityData.forEach(({ slug, count }) => {
			filterCount[slug] = count;
		});
		return filterCount;
	}

	return state;
};

export const searchResultsArticlesReducer: Reducer<ArticleCardInterface[], SearchActions> = (state = [], action) => {
	const parseArticleCardInterface = ({
		imageUrl,
		summary,
		title,
		titleHtml,
		url,
		isHealthCareContent,
		contentFamily,
		themeColor,
		publicationTime,
		resourceType,
	}: ArticleSearchApiArticle): ArticleCardInterface => ({
		url,
		title,
		titleHtml,
		imageUrl,
		icon: null,
		contentFamily,
		themeColor,
		date: publicationTime,
		isHealthCareContent,
		resourceType,
	});

	if (action.type === getType(searchActions.searchArticlesSucceeded)) {
		const { searchData } = action.payload.result;
		const articles = searchData.map(parseArticleCardInterface);

		if (action.payload.pageNumber > 1) {
			const uniqArticles = articles.filter(
				article =>
					!includes(
						state.map(articleInState => articleInState.url),
						article.url
					)
			);
			return state.concat(uniqArticles);
		} else {
			return articles;
		}
	}

	return state;
};

export const searchResultsRecipesReducer: Reducer<RecipeCardInterface[], SearchActions> = (
	stateRecipes = [],
	action
) => {
	const parseRecipeCardInterface = ({
		name,
		url,
		totalTime,
		rating,
		imageUrl,
		guestcook,
		userGenerated,
		valioLuomuRecipe,
		newRecipe,
	}: RecipeSearchApiRecipe): RecipeCardInterface => ({
		type: getRecipeType(guestcook, userGenerated),
		imageUrl: `${imageUrl}/358x231-recipe-highlight`,
		recipeName: name,
		url,
		rating,
		totalTime,
		isOrganic: valioLuomuRecipe,
		isNovelty: newRecipe,
	});

	if (action.type === getType(searchActions.searchRecipesSucceeded)) {
		const { searchData } = action.payload.result;
		const recipes = searchData.map(parseRecipeCardInterface);

		if (action.payload.pageNumber > 1) {
			const uniqRecipes = recipes.filter(
				r =>
					!includes(
						stateRecipes.map(sr => sr.url),
						r.url
					)
			);
			return stateRecipes.concat(uniqRecipes);
		} else {
			return recipes;
		}
	}

	return stateRecipes;
};

const searchResultsProductsReducer: Reducer<SearchProductCard[], SearchActions> = (stateProducts = [], action) => {
	const parseProductCard = ({ imageUrl, linkUrl, title, isNovelty }: ProductSearchApiProduct): SearchProductCard => ({
		productName: title,
		url: linkUrl,
		imageUrl: `${imageUrl}/100x100-product-thumbnail`,
		largeImageUrl: `${imageUrl}/140x140-product-thumbnail`,
		isNovelty,
	});

	if (action.type === getType(searchActions.searchProductsSucceeded)) {
		const { searchData } = action.payload.result;
		const products = searchData.map(parseProductCard);

		if (action.payload.pageNumber > 1) {
			const uniqProducts = products.filter(
				r =>
					!includes(
						stateProducts.map(sr => sr.url),
						r.url
					)
			);
			return stateProducts.concat(uniqProducts);
		} else {
			return products;
		}
	}

	return stateProducts;
};

export const getRecipeType = (isCooperation: boolean, isUser: boolean) => {
	if (isCooperation) {
		return RecipeCreatorType.cooperation;
	}
	if (isUser) {
		return RecipeCreatorType.user;
	}
	return RecipeCreatorType.valio;
};

const recipeProductFilterReducer: Reducer<RecipeProductFilterState, SearchActions> = (state = {}, action) => {
	if (action.type === getType(searchActions.recipeProductFilterSearchProducts)) {
		return { ...state, isSearching: true };
	}

	if (action.type === getType(searchActions.recipeProductFilterSearchProductsSuccess)) {
		return { ...state, searchResult: action.payload.searchResult, isSearching: false };
	}

	if (action.type === getType(searchActions.recipeProductFilterSetProduct)) {
		return { ...state, productTitle: action.payload.title, productSlug: action.payload.slug };
	}

	if (action.type === getType(searchActions.recipeProductFilterLoadProductSuccess)) {
		const { slug, title } = action.payload;
		return { ...state, productTitle: title || slug, productSlug: slug };
	}

	return state;
};
