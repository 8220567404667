import { IntlShape } from 'react-intl';
import { Locales } from 'common/types/locale';

const monthNames = [
	'global_monthname_jan',
	'global_monthname_feb',
	'global_monthname_mar',
	'global_monthname_apr',
	'global_monthname_may',
	'global_monthname_jun',
	'global_monthname_jul',
	'global_monthname_aug',
	'global_monthname_sep',
	'global_monthname_oct',
	'global_monthname_nov',
	'global_monthname_dec',
];

export const getFormattedFullDate = (intl: IntlShape, date: Date): string => {
	return `${date.getDate()}. ${intl.formatMessage({ id: monthNames[date.getMonth()] })} ${date.getFullYear()}`;
};

export const getLocalizedDate = (date: Date, locale: Locales = Locales.fi) => {
	const actualLocale = locale === Locales.lv ? Locales.et : locale; // Latvia should use same dateformat as Estonia
	return Intl.DateTimeFormat(actualLocale).format(date);
};

export const getLocalizedDateTime = (date: Date, locale: Locales = Locales.fi) => {
	const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' };
	return Intl.DateTimeFormat(locale, options).format(date);
};

export const nullSafeStringToDate = (dateString: string | null) => (!!dateString && new Date(dateString)) || null;
