import * as React from 'react';
import styled, { css } from 'styled-components';
import { ArrowDownIcon } from '../../Icons';
import { InputSizes, InputSize } from '../../../types';
import Field, { FieldProps, StyledFieldProps, commonInputStyles, getBorder, getCssPadding } from '../Field/Field';
import { ResponsiveValues } from 'common/components/Media';
import { media } from 'styleguide/helpers';

const getPaddingModifier = (size?: InputSizes) => {
	if (size === InputSize.large) {
		return 3;
	}
	if (size === InputSize.medium) {
		return 2;
	}

	return 1;
};

interface StyledSelectProps {
	inputSize?: InputSizes | ResponsiveValues<InputSizes>;
	disabled?: boolean;
}

const SelectWrapper = styled('div')<StyledSelectProps>`
	background-color: ${props => (props.disabled ? props.theme.colors.border : props.theme.colors.white)};
	border-radius: 4px;
	position: relative;

	& > svg {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		z-index: 0;
		width: 24px;
		height: 24px;

		${props => {
			if (typeof props.inputSize === 'object') {
				const sizeMap = props.inputSize as ResponsiveValues<InputSizes>;

				return css`
					${Object.keys(sizeMap).map(key => {
						if (key === 'default') {
							return css`
								right: ${props.theme.grid.gutterInPx(getPaddingModifier(sizeMap.default))};
							`;
						}
						return css`
							${media[key]`
								right: ${props.theme.grid.gutterInPx(getPaddingModifier(sizeMap[key]))};
							`};
						`;
					})};
				`;
			}

			return css`
				right: ${props.theme.grid.gutterInPx(getPaddingModifier(props.inputSize))};
			`;
		}};
	}
`;

const StyledSelect = styled('select')<StyledFieldProps>`
	appearance: none;
	${({ theme }) => theme.typography.heading};
	${commonInputStyles};

	border: 1px solid ${props => getBorder(props)};
	${props => getCssPadding(props)};

	font-size: 13px;
	text-transform: uppercase;
	color: ${({ theme }) => theme.colors.selectTextColor || theme.colors.buttonPrimary};
	background: transparent;
	position: relative;

	${props => {
		if (typeof props.inputSize === 'object') {
			const sizeMap = props.inputSize as ResponsiveValues<InputSizes>;

			return css`
				${Object.keys(sizeMap).map(key => {
					if (key === 'default') {
						return css`
							padding-right: ${props.theme.grid.gutter * 2 * getPaddingModifier(sizeMap.default) + 24}px;
						`;
					}
					return css`
						${media[key]`
							padding-right: ${props.theme.grid.gutter * 2 * getPaddingModifier(sizeMap[key]) + 24}px;
						`};
					`;
				})};
			`;
		}

		return css`
			padding-right: ${props.theme.grid.gutter * 2 * getPaddingModifier(props.inputSize) + 24}px;
		`;
	}};

	/* Hide expander arrow on IE10 and above */
	&::-ms-expand {
		display: none;
	}

	&:disabled {
		color: ${({ theme }) => theme.colors.inputDisabledText};
	}
`;

export interface SelectProps extends FieldProps {
	inputClassName?: string;
}

type Props = SelectProps & React.SelectHTMLAttributes<HTMLSelectElement>;

const Select = React.forwardRef<HTMLSelectElement, Props>(
	(
		{
			validationError,
			helpText,
			id,
			name,
			icon,
			iconPosition,
			iconOffset,
			iconWidth,
			inputSize,
			inputClassName,
			className,
			label,
			inlineLabel,
			children,
			disabled,
			...props
		},
		ref: React.RefObject<HTMLSelectElement>
	) => {
		const inputId = id || name;

		return (
			<Field
				validationError={validationError}
				helpText={helpText}
				id={inputId}
				name={name}
				icon={icon}
				iconPosition={iconPosition}
				iconOffset={iconOffset}
				iconWidth={iconWidth}
				inputSize={inputSize}
				className={className}
				label={label}
				inlineLabel={inlineLabel}>
				<SelectWrapper disabled={disabled} inputSize={inputSize}>
					<ArrowDownIcon isDisabled={disabled} />
					<StyledSelect
						name={name}
						id={inputId}
						inputSize={inputSize}
						iconWidth={iconWidth}
						iconPosition={iconPosition}
						iconOffset={iconOffset}
						className={inputClassName}
						isValid={validationError ? validationError.length === 0 : true}
						disabled={disabled}
						ref={ref}
						{...props}>
						{children}
					</StyledSelect>
				</SelectWrapper>
			</Field>
		);
	}
);

export default Select;
