import * as React from 'react';
import SmallHeader from 'styleguide/components/Typography/SmallHeader/SmallHeader';
import styled from 'styled-components';
import { media } from 'styleguide/helpers/media';
import ShoppingListItem from './ShoppingListItem';
import { ShoppingListStoreSectionShape } from '../reducers/shopping-list';
import { ShoppingListColours } from '../types';

const StoreSectionWrapper = styled.section`
	padding: 20px 10px;
	border-top: 1px solid ${props => props.theme.colors.border};
	width: 100%;

	${media.tablet`
		padding: 20px;
	`};

	@media print {
		padding: 5pt 0;
		width: 50%;

		.store-section-header {
			margin: 0;
			font-size: 12pt;
		}
	}
`;

const ShoppingListItemsWrapper = styled.ul`
	list-style: none;
	margin: 0;
	padding: 0;
`;

export interface ShoppingListStoreSectionProps extends ShoppingListStoreSectionShape {
	recipeIndexesById: object;
	onItemRemove: (itemId: string) => void;
	onToggleItemPicked: (itemId: string, isPicked: boolean) => void;
}

const ShoppingListStoreSection: React.FC<ShoppingListStoreSectionProps> = ({
	name,
	items,
	recipeIndexesById,
	onItemRemove,
	onToggleItemPicked,
}) => {
	return (
		<StoreSectionWrapper>
			{name && (
				<header>
					<SmallHeader className="store-section-header">{name}</SmallHeader>
				</header>
			)}
			<ShoppingListItemsWrapper>
				{items.map(item => {
					let itemColour;
					if (item.recipe !== null) {
						const recipeIndex = recipeIndexesById[item.recipe.id];
						if (recipeIndex !== undefined) {
							itemColour = ShoppingListColours[recipeIndex];
						}
					}
					return (
						<ShoppingListItem
							key={item.id}
							{...item}
							itemColour={itemColour}
							onItemRemove={onItemRemove}
							onToggleItemPicked={onToggleItemPicked}
						/>
					);
				})}
			</ShoppingListItemsWrapper>
		</StoreSectionWrapper>
	);
};

export default ShoppingListStoreSection;
