import * as React from 'react';
import { connect, MapStateToProps } from 'react-redux';
import { localeSelectorNoDefault } from 'common/selectors/localeSelector';
import { State } from 'common/reducers';
import { Locales } from 'common/types/locale';
import { ValioMessages } from 'translations/messages.default';
import LoadingSpinner from '../Loading/LoadingSpinner';
import { loadMessagesThrow } from 'utils/import';
import App from './App';
import { RoutingState } from '../Routing/reducers';
import { appActions } from './reducers';
import { getThemeForResource, WithAppSettings } from './services';
import ResourceLoadError from '../Resource/components/ResourceLoadError';
import { ThemeProvider, DefaultTheme } from 'styled-components';
import { IntlProvider } from 'react-intl';
import { getBaseMessages } from 'translations/baseMessages';
import { themes, defaultTheme } from 'styleguide/themes/theme';

interface ClientAppStateProps extends WithAppSettings {
	routing: RoutingState;
	locale: Locales | null;
	siteTheme: DefaultTheme;
}

type Props = ClientAppStateProps & ClientAppDispatchProps;

interface ClientAppState {
	messages: ValioMessages | null;
	error: any;
}

class ClientApp extends React.Component<Props, ClientAppState> {
	constructor(props: Props) {
		super(props);
		this.state = {
			messages: null,
			error: null,
		};
	}

	public componentDidMount() {
		const { fetchSettingsAndNavigation, routing } = this.props;
		const { messages } = this.state;
		if (!messages) {
			this.loadLocale();
		}
		if (!this.props.settings) {
			fetchSettingsAndNavigation(routing);
		}
	}

	public componentDidUpdate(prevProps: Props) {
		const { locale } = this.props;

		if (locale !== prevProps.locale) {
			this.loadLocale();
		}
	}

	public render() {
		const { settings, locale } = this.props;
		const { messages, error } = this.state;
		if (error && locale) {
			return this.renderError(locale);
		} else if (!messages || !settings) {
			return <LoadingSpinner />;
		} else {
			return <App messages={messages} />;
		}
	}

	private renderError(locale: Locales) {
		const { siteTheme } = this.props;
		const baseMessages = getBaseMessages(locale);
		return (
			<IntlProvider locale={locale} messages={baseMessages}>
				<ThemeProvider theme={siteTheme}>
					<ResourceLoadError />
				</ThemeProvider>
			</IntlProvider>
		);
	}

	private async loadLocale() {
		const { locale } = this.props;

		try {
			const localeMessages = locale ? await loadMessagesThrow(locale) : null;
			this.setState({ messages: localeMessages, error: null });
		} catch (error) {
			this.setState({ error });
			appInsights.trackException(error);
		}
	}
}

const mapStateToProps: MapStateToProps<ClientAppStateProps, {}, State> = (state): ClientAppStateProps => {
	const { routing, app, resource } = state;
	const locale = localeSelectorNoDefault(state);

	const sites = app.settings?.sites || [];
	const siteThemeCode = sites.length > 0 ? getThemeForResource(routing, sites, resource) : undefined;

	return {
		routing,
		settings: app.settings,
		locale,
		siteTheme: themes[siteThemeCode ?? defaultTheme],
	};
};

interface ClientAppDispatchProps {
	fetchSettingsAndNavigation: typeof appActions.fetchSettingsAndNavigation;
}

export default connect(mapStateToProps, {
	fetchSettingsAndNavigation: appActions.fetchSettingsAndNavigation,
})(ClientApp);
