import * as React from 'react';
import Button from 'styleguide/components/Buttons/Button/Button';
import Modal from 'styleguide/components/Modals/Modal/Modal';
import MediumHeader from 'styleguide/components/Typography/MediumHeader/MediumHeader';
import styled from 'styled-components';
import { media } from 'styleguide/helpers/media';
import { FormattedMessage } from 'react-intl';

const FilterModal = styled(Modal)`
	${media.tablet`
		max-height: 90%;
	`};

	.modal-content {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		padding: 0;
	}

	.filter-modal-header {
		margin: 0 16px 32px;
	}
`;

const ContentWrapper = styled.div`
	overflow-y: auto;
	padding: 0 20px;
`;

const ReadyContainer = styled.div`
	width: 100%;
	padding: 20px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
	z-index: 1;

	.ready-button {
		width: 100%;

		${media.phone560`
			width: 240px;
			margin: 0 auto;
		`};
	}
`;

interface ModalFiltersState {
	isOpen: boolean;
}

interface ModalFiltersProps {
	filters: React.ReactNode;
}

class ModalFilters extends React.Component<ModalFiltersProps, ModalFiltersState> {
	public state: ModalFiltersState = {
		isOpen: false,
	};

	public render() {
		const { filters } = this.props;
		const { isOpen } = this.state;
		return (
			<>
				<Button size="small" className="modal-filter-trigger" onClick={this.onShowFilters}>
					<FormattedMessage id="search_filter_search" />
				</Button>
				{isOpen && (
					<FilterModal onClose={this.onCloseFilters}>
						<ContentWrapper id="modal-filters-content-wrapper">
							<MediumHeader tagName="h3" className="filter-modal-header">
								<FormattedMessage id="search_filter_search" />
							</MediumHeader>
							{filters}
						</ContentWrapper>
						<ReadyContainer>
							<Button className="ready-button" onClick={this.onCloseFilters}>
								<FormattedMessage id="global_ready" />
							</Button>
						</ReadyContainer>
					</FilterModal>
				)}
			</>
		);
	}

	private onShowFilters = () => {
		this.setState({ isOpen: true });
	};

	private onCloseFilters = () => {
		this.setState({ isOpen: false });
	};
}

export default ModalFilters;
