import { ResourceState } from 'common/components/Resource/reducers/reducers';
import { ResourceType } from 'common/components/Resource/types';
import { getType } from 'typesafe-actions';
import { Reducer } from 'redux';
import { resourceActions, ResourceActions } from 'common/components/Resource/reducers/actions';
import { NameAndValue } from 'common/interfaces/common';
import { SearchActions, searchActions } from '../Search/reducers/search-actions';
import { RecipeCardInterface } from 'styleguide/interfaces/cardInterfaces';
import { searchResultsRecipesReducer, getRecipeType } from '../Search/reducers';
import { convertLineBreaks } from 'utils/strings';

export interface RecipeAspectGroupResourceState extends ResourceState {
	type: ResourceType.recipeAspectGroup;
	content: RecipeAspectGroupState;
}

export interface RecipeAspectGroupState {
	description: string;
	filters: NameAndValue[];
	isLoading: boolean;
	isResultsLoading: boolean;
	recipes: RecipeCardInterface[];
	count: number;
}

const recipeAspectGroupInitialState: RecipeAspectGroupState = {
	description: '',
	filters: [],
	recipes: [],
	isLoading: true,
	isResultsLoading: false,
	count: 0,
};

export const recipeAspectGroupReducer: Reducer<RecipeAspectGroupState, ResourceActions | SearchActions> = (
	state = recipeAspectGroupInitialState,
	action
): RecipeAspectGroupState => {
	switch (action.type) {
		case getType(resourceActions.setResource):
			if (action.payload.resource.type === ResourceType.recipeAspectGroup) {
				const { description, filters, recipes } = action.payload.resource.content;

				return {
					...state,
					description: description ? convertLineBreaks(description) : description,
					filters,
					recipes: recipes?.map(r => ({
						type: getRecipeType(r.guestcook, r.userGenerated),
						imageUrl: `${r.imageUrl}/358x231-recipe-highlight`,
						recipeName: r.name,
						url: r.url,
						rating: r.rating,
						totalTime: r.totalTime,
						isOrganic: r.valioLuomuRecipe,
						isNovelty: r.newRecipe,
					})),
				};
			}
		case getType(searchActions.searchRecipes):
			return {
				...state,
				isLoading: true,
			};
		case getType(searchActions.loadMoreRecipes):
			return {
				...state,
				isResultsLoading: true,
			};
		case getType(searchActions.searchRecipesSucceeded):
			return {
				...state,
				count: action.payload.result.count,
				isLoading: false,
				isResultsLoading: false,
				recipes: searchResultsRecipesReducer(state.recipes as RecipeCardInterface[], action as SearchActions),
			};
		default:
			return state;
	}
};
