import * as React from 'react';
import { Helmet } from 'react-helmet';
import Divider from 'styleguide/components/Divider/Divider';
import { DividerDirection, DividerSize } from 'styleguide/components/Divider/Divider';
import styled from 'styled-components';
import MediumHeader from 'styleguide/components/Typography/MediumHeader/MediumHeader';
import BodyText from 'styleguide/components/Typography/BodyText/BodyText';
import { media } from 'styleguide/helpers/media';
import OutlinedButtonLink from 'styleguide/components/Buttons/OutlinedButton/OutlinedButtonLink';
import { connect, MapStateToProps } from 'react-redux';
import { ContentWrapper } from 'common/components/General';
import { ProfileApiShape } from './services/profile';
import { ProfileShape } from './reducers/profile';
import Loading from 'common/components/Loading/Loading';
import { LoginModalFormType } from 'common/components/Login/types';
import { State } from 'common/reducers';
import { userActions } from './reducers/actions';
import { loginActions } from 'common/components/Login/reducers/actions';
import { injectIntl, WrappedComponentProps, FormattedMessage } from 'react-intl';
import Link from 'common/components/Routing/Link';
import UserContactForm, { UserContactFormFields } from './components/UserContactForm';
import PasswordChangeForm, { PasswordChangeFormFields } from './components/PasswordChangeForm';
import { getSiteUrlPrefixOrDefault } from 'common/components/App/services';

const DetailsWrapper = styled(ContentWrapper)`
	width: 100%;
	max-width: 700px;

	${media.tablet`
		padding: 30px;
	`};
`;

const SectionWrapper = styled.div`
	padding: 0 0 20px;
	width: 100%;

	${media.tablet`
		padding: 30px 20px;
	`};
`;

const SectionHeader = styled(MediumHeader)`
	text-align: left;
`;

const FieldsWrapper = styled.div`
	margin-bottom: 30px;

	${media.tablet`
		display: flex;
		flex-flow: row wrap;
		justify-content: flex-start;
		align-content: center;
	`};
`;

const FieldsInner = styled.div`
	${media.tablet`
		flex: 0 0 285px;
		flex: 0 0 calc(50% - 15px);
	`};

	> .form-field {
		margin-top: 10px;
		margin-bottom: 10px;
	}

	> button {
		width: 100%;
	}
`;

const ModalLink = styled.a`
	${props => props.theme.typography.heading};
	color: ${props => props.theme.colors.brandPrimary};
	cursor: pointer;
`;

const ButtonsWrapper = styled.div`
	margin-top: 20px;
	display: flex;
	flex-flow: row nowrap;
	justify-content: center;
	align-content: center;

	${media.tablet`
		margin-top: 0;
	`};

	> button {
		flex: 1 1 50%;
		width: 100%;
		margin-left: 15px;
		margin-right: 15px;
	}
`;

interface DetailsFormData extends ProfileApiShape {
	oldPassword?: string;
	newPassword?: string;
	confirmPassword?: string;
}

interface UserDetailsState {
	formData: DetailsFormData;
	isContactInfoValid: boolean;
	isSavePasswordValid: boolean;
}

interface UserDetailsStateProps {
	isLoggedIn: boolean;
	userProfile: ProfileShape;
	siteUrlPrefix: string;
}

type UserDetailsProps = UserDetailsStateProps & UserDetailsDispatchProps & WrappedComponentProps;

class UserDetails extends React.Component<UserDetailsProps, UserDetailsState> {
	public static getDerivedStateFromProps(props: UserDetailsProps, state: UserDetailsState) {
		if (props.userProfile.profile) {
			return {
				...state,
				formData: { ...state.formData, ...props.userProfile.profile },
			};
		}
		return null;
	}

	constructor(props: UserDetailsProps) {
		super(props);

		this.state = {
			formData: {},
			isContactInfoValid: false,
			isSavePasswordValid: false,
		};

		if (props.isLoggedIn && !props.userProfile.profile) {
			props.fetchCurrentProfile();
		}
	}

	public componentDidUpdate(prevProps: UserDetailsProps) {
		if (prevProps.isLoggedIn !== this.props.isLoggedIn && this.props.isLoggedIn) {
			// * Fetch logged in user profile when isLoggedIn state changes to true
			this.props.fetchCurrentProfile();
		}
	}

	public render() {
		const { isContactInfoValid, isSavePasswordValid } = this.state;
		const { isLoggedIn, userProfile, intl } = this.props;

		return (
			<DetailsWrapper>
				<Helmet>
					<title>{intl.formatMessage({ id: 'user_profile' })} - Valio</title>
				</Helmet>
				<Divider direction={DividerDirection.horizontal} size={DividerSize.full} />
				{isLoggedIn ? (
					userProfile.isLoading ? (
						<Loading visible={true} />
					) : (
						this.renderForm(isContactInfoValid, isSavePasswordValid, userProfile)
					)
				) : (
					this.renderLogInSuggestion()
				)}
			</DetailsWrapper>
		);
	}

	private renderLogInSuggestion = () => {
		return (
			<>
				<MediumHeader>
					<FormattedMessage id="user_profile_are_you_a_user_already" />
				</MediumHeader>
				<BodyText>
					<FormattedMessage
						id="user_profile_login_or_register_as_user_to_modify_details"
						values={{
							a: (msg: string) => (
								<ModalLink onClick={this.openLogin} rel="nofollow">
									{msg}
								</ModalLink>
							),
							b: (msg: string) => (
								<ModalLink onClick={this.openLoginWithRegistrationRequest} rel="nofollow">
									{msg}
								</ModalLink>
							),
						}}
					/>
				</BodyText>
			</>
		);
	};

	private renderForm = (isContactInfoValid: boolean, isSavePasswordValid: boolean, userProfile: ProfileShape) => {
		const { siteUrlPrefix } = this.props;
		const { profile } = userProfile;
		if (!profile) {
			return null;
		}

		const userContactFields = {
			firstName: { value: profile.firstName || '' },
			lastName: { value: profile.lastName || '' },
			email: { value: profile.email || '' },
		};

		return (
			<>
				<SectionWrapper>
					<SectionHeader>
						<FormattedMessage id="global_contact_information" />
					</SectionHeader>
					<FieldsWrapper>
						<FieldsInner>
							<UserContactForm
								userProfile={userProfile}
								onFormSubmit={this.updateContactInfo}
								fields={userContactFields}
								disableClearOnSubmit
							/>
						</FieldsInner>
						<FieldsInner>
							<PasswordChangeForm userProfile={userProfile} onFormSubmit={this.changePassword} />
						</FieldsInner>
					</FieldsWrapper>

					<BodyText>
						<FormattedMessage
							id="user_profile_read_register_information"
							values={{
								a: (msg: string) => (
									<Link to={`${siteUrlPrefix}rekisteriseloste/henkilorekisterinrekisteriseloste/`}>{msg}</Link>
								),
							}}
						/>
					</BodyText>
				</SectionWrapper>
				<Divider direction={DividerDirection.horizontal} size={DividerSize.full} />
				<SectionWrapper>
					<ButtonsWrapper>
						<OutlinedButtonLink onClick={this.props.doLogOut} rel="nofollow">
							<FormattedMessage id="user_log_out" />
						</OutlinedButtonLink>
					</ButtonsWrapper>
				</SectionWrapper>
			</>
		);
	};

	private openLogin = () => {
		this.props.openLogin(LoginModalFormType.LOGIN);
	};

	private openLoginWithRegistrationRequest = () => {
		this.props.openLogin(LoginModalFormType.REGISTRATION_REQUEST);
	};

	private updateContactInfo = (fields: UserContactFormFields) => {
		const { userProfile, updateContactInfo } = this.props;
		const { firstName, lastName, email } = fields;
		updateContactInfo({
			firstName: firstName.value,
			lastName: lastName.value,
			email: email.value,
			username: userProfile.profile && userProfile.profile.username,
		});
	};

	private changePassword = (fields: PasswordChangeFormFields) => {
		const { oldPassword, newPassword, confirmPassword } = fields;
		this.props.changePassword({
			oldPassword: oldPassword.value,
			newPassword: newPassword.value,
			confirmPassword: confirmPassword.value,
		});
	};
}

const mapStateToProps: MapStateToProps<UserDetailsStateProps, {}, State> = ({
	user,
	app,
	routing,
}): UserDetailsStateProps => {
	const sites = (app.settings && app.settings.sites) || [];
	return {
		isLoggedIn: user ? user.isLoggedIn : false,
		userProfile: user ? user.profile : { isLoading: true },
		siteUrlPrefix: getSiteUrlPrefixOrDefault(routing, sites, '/'),
	};
};

interface UserDetailsDispatchProps {
	fetchCurrentProfile: typeof userActions.fetchCurrentProfile;
	updateContactInfo: typeof userActions.updateContactInfo;
	changePassword: typeof userActions.changePassword;
	openLogin: typeof loginActions.openLogin;
	doLogOut: typeof loginActions.doLogOut;
}

export default connect(mapStateToProps, {
	fetchCurrentProfile: userActions.fetchCurrentProfile,
	updateContactInfo: userActions.updateContactInfo,
	changePassword: userActions.changePassword,
	openLogin: loginActions.openLogin,
	doLogOut: loginActions.doLogOut,
})(injectIntl(UserDetails));
