import * as React from 'react';
import styled from 'styled-components';
import { media, withWindow } from 'styleguide/helpers';
import { ArrowDownIcon } from 'styleguide/components/Icons';
import { DropdownButton } from 'styleguide/components/Inputs/Dropdown';
import { ItemList, Item } from 'styleguide/components/Inputs/ItemList/ItemList';
import { Button } from 'styleguide/components/Buttons/Button';
import { FormattedMessage } from 'react-intl';
import { CartItem } from '../interfaces';

const CartDropdownWrapper = styled.div`
	position: relative;
	order: 5;
	width: calc(70% - ${({ theme }) => theme.grid.gutterInPx()});

	${media.tablet`
		width: calc(50% - ${({ theme }) => theme.grid.gutterInPx()});
	`};

	${media.desktop`
		order: 3;
		flex: 0 0 auto;
		width: 50%;
		margin-right: -10px;
	`};

	${media.desktop1200`
		width: calc(100% / 3 - ${({ theme }) => theme.grid.gutterInPx(0.5)});
	`};

	${media.desktop1440`
		width: calc(100% / 3 + ${({ theme }) => theme.grid.gutterInPx(2)});
		margin-right: -40px;
	`};

	.item-list {
		margin-top: -1px;

		li.actions {
			padding: 0;
			background: none;
			margin-top: ${({ theme }) => theme.grid.gutterInPx(2)};
		}

		button {
			width: 100%;
		}
	}
`;

const CartListItem = styled(Item)`
	display: flex;
	justify-content: space-between;
	padding: ${({ theme }) => theme.grid.gutterInPx()};

	&:nth-child(odd) {
		background: ${({ theme }) => theme.colors.border};
	}

	span {
		flex: 1 1 auto;

		&:last-child {
			text-align: right;
			flex: 0 0 auto;
			margin-left: ${({ theme }) => theme.grid.gutterInPx()};
		}
	}
`;

const CartDropdownButton = styled(DropdownButton)`
	padding: ${({ theme }) => theme.grid.gutterInPx()} ${({ theme }) => theme.grid.gutterInPx()}
		${({ theme }) => theme.grid.gutterInPx()} ${({ theme }) => theme.grid.gutterInPx(2)};
	font-family: ${({ theme }) => theme.fonts.primary};
	font-size: 13px;
	text-transform: uppercase;

	&:disabled {
		color: ${({ theme }) => theme.colors.shoppingListGrey};

		svg path {
			fill: ${({ theme }) => theme.colors.shoppingListGrey};
		}
	}

	& span {
		font-size: 16px;
	}
`;

interface ItemCountProps {
	isOpen: boolean;
}

const ItemCount = styled.span<ItemCountProps>`
	display: ${props => (props.isOpen ? 'none' : 'inline-block')};
	margin-left: ${({ theme }) => theme.grid.gutterInPx()};
	background: ${({ theme }) => theme.colors.buttonText};
	color: ${({ theme }) => theme.colors.white};
	width: 24px;
	height: 24px;
	border-radius: 50%;
	text-align: center;
	line-height: 24px;
`;

interface Props {
	items: CartItem[];
	onGoToCart: () => void;
}

const CartDropdown: React.FC<Props> = ({ items, onGoToCart }) => {
	const wrapperRef = React.useRef<HTMLDivElement>(null);
	const [isOpen, setIsOpen] = React.useState(false);

	React.useEffect(() => {
		withWindow(w => {
			w.addEventListener('click', onDocumentClick);
			w.addEventListener('keydown', onKeyDown);
		});

		return () => {
			withWindow(w => {
				w.removeEventListener('click', onDocumentClick);
				w.removeEventListener('keydown', onKeyDown);
			});
		};
	});

	const onDocumentClick = (event: MouseEvent) => {
		if (event.target && wrapperRef.current?.contains(event.target as Node)) {
			return;
		}
		close();
	};

	const onKeyDown = (event: KeyboardEvent) => {
		if (isOpen && event.keyCode === 27) {
			close();
		}
	};

	const onToggleOpen = () => {
		setIsOpen(!isOpen);
	};

	const close = () => {
		setIsOpen(false);
	};

	const isDisabled = items.length === 0;

	return (
		<CartDropdownWrapper ref={wrapperRef}>
			<CartDropdownButton id="cart-dropdown-button" disabled={isDisabled} isOpen={isOpen} onClick={onToggleOpen}>
				<span>
					<FormattedMessage id="materials_search_cart" />
					{!isDisabled && <ItemCount isOpen={isOpen}>{items.length}</ItemCount>}
				</span>
				<ArrowDownIcon />
			</CartDropdownButton>
			{isOpen && (
				<ItemList className="item-list">
					{items.map(({ id, title, quantity }) => (
						<CartListItem key={`item-${id}`}>
							<span>{title}</span>
							<span>
								{quantity} <FormattedMessage id="unit_pcs" />
							</span>
						</CartListItem>
					))}
					<li className="actions">
						<Button size="medium" onClick={onGoToCart}>
							<FormattedMessage id="materials_search_order_back_to_cart" />
						</Button>
					</li>
				</ItemList>
			)}
		</CartDropdownWrapper>
	);
};

export default CartDropdown;
