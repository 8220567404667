import { media, mediaBreakpoints } from 'styleguide/helpers/media';
import { BodyTextColour, BodyTextColours } from 'styleguide/components/Typography/BodyText/BodyText';
import styled, { DefaultTheme, css } from 'styled-components';
import { getScrollbarWidth } from 'utils/document';
import { ProductHighlightLayouts } from '../Widgets/interfaces/ProductHighlight';
import { toRgbString } from 'utils/color';
import { ProductCardSizes } from 'styleguide/components/Cards/ProductCard/ProductCard';

export const bodyTextFont = (theme: DefaultTheme) => `
	font-family: ${theme.fonts.secondary};
	font-size: 18px;
	color: ${theme.colors.bodyText};
`;

export const PageWrapper = styled.div<{ isLoading?: boolean }>`
	width: 100%;
	display: flex;
	align-items: center;
	flex-direction: column;
	flex: 1;
	justify-content: ${props => (props.isLoading ? 'center' : 'flex-start')};
`;

export interface SectionProps {
	isHighlighted?: boolean;
	isFullWidth?: boolean;
	isPrintHidden?: boolean;
}

export const Section = styled.div<SectionProps>`
	background-color: ${({ isHighlighted, theme }) =>
		isHighlighted ? theme.colors.contentSectionHiglighted : theme.colors.contentSection};
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	padding: 19px 0;

	${props => {
		if (props.isFullWidth && props.isHighlighted) {
			return css`
				width: 100vw;
				margin: 0 calc(50% - 50vw);

				${media.desktop`
					width: calc(100vw - ${getScrollbarWidth()}px);
					margin: 0 calc(50% - 50vw + ${Math.floor(getScrollbarWidth() / 2)}px);
				`};
			`;
		}
		return null;
	}};

	@media print {
		display: ${props => (props.isPrintHidden ? 'none' : 'flex')};
	}
`;

export const contentLinkStyle = (theme: DefaultTheme, themeColor: string) => `
	color: ${theme.colors.bodyText};
	text-decoration: none;
	transition: background 0.2s ease-in-out;

	background-image: linear-gradient(to right, ${toRgbString(themeColor, 70)}, ${toRgbString(themeColor, 70)});
	${theme.styledVariations.contentLinkDecoration()}
  background-repeat: repeat-x;
  background-size: auto 4px;
	padding-bottom: 4px;

	${media.desktop`
	  background-size: auto 5px;
	`};

	&:hover {
		background-image: linear-gradient(to right, ${themeColor}, ${themeColor});
	}
`;

export interface ContentWrapperProps {
	headerHeight?: number;
	isPrintHidden?: boolean;
	isMobileHidden?: boolean;
	hasContentSubNav?: boolean;
}

export const contentWrapperStyles = (theme: DefaultTheme) => css`
	width: 100%;
	max-width: ${mediaBreakpoints.desktop1200}px;
	display: flex;
	flex-direction: column;
	align-items: center;
	box-sizing: border-box;
	margin: 0 auto;
	position: relative;

	${media.desktop`
    padding: 0 ${theme.grid.gutterInPx()};
  `}

	${media.tablet`
		padding: 0 ${theme.grid.gutterInPx(5)};
	`};

	${media.phone`
		padding: 0 ${theme.grid.gutterInPx(2)};
	`};
`;

export const ContentWrapper = styled.section<ContentWrapperProps>`
	${({ theme }) => contentWrapperStyles(theme)};

	${props =>
		props.isPrintHidden
			? css`
					@media print {
						display: none;
					}
			  `
			: ''};
`;

export const PrintHidden = styled.div`
	width: 100%;

	@media print {
		display: none;
	}
`;

export const PrintHeroWrapper = styled.div`
	display: none;

	@media print {
		display: block;
		width: 100%;
		padding: 0 0 3mm;

		.logo > img {
			display: block;
			margin: 0 auto 3mm;
			width: 20mm;
		}
	}
`;

export const FrontHeader = styled.div`
	margin: 0 auto;
	position: absolute;
	top: 85px;
	z-index: ${props => props.theme.zIndices.headerWithSearchBox};
	padding: 0 10px;
	width: 100%;

	${media.tablet`
		top: 130px;		
	`};

	${media.desktop`
		top: 190px;		
	`};

	.search-results {
		margin: 0 auto;
	}

	.search-input {
		padding-right: 46px;

		${media.phone`
			padding-right: 54px;
		`};
	}
`;

export interface FrontTitleTheme {
	colorTheme?: 'light' | 'dark';
	color?: string;
}

export const FrontTitle = styled.h1<FrontTitleTheme>`
	${props => props.theme.typography.heading};
	font-size: 40px;
	color: ${props =>
		props.colorTheme === 'dark' ? props.color || props.theme.colors.brandPrimary : props.theme.colors.white};
	margin: 0;
	text-align: center;
	text-transform: uppercase;
	margin-bottom: 20px;
	${props =>
		props.colorTheme === 'dark'
			? ''
			: css`
					text-shadow: 0 2px 20px rgba(0, 0, 0, 0.3);
			  `};
	hyphens: manual;

	${media.tablet`
		font-size: 60px;
		margin-bottom: 30px;
	`};
`;

export const FrontSubTitle = styled.h2<FrontTitleTheme>`
	font-family: ${props => props.theme.fonts.secondary};
	font-size: 18px;
	font-weight: 600;
	font-style: italic;
	color: ${props =>
		props.colorTheme === 'dark' ? props.color || props.theme.colors.brandPrimary : props.theme.colors.white};
	margin: 0;
	text-align: center;
	${props =>
		props.colorTheme === 'dark'
			? ''
			: css`
					text-shadow: 0 2px 20px rgba(0, 0, 0, 0.3);
			  `};

	${media.tablet`
		font-size: 22px;
		margin-bottom: 30px;
	`};
`;

export interface IconTextWrapperProps {
	color?: BodyTextColours;
}

export const IconTextWrapper = styled.div<IconTextWrapperProps>`
	font-family: ${props => props.theme.fonts.secondary};
	line-height: 1.11;
	font-size: 18px;
	color: ${({ theme, color }) => (color === BodyTextColour.blue ? theme.colors.brandPrimary : theme.colors.bodyText)};
	display: flex;

	& > *:first-child {
		margin-right: 7px;

		& > div {
			vertical-align: middle;
		}
	}
`;

export const Strong = styled.strong`
	font-weight: 600;
`;

export const PageTitleWrapper = styled.div`
	text-align: center;
	position: relative;
	padding: 30px 30px 0;
	width: 100%;
	box-sizing: border-box;

	${media.desktop`
    padding: 30px 120px 0;
  `};
`;

export const productCardGridStyles = (layoutName?: ProductHighlightLayouts, size?: ProductCardSizes) => {
	let maxWidth = size === 'small' ? '174px' : 'none';
	let tabletColumns = 4;
	let desktopColumns = 6;

	if (layoutName === 'fourColumn') {
		maxWidth = 'none';
		desktopColumns = 4;
	}

	if (layoutName === 'threeColumn' || layoutName === 'oddlygood3c') {
		maxWidth = 'none';
		tabletColumns = 3;
		desktopColumns = 3;
	}

	return css`
		.product-card {
			flex: 0 0 48%;
			flex: 0 0 calc(50% - 6px);
			width: calc(50% - 6px);
			margin: 0 3px;
			max-width: ${maxWidth};

			${media.tablet`
				flex: 0 0 auto;
				flex: 0 0 calc(100% / ${tabletColumns} - 6px);
				width: calc(100% / ${tabletColumns} - 6px);
			`};

			${media.desktop`
				flex: 0 0 calc(100% / ${desktopColumns} - 6px);
				width: calc(100% / ${desktopColumns} - 6px);
			`};

			${media.desktop1440`
				flex: 0 0 calc(100% / ${desktopColumns} - 6px);
				width: calc(100% / ${desktopColumns} - 6px);
			`};
		}
	`;
};
