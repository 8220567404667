import * as React from 'react';
import NormalIconWrapper, { NormalIconWrapperProps } from '../NormalIconWrapper';
import { ThemeContext } from 'styled-components';

export interface HeartIconProps extends NormalIconWrapperProps {
	isSelected?: boolean;
}

const HeartIcon: React.FC<HeartIconProps & React.SVGAttributes<SVGElement>> = ({ color, isSelected, ...props }) => {
	const theme = React.useContext(ThemeContext);
	return (
		<NormalIconWrapper {...props}>
			{isSelected && (
				<path
					fill={theme.colors.white}
					d="M12.997 24.235a.993.993 0 0 1-.707-.293L1.995 13.648a6.831 6.831 0 0 1 0-9.649A6.776 6.776 0 0 1 6.82 2c1.823 0 3.536.709 4.825 1.999l1.352 1.351 1.352-1.351A6.772 6.772 0 0 1 19.174 2c1.822 0 3.536.709 4.824 1.999a6.831 6.831 0 0 1 0 9.649L13.704 23.942a.995.995 0 0 1-.707.293"
				/>
			)}
			{!isSelected && (
				<path
					fill={color ?? theme.colors.brandPrimary}
					d="M6.82 4a4.79 4.79 0 0 0-3.411 1.413 4.83 4.83 0 0 0 0 6.821l9.588 9.587 9.587-9.587a4.828 4.828 0 0 0 0-6.821A4.787 4.787 0 0 0 19.174 4c-1.289 0-2.5.501-3.411 1.413l-2.059 2.058a.999.999 0 0 1-1.414 0l-2.059-2.058A4.788 4.788 0 0 0 6.82 4m6.177 20.235a.993.993 0 0 1-.707-.293L1.995 13.648a6.831 6.831 0 0 1 0-9.649A6.776 6.776 0 0 1 6.82 2c1.823 0 3.536.709 4.825 1.999l1.352 1.351 1.352-1.351A6.772 6.772 0 0 1 19.174 2c1.822 0 3.536.709 4.824 1.999a6.831 6.831 0 0 1 0 9.649L13.704 23.942a.995.995 0 0 1-.707.293"
				/>
			)}
		</NormalIconWrapper>
	);
};

export default HeartIcon;
