import * as React from 'react';
import { Component } from 'react';
import styled, { css, ThemeConsumer } from 'styled-components';
import { media } from '../../helpers';
import { IconWrapperProps } from '../../interfaces';

export interface StyledTabProps {
	/** This is used to set a tab as active. Note that the `Tabs` instance
	 * should have the `useExternalProps` set to `true` if you wish to
	 * control the active state externally (e.g. via Redux).
	 */
	isActive?: boolean;
	size?: 'large';
	isLowerCase?: boolean;
	hasIcon?: boolean;
	url?: string;
}

const StyledTab = styled('li')<StyledTabProps>`
	padding: 5px 10px;
	position: relative;
	${media.tablet`
		padding: 5px 15px;	
	`};
	& + &:before {
		content: '';
		display: block;
		border-left: 1px solid ${props => props.theme.colors.border};
		height: calc(100% - 10px);
		position: absolute;
		left: 0;
		top: 5px;
	}
`;

const TabLink = styled('a')<StyledTabProps>`
	${props => props.theme.typography.heading};
	color: ${props => (props.isActive ? props.theme.colors.tabActive : props.theme.colors.tabInactive)};
	font-size: 13px;
	padding: 10px;
	display: inline-block;
	position: relative;
	height: 100%;
	text-decoration: none;

	${media.tablet<StyledTabProps>`
	  padding: 10px 20px;
		font-size: ${props => (props.size === 'large' ? '18px' : '13px')};
	`};

	${props => (props.isLowerCase ? '' : `text-transform: uppercase;`)} {
		cursor: ${props => (props.isActive ? 'default' : 'pointer')};
		color: ${props => (props.isActive ? props.theme.colors.brandPrimary : props.theme.colors.tabInactiveHover)};

		${props =>
			props.isActive
				? ''
				: css`
						g,
						path {
							fill: ${props.theme.colors.tabInactiveHover};
						}
				  `};
	}

	& > div {
		display: none;
	}

	${media.tablet<StyledTabProps>`
		${props =>
			props.hasIcon
				? css`
						display: flex;
						align-items: center;
						justify-content: center;

						svg {
							display: block;
							margin-right: 10px;
						}
				  `
				: ''}
	`};

	${props =>
		props.isActive
			? css`
					&:after {
						content: '';
						display: block;
						height: ${props.size === 'large' ? '5px' : '3px'};
						width: 100%;
						position: absolute;
						left: 0;
						bottom: ${props.size === 'large' ? '-10px' : '-8px'};
						background: ${props.theme.colors.brandPrimary};
					}
			  `
			: ''};

	span {
		display: inline-block;
	}
`;

export interface TabProps extends StyledTabProps {
	identifier?: string;
	label: string;
	url: string;
	icon?: React.FC<IconWrapperProps> | React.ComponentClass<IconWrapperProps>;
	noFollow?: boolean;
	onClick?: (url: string) => void;
	renderAnchorTags?: boolean;
}

class Tab extends Component<TabProps, {}> {
	public render() {
		const { identifier, isActive, label, isLowerCase, size, icon, url, noFollow, renderAnchorTags = true } = this.props;
		return (
			<StyledTab isActive={isActive}>
				{renderAnchorTags ? (
					<>
						<TabLink
							id={identifier}
							onClick={this.onClick}
							isActive={isActive}
							isLowerCase={isLowerCase}
							size={size}
							href={url}
							hasIcon={icon && icon !== undefined}
							rel={noFollow ? 'nofollow' : undefined}>
							{this.renderIcon()}
							<span dangerouslySetInnerHTML={{ __html: label }} />
						</TabLink>
					</>
				) : (
					<>
						<TabLink
							as="span"
							id={identifier}
							isActive={isActive}
							isLowerCase={isLowerCase}
							size={size}
							hasIcon={icon && icon !== undefined}>
							{this.renderIcon()}
							<span dangerouslySetInnerHTML={{ __html: label }} />
						</TabLink>
					</>
				)}
			</StyledTab>
		);
	}

	private renderIcon() {
		const { icon, isActive } = this.props;
		if (!icon) {
			return null;
		}
		const Icon = icon;
		return <ThemeConsumer>{theme => <Icon color={isActive ? undefined : theme.colors.tabInactive} />}</ThemeConsumer>;
	}

	private onClick = (event: React.MouseEvent<HTMLElement>) => {
		const { isActive, onClick, url } = this.props;
		if (isActive) {
			event.preventDefault();
			return;
		}
		if (onClick) {
			event.preventDefault();
			onClick(url);
		}
	};
}

export default Tab;
