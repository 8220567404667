import './public-path';

import { loadableReady } from '@loadable/component';

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import registerServiceWorker from './registerServiceWorker';
import * as smoothscroll from 'smoothscroll-polyfill';
import { lazyImages } from 'styleguide/components/LazyImageRegistry';
import { withWindow } from 'styleguide/helpers/window';
import { createStoreWithState } from 'stores/store';
import { AppInsights } from 'applicationinsights-js';
import { generateConfig } from 'config/config';
import App from 'common/components/App/App';
import { getRoutingStateFromCurrentUrl, routingActions } from 'common/components/Routing/reducers';
import { initialState, State } from 'common/reducers';
import { localeSelector } from 'common/selectors/localeSelector';
import ClientApp from 'common/components/App/ClientApp';

// Enable smoothscroll polyfill globally
smoothscroll.polyfill();

withWindow(w => generateConfig(undefined, w.siteBaseUrl));

const instrumentationKey = withWindow(w => w.instrumentationKey, '');

if (instrumentationKey && AppInsights.downloadAndSetup) {
	AppInsights.downloadAndSetup({ instrumentationKey });
}

const theState = withWindow(w => w.initialReduxState) || initialState;
const clientSideState: State = {
	...theState,
	routing: getRoutingStateFromCurrentUrl(),
	resource: {
		...theState.resource,
	},
};

const { store } = createStoreWithState(clientSideState);

withWindow(w => {
	// Prevent scroll memory to force scrollTop = 0 for initial nav height
	w.addEventListener('beforeunload', () => {
		w.scrollTo(0, 0);
	});
	// Init location listener
	w.addEventListener('popstate', e => {
		store.dispatch(routingActions.historyStateChanged(getRoutingStateFromCurrentUrl()));
	});
	w.addEventListener('beforeprint', () => {
		lazyImages.forceRender();
	});
	w.addEventListener('forceLazyImages', () => {
		lazyImages.forceRender();
	});
	if (w.matchMedia) {
		const mediaQueryList = w.matchMedia('print');
		mediaQueryList.addListener(mql => {
			if (mql.matches) {
				lazyImages.forceRender();
			}
		});
	}
});

const serverRendered = withWindow(w => w.serverRendered === true, true);
let messages: any;

if (serverRendered) {
	const locale = localeSelector(store.getState());
	messages = window.messages?.[locale];
}

if (serverRendered && messages) {
	loadableReady(() => {
		ReactDOM.hydrate(
			<Provider store={store}>
				<App messages={messages} />
			</Provider>,
			document.getElementById('root')
		);
	});
} else {
	ReactDOM.render(
		<Provider store={store}>
			<ClientApp />
		</Provider>,
		document.getElementById('root')
	);
}

registerServiceWorker();

/*
// For testing SSR effects on the client side
// This dispatches the END action just like in SSR
import { rootSagaCompleted } from './stores/store';
import { Timeout } from 'utils/timeout';
import { END } from 'redux-saga';

Timeout.of(100).then(async () => {
	store.dispatch(END);
});
*/
