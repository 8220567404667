import * as React from 'react';
import MediumHeader from 'styleguide/components/Typography/MediumHeader';
import { FormattedMessage } from 'react-intl';

const ModalConfirmation: React.FC<{}> = () => {
	return (
		<>
			<MediumHeader>
				<FormattedMessage id="materials_search_login_success_title" />
			</MediumHeader>
			<p>
				<FormattedMessage id="materials_search_login_success_description" />
			</p>
		</>
	);
};

export default ModalConfirmation;
