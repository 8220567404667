import * as React from 'react';
import StarSvg from './StarSvg';
import { StarSize, StarSizes, FillLevel, FillLevels } from '../types';
import { ResponsiveValues } from 'common/components/Media';
import { withTheme, DefaultTheme } from 'styled-components';

export interface StarProps {
	theme: DefaultTheme;
	index: number;
	starSize: StarSizes | ResponsiveValues<StarSizes>;
	fillLevel: FillLevels;
	className: string;
	onClick?: (index: number) => void;
	onMouseEnter?: (index: number) => void;
	onMouseLeave?: () => void;
}

class Star extends React.Component<StarProps> {
	public static defaultProps = {
		starSize: StarSize.small,
	};

	public render() {
		const { starSize, fillLevel, onMouseLeave, ...rest } = this.props;
		const firstHalfColor = this.getFirstHalfColor(fillLevel);
		const lastHalfColor = this.getLastHalfcolor(fillLevel);

		return (
			<StarSvg
				starSize={starSize}
				lastHalfColor={lastHalfColor}
				firstHalfColor={firstHalfColor}
				{...rest}
				onClick={this.onClick}
				onMouseEnter={this.onMouseEnter}
				onMouseLeave={onMouseLeave}
			/>
		);
	}

	private onClick = () => {
		if (this.props.onClick) {
			this.props.onClick(this.props.index);
		}
	};

	private onMouseEnter = () => {
		if (this.props.onMouseEnter) {
			this.props.onMouseEnter(this.props.index);
		}
	};

	private getLastHalfcolor(fillLevel: FillLevels) {
		const { theme } = this.props;
		return !fillLevel || fillLevel === FillLevel.half ? theme.colors.ratingUnselected : theme.colors.rating;
	}

	private getFirstHalfColor(fillLevel: FillLevels) {
		const { theme } = this.props;
		return fillLevel ? theme.colors.rating : theme.colors.ratingUnselected;
	}
}

export default withTheme(Star);
