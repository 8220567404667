export enum SearchUrl {
	ARTICLE = '/artikkelit/',
	RECIPES = '/reseptit/haku/',
	PRODUCTS = '/tuotteet/haku/',
	PROFESSIONAL_RECIPES = '/ammattilaiset/reseptit/haku/',
	MATERIAL = '/materiaalipankki/',
}

export enum SearchType {
	RECIPE = 'recipe',
	PROFESSIONAL_RECIPE = 'professional_recipe',
	PRODUCT = 'product',
	ARTICLE = 'article',
	MATERIAL = 'material',
}

export enum SearchResultType {
	RECIPE = 'recipe',
	PROFESSIONAL_RECIPE = 'professional_recipe',
	PRODUCT = 'product',
	ARTICLE = 'article',
	MATERIAL = 'material',
}

export enum SearchFilterName {
	ingredients = 'tarveaineet',
	author = 'tekija',
	context = 'context',
	bigPackaging = 'suurpakkaukset',
	takeaway = 'takeaway',
	themes = 'teemat',
	difficulty = 'vaikeusaste',
	maxTime = 'valmistusaikaMax',
}

export type SearchFilterType =
	| 'material'
	| 'secureMaterial'
	| 'AllergenicFeature'
	| 'Aspect'
	| 'Brand'
	| 'Ingredient'
	| 'NutrientFeature'
	| 'ProductGroup'
	| 'Context'
	| 'ContentFamily'
	| 'Novelty'
	| 'PackageFeature'
	| 'OnlyQuick'
	| 'ValioProduct'
	| 'Author'
	| 'AspectValueToggle'
	| 'CookingPreference';
