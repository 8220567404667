import { createStandardAction, getType, ActionType } from 'typesafe-actions';
import { Reducer } from 'redux';
import { WeeklyMenuContent, WeeklyMenuState } from './interfaces';
import { ResourceActions, resourceActions } from '../Resource/reducers/actions';
import { ResourceType } from '../Resource/types';

interface WeeklyMenuRequest {
	siteUrlId: string;
	year?: number;
	weekNumber?: number;
}

export const weeklyMenuActions = {
	requestWeeklyMenu: createStandardAction('WEEKLY_MENU/MENU_REQUEST')<WeeklyMenuRequest>(),
	setWeeklyMenu: createStandardAction('WEEKLY_MENU/MENU_SUCCESS')<WeeklyMenuContent>(),
	requestWeeklyMenuFailed: createStandardAction('WEEKLY_MENU/MENU_ERROR')<Error>(),
};

export type WeeklyMenuActions = ActionType<typeof weeklyMenuActions>;

const initialState: WeeklyMenuState = {
	isLoading: false,
};

export const weeklyMenuReducer: Reducer<WeeklyMenuState, WeeklyMenuActions | ResourceActions> = (
	state = initialState,
	action
): WeeklyMenuState => {
	switch (action.type) {
		case getType(resourceActions.setResource):
			if (action.payload.resource.type === ResourceType.kitchenMenu) {
				return {
					...state,
					isLoading: false,
					weeklyMenu: action.payload.resource.content,
				};
			}

		case getType(weeklyMenuActions.requestWeeklyMenu):
			return {
				...state,
				isLoading: true,
			};

		case getType(weeklyMenuActions.setWeeklyMenu):
			return {
				...state,
				isLoading: false,
				error: '',
				weeklyMenu: action.payload,
			};

		case getType(weeklyMenuActions.requestWeeklyMenuFailed):
			return {
				...state,
				error: action.payload.toString(),
			};

		default:
			return state;
	}
};
