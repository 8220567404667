import * as React from 'react';
import NormalIconWrapper, { NormalIconWrapperProps } from '../NormalIconWrapper';
import { ThemeContext } from 'styled-components';

const ProfessionalIcon: React.FC<NormalIconWrapperProps & React.SVGAttributes<SVGElement>> = ({ color, ...props }) => {
	const theme = React.useContext(ThemeContext);

	return (
		<NormalIconWrapper {...props}>
			<path
				fill={color ?? theme.colors.brandPrimary}
				d="M10.25 10.25a1 1 0 0 1 1 1v5.5a1 1 0 1 1-2 0v-5.5a1 1 0 0 1 1-1zm4-2a1 1 0 0 1 1 1v7.5a1 1 0 1 1-2 0v-7.5a1 1 0 0 1 1-1zm5.584 8.674l-.834.141V24H7v-2h9a1 1 0 1 0 0-2H7v-2.935l-.834-.141A4.98 4.98 0 0 1 2 12c0-2.757 2.243-5 4.97-5.001l.963.02.142-.849A4.98 4.98 0 0 1 13 2a4.979 4.979 0 0 1 4.925 4.17l.228.83H19c2.757 0 5 2.243 5 5a4.98 4.98 0 0 1-4.166 4.924m-.119-11.888A6.971 6.971 0 0 0 13 0a6.971 6.971 0 0 0-6.715 5.036C2.76 5.396 0 8.382 0 12a6.972 6.972 0 0 0 5 6.704V24c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2v-5.296A6.972 6.972 0 0 0 26 12c0-3.618-2.76-6.604-6.285-6.964"
			/>
		</NormalIconWrapper>
	);
};

export default ProfessionalIcon;
