import { postJson, fetchJson, throwMissingSiteUrlIdError } from 'utils/service';
import { RecipeApiIngredient } from '../interfaces';

export interface AddRatingResponseShape {
	averageRating: number;
	ratingCount: number;
}

async function addRating(recipeId: string, rating: number): Promise<AddRatingResponseShape> {
	return await postJson(`/api/resources/${recipeId}/ratings/`, {
		rating,
	});
}

export interface RecipePortionApiResponseData {
	title: string;
	orderNumber: number;
	ingredients: RecipeApiIngredient[];
}

export type RecipePortionApiResponse = RecipePortionApiResponseData[];

async function changePortion(
	siteUrlId: string,
	recipeId: string,
	quantity: number,
	weight?: number
): Promise<RecipePortionApiResponse> {
	if (!siteUrlId) {
		throwMissingSiteUrlIdError();
	}
	let url = `/api/sites/${siteUrlId}/recipes/${recipeId}/scaled?portions=${quantity}`;
	if (weight) {
		url = `${url}&portionSize=${weight}`;
	}
	return await fetchJson<RecipePortionApiResponse>(url);
}

export interface RecipeNutrientApiNutrient {
	gda: number;
	name: string;
	quantity: string;
	quantityUnit: string;
}

export type RecipeNutrientApiResponse = RecipeNutrientApiNutrient[];

async function updateNutrients(recipeId: string, portionSize: number): Promise<RecipeNutrientApiResponse> {
	return await fetchJson<RecipeNutrientApiResponse>(`/api/v2/nutritiondata/${recipeId}?portionsize=${portionSize}`);
}

export const recipeApi = {
	addRating,
	changePortion,
	updateNutrients,
};

export type RecipeApi = typeof recipeApi;
