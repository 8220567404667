import { withWindow } from 'styleguide/helpers/window';

export const isInViewport = (element: HTMLElement) => {
	return withWindow(w => {
		const scroll = window.scrollY || window.pageYOffset;
		const boundsTop = element.getBoundingClientRect().top + scroll;

		const viewport = {
			top: scroll,
			bottom: scroll + window.innerHeight,
		};

		const bounds = {
			top: boundsTop,
			bottom: boundsTop + element.clientHeight,
		};

		return (
			(bounds.bottom >= viewport.top && bounds.bottom <= viewport.bottom) ||
			(bounds.top <= viewport.bottom && bounds.top >= viewport.top)
		);
	});
};
