import { createStore, applyMiddleware, compose, AnyAction, Store, Middleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { withWindow } from 'styleguide/helpers/window';
import { getConfig } from 'config/config';
import reducers, { State } from 'common/reducers';
import { routingMiddleware } from 'middleware/routingMiddleware';
import rootSaga from 'common/sagas';

const sagaMiddleware = createSagaMiddleware();

declare global {
	interface Window {
		initialReduxState?: State;
	}
}

const config = getConfig();

// tslint:disable:no-string-literal
const reduxDevToolsCompose = withWindow(w => w['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__']);

const enableReduxDevTools = withWindow(w => {
	const search = w.location.search;
	return search && search.indexOf('reduxdev=1') >= 0;
}, false);

let composeEnhancers = compose;
if ((config.isDevelopment || enableReduxDevTools) && reduxDevToolsCompose) {
	composeEnhancers = reduxDevToolsCompose;
}

export interface StoreResult {
	store: Store<State, AnyAction>;
	rootSagaComplete: Promise<any>;
}

export const createStoreWithState = (state: State, ...middleware: Middleware[]): StoreResult => {
	const store = createStore(
		reducers,
		state,
		composeEnhancers(applyMiddleware(...middleware, routingMiddleware, sagaMiddleware))
	);
	const rootSagaTask = sagaMiddleware.run(rootSaga);

	return { store, rootSagaComplete: rootSagaTask.done };
};
