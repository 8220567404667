import { withWindow } from 'styleguide/helpers/window';
import debounce from 'lodash/debounce';

type ScrollCallback = (pageSize: number) => void;

class InfiniteScrollHelper {
	public static subscribe(scrollCallback: ScrollCallback): InfiniteScrollHelper {
		const infiniteScrollHelper = new InfiniteScrollHelper();
		infiniteScrollHelper.subscribe(scrollCallback);
		return infiniteScrollHelper;
	}

	public pageNumber: number;
	public infiniteScrollOffset: number = 600;
	public scrollCallback: ScrollCallback;

	private isLocked = true;

	private onScroll = debounce(() => {
		if (!this.isLocked && this.shouldTriggerInfiniteScroll()) {
			this.doInfiniteScroll();
		}
	});

	constructor(initialPage: number = 1) {
		this.pageNumber = initialPage;
	}

	public subscribe(scrollCallback: ScrollCallback) {
		this.scrollCallback = scrollCallback;
		withWindow(w => w.addEventListener('scroll', this.onScroll));
	}

	public unsubscribeInfiniteScroll() {
		this.reset();
		withWindow(w => w.removeEventListener('scroll', this.onScroll));
	}

	public unlockInfiniteScroll(prevCollection: any[], currentCollection: any[]) {
		if (prevCollection.length < currentCollection.length) {
			this.isLocked = false;
			this.shouldTriggerInfiniteScroll() && this.doInfiniteScroll();
		}
	}

	public reset(pageNumber: number = 1) {
		this.pageNumber = pageNumber;
		this.isLocked = false;
	}

	private shouldTriggerInfiniteScroll = () => {
		return withWindow(window => {
			const { clientHeight } = window.document.body;
			const { pageYOffset, innerHeight } = window;

			return clientHeight - this.infiniteScrollOffset <= innerHeight + pageYOffset;
		});
	};

	private doInfiniteScroll() {
		this.isLocked = true;
		this.pageNumber++;
		this.scrollCallback(this.pageNumber);
	}
}

export default InfiniteScrollHelper;
