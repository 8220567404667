import * as React from 'react';
import * as defaultIcon from '../../../../images/novelty-icon.svg';
import * as enIcon from '../../../../images/novelty-icon-en.svg';
import * as esIcon from '../../../../images/novelty-icon-es.svg';
import * as etIcon from '../../../../images/novelty-icon-et.svg';
import * as kaIcon from '../../../../images/novelty-icon-ka.svg';
import * as ltIcon from '../../../../images/novelty-icon-lt.svg';
import * as lvIcon from '../../../../images/novelty-icon-lv.svg';
import * as plIcon from '../../../../images/novelty-icon-pl.svg';
import * as ruIcon from '../../../../images/novelty-icon-ru.svg';
import * as svIcon from '../../../../images/novelty-icon-sv.svg';
import * as svIconNyhet from '../../../../images/novelty-icon-sv-nyhet.svg';
import * as aimoIcon from '../../../../images/novelty-icon-aimo.svg';
import FilteredIconImage from '../FilteredIconImage';
import { ThemeContext } from 'styled-components';

interface NoveltyIconProps {
	language?: string | null;
	forProduct?: boolean | null;
}

const resolveIcon = (language?: string | null) => {
	switch (language) {
		case 'en':
			return enIcon;
		case 'es':
			return esIcon;
		case 'et':
			return etIcon;
		case 'ka':
			return kaIcon;
		case 'lt':
			return ltIcon;
		case 'lv':
			return lvIcon;
		case 'pl':
			return plIcon;
		case 'ru':
			return ruIcon;
		case 'sv':
			return svIcon;
		case 'sv-nyhet':
			return svIconNyhet;
		case 'aimo':
			return aimoIcon;
		default:
			return defaultIcon;
	}
};

const Icon: React.FC<NoveltyIconProps & React.HTMLAttributes<HTMLImageElement>> = ({
	forProduct,
	language,
	...rest
}) => {
	const theme = React.useContext(ThemeContext);
	let actualLanguage = language;
	if (!!forProduct && !!language && language === 'sv') {
		actualLanguage = 'sv-nyhet';
	}
	const icon = resolveIcon((theme.options && theme.options.noveltyIconOverride) || actualLanguage);
	return <FilteredIconImage icon={icon} isTextIcon={true} {...rest} />;
};

export default Icon;
