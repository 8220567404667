import * as React from 'react';
import { isEmpty } from 'utils/object';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { FormValidationError } from './interfaces';

// Sourcery from https://medium.com/@jrwebdev/react-higher-order-component-patterns-in-typescript-42278f7590fb
type Omit<T, K> = Pick<T, Exclude<keyof T, K>>;
type Subtract<T, K> = Omit<T, keyof K>;

export interface InjectedFormProps<F> {
	fields: F;
	isValid: boolean;
	onInputChange: (value: any, name: string, validate?: boolean) => void;
	onInputFocus: (value: any, name: string) => void;
	onInputBlur: (value: any, name: string) => void;
	onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
}

export interface FormProps<F> {
	onFormSubmit: (fields: F) => void;
	disableClearOnSubmit?: boolean;
	fields?: F;
}

export interface FormState<F> {
	fields: F;
}

const injectForm = <F, V>(initialFields: F, validators?: V) => <P extends InjectedFormProps<F>>(
	ComposedComponent: React.ComponentType<P>
) => {
	class Form extends React.Component<
		Subtract<P, InjectedFormProps<F>> & FormProps<F> & WrappedComponentProps,
		FormState<F>
	> {
		private validators?: V;

		constructor(props: Subtract<P, InjectedFormProps<F>> & FormProps<F> & WrappedComponentProps) {
			super(props);

			this.validators = validators;

			this.state = {
				fields: this.getValidatedInitialFields(this.props.fields ? this.props.fields : initialFields),
			};
		}

		public render() {
			const { onFormSubmit, ...props } = this.props as FormProps<F>;
			const otherProps: any = {
				isValid: this.isValid(),
				onSubmit: this.onSubmit,
				onInputChange: this.onInputChange,
				onInputFocus: this.onInputFocus,
				onInputBlur: this.onInputBlur,
			};
			return <ComposedComponent {...props} {...this.state} {...otherProps} />;
		}

		private onInputChange = (value: any, name: string, validate?: boolean) => {
			this.setState(prevState => ({
				fields: Object.assign({}, prevState.fields, {
					[name]: {
						...prevState.fields[name],
						value,
						touched: true,
						error: validate ? this.validateInput(value, name) : prevState.fields[name].error,
					},
				}),
			}));
		};

		private onInputFocus = (value: any, name: string) => {
			this.setState(prevState => ({
				fields: Object.assign({}, prevState.fields, {
					[name]: {
						...prevState.fields[name],
						focused: true,
					},
				}),
			}));
		};

		private onInputBlur = (value: any, name: string) => {
			this.setState(prevState => ({
				fields: Object.assign({}, prevState.fields, {
					[name]: {
						...prevState.fields[name],
						value,
						focused: false,
						error: this.validateInput(value, name),
					},
				}),
			}));
		};

		private getValidatedInitialFields(fields: F): F {
			Object.keys(fields).forEach(key => {
				fields[key] = {
					...fields[key],
					error: this.validateInput(fields[key].value, key),
				};
			});
			return fields;
		}

		private validateInput(value: string, name: string): string | null {
			if (!this.validators || isEmpty(this.validators)) {
				return null;
			}
			const { intl } = this.props;

			return this.validators[name].reduce(
				(
					errors: string,
					validationFn: (value: string, fields?: F, props?: FormProps<F>) => FormValidationError | string
				) => {
					if (errors.length > 0) {
						return errors;
					}
					const error = validationFn(value, this.state && this.state.fields, this.props);
					if (!error) {
						return '';
					}
					if (typeof error === 'object') {
						return intl.formatMessage({ id: error.id }, error.values);
					}
					return intl.formatMessage({ id: error as string });
				},
				''
			);
		}

		private isValid(): boolean {
			const { fields } = this.state;
			return Object.keys(fields)
				.map(field => (fields[field].error ? fields[field].error.length === 0 : true))
				.every(r => r === true);
		}

		private onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
			const { onFormSubmit, disableClearOnSubmit } = this.props;
			event.preventDefault();
			if (this.isValid()) {
				onFormSubmit(this.state.fields);
				if (!disableClearOnSubmit) {
					this.clearFormFields();
				}
			}
		};

		private clearFormFields() {
			this.setState(prevState => ({ ...prevState, fields: initialFields }));
		}
	}

	return injectIntl(Form);
};

export default injectForm;
