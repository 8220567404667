import { Reducer } from 'redux';
import { getType } from 'typesafe-actions';
import { ResourceActions, resourceActions } from 'common/components/Resource/reducers/actions';
import { ResourceType } from 'common/components/Resource/types';
import { parseRecipe } from '../helpers';
import { RecipeDetails } from '../interfaces';

export const recipeDetailsReducer: Reducer<RecipeDetails, ResourceActions> = (
	state = {
		isLoading: false,
	},
	action
): RecipeDetails => {
	switch (action.type) {
		case getType(resourceActions.setResource):
			if (
				action.payload.resource.type === ResourceType.recipe ||
				action.payload.resource.type === ResourceType.professionalRecipe
			) {
				return {
					recipe: parseRecipe(action.payload.resource),
					resourceId: action.payload.resource.id,
					isLoading: false,
					error: null,
				};
			}

		default:
			return state;
	}
};
