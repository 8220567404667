import { ResourceType } from 'common/components/Resource/types';
import { Reducer } from 'redux';
import { ResourceActions, resourceActions } from 'common/components/Resource/reducers/actions';
import { getType } from 'typesafe-actions';
import { ProductGroupContentState } from './interfaces';

const productGroupInitialState: ProductGroupContentState = {
	contentFamily: '',
	leadText: '',
};

export const productGroupReducer: Reducer<ProductGroupContentState, ResourceActions> = (
	state = productGroupInitialState,
	action
): ProductGroupContentState => {
	switch (action.type) {
		case getType(resourceActions.setResource):
			if (action.payload.resource.type === ResourceType.productGroup) {
				const { contentFamily, leadText } = action.payload.resource.content;
				return {
					...state,
					contentFamily,
					leadText,
				};
			}
		default:
			return state;
	}
};
