import * as React from 'react';
import { ThemeContext } from 'styled-components';

export interface ContentTheme {
	color: string;
}

export const ContentThemeContext = React.createContext<ContentTheme>({} as ContentTheme);

export const ContentThemeConsumer = ContentThemeContext.Consumer;

interface Props {
	contentTheme?: ContentTheme;
}

export const ContentThemeProvider: React.FC<Props> = ({ contentTheme, children }) => {
	const theme = React.useContext(ThemeContext);

	if (!children) {
		return null;
	}

	const defaultTheme: ContentTheme = {
		color: theme.colors.brandPrimary,
	};

	return <ContentThemeContext.Provider value={contentTheme ?? defaultTheme}>{children}</ContentThemeContext.Provider>;
};
