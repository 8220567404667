export function flatten<T>(array: T[][]): T[] {
	return array.reduce((acc, val) => acc.concat(val), []);
}

export function flatMap<T, V>(array: T[], projection: (item: T) => V[]): V[] {
	return flatten(array.map(projection));
}

export function filterNonNull<T>(array: Array<T | null | undefined>): T[] {
	return array.filter(x => x !== null && x !== undefined) as T[];
}

export function chunk<T>(array: T[], chunkCount: number): T[][] {
	const chunks = [];
	while (array.length) {
		const chunkSize = Math.ceil(array.length / chunkCount--);
		const currentChunk = array.slice(0, chunkSize);
		chunks.push(currentChunk);
		array = array.slice(chunkSize);
	}
	return chunks;
}
