import { createCustomAction, getType, ActionType } from 'typesafe-actions';
import { Reducer } from 'redux';
import { RecipePortionApiResponse } from '../services/services';
import { parseRecipePortionData } from '../helpers';
import { RecipeDetails } from '../interfaces';

export const scaleActions = {
	scaleRecipe: createCustomAction(
		'RECIPE/FETCH_PORTION_REQUEST',
		type => (siteUrlId: string, recipeId: string, quantity: number, weight?: number) => ({
			type,
			payload: {
				siteUrlId,
				recipeId,
				quantity,
				weight,
			},
		})
	),

	updateScaledRecipe: createCustomAction(
		'RECIPE/FETCH_PORTION_SUCCESS',
		type => (scaled: RecipePortionApiResponse, newQuantity: number, newWeight?: number) => ({
			type,
			payload: { scaled, newQuantity, newWeight },
		})
	),

	scaleFailed: createCustomAction('RECIPE/FETCH_PORTION_FAILURE', type => (error: string) => ({
		type,
		payload: { error },
	})),
};

export const scaleReducer: Reducer<RecipeDetails, ScaleActions> = (
	state = {
		isLoading: false,
	},
	action
): RecipeDetails => {
	switch (action.type) {
		case getType(scaleActions.scaleRecipe):
			if (!state.recipe) {
				return state;
			}

			return {
				...state,
				recipe: {
					...state.recipe,
					portion: {
						...state.recipe.portion,
						isLoading: true,
					},
				},
			};

		case getType(scaleActions.updateScaledRecipe):
			if (!state.recipe) {
				return state;
			}

			return {
				...state,
				recipe: parseRecipePortionData(
					state.recipe,
					action.payload.scaled,
					action.payload.newQuantity,
					action.payload.newWeight
				),
				isLoading: false,
			};

		case getType(scaleActions.scaleFailed):
			if (!state.recipe) {
				return state;
			}
			return {
				...state,
				recipe: {
					...state.recipe,
					portion: {
						...state.recipe.portion,
						isLoading: false,
						error: action.payload.error,
					},
				},
			};

		default:
			return state;
	}
};

export type ScaleActions = ActionType<typeof scaleActions>;
