import * as React from 'react';
import IconWrapper from '../../IconWrapper';
import { ThemeContext } from 'styled-components';

export interface InfoIconProps {
	size?: number;
	backgroundColor?: string;
	infoIconColor?: string;
}

const InfoIcon: React.FC<InfoIconProps & React.SVGAttributes<SVGElement>> = ({
	backgroundColor,
	infoIconColor,
	...rest
}) => {
	const theme = React.useContext(ThemeContext);

	return (
		<IconWrapper viewBox="0 0 20 20" {...rest}>
			<circle cx="10" cy="10" r="10" fill={backgroundColor ?? theme.colors.inputInfo} />
			<path
				fill={infoIconColor ?? theme.colors.white}
				d="M10.008 6.465c.735 0 1.185-.405 1.185-1.08s-.45-1.065-1.185-1.065c-.735 0-1.185.405-1.185 1.065 0 .675.45 1.08 1.184 1.08zm-.105 8.595h.224c.54 0 .93-.24.93-1.005v-5.43c0-.78-.375-1.02-.944-1.02h-.226c-.54 0-.944.255-.944 1.02v5.43c0 .78.405 1.005.96 1.005z"
			/>
		</IconWrapper>
	);
};

InfoIcon.defaultProps = {
	size: 20,
};

export default InfoIcon;
