import * as React from 'react';
import Star from './Star';
import { StarSizes, StarSize, FillLevel } from './types';
import styled from 'styled-components';
import { ResponsiveValues } from 'common/components/Media';

const RANGE_START: number = 0;
const RANGE_END: number = 5;

const normalizeRating = (rating: number, startRange: number, endRange: number): number => {
	if (rating < startRange) {
		return startRange;
	} else if (rating > endRange) {
		return endRange;
	} else {
		return rating;
	}
};

const StarsWrapper = styled.span`
	display: inline-block;
	white-space: nowrap;
`;

const starsRange = [1, 2, 3, 4, 5];

export interface StarsProps {
	selectable?: boolean;
	size?: StarSizes | ResponsiveValues<StarSizes>;
	rating: number;
	onRatingSelected?: (rating: number) => void;
}

export interface StarsState {
	currentRating: number;
}

export default class Stars extends React.Component<StarsProps, StarsState> {
	public static defaultProps = {
		selectable: false,
		size: StarSize.small,
		rating: RANGE_START,
	};

	public static getDerivedStateFromProps(nextProps: StarsProps, prevState: StarsState) {
		return {
			currentRating: nextProps.rating,
		};
	}

	constructor(props: StarsProps) {
		super(props);
		this.state = { currentRating: 0 };
	}

	public render() {
		return <StarsWrapper>{this.renderStars()}</StarsWrapper>;
	}

	private handleClick = (rating: number) => {
		if (this.props.selectable) {
			this.setState({ currentRating: rating });
			if (this.props.onRatingSelected) {
				this.props.onRatingSelected(rating);
			}
		}
	};

	private handleMouseEnter = (rating: number) => {
		if (this.props.selectable) {
			this.setState({ currentRating: rating });
		}
	};

	private handleMouseLeave = () => {
		if (this.props.selectable) {
			this.setState(prevState => ({
				currentRating: this.props.rating,
			}));
		}
	};

	private getStarClassName(index: number) {
		if (this.props.selectable) {
			let className = `ga-star-rating-${index}`;
			if (this.state.currentRating === index) {
				className += ` ga-star-rating-${index}--selected`;
			}
			return className;
		}

		return '';
	}

	private renderStars() {
		const { size } = this.props;
		const { currentRating } = this.state;

		const actualRating = normalizeRating(Math.round(currentRating), RANGE_START, RANGE_END);

		return starsRange.map((index: number) => (
			<Star
				key={`star-${index}`}
				className={this.getStarClassName(index)}
				index={index}
				starSize={size || StarSize.small}
				fillLevel={actualRating > 0 && index <= actualRating ? FillLevel.full : undefined}
				onClick={this.handleClick}
				onMouseEnter={this.handleMouseEnter}
				onMouseLeave={this.handleMouseLeave}
			/>
		));
	}
}
