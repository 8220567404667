import * as React from 'react';
import Divider, { DividerDirection, DividerSize } from 'styleguide/components/Divider/Divider';
import { Button } from 'styleguide/components/Buttons/Button';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

const Wrapper = styled.div``;

interface Props {
	onClose: () => void;
}

const RegistrationRequestSent: React.FC<Props> = ({ onClose }) => {
	return (
		<Wrapper>
			<p>
				<FormattedMessage id="component_login_registration_request_sent_title" />
			</p>
			<Divider direction={DividerDirection.horizontal} size={DividerSize.full} />
			<Button onClick={onClose} size="medium">
				<FormattedMessage id="global_close" />
			</Button>
		</Wrapper>
	);
};

export default RegistrationRequestSent;
