import { withWindow } from 'styleguide/helpers/window';

export const getDocumentHeight = (): number => {
	const body = document.body;
	const html = document.documentElement;

	return Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
};

export const getScrollbarWidth = (): number => {
	let scrollbarWidth = 15;

	withWindow(w => {
		const windowWidth = w.innerWidth;
		const windowHeight = w.innerHeight;
		const documentWidth = document.body.offsetWidth;
		const documentHeight = getDocumentHeight();

		if ((w.scrollbars && w.scrollbars.visible) || documentHeight > windowHeight) {
			scrollbarWidth = windowWidth - documentWidth;
		} else {
			scrollbarWidth = 0;
		}
	});

	return scrollbarWidth;
};
