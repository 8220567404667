import * as React from 'react';
import styled from 'styled-components';
import MediumHeader from 'styleguide/components/Typography/MediumHeader/MediumHeader';
import { FormattedMessage } from 'react-intl';

const ResourceLoadErrorContainer = styled.section`
	margin-top: 200px;
	> svg {
		display: block;
		margin: auto;
	}

	> h1 {
		margin-top: 42px;
		text-align: center;
	}

	> p {
		margin: 13px 0 23px 0;
		text-align: center;
		font-size: 18px;
		line-height: 1.33;
		font-family: ${props => props.theme.fonts.secondary};
	}
`;

const ResourceLoadError: React.FC = () => (
	<ResourceLoadErrorContainer>
		<svg xmlns="http://www.w3.org/2000/svg" width="88" height="39" viewBox="0 0 88 39">
			<path
				d="M87.342 32.92c-.898-3.923-14.192-6.876-22.374-6.876a1 1 0 0 0 0 2c9.333 0 19.998 3.461 20.425 5.322.017.077-.185.363-.902.71-7.722 3.735-33.626 2.65-35.619.267.07-.087.225-.244.558-.46.894-.58 3.616-2.349 3.11-4.51-.122-.518-.42-.955-.818-1.329h8.33a1 1 0 0 0 0-2H41.385a1 1 0 0 0 0 2h4.722c1.985.24 4.313 1.047 4.485 1.785.02.081.118.838-2.25 2.377-1.97 1.28-1.548 2.545-1.275 3.03 1.285 2.28 9.344 3.316 17.894 3.316 8.193 0 16.837-.952 20.402-2.676 2.081-1.006 2.133-2.284 1.98-2.956M2.264 17.188c-.535-5.222-.113-8.368.268-9.096l4.4-.892c.541.635 1.648 3.751 2.211 9.242.478 4.666.223 7.726-.11 8.993-.589-1.265-1.424-4.199-1.89-8.746a50.64 50.64 0 0 1-.25-3.864c-.014-.552-.496-1.009-1.025-.975a.998.998 0 0 0-.975 1.023c.03 1.278.118 2.63.26 4.02.292 2.851.783 5.518 1.382 7.511.164.546.358 1.11.584 1.631l-2.745.011c-.518-.626-1.573-3.623-2.11-8.858zm1.835 8.86a.702.702 0 0 1 .122 0H4.1zm7.033-9.81c-.246-2.402-.874-7.02-2.16-9.452l21.755-4.413c-.928 3.418-.428 9.576-.188 11.92.36 3.514.965 6.801 1.703 9.254.291.968.587 1.745.896 2.38l-22.228.092c.801-2.626.471-7.352.222-9.78zm24.382-2.456c.662 6.472.19 10.54-.298 11.811-.736-1.146-2.024-5.033-2.687-11.505-.663-6.472-.19-10.539.297-11.81.736 1.145 2.024 5.033 2.688 11.504zm.287-9.46c-.937-3.113-1.913-4.44-3.207-4.314a1.427 1.427 0 0 0-.3.065C32.196.064 32.1.053 32 .074L2.061 6.151c-2.624.27-2.124 7.951-1.786 11.241.278 2.718.746 5.262 1.318 7.163.492 1.64 1.226 3.494 2.475 3.494.045 0 .091-.002.137-.007l4.911-.02c.02.001.038.011.06.011.05 0 .1-.003.152-.009.004 0 .007-.002.01-.003l25.514-.103c.111 0 .213-.03.312-.064.04.003.075.017.114.017.056 0 .113-.003.17-.009 1.281-.13 1.98-1.634 2.265-4.875.224-2.55.15-5.893-.21-9.409-.36-3.515-.965-6.803-1.702-9.256z"
				fill="#666"
			/>
		</svg>
		<MediumHeader tagName="h1" greyed>
			<FormattedMessage id="global_resource_load_error_oh_no" />
		</MediumHeader>
		<p>
			<FormattedMessage id="global_resource_error" />
		</p>
	</ResourceLoadErrorContainer>
);

export default ResourceLoadError;
