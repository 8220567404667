import { LazyImage } from './LazyImage';

class LazyImageRegistry {
	private images: LazyImage[] = [];
	public add(image: LazyImage) {
		this.images.push(image);
	}
	public remove(image: LazyImage) {
		const index = this.images.indexOf(image);
		if (index >= 0) {
			this.images.splice(index, 1);
		}
	}
	public forceRender() {
		for (const image of this.images) {
			if (image) {
				image.setState({ forceRender: true });
			}
		}
		this.images = [];
	}
}

export const lazyImages = new LazyImageRegistry();
