export { default as ActiveSeniorIcon } from './ActiveSeniorIcon';
export { default as AdultIcon } from './AdultIcon';
export { default as BabyIcon } from './BabyIcon';
export { default as BakingBreadIcon } from './BakingBreadIcon';
export { default as BakingBunsIcon } from './BakingBunsIcon';
export { default as BakingCakesIcon } from './BakingCakesIcon';
export { default as BakingDoughIcon } from './BakingDoughIcon';
export { default as BakingMuffinsIcon } from './BakingMuffinsIcon';
export { default as BakingSavouryPastryIcon } from './BakingSavouryPastryIcon';
export { default as ButterIcon } from './ButterIcon';
export { default as ButterMilkIcon } from './ButterMilkIcon';
export { default as CheeseIcon } from './CheeseIcon';
export { default as CheeseTrayIcon } from './CheeseTrayIcon';
export { default as ChickenIcon } from './ChickenIcon';
export { default as ChocolateIcon } from './ChocolateIcon';
export { default as CowIcon } from './CowIcon';
export { default as CreamIcon } from './CreamIcon';
export { default as CurdIcon } from './CurdIcon';
export { default as DessertIcon } from './DessertIcon';
export { default as DietDairyFreeIcon } from './DietDairyFreeIcon';
export { default as DietEggFreeIcon } from './DietEggFreeIcon';
export { default as DietGlutenFreeIcon } from './DietGlutenFreeIcon';
export { default as DietLactoseFreeIcon } from './DietLactoseFreeIcon';
export { default as DietLightIcon } from './DietLightIcon';
export { default as DietLowLactoseIcon } from './DietLowLactoseIcon';
export { default as DietProteinIcon } from './DietProteinIcon';
export { default as DietVegetarianIcon } from './DietVegetarianIcon';
export { default as DietLowCarbIcon } from './DietLowCarbIcon';
export { default as DocumentIcon } from './DocumentIcon';
export { default as EveryDayIcon } from './EveryDayIcon';
export { default as FamilyIcon } from './FamilyIcon';
export { default as FishIcon } from './FishIcon';
export { default as FlagIcon } from './FlagIcon';
export { default as ForKidsIcon } from './ForKidsIcon';
export { default as GlobeIcon } from './GlobeIcon';
export { default as HandIcon } from './HandIcon';
export { default as HealthcareIcon } from './HealthcareIcon';
export { default as IceCreamIcon } from './IceCreamIcon';
export { default as IndustrialIcon } from './IndustrialIcon';
export { default as JuiceIcon } from './JuiceIcon';
export { default as KidIcon } from './KidIcon';
export { default as LegislationIcon } from './LegislationIcon';
export { default as MeatIcon } from './MeatIcon';
export { default as MifuIcon } from './MifuIcon';
export { default as MifuRecipeIcon } from './MifuRecipeIcon';
export { default as MilkIcon } from './MilkIcon';
export { default as MincedMeatIcon } from './MincedMeatIcon';
export { default as MobileIcon } from './MobileIcon';
export { default as MothersDayIcon } from './MothersDayIcon';
export { default as NewsIcon } from './NewsIcon';
export { default as NoveltyIcon } from './NoveltyIcon';
export { default as NutritionIcon } from './NutritionIcon';
export { default as OrganicIcon } from './OrganicIcon';
export { default as PartyBbqIcon } from './PartyBbqIcon';
export { default as PartyEasterIcon } from './PartyEasterIcon';
export { default as PartyGraduationIcon } from './PartyGraduationIcon';
export { default as PartyHalloweenIcon } from './PartyHalloweenIcon';
export { default as PartyHarvestIcon } from './PartyHarvestIcon';
export { default as PartyKidsIcon } from './PartyKidsIcon';
export { default as PartyLabourDayIcon } from './PartyLabourDayIcon';
export { default as PartyXmasIcon } from './PartyXmasIcon';
export { default as PowderIcon } from './PowderIcon';
export { default as ProductsIcon } from './ProductsIcon';
export { default as PromotionIcon } from './PromotionIcon';
export { default as ProteinCounterIcon } from './ProteinCounterIcon';
export { default as ProteinIcon } from './ProteinIcon';
export { default as PuddingIcon } from './PuddingIcon';
export { default as RecipeIcon } from './RecipeIcon';
export { default as ResponsibilityBeeIcon } from './ResponsibilityBeeIcon';
export { default as ResponsibilityBuyIcon } from './ResponsibilityBuyIcon';
export { default as ResponsibilityChainIcon } from './ResponsibilityChainIcon';
export { default as ResponsibilityCowIcon } from './ResponsibilityCowIcon';
export { default as ResponsibilityFlagIcon } from './ResponsibilityFlagIcon';
export { default as ResponsibilityPlantIcon } from './ResponsibilityPlantIcon';
export { default as ResponsibilityRecyclingIcon } from './ResponsibilityRecyclingIcon';
export { default as ResponsibilityTruckIcon } from './ResponsibilityTruckIcon';
export { default as SaladIcon } from './SaladIcon';
export { default as SalesPresentationIcon } from './SalesPresentationIcon';
export { default as SaltIcon } from './SaltIcon';
export { default as SeniorIcon } from './SeniorIcon';
export { default as SomeIcon } from './SomeIcon';
export { default as SoupIcon } from './SoupIcon';
export { default as SouredWholeMilkIcon } from './SouredWholeMilkIcon';
export { default as SugarIcon } from './SugarIcon';
export { default as SustainabilityIcon } from './SustainabilityIcon';
export { default as SweetPastryIcon } from './SweetPastryIcon';
export { default as VegeIcon } from './VegeIcon';
export { default as VegeRecipeIcon } from './VegeRecipeIcon';
export { default as VitaminsIcon } from './VitaminsIcon';
export { default as YogurtIcon } from './YogurtIcon';
export { default as YoungIcon } from './YoungIcon';
export { default as BulkPackageIcon } from './BulkPackageIcon';
export { default as PortionPackageIcon } from './PortionPackageIcon';
