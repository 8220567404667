import { Reducer } from 'redux';
import { UserActions, userActions } from './actions';
import { getType } from 'typesafe-actions';
import { ShoppingListApiRecipeItem, ShoppingListApiStoreSection, ShoppingListApiItem } from '../services/shopping-list';

const UNCLASSIFIED_STORE_SECTION_ID = 'UNCLASSIFIED_STORE_SECTION_ID';
const USER_GENERATED_STORE_SECTION_ID = 'USER_GENERATED_STORE_SECTION_ID';

export interface UserShoppingListSharingShape {
	error?: string;
	isLoading: boolean;
}

export interface UserShoppingListShape {
	shoppingListId?: string | null;
	isLoading: boolean;
	recipes: ShoppingListApiRecipeItem[];
	storeSections: ShoppingListStoreSectionShape[];
	emailSharing?: UserShoppingListSharingShape;
}

export const userShoppingListInitialState: UserShoppingListShape = {
	shoppingListId: '',
	isLoading: false,
	recipes: [],
	storeSections: [],
};

export const userShoppingListReducer: Reducer<UserShoppingListShape, UserActions> = (
	state = userShoppingListInitialState,
	action
) => {
	if (action.type === getType(userActions.setShoppingList)) {
		const { recipes, id } = action.payload;
		return {
			shoppingListId: id,
			isLoading: false,
			recipes,
			storeSections: shoppingListStoreSectionsReducer(state.storeSections, action),
		};
	}

	if (action.type === getType(userActions.changeShoppingListRecipePortionSucceeded)) {
		return {
			...state,
			recipes: state.recipes.map(recipe => {
				if (recipe.id !== action.payload.recipeId) {
					return recipe;
				}
				return {
					...recipe,
					portions: action.payload.quantity,
				};
			}),
			storeSections: shoppingListStoreSectionsReducer(state.storeSections, action),
		};
	}

	if (
		action.type === getType(userActions.addShoppingListItemSuccess) ||
		action.type === getType(userActions.toggleShoppingListItemPickedSuccess)
	) {
		return {
			...state,
			storeSections: shoppingListStoreSectionsReducer(state.storeSections, action),
		};
	}

	if (
		action.type === getType(userActions.openEmailSharing) ||
		action.type === getType(userActions.shareShoppingListToEmail) ||
		action.type === getType(userActions.shareShoppingListToEmailSuccess) ||
		action.type === getType(userActions.shareShoppingListToEmailError)
	) {
		return {
			...state,
			emailSharing: emailSharingReducer(state.emailSharing, action),
		};
	}

	if (action.type === getType(userActions.closeEmailSharing)) {
		const { emailSharing, ...rest } = state;
		return {
			...rest,
		};
	}

	return state;
};

const emailSharingReducer: Reducer<UserShoppingListSharingShape, UserActions> = (
	state = { isLoading: false },
	action
) => {
	if (action.type === getType(userActions.shareShoppingListToEmail)) {
		return {
			...state,
			isShared: false,
			isLoading: true,
		};
	}

	if (action.type === getType(userActions.shareShoppingListToEmailSuccess)) {
		return {
			...state,
			isShared: true,
			isLoading: false,
		};
	}

	if (action.type === getType(userActions.shareShoppingListToEmailError)) {
		return {
			...state,
			isShared: false,
			isLoading: false,
			error: action.payload,
		};
	}

	return state;
};

export interface ShoppingListStoreSectionShape extends ShoppingListApiStoreSection {
	items: ShoppingListApiItem[];
}

const shoppingListStoreSectionsReducer: Reducer<ShoppingListStoreSectionShape[], UserActions> = (
	state = [],
	action
) => {
	if (action.type === getType(userActions.setShoppingList)) {
		const { items } = action.payload;
		const storeSections: ShoppingListStoreSectionShape[] = [];

		items.forEach(item => {
			const existingStoreSection = storeSections.find((storeSection: ShoppingListApiStoreSection) => {
				if (!item.storeSection) {
					if (item.category === 'userGenerated') {
						return storeSection.id === USER_GENERATED_STORE_SECTION_ID;
					}
					return storeSection.id === UNCLASSIFIED_STORE_SECTION_ID;
				}
				return storeSection.id === item.storeSection.id;
			});

			if (existingStoreSection) {
				existingStoreSection.items.push(item);
				return;
			}

			if (!item.storeSection) {
				storeSections.push({
					id: item.category === 'userGenerated' ? USER_GENERATED_STORE_SECTION_ID : UNCLASSIFIED_STORE_SECTION_ID,
					// Bit of a hack, but this is an easy way to order these two special cases
					orderNumber: item.category === 'userGenerated' ? 1100 : 1000,
					items: [item],
				});
				return;
			}

			storeSections.push({
				...item.storeSection,
				items: [item],
			});
		});

		return storeSections.sort(sortByStoreSection);
	}

	if (
		action.type === getType(userActions.changeShoppingListRecipePortionSucceeded) ||
		action.type === getType(userActions.toggleShoppingListItemPickedSuccess)
	) {
		return state.map(storeSection => {
			return {
				...storeSection,
				items: shoppingListItemsReducer(storeSection.items, action),
			};
		});
	}

	if (action.type === getType(userActions.addShoppingListItemSuccess)) {
		const userGeneratedStoreSection = state.find(storeSection => storeSection.id === USER_GENERATED_STORE_SECTION_ID);
		if (!userGeneratedStoreSection) {
			return [
				...state,
				{
					id: USER_GENERATED_STORE_SECTION_ID,
					items: [action.payload],
				},
			];
		}
		return state.map(storeSection => {
			if (storeSection.id === USER_GENERATED_STORE_SECTION_ID) {
				return {
					...storeSection,
					items: [...storeSection.items, action.payload],
				};
			}
			return storeSection;
		});
	}

	return state;
};

const shoppingListItemsReducer: Reducer<ShoppingListApiItem[], UserActions> = (state = [], action) => {
	if (action.type === getType(userActions.changeShoppingListRecipePortionSucceeded)) {
		return state.map(item => {
			const updatedItem = action.payload.items.find(i => i.id === item.id);
			if (updatedItem) {
				return {
					...item,
					quantity: updatedItem.quantity,
					quantityString: updatedItem.quantityString,
				};
			}
			return item;
		});
	}

	if (action.type === getType(userActions.toggleShoppingListItemPickedSuccess)) {
		return state.map(item => {
			const { id, ignored } = action.payload;
			if (item.id === id) {
				return {
					...item,
					ignored,
				};
			}
			return item;
		});
	}

	return state;
};

const sortByStoreSection = (a: ShoppingListStoreSectionShape, b: ShoppingListStoreSectionShape) => {
	if (!a.orderNumber) {
		return 1;
	}
	if (!b.orderNumber) {
		return -1;
	}
	if (a.orderNumber < b.orderNumber) {
		return -1;
	}
	if (a.orderNumber > b.orderNumber) {
		return 1;
	}
	return 0;
};
