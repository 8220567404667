import { fork, put, call, takeLatest } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { CookbookApi, cookbookApi, SendUserRecipesApiResponse, UserRecipesApiRecipes } from '../services/cookbook';
import { userActions } from '../reducers/actions';

export function* fetchUserRecipes(api: CookbookApi) {
	try {
		const result: UserRecipesApiRecipes[] = yield call(api.fetchUserRecipes);

		yield put(userActions.fetchUserRecipesSucceeded(result));
	} catch (e) {
		yield put(userActions.fetchUserRecipesError(e));
	}
}

export function* watchFetchUserRecipesSaga() {
	yield takeLatest(getType(userActions.fetchUserRecipes), fetchUserRecipes, cookbookApi);
}

export function* sendUserRecipes(api: CookbookApi) {
	try {
		const result: SendUserRecipesApiResponse = yield call(api.sendUserRecipes);

		yield put(userActions.sendUserRecipesSucceeded(result));
	} catch (e) {
		yield put(userActions.sendUserRecipesError(e));
	}
}

export function* watchSendUserRecipesSaga() {
	yield takeLatest(getType(userActions.sendUserRecipes), sendUserRecipes, cookbookApi);
}

export function* userRecipesSaga() {
	yield fork(watchFetchUserRecipesSaga);
	yield fork(watchSendUserRecipesSaga);
}
