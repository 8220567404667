import * as React from 'react';
import styled from 'styled-components';
import { media } from 'styleguide/helpers/media';
import { contentWrapperStyles, ContentWrapperProps } from '.';

export const OffsetContentWrapper = styled('section')<ContentWrapperProps>`
	${({ theme }) => contentWrapperStyles(theme)};
	display: ${props => (props.isMobileHidden ? 'none' : 'block')};
	margin: 0 auto -60px;

	${media.tablet<ContentWrapperProps>`
		display: block;
  `};

	${media.desktop`
    margin: -60px auto -80px;
  `}

	@media print {
		display: none;
	}
`;

const ContentCardWrapper = styled.div`
	border-radius: 3px;
	position: relative;
	background-color: ${props => props.theme.colors.bodyBackground};
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
	height: 60px;

	${media.desktop`
    height: 80px;
  `};

	&:after {
		content: '';
		display: block;
		position: absolute;
		height: 6px;
		background-color: ${({ theme }) => theme.colors.bodyBackground};
		bottom: -10px;
		box-shadow: 0 -10px 15px 15px ${({ theme }) => theme.colors.bodyBackground};
		left: 0px;
		right: 0px;

		${media.desktop`
      bottom: -20px;
    `};
	}
`;

const ContentCard: React.FC<ContentWrapperProps & React.HTMLAttributes<HTMLDivElement>> = ({
	hasContentSubNav,
	isMobileHidden,
	children,
	...rest
}) => {
	return (
		<OffsetContentWrapper hasContentSubNav={hasContentSubNav} isMobileHidden={isMobileHidden}>
			<ContentCardWrapper {...rest}>{children}</ContentCardWrapper>
		</OffsetContentWrapper>
	);
};

export default ContentCard;
