(function (global) {
	'use strict';
	
	// fetch() polyfill for making API calls.
	require('whatwg-fetch');

	// In tests, polyfill requestAnimationFrame since jsdom doesn't provide it yet.
	// We don't polyfill it in the browser--this is user's responsibility.
	if (process.env.NODE_ENV === 'test') {
		require('raf').polyfill(global);
	}
})(this);
