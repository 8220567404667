export enum ShoppingListColours {
	'shoppingListBlue',
	'shoppingListRed',
	'shoppingListPurple',
	'shoppingListTurquoise',
	'shoppingListOrange',
	'shoppingListGreen',
	'shoppingListLightBlue',
	'shoppingListGrey',
}
