import * as React from 'react';
import MostPopularKeywords from './MostPopularKeywords';
import SearchCategories from './SearchCategories';
import styled, { css } from 'styled-components';
import { InputSize } from 'styleguide/types/inputTypes';
import { media, mediaBreakpoints } from 'styleguide/helpers/media';
import { inPixels } from 'styleguide/helpers/helper';
import SearchField from 'styleguide/components/Inputs/SearchField/SearchField';
import CloseIcon from 'styleguide/components/Icons/NormalIcons/CloseIcon/CloseIcon';
import SearchIcon from 'styleguide/components/Icons/NormalIcons/SearchIcon/SearchIcon';
import { withWindow } from 'styleguide/helpers/window';
import { SearchResultCollection, NavLinkItemProps } from '../../interfaces';
import { SearchType } from 'pagetypes/Search/types';
import injectRoutable, { InjectedRoutableProps } from '../../../Routing/Routable';
import { ResponsiveValues } from 'common/components/Media';

interface SearchWrapperProps {
	size: InputSize | ResponsiveValues<InputSize>;
	isDesktopWidthFluid?: boolean;
	searchActive?: boolean;
	isMobileInActiveHidden?: boolean;
}

const getWidthByInputSize = (size?: InputSize) => {
	return size === InputSize.small ? inPixels(375) : inPixels(655);
};

const SearchWrapper = styled.div<SearchWrapperProps>`
	width: 100%;
	position: relative;
	pointer-events: auto;
	display: ${({ searchActive, isMobileInActiveHidden }) =>
		isMobileInActiveHidden && !searchActive ? 'none' : 'block'};

	${media.desktop<SearchWrapperProps>`
		position: relative;
		display: block;

		${props => {
			if (props.isDesktopWidthFluid) {
				return css`
					width: 100%;
				`;
			}

			if (typeof props.size === 'object') {
				const sizeMap = props.size as ResponsiveValues<InputSize>;

				return css`
					${Object.keys(sizeMap).map(key => {
						if (mediaBreakpoints[key] >= mediaBreakpoints.desktop) {
							return css`
								width: ${getWidthByInputSize(sizeMap[key])};
							`;
						}
						return null;
					})};
				`;
			}

			return css`
				width: ${getWidthByInputSize(props.size)};
			`;
		}};
  `};
`;

const SearchResultContent = styled.div``;

const borderRadius = inPixels(3);

const SearchResultsWrapper = styled.div`
	width: 100%;
	background-color: ${props => props.theme.colors.white};
	border-bottom-left-radius: ${borderRadius};
	border-bottom-right-radius: ${borderRadius};
	position: absolute;
	z-index: 1;
`;

const SearchResultsContainer = styled.div`
	padding: 20px;
	display: flex;
	flex-direction: column;
	box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 20px 0px;
`;

export interface SearchResultsProps {
	inputSize?: InputSize | ResponsiveValues<InputSize>;
	placeholder?: string;
	showMostPopularKeywords: boolean;
	disableSuggestions?: boolean;
	mostPopularKeywords: NavLinkItemProps[];
	recipeResults?: SearchResultCollection;
	professionalRecipeResults?: SearchResultCollection;
	productResults?: SearchResultCollection;
	contentResults?: SearchResultCollection;
	loading?: boolean;
	autoSearchUrl?: string;
	autoSearchUrlQuery?: string;
	autoFocus?: boolean;
	inputBorderColor?: 'blue' | 'white';
	isDesktopWidthFluid?: boolean;
	hideSearchCategoryTitle?: boolean;
	hideSearchIcon?: boolean;
	onSetSearchText: (input: string) => void;
	onClear?: () => void;
	itemClicked?: (item: NavLinkItemProps) => void;
	initialSearchTerm?: string | undefined;
	professional?: boolean;
	siteUrlPrefix: string;
	searchActive?: boolean;
	isMobileInActiveHidden?: boolean;
	searchParams?: string;
}

export interface SearchResultsState {
	isOpen: boolean;
	showMostPopularKeywords: boolean;
	keyword: string;
}

type Props = SearchResultsProps & InjectedRoutableProps & React.HTMLAttributes<HTMLElement>;

class SearchResults extends React.Component<Props, SearchResultsState> {
	public static defaultProps = {
		showMostPopularKeywords: false,
	};

	public state: SearchResultsState = {
		isOpen: false,
		showMostPopularKeywords: true,
		keyword: '',
	};

	private searchFieldRef = React.createRef<HTMLInputElement>();

	public render() {
		const {
			mostPopularKeywords,
			recipeResults,
			professionalRecipeResults,
			productResults,
			contentResults,
			loading,
			className,
			autoFocus,
			itemClicked,
			inputBorderColor,
			isDesktopWidthFluid,
			hideSearchCategoryTitle,
			hideSearchIcon,
			disableSuggestions,
			searchActive,
			isMobileInActiveHidden,
			onSetSearchText,
			onLinkClick,
			onClear,
			searchParams,
			...props
		} = this.props;
		const { isOpen } = this.state;
		const size = props.inputSize || InputSize.small;
		const showSuggestions =
			this.props.showMostPopularKeywords &&
			this.state.showMostPopularKeywords &&
			mostPopularKeywords &&
			mostPopularKeywords.length > 0;

		return (
			<SearchWrapper
				size={size}
				isDesktopWidthFluid={isDesktopWidthFluid}
				className={className}
				isMobileInActiveHidden={isMobileInActiveHidden}
				searchActive={searchActive}>
				<SearchResultContent>
					<SearchField
						{...props}
						name="search"
						inputSize={size}
						value={this.state.keyword}
						withBlueBorder={true}
						fullWidth={true}
						icon={isOpen ? <CloseIcon /> : <SearchIcon />}
						hideIcon={!isOpen && hideSearchIcon}
						onButtonClick={this.onButtonClicked}
						onChange={this.onChange}
						onFocus={this.gotFocus}
						onKeyPress={this.onKeyPress}
						autoComplete="off"
						inputRef={this.searchFieldRef}
						borderColor={inputBorderColor}
					/>
					{!disableSuggestions && isOpen ? (
						<SearchResultsWrapper>
							<SearchResultsContainer className="search-results-container">
								{showSuggestions ? (
									<MostPopularKeywords mostPopularKeywords={mostPopularKeywords} itemClicked={this.keywordSelected} />
								) : (
									<SearchCategories
										loading={loading}
										keyword={this.state.keyword}
										recipeResults={recipeResults}
										professionalRecipeResults={professionalRecipeResults}
										productResults={productResults}
										contentResults={contentResults}
										categoryClicked={this.categoryClicked}
										itemClicked={this.itemClicked}
										hideSearchCategoryTitle={hideSearchCategoryTitle}
										siteUrlPrefix={this.props.siteUrlPrefix}
										searchParams={searchParams}
									/>
								)}
							</SearchResultsContainer>
						</SearchResultsWrapper>
					) : null}
				</SearchResultContent>
			</SearchWrapper>
		);
	}

	public componentDidMount() {
		const { initialSearchTerm } = this.props;
		if (initialSearchTerm) {
			this.setInputText(initialSearchTerm);
		}

		const searchInput = this.searchFieldRef.current;
		if (searchInput && this.props.autoFocus) {
			withWindow(w => {
				w.setTimeout(() => {
					searchInput.focus();

					// * This is a hack for better positioning of the search input field on mobile devices (Android atleast).
					// Otherwise the suggestions dropdown would appear under the software keyboard and not be clearly visible for the end user.
					const modal = searchInput.closest('div[class^="SearchFiltersContainer"]') as HTMLElement;
					if (modal) {
						const inputTop = (modal.querySelector('.search-results') as HTMLElement).offsetTop;
						(modal.parentElement as HTMLElement).scrollTop = inputTop - 50;
					}
				}, 1);
			});
		}
	}

	public componentDidUpdate(prevProps: Props) {
		const { initialSearchTerm } = this.props;
		if (prevProps.initialSearchTerm !== initialSearchTerm && initialSearchTerm !== this.state.keyword) {
			this.setState({ keyword: initialSearchTerm ? initialSearchTerm : '' });
		}
		if (prevProps.routing.pathname !== this.props.routing.pathname) {
			this.setState({ isOpen: false });
		}
	}

	private itemClicked = (item: NavLinkItemProps, searchType?: SearchType) => {
		const { itemClicked, navigateTo } = this.props;
		if (itemClicked) {
			itemClicked(item);
			this.setState({ isOpen: false });
		} else {
			if (searchType && [SearchType.RECIPE, SearchType.PRODUCT].indexOf(searchType) > -1) {
				navigateTo({ pathname: item.url });
				this.setState({ isOpen: false, keyword: '' });
			} else {
				withWindow(w => {
					w.location.href = item.url;
				});
			}
		}
	};

	private categoryClicked = () => {
		this.setState({ isOpen: false, keyword: '' });
	};

	private gotFocus = () => {
		const { showMostPopularKeywords, mostPopularKeywords } = this.props;
		const hasInput = !!this.state.keyword;
		this.setState({
			isOpen: (showMostPopularKeywords && mostPopularKeywords && mostPopularKeywords.length > 0) || hasInput,
		});
	};

	private keywordSelected = (item: NavLinkItemProps) => {
		window.location.href = item.url;
	};

	private onChange = (event: React.FormEvent<HTMLInputElement>) => {
		const typedText = event.currentTarget.value;
		this.setSearchText(typedText);
	};

	private onKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === 'Enter') {
			this.autoSearch();
		}
	};

	private autoSearch() {
		const { autoSearchUrl, autoSearchUrlQuery, navigateTo } = this.props;
		const keyword = this.state.keyword;
		const searchFieldEl = this.searchFieldRef.current;

		if (searchFieldEl) {
			searchFieldEl.readOnly = true;
			setTimeout(() => {
				searchFieldEl.blur();
				searchFieldEl.readOnly = false;
			}, 100);
		}

		if (autoSearchUrl && autoSearchUrlQuery && keyword) {
			navigateTo({
				pathname: autoSearchUrl,
				search: `?${autoSearchUrlQuery}=${encodeURIComponent(keyword)}`,
			});
		}

		if (autoSearchUrl && !keyword) {
			navigateTo({
				pathname: autoSearchUrl,
			});
		}
	}

	private setInputText = (text: string) => {
		const hasNoText = text.length === 0;

		this.setState({
			isOpen: !hasNoText,
			showMostPopularKeywords: hasNoText,
			keyword: text,
		});
	};

	private setSearchText(text: string) {
		this.setInputText(text);
		this.props.onSetSearchText(text);
	}

	private onButtonClicked = () => {
		const { isOpen } = this.state;
		const { autoSearchUrl } = this.props;

		if (autoSearchUrl && !isOpen) {
			this.autoSearch();
		}

		this.setState({ showMostPopularKeywords: true, keyword: '', isOpen: false });
		if (this.props.onClear) {
			this.props.onClear();
		}
	};
}

export default injectRoutable(SearchResults);
