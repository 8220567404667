import { Reducer } from 'redux';
import { userActions, UserActions } from './actions';
import { getType } from 'typesafe-actions';
import { userRecipeReducer } from './recipes';
import { ProfileShape, userProfileReducer } from './profile';
import { UserRecipesShape } from '../interfaces';

export interface UserState {
	id: string | null;
	isAdmin: boolean;
	isLoggedIn: boolean;
	recipesInMemory: string[];
	recipeCount: number;
	shoppingListItemCount: number;
	cookbookRecipes: UserRecipesShape;
	profile: ProfileShape;
	cluster: string;
	gaUserId: string;
}

export const userReducer: Reducer<UserState | null, UserActions> = (state = null, action) => {
	if (action.type === getType(userActions.setCurrentUser)) {
		return {
			id: action.payload.id,
			isAdmin: action.payload.isAdmin,
			isLoggedIn: action.payload.isLoggedIn,
			recipesInMemory: action.payload.recipesInMemory,
			recipeCount: action.payload.recipeCount,
			shoppingListItemCount: action.payload.shoppingListItemCount,
			cookbookRecipes: userRecipeReducer(undefined, action),
			profile: userProfileReducer(undefined, action),
			cluster: action.payload.cluster,
			gaUserId: action.payload.gaUserId,
		};
	}

	if (action.type === getType(userActions.fetchCurrentUserError)) {
		return state;
	}

	if (state === null) {
		return state;
	}

	if (action.type === getType(userActions.updateCurrentUserItemsInMemory)) {
		return {
			...state,
			recipesInMemory: action.payload.recipesInMemory,
			recipeCount: action.payload.recipeCount,
			shoppingListItemCount: action.payload.shoppingListItemCount,
		};
	}

	const cookbookRecipes = userRecipeReducer(state.cookbookRecipes, action);
	const profile = userProfileReducer(state.profile, action);

	if (cookbookRecipes !== state.cookbookRecipes || profile !== state.profile) {
		return {
			...state,
			cookbookRecipes,
			profile,
		};
	}

	return state;
};
