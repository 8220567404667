import * as React from 'react';
import { StyledIconButton, StyledIconButtonProps } from './common';
import { NormalIconWrapperProps } from '../../Icons/NormalIcons/NormalIconWrapper';
import { ThemeContext } from 'styled-components';

const Button = StyledIconButton('button');

export interface IconButtonProps extends StyledIconButtonProps {
	/** The icon element to show in the button */
	icon: React.ReactElement<NormalIconWrapperProps & React.SVGAttributes<SVGElement>>;
}

const IconButton: React.FC<IconButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>> = ({
	icon,
	color,
	hoverColor,
	enableShadowOnDesktop,
	disabled,
	...rest
}) => {
	const theme = React.useContext(ThemeContext);

	let buttonColor = color;
	let buttonHoverColor = hoverColor;

	if (disabled) {
		buttonColor = theme.colors.buttonDisabled;
		buttonHoverColor = theme.colors.buttonDisabled;
	}

	return (
		<Button
			enableShadowOnDesktop={enableShadowOnDesktop}
			color={buttonColor}
			hoverColor={buttonHoverColor}
			disabled={disabled}
			{...rest}>
			{icon}
		</Button>
	);
};

IconButton.defaultProps = {
	type: 'button',
};

export default IconButton;
