import { postJson, deleteJson, fetchJson } from 'utils/service';

export interface UserRecipesApiRecipes {
	id: string;
	name: string;
	url: string;
	imageUrl: string;
	recipeTimeInMinutes: number;
	averageRating: number;
	userIsCreator: boolean;
	creationTime: string;
	availableForDownload?: boolean;
	downloadUrl?: string;
}

export interface SendUserRecipesApiResponse {
	success: boolean;
}

async function addRecipe(recipeId: string) {
	await postJson(`/api/v2/cookbook/recipes/${recipeId}/`, {});
}

async function removeRecipe(recipeId: string) {
	await deleteJson(`/api/v2/cookbook/recipes/${recipeId}/`, {});
}

async function fetchUserRecipes(): Promise<UserRecipesApiRecipes[]> {
	return await fetchJson<UserRecipesApiRecipes[]>(`/api/v2/cookbook/getcookbook`);
}

async function sendUserRecipes(): Promise<SendUserRecipesApiResponse> {
	return await postJson(`/api/v2/cookbook/sendcookbook`, {});
}

export interface CookbookApi {
	addRecipe: (recipeId: string) => Promise<void>;
	removeRecipe: (recipeId: string) => Promise<void>;
	fetchUserRecipes: () => Promise<UserRecipesApiRecipes[]>;
	sendUserRecipes: () => Promise<SendUserRecipesApiResponse>;
}

export const cookbookApi: CookbookApi = { addRecipe, removeRecipe, fetchUserRecipes, sendUserRecipes };
