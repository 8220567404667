export enum StarSize {
	small = 'small',
	medium = 'medium',
	large = 'large',
}

export type StarSizes = StarSize.small | StarSize.medium | StarSize.large;

export enum FillLevel {
	half = 'half',
	full = 'full',
}

export type FillLevels = FillLevel.half | FillLevel.full | undefined;
