import { fetchJson, postJson } from 'utils/service';
import { CommentReportReason } from './types';

export interface CommentApiComment {
	id: string;
	textWithLineBreaks: string;
	creationTimeString: string;
	creationTime: Date;
	creatorName: string;
	text: string;
	status: string;
	isAnonymous: boolean;
	creatorProfileUrl: string;
	creatorSearchUrl: string;
	renderLink: boolean;
	isValioComment: boolean;
}

export interface FetchCommentsApiShape {
	skip: number;
	pageLength: number;
	totalCount: number;
	comments: CommentApiComment[];
}

async function fetchComments(
	recipeId: string,
	skip: number = 0,
	pageLength: number = 10
): Promise<FetchCommentsApiShape> {
	return await fetchJson<FetchCommentsApiShape>(
		`/api/v2/Comments?id=${recipeId}&skip=${skip}&pagelength=${pageLength}`
	);
}

async function addComment(resourceId: string, comment: string, email?: string): Promise<any> {
	return await postJson(`/api/resources/${resourceId}/comments`, {
		comment,
		email,
	});
}

async function confirmComment(resourceId: string, code: string): Promise<any> {
	return await postJson(
		`/api/resources/${resourceId}/comment-confirmations`,
		{
			code,
		},
		false
	);
}

async function reportComment(commentId: string, reason: CommentReportReason): Promise<any> {
	return await postJson(
		`/api/comments/${commentId}/reports`,
		{
			reason,
		},
		false
	);
}

export const commentApi = {
	fetchComments,
	addComment,
	confirmComment,
	reportComment,
};

export type CommentApi = typeof commentApi;
