import * as React from 'react';
import NormalIconWrapper, { NormalIconWrapperProps } from '../NormalIconWrapper';

const CalendarCheckedIcon: React.FC<NormalIconWrapperProps & React.SVGAttributes<SVGElement>> = ({
	color,
	stroke = '#002f6d',
	...props
}) => {
	return (
		<NormalIconWrapper {...props} fill="none" width="41" height="40" viewBox="0 0 41 40">
			<path
				d="M15.5 25L18.8333 28.3333L27.1667 20"
				stroke={stroke}
				strokeWidth="2"
				strokeMiterlimit="10"
				strokeLinecap="square"
			/>
			<path
				d="M35.4993 5H5.49935C3.6584 5 2.16602 6.49238 2.16602 8.33333V33.3333C2.16602 35.1743 3.6584 36.6667 5.49935 36.6667H35.4993C37.3403 36.6667 38.8327 35.1743 38.8327 33.3333V8.33333C38.8327 6.49238 37.3403 5 35.4993 5Z"
				stroke={stroke}
				strokeWidth="2"
				strokeMiterlimit="10"
				strokeLinecap="square"
			/>
			<path d="M10.5 1.66663V6.66663" stroke={stroke} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="square" />
			<path d="M30.5 1.66663V6.66663" stroke={stroke} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="square" />
			<path d="M2.16602 13.3333H38.8327" stroke={stroke} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="square" />
		</NormalIconWrapper>
	);
};

export default CalendarCheckedIcon;
