import { MaterialSearchResultsApiResponse, CartItem, DeliveryFormShape, MaterialSearchApiState } from './interfaces';
import { throwMissingSiteUrlIdError, fetchJson, postJson } from 'utils/service';

const fetchSecureFilters = async (query: string): Promise<MaterialSearchApiState> => {
	return await fetchJson<MaterialSearchApiState>(`/api/ammattilaiset/materialbank/categories/${query}`);
};

const searchMaterials = async (
	siteUrlId: string,
	query: string,
	pageSize: number,
	pageNumber: number = 1
): Promise<MaterialSearchResultsApiResponse> => {
	if (!siteUrlId) {
		throwMissingSiteUrlIdError();
	}
	return await fetchJson<MaterialSearchResultsApiResponse>(
		`/api/ammattilaiset/materialbank/searchmaterialbankitems/?sivunkoko=${pageSize}&sivu=${pageNumber}&VainHaku=${
			pageNumber > 1 ? 1 : 0
		}${query ? `&${query}` : ''}`
	);
};

const getCart = async () => {
	return await fetchJson<CartItem[]>('/api/ammattilaiset/shoppingbasket/GetShoppingBasket');
};

const addToCart = async (id: string, quantity: number) => {
	await postJson(
		'/api/ammattilaiset/shoppingbasket/AddToShoppingBasket',
		{ MediaItemId: id, Quantity: quantity },
		false
	);
};

const removeFromCart = async (id: string) => {
	await postJson('/api/ammattilaiset/shoppingbasket/RemoveFromShoppingBasket', { BasketItemId: id }, false);
};

const updateCartItem = async (id: string, quantity: number) => {
	await postJson(
		'/api/ammattilaiset/shoppingbasket/ModifyShoppingBasketItemQuantity',
		{ BasketItemId: id, Quantity: quantity },
		false
	);
};

const orderCart = async (payload: DeliveryFormShape) => {
	await postJson('/api/ammattilaiset/shoppingbasket/OrderShoppingBasketContent', payload, false);
};

const registerToMaterialBank = async (email: string) => {
	await postJson('/api/Account/RegisterToMaterialBank', { email }, false);
};

export const materialSearchApi = {
	fetchSecureFilters,
	searchMaterials,
	getCart,
	removeFromCart,
	updateCartItem,
	addToCart,
	orderCart,
	registerToMaterialBank,
};

export type MaterialSearchApi = typeof materialSearchApi;
