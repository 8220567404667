import { postJson, fetchJson } from 'utils/service';

const userLogin = async (email: string, password: string, rememberLogin?: boolean) => {
	const postParams = {
		email,
		password,
		rememberLogin,
	};

	if (!rememberLogin) {
		delete postParams.rememberLogin;
	}

	return await postJson('/api/account/login', postParams);
};

const userLogout = async () => {
	return await postJson('/api/account/logout', {}, false);
};

const sendFacebookLogin = async (accessToken: string) => {
	return await postJson(`/api/account/FacebookLogin?accessToken=${accessToken}`, { accessToken });
};

const requestRegisterUser = async (email: string) => {
	return await postJson(`/api/account/register?email=${encodeURIComponent(email)}&logintarget=0`, {}, false);
};

const registerUser = async (
	email: string,
	username: string,
	password: string,
	subscribenewsletter: boolean,
	hash: string
) => {
	const postParams = {
		email,
		username,
		password,
		subscribenewsletter,
		rememberlogin: true,
		hash,
	};

	// new-valio-products-subscription: 1
	// legal-acknowledgement: on

	return await postJson('/api/account/Create', postParams, false);
};

const resetPassword = async (email: string, password: string, hash: string) => {
	const postParams = {
		email,
		password,
		hash,
	};

	return await postJson('/api/account/ResetPassword', postParams, false);
};

const getUniqueUserName = async (userName: string) => {
	return await fetchJson(`/api/account/GetUniqueUsername?username=${userName}`);
};

const forgotPassword = async (email: string) => {
	return await postJson(`/api/account/forgotpassword?email=${encodeURIComponent(email)}`, {}, false);
};

export const loginApi = {
	userLogin,
	userLogout,
	sendFacebookLogin,
	requestRegisterUser,
	registerUser,
	getUniqueUserName,
	forgotPassword,
	resetPassword,
};

export type LoginApi = typeof loginApi;
