import * as React from 'react';
import { MapStateToProps, connect } from 'react-redux';
import { State } from 'common/reducers';
import { RoutingState } from 'common/components/Routing/reducers';
import { withWindow } from 'styleguide/helpers/window';
import { ResourceState } from 'common/components/Resource/reducers/reducers';
import { HeaderState } from 'common/components/Dom/reducers';

interface AnchorScrollBehaviorState {
	hasScrolled: boolean;
}

class AnchorScrollBehavior extends React.Component<HashAnchorProps, AnchorScrollBehaviorState> {
	public state = {
		hasScrolled: false,
	};

	public componentWillReceiveProps(nextProps: HashAnchorProps) {
		const { routing, resource, initiallyRenderedOnServer } = this.props;
		const { hasScrolled } = this.state;

		if (
			!!routing &&
			!!routing.hash &&
			((initiallyRenderedOnServer && !hasScrolled) ||
				(!nextProps.resource.isLoading && resource.isLoading !== nextProps.resource.isLoading))
		) {
			const id = routing.hash.replace('#', '');
			setTimeout(() => this.scrollToElement(id), 300);
		}
	}

	public render() {
		return '';
	}

	private scrollToElement = (hash: string) => {
		const element = document.getElementById(hash);

		if (!!element) {
			const rect = element.getBoundingClientRect();

			withWindow(w => {
				const scrollPos = w.scrollY + rect.top;

				// Header and subnavigation offset
				w.scroll({ top: scrollPos - 170, behavior: 'smooth' });

				this.setState({ hasScrolled: true });
			});
		}
	};
}

interface HashAnchorProps {
	routing: RoutingState;
	resource: ResourceState;
	initiallyRenderedOnServer: boolean;
	header: HeaderState;
}

const mapStateToProps: MapStateToProps<HashAnchorProps, {}, State> = ({
	routing,
	resource,
	app,
	dom: { header },
}: State): HashAnchorProps => {
	return {
		routing,
		resource,
		header,
		initiallyRenderedOnServer: app.initiallyRenderedOnServer,
	};
};

export default connect(mapStateToProps)(AnchorScrollBehavior);
