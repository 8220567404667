import * as React from 'react';
import NormalIconWrapper, { NormalIconWrapperProps } from '../NormalIconWrapper';
import { ThemeContext } from 'styled-components';

const LockIcon: React.FC<NormalIconWrapperProps & React.SVGAttributes<SVGElement>> = ({ color, ...props }) => {
	const theme = React.useContext(ThemeContext);
	return (
		<NormalIconWrapper {...props}>
			<path
				fill={color ?? theme.colors.brandPrimary}
				d="M12 16v2a1 1 0 0 0 2 0v-2a1 1 0 0 0-2 0zm-7 7V11h16l.001 12H5zM9 7c0-2.206 1.794-4 4-4s4 1.794 4 4v2H9V7zm12 2h-2V7c0-3.309-2.691-6-6-6S7 3.691 7 7v2H5c-1.103 0-2 .897-2 2v12c0 1.103.897 2 2 2h16c1.103 0 2-.897 2-2V11c0-1.103-.897-2-2-2z"
			/>
		</NormalIconWrapper>
	);
};

export default LockIcon;
