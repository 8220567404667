import * as React from 'react';
import NormalIconWrapper, { NormalIconWrapperProps } from '../NormalIconWrapper';

const MilkBarrelIcon: React.FC<NormalIconWrapperProps & React.SVGAttributes<SVGElement>> = ({ ...props }) => {
	return (
		<NormalIconWrapper {...props} width="48" height="48" fill="none" viewBox="0 0 48 48">
			<path
				stroke="#fff"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeMiterlimit="10"
				strokeWidth="2"
				d="M24 37c3.314 0 6-2.686 6-6s-2.686-6-6-6-6 2.686-6 6 2.686 6 6 6z"
			/>
			<path
				stroke="#fff"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeMiterlimit="10"
				strokeWidth="2"
				d="M38 11V4H10v7l-4 7v26h36V18l-4-7zM6 18h36M10 11h28"
			/>
		</NormalIconWrapper>
	);
};

export default MilkBarrelIcon;
