import { fetchJson } from 'utils/service';
import { Locales } from 'common/types/locale';
import { flatMap } from 'utils/array';
import { State } from 'common/reducers';
import { getConfig } from 'config/config';
import { Theme } from 'styleguide/themes/theme';
import { isEqualCaseInsensitive } from '../../../utils/strings';
import { RoutingState } from '../Routing/reducers';
import { ResourceState } from '../Resource/reducers/reducers';

export enum SiteId {
	fi = 'e11251a6-823c-47dc-88a9-041c50f65dc2',
	com = '969fb1c4-fe57-4310-a709-2378ad2c3436',
	ge = 'f08aaef2-5c7a-43c5-afac-e8ac5dd18e8a', // georgia
	es = '57f9e6bc-e8a2-4edf-92a8-f1749d978a16',
	pl = '95504d55-0de3-479e-94e7-2a0fc2da1f39',
	se = 'fa2535aa-c28a-4b38-aa44-3ffc1b23fdcc',
	us = '26ae950c-409e-4332-90d8-a822f0cd4001',
	og = 'a1601caf-9fe6-4c47-965f-548fa6fd0981', // Oddlygood Global
	ogNl = '5aa24c1e-3326-4373-ae06-bc23891a67c3', // Oddlygood NL
	ogUs = '3bd87b0e-d115-430b-9be3-3084fb07302e', // Oddlygood US
	ogUK = '1d65ac2b-2c2b-4238-83d2-e2cf531d7382', // Oddlygood UK
	ogFi = '7d480b44-b060-4a95-a194-10d8916f21f5', // Oddlygood FI
	ogSe = 'd23d9f72-3871-4443-9ccd-929a206e3f43', // Oddlygood SE
	ogEe = '33e72748-5094-4ef5-915f-ef27f015dcc1', // Oddlygood EE
	ogEs = 'f0f5a31d-fe86-474d-9a4e-308a18745484', // Oddlygood ES
	ogIe = 'cfffdc40-ad29-4066-bae3-6648f0fdbf50', // Oddlygood IE
	ogPl = '83ee3dda-a3f0-4fdf-b715-2036520da02a', // Oddlygood PL
	mjm = 'b967a17a-751a-4b09-9120-9d0505038b4a', // maito ja me
	finc = '8fd57774-83b8-43b9-ad15-ec2018dabaa3', // finlandia cheese
	majal = 'ad0686ed-b930-4eaf-a489-ca0ac77007d9', // maidonjalostajat
	ee = '0192eb38-eea3-47b8-b017-84c0d01cf03f', // Estonia
	eeRu = 'dfa1eb4f-f1f2-4307-ac4c-5068885bcf50', // Estonia Ru
	eeExP = '7dfd3c8e-d94f-411f-a3f7-1f0aa6bdb82c', // Estonia Export
	eeEn = '66411c28-7d7f-4d94-b910-53f63fd0b8ed', // Estonia En
	lv = 'd07b5a56-e146-4a1c-94f9-f8b0a6f44cc7', // Latvia
	lvEn = 'bd76ace3-563e-4b9c-9df0-e39897b55a57', // Latvia En
	lvRu = 'eee184a5-4718-445f-a678-2f6ac9a833ec', // Latvia Ru
	lt = 'd7865d3d-183d-4634-a634-a120135483ff', // Lithuania
	ltRu = '05d93d1a-1602-4be2-8495-e93abe39b46b', // Lithuania Ru
}

export interface SearchSuggestion {
	title: string;
	url: string;
}

export interface Site {
	id: SiteId;
	ownPagesEnabled: boolean;
	defaultLanguage: Locales;
	siteUrls: SiteUrl[];
	siteSettings: SiteSetting[];
	theme: Theme;
}

export interface SiteUrl {
	id: string;
	environment: string;
	domain: string;
	urlPrefix: string;
}

export interface SiteUrlInfo {
	ownPagesEnabled: boolean;
	urlPrefix: string;
}

export interface SiteSetting {
	environment: string | null;
	feature: string;
	key: string;
	value: string;
}

export interface AppSettingsAM {
	searchSuggestions: SearchSuggestion[];
	sites: Site[];
}

export interface WithAppSettings {
	settings: AppSettingsAM | null;
}

type SiteLocatorInfo = Pick<RoutingState, 'protocol' | 'hostname' | 'port' | 'pathname'>;

function domainHasPort(s: string): boolean {
	return s.match(/:[0-9]+$/) !== null;
}

function normalizeDomain(s: string) {
	if (domainHasPort(s)) {
		return s;
	} else {
		return s.replace(/^https:/, 'http:');
	}
}

function sameDomain(x: string, y: string) {
	return normalizeDomain(x) === normalizeDomain(y);
}

export function getSiteSpecs(info: SiteLocatorInfo, sites: Site[]): { siteUrl: SiteUrl; site: Site } | undefined {
	const { protocol, hostname, port, pathname } = info;
	const domain = (protocol ? protocol + '//' : '') + hostname + (port ? ':' + port : '');
	const items = flatMap(sites, site => site.siteUrls.map(siteUrl => ({ site, siteUrl })));
	const matches = items.filter(i => sameDomain(domain, i.siteUrl.domain) && pathname.startsWith(i.siteUrl.urlPrefix));
	matches.sort((x, y) => y.siteUrl.urlPrefix.length - x.siteUrl.urlPrefix.length);
	if (matches.length === 0) {
		return undefined;
	} else {
		// Use first matching site since URLs are ordered from longest prefix to shortest so /foo/ matches before /
		return matches[0];
	}
}

export function getSiteById(sites: Site[], siteId: SiteId) {
	return sites.find(s => isEqualCaseInsensitive(s.id, siteId));
}

export function getSite(info: SiteLocatorInfo, sites: Site[]): Site | undefined {
	const siteSpec = getSiteSpecs(info, sites);
	return siteSpec ? siteSpec.site : undefined;
}

export function getThemeForResource(info: SiteLocatorInfo, sites: Site[], resource: ResourceState): Theme | undefined {
	if (resource && resource.theme) {
		return resource.theme;
	}

	const siteSpec = getSiteSpecs(info, sites);
	return siteSpec ? siteSpec.site.theme : undefined;
}

export function getSiteUrlId(info: SiteLocatorInfo, sites: Site[]): string | undefined {
	const siteSpec = getSiteSpecs(info, sites);
	return siteSpec?.siteUrl.id;
}

export function getSiteUrlIdThrow(info: SiteLocatorInfo, sites: Site[]): string {
	const siteUrlId = getSiteUrlId(info, sites);
	if (siteUrlId) {
		return siteUrlId;
	} else {
		throw getSiteError(info, sites);
	}
}

function getSiteError(info: SiteLocatorInfo, sites: Site[]) {
	return new Error(
		`siteUrlId for protocol=${info.protocol} hostname=${info.hostname} port=${info.port} pathname=${info.pathname} not found`
	);
}

export function getSiteUrl(info: SiteLocatorInfo, sites: Site[]): string | undefined {
	const siteSpec = getSiteSpecs(info, sites);
	return siteSpec?.siteUrl ? `${siteSpec.siteUrl.domain}${siteSpec.siteUrl.urlPrefix}` : undefined;
}

export function getSiteEnvironment(info: SiteLocatorInfo, sites: Site[]): string | undefined {
	const siteSpec = getSiteSpecs(info, sites);
	return siteSpec?.siteUrl ? siteSpec.siteUrl.environment : undefined;
}

export function getSiteDomain(info: SiteLocatorInfo, sites: Site[]): string | undefined {
	const siteSpec = getSiteSpecs(info, sites);
	return siteSpec?.siteUrl ? siteSpec.siteUrl.domain : undefined;
}

export function getSiteUrlInfoOrDefault(info: SiteLocatorInfo, sites: Site[]): SiteUrlInfo {
	const siteSpec = getSiteSpecs(info, sites);
	if (siteSpec) {
		return { ownPagesEnabled: siteSpec.site.ownPagesEnabled, urlPrefix: siteSpec.siteUrl.urlPrefix };
	} else {
		return { ownPagesEnabled: false, urlPrefix: '/' };
	}
}

export function getSiteUrlPrefix(info: SiteLocatorInfo, sites: Site[]): string | undefined {
	const siteSpec = getSiteSpecs(info, sites);
	return siteSpec?.siteUrl.urlPrefix;
}

export function isExternalUrl(url: string) {
	return url.toLowerCase().startsWith('http://') || url.toLowerCase().startsWith('https://');
}

export function addSiteUrlPrefix(prefix: string, url: string) {
	if (
		url.toLowerCase().startsWith('http://') ||
		url.toLowerCase().startsWith('https://') ||
		url.toLowerCase().startsWith(prefix.toLowerCase())
	) {
		return url;
	}

	return (prefix + url).replace('//', '/');
}

export function getSiteUrlPrefixOrDefault(info: SiteLocatorInfo, sites: Site[], defaultIfEmpty: string): string {
	const siteUrlPrefix = getSiteUrlPrefix(info, sites);
	return siteUrlPrefix || defaultIfEmpty;
}

export function getSettingValue(state: State, feature: string, key: string): string | undefined {
	const settings = state.app.settings;
	if (!settings) {
		return undefined;
	}

	const siteSpec = getSiteSpecs(state.routing, settings.sites);
	if (!siteSpec) {
		return undefined;
	}

	const environment = siteSpec.siteUrl.environment;

	const macthingSettings = siteSpec.site.siteSettings.filter(
		ss => ss.feature === feature && ss.key === key && (ss.environment === null || ss.environment === environment)
	);
	if (macthingSettings.length === 0) {
		return undefined;
	}

	return macthingSettings[0].value;
}

export function getWildcardPart(info: SiteLocatorInfo, basePath: string) {
	const path = info.pathname;
	if (path.startsWith(basePath)) {
		const text = path.substring(basePath.length);
		return text.endsWith('/') ? text.substring(0, text.length - 1) : text;
	} else {
		return '';
	}
}

export function getEnableWhatsApp(state: State) {
	const settingValue = getSettingValue(state, 'Some', 'EnableWhatsApp');
	return (settingValue && settingValue === '1') || false;
}

export function getIsEmailShareEnabled(state: State) {
	const settingValue = getSettingValue(state, 'Some', 'IsEmailShareEnabled');
	return (settingValue && settingValue === '1') || false;
}

export function getFacebookAppId(state: State) {
	return getSettingValue(state, 'Some', 'FacebookAppId');
}

function getSomeUserName(state: State, prefix: string) {
	return getSettingValue(state, 'Some', prefix + 'UserName');
}

export function getFacebookUserName(state: State) {
	return getSomeUserName(state, 'Facebook');
}

export function getYouTubeUserName(state: State) {
	return getSomeUserName(state, 'YouTube');
}

export function getTwitterUserName(state: State) {
	return getSomeUserName(state, 'Twitter');
}

export function getTwitterUserNameWithAtSign(state: State) {
	const s = getTwitterUserName(state);
	return s ? withAtSign(s) : s;
}

export function getLinkedInUserName(state: State) {
	return getSomeUserName(state, 'LinkedIn');
}

export function getInstagramUserName(state: State) {
	return getSomeUserName(state, 'Instagram');
}

export function getPinterestUserName(state: State) {
	return getSomeUserName(state, 'Pinterest');
}

export function getFooterBackgroundMediaUrl(state: State) {
	return getSettingValue(state, 'Footer', 'BackgroundMediaUrl');
}

export function withAtSign(handle: string) {
	return handle.startsWith('@') ? handle : '@' + handle;
}

export function withoutAtSign(handle: string) {
	return handle.startsWith('@') ? handle.substring(1) : handle;
}

const getSettings = async (): Promise<AppSettingsAM> => {
	const config = getConfig();
	const environment = config.environment;
	const all = !environment && config.isDevelopment;
	const url = '/api/settings' + (all ? '?all=true' : environment ? `?environment=${environment}` : '');
	return await fetchJson<AppSettingsAM>(url);
};

export const settingsApi = {
	getSettings,
};

export type SettingsApi = typeof settingsApi;
