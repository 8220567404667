import {
	MaterialSearchState,
	MaterialSearchResultsApiResponse,
	Order,
	CartItem,
	DeliveryFormShape,
	IdAndQuantity,
	Register,
	RegisterModalStatus,
	MaterialSearchApiState,
} from './interfaces';
import { ResourceActions, resourceActions } from 'common/components/Resource/reducers/actions';
import { createCustomAction, ActionType, getType, createStandardAction, createAsyncAction } from 'typesafe-actions';
import { getInitialQuery } from 'pagetypes/Search/reducers/index';
import { ResourceType } from 'common/components/Resource/types';
import { searchWithoutQuestion } from 'utils/query-string';
import { NumberMap } from 'common/interfaces/common';
import includes from 'lodash/includes';
import { Reducer } from 'redux';
import { routingActions, RoutingActions } from 'common/components/Routing/reducers';
import { ORDER_STATUS_BY_HASH } from './constants';

export const materialSearchActions = {
	searchMaterials: createCustomAction(
		'MATERIAL_SEARCH/SEARCH_MATERIALS_REQUEST',
		type => (siteUrlId: string, query: string, pageSize: number) => ({
			type,
			payload: { siteUrlId, query: searchWithoutQuestion(query), pageSize },
		})
	),
	loadMoreMaterials: createCustomAction(
		'MATERIAL_SEARCH/LOAD_MORE_MATERIALS_REQUEST',
		type => (siteUrlId: string, query: string, pageSize: number, pageNumber: number) => ({
			type,
			payload: { siteUrlId, query: searchWithoutQuestion(query), pageSize, pageNumber },
		})
	),
	searchMaterialsSucceeded: createCustomAction(
		'MATERIAL_SEARCH/SEARCH_MATERIALS_SUCCEEDED',
		type => (response: MaterialSearchResultsApiResponse, pageNumber: number) => ({
			type,
			payload: { response, pageNumber },
		})
	),

	searchMaterialsFailed: createStandardAction('MATERIAL_SEARCH/SEARCH_MATERIALS_FAILED')<string>(),

	fetchSecureFilters: createAsyncAction(
		'MATERIAL_SEARCH/FETCH_SECURE_FILTERS',
		'MATERIAL_SEARCH/FETCH_SECURE_FILTERS_SUCCESS',
		'MATERIAL_SEARCH/FETCH_SECURE_FILTERS_FAILED'
	)<string, MaterialSearchApiState, string>(),

	fetchCart: createAsyncAction(
		'MATERIAL_SEARCH/FETCH_CART',
		'MATERIAL_SEARCH/FETCH_CART_SUCCESS',
		'MATERIAL_SEARCH/FETCH_CART_FAILED'
	)<undefined, CartItem[], string>(),

	addToCart: createAsyncAction(
		'MATERIAL_SEARCH/ADD_TO_CART',
		'MATERIAL_SEARCH/ADD_TO_CART_SUCCESS',
		'MATERIAL_SEARCH/ADD_TO_CART_FAILED'
	)<IdAndQuantity, undefined, string>(),

	removeFromCart: createAsyncAction(
		'MATERIAL_SEARCH/REMOVE_FROM_CART',
		'MATERIAL_SEARCH/REMOVE_FROM_CART_SUCCESS',
		'MATERIAL_SEARCH/REMOVE_FROM_CART_FAILED'
	)<string, undefined, string>(),

	updateCartItemQuantity: createAsyncAction(
		'MATERIAL_SEARCH/UPDATE_CART_ITEM_QUANTITY',
		'MATERIAL_SEARCH/UPDATE_CART_ITEM_QUANTITY_SUCCESS',
		'MATERIAL_SEARCH/UPDATE_CART_ITEM_QUANTITY_FAILED'
	)<IdAndQuantity, undefined, string>(),

	orderCart: createAsyncAction(
		'MATERIAL_SEARCH/ORDER_CART',
		'MATERIAL_SEARCH/ORDER_CART_SUCCESS',
		'MATERIAL_SEARCH/ORDER_CART_FAILED'
	)<DeliveryFormShape, undefined, string>(),

	registerToMaterialBank: createAsyncAction(
		'MATERIAL_SEARCH/REGISTER',
		'MATERIAL_SEARCH/REGISTER_SUCCESS',
		'MATERIAL_SEARCH/REGISTER_FAILED'
	)<string, undefined, string>(),

	updateDeliveryDetails: createStandardAction('MATERIAL_SEARCH/UPDATE_DELIVERY_DETAILS')<DeliveryFormShape>(),
	clearOrder: createStandardAction('MATERIAL_SEARCH/CLEAR_ORDER')<undefined>(),
	setRegisterModalStatus: createStandardAction('MATERIAL_SEARCH/SET_MODAL_STATUS')<RegisterModalStatus>(),
};

export type MaterialSearchActions = ActionType<typeof materialSearchActions>;

const orderInitialState: Order = {
	status: 'SEARCH',
	items: [],
	delivery: {
		isLoading: false,
	},
};

const orderReducer: Reducer<Order, MaterialSearchActions | RoutingActions> = (state = orderInitialState, action) => {
	if (
		action.type === getType(routingActions.historyStateChanged) ||
		action.type === getType(routingActions.updateHash)
	) {
		if (action.payload.hash && action.payload.hash.length > 0 && ORDER_STATUS_BY_HASH[action.payload.hash]) {
			return {
				...state,
				status: ORDER_STATUS_BY_HASH[action.payload.hash],
			};
		}

		return {
			...state,
			status: 'SEARCH',
		};
	}

	if (action.type === getType(materialSearchActions.removeFromCart.request)) {
		const newItems = state.items.filter(item => item.id !== action.payload);

		if (newItems.length === 0) {
			return {
				...state,
				items: newItems,
			};
		}

		return {
			...state,
			items: newItems,
		};
	}

	if (action.type === getType(materialSearchActions.updateDeliveryDetails)) {
		return {
			...state,
			delivery: {
				...state.delivery,
				data: action.payload,
			},
		};
	}

	if (action.type === getType(materialSearchActions.fetchCart.success)) {
		return {
			...state,
			items: action.payload,
		};
	}

	if (action.type === getType(materialSearchActions.fetchCart.failure)) {
		return {
			...state,
			items: [],
		};
	}

	if (action.type === getType(materialSearchActions.orderCart.request)) {
		return {
			...state,
			delivery: {
				...state.delivery,
				isLoading: true,
			},
		};
	}

	if (action.type === getType(materialSearchActions.orderCart.success)) {
		return {
			...state,
			delivery: {
				...state.delivery,
				isLoading: false,
			},
		};
	}

	if (action.type === getType(materialSearchActions.orderCart.failure)) {
		return {
			...state,
			delivery: {
				...state.delivery,
				error: 'materials_search_delivery_error',
				isLoading: false,
			},
		};
	}

	if (action.type === getType(materialSearchActions.clearOrder)) {
		return {
			...state,
			items: [],
			delivery: {
				...state.delivery,
				data: undefined,
				error: undefined,
				isLoading: false,
			},
		};
	}

	return state;
};

const registerInitialState: Register = {
	modalStatus: 'CLOSED',
	isLoading: false,
};

export const registerReducer: Reducer<Register, MaterialSearchActions> = (
	state = registerInitialState,
	action
): Register => {
	if (action.type === getType(materialSearchActions.setRegisterModalStatus)) {
		return {
			...state,
			modalStatus: action.payload,
		};
	}

	if (action.type === getType(materialSearchActions.registerToMaterialBank.request)) {
		return {
			...state,
			isLoading: true,
		};
	}

	if (action.type === getType(materialSearchActions.registerToMaterialBank.success)) {
		return {
			...state,
			isLoading: false,
			modalStatus: 'SHOW_CONFIRMATION',
		};
	}

	if (action.type === getType(materialSearchActions.registerToMaterialBank.failure)) {
		return {
			...state,
			isLoading: false,
			error: 'materials_search_login_error_description',
		};
	}

	return state;
};

const materialSearchInitialState: MaterialSearchState = {
	query: getInitialQuery(),
	count: 0,
	availableFilters: [],
	isLoading: false,
	isResultsLoading: false,
	filterCount: {},
	results: [],
	order: orderInitialState,
	register: registerInitialState,
};

export const materialSearchReducer: Reducer<
	MaterialSearchState,
	MaterialSearchActions | ResourceActions | RoutingActions
> = (state = materialSearchInitialState, action): MaterialSearchState => {
	if (
		action.type === getType(materialSearchActions.fetchCart.request) ||
		action.type === getType(materialSearchActions.fetchCart.success) ||
		action.type === getType(materialSearchActions.fetchCart.failure) ||
		action.type === getType(materialSearchActions.orderCart.request) ||
		action.type === getType(materialSearchActions.orderCart.success) ||
		action.type === getType(materialSearchActions.orderCart.failure) ||
		action.type === getType(materialSearchActions.removeFromCart.request) ||
		action.type === getType(materialSearchActions.updateDeliveryDetails) ||
		action.type === getType(materialSearchActions.clearOrder) ||
		action.type === getType(routingActions.updateHash) ||
		action.type === getType(routingActions.historyStateChanged)
	) {
		return {
			...state,
			order: orderReducer(state.order, action),
		};
	}

	if (
		action.type === getType(materialSearchActions.registerToMaterialBank.request) ||
		action.type === getType(materialSearchActions.registerToMaterialBank.success) ||
		action.type === getType(materialSearchActions.registerToMaterialBank.failure) ||
		action.type === getType(materialSearchActions.setRegisterModalStatus)
	) {
		return {
			...state,
			register: registerReducer(state.register, action),
		};
	}

	if (
		action.type === getType(resourceActions.setResource) &&
		action.payload.resource.type === ResourceType.materialSearch
	) {
		return {
			...state,
			availableFilters: action.payload.resource.content.searchFilters,
		};
	}

	if (action.type === getType(materialSearchActions.fetchSecureFilters.success)) {
		return {
			...state,
			availableFilters: action.payload.searchFilters,
		};
	}

	if (action.type === getType(materialSearchActions.searchMaterials)) {
		return {
			...state,
			isLoading: true,
		};
	}

	if (action.type === getType(materialSearchActions.loadMoreMaterials)) {
		return {
			...state,
			isResultsLoading: true,
		};
	}

	if (action.type === getType(materialSearchActions.searchMaterialsSucceeded)) {
		const { count, searchData, searchFunctionalityData } = action.payload.response;

		const filterCount: NumberMap = {};
		searchFunctionalityData.forEach(({ slug, count: filterDataCount }) => {
			filterCount[slug] = filterDataCount;
		});

		const newState = {
			...state,
			isLoading: false,
			isResultsLoading: false,
			count,
			filterCount,
		};

		if (action.payload.pageNumber > 1) {
			const stateResultsMap = state.results.map(result => result.id);
			newState.results = state.results.concat(searchData.filter(result => !includes(stateResultsMap, result.id)));
			return newState;
		}

		newState.results = searchData;
		return newState;
	}

	if (action.type === getType(materialSearchActions.searchMaterialsFailed)) {
		return {
			...state,
			isLoading: false,
			isResultsLoading: false,
			error: 'materials_search_results_error',
		};
	}

	return state;
};
