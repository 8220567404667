import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RoutingParams, routingActions, RoutingState } from './reducers';
import { State } from '../../reducers';
import { getSiteUrlId } from '../App/services';

export interface InjectedRoutableProps {
	navigateTo: (params: RoutingParams) => void;
	onLinkClick: (event: React.MouseEvent<HTMLAnchorElement>) => void;
	routing: RoutingState;
	siteUrlId?: string;
}

const isExternalURL = (url: string) => new URL(url).origin !== location.origin;

const injectRoutable = <TOriginalProps extends InjectedRoutableProps>(
	ComposedComponent: React.ComponentType<TOriginalProps>
) => {
	function Routable(props: Omit<TOriginalProps, keyof InjectedRoutableProps>) {
		const dispatch = useDispatch();
		const reduxState = useSelector((state: State) => {
			const sites = (state.app.settings && state.app.settings.sites) || [];
			return {
				routing: state.routing,
				siteUrlId: getSiteUrlId(state.routing, sites),
			};
		});

		const navigateTo = (params: RoutingParams) => dispatch(routingActions.updateUrl(params, reduxState.siteUrlId));

		const onLinkClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
			if (
				(event.button && event.button !== 0) ||
				event.metaKey ||
				event.altKey ||
				event.ctrlKey ||
				event.shiftKey ||
				event.defaultPrevented === true
			) {
				return;
			}

			if (isExternalURL(event.currentTarget.href)) {
				return;
			}

			event.preventDefault();

			const { pathname, search, hash } = event.currentTarget;

			navigateTo({ pathname, search, hash });
		};

		return (
			<ComposedComponent
				{...((props as unknown) as TOriginalProps)}
				siteUrlId={reduxState.siteUrlId}
				routing={reduxState.routing}
				navigateTo={navigateTo}
				onLinkClick={onLinkClick}
			/>
		);
	}

	return Routable;
};

export default injectRoutable;
