import * as React from 'react';
import NormalIconWrapper, { NormalIconWrapperProps } from '../NormalIconWrapper';
import { ThemeContext } from 'styled-components';

export interface NumberBadgeIconProps extends NormalIconWrapperProps {
	numberValue: number;
	numberColor?: string;
}

const NumberBadgeIcon: React.FC<NumberBadgeIconProps & React.SVGAttributes<SVGElement>> = ({
	color,
	numberValue,
	numberColor,
	...props
}) => {
	const theme = React.useContext(ThemeContext);
	return (
		<NormalIconWrapper size={13} viewBox="0 0 12 12" {...props}>
			<circle fill={theme.colors.border} cx="6" cy="6" r="6" />
		</NormalIconWrapper>
	);
};

// This can be taken into use once all the recipes in Oiva have been fixed to the new format
/*
const NumberBadgeIcon: React.FC<NumberBadgeIconProps & React.SVGAttributes<SVGElement>> = ({
	color,
	numberValue,
	numberColor,
	...props
}) => {
	return (
		<NormalIconWrapper viewBox="0 0 24 24" {...props}>
			<circle fill={color} cx="12" cy="12" r="12" />
			<text
				x="50%"
				y="50%"
				textAnchor="middle"
				fill={numberColor}
				fontSize="15px"
				fontWeight="900"
				fontFamily={theme.fonts.primary}
				dy=".3em">
				{numberValue}
			</text>
		</NormalIconWrapper>
	);
};
*/

export default NumberBadgeIcon;
