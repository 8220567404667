import * as React from 'react';
import NormalIconWrapper, { NormalIconWrapperProps } from '../NormalIconWrapper';
import { ThemeContext } from 'styled-components';

const FaqIcon: React.FC<NormalIconWrapperProps & React.SVGAttributes<SVGElement>> = ({ color, ...props }) => {
	const theme = React.useContext(ThemeContext);
	const fillColor = color ?? theme.colors.brandPrimary;

	return (
		<NormalIconWrapper {...props}>
			<path
				fill={fillColor}
				d="M23 1H3C1.346 1 0 2.346 0 4v14c0 1.654 1.346 3 3 3h10.086l3.707 3.707a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414L13.914 19H3c-.551 0-1-.449-1-1V4c0-.551.449-1 1-1h20c.551 0 1 .449 1 1v14c0 .551-.449 1-1 1h-3a1 1 0 0 0 0 2h3c1.654 0 3-1.346 3-3V4c0-1.654-1.346-3-3-3"
			/>
			<path
				fill={fillColor}
				d="M16.401 8.601c0-1.604-1.274-2.683-3.388-2.683-2.279 0-3.419 1.35-3.419 2.413 0 1.124 1.425 1.08 1.44 1.05.12-.855.69-1.724 1.95-1.724.884 0 1.393.42 1.393 1.094 0 1.274-2.549.9-2.609 3.404-.014.494.361.734.9.734.48 0 .78-.135.855-.405.135-1.739 2.878-1.409 2.878-3.883M12.534 13.729c-.66 0-.99.33-.99.989v.21c0 .66.33.99.99.99h.27c.673 0 1.02-.33 1.02-.99v-.21c0-.659-.33-.989-1.02-.989h-.27z"
			/>
		</NormalIconWrapper>
	);
};

export default FaqIcon;
