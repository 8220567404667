import { takeLatest } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { call, put } from 'redux-saga/effects';
import { RecipeSearchApi, recipeSearchApi } from '../services/recipe-search';
import { ProductSearchApi, productSearchApi } from '../services/product-search';
import { ArticleSearchApi, articleSearchApi } from '../services/article-search';
import { globalSearchActions } from '../reducers/actions';

function* searchContent(
	recipeApi: RecipeSearchApi,
	productApi: ProductSearchApi,
	articleApi: ArticleSearchApi,
	{ payload }: ReturnType<typeof globalSearchActions.search>
) {
	try {
		const recipeResponse = yield call(
			recipeApi.searchRecipes,
			payload.siteUrlId,
			payload.searchTerm,
			payload.maxResults
		);
		yield put(globalSearchActions.searchRecipeResult(recipeResponse));

		const professionalRecipeResponse = yield call(
			recipeApi.searchRecipes,
			payload.siteUrlId,
			payload.searchTerm,
			payload.maxResults,
			true
		);
		yield put(globalSearchActions.searchProfessionalRecipeResult(professionalRecipeResponse));

		const productResponse = yield call(
			productApi.searchProducts,
			payload.siteUrlId,
			payload.searchTerm,
			payload.maxResults
		);
		yield put(globalSearchActions.searchProductResult(productResponse));

		const articleResponse = yield call(
			articleApi.searchArticles,
			payload.siteUrlId,
			payload.searchTerm,
			payload.maxResults
		);
		yield put(globalSearchActions.searchArticleResult(articleResponse));

		yield put(globalSearchActions.searchSucceeded());
	} catch {
		//
	}
}

export function* globalSearchSaga() {
	yield takeLatest(
		getType(globalSearchActions.search),
		searchContent,
		recipeSearchApi,
		productSearchApi,
		articleSearchApi
	);
}
