import * as React from 'react';
import styled from 'styled-components';
import Field, {
	FieldProps,
	StyledFieldProps,
	getBorder,
	getFontWeight,
	getCssPadding,
	commonInputStyles,
	getInvalidStyles,
} from '../Field/Field';

const StyledInput = styled.input<StyledFieldProps>`
	&[type='text'],
	&[type='number'],
	&[type='email'] {
		appearance: none;
	}

	${commonInputStyles};

	border: 1px solid ${props => getBorder(props)};
	font-weight: ${({ inputFontWeight }) => getFontWeight(inputFontWeight)};

	${props => getCssPadding(props)};
	${props => getInvalidStyles(props)};
`;

export interface InputProps extends FieldProps, Omit<React.InputHTMLAttributes<HTMLInputElement>, 'name'> {
	inputClassName?: string;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
	(
		{
			validationError,
			helpText,
			id,
			name,
			icon,
			iconPosition,
			iconOffset,
			iconWidth,
			inputSize,
			inputClassName,
			className,
			label,
			inlineLabel,
			postfixLabel,
			...props
		},
		ref: React.RefObject<HTMLInputElement>
	) => {
		const inputId = id || name;

		return (
			<Field
				validationError={validationError}
				helpText={helpText}
				id={inputId}
				name={name}
				icon={icon}
				iconPosition={iconPosition}
				iconOffset={iconOffset}
				iconWidth={iconWidth}
				inputSize={inputSize}
				className={className}
				label={label}
				inlineLabel={inlineLabel}
				postfixLabel={postfixLabel}>
				<StyledInput
					name={name}
					id={inputId}
					inputSize={inputSize}
					iconWidth={iconWidth}
					iconPosition={iconPosition}
					iconOffset={iconOffset}
					className={inputClassName}
					isValid={validationError ? validationError.length === 0 : true}
					ref={ref}
					{...props}
				/>
			</Field>
		);
	}
);

export default Input;
