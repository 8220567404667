import { createCustomAction, createStandardAction, getType, ActionType } from 'typesafe-actions';
import { Reducer } from 'redux';
import { RatingState } from '../interfaces';
import { ResourceActions, resourceActions } from 'common/components/Resource/reducers/actions';
import { ResourceType } from 'common/components/Resource/types';

export const recipeRatingActions = {
	selectRating: createStandardAction('RECIPE/UPDATE_CURRENT_RATING')<number>(),

	updateRating: createCustomAction('RECIPE/SAVE_RATING_REQUEST', type => (recipeId: string, rating: number) => ({
		type,
		payload: { recipeId, rating },
	})),

	ratingSaved: createCustomAction(
		'RECIPE/SAVE_RATING_SUCCESS',
		type => (averageRating: number, ratingCount: number) => ({
			type,
			payload: { averageRating, ratingCount },
		})
	),

	updateRatingError: createStandardAction('RECIPE/SAVE_RATING_ERROR')<string>(),
};

export const initialRatingState = {
	averageRating: 0,
	isLoading: false,
	ratingCount: 0,
	currentUserRating: null,
};

export const recipeRatingReducer: Reducer<RatingState, RecipeRatingActions | ResourceActions> = (
	state = initialRatingState,
	action
): RatingState => {
	switch (action.type) {
		case getType(recipeRatingActions.updateRating): {
			return {
				...state,
				isLoading: true,
			};
		}

		case getType(recipeRatingActions.ratingSaved): {
			return {
				...state,
				isLoading: false,
				error: undefined,
				averageRating: action.payload.averageRating,
				ratingCount: action.payload.ratingCount,
			};
		}

		case getType(recipeRatingActions.updateRatingError): {
			return {
				...state,
				isLoading: false,
				error: 'recipe_ratings_save_error',
			};
		}

		case getType(recipeRatingActions.selectRating): {
			return {
				...state,
				currentUserRating: action.payload,
			};
		}

		case getType(resourceActions.setResource): {
			if (action.payload.resource.type === ResourceType.recipe) {
				const { statistics } = action.payload.resource.content;
				const { rating } = action.payload.resource.userData;
				return {
					...state,
					averageRating: statistics.averageRating,
					ratingCount: statistics.ratingCount,
					currentUserRating: rating,
				};
			}
		}

		default:
			return state;
	}
};

export type RecipeRatingActions = ActionType<typeof recipeRatingActions>;
