import * as React from 'react';
import styled, { css } from 'styled-components';

const StyledLabel = styled('label')<LabelProps>`
	${({ theme }) => theme.typography.heading};
	font-size: 14px;
	line-height: 16px;
	color: ${({ theme }) => theme.colors.brandPrimary};
	text-transform: uppercase;
	display: inline-block;

	${({ inline, theme }) => {
		if (inline) {
			return css`
				padding: 0 ${theme.grid.gutterInPx()};
				position: absolute;
				top: ${theme.grid.gutterInPx(-1)};
				left: ${theme.grid.gutterInPx()};
				z-index: 1;

				&:before {
					content: '';
					display: block;
					width: 100%;
					height: 9px;
					background: ${theme.colors.white};
					position: absolute;
					z-index: -1;
					left: 0;
					top: 0;
				}
			`;
		}

		return null;
	}};
`;

interface LabelProps {
	inline?: boolean;
}

const Label: React.FC<LabelProps & React.LabelHTMLAttributes<HTMLLabelElement>> = ({ children, ...props }) => {
	return <StyledLabel {...props}>{children}</StyledLabel>;
};

export default Label;
