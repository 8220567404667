import * as React from 'react';
import * as finlandiaCheeseLogo from './finlandiaCheeseLogo.svg';

export interface FinlandiaCheeseLogoIconProps {
	disableScaling?: boolean;
	logoSize?: string;
	inverted?: boolean;
}

const FinlandiaCheeseLogoIcon: React.FC<FinlandiaCheeseLogoIconProps> = ({ disableScaling, logoSize }) => {
	return <img src={finlandiaCheeseLogo} alt="Finlandia" width={disableScaling && logoSize ? logoSize : '100%'} />;
};

export default FinlandiaCheeseLogoIcon;
