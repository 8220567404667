import * as React from 'react';
import { injectIntl, WrappedComponentProps, FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import InputWithIcon from 'styleguide/components/Inputs/InputWithIcon/InputWithIcon';
import SendToMailIcon from 'styleguide/components/Icons/NormalIcons/SendToMailIcon/SendToMailIcon';
import KeyIcon from 'styleguide/components/Icons/NormalIcons/KeyIcon/KeyIcon';
import Checkbox from 'styleguide/components/Inputs/Checkbox/Checkbox';
import Button from 'styleguide/components/Buttons/Button/Button';
import { AlertType } from 'styleguide/components/Typography/Alert/Alert';
import Alert from 'styleguide/components/Typography/Alert/Alert';
import { connect, MapStateToProps } from 'react-redux';
import { InputShape } from '../../Forms/interfaces';
import injectForm, { InjectedFormProps } from '../../Forms/Form';
import { ValidationPatterns, validateRequired, validateEmail } from '../../Forms/helpers';
import { State } from '../../../reducers';
import ModalForm from 'common/components/General/ModalForm';

const ForgotPasswordLink = styled.span`
	font-family: ${props => props.theme.fonts.secondary};
	font-weight: 600;
	font-size: 15px;
	color: ${props => props.theme.colors.linkText};
	cursor: pointer;

	&:hover {
		color: ${props => props.theme.colors.linkTextHover};
	}
`;

export interface LoginFormFields {
	email: InputShape<string>;
	password: InputShape<string>;
	rememberLogin: InputShape<boolean>;
}

export interface LoginFormValidators {
	email: Array<(value: string) => string>;
	password: Array<(value: string) => string>;
	rememberLogin: Array<(value: string) => string>;
}

export interface LoginFormProps {
	fields?: LoginFormFields;
	onForgotPassword?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
}

interface LoginFormState {
	fields: LoginFormFields;
}

type Props = LoginFormProps & LoginFormStateProps & InjectedFormProps<LoginFormFields> & WrappedComponentProps;

class LoginForm extends React.Component<Props, LoginFormState> {
	public render() {
		const { intl, onForgotPassword, error, isLoading, fields, onSubmit, isValid } = this.props;
		const { email, password, rememberLogin } = fields;
		return (
			<ModalForm onSubmit={onSubmit} noValidate>
				<InputWithIcon
					icon={<SendToMailIcon />}
					type="email"
					name="email"
					value={email.value}
					validationError={email.touched && !email.focused && email.error ? email.error : ''}
					placeholder={intl.formatMessage({ id: 'forms_email_address' })}
					required={true}
					pattern={ValidationPatterns.EMAIL}
					autoComplete="username"
					onChange={this.onChange}
					onFocus={this.onFocus}
					onBlur={this.onBlur}
				/>
				<InputWithIcon
					icon={<KeyIcon />}
					type="password"
					name="password"
					value={password.value}
					validationError={password.touched && !password.focused && password.error ? password.error : ''}
					placeholder={intl.formatMessage({ id: 'forms_password' })}
					required={true}
					autoComplete="current-password"
					onChange={this.onChange}
					onFocus={this.onFocus}
					onBlur={this.onBlur}
				/>
				<Checkbox
					name="rememberLogin"
					checked={rememberLogin.value}
					value="Y"
					onChange={this.onRememberLoginChange}
					className="form-field__remember-login">
					<FormattedMessage id="user_remember_login" />
				</Checkbox>
				<Button type="submit" disabled={!isValid || isLoading} size="medium">
					<FormattedMessage id="user_log_in" />
				</Button>
				{error && (
					<Alert
						type={AlertType.Error}
						highlightedText={intl.formatMessage({ id: 'component_login_login_failed' })}
						className="form-error">
						{intl.formatMessage({ id: error })}
					</Alert>
				)}
				<p>
					<ForgotPasswordLink onClick={onForgotPassword}>
						<FormattedMessage id="user_forgot_password" />
					</ForgotPasswordLink>
				</p>
			</ModalForm>
		);
	}

	private onFocus = (event: React.FormEvent<HTMLInputElement>) => {
		const { value, name } = event.currentTarget;
		this.props.onInputFocus(value, name);
	};

	private onBlur = (event: React.FormEvent<HTMLInputElement>) => {
		const { value, name } = event.currentTarget;
		this.props.onInputBlur(value, name);
	};

	private onChange = (event: React.FormEvent<HTMLInputElement>) => {
		const { value, name } = event.currentTarget;
		this.props.onInputChange(value, name, true);
	};

	private onRememberLoginChange = (event: React.FormEvent<HTMLInputElement>) => {
		const { checked } = event.currentTarget;
		this.props.onInputChange(checked, 'rememberLogin');
	};
}

const loginFields: LoginFormFields = {
	email: { value: '' },
	password: { value: '' },
	rememberLogin: { value: false },
};

const loginValidators: LoginFormValidators = {
	email: [validateRequired('component_login_validation_email_required'), validateEmail],
	password: [validateRequired('component_login_validation_password_required')],
	rememberLogin: [],
};

interface LoginFormStateProps {
	isLoading?: boolean;
	error?: string;
}

const mapStateToProps: MapStateToProps<LoginFormStateProps, {}, State> = ({ login }): LoginFormStateProps => {
	const { loginForm } = login;

	return {
		isLoading: loginForm && loginForm.isLoading,
		error: loginForm && loginForm.error,
	};
};

export default connect(mapStateToProps)(injectForm(loginFields, loginValidators)(injectIntl(LoginForm)));
