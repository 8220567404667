import { put, call, takeLatest, fork } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { CookbookApi, cookbookApi } from '../services/cookbook';
import { userActions } from '../reducers/actions';

function* addRecipe(api: CookbookApi, { payload }: ReturnType<typeof userActions.addRecipe>) {
	const { recipeId } = payload;
	try {
		yield call(api.addRecipe, recipeId);

		yield put(userActions.addRecipeSucceeded());
	} catch (e) {
		yield put(userActions.addRecipeFailed(e));
	}
}

function* removeRecipe(api: CookbookApi, { payload }: ReturnType<typeof userActions.removeRecipe>) {
	const { recipeId } = payload;
	try {
		yield call(api.removeRecipe, recipeId);

		yield put(userActions.removeRecipeSucceeded(recipeId));
	} catch (e) {
		yield put(userActions.removeRecipeFailed(e));
	}
}

export function* watchAddRecipe() {
	yield takeLatest(getType(userActions.addRecipe), addRecipe, cookbookApi);
}

export function* watchRemoveRecipe() {
	yield takeLatest(getType(userActions.removeRecipe), removeRecipe, cookbookApi);
}

export function* recipeCookbookSaga() {
	yield fork(watchAddRecipe);
	yield fork(watchRemoveRecipe);
}
