import { createCustomAction, createStandardAction, ActionType } from 'typesafe-actions';
import { RecipeSearchResult } from '../services/recipe-search';
import { ProductSearchResult } from '../services/product-search';
import { ArticleSearchResult } from '../services/article-search';

export const globalSearchActions = {
	search: createCustomAction(
		'GLOBAL_SEARCH/SEARCH_REQUEST',
		type => (siteUrlId: string, searchTerm: string, maxResults: number) => ({
			type,
			payload: { siteUrlId, searchTerm, maxResults },
		})
	),
	searchRecipeResult: createCustomAction(
		'GLOBAL_SEARCH/SEARCH_RECIPE_RESULT',
		type => (searchResult: RecipeSearchResult) => ({
			type,
			payload: { searchResult },
		})
	),
	searchProfessionalRecipeResult: createCustomAction(
		'GLOBAL_SEARCH/SEARCH_PROFESSIONAL_RECIPE_RESULT',
		type => (searchResult: RecipeSearchResult) => ({
			type,
			payload: { searchResult },
		})
	),
	searchProductResult: createCustomAction(
		'GLOBAL_SEARCH/SEARCH_PRODUCT_RESULT',
		type => (searchResult: ProductSearchResult) => ({
			type,
			payload: { searchResult },
		})
	),
	searchArticleResult: createCustomAction(
		'GLOBAL_SEARCH/SEARCH_ARTICLE_RESULT',
		type => (searchResult: ArticleSearchResult) => ({
			type,
			payload: { searchResult },
		})
	),
	searchSucceeded: createStandardAction('GLOBAL_SEARCH/SEARCH_SUCCESS')(),
};

export type GlobalSearchActions = ActionType<typeof globalSearchActions>;
