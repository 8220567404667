import * as React from 'react';
import styled from 'styled-components';
import { media, withWindow } from '../../../helpers';
import { LazyImageProps } from '../../LazyImage';
import throttle from 'lodash/throttle';

const BORDER_RADIUS = 3; // px
const IMAGE_RATIO = 3 / 2;

const StyledCardHeader = styled.div`
	flex-basis: auto;
	position: relative;
	width: 100%;

	/* IE */
	@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
		${media.desktop`
			flex-basis: 136px;
		`};

		${media.desktop1440`
			flex-basis: 112px;
		`};
	}
`;

const CardImageContainer = styled.div`
	width: 100%;
	height: 100%;
	overflow: hidden;
	position: relative;
	border-radius: ${BORDER_RADIUS}px ${BORDER_RADIUS}px 0 0;

	> img {
		display: block;
		top: 50%;
		left: 50%;
		min-height: 100%;
		min-width: 100%;
		max-height: 100%;
		position: absolute;
		transform: translate(-50%, -50%);
	}
`;

interface CardImageWrapperState {
	height?: number;
}

interface CardImageProps {
	image: React.ReactElement<LazyImageProps>;
}

type Props = CardImageProps & React.HTMLAttributes<HTMLDivElement>;

class CardImage extends React.Component<Props, CardImageWrapperState> {
	public imageWrapperRef: React.RefObject<any>;

	private onResize = throttle(() => {
		this.setState({
			height: this.getHeight(),
		});
	});

	constructor(props: Props) {
		super(props);
		this.imageWrapperRef = React.createRef();
	}

	public componentDidMount() {
		this.setState({ height: this.getHeight() });

		withWindow(w => {
			w.addEventListener('resize', this.onResize);
		});
	}

	public componentWillUnmount() {
		withWindow(w => w.removeEventListener('resize', this.onResize));
	}

	public render() {
		const { children, className, image, ...rest } = this.props;

		const styles = { ...this.state };

		return (
			<StyledCardHeader ref={this.imageWrapperRef} className={className} {...rest} style={styles}>
				<CardImageContainer>{image}</CardImageContainer>
				{children}
			</StyledCardHeader>
		);
	}

	private getHeight() {
		const width = this.imageWrapperRef.current && this.imageWrapperRef.current.offsetWidth;
		return Math.round(width / IMAGE_RATIO);
	}
}

export default CardImage;
