import { combineReducers } from 'redux';
import { WeeklyMenuState } from 'common/components/WeeklyMenu/interfaces';
import { weeklyMenuActions, WeeklyMenuActions, weeklyMenuReducer } from 'common/components/WeeklyMenu/reducers';

export interface KitchenMenuState {
	weeklyMenu?: WeeklyMenuState;
}

export const initialState: KitchenMenuState = {
	weeklyMenu: {
		isLoading: false,
	},
};

export const kitchenMenuActions = {
	...weeklyMenuActions,
};

export type KitchenMenuActions = WeeklyMenuActions;

export const kitchenMenuReducer = combineReducers<KitchenMenuState>({
	weeklyMenu: weeklyMenuReducer,
});
