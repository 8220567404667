import * as React from 'react';
import { generate } from 'shortid';
import styled from 'styled-components';
import { NavLinkItemProps } from '../../interfaces';
import { SearchType } from 'pagetypes/Search/types';

const SearchResultListWrapper = styled.div`
	display: flex;
	flex-direction: column;
`;

const SearchResultListItem = styled.a`
	font-family: ${props => props.theme.fonts.secondary};
	font-size: 18px;
	line-height: 2.22;
	text-align: left;
	color: ${props => props.theme.colors.bodyText};
	text-decoration: none;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

export interface SearchResultListProps {
	items: NavLinkItemProps[];
	searchType?: SearchType;
	itemClicked?: (item: NavLinkItemProps, searchType?: SearchType) => void;
}

const SearchResultList: React.FC<SearchResultListProps> = ({ items = [], searchType, itemClicked }) => {
	const clickHandler = (item: NavLinkItemProps) => {
		if (!itemClicked) {
			return undefined;
		}

		return (ev: React.MouseEvent<HTMLAnchorElement>) => {
			ev.preventDefault();
			ev.stopPropagation();
			itemClicked(item, searchType);
		};
	};

	return (
		<SearchResultListWrapper>
			{items.map(item => {
				const { title, url } = item;
				const ch = clickHandler(item);

				return (
					<SearchResultListItem key={generate()} onClick={ch} href={url}>
						{title}
					</SearchResultListItem>
				);
			})}
		</SearchResultListWrapper>
	);
};

export default SearchResultList;
