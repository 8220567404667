import * as React from 'react';
import { useForm } from 'react-hook-form/dist/react-hook-form.ie11';
import { useIntl, FormattedMessage } from 'react-intl';
import Input from 'styleguide/components/Inputs/Input';
import { ValidationPatterns } from 'common/components/Forms/helpers';
import Checkbox from 'styleguide/components/Inputs/Checkbox';
import { Button } from 'styleguide/components/Buttons/Button';
import MediumHeader from 'styleguide/components/Typography/MediumHeader';
import styled from 'styled-components';
import { RegisterFormData } from '../interfaces';
import Alert, { AlertType } from 'styleguide/components/Typography/Alert';
import LoadingSpinner from 'common/components/Loading/LoadingSpinner';

const Form = styled.form`
	.form-field + .form-field {
		margin-top: ${({ theme }) => theme.grid.gutterInPx(2)};
	}
	.checkbox-label {
		font-size: 15px;
	}
	.loading {
		margin: 0;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		background: rgba(255, 255, 255, 0.5);
	}
`;

const FormActions = styled.div`
	margin-top: ${({ theme }) => theme.grid.gutterInPx(2)};
`;

interface Props {
	isLoading: boolean;
	error?: string | null;
	onRegisterToMaterialBank: (email: string) => void;
}

const ModalForm: React.FC<Props> = ({ error, isLoading, onRegisterToMaterialBank }) => {
	const intl = useIntl();

	const { handleSubmit, errors, register } = useForm<RegisterFormData>();

	const onSubmit = (fieldValues: RegisterFormData) => {
		onRegisterToMaterialBank(fieldValues.email);
	};

	return (
		<Form onSubmit={handleSubmit(onSubmit)}>
			<MediumHeader>
				<FormattedMessage id="materials_search_login_title" />
			</MediumHeader>
			<Input
				type="email"
				name="email"
				placeholder={intl.formatMessage({ id: 'forms_write_your_email_address' })}
				label={intl.formatMessage({ id: 'forms_email_address' })}
				validationError={errors.email?.message}
				ref={register({
					required: { value: true, message: intl.formatMessage({ id: 'forms_validation_email_empty' }) },
					pattern: {
						value: new RegExp(ValidationPatterns.EMAIL),
						message: intl.formatMessage({ id: 'forms_validation_email_check' }),
					},
				})}
			/>
			<Checkbox
				id="confirmProfessionalStatus"
				name="confirmProfessionalStatus"
				isInvalid={!!errors.confirmProfessionalStatus?.message?.length}
				validationError={errors.confirmProfessionalStatus?.message}
				ref={register({
					validate: value =>
						value === true || intl.formatMessage({ id: 'materials_search_login_acknowledge_required' }),
				})}>
				<FormattedMessage id="materials_search_login_acknowledge" />
			</Checkbox>

			{error && (
				<Alert
					type={AlertType.Error}
					highlightedText={intl.formatMessage({ id: 'materials_search_login_acknowledge' })}
					className="form-error">
					{intl.formatMessage({ id: error })}
				</Alert>
			)}

			{isLoading && <LoadingSpinner className="loading" />}

			<FormActions>
				<Button type="submit" size="medium" disabled={isLoading}>
					<FormattedMessage id="materials_search_login" />
				</Button>
			</FormActions>
		</Form>
	);
};

export default ModalForm;
