import * as React from 'react';
import WidgetRenderer from './WidgetRenderer';
import { MapStateToProps, connect } from 'react-redux';
import { State } from '../../reducers';
import { WidgetConfiguration } from './interfaces/interfaces';
import styled, { css } from 'styled-components';

interface WidgetZoneWrapperProps {
	isFullWidth?: boolean;
	escapeHtml?: boolean;
}

const WidgetZoneWrapper = styled.section<WidgetZoneWrapperProps>`
	${props =>
		props.isFullWidth
			? css`
					width: 100%;
			  `
			: ''};
`;

export interface WidgetZoneProps extends WidgetZoneWrapperProps {
	name: string;
	className?: string;
	customWidgets?: WidgetConfiguration[];
}

export interface WidgetZoneStateProps {
	widgets?: WidgetConfiguration[];
}

const WidgetZone: React.FC<WidgetZoneProps & WidgetZoneStateProps> = ({
	widgets = [],
	customWidgets,
	name,
	className,
	isFullWidth,
	escapeHtml,
}) => (
	<WidgetZoneWrapper
		isFullWidth={isFullWidth}
		className={`widget-zone widget-zone--${name.toLowerCase()} ${className ? className : ''}`}>
		{(customWidgets || widgets).map((w, i) => (
			<WidgetRenderer key={i} widget={w} escapeHtml={escapeHtml} />
		))}
	</WidgetZoneWrapper>
);

const mapStateToProps: MapStateToProps<WidgetZoneStateProps, WidgetZoneProps, {}> = (
	{ resource, app }: State,
	{ name }: WidgetZoneProps
): WidgetZoneStateProps => {
	const { resourceData } = resource;
	const allWidgets = resourceData ? resourceData.widgetConfigurations : {};
	const widgets = allWidgets[name];
	return {
		widgets,
	};
};

export default connect(mapStateToProps, {})(WidgetZone);
