import styled from 'styled-components';
import { media } from '../../../helpers';

const BORDER_RADIUS = 3; // px

const CardTitle = styled.div`
	border-radius: 0px 0px ${BORDER_RADIUS}px ${BORDER_RADIUS}px;
	background-color: ${props => props.theme.colors.bodyBackground};
	font-family: ${props => props.theme.fonts.secondary};
	font-size: 13px;
	font-weight: 600;
	line-height: 1.07;
	text-align: left;
	color: ${({ theme, color }) => color || theme.colors.brandPrimary};
	padding: 10px;
	word-break: break-all; /* IE11 */
	word-break: break-word;
	display: block;
	flex: 1 0 auto;

	${media.phone`
		display: flex;
		font-size: 15px;
		flex-direction: column;
		justify-content: center;
	`};

	${media.desktop`
		word-break: normal;
	`};
`;

/** @component */
export default CardTitle;
