import * as React from 'react';
import { css } from 'styled-components';
import styled from 'styled-components';
import { media } from 'styleguide/helpers/media';
import { NavLinkItemProps } from '../../interfaces';
import { useIntl } from 'react-intl';

const boxShadow = css`
	box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
`;

interface ProfessionalNavLinksWrapperProps {
	isSecondary?: boolean;
}

const ProfessionalNavLinksWrapper = styled.div<ProfessionalNavLinksWrapperProps>`
	display: flex;
	${media.desktop`
		${boxShadow}
	`};
	justify-content: space-evenly;
	background-color: ${props => props.theme.colors.brandPrimary};
	height: 40px;
	${({ isSecondary }) =>
		isSecondary &&
		`
		padding-top: 35px;
		height: 75px;
		border-bottom: 1px solid white;
	`}

	> a {
		pointer-events: auto;
	}
	> :not(button) {
		${props => props.theme.typography.button};
		color: ${props => props.theme.colors.white};
		border: 1px solid ${props => props.theme.colors.brandPrimary};
		font-size: 12px;
		text-align: center;
		text-decoration: none;
		text-transform: uppercase;
		&:hover {
			color: ${props => props.theme.colors.buttonText};
			background-color: ${props => props.theme.colors.buttonPrimaryHover};
		}
	}
	> * {
		display: flex;
		padding: 0 12px;
		height: 100%;
		justify-content: center;
		align-items: center;
		${media.phone`
			flex-grow: 1;
		`};
		${media.tablet`
			flex-grow: 1;
		`};
		letter-spacing: 0.8px;

		${({ isSecondary, theme }) =>
			!isSecondary &&
			`
			&:not(:first-child) {
				border-left: 1px solid ${theme.colors.borderNavigation};
			}
		`}
	}
`;
// display: none;
// ${media.desktop`
//   display: flex;
// `};

const MobileOnlyWrapper = styled.div`
	${media.desktop`
		display: none;
	`};
`;

export default function ProfessionalWebStoreButtons(props: {
	navLinks?: NavLinkItemProps[];
	isSecondary?: boolean;
	onLinkClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
}) {
	const { onLinkClick, isSecondary } = props;
	const intl = useIntl();

	const navLinks = [
		{
			title: intl.formatMessage({ id: 'component_navigation_toimitustukku' }),
			url: `https://toimitustukku.heinontukku.fi/`,
		},
		{
			title: intl.formatMessage({ id: 'component_navigation_pikatukku' }),
			url: `https://tukkukauppa.heinontukku.fi/`,
		},
		{
			title: intl.formatMessage({ id: 'component_navigation_tilauspalvelu' }),
			url: `https://valotilaus.fi/fi/all`,
		},
	];

	function renderNavLinks() {
		return (
			<ProfessionalNavLinksWrapper isSecondary={isSecondary}>
				{navLinks.map(navLink => (
					<a key={navLink.url} href={navLink.url} onClick={onLinkClick}>
						{navLink.title}
					</a>
				))}
			</ProfessionalNavLinksWrapper>
		);
	}

	if (isSecondary) {
		return <MobileOnlyWrapper>{renderNavLinks()}</MobileOnlyWrapper>;
	} else {
		return renderNavLinks();
	}
}
