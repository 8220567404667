import * as React from 'react';
import NormalIconWrapper, { NormalIconWrapperProps } from '../NormalIconWrapper';
import { ThemeContext } from 'styled-components';

const UserIcon: React.FC<NormalIconWrapperProps & React.SVGAttributes<SVGElement>> = ({ color, ...props }) => {
	const theme = React.useContext(ThemeContext);

	return (
		<NormalIconWrapper {...props}>
			<path
				fill={color ?? theme.colors.brandPrimary}
				d="M23.969 18.41a2.973 2.973 0 0 0-.787-1.72 5.818 5.818 0 0 0-1.082-.875c-.598-.354-1.486-.615-2.818-.947-1.52-.38-2.033-.665-2.16-.747-.02-.013-.03-.02-.065-.054a.999.999 0 1 0-1.41 1.418c.165.164.293.255.391.318.536.347 1.44.677 2.76 1.005.945.236 1.876.487 2.288.73.173.102.502.378.639.523a.953.953 0 0 1 .254.552c.032.318.024 1.216.001 1.894-.086 1.06-1.445 1.979-4.153 2.807-3.056.927-6.232.915-9.436-.037-2.786-.825-4.305-1.771-4.388-2.647L4 18.696c.182-.674.832-1.118.907-1.167.363-.238 1.179-.45 2.216-.702 1.358-.342 2.279-.676 2.813-1.021.091-.06.182-.124.272-.193a1 1 0 1 0-1.218-1.586c-.045.034-.09.067-.138.097-.156.1-.685.378-2.21.76-1.3.318-2.199.558-2.833.973-.345.227-1.494 1.08-1.788 2.526a.966.966 0 0 0-.021.2l.007 2.138c.226 2.505 3.227 3.707 5.816 4.474 1.81.538 3.616.806 5.402.806 1.75 0 3.482-.258 5.184-.774 2.288-.7 5.352-1.932 5.567-4.605.01-.266.053-1.622-.007-2.212M10.903 2.75c.64-.559 1.317-.794 2.13-.743 1.006.062 1.753.45 2.35 1.215.646.83.87 1.744.684 2.797a102.397 102.397 0 0 1-.89 4.474.496.496 0 0 1-.136.26c-.716.732-1.5 1.004-2.563.876-.723-.085-1.207-.352-1.602-.873a.78.78 0 0 1-.12-.237 33.912 33.912 0 0 1-.967-4.477c-.2-1.368.152-2.39 1.114-3.292zM7.81 6.331c.237 1.612.58 3.208 1.03 4.757.091.305.227.58.428.858.742.975 1.716 1.521 2.976 1.67.239.028.473.042.702.042 1.364 0 2.546-.505 3.52-1.502.323-.323.543-.725.662-1.21.345-1.56.652-3.102.912-4.582.28-1.594-.094-3.106-1.079-4.372C16.01.775 14.732.11 13.16.012c-1.338-.089-2.54.33-3.598 1.255C8.088 2.648 7.514 4.304 7.81 6.33v.001z"
			/>
		</NormalIconWrapper>
	);
};

export default UserIcon;
