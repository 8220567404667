import * as React from 'react';
import Divider from 'styleguide/components/Divider/Divider';
import { DividerSize, DividerDirection } from 'styleguide/components/Divider/Divider';
import styled, { css, ThemeConsumer } from 'styled-components';
import PrintIcon from 'styleguide/components/Icons/NormalIcons/PrintIcon/PrintIcon';
import RecyclebinIcon from 'styleguide/components/Icons/NormalIcons/RecyclebinIcon/RecyclebinIcon';
import { withWindow } from 'styleguide/helpers/window';
import SendToMailIcon from 'styleguide/components/Icons/NormalIcons/SendToMailIcon/SendToMailIcon';
import WhatsappIcon from 'styleguide/components/Icons/SocialIcons/WhatsappIcon/WhatsappIcon';
import { media } from 'styleguide/helpers/media';
import Modal from 'styleguide/components/Modals/Modal/Modal';
import LinkIcon from 'styleguide/components/Icons/SocialIcons/LinkIcon/LinkIcon';
import { FormattedMessage } from 'react-intl';
import ShoppingListShareForm, { EmailShareFormFields } from './ShoppingListEmailShareForm';
import ShoppingListLinkShareForm from './ShoppingListLinkShareForm';
import { UserShoppingListSharingShape } from '../reducers/shopping-list';
import { getSiteRelativePublicUrl } from 'utils/service';
import { ShareSectionHeading } from 'common/components/SocialLinks/SocialLinks';
import injectRoutable, { InjectedRoutableProps } from 'common/components/Routing/Routable';

const PrintWrapper = styled.div`
	@media print {
		display: none;
	}
`;

const ShoppingListActionsWrapper = styled.div`
	padding: 10px 30px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;

	${media.tablet`
    flex-direction: row;
    justify-content: space-between;
	  height: 60px;
  `};

	& > .divider {
		height: 40px;
		margin: 0 20px;
	}
`;

const SectionWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 40px;

	${media.tablet`
	  width: 40%;

		&:first-child {
			width: 60%;
		}
  `};

	& + & {
		margin-top: 20px;

		${media.tablet`
      margin: 0;
    `};
	}

	.divider {
		margin: 0 20px;
	}
`;

const ActionButton = styled.button`
	background: none;
	border: none;
	padding: 0;
	display: flex;
	align-items: center;
	font-family: ${props => props.theme.fonts.secondary};
	font-size: 18px;
	color: ${props => props.theme.colors.bodyText};
	cursor: pointer;

	&:focus,
	&:active {
		outline: none;
	}

	& > span {
		margin-left: 10px;
	}
`;

const shareStyles = css`
	width: 40px;
	height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
`;

const ShareButton = styled.div`
	${shareStyles};
	margin-left: 15px;
`;

const ShareLink = styled.a`
	${shareStyles};
`;

interface ShoppingListActionsProps {
	shoppingListId?: string | null;
	isLinkSharingOpen?: boolean;
	emailSharing?: UserShoppingListSharingShape;
	onEmailShareFormOpen: () => void;
	onEmailShareFormClose: () => void;
	onEmailShareFormSubmit: (fields: EmailShareFormFields) => void;
	onEmptyShoppingList: () => void;
	onLinkShareFormOpen: () => void;
	onLinkShareFormClose: () => void;
}

class ShoppingListActions extends React.Component<ShoppingListActionsProps & InjectedRoutableProps> {
	public render() {
		const {
			onEmptyShoppingList,
			onEmailShareFormSubmit,
			emailSharing,
			onEmailShareFormOpen,
			onEmailShareFormClose,
			isLinkSharingOpen,
			shoppingListId,
			onLinkShareFormOpen,
			onLinkShareFormClose,
			routing,
		} = this.props;

		const shoppingListUrl = getSiteRelativePublicUrl(routing, `/jaettu-kauppalista/${shoppingListId}`);

		return (
			<ThemeConsumer>
				{theme => (
					<PrintWrapper>
						<Divider size={DividerSize.full} direction={DividerDirection.horizontal} />
						<ShoppingListActionsWrapper>
							<SectionWrapper>
								<ShareSectionHeading>
									<FormattedMessage id="user_shopping_list_send" />
								</ShareSectionHeading>
								{shoppingListId && (
									<>
										<ShareLink
											className="ga-share-link ga-share-link--whatsapp"
											href={`whatsapp://send?text=${shoppingListUrl}`}>
											<WhatsappIcon />
										</ShareLink>
										<ShareButton className="ga-share-link ga-share-link--link" onClick={onLinkShareFormOpen}>
											<LinkIcon />
										</ShareButton>
									</>
								)}
								<ShareButton className="ga-share-link ga-share-link--email" onClick={onEmailShareFormOpen}>
									<SendToMailIcon color={theme.colors.bodyTextLight} />
								</ShareButton>
							</SectionWrapper>
							<Divider
								size={DividerSize.full}
								direction={DividerDirection.vertical}
								className="divider"
								isVisible={{ default: false, tablet: true }}
							/>
							<SectionWrapper>
								<ActionButton className="ga-page-action ga-page-action--print" onClick={print}>
									<PrintIcon color={theme.colors.bodyTextLight} />
									<FormattedMessage id="global_print" />
								</ActionButton>
								<Divider size={DividerSize.full} direction={DividerDirection.vertical} className="divider" />
								<ActionButton className="ga-page-action ga-page-action--empty" onClick={onEmptyShoppingList}>
									<RecyclebinIcon color={theme.colors.bodyTextLight} />
									<FormattedMessage id="user_shoppinglist_empty" />
								</ActionButton>
							</SectionWrapper>
						</ShoppingListActionsWrapper>
						<Divider size={DividerSize.full} direction={DividerDirection.horizontal} />
						{emailSharing && (
							<Modal onClose={onEmailShareFormClose}>
								<ShoppingListShareForm {...emailSharing} onFormSubmit={onEmailShareFormSubmit} />
							</Modal>
						)}
						{isLinkSharingOpen && (
							<Modal onClose={onLinkShareFormClose}>
								<ShoppingListLinkShareForm shoppingListUrl={shoppingListUrl} />
							</Modal>
						)}
					</PrintWrapper>
				)}
			</ThemeConsumer>
		);
	}
}

const print = () => {
	withWindow(w => w.print());
};

export default injectRoutable(ShoppingListActions);
