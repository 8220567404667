import { Reducer } from 'redux';
import groupBy from 'lodash/groupBy';
import { getType } from 'typesafe-actions';

import { ResourceActions, resourceActions } from './actions';
import { EmergencyNotification, ResourceContextName } from '../interfaces';
import { AsyncLoadableState } from '../../../interfaces/common';
import { ResourceType, ResourceContent, ResourceSendToEmailTypes } from '../types';
import { cmsContentReducer } from 'pagetypes/CmsContent/reducers';
import { myContentReducer } from 'pagetypes/MyContent/reducers';
import { recipeReducer } from 'pagetypes/Recipe/reducers/reducers';
import { StaticHighlightActions, staticHighlightActions } from 'common/components/Widgets/reducers/static-highlights';
import { ProductSearchActions } from 'pagetypes/ProductFront/reducers/product-search-actions';
import { KitchenMenuActions, kitchenMenuReducer } from 'pagetypes/KitchenMenu/reducers';
import { ProductFrontActions, productFrontReducer } from 'pagetypes/ProductFront/reducers/reducers';
import { recipeFrontReducer } from 'pagetypes/RecipeFront/reducers/reducers';
import { productReducer } from 'pagetypes/Product/reducers/reducers';
import { WidgetConfiguration } from '../../Widgets/interfaces/interfaces';
import { searchReducer } from 'pagetypes/Search/reducers';
import { SearchActions } from 'pagetypes/Search/reducers/search-actions';
import { recipeAspectGroupReducer } from 'pagetypes/RecipeAspectGroup/reducers';
import { StaticHighlight } from 'common/components/Widgets/interfaces/StaticContentHighlight';
import { brandReducer } from 'pagetypes/Brand/reducers';
import { productGroupReducer } from 'pagetypes/ProductGroup/reducers';
import { NavLinkItemProps } from '../../Navigation/interfaces';
import { newsArticleReducer, NewsArchiveActions } from 'pagetypes/NewsArticle/reducers';
import { homeReducer } from 'pagetypes/Home/reducers';
import { Locales } from 'common/types/locale';
import { materialSearchReducer, MaterialSearchActions } from 'pagetypes/MaterialSearch/reducers';
import { RoutingActions } from 'common/components/Routing/reducers';
import { eventContentReducer } from '../../../../pagetypes/Event/reducers';
import { Theme } from 'styleguide/themes/theme';

export interface ResourceData {
	widgetConfigurations: { [zone: string]: WidgetConfiguration[] };
	notifications?: EmergencyNotification[];
}

export interface ResourceState extends AsyncLoadableState {
	id?: string;
	type?: ResourceType;
	layout?: string;
	themeColor?: string;
	content?: ResourceContent;
	resourceData?: ResourceData;
	title?: string;
	titleHtml?: string;
	metaDescription?: string;
	structuredData: string | null;
	relativeUrl?: string;
	titleAsSlug?: string;
	basePath: string;
	canonicalUrl?: string;
	ampUrl?: string | null;
	previewImage?: string;
	heroImageDesktop?: string;
	heroImageMobile?: string;
	heroImageOffsetY: number;
	heroMediaType?: string;
	staticHighlights: StaticHighlight[];
	isPublished: boolean;
	modificationTime?: string | null;
	publicationTime: string | null;
	isDraft: boolean;
	publishActionRedirectUrl?: string;
	notFound: boolean;
	context: ResourceContextName;
	noindex: boolean;
	topics: NavLinkItemProps[];
	sendToEmail?: ResourceSendToEmailTypes;
	language: Locales | null;
	highlightIcon: string | null;
	dataLayerCategory: string | null;
	theme?: Theme | null;
}

export const initialResourceState: ResourceState = {
	isLoading: false,
	error: null,
	heroImageOffsetY: 0,
	staticHighlights: [],
	isPublished: true,
	modificationTime: null,
	publicationTime: null,
	isDraft: false,
	notFound: false,
	structuredData: null,
	basePath: '',
	context: 'valio.fi',
	noindex: false,
	topics: [],
	language: null,
	highlightIcon: null,
	dataLayerCategory: null,
	theme: null,
	resourceData: {
		widgetConfigurations: {},
		// notifications: [
		// 	{
		// 		id: '1',
		// 		message:
		// 			'Valio poistaa varotoimenpiteenä myynnistä Valio Tuuti® äidinmaidonkorvikkeita ja vieroitusvalmisteita, joissa on parasta ennen -päiväys 14.6.2018. Pahoittelemme kuluttajille mahdollisesti aiheutunutta haittaa.',
		// 		url: '/uutiset/',
		// 		type: 'warning',
		// 	},
		// 	{
		// 		id: '2',
		// 		message:
		// 			'Palvelussa on tietoliikenneyhteyksistä johtuvaa hitautta. Ongelmaa selvitetään. Pahoittelemme häiriötä.',
		// 		type: 'info',
		// 	},
		// ],
	},
};

export const resourceReducer: Reducer<ResourceState, ResourceActions | StaticHighlightActions> = (
	state = initialResourceState,
	action
) => {
	switch (action.type) {
		case getType(resourceActions.fetchResource):
			return { ...state, isLoading: action.payload.resourceUrl !== state.relativeUrl };

		case getType(resourceActions.setResource):
			const {
				resource: {
					id,
					type,
					layout,
					themeColor,
					previewImage,
					heroImageDesktop,
					heroImageMobile,
					heroImageOffsetY,
					heroMediaType,
					title,
					titleHtml,
					titleAsSlug,
					metaDescription,
					structuredData,
					basePath,
					canonicalUrl,
					ampUrl,
					isDraft,
					isPublished,
					modificationTime,
					publicationTime,
					context,
					noindex,
					topics,
					language,
					highlightIcon,
					dataLayerCategory,
					theme,
				},
				resourceUrl,
			} = action.payload;
			const resourceChanged = state.id !== id || state.type !== type;

			return {
				...state,
				id,
				type,
				layout,
				themeColor,
				content: contentReducer(state, action, resourceChanged),
				resourceData: resourceDataReducer(state.resourceData, action),
				previewImage,
				heroImageDesktop,
				heroImageMobile,
				heroImageOffsetY,
				heroMediaType,
				title,
				titleHtml,
				titleAsSlug,
				metaDescription: metaDescription || '',
				structuredData,
				relativeUrl: resourceUrl,
				basePath,
				canonicalUrl: canonicalUrl === null ? undefined : canonicalUrl,
				ampUrl,
				isLoading: false,
				error: null,
				notFound: false,
				isDraft,
				isPublished,
				modificationTime,
				publicationTime,
				context,
				noindex,
				topics,
				language,
				highlightIcon,
				dataLayerCategory,
				theme,
			};

		case getType(resourceActions.fetchResourceFailed): {
			const { error } = action.payload;
			if (error.status === 404) {
				return { ...state, isLoading: false, notFound: true, error: null };
			}

			return { ...state, isLoading: false, notFound: false, error: action.payload.error.toString() };
		}

		case getType(staticHighlightActions.setStaticHighlights):
			return { ...state, staticHighlights: action.payload };

		case getType(resourceActions.setResourcePublicationStatusSuccess):
			return { ...state, publishActionRedirectUrl: action.payload.redirectUrl };

		case getType(resourceActions.dismissNotification):
			return { ...state, resourceData: resourceDataReducer(state.resourceData, action) };

		case getType(resourceActions.sendResourceToEmailRequest):
			return { ...state, sendToEmail: 'SENDING' };

		case getType(resourceActions.sendResourceToEmailSuccess):
			if (action.payload.result === 'OK') {
				return { ...state, sendToEmail: 'SENT' };
			}
			return { ...state, sendToEmail: 'ERROR' };

		case getType(resourceActions.sendResourceToEmailError):
			return { ...state, sendToEmail: 'ERROR' };

		default:
			return {
				...state,
				content: contentReducer(state, action, false),
			};
	}
};

const contentReducers: {
	[type: string]: Reducer<
		ResourceContent,
		| ResourceActions
		| ProductSearchActions
		| StaticHighlightActions
		| KitchenMenuActions
		| ProductFrontActions
		| SearchActions
		| NewsArchiveActions
		| MaterialSearchActions
		| RoutingActions
	>;
} = {
	[ResourceType.home]: homeReducer,
	[ResourceType.brand]: brandReducer,
	[ResourceType.cmsContent]: cmsContentReducer,
	[ResourceType.event]: eventContentReducer,
	[ResourceType.myContent]: myContentReducer,
	[ResourceType.recipe]: recipeReducer,
	[ResourceType.professionalRecipe]: recipeReducer,
	[ResourceType.recipeAspectGroup]: recipeAspectGroupReducer,
	[ResourceType.recipeFront]: recipeFrontReducer,
	[ResourceType.product]: productReducer,
	[ResourceType.productFront]: productFrontReducer,
	[ResourceType.productGroup]: productGroupReducer,
	[ResourceType.kitchenMenu]: kitchenMenuReducer,
	[ResourceType.recipeSearch]: searchReducer,
	[ResourceType.productSearch]: searchReducer,
	[ResourceType.articleSearch]: searchReducer,
	[ResourceType.newsArticle]: newsArticleReducer,
	[ResourceType.materialSearch]: materialSearchReducer,
};

const unknownContentReducer = (state: {} = {}, action: ResourceActions): ResourceContent => {
	return state;
};

const getContentReducer = (type: ResourceType) => {
	return contentReducers[type] || unknownContentReducer;
};

const contentReducer = (
	state: ResourceState,
	action: ResourceActions | StaticHighlightActions,
	resourceChanged: boolean
): ResourceContent => {
	if (action.type === getType(resourceActions.setResource)) {
		const contentState = resourceChanged ? undefined : state.content;
		return getContentReducer(action.payload.resource.type)(contentState, action);
	}

	if (state.type) {
		return getContentReducer(state.type)(state.content, action);
	}

	return state.content;
};

const resourceDataReducer = (state: ResourceData | undefined, action: ResourceActions): ResourceData => {
	if (action.type === getType(resourceActions.setResource)) {
		const { widgetConfigurations, notifications } = action.payload.resource.resourceData;
		// const { widgetConfigurations } = action.payload.resource.resourceData;
		return {
			...state,
			widgetConfigurations: groupBy(widgetConfigurations, w => w.zone),
			notifications,
		};
	}

	if (action.type === getType(resourceActions.dismissNotification)) {
		if (state && state.notifications) {
			return {
				...state,
				notifications: state.notifications.map(notification => {
					if (notification.id === action.payload) {
						return {
							...notification,
							isDismissed: true,
						};
					}
					return notification;
				}),
			};
		}
	}

	if (!state) {
		return {
			widgetConfigurations: {},
		};
	}

	return state;
};
