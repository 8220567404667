import * as React from 'react';
import { IconWrapperProps } from '../../../interfaces';
import IconWrapper from '../IconWrapper';
import { ThemeContext } from 'styled-components';

export interface NormalIconWrapperProps extends IconWrapperProps {
	isDisabled?: boolean;
}

const NormalIconWrapper: React.FC<NormalIconWrapperProps & React.SVGAttributes<SVGElement>> = ({
	children,
	isDisabled,
	color,
	className,
	...rest
}) => {
	const theme = React.useContext(ThemeContext);
	return (
		<IconWrapper className={className} color={isDisabled ? theme.colors.inputDisabledText : color} {...rest}>
			{children}
		</IconWrapper>
	);
};

NormalIconWrapper.defaultProps = {
	size: 26,
	viewBox: '0 0 26 26',
};

export default NormalIconWrapper;
