import * as React from 'react';
import NormalIconWrapper, { NormalIconWrapperProps } from '../NormalIconWrapper';
import { ThemeContext } from 'styled-components';

const ShareLinkIcon: React.FC<NormalIconWrapperProps & React.SVGAttributes<SVGElement>> = ({ color, ...props }) => {
	const theme = React.useContext(ThemeContext);
	return (
		<NormalIconWrapper viewBox="0 0 16 14" {...props}>
			<path fill={color ?? theme.colors.brandPrimary} d="M8 4C2.8 4 0 7.8 0 14C1.5 11.6 2.7 10 8 10V14L16 7L8 0V4Z" />
		</NormalIconWrapper>
	);
};

export default ShareLinkIcon;
