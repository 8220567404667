import * as React from 'react';
import styled from 'styled-components';

const StyledSearchCategoryTitle = styled.div`
	${props => props.theme.typography.heading};
	font-size: 15px;
	text-align: left;
	color: ${props => props.theme.colors.brandPrimary};
	text-transform: uppercase;
`;

const SearchCategoryTitle: React.FC = ({ children }) => (
	<StyledSearchCategoryTitle>{children}</StyledSearchCategoryTitle>
);

export default SearchCategoryTitle;
