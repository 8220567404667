import * as React from 'react';
import styled from 'styled-components';
import MediumHeader from 'styleguide/components/Typography/MediumHeader/MediumHeader';
import BodyText from 'styleguide/components/Typography/BodyText/BodyText';
import LockIcon from 'styleguide/components/Icons/NormalIcons/LockIcon/LockIcon';
import LabeledIconButtonLink from 'styleguide/components/Buttons/LabeledIconButton/LabeledIconButtonLink';
import { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';

const DescriptionWrapper = styled.div`
	padding: 60px;
	text-align: center;
	margin-bottom: 90px;

	.center-button button {
		width: 100%;
	}
`;

const DescriptionText = styled(BodyText)`
	max-width: 527px;
	margin-left: auto;
	margin-right: auto;
`;

interface RecipesIntroductionProps {
	onLoginClicked: () => void;
	isLoggedIn: boolean;
}

const RecipesIntroduction: React.FC<RecipesIntroductionProps> = ({ onLoginClicked, isLoggedIn }) => (
	<DescriptionWrapper>
		<MediumHeader greyed>
			<FormattedMessage id="user_your_saved_recipes" />
		</MediumHeader>
		<DescriptionText>
			<FormattedMessage id="user_your_saved_recipes_description" />
		</DescriptionText>
		{!isLoggedIn && (
			<Fragment>
				<DescriptionText>
					<FormattedMessage id="user_login_to_save_recipes" />
				</DescriptionText>
				<div className="center-button">
					<LabeledIconButtonLink icon={<LockIcon />} onClick={onLoginClicked} rel="nofollow">
						<FormattedMessage id="user_log_in" />
					</LabeledIconButtonLink>
				</div>
			</Fragment>
		)}
	</DescriptionWrapper>
);

export default RecipesIntroduction;
