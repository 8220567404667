import * as React from 'react';
import Button from 'styleguide/components/Buttons/Button/Button';
import BodyText from 'styleguide/components/Typography/BodyText/BodyText';
import MediumHeader from 'styleguide/components/Typography/MediumHeader/MediumHeader';
import styled from 'styled-components';
import Input from 'styleguide/components/Inputs/Input/Input';
import { withWindow } from 'styleguide/helpers/window';
import { AlertType } from 'styleguide/components/Typography/Alert/Alert';
import Alert from 'styleguide/components/Typography/Alert/Alert';
import { FormattedMessage } from 'react-intl';

const LinkShareFormWrapper = styled.div`
	text-align: center;

	.medium-header {
		margin: 0 0 20px;
	}

	button {
		margin: 20px auto 0;
	}

	.copied-notification {
		margin-top: 20px;
	}
`;

export interface ShoppingListLinkShareFormProps {
	shoppingListUrl: string;
}

interface ShoppingListLinkShareFormState {
	isCopied: boolean;
}

class ShoppingListLinkShareForm extends React.Component<
	ShoppingListLinkShareFormProps,
	ShoppingListLinkShareFormState
> {
	private inputRef: React.RefObject<HTMLInputElement>;
	private copyNoticeTimeoutId: number;

	constructor(props: ShoppingListLinkShareFormProps) {
		super(props);
		this.inputRef = React.createRef();
		this.state = {
			isCopied: false,
		};
	}

	public componentWillUnmount() {
		withWindow(w => {
			w.clearTimeout(this.copyNoticeTimeoutId);
		});
	}

	public render() {
		const { shoppingListUrl } = this.props;
		const { isCopied } = this.state;

		return (
			<LinkShareFormWrapper>
				<MediumHeader className="medium-header">
					<FormattedMessage id="user_shopping_list_share_link" />
				</MediumHeader>
				<BodyText>
					<FormattedMessage id="user_shopping_list_share_link_description" />
				</BodyText>
				<Input name="shopping-list-link" value={shoppingListUrl} ref={this.inputRef} readOnly />
				{isCopied && (
					<Alert type={AlertType.Success} highlightedText="Linkki kopioitu." className="copied-notification">
						<FormattedMessage id="user_shopping_list_share_link_copied_instruction" />
					</Alert>
				)}
				<Button size="medium" onClick={this.onCopy}>
					<FormattedMessage id="global_copy" />
				</Button>
			</LinkShareFormWrapper>
		);
	}

	private onCopy = () => {
		if (this.inputRef.current) {
			this.inputRef.current.select();
			withWindow(w => {
				try {
					w.document.execCommand('copy');
					this.setState({ isCopied: true });
					this.copyNoticeTimeoutId = w.setTimeout(() => {
						this.setState({ isCopied: false });
					}, 5000);
				} catch (e) {
					// ? Show an indicator that text wasn't copied, or just leave as is?
				}
			});
		}
	};
}

export default ShoppingListLinkShareForm;
