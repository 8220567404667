import * as React from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import FacebookIcon from 'styleguide/components/Icons/SocialIcons/FacebookIcon/FacebookIcon';
import InstagramIcon from 'styleguide/components/Icons/SocialIcons/InstagramIcon/InstagramIcon';
import YoutubeIcon from 'styleguide/components/Icons/SocialIcons/YoutubeIcon/YoutubeIcon';
import LinkedinIcon from 'styleguide/components/Icons/SocialIcons/LinkedinIcon/LinkedinIcon';
import Logo from 'styleguide/components/Logos/Logo/Logo';
import { media } from 'styleguide/helpers/media';
import styled, { useTheme } from 'styled-components';

const AIMO_INSTAGRAM_URL = 'https://www.instagram.com/valioaimo/';
const AIMO_FACEBOOK_URL = 'https://www.facebook.com/valioaimo/';
const AIMO_LINKEDIN_URL = 'https://fi.linkedin.com/company/valio-aimo';
const AIMO_YOUTUBE_URL = 'https://www.youtube.com/channel/UCbQ9atrqYMD5SJTJA2Iiraw';

const FooterWrapper = styled.div`
	background-color: ${props => props.theme.colors.brandPrimary};

	@media print {
		display: none;
	}
`;

const FooterContainer = styled.div`
	display: flex;
	flex-flow: row wrap;
	justify-content: space-around;
	align-content: flex-start;
	padding: 40px 15px;
	margin: 0 auto;

	${media.desktop`
    max-width: 1200px;
    flex-flow: row nowrap;
    justify-content: space-between;
	`};
`;

const LogoWrapper = styled.div`
	flex: 0 0 100px;
`;

interface FooterLinkGroupProps {
	bolded?: boolean;
}

const FooterLinks = styled.div`
	flex: 0 1 100%;
	display: flex;
	flex-flow: row wrap;
	justify-content: flex-start;
	align-content: flex-start;
	margin: 30px auto;
	padding: 0 30px;

	${media.tablet`
    flex: 0 1 calc(100% - 170px);
    margin-top: 0;
    padding: 0;
  `}

	${media.desktop`
    flex: 0 1 50%;
    margin: 0;
	`};
`;

const FooterLinkGroup = styled.ul<FooterLinkGroupProps>`
	flex: 1 1 100%;
	font-weight: ${props => (props.bolded ? 600 : 400)};
	list-style: none;
	padding: 0;
	margin: 10px auto;
	min-width: 165px;

	> li {
		white-space: nowrap;
	}

	> li:before {
		content: '›';
		color: ${({ theme }) => theme.colors.white};
		font-size: 14px;
		width: 15px;
		float: left;
	}

	${media.phone`
    flex: 1 1 50%;
  `}

	${media.phone560`
    flex: 1 1 33.3333%;
    margin: 0;
  `}
`;

const FooterLink = styled.a`
	display: inline-block;
	font-family: ${props => props.theme.fonts.secondary};
	font-size: 14px;
	color: ${props => props.theme.colors.white};
	text-decoration: none;
	white-space: normal;
`;

const SocialWrapper = styled.div`
	flex: 0 1 300px;

	${media.desktop`
    flex: 0 1 25%;
	`};
`;

const IconGroup = styled.div`
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	margin-bottom: 10px;

	${media.desktop`
    max-width: 100%;
	`};
`;

const CopyRightTextWrapper = styled.div`
	font-family: ${props => props.theme.fonts.secondary};
	font-size: 12px;
	color: ${props => props.theme.colors.white};
	line-height: 1.67;
	text-align: center;

	${media.desktop`
    text-align: right;
	`};
`;

const AimoFooter: React.FC<{}> = () => {
	const theme = useTheme();

	const renderCopyText = () => (
		<CopyRightTextWrapper>
			<FormattedHTMLMessage id="global_copyright_valio" />
		</CopyRightTextWrapper>
	);

	return (
		<FooterWrapper>
			<FooterContainer>
				<LogoWrapper>
					<Logo logo="aimo" inverted={true} disableScaling={true} logoSize="100px" href={'/ammattilaiset/'} />
				</LogoWrapper>
				<FooterLinks>
					<FooterLinkGroup bolded={true}>
						<li>
							<FooterLink href="/ammattilaiset/valio-aimo-yhteystiedot/">
								<FormattedMessage id="global_contact_information" />
							</FooterLink>
						</li>
						<li>
							<FooterLink href="/ammattilaiset/tule-asiakkaaksi/">
								<FormattedMessage id="component_aimofooter_signup" />
							</FooterLink>
						</li>
						<li>
							<FooterLink href="/ammattilaiset/uutiskirjeet-ammattilaisille/">
								<FormattedMessage id="global_order_newsletter" />
							</FooterLink>
						</li>
					</FooterLinkGroup>
					<FooterLinkGroup>
						<li>
							<FooterLink href="/ammattilaiset/tukkupalvelut/">
								<FormattedMessage id="component_aimofooter_wholesale" />
							</FooterLink>
						</li>
						<li>
							<FooterLink href="/ammattilaiset/valio-aimo-yhteystiedot/pikatukkujen-aukioloajat/">
								<FormattedMessage id="component_aimofooter_wholesale_hours" />
							</FooterLink>
						</li>
						<li>
							<FooterLink href="/ammattilaiset/reseptit/haku/#">
								<FormattedMessage id="component_aimofooter_pro_recipes" />
							</FooterLink>
						</li>
						<li>
							<FooterLink href="/ammattilaiset/ammattilaistuotteet/">
								<FormattedMessage id="component_aimofooter_pro_products" />
							</FooterLink>
						</li>
					</FooterLinkGroup>
					<FooterLinkGroup>
						<li>
							<FooterLink href="/kayttoehdot/">
								<FormattedMessage id="global_general_terms_of_use" />
							</FooterLink>
						</li>
						<li>
							<FooterLink href="/tietosuoja/">
								<FormattedMessage id="global_privacy_policy" />
							</FooterLink>
						</li>
						<li>
							<FooterLink href="/evastekaytannot/">
								<FormattedMessage id="global_cookie_terms" />
							</FooterLink>
						</li>
						<li>
							<FooterLink href="https://www.oivahymy.fi/hae-yrityksia/#text=valio%20oy" target="_blank" rel="noopener">
								<FormattedMessage id="component_footer_oiva_reports" />
							</FooterLink>
						</li>
					</FooterLinkGroup>
				</FooterLinks>
				<SocialWrapper>
					<IconGroup>
						<a href={AIMO_INSTAGRAM_URL} rel="noopener">
							<InstagramIcon color={theme.colors.white} size={24} />
						</a>
						<a href={AIMO_FACEBOOK_URL} rel="noopener">
							<FacebookIcon color={theme.colors.white} size={24} />
						</a>
						<a href={AIMO_LINKEDIN_URL} rel="noopener">
							<LinkedinIcon color={theme.colors.white} size={24} />
						</a>
						<a href={AIMO_YOUTUBE_URL} rel="noopener">
							<YoutubeIcon color={theme.colors.white} size={24} />
						</a>
					</IconGroup>
					{renderCopyText()}
				</SocialWrapper>
			</FooterContainer>
		</FooterWrapper>
	);
};

export default AimoFooter;
