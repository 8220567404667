import { trimStart } from 'utils/strings';
import { fetchJson, throwMissingSiteUrlIdError } from 'utils/service';
import { StaticHighlight } from '../interfaces/StaticContentHighlight';
import { ensureResourceUrl } from 'common/components/Resource/services';

export async function getStaticHighlights(siteUrlId: string, resourceUrl: string): Promise<StaticHighlight[]> {
	if (!siteUrlId) {
		throwMissingSiteUrlIdError();
	}
	const resourcePart = trimStart(ensureResourceUrl(resourceUrl), '/');
	return await fetchJson<StaticHighlight[]>(`/api/sites/${siteUrlId}/static-highlights/for-resource/${resourcePart}`);
}

export const staticHighlightApi = {
	getStaticHighlights,
};

export type StaticHighlightApi = typeof staticHighlightApi;
