import { css } from 'styled-components';
import extend from './extend';
import { oddlygoodThemeFonts } from './oddlygood';
import valio from './valio';

const valiooddlygood = extend(valio, {
	fonts: {
		primary: oddlygoodThemeFonts.lato,
		secondary: oddlygoodThemeFonts.lato,
		primaryButtons: oddlygoodThemeFonts.lato,
		secondaryButtons: oddlygoodThemeFonts.lato,
	},
	typography: {
		heading: css`
			font-family: ${oddlygoodThemeFonts.futuraPassata} !important;
			font-weight: 700;
			text-transform: uppercase;
		`,
	},
	styledVariations: {
		recipeHighlight: () => css`
			.recipe-highlights-header {
				font-size: 34px;
			}

			.recipe-highlights-text {
				text-align: center;
			}
		`,
	},
});

export default valiooddlygood;
