import { getType, createStandardAction, ActionType } from 'typesafe-actions';
import { AppSettingsAM, WithAppSettings } from './services';
import { RoutingState } from '../Routing/reducers';
import { getNewState } from 'common/reducers/utils';

export const appActions = {
	fetchSettingsAndNavigation: createStandardAction('APP/FETCH_SETTINGS')<RoutingState>(),
	setSettings: createStandardAction('APP/FETCH_SETTINGS_SUCCESS')<AppSettingsAM>(),
	fetchSettingsFailed: createStandardAction('APP/FETCH_SETTINGS_FAILURE')<undefined>(),
};

export type AppActions = ActionType<typeof appActions>;

export interface AppState extends WithAppSettings {
	initiallyRenderedOnServer: boolean;
}

export const initialAppState: AppState = {
	settings: null,
	initiallyRenderedOnServer: false,
};

const appReducer = (state: AppState = initialAppState, action: AppActions): AppState => {
	switch (action.type) {
		case getType(appActions.setSettings):
			const newState = {
				...state,
				settings: getNewState(state.settings, action.payload),
			};
			return getNewState(state, newState);
		default:
			return state;
	}
};

export default appReducer;
