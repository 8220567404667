import * as React from 'react';
import { StyledButton } from './common';
import { ButtonProps } from '../../../interfaces';

const ButtonComponent = StyledButton('button');

const Button: React.FC<ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>> = ({ children, ...rest }) => (
	<ButtonComponent {...rest}>{children}</ButtonComponent>
);

Button.defaultProps = {
	size: 'large',
	type: 'button',
};

export default Button;
