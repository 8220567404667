import styled, { css } from 'styled-components';

interface DropdownButtonProps {
	isOpen: boolean;
}

export default styled('button')<DropdownButtonProps>`
	background: ${({ theme }) => theme.colors.white};
	padding: 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	text-align: left;
	padding: ${({ theme }) => theme.grid.gutterInPx(2)};
	font-family: ${({ theme }) => theme.fonts.secondary};
	font-size: 16px;
	line-height: 24px;
	font-weight: 600;
	color: ${({ isOpen, theme }) =>
		isOpen ? theme.colors.dropdownButtonTextOpen : theme.colors.dropdownButtonTextClosed};
	cursor: pointer;

	&::-moz-focus-inner {
		border: none;
	}

	${({ isOpen, theme }) => {
		if (isOpen) {
			return css`
				border: 1px solid ${theme.colors.buttonPrimary};
				border-radius: 4px 4px 0 0;
				border-bottom-color: transparent;
			`;
		}

		return css`
			border: 1px solid ${theme.colors.border};
			border-radius: 4px;
		`;
	}};

	&:focus,
	&:active {
		background: ${({ theme }) => theme.colors.white};
		border-color: ${({ theme }) => theme.colors.buttonPrimary};
		border-bottom-color: ${({ isOpen, theme }) => (isOpen ? 'transparent' : theme.colors.buttonPrimary)};
		outline: none;
	}

	&:disabled {
		background: ${({ theme }) => theme.colors.border};
		border-color: ${({ theme }) => theme.colors.border};
		color: ${({ theme }) => theme.colors.inputDisabledText};
		cursor: default;

		svg path {
			fill: ${({ theme }) => theme.colors.inputDisabledText};
		}
	}

	svg {
		width: 24px;
		height: 24px;
		flex: 0 0 24px;
		margin-left: ${({ theme }) => theme.grid.gutterInPx(2)};

		${props => {
			if (props.isOpen) {
				return css`
					transform: rotateX(180deg);
				`;
			}
			return null;
		}} path {
			fill: ${({ theme }) => theme.colors.buttonPrimary};
		}
	}
`;
