import * as React from 'react';
import styled from 'styled-components';
import { OutlinedButtonProps } from '../../../interfaces';
import { baseButtonCss, baseOutlinedButtonCss } from '../common';

export const StyledOutlinedButton = <T extends 'button' | 'a'>(tagName: T) => styled<T>(tagName)<
	OutlinedButtonProps &
		(T extends 'button' ? React.ButtonHTMLAttributes<HTMLButtonElement> : React.AnchorHTMLAttributes<HTMLAnchorElement>)
>`
	${baseButtonCss};
	${baseOutlinedButtonCss};
`;
