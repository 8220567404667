import { DisplayType, ToggleType } from '../interfaces';
import { MediaBreakpoints } from 'styleguide/helpers';
import { toggleMedia } from '../helpers';
import styled from 'styled-components';

interface ToggleMediaProps {
	displayType: DisplayType;
	toggleType: ToggleType;
	breakpoint: keyof MediaBreakpoints;
}

export default styled.div<ToggleMediaProps>`
	${({ displayType, toggleType, breakpoint }) => toggleMedia(displayType, toggleType, breakpoint)};
`;
