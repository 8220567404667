import * as React from 'react';
import NormalIconWrapper, { NormalIconWrapperProps } from '../NormalIconWrapper';

const CheckIcon: React.FC<NormalIconWrapperProps & React.SVGAttributes<SVGElement>> = ({ color, ...props }) => {
	return (
		<NormalIconWrapper {...props}>
			<path
				d="M21.831 12.152a.441.441 0 0 1 .883 0v.854c-.003 5.364-4.354 9.71-9.72 9.708-5.364-.003-9.71-4.354-9.708-9.719.003-5.365 4.355-9.712 9.72-9.71 1.36.002 2.705.288 3.948.842a.441.441 0 1 1-.36.806 8.832 8.832 0 1 0 5.237 8.072v-.853z"
				fill="#00833F"
			/>
			<path
				d="M13 14.245l8.96-8.97a.441.441 0 0 1 .625.624l-9.273 9.282a.441.441 0 0 1-.624 0L9.906 12.4a.441.441 0 1 1 .624-.624l2.47 2.47z"
				fill="#00833F"
			/>
		</NormalIconWrapper>
	);
};

export default CheckIcon;
