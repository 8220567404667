import * as React from 'react';
import SearchCategory from './SearchCategory';
import SearchResultList from './SearchResultList';
import styled from 'styled-components';
import { NavLinkItemProps } from '../../interfaces';
import { SearchType } from 'pagetypes/Search/types';

const SearchResultGroupWrapper = styled.div`
	&:not(:first-child) {
		margin-top: 10px;
	}
`;

export interface SearchResultGroupProps {
	href: string;
	title: string;
	items: NavLinkItemProps[];
	hideSearchCategory?: boolean;
	searchType: SearchType;
	categoryClicked?: () => void;
	itemClicked?: (item: NavLinkItemProps, searchType?: SearchType) => void;
}

const SearchResultGroup: React.FC<SearchResultGroupProps> = ({
	searchType,
	href,
	title,
	items,
	hideSearchCategory,
	itemClicked,
	categoryClicked,
}) => (
	<SearchResultGroupWrapper>
		{!hideSearchCategory && (
			<SearchCategory categoryClicked={categoryClicked} searchType={searchType} href={href} title={title} />
		)}
		<SearchResultList items={items} itemClicked={itemClicked} searchType={searchType} />
	</SearchResultGroupWrapper>
);

export default SearchResultGroup;
