import * as React from 'react';
import { generate } from 'shortid';
import styled from 'styled-components';
import ValidationError from '../ValidationError';

const CheckboxInput = styled.input`
	position: absolute;
	left: 2px;
	top: 2px;

	&:focus {
		outline: none;
		box-shadow: none;

		& + label:before {
			border-color: ${({ theme }) => theme.colors.brandPrimary};
		}
	}

	&:checked + label:after {
		content: '';
	}

	&:disabled + label {
		color: ${({ theme }) => theme.colors.inputDisabledText};

		&:before {
			background: ${({ theme }) => theme.colors.inputDisabledBackground};
		}
		&:after {
			border-color: ${({ theme }) => theme.colors.border};
		}
	}
`;

const CheckboxLabel = styled('label')<CheckboxWrapperProps>`
	font-family: ${({ theme }) => theme.fonts.secondary};
	font-size: 18px;
	color: ${({ theme }) => theme.colors.bodyText};
	padding-left: 36px;
	position: relative;
	display: block;
	line-height: 24px;

	&:focus:before {
		border-color: ${({ theme }) => theme.colors.brandPrimary};
	}

	&:before {
		content: '';
		background: ${({ theme, isInvalid }) => (isInvalid ? theme.colors.actionErrorBackground : theme.colors.white)};
		border: 1px solid ${({ theme, isInvalid }) => (isInvalid ? theme.colors.actionErrorText : theme.colors.border)};
		position: absolute;
		top: 0;
		left: 0;
		width: 22px;
		height: 22px;
		border-radius: 4px;
	}

	&:after {
		content: none;
		display: inline-block;
		height: 6px;
		width: 11px;
		border-left: 2px solid ${({ theme }) => theme.colors.brandPrimary};
		border-bottom: 2px solid ${({ theme }) => theme.colors.brandPrimary};
		transform: rotate(-45deg);
		position: absolute;
		left: 7px;
		top: 7px;
	}
`;

export interface CheckboxWrapperProps {
	isInvalid?: boolean;
}

const CheckboxWrapper = styled('div')<CheckboxWrapperProps>`
	position: relative;

	/* Icon */
	div {
		background: ${({ theme, isInvalid }) => (isInvalid ? theme.colors.actionErrorBackground : theme.colors.white)};
		position: absolute;
		left: 1px;
		top: 1px;
		opacity: 0;
		width: 22px;
		height: 22px;
		border-radius: 4px;

		svg {
			width: 12px;
		}
	}
`;

export interface CheckboxProps extends CheckboxWrapperProps {
	name: string;
	validationError?: string;
}

type Props = CheckboxProps & React.InputHTMLAttributes<HTMLInputElement>;

const Checkbox = React.forwardRef<HTMLInputElement, Props>(
	({ children, name, id, className, validationError, isInvalid, ...rest }, ref: React.RefObject<HTMLInputElement>) => {
		const checkboxId = id || name || generate();

		return (
			<div className={`form-field${className ? ` ${className}` : ''}`}>
				<CheckboxWrapper isInvalid={isInvalid}>
					<CheckboxInput type="checkbox" name={name} id={checkboxId} ref={ref} {...rest} />
					<CheckboxLabel htmlFor={checkboxId} isInvalid={isInvalid} className="checkbox-label">
						{children}
					</CheckboxLabel>
				</CheckboxWrapper>
				{validationError && <ValidationError htmlFor={checkboxId}>{validationError}</ValidationError>}
			</div>
		);
	}
);

export default Checkbox;
