import { createCustomAction, createStandardAction, ActionType } from 'typesafe-actions';
import { UserApiShape, UserRecipe } from '../interfaces';
import { ShoppingListApiResponse, ShoppingListApiItem } from '../services/shopping-list';
import { SendUserRecipesApiResponse, UserRecipesApiRecipes } from '../services/cookbook';
import { ProfileApiShape, ChangePasswordAMShape } from '../services/profile';
import { SortDirection } from 'common/components/SortOrder/SortOrderSelect';
import { ExceptionDetails } from 'common/interfaces/exception';

export const userActions = {
	fetchCurrentUser: createStandardAction('USER/FETCH_CURRENT_USER')(),

	setCurrentUser: createStandardAction('USER/SET_CURRENT_USER')<UserApiShape>(),

	fetchCurrentUserError: createStandardAction('USER/FETCH_CURRENT_USER_ERROR')(),

	updateCurrentUserItemsInMemory: createStandardAction('USER/UPDATE_CURRENT_USER_ITEMS_IN_MEMORY')<UserApiShape>(),

	fetchShoppingList: createStandardAction('USER/FETCH_SHOPPING_LIST')(),

	setShoppingList: createStandardAction('USER/SET_SHOPPING_LIST')<ShoppingListApiResponse>(),

	fetchShoppingListError: createStandardAction('USER/FETCH_SHOPPING_LIST_ERROR')<string>(),

	fetchUserRecipes: createStandardAction('USERRECIPES/COOKBOOK/FETCH')(),

	fetchUserRecipesSucceeded: createCustomAction(
		'USERRECIPES/COOKBOOK/FETCH/SUCCESS',
		type => (recipes: UserRecipesApiRecipes[]) => ({
			type,
			payload: { recipes },
		})
	),

	fetchUserRecipesError: createCustomAction('USERRECIPES/COOKBOOK/FETCH/FAILURE', type => (error: string) => ({
		type,
		payload: { error },
	})),

	sendUserRecipes: createStandardAction('USERRECIPES/COOKBOOK/SEND')(),

	sendUserRecipesSucceeded: createCustomAction(
		'USERRECIPES/COOKBOOK/SEND/SUCCESS',
		type => (response: SendUserRecipesApiResponse) => ({
			type,
			payload: { response },
		})
	),

	sendUserRecipesError: createCustomAction('USERRECIPES/COOKBOOK/SEND/FAILURE', type => (error: string) => ({
		type,
		payload: { error },
	})),

	addRecipe: createCustomAction('USER/COOKBOOK/ADD_RECIPE_REQUEST', type => (recipeId: string) => ({
		type,
		payload: { recipeId },
	})),

	addRecipeSucceeded: createStandardAction('USER/COOKBOOK/ADD_RECIPE_SUCCESS')(),

	addRecipeFailed: createCustomAction('USER/COOKBOOK/ADD_RECIPE_FAILURE', type => (error: string) => ({
		type,
		payload: { error },
	})),

	removeRecipe: createCustomAction('USER/COOKBOOK/REMOVE_RECIPE_REQUEST', type => (recipeId: string) => ({
		type,
		payload: { recipeId },
	})),

	removeRecipeSucceeded: createCustomAction('USER/COOKBOOK/REMOVE_RECIPE_SUCCESS', type => (recipeId: string) => ({
		type,
		payload: { recipeId },
	})),

	removeRecipeFailed: createCustomAction('USER/COOKBOOK/REMOVE_RECIPE_FAILURE', type => (error: string) => ({
		type,
		payload: { error },
	})),

	deleteShoppingListItem: createStandardAction('USER/DELETE_SHOPPING_LIST_ITEM')<string>(),

	deleteShoppingListItemSucceeded: createStandardAction('USER/DELETE_SHOPPING_LIST_ITEM_SUCCEEDED')(),

	deleteShoppingListItemFailed: createStandardAction('USER/DELETE_SHOPPING_LIST_ITEM_FAILED')<string>(),

	changeShoppingListRecipePortion: createCustomAction(
		'USER/CHANGE_SHOPPING_LIST_RECIPE_PORTION',
		type => (recipeId: string, quantity: number) => ({
			type,
			payload: { recipeId, quantity },
		})
	),

	changeShoppingListRecipePortionSucceeded: createCustomAction(
		'USER/CHANGE_SHOPPING_LIST_RECIPE_PORTION_SUCCEEDED',
		type => (items: ShoppingListApiItem[], recipeId: string, quantity: number) => ({
			type,
			payload: { items, recipeId, quantity },
		})
	),

	changeShoppingListRecipePortionFailed: createStandardAction('USER/CHANGE_SHOPPING_LIST_RECIPE_PORTION_FAILED')<
		string
	>(),

	addShoppingListItem: createStandardAction('USER/ADD_SHOPPING_LIST_ITEM')<string>(),

	addShoppingListItemSuccess: createStandardAction('USER/ADD_SHOPPING_LIST_ITEM_SUCCESS')<ShoppingListApiItem>(),

	addShoppingListItemFailed: createStandardAction('USER/ADD_SHOPPING_LIST_ITEM_FAILED')<string>(),

	toggleShoppingListItemPicked: createCustomAction(
		'USER/TOGGLE_SHOPPING_LIST_ITEM_PICKED',
		type => (itemId: string, isPicked: boolean) => ({
			type,
			payload: { itemId, isPicked },
		})
	),

	toggleShoppingListItemPickedSuccess: createStandardAction('USER/TOGGLE_SHOPPING_LIST_ITEM_PICKED_SUCCESS')<
		ShoppingListApiItem
	>(),

	toggleShoppingListItemPickedFailed: createStandardAction('USER/TOGGLE_SHOPPING_LIST_ITEM_PICKED_FAILED')<string>(),

	emptyShoppingList: createStandardAction('USER/EMPTY_SHOPPING_LIST')(),

	emptyShoppingListSuccess: createStandardAction('USER/EMPTY_SHOPPING_LIST_SUCCESS')(),

	emptyShoppingListFailed: createStandardAction('USER/EMPTY_SHOPPING_LIST_FAILED')<string>(),

	fetchCurrentProfile: createStandardAction('PROFILE/FETCH_CURRENT_PROFILE')(),

	fetchCurrentProfileSuccess: createStandardAction('PROFILE/FETCH_CURRENT_PROFILE_SUCCESS')<ProfileApiShape>(),

	fetchCurrentProfileError: createStandardAction('PROFILE/FETCH_CURRENT_PROFILE_ERROR')<string>(),

	updateContactInfo: createStandardAction('PROFILE/UPDATE_PROFILE')<ProfileApiShape>(),

	updateContactInfoSuccess: createStandardAction('PROFILE/UPDATE_PROFILE_SUCCESS')<ProfileApiShape>(),

	updateContactInfoError: createCustomAction(
		'PROFILE/UPDATE_PROFILE_ERROR',
		type => ({ title, details }: { title: string; details: ExceptionDetails }) => ({
			type,
			payload: { title, details },
		})
	),

	changePassword: createStandardAction('PROFILE/CHANGE_PASSWORD')<ChangePasswordAMShape>(),

	changePasswordSuccess: createStandardAction('PROFILE/CHANGE_PASSWORD_SUCCESS')(),

	changePasswordError: createCustomAction(
		'PROFILE/CHANGE_PASSWORD_ERROR',
		type => ({ title, details }: { title: string; details: ExceptionDetails }) => ({
			type,
			payload: { title, details },
		})
	),

	openEmailSharing: createStandardAction('USER/SHOPPING_LIST_SHOW_SHARING')(),

	closeEmailSharing: createStandardAction('USER/SHOPPING_LIST_HIDE_SHARING')(),

	shareShoppingListToEmail: createStandardAction('USER/SHARE_SHOPPING_LIST_EMAIL')<string>(),

	shareShoppingListToEmailSuccess: createStandardAction('USER/SHARE_SHOPPING_LIST_EMAIL_SUCCESS')(),

	shareShoppingListToEmailError: createStandardAction('USER/SHARE_SHOPPING_LIST_EMAIL_ERROR')<string>(),

	sortUserRecipes: createCustomAction(
		'USER/SORT_USER_RECIPES',
		type => (sortProperty: keyof UserRecipe, sortDirection: SortDirection) => ({
			type,
			payload: { sortProperty, sortDirection },
		})
	),
};

export type UserActions = ActionType<typeof userActions>;
