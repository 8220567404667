import * as React from 'react';
import Observer, { RenderProps } from 'react-intersection-observer';
import Image from '../components/PicturesAndVideos/Image';
import { ImageProps } from '../interfaces/imgInterfaces';
import { Omit } from '../interfaces/util';
import { lazyImages } from './LazyImageRegistry';

export type LazyImageProps = ImageProps & Omit<React.ImgHTMLAttributes<HTMLImageElement>, 'src'>;

interface Props {
	render?: (props: LazyImageProps) => React.ReactElement<any>;
}

interface LazyImageState {
	forceRender?: boolean;
}

export class LazyImage extends React.Component<Props & LazyImageProps, LazyImageState> {
	constructor(props: LazyImageProps) {
		super(props);
		this.state = {};
	}

	public render() {
		const { children, render, ...rest } = this.props;
		// Server-side render
		if (!this.windowAvailable()) {
			return null;
		}

		if (this.state.forceRender) {
			if (render) {
				return render(rest);
			}

			if (children) {
				return children;
			}

			return <Image {...this.props} />;
		}

		return <Observer triggerOnce={true} render={this.renderImage} />;
	}

	public componentDidMount() {
		lazyImages.add(this);
	}

	public componentWillUnmount() {
		lazyImages.remove(this);
	}

	private windowAvailable() {
		return typeof window !== 'undefined';
	}

	private renderImage = ({ inView, ref }: RenderProps) => {
		const typedRef = (ref as any) as React.RefObject<any>;
		const { children, render, ...rest } = this.props;
		const style: React.CSSProperties = { display: 'inline-block', minWidth: '1px', minHeight: '1px' };

		if (!inView) {
			return <span className="lazy-sentinel" ref={typedRef} style={style} />;
		}

		if (render) {
			return render(rest);
		}

		if (children) {
			return children;
		}

		return <Image {...this.props} />;
	};
}
