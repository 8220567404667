import * as React from 'react';
import NormalIconWrapper, { NormalIconWrapperProps } from '../../NormalIcons/NormalIconWrapper';

const StoreFiSGroupIcon: React.FC<NormalIconWrapperProps & React.SVGAttributes<SVGElement>> = ({ color, ...props }) => {
	const fillColor = color ?? 'white';

	return (
		<NormalIconWrapper {...props}>
			<path
				fill={fillColor}
				d="M11.6646 4.09859C13.248 2.51519 15.2916 1.82099 17.46 1.82099H19.0629V0.299988H17.4522C14.8743 0.299988 12.4563 1.15019 10.5882 3.02219C8.70843 4.90199 7.86603 7.30439 7.86603 9.93299V13.872C7.86603 14.0163 7.85433 14.1996 7.83093 14.3244C7.77633 14.6169 7.65543 14.8587 7.46043 15.0537C7.28493 15.2292 7.07043 15.3306 6.90663 15.3891C6.76623 15.4398 6.58683 15.4788 6.39573 15.5061C5.97063 15.5685 4.93713 15.5919 4.93713 15.5919V20.3187H6.33723C8.12343 20.3187 9.76143 19.7766 10.9899 18.552C12.2145 17.3274 12.7566 15.7596 12.7566 14.0709V9.93299C12.7566 8.65769 13.1622 7.46429 14.0982 6.52829C15.0381 5.58839 16.2354 5.18279 17.4873 5.18279H17.7057V7.00019C17.2767 7.02749 16.9764 7.07039 16.5903 7.19129C16.1808 7.31999 15.7869 7.53839 15.4476 7.87379C14.8743 8.44319 14.6364 9.17249 14.6364 9.92909V14.067C14.6364 16.2783 13.9305 18.3102 12.3393 19.8975C10.7559 21.4809 8.71233 22.1751 6.54393 22.1751H4.94103V23.7H6.55563C9.13353 23.7 11.5515 22.8498 13.4196 20.9778C15.2916 19.098 16.134 16.6956 16.134 14.067V10.128C16.134 9.98369 16.1457 9.80039 16.1691 9.67559C16.2237 9.38309 16.3446 9.14129 16.5396 8.94629C16.7151 8.77079 16.9296 8.66939 17.0934 8.61089C17.2338 8.56019 17.4132 8.52119 17.6043 8.49389C18.0294 8.43149 19.0629 8.40809 19.0629 8.40809V3.68129H17.6628C15.8766 3.68129 14.2386 4.22339 13.0101 5.44799C11.7816 6.67259 11.2434 8.24039 11.2434 9.92909V14.067C11.2434 15.3423 10.8378 16.5357 9.90183 17.4717C8.96193 18.4077 7.76463 18.8172 6.51273 18.8172H6.29433V16.9608C6.72333 16.9335 7.04703 16.8789 7.40973 16.7697C7.82313 16.6449 8.21313 16.4616 8.55243 16.1223C9.12573 15.5529 9.36363 14.8236 9.36363 14.067V9.92909C9.37143 7.72169 10.0773 5.68979 11.6646 4.09859"
			/>
		</NormalIconWrapper>
	);
};

export default StoreFiSGroupIcon;
