import { ResourceType } from 'common/components/Resource/types';
import { Reducer } from 'redux';
import { ResourceActions, resourceActions } from 'common/components/Resource/reducers/actions';
import { getType } from 'typesafe-actions';
import { EventContentState } from './interfaces';

const eventContentInitialState: EventContentState = {
	events: [],
	contentFamily: '',
};

export const eventContentReducer: Reducer<EventContentState, ResourceActions> = (
	state = eventContentInitialState,
	action
): EventContentState => {
	switch (action.type) {
		case getType(resourceActions.setResource):
			if (action.payload.resource.type === ResourceType.event) {
				const {
					events,
					contentFamily,
					registrationButtonLink,
					registrationButtonText,
				} = action.payload.resource.content;
				return {
					...state,
					events,
					contentFamily,
					registrationButtonText,
					registrationButtonLink,
				};
			}
		default:
			return state;
	}
};
