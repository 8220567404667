import * as React from 'react';
import SocialIconWrapper from '../SocialIconWrapper';
import { IconWrapperProps } from '../../../../interfaces';

const PinterestIcon: React.FC<IconWrapperProps & React.SVGAttributes<SVGElement>> = ({ color, ...props }) => {
	return (
		<SocialIconWrapper alt="Pinterest" {...props}>
			<path
				fill={color}
				d="M14.926 6.467c-4.734 0-8.667 3.866-8.667 8.533a8.6 8.6 0 1 0 8.667-8.533m0 18.533a10 10 0 0 1 0-20c5.523 0 10 4.477 10 10s-4.477 10-10 10"
			/>
			<path
				fill={color}
				d="M14.26 17.733l-.268.8-.466 1.667c-.267.667-.667 1.267-1.067 1.8 0 0-.133.067-.133 0a.2.2 0 0 1-.067-.067c-.133-1-.133-2 .067-3l.866-3.466a.467.467 0 0 0 0-.2 2.667 2.667 0 0 1 0-2.134c.267-.466.667-.733 1.134-.733.533 0 .866.333 1 .8a74.666 74.666 0 0 1-.6 2.667c-.267.866.4 1.666 1.333 1.466.467 0 .8-.333 1.133-.666a4 4 0 0 0 .8-1.8c.067-.6.134-1.134.067-1.734-.133-1.133-.867-1.933-2-2.2a3.6 3.6 0 0 0-3.133.534 3.267 3.267 0 0 0-1.334 3.333c.067.333.267.667.467.867.067.133.133.266.067.4l-.134.533c0 .2-.2.267-.4.2-.533-.267-.933-.667-1.133-1.267a4 4 0 0 1 .467-3.866 4.733 4.733 0 0 1 3.2-2 5.333 5.333 0 0 1 3 .266 4.067 4.067 0 0 1 2.6 4.667c-.1.887-.42 1.736-.934 2.467-.533.8-1.333 1.333-2.4 1.533-.666.067-1.4 0-2-.533l-.133-.4"
			/>
		</SocialIconWrapper>
	);
};

PinterestIcon.defaultProps = {
	color: '#e60023',
};

export default PinterestIcon;
