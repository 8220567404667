import * as React from 'react';
import * as iconSm from '../../../../images/globe-icon.svg';
import * as iconLg from '../../../../images/globe-icon-lg.svg';
import FilteredIconImage from '../FilteredIconImage';
import { IconUsageProps } from 'styleguide/interfaces/iconInterfaces';

const Icon: React.FC<IconUsageProps & React.HTMLAttributes<HTMLImageElement>> = ({ useHDVariant, ...rest }) => (
	<FilteredIconImage icon={useHDVariant ? iconLg : iconSm} {...rest} />
);

export default Icon;
