import * as React from 'react';
import styled from 'styled-components';
import { media, mediaBreakpoints } from '../../../helpers';
import { HighlightCardWrapperProps } from './HighlightCardWrapper';

const ContentWrapper = styled('div')<HighlightCardWrapperProps>`
	flex: 1 1 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 15px 13px 13px;
	text-align: left;

	@media (max-width: ${mediaBreakpoints.phone320}px) {
		padding: 6px 13px 6px;
		> span {
			font-size: 12px;
		}
	}

	${media.tablet<HighlightCardWrapperProps>`
		${props => (props.isSmall ? '' : 'padding: 25px 24px 12px;')}
	`};

	${media.desktop<HighlightCardWrapperProps>`
		${props => (props.isSmall ? '' : 'padding: 30px 24px 17px;')}
	`};
`;

const Title = styled('span')<HighlightCardWrapperProps>`
	display: block;
	font-family: ${props => props.theme.fonts.secondary};
	font-size: 15px;
	font-weight: 600;
	line-height: 1;
	color: ${props => props.theme.colors.brandPrimary};
	text-decoration: none;

	${media.tablet<HighlightCardWrapperProps>`
		${props => (props.isSmall ? '' : 'font-size: 18px;')}
	`};
`;

export interface HighlightCardContentProps extends HighlightCardWrapperProps {
	title?: string;
}

const HighlightCardContent: React.FC<HighlightCardContentProps & React.HTMLAttributes<HTMLDivElement>> = ({
	title,
	isSmall,
	className,
	children,
}) => {
	return (
		<ContentWrapper className={className} isSmall={isSmall}>
			<Title isSmall={isSmall}>{title}</Title>
			{children}
		</ContentWrapper>
	);
};

export default HighlightCardContent;
