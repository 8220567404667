import find from 'lodash/find';
export interface Config {
	apiBaseUrl: string;
	authHeader: { Authorization: string } | {};
	isDevelopment: boolean;
	environment: string | undefined;
}

declare global {
	interface Window {
		siteBaseUrl: string;
		instrumentationKey: string;
		reCaptchaSiteKey: string;
		serverRendered?: boolean;
		messages?: object;
	}
}

let config: Config;
export function getConfig(): Config {
	return config;
}

const knownDevEnvironments = ['dev.valio.fi', 'qa.valio.fi'];
function isDevEnvironmentUrl(url: string): boolean {
	const withoutProto = url.replace(new RegExp('https?://'), '');

	return !!find(knownDevEnvironments, s => withoutProto.indexOf(s) >= 0);
}

export function generateConfig(presetApiBaseUrl?: string, presetPublicUrl?: string): void {
	const env = process.env;

	const isDevBuild = env.NODE_ENV === 'development';

	const apiBaseUrl = presetApiBaseUrl || env.REACT_APP_API_BASE_URL || (isDevBuild ? 'https://dev.valio.fi' : '');

	const authHeader = isDevEnvironmentUrl(apiBaseUrl)
		? { Authorization: 'Basic dmFsaW9kZW1vOnV1ZGV0dHVvdHRlZXQyMDIxIQ==' }
		: {};

	const environment = env.REACT_APP_ENVIRONMENT;

	const isDevelopment = isDevBuild;

	config = {
		apiBaseUrl,
		authHeader,
		isDevelopment,
		environment,
	};
}

generateConfig();
