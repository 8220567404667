import * as React from 'react';
import { SupportedNewsletters } from '../interfaces';
import config from '../config';
import styled, { css } from 'styled-components';
import { media } from 'styleguide/helpers';
import { FormattedMessage } from 'react-intl';
import { Button } from 'styleguide/components/Buttons/Button';
import includes from 'lodash/includes';

interface CardProps {
	isSelected: boolean;
	numberOfCards: number;
}

const Card = styled.li<CardProps>`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	box-shadow: 0 6px 10px -8px ${({ theme }) => theme.colors.shadowBranded};
	margin: ${({ theme }) => theme.grid.gutterInPx(4)} 0;
	text-align: left;

	${media.tablet<CardProps>`
		flex: 0 0 auto;
		margin: ${({ theme }) => theme.grid.gutterInPx(2)};
		${props => {
			if (props.numberOfCards === 1) {
				return css`
					width: calc(100% - ${({ theme }) => theme.grid.gutterInPx(4)});
				`;
			}
			return css`
				width: calc(50% - ${({ theme }) => theme.grid.gutterInPx(4)});
			`;
		}};
	`};

	${media.desktop1200<CardProps>`
		margin: ${({ theme }) => theme.grid.gutterInPx(4)};
		${props => {
			if (props.numberOfCards === 1) {
				return css`
					width: calc(100% - ${({ theme }) => theme.grid.gutterInPx(8)});
				`;
			}
			if (props.numberOfCards === 2) {
				return css`
					width: calc(50% - ${({ theme }) => theme.grid.gutterInPx(8)});
				`;
			}
			return css`
				width: calc(100% / 3 - ${({ theme }) => theme.grid.gutterInPx(8)});
			`;
		}};

	`};

	.form-field {
		flex: 0 0 auto;
	}

	input:focus + label:before {
		border-color: ${({ theme }) => theme.colors.white};
	}

	.checkbox-label {
		${({ theme }) => theme.typography.heading};
		background: ${({ theme, isSelected }) =>
			isSelected ? theme.colors.shoppingListGreen : theme.colors.buttonPrimary};
		border: 1px solid ${({ theme }) => theme.colors.buttonPrimary};
		border-radius: 0 0 10px 10px;
		padding: ${({ theme }) => theme.grid.gutterInPx(3)} ${({ theme }) => theme.grid.gutterInPx(3)}
			${({ theme }) => theme.grid.gutterInPx(3)} 58px;
		font-size: 13px;
		color: ${({ theme }) => theme.colors.white};
		cursor: pointer;
		text-align: left;

		&:hover {
			background: ${({ theme, isSelected }) =>
				isSelected ? theme.colors.shoppingListGreen : theme.colors.buttonPrimaryHover};
		}

		&::before {
			border-width: 2px;
			border-color: ${({ theme }) => theme.colors.white};
			background: ${({ isSelected, theme }) => (isSelected ? theme.colors.white : 'transparent')};
			top: 23px;
			left: 24px;
		}

		&::after {
			border-left-color: ${({ theme }) => theme.colors.shoppingListGreen};
			border-bottom-color: ${({ theme }) => theme.colors.shoppingListGreen};
			left: 31px;
			top: 30px;
		}
	}
`;

export const CardHeader = styled.div`
	flex: 1 0 auto;
	background: ${({ theme }) => theme.colors.white};
	border: 1px solid ${({ theme }) => theme.colors.border};
	border-bottom: none;
	border-radius: 10px 10px 0 0;
	padding: ${({ theme }) => theme.grid.gutterInPx(3)};
`;

export const CardTitle = styled.h3`
	${({ theme }) => theme.typography.heading};
	font-size: 18px;
	color: ${({ theme }) => theme.colors.brandPrimary};
	margin: 0 0 ${({ theme }) => theme.grid.gutterInPx(2)};
	display: flex;
	justify-content: space-between;
	align-items: center;

	& > img {
		width: 64px;
		height: 64px;
		flex: 0 0 64px;
		margin: ${({ theme }) => theme.grid.gutterInPx(-1.5)} 0;
	}
`;

const CardDescription = styled.p``;

const OrderButton = styled(Button)`
	border-radius: 0 0 10px 10px;
`;

interface NewsletterCardProps {
	widgetId: string;
	type: SupportedNewsletters;
	chosenNewsletters: SupportedNewsletters[];
	numberOfCards: number;
	onSelect: (type: SupportedNewsletters, checked: boolean) => void;
}

const NewsletterCard: React.FC<NewsletterCardProps> = ({
	widgetId,
	type,
	chosenNewsletters,
	numberOfCards,
	onSelect,
}) => {
	if (!config[type]) {
		return null;
	}

	const { title, description, icon: newsletterIcon } = config[type];

	const isSelected = includes(chosenNewsletters, type);

	const onClick = () => {
		onSelect(type, true);
	};

	return (
		<Card numberOfCards={numberOfCards} isSelected={isSelected}>
			<CardHeader>
				<CardTitle>
					<FormattedMessage id={title} />
					{newsletterIcon}
				</CardTitle>
				<CardDescription>
					<FormattedMessage id={description} />
				</CardDescription>
			</CardHeader>
			<OrderButton onClick={onClick}>
				<FormattedMessage id="global_order" />
			</OrderButton>
		</Card>
	);
};

export default NewsletterCard;
