import * as React from 'react';
import * as ReactDOM from 'react-dom';
import styled from 'styled-components';
import IconButton from '../../../components/Buttons/IconButton/IconButton';
import { CloseIcon } from '../../Icons';
import { media, withWindow } from '../../../helpers';
import MediumHeader from 'styleguide/components/Typography/MediumHeader';

const ModalBackground = styled.div`
	display: none;
	background: ${props => props.theme.colors.modalBackground};
	position: fixed;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	z-index: ${props => props.theme.zIndices.modal};

	${media.tablet`
		display: block;
	`};
`;

interface ModalWrapperProps {
	desktopWidth?: string;
}

const ModalWrapper = styled('article')<ModalWrapperProps>`
	background: ${props => props.theme.colors.white};
	position: fixed;
	box-sizing: border-box;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: ${props => props.theme.zIndices.modal + 1};
	width: 100%;
	max-width: 100%;
	height: 100%;
	max-height: 100%;
	padding-top: 50px;

	${media.tablet`
		width: 80%;		
		min-height: 200px;
		border-radius: 3px;
		box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.3);
	`};

	${media.desktop<ModalWrapperProps>`
		width: ${props => (props.desktopWidth ? props.desktopWidth : '760px')};		
    height: auto;
	`};
`;

const ModalContent = styled.div`
	padding: 0 10px 20px;
	width: 100%;
	height: 100%;
	overflow: auto;

	${media.phone`
		padding: 0 20px 20px;
	`};

	${media.tablet`
		padding: 0 50px 50px;		
	`};
`;

const ModalClose = styled.div`
	position: absolute;
	right: 10px;
	top: 10px;
	cursor: pointer;
`;

const ModalTitle = styled(MediumHeader)`
	position: absolute;
	left: 20px;
	top: 20px;
	margin: 0;

	${media.tablet`
		left: 50px;		
	`};
`;

export interface ModalProps extends ModalWrapperProps {
	disableCloseOnEsc?: boolean;
	disableCloseOnOverlayClick?: boolean;
	modalTitle?: React.ReactNode;
	onClose?: () => void;
}

class Modal extends React.Component<ModalProps & React.HTMLAttributes<HTMLDivElement>> {
	private el: HTMLDivElement;

	constructor(props: ModalProps) {
		super(props);
		this.el = document.createElement('div');
	}

	public componentDidMount() {
		document.body.appendChild(this.el);
		document.body.style.overflow = 'hidden';
		withWindow(w => {
			w.addEventListener('keydown', this.onKeyDown);
		});
	}

	public componentWillUnmount() {
		withWindow(w => {
			w.removeEventListener('keydown', this.onKeyDown);
		});
		document.body.style.overflow = '';
		document.body.removeChild(this.el);
	}

	public render() {
		const { desktopWidth, className, modalTitle } = this.props;
		return ReactDOM.createPortal(
			<>
				<ModalBackground onClick={this.onOverlayClick} />
				<ModalWrapper className={className} desktopWidth={desktopWidth}>
					<ModalContent className="modal-content">{this.props.children}</ModalContent>
					{modalTitle && <ModalTitle tagName="h3">{modalTitle}</ModalTitle>}
					<ModalClose>
						<IconButton onClick={this.onCloseClick} icon={<CloseIcon />} />
					</ModalClose>
				</ModalWrapper>
			</>,
			this.el
		);
	}

	private onCloseClick = (event: React.MouseEvent<HTMLElement> | React.TouchEvent<HTMLElement>) => {
		this.close();
	};

	private onOverlayClick = (event: React.MouseEvent<HTMLElement> | React.TouchEvent<HTMLElement>) => {
		if (!this.props.disableCloseOnOverlayClick) {
			this.close();
		}
	};

	private onKeyDown = (event: KeyboardEvent) => {
		if (!this.props.disableCloseOnEsc) {
			if (event.keyCode === 27) {
				this.close();
			}
		}
	};

	private close() {
		const { onClose } = this.props;
		if (onClose) {
			onClose();
		}
	}
}

export default Modal;
