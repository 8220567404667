export const RECIPE_DIFFICULTY_MAP = {
	baking: {
		1: 'recipe_difficulty_baking_easy',
		2: 'recipe_difficulty_baking_medium',
		3: 'recipe_difficulty_baking_difficult',
	},
	cooking: {
		1: 'recipe_difficulty_cooking_easy',
		2: 'recipe_difficulty_cooking_medium',
		3: 'recipe_difficulty_cooking_difficult',
	},
};
