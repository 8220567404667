import * as React from 'react';
import OrderFooter from './OrderFooter';
import { useForm } from 'react-hook-form/dist/react-hook-form.ie11';
import { useIntl, FormattedMessage } from 'react-intl';
import { OrderStatus, CartItem, OrderFormShape } from '../interfaces';
import MediumHeader from 'styleguide/components/Typography/MediumHeader';
import { RecyclebinIcon } from 'styleguide/components/Icons';
import styled from 'styled-components';
import { media } from 'styleguide/helpers';
import Input from 'styleguide/components/Inputs/Input';

const Form = styled.form`
	width: 100%;

	.medium-header {
		margin: 0;
		text-align: left;
	}
`;

export const Cart = styled.ul`
	width: 100%;
	margin: ${({ theme }) => theme.grid.gutterInPx(3)} 0;
	padding: 0;
	list-style: none;
`;

export const Item = styled.li`
	display: flex;
	justify-content: space-between;
	padding: ${({ theme }) => theme.grid.gutterInPx(2)} ${({ theme }) => theme.grid.gutterInPx(3)};
	flex-wrap: wrap;
	border-radius: 2px;

	${media.tablet`
		flex-wrap: nowrap;
	`};

	&:nth-child(odd) {
		background: ${({ theme }) => theme.colors.border};
	}

	.form-field {
		flex: 0 0 100%;
		order: 3;
		margin-top: ${({ theme }) => theme.grid.gutterInPx()};

		${media.tablet`
			flex: 0 0 auto;
			margin: 0 ${({ theme }) => theme.grid.gutterInPx(6)};
		`};

		input {
			width: ${({ theme }) => theme.grid.gutterInPx(11)};
		}
	}
`;

export const ItemLink = styled.a`
	font-size: 16px;
	line-height: 22px;
	flex: 1 1 auto;
	flex: 1 1 calc(100% - ${({ theme }) => theme.grid.gutter + 22}px);
	width: calc(100% - ${({ theme }) => theme.grid.gutter + 22}px);
	order: 1;
	color: ${({ theme }) => theme.colors.linkText};
	text-decoration: none;

	${media.tablet`
		flex: 1 1 auto;
		width: auto;
		margin-top: ${({ theme }) => theme.grid.gutterInPx(0.5)};
		font-size: 20px;
		line-height: 28px;
	`};

	&:hover {
		color: ${({ theme }) => theme.colors.linkTextAlternative};
	}
`;

const ItemDetail = styled.span`
	display: block;
	line-height: 1;
	font-size: 13px;
	color: ${({ theme }) => theme.colors.bodyText};
`;

const DeleteButton = styled.button`
	background: transparent;
	border: none;
	padding: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 22px;
	height: 22px;
	cursor: pointer;
	flex: 0 0 22px;
	margin-left: ${({ theme }) => theme.grid.gutterInPx()};
	order: 2;

	${media.tablet`
		width: 40px;
		height: 40px;
		flex: 0 0 40px;
		margin-left: 0;
		order: 3;
	`};

	&:hover {
		path {
			fill: ${({ theme }) => theme.colors.buttonPrimaryHover};
		}
	}

	svg {
		width: 18px;
		height: 18px;
	}
`;

interface Props {
	items: CartItem[];
	onUpdateCartItemQuantity: (id: string, value: number) => void;
	onRemoveFromCart: (id: string) => void;
	onSetOrderStatus: (status: OrderStatus) => void;
}

const OrderCart: React.FC<Props> = ({ items, onUpdateCartItemQuantity, onRemoveFromCart, onSetOrderStatus }) => {
	const intl = useIntl();

	const { register, errors, handleSubmit, triggerValidation } = useForm<OrderFormShape>();

	const onSubmit = () => {
		onSetOrderStatus('DELIVERY');
	};

	const onBackClick = () => {
		onSetOrderStatus('SEARCH');
	};

	const onBlur = async (event: React.FormEvent<HTMLInputElement>) => {
		const { id, value } = event.currentTarget;
		const isValid = await triggerValidation(id);

		if (isValid) {
			const currentQuantity = items.find(item => item.id === id)?.quantity;
			if (currentQuantity?.toString() !== value) {
				onUpdateCartItemQuantity(id, parseInt(value, 10));
			}
		}
	};

	const renderCartItem = (item: CartItem) => {
		const { id, title, quantity, maxOrderAmount, url, fileSuffix } = item;

		const onDeleteClick = () => {
			onRemoveFromCart(id);
		};

		return (
			<Item key={`cart-item-${id}`}>
				<ItemLink href={url} download={`${title}.${fileSuffix}`}>
					{title}
					<ItemDetail>
						<FormattedMessage id="materials_search_order_quantity_max_help_text" values={{ maxOrderAmount }} />
					</ItemDetail>
				</ItemLink>
				<Input
					type="number"
					name={id}
					label={intl.formatMessage({ id: 'unit_pcs' })}
					defaultValue={quantity.toString()}
					validationError={errors[id]?.message}
					max={maxOrderAmount}
					onBlur={onBlur}
					ref={register({
						min: {
							value: 1,
							message: intl.formatMessage({ id: 'materials_search_order_quantity_min_error' }),
						},
						max: {
							value: maxOrderAmount,
							message: intl.formatMessage({ id: 'materials_search_order_quantity_max_error' }, { maxOrderAmount }),
						},
					})}
					postfixLabel
				/>
				<DeleteButton onClick={onDeleteClick}>
					<RecyclebinIcon />
				</DeleteButton>
			</Item>
		);
	};

	return (
		<Form onSubmit={handleSubmit(onSubmit)}>
			<MediumHeader className="medium-header">
				<FormattedMessage id="materials_search_cart_content" />
			</MediumHeader>
			<Cart>{items.map(item => renderCartItem(item))}</Cart>
			<OrderFooter
				backButtonLabel={intl.formatMessage({ id: 'materials_search_order_back_to_search' })}
				continueLabel={intl.formatMessage({ id: 'global_continue' })}
				onBackClick={onBackClick}
				showContinue
			/>
		</Form>
	);
};

export default OrderCart;
