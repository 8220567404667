import * as React from 'react';
import * as valioLogo from './valioLogo.png';

export interface LogoIconProps {
	disableScaling?: boolean;
	logoSize?: string;
	inverted?: boolean;
}

const LogoIcon: React.FC<LogoIconProps> = ({ disableScaling, logoSize }) => {
	return <img src={valioLogo} alt="Valio" width={disableScaling && logoSize ? logoSize : '100%'} />;
};

export default LogoIcon;
