import { call, put, fork } from 'redux-saga/effects';
import { takeLatest } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { ShoppingListApi, shoppingListApi } from '../services/shopping-list';
import { userActions } from '../reducers/actions';
import { recipeShoppingListActions } from '../../Recipe/reducers/shopping-list';
import { productShoppingListActions } from '../../Product/reducers/shopping-list';

function* addRecipeToShoppingList(
	api: ShoppingListApi,
	{ payload }: ReturnType<typeof recipeShoppingListActions.addToShoppingList>
) {
	const { recipeId, portions } = payload;
	try {
		const addResult = yield call(api.addRecipeToShoppingList, recipeId, portions);
		yield put(recipeShoppingListActions.addSucceeded(addResult.id));
	} catch (e) {
		yield put(recipeShoppingListActions.addFailed(e));
	}
}

function* addProductToShoppingList(
	api: ShoppingListApi,
	{ payload }: ReturnType<typeof productShoppingListActions.addToShoppingList>
) {
	const { productId } = payload;
	try {
		yield call(api.addProductToShoppingList, productId);
		yield put(productShoppingListActions.addSucceeded());
	} catch (e) {
		yield put(productShoppingListActions.addFailed(e));
	}
}

function* removeRecipeFromShoppingList(
	api: ShoppingListApi,
	{ payload }: ReturnType<typeof recipeShoppingListActions.removeFromShoppingList>
) {
	try {
		yield call(api.removeRecipeFromShoppingList, payload.recipeId);
		yield put(recipeShoppingListActions.removeSucceeded());
		if (payload.refreshShoppingList) {
			yield fetchShoppingList(api);
		}
	} catch (e) {
		yield put(recipeShoppingListActions.removeFailed(e));
	}
}

function* fetchShoppingList(api: ShoppingListApi) {
	try {
		const result = yield call(api.fetchShoppingList);
		yield put(userActions.setShoppingList(result));
	} catch (e) {
		yield put(userActions.fetchShoppingListError(e));
	}
}

function* deleteItemFromShoppingList(
	api: ShoppingListApi,
	{ payload }: ReturnType<typeof userActions.deleteShoppingListItem>
) {
	try {
		yield call(api.deleteItemFromShoppingList, payload);
		yield put(userActions.deleteShoppingListItemSucceeded());
		yield fetchShoppingList(api);
	} catch (e) {
		yield put(userActions.deleteShoppingListItemFailed(e));
	}
}

function* changeShoppingListRecipePortion(
	api: ShoppingListApi,
	{ payload }: ReturnType<typeof userActions.changeShoppingListRecipePortion>
) {
	try {
		const { recipeId, quantity } = payload;
		const result = yield call(api.changeShoppingListRecipePortion, recipeId, quantity);
		yield put(userActions.changeShoppingListRecipePortionSucceeded(result, recipeId, quantity));
	} catch (e) {
		yield put(userActions.changeShoppingListRecipePortionFailed(e));
	}
}

function* addShoppingListItem(api: ShoppingListApi, { payload }: ReturnType<typeof userActions.addShoppingListItem>) {
	try {
		const result = yield call(api.addItemToShoppingList, payload);
		yield put(userActions.addShoppingListItemSuccess(result));
	} catch (e) {
		yield put(userActions.addShoppingListItemFailed(e));
	}
}

function* toggleShoppingListItemPicked(
	api: ShoppingListApi,
	{ payload }: ReturnType<typeof userActions.toggleShoppingListItemPicked>
) {
	try {
		const { itemId, isPicked } = payload;
		const result = yield call(api.toggleItemPickedFromShoppingList, itemId, isPicked);
		yield put(userActions.toggleShoppingListItemPickedSuccess(result));
	} catch (e) {
		yield put(userActions.toggleShoppingListItemPickedFailed(e));
	}
}

function* emptyShoppingList(api: ShoppingListApi) {
	try {
		yield call(api.emptyShoppingList);
		yield put(userActions.emptyShoppingListSuccess());
		yield fetchShoppingList(api);
	} catch (e) {
		yield put(userActions.emptyShoppingListFailed(e));
	}
}

function* shareShoppingListToEmail(
	api: ShoppingListApi,
	{ payload }: ReturnType<typeof userActions.shareShoppingListToEmail>
) {
	try {
		const result = yield call(api.shareShoppingListToEmail, payload);
		if (result === 'OK' || result.result === 'OK') {
			yield put(userActions.shareShoppingListToEmailSuccess());
			yield userActions.closeEmailSharing();
		} else {
			yield put(userActions.shareShoppingListToEmailError('user_shopping_list_share_email_error'));
		}
	} catch (e) {
		yield put(userActions.shareShoppingListToEmailError('user_shopping_list_share_email_error'));
	}
}

function* watchAddRecipeRequests() {
	yield takeLatest(getType(recipeShoppingListActions.addToShoppingList), addRecipeToShoppingList, shoppingListApi);
}

function* watchRemoveRecipeRequests() {
	yield takeLatest(
		getType(recipeShoppingListActions.removeFromShoppingList),
		removeRecipeFromShoppingList,
		shoppingListApi
	);
}

function* watchAddProductRequests() {
	yield takeLatest(getType(productShoppingListActions.addToShoppingList), addProductToShoppingList, shoppingListApi);
}

function* watchFetchShoppingListRequests() {
	yield takeLatest(getType(userActions.fetchShoppingList), fetchShoppingList, shoppingListApi);
}

function* watchDeleteItemFromShoppingList() {
	yield takeLatest(getType(userActions.deleteShoppingListItem), deleteItemFromShoppingList, shoppingListApi);
}

function* watchChangeShoppingListRecipePortion() {
	yield takeLatest(
		getType(userActions.changeShoppingListRecipePortion),
		changeShoppingListRecipePortion,
		shoppingListApi
	);
}

function* watchAddShoppingListItem() {
	yield takeLatest(getType(userActions.addShoppingListItem), addShoppingListItem, shoppingListApi);
}

function* watchToggleShoppingListItemPicked() {
	yield takeLatest(getType(userActions.toggleShoppingListItemPicked), toggleShoppingListItemPicked, shoppingListApi);
}

function* watchEmptyShoppingList() {
	yield takeLatest(getType(userActions.emptyShoppingList), emptyShoppingList, shoppingListApi);
}

function* watchShareShoppingListToEmail() {
	yield takeLatest(getType(userActions.shareShoppingListToEmail), shareShoppingListToEmail, shoppingListApi);
}

export function* shoppingListSaga() {
	yield fork(watchAddRecipeRequests);
	yield fork(watchRemoveRecipeRequests);
	yield fork(watchAddProductRequests);
	yield fork(watchFetchShoppingListRequests);
	yield fork(watchDeleteItemFromShoppingList);
	yield fork(watchChangeShoppingListRecipePortion);
	yield fork(watchAddShoppingListItem);
	yield fork(watchToggleShoppingListItemPicked);
	yield fork(watchEmptyShoppingList);
	yield fork(watchShareShoppingListToEmail);
}
