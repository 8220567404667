import * as React from 'react';
import SocialIconWrapper from '../SocialIconWrapper';
import { IconWrapperProps } from '../../../../interfaces';
import { ThemeContext } from 'styled-components';

const LinkIcon: React.FC<IconWrapperProps & React.SVGAttributes<SVGElement>> = ({ color, ...props }) => {
	const theme = React.useContext(ThemeContext);
	return (
		<SocialIconWrapper {...props}>
			<path
				fill={color ?? theme.colors.socialIcon}
				d="M19.12 1.556a5.311 5.311 0 0 1 7.512 0l1.812 1.812a5.312 5.312 0 0 1 0 7.512l-4.118 4.118a5.313 5.313 0 0 1-6.143.99L25.55 8.62a2.36 2.36 0 0 0 0-3.338l-.833-.833a2.36 2.36 0 0 0-3.338 0l-7.367 7.367a5.313 5.313 0 0 1 .99-6.143l4.118-4.118zM8.72 25.452l7.268-7.268a5.313 5.313 0 0 1-.99 6.142l-4.118 4.118a5.311 5.311 0 0 1-7.512 0l-1.812-1.812a5.311 5.311 0 0 1 0-7.512l4.118-4.118a5.313 5.313 0 0 1 6.143-.99l-7.269 7.269a2.36 2.36 0 0 0 0 3.338l.833.833a2.36 2.36 0 0 0 3.338 0zm12.665-14.333L11.119 21.385a1.593 1.593 0 0 1-2.253 0l-.251-.25a1.593 1.593 0 0 1 0-2.254L18.881 8.615a1.593 1.593 0 0 1 2.254 0l.25.25a1.593 1.593 0 0 1 0 2.254z"
			/>
		</SocialIconWrapper>
	);
};

export default LinkIcon;
