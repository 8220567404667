import { fetchJson } from 'utils/service';
import { WeeklyMenuContent } from './interfaces';

export async function getWeeklyMenu(siteUrlId: string, year?: number, weekNumber?: number): Promise<WeeklyMenuContent> {
	const url = year ? `api/sites/${siteUrlId}/kitchenmenu/${year}/${weekNumber}` : `api/sites/${siteUrlId}/kitchenmenu/`;
	return await fetchJson<WeeklyMenuContent>(url);
}

export const weeklyMenuApi = {
	getWeeklyMenu,
};

export type WeeklyMenuApi = typeof weeklyMenuApi;
