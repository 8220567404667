import * as React from 'react';
import { StyledOutlinedButton } from './common';
import { OutlinedButtonProps } from '../../../interfaces';

const Button = StyledOutlinedButton('button');

const OutlinedButton: React.FC<OutlinedButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>> = ({
	children,
	...rest
}) => <Button {...rest}>{children}</Button>;

OutlinedButton.defaultProps = {
	type: 'button',
};

export default OutlinedButton;
