import * as React from 'react';
import styled from 'styled-components';
import { RecipeCreatorType, RecipeCreatorTypes } from '../../../../types';
import { dimensionCombination } from '../../../../helpers';

const HORIZONTAL_PADDING = 10; // px
const VERTICAL_PADDING = 3; // px

const RecipeCardImageBadgeTextWrapper = styled('div')<RecipeCardImageBadgeTextProps>`
	border-top-left-radius: 3px;
	display: inline-block;
	padding: ${dimensionCombination(HORIZONTAL_PADDING, VERTICAL_PADDING, 'px')};
	background-color: ${props =>
		props.type === RecipeCreatorType.user ? props.theme.colors.recipeUser : props.theme.colors.recipeCooperation};
`;

export interface RecipeCardImageBadgeTextProps {
	type: RecipeCreatorTypes;
}

const RecipeCardImageBadgeText: React.FC<RecipeCardImageBadgeTextProps> = ({ type, children }) => (
	<RecipeCardImageBadgeTextWrapper type={type}>{children}</RecipeCardImageBadgeTextWrapper>
);

export default RecipeCardImageBadgeText;
