import * as React from 'react';
import { ReactNode } from 'react';
import RecipeCard from 'styleguide/components/Cards/RecipeCard/RecipeCard';
import styled from 'styled-components';
import ShoppinglistIcon from 'styleguide/components/Icons/NormalIcons/ShoppinglistIcon/ShoppinglistIcon';
import CloseIcon from 'styleguide/components/Icons/NormalIcons/CloseIcon/CloseIcon';
import AnimatedFlyIconButton from 'styleguide/components/Buttons/AnimatedFlyIconButton/AnimatedFlyIconButton';
import { media } from 'styleguide/helpers/media';
import PenIcon from 'styleguide/components/Icons/NormalIcons/PenIcon/PenIcon';
import DownloadIcon from 'styleguide/components/Icons/NormalIcons/DownloadIcon/DownloadIcon';
import { UserRecipe } from '../interfaces';
import injectRoutable, { InjectedRoutableProps } from 'common/components/Routing/Routable';
import MediumHeader from 'styleguide/components/Typography/MediumHeader';
import { FormattedMessage } from 'react-intl';
import { UnderlineText } from './UnderlineText';
import { Button } from 'styleguide/components/Buttons/Button';
import Alert, { AlertType } from 'styleguide/components/Typography/Alert';

const RecipeTilesWrapper = styled.div`
	display: flex;
	flex-flow: row wrap;
	justify-content: flex-start;
	align-items: stretch;
	margin-bottom: 25px;

	${media.tablet`
    padding: 30px;
  `};
`;

const RecipeListWrapper = styled.div`
	margin-top: 45px;
`;

interface RecipeCardWrapperProps {
	isQueuedForDeletion: boolean;
}

const RecipeCardWrapper = styled.div<RecipeCardWrapperProps>`
	flex: 0 1 345px;
	flex: 0 1 calc(50% - 20px);
	position: relative;
	margin: 0 10px 20px;

	${({ isQueuedForDeletion }) =>
		isQueuedForDeletion &&
		`
	& img { 
		border: 4px solid red;
	}
	`}

	${media.tablet`
		/* IE */
    flex: 0 1 245px;
    flex: 0 1 calc(25% - 20px);
  `};
`;

const FloatingButtonsWrapper = styled.div`
	position: absolute;
	top: 6px;
	right: 6px;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
	z-index: 5;
	> .inlined {
		display: inline-block;
	}
`;

const FloatingButton = styled.button`
	background-color: ${props => props.theme.colors.white};
	width: 40px;
	height: 30px;
	border: 0 none;
	outline: none;
	cursor: pointer;
`;

const LeftButton = styled(FloatingButton)`
	border-top-left-radius: 3px;
	border-bottom-left-radius: 3px;
	border-right: 1px solid ${props => props.theme.colors.border};
`;

const MiddleButton = styled(FloatingButton)`
	border-right: 1px solid ${props => props.theme.colors.border};
`;

const RightButton = styled(FloatingButton)`
	border-top-right-radius: 3px;
	border-bottom-right-radius: 3px;
`;

const EditButton = styled(FloatingButton)`
	border-radius: 3px;
	margin-left: 5px;
`;

const SendMailWrapper = styled.div`
	margin-top: 1em;
	margin-bottom: 1em;
`;

export interface UserRecipeTilesProps {
	recipes: UserRecipe[];
	onToShoppingListClick: React.EventHandler<React.MouseEvent<HTMLButtonElement>>;
	onRemoveClick: React.EventHandler<React.MouseEvent<HTMLButtonElement>>;
	onSendClick: React.EventHandler<React.MouseEvent<HTMLButtonElement>>;
	sendClickDisabled: boolean | undefined;
	sendSucceeded: boolean | undefined;
}

const UserRecipeTiles: React.FC<UserRecipeTilesProps & InjectedRoutableProps> = props => {
	const {
		recipes,
		onRemoveClick,
		onToShoppingListClick,
		onLinkClick,
		onSendClick,
		sendClickDisabled,
		sendSucceeded,
	} = props;

	const onToShoppingListClickHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
		e.stopPropagation();
		onToShoppingListClick(e);
	};

	const onRemoveClickHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
		e.stopPropagation();
		onRemoveClick(e);
	};

	const onEditClickHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
		e.stopPropagation();
		window.location.href = `/reseptit/editori/?id=${e.currentTarget.value}`;
	};

	const downloadHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
		e.stopPropagation();
		window.location.href = e.currentTarget.value;
	};

	const renderRecipeList = (recipeList: UserRecipe[]) => {
		return (
			<RecipeTilesWrapper>
				{recipeList.map(
					({
						id,
						url,
						averageRating,
						recipeTimeInMinutes,
						imageUrl,
						name,
						userIsCreator,
						availableForDownload,
						downloadUrl,
					}) => (
						<RecipeCardWrapper key={id} isQueuedForDeletion={availableForDownload || false}>
							<RecipeCard
								url={url}
								rating={averageRating}
								totalTime={{ minutes: recipeTimeInMinutes }}
								imageUrl={`${imageUrl}/358x231-recipe-highlight`}
								recipeName={name}
								onClick={onLinkClick}
							/>
							<FloatingButtonsWrapper>
								<AnimatedFlyIconButton
									className="inlined"
									animationIcon={<ShoppinglistIcon size={18} />}
									onClick={onToShoppingListClickHandler}
									shouldFly={true}
									targetSelector="#shoppinglist-target">
									<LeftButton type="button" value={id}>
										<ShoppinglistIcon size={18} />
									</LeftButton>
								</AnimatedFlyIconButton>
								{!!availableForDownload && !!downloadUrl && (
									<MiddleButton type="button" onClick={downloadHandler} value={downloadUrl}>
										<DownloadIcon size={18} />
									</MiddleButton>
								)}
								<RightButton type="button" onClick={onRemoveClickHandler} value={id}>
									<CloseIcon size={18} />
								</RightButton>
								{userIsCreator && (
									<EditButton type="button" onClick={onEditClickHandler} value={id}>
										<PenIcon size={18} />
									</EditButton>
								)}
							</FloatingButtonsWrapper>
						</RecipeCardWrapper>
					)
				)}
			</RecipeTilesWrapper>
		);
	};

	const queuedForDeletionRecipes = recipes.filter(r => r.availableForDownload);
	const normalRecipes = recipes.filter(r => !r.availableForDownload);

	return (
		<RecipeListWrapper>
			{queuedForDeletionRecipes.length > 0 && (
				<>
					<MediumHeader>
						<FormattedMessage id="user_profile_recipe_deleting_title" />
					</MediumHeader>
					<div>
						<FormattedMessage
							id="user_profile_recipe_deleting_description"
							values={{ underline: (chunks: ReactNode) => <UnderlineText>{chunks}</UnderlineText> }}
						/>
					</div>
					{renderRecipeList(queuedForDeletionRecipes)}
				</>
			)}
			{normalRecipes.length > 0 && (
				<>
					<MediumHeader>
						<FormattedMessage id="user_profile_recipe_normal_title" />
					</MediumHeader>
					<div>
						<FormattedMessage id="user_profile_recipe_normal_description" />
					</div>
					<SendMailWrapper>
						<Button disabled={sendClickDisabled} size="medium" onClick={onSendClick}>
							<FormattedMessage id="user_profile_recipe_sendmail" />
						</Button>
					</SendMailWrapper>
					{sendSucceeded && (
						<Alert type={AlertType.Success}>
							<FormattedMessage id="user_profile_recipe_sendmail_sent" />
						</Alert>
					)}
					{renderRecipeList(normalRecipes)}
				</>
			)}{' '}
		</RecipeListWrapper>
	);
};

export default injectRoutable(UserRecipeTiles);
