import { ResourceType } from 'common/components/Resource/types';
import { Reducer } from 'redux';
import { ResourceActions, resourceActions } from 'common/components/Resource/reducers/actions';
import { getType } from 'typesafe-actions';
import { MyContentState } from './interfaces';

const myContentInitialState: MyContentState = {};

export const myContentReducer: Reducer<MyContentState, ResourceActions> = (
	state = myContentInitialState,
	action
): MyContentState => {
	switch (action.type) {
		case getType(resourceActions.setResource):
			if (action.payload.resource.type === ResourceType.myContent) {
				return {
					...state,
				};
			}
		default:
			return state;
	}
};
