import extend from './extend';
import valio from './valio';

const maitojame = extend(valio, {
	styledVariations: {
		siteLogoHeight: {
			desktop: 77,
			mobile: 48,
		},
	},
});

export default maitojame;
