import extend from './extend';
import { default as baseTheme } from './valio';
import { css } from 'styled-components';
import { media } from 'styleguide/helpers';

const themeColors = {
	primary: '#005670',
	summer: '#C0E021',
	autumn: '#AC7644',
	backgroundWhite: '#F2F6F8',
	textColor: '#000000',
	white: '#FFFFFF',
	petrol5: '#F2F7F8',
	violet30: '#EEE8F5',
	autumn30: '#E6D6C7',
};

const themeFonts = {
	heading: 'NationalCompressed2Bold',
	text: 'Matter Regular',
	button: 'Matter Bold',
};

const themeTextDefinitions = {
	heading: css`
		font-family: '${themeFonts.heading}';
		font-weight: 1000;
	`,
	navigation: css`
		font-family: '${themeFonts.text}';
		font-weight: 800;
	`,
	button: css`
		font-family: '${themeFonts.button}';
	`,
	nutrient: css`
		font-size: 18px;
		${media.tablet`
			font-size: 16px;
		`};
	`,
	contactPersonName: css`
		font-size: 20px !important;
		line-height: 22px;
		${media.tablet`
			font-size: 17px;
		`};
	`,
};

// iconfilter: https://codepen.io/sosuke/pen/Pjoqqp
// iconfilter: https://stackoverflow.com/questions/22252472/how-to-change-the-color-of-an-svg-element

const aimo = extend(baseTheme, {
	filters: {
		iconFilter:
			'brightness(0) saturate(100%) invert(17%) sepia(84%) saturate(2394%) hue-rotate(174deg) brightness(95%) contrast(103%)',
		textIconFilter: 'none',
	},
	colors: {
		brandPrimary: themeColors.primary,
		brandSecondary: themeColors.summer,
		brandTertiary: themeColors.autumn,

		brandPrimaryFeatureBackground: themeColors.violet30,

		buttonPrimary: themeColors.summer,
		buttonPrimaryHover: themeColors.backgroundWhite,
		buttonPrimaryBorder: themeColors.summer,
		buttonPrimaryBorderHover: themeColors.summer,
		buttonSecondary: themeColors.autumn,
		buttonSecondaryHover: themeColors.white,
		buttonTertiary: themeColors.backgroundWhite,
		buttonTertiaryHover: themeColors.summer,

		buttonHighlightText: themeColors.primary,
		buttonHighlightTextHover: themeColors.primary,
		buttonHighlightBackground: themeColors.summer,
		buttonHighlightBackgroundHover: themeColors.backgroundWhite,
		buttonHighlightBorder: themeColors.summer,
		buttonHighlightBorderHover: themeColors.summer,

		buttonOutlineText: themeColors.primary,
		buttonOutlineTextHover: themeColors.primary,
		buttonOutlineTextSecondary: themeColors.summer,
		buttonOutlineTextSecondaryHover: themeColors.primary,
		buttonOutlineBackground: themeColors.backgroundWhite,
		buttonOutlineBackgroundHover: themeColors.summer,
		buttonOutlineBackgroundSecondary: themeColors.backgroundWhite,
		buttonOutlineBackgroundSecondaryHover: themeColors.summer,
		buttonOutlineBorder: themeColors.summer,
		buttonOutlineBorderHover: themeColors.summer,
		buttonOutlineBorderSecondary: themeColors.summer,
		buttonOutlineBorderSecondaryHover: themeColors.primary,

		buttonLabeledBackground: themeColors.summer,
		buttonLabeledBackgroundHover: themeColors.backgroundWhite,
		buttonLabeledBorder: themeColors.summer,
		buttonLabeledBorderHover: themeColors.summer,

		buttonText: themeColors.primary,
		buttonHoverText: themeColors.primary,

		buttonTextForDarkBackground: themeColors.white,
		buttonTextForLightBackground: themeColors.primary,

		buttonHoverBackground: themeColors.primary,

		bodyText: themeColors.textColor,
		bodyBackground: themeColors.backgroundWhite,

		border: themeColors.summer,
		borderDark: themeColors.summer,
		borderHighlight: themeColors.summer,
		borderNavigation: themeColors.white,

		dropdownButtonTextOpen: themeColors.primary,
		dropdownButtonTextClosed: themeColors.primary,

		footerBackground: themeColors.primary,
		framedHeaderTitle: themeColors.primary,
		framedHeaderBorder: themeColors.summer,

		heading: themeColors.primary,

		imagePlaceHolder: themeColors.white,

		linkText: themeColors.primary,
		linkTextHover: themeColors.primary,

		navigationHeaderBackground: themeColors.summer,
		navigationBackground: themeColors.summer,
		navigationText: themeColors.backgroundWhite,
		navigationTopBar: themeColors.summer,

		nutrientBackground: themeColors.violet30,

		selectTextColor: themeColors.primary,
		selectedItemBackground: themeColors.summer,
	},
	fonts: {
		primary: themeFonts.heading,
		secondary: themeFonts.text,
		tertiary: themeFonts.text,
		primaryButtons: themeFonts.button,
		secondaryButtons: themeFonts.button,
	},
	typography: {
		heading: themeTextDefinitions.heading,
		navigation: themeTextDefinitions.navigation,
		button: themeTextDefinitions.button,
		nutrient: themeTextDefinitions.nutrient,
		contactPersonName: themeTextDefinitions.contactPersonName,
	},
	styledVariations: {
		mainContentOffset: () => css`
			padding-top: 0px;
		`,
	},
	options: {
		noveltyIconOverride: 'aimo',
	},
});

export default aimo;
