import * as React from 'react';
import NormalIconWrapper, { NormalIconWrapperProps } from '../NormalIconWrapper';
import { ThemeContext } from 'styled-components';

const DownloadIcon: React.FC<NormalIconWrapperProps & React.SVGAttributes<SVGElement>> = ({ color, ...props }) => {
	const theme = React.useContext(ThemeContext);

	return (
		<NormalIconWrapper {...props} viewBox="0 0 485 485">
			<g>
				<g>
					<path
						fill={color ?? theme.colors.brandPrimary}
						d="M233,378.7c2.6,2.6,6.1,4,9.5,4s6.9-1.3,9.5-4l107.5-107.5c5.3-5.3,5.3-13.8,0-19.1c-5.3-5.3-13.8-5.3-19.1,0L256,336.5
			v-323C256,6,250,0,242.5,0S229,6,229,13.5v323l-84.4-84.4c-5.3-5.3-13.8-5.3-19.1,0s-5.3,13.8,0,19.1L233,378.7z"
					/>
					<path
						fill={color ?? theme.colors.brandPrimary}
						d="M426.5,458h-368C51,458,45,464,45,471.5S51,485,58.5,485h368c7.5,0,13.5-6,13.5-13.5S434,458,426.5,458z"
					/>
				</g>
			</g>
		</NormalIconWrapper>
	);
};

export default DownloadIcon;
