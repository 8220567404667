import * as React from 'react';
import { default as Input, InputProps } from '../Input';

const asSmallIcon = (icon: React.ReactElement<any>) => React.cloneElement(icon, { size: 18 });

export interface InputWithIconProps extends InputProps {
	icon: React.ReactElement<any>;
}

const InputWithIcon: React.FC<InputWithIconProps & React.InputHTMLAttributes<HTMLInputElement>> = ({
	icon,
	...inputProps
}) => {
	return <Input iconPosition="left" iconWidth={18} icon={asSmallIcon(icon)} {...inputProps} />;
};

export default InputWithIcon;
