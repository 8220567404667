import * as React from 'react';
import SocialIconWrapper from '../SocialIconWrapper';
import { IconWrapperProps } from '../../../../interfaces';
import { ThemeContext } from 'styled-components';

const YoutubeIcon: React.FC<IconWrapperProps & React.SVGAttributes<SVGElement>> = ({ color, ...props }) => {
	const theme = React.useContext(ThemeContext);

	return (
		<SocialIconWrapper alt="YouTube" {...props}>
			<path
				fill={color ?? theme.colors.socialIcon}
				d="M11.868 19.682v-9.008l7.97 4.414-7.97 4.594zM29.7 8.853s-.293-2.204-1.192-3.175c-1.141-1.274-2.42-1.28-3.006-1.355C21.304 4 15.007 4 15.007 4h-.014s-6.297 0-10.495.323c-.587.075-1.865.081-3.006 1.355C.592 6.65.3 8.853.3 8.853S0 11.442 0 14.03v2.427c0 2.588.3 5.177.3 5.177s.293 2.204 1.192 3.175c1.141 1.274 2.64 1.233 3.308 1.367 2.4.245 10.2.32 10.2.32s6.304-.01 10.502-.333c.586-.075 1.865-.08 3.006-1.355.9-.97 1.192-3.175 1.192-3.175s.3-2.588.3-5.176V14.03c0-2.588-.3-5.177-.3-5.177z"
			/>
		</SocialIconWrapper>
	);
};

export default YoutubeIcon;
