import { trimEnd, trimStart } from 'utils/strings';
import { fetchJson, postJson, throwMissingSiteUrlIdError } from 'utils/service';
import { ResourceApiShape, ResourceSendToEmailApiResponse } from './interfaces';

export function ensureResourceUrl(url: string): string {
	return trimEnd(removeUrlHash(url), '/') + '/';
}

function removeUrlHash(url: string) {
	return url.split('#')[0];
}

export interface ResourceRedirectApiShape {
	redirectUrl: string;
}

async function fetchResource(siteUrlId: string, resourceUrl: string): Promise<ResourceApiShape> {
	if (!siteUrlId) {
		throwMissingSiteUrlIdError();
	}
	const sitePart = siteUrlId;
	const resourcePart = trimStart(ensureResourceUrl(resourceUrl), '/');
	const fullUrl = `/api/sites/${sitePart}/content/${resourcePart}`;
	return await fetchJson<ResourceApiShape>(fullUrl);
}

async function setResourcePublicationStatus(resourceId: string, published: boolean): Promise<ResourceRedirectApiShape> {
	return await postJson(`/api/resources/${resourceId}/status`, {
		published,
	});
}

async function sendResourceToEmail(
	resourceId: string,
	senderName: string,
	senderEmail: string,
	recipientEmail: string
): Promise<ResourceSendToEmailApiResponse> {
	return await postJson(`/api/email/SendResourceLinkEmail`, {
		resourceId,
		senderName,
		senderEmail,
		recipientEmail,
	});
}

export const resourceApi = {
	fetchResource,
	setResourcePublicationStatus,
	sendResourceToEmail,
};

export type ResourceApi = typeof resourceApi;
