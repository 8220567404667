import * as React from 'react';
const FiFooter = loadable(() => import(/* webpackChunkName: "navigation-fi" */ './FiFooter'));
import { connect, MapStateToProps } from 'react-redux';
import loadable from '@loadable/component';
import {
	Site,
	getSite,
	getSiteUrlPrefixOrDefault,
	SiteId,
	getTwitterUserName,
	getFacebookUserName,
	getYouTubeUserName,
	getLinkedInUserName,
	getInstagramUserName,
	getPinterestUserName,
	getFooterBackgroundMediaUrl,
} from '../App/services';
import { State } from 'common/reducers';
const ComFooter = loadable(() => import(/* webpackChunkName: "navigation-com" */ './ComFooter'));
import { isEqualCaseInsensitive } from 'utils/strings';
import { SocialUserNameProps } from './interfaces';
import OgFooter from './OgFooter';
import AimoFooter from './AimoFooter';

function FooterComponent({ site, isProfessional, ...otherProps }: StateProps) {
	if (!site) {
		return null;
	}

	const isValioFi = isEqualCaseInsensitive(site.id, SiteId.fi);
	const isMajal = isEqualCaseInsensitive(site.id, SiteId.majal);

	if (isMajal) {
		return <ComFooter siteId={site.id} {...otherProps} copyright={'global_copyright_majal'} />;
	} else if (isValioFi && !isProfessional) {
		return <FiFooter {...otherProps} />;
	} else if (isValioFi && isProfessional) {
		return <AimoFooter />;
	}

	if (site.theme === 'oddlygood') {
		return <OgFooter siteId={site.id} {...otherProps} />;
	}

	return <ComFooter siteId={site.id} {...otherProps} />;
}

const Footer: React.FC<StateProps> = props => {
	return <FooterComponent {...props} />;
};

interface StateProps extends SocialUserNameProps {
	site: Site | undefined;
	siteUrlPrefix: string;
	backgroundMediaUrl?: string;
	isProfessional: boolean;
}

const mapStateToProps: MapStateToProps<StateProps, {}, State> = state => {
	const { routing, app, navigation } = state;
	const sites = app.settings?.sites || [];
	return {
		siteUrlPrefix: getSiteUrlPrefixOrDefault(routing, sites, '/'),
		site: !!sites ? getSite(routing, sites) : undefined,
		isProfessional: navigation.layout === 'Professional',
		facebookUserName: getFacebookUserName(state),
		youTubeUserName: getYouTubeUserName(state),
		twitterUserName: getTwitterUserName(state),
		linkedInUserName: getLinkedInUserName(state),
		instagramUserName: getInstagramUserName(state),
		pinterestUserName: getPinterestUserName(state),
		backgroundMediaUrl: getFooterBackgroundMediaUrl(state),
	};
};

export default connect(mapStateToProps)(Footer);
