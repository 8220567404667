import * as React from 'react';
import styled from 'styled-components';

const PlusOneIconWrapper = styled.div`
	background-color: ${props => props.theme.colors.white};
	padding: 4px;
	border-radius: 15px;
	font-family: omnes-pro;
	font-size: 12px;
	width: 26px;
	height: 26px;
	border: 1px solid ${props => props.theme.colors.brandPrimary};
	color: ${props => props.theme.colors.brandPrimary};
	text-align: center;

	:before {
		content: '+1';
	}
`;

const PlusOneIcon: React.FC = _ => <PlusOneIconWrapper />;

/** @component */
export default PlusOneIcon;
