import { createCustomAction, createStandardAction, ActionType } from 'typesafe-actions';
import { SearchType } from 'pagetypes/Search/types';
import { RecipeSearchApiResult, ProductSearchApiResult, ProductInfo, ArticleSearchApiResult } from '../interfaces';
import { ProductSearchResult } from 'common/components/GlobalSearch/services/product-search';
import { searchWithoutQuestion } from 'utils/query-string';

export const searchActions = {
	setQuery: createCustomAction(
		'SEARCH/SET_QUERY',
		type => (
			siteUrlId: string,
			professional: boolean,
			resourceId: string,
			query: string,
			searchType: SearchType,
			pageSize: number,
			pageNumber: number,
			onlyItems: boolean
		) => ({
			type,
			payload: { siteUrlId, professional, resourceId, query, searchType, pageSize, pageNumber, onlyItems },
		})
	),
	setSearchType: createStandardAction('SEARCH/SET_SEARCH_TYPE')<SearchType>(),

	searchArticles: createCustomAction(
		'SEARCH/SEARCH_ARTICLES_REQUEST',
		type => (siteUrlId: string, resourceId: string, query: string, pageSize: number) => ({
			type,
			payload: { siteUrlId, resourceId, query: searchWithoutQuestion(query), pageSize, pageNumber: 1 },
		})
	),
	loadMoreArticles: createCustomAction(
		'SEARCH/LOAD_MORE_ARTICLES_REQUEST',
		type => (siteUrlId: string, resourceId: string, query: string, pageSize: number, pageNumber: number) => ({
			type,
			payload: { siteUrlId, resourceId, query: searchWithoutQuestion(query), pageSize, pageNumber },
		})
	),

	searchArticlesSucceeded: createCustomAction(
		'SEARCH/SEARCH_ARTICLES_SUCCEEDED',
		type => (result: ArticleSearchApiResult, pageNumber: number) => ({
			type,
			payload: { result, pageNumber },
		})
	),
	searchArticlesFailed: createStandardAction('SEARCH/SEARCH_ARTICLES_FAILED')<string>(),

	searchRecipes: createCustomAction(
		'SEARCH/SEARCH_RECIPES_REQUEST',
		type => (siteUrlId: string, professional: boolean, search: string, pageSize: number) => ({
			type,
			payload: { siteUrlId, professional, query: searchWithoutQuestion(search), pageSize, pageNumber: 1 },
		})
	),
	loadMoreRecipes: createCustomAction(
		'SEARCH/LOAD_MORE_RECIPES_REQUEST',
		type => (siteUrlId: string, professional: boolean, search: string, pageSize: number, pageNumber: number) => ({
			type,
			payload: { siteUrlId, professional, query: searchWithoutQuestion(search), pageSize, pageNumber },
		})
	),
	searchRecipesSucceeded: createCustomAction(
		'SEARCH/SEARCH_RECIPES_SUCCEEDED',
		type => (result: RecipeSearchApiResult, pageNumber: number) => ({
			type,
			payload: { result, pageNumber },
		})
	),
	searchRecipesFailed: createStandardAction('SEARCH/SEARCH_RECIPES_FAILED')<string>(),
	searchProducts: createCustomAction(
		'SEARCH/SEARCH_PRODUCTS_REQUEST',
		type => (siteUrlId: string, query: string, pageSize: number) => ({
			type,
			payload: { siteUrlId, query: searchWithoutQuestion(query), pageSize, pageNumber: 1, onlyItems: false },
		})
	),
	loadMoreProducts: createCustomAction(
		'SEARCH/LOAD_MORE_PRODUCTS_REQUEST',
		type => (siteUrlId: string, query: string, pageSize: number, pageNumber: number) => ({
			type,
			payload: { siteUrlId, query: searchWithoutQuestion(query), pageSize, pageNumber, onlyItems: true },
		})
	),
	searchProductsSucceeded: createCustomAction(
		'SEARCH/SEARCH_PRODUCTS_SUCCEEDED',
		type => (result: ProductSearchApiResult, pageNumber: number) => ({
			type,
			payload: { result, pageNumber },
		})
	),
	searchProductsFailed: createStandardAction('SEARCH/SEARCH_PRODUCTS_FAILED')<string>(),

	recipeProductFilterSearchProducts: createCustomAction(
		'SEARCH/RECIPE_PRODUCT_FILTER/SEARCH_PRODUCTS_REQUEST',
		type => (siteUrlId: string, searchTerm: string, maxResults: number) => ({
			type,
			payload: { siteUrlId, searchTerm, maxResults },
		})
	),
	recipeProductFilterSearchProductsSuccess: createCustomAction(
		'SEARCH/RECIPE_PRODUCT_FILTER/SEARCH_PRODUCTS_SUCCESS',
		type => (searchResult: ProductSearchResult) => ({
			type,
			payload: { searchResult },
		})
	),
	recipeProductFilterSetProduct: createCustomAction(
		'SEARCH/RECIPE_PRODUCT_FILTER/SET_PRODUCT',
		type => (title: string, slug: string | undefined) => ({
			type,
			payload: { title, slug },
		})
	),
	recipeProductFilterLoadProduct: createCustomAction(
		'SEARCH/RECIPE_PRODUCT_FILTER/LOAD_PRODUCT_REQUEST',
		type => (slug: string) => ({
			type,
			payload: { slug },
		})
	),
	recipeProductFilterLoadProductSuccess: createCustomAction(
		'SEARCH/RECIPE_PRODUCT_FILTER/LOAD_PRODUCT_SUCCESS',
		type => (result: ProductInfo) => ({
			type,
			payload: { ...result },
		})
	),
};

export type SearchActions = ActionType<typeof searchActions>;
