import { createCustomAction, ActionType, getType } from 'typesafe-actions';
import { RecipeNutrientApiResponse } from '../services/services';
import { Reducer } from 'react';
import { RecipeDetails } from '../interfaces';

export const portionNutrientsActions = {
	fetchScaledNutrients: createCustomAction(
		'RECIPE/FETCH_NUTRIENTS_REQUEST',
		type => (recipeId: string, portionWeight: number) => ({
			type,
			payload: {
				recipeId,
				portionWeight,
			},
		})
	),

	updateScaledNutrients: createCustomAction(
		'RECIPE/FETCH_NUTRIENTS_SUCCESS',
		type => (response: RecipeNutrientApiResponse) => ({
			type,
			payload: response,
		})
	),

	scaledNutrientsFailed: createCustomAction('RECIPE/FETCH_NUTRIENTS_FAILURE', type => (error: string) => ({
		type,
		payload: { error },
	})),
};

export const portionNutrientsReducer: Reducer<RecipeDetails, PortionNutrientsActions> = (
	state,
	action
): RecipeDetails => {
	switch (action.type) {
		case getType(portionNutrientsActions.fetchScaledNutrients):
			if (!state.recipe) {
				return state;
			}

			return {
				...state,
				recipe: {
					...state.recipe,
					portionNutritients: {
						...state.recipe.portionNutritients,
						isLoading: true,
					},
				},
			};

		case getType(portionNutrientsActions.updateScaledNutrients):
			if (!state.recipe) {
				return state;
			}
			return {
				...state,
				recipe: {
					...state.recipe,
					portionNutritients: {
						...state.recipe.portionNutritients,
						isLoading: false,
						nutrients: action.payload.map(({ name, quantity, quantityUnit }) => ({
							nutrient: name,
							amount: parseFloat(quantity.replace(/,/, '.')),
							unit: quantityUnit,
						})),
					},
				},
			};

		case getType(portionNutrientsActions.scaledNutrientsFailed):
			if (!state.recipe) {
				return state;
			}

			return {
				...state,
				recipe: {
					...state.recipe,
					portionNutritients: {
						...state.recipe.portionNutritients,
						isLoading: false,
						error: action.payload.error,
					},
				},
			};

		default:
			return state;
	}
};

export type PortionNutrientsActions = ActionType<typeof portionNutrientsActions>;
