import * as React from 'react';
import NormalIconWrapper, { NormalIconWrapperProps } from '../NormalIconWrapper';
import { ThemeContext } from 'styled-components';

const BarChartIcon: React.FC<NormalIconWrapperProps & React.SVGAttributes<SVGElement>> = ({ color, ...props }) => {
	const theme = React.useContext(ThemeContext);
	return (
		<NormalIconWrapper {...props}>
			<path
				fill={color ?? theme.colors.brandSecondary}
				d="M20.265 22.412a.441.441 0 0 1-.883 0V10.647a.441.441 0 0 1 .883 0v11.765zM13.206 22.412a.441.441 0 0 1-.882 0V3.588a.441.441 0 1 1 .882 0v18.824zM6.147 22.412a.441.441 0 0 1-.882 0v-7.06a.441.441 0 1 1 .882 0v7.06z"
			/>
		</NormalIconWrapper>
	);
};

export default BarChartIcon;
