export interface AbsoluteOffset {
	top: number;
	left: number;
}

export const getOffset = (element: Element): AbsoluteOffset => {
	const rect = element.getBoundingClientRect();
	return {
		left: rect.left,
		top: rect.top,
	};
};
