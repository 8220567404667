import * as React from 'react';
import { MapStateToProps, connect } from 'react-redux';
import { State } from 'common/reducers';
import styled from 'styled-components';
import Pill from 'styleguide/components/Selection/Pill/Pill';
import { media } from 'styleguide/helpers/media';
import { NameAndValue } from 'common/interfaces/common';
import { SearchState, SelectedSearchFilter, SearchFilterSection } from 'pagetypes/Search/interfaces';
import {
	queryToFilters,
	getDisplayedSelectedFilters,
	findFilterItem,
	getFilterItemAncestors,
} from 'pagetypes/Search/utils';
import { removeFromSearch } from 'utils/query-string';
import { routingActions, RoutingState } from 'common/components/Routing/reducers';
import { searchActions } from 'pagetypes/Search/reducers/search-actions';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { SearchFilterName } from '../../../../pagetypes/Search/types';
import { getSettingValue } from 'common/components/App/services';

const PillsContainer = styled.span`
	padding: 2px 0 10px;
	order: 0;
	width: 100%;

	${media.desktop`
		order: 2;
		width: auto;
		flex: 1;
    padding: 2px 0;
		margin: -3px -3px -3px 13px;
	`};

	.selected-filter {
		margin: 3px;

		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
		max-width: 155px;

		${media.phone`
			max-width: 205px;
		`};

		${media.phone560`
			max-width: 405px;
		`};

		${media.tablet`
      max-width: none;
    `};
	}
`;

interface SelectedFiltersStateProps {
	routingState: RoutingState;
	availableFilters: SearchFilterSection[];
	selectedFilters: NameAndValue[];
	otherFilters: SelectedSearchFilter[];
	selectedProductTitle?: string;
	selectedProductSlug?: string;
	ingredientsParameterName: string;
}

interface SelectedFiltersDispatchProps {
	updateSearch: typeof routingActions.updateSearch;
	setProduct: typeof searchActions.recipeProductFilterSetProduct;
}

class SelectedFilterPills extends React.Component<
	SelectedFiltersStateProps & SelectedFiltersDispatchProps & WrappedComponentProps
> {
	public render() {
		const {
			selectedFilters,
			availableFilters,
			otherFilters,
			selectedProductSlug,
			selectedProductTitle,
			ingredientsParameterName,
			intl,
		} = this.props;
		const displayedFilters = getDisplayedSelectedFilters(
			intl,
			selectedFilters,
			availableFilters,
			otherFilters,
			selectedProductSlug,
			selectedProductTitle,
			ingredientsParameterName
		);
		return <PillsContainer>{displayedFilters.map(f => this.renderFilter(f))}</PillsContainer>;
	}

	private renderFilter({ name, value, title }: SelectedSearchFilter) {
		return (
			<Pill
				key={`${name} ${value}`}
				className="selected-filter"
				onRemove={this.handleRemove.bind(this, { name, value })}>
				<FormattedMessage id={title} defaultMessage={title} />
			</Pill>
		);
	}

	private handleRemove = (filter: NameAndValue) => {
		const { updateSearch, routingState, availableFilters, ingredientsParameterName } = this.props;

		if (filter.name === ingredientsParameterName) {
			this.props.setProduct('', undefined);
		}

		let search = routingState.search || '';

		const filterItem = findFilterItem(filter, availableFilters);
		if (filterItem) {
			const filterItemAncestors = getFilterItemAncestors(availableFilters, filterItem);
			search = filterItemAncestors.reduce((memo, { name, value }) => {
				return removeFromSearch(memo, name, value);
			}, search);
		}

		search = removeFromSearch(search, filter.name, filter.value);
		updateSearch(search);
	};
}

const mapStateToProps: MapStateToProps<SelectedFiltersStateProps, {}, State> = state => {
	const { resource, routing } = state;
	const search = resource.content as SearchState;
	const availableFilters = search.availableFilters;
	const filter = search && search.recipeProductFilter;
	const ingredientsParameterName =
		getSettingValue(state, 'RecipeSearch', 'IngredientsParameter') ?? SearchFilterName.ingredients;
	return {
		routingState: routing,
		availableFilters,
		selectedFilters: queryToFilters(routing.query || {}),
		otherFilters: search.otherFilters,
		selectedProductTitle: filter && filter.productTitle,
		selectedProductSlug: filter && filter.productSlug,
		ingredientsParameterName,
	};
};

export default connect(mapStateToProps, {
	updateSearch: routingActions.updateSearch,
	setProduct: searchActions.recipeProductFilterSetProduct,
	recipeProductFilterLoadProduct: searchActions.recipeProductFilterLoadProduct,
})(injectIntl(SelectedFilterPills));
