import { combineReducers } from 'redux';
import { RoutingActions, RoutingState, initialRoutingState, routingReducer } from '../components/Routing/reducers';
import { ResourceActions } from '../components/Resource/reducers/actions';
import appReducer, { AppActions, AppState, initialAppState } from '../components/App/reducers';
import navigationReducer, { NavigationActions, initialNavigationState } from '../components/Navigation/reducers';
import { RecipeActions } from 'pagetypes/Recipe/reducers/reducers';
import { ProductSearchActions } from 'pagetypes/ProductFront/reducers/product-search-actions';
import { WeeklyMenuActions } from '../components/WeeklyMenu/reducers';
import { ProductFrontActions } from 'pagetypes/ProductFront/reducers/reducers';
import { GlobalSearchActions } from '../components/GlobalSearch/reducers/actions';
import domReducer, { DomActions, DomState, initialDomState } from '../components/Dom/reducers';
import { UserActions } from 'pagetypes/User/reducers/actions';
import { ResourceState, initialResourceState, resourceReducer } from '../components/Resource/reducers/reducers';
import { NavigationState } from '../components/Navigation/reducers';
import {
	GlobalSearchState,
	initialGlobalSearchState,
	globalSearchReducer,
} from '../components/GlobalSearch/reducers/reducers';
import { UserState, userReducer } from 'pagetypes/User/reducers/reducers';
import { LoginState, initialLoginState, loginReducer } from '../components/Login/reducers/reducers';
import { SearchActions } from 'pagetypes/Search/reducers/search-actions';
import {
	UserShoppingListShape,
	userShoppingListReducer,
	userShoppingListInitialState,
} from 'pagetypes/User/reducers/shopping-list';

export enum ActionType {
	OTHER_ACTION = '__any_other_action_type__',
}

export interface OtherAction {
	type: ActionType.OTHER_ACTION;
}

export type ActionTypes =
	| RoutingActions
	| ResourceActions
	| AppActions
	| NavigationActions
	| RecipeActions
	| ProductSearchActions
	| WeeklyMenuActions
	| ProductFrontActions
	| GlobalSearchActions
	| DomActions
	| UserActions
	| SearchActions
	| OtherAction; // OtherAction needs to be last

export interface State {
	app: AppState;
	dom: DomState;
	resource: ResourceState;
	navigation: NavigationState;
	globalSearch: GlobalSearchState;
	routing: RoutingState;
	user: UserState | null;
	shoppingList: UserShoppingListShape;
	login: LoginState;
}

export const initialState: State = {
	app: initialAppState,
	dom: initialDomState,
	resource: initialResourceState,
	navigation: initialNavigationState,
	globalSearch: initialGlobalSearchState,
	routing: initialRoutingState,
	user: null,
	shoppingList: userShoppingListInitialState,
	login: initialLoginState,
};

export default combineReducers<State>({
	app: appReducer,
	dom: domReducer,
	resource: resourceReducer,
	navigation: navigationReducer,
	globalSearch: globalSearchReducer,
	routing: routingReducer,
	user: userReducer,
	shoppingList: userShoppingListReducer,
	login: loginReducer,
});
