import * as React from 'react';
import styled from 'styled-components';
import Stars from '../../Stars';
import CookingTime from './CookingTime';

const RecipeCardInfoWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 5px;
	position: relative;
`;

const PaddingLeftWrapper = styled.div`
	padding-left: 9px;
`;

const PaddingRightWrapper = styled.div`
	padding-right: 9px;
`;

export interface RecipeCardInfoProps {
	showRating: boolean;
	rating: number | null;
	cookingTime: string;
}

const RecipeCardInfo: React.FC<RecipeCardInfoProps> = ({ rating, showRating, cookingTime }) => {
	return (
		<RecipeCardInfoWrapper>
			<PaddingLeftWrapper>{showRating && <Stars rating={rating || 0} />}</PaddingLeftWrapper>
			<PaddingRightWrapper>
				<CookingTime>{cookingTime}</CookingTime>
			</PaddingRightWrapper>
		</RecipeCardInfoWrapper>
	);
};

export default RecipeCardInfo;
