import * as React from 'react';
import * as defaultIcon from '../../../../images/fc-check-mark-icon.png';
import * as caretIcon from '../../../../images/fc-check-mark-with-caret-icon.png';
import { NormalIconWrapperProps } from '../NormalIconWrapper';

export type CheckMarkType = 'default' | 'withCaret';

export interface CheckIconProps extends NormalIconWrapperProps {
	iconType?: CheckMarkType;
}

const FcCheckIcon: React.FC<CheckIconProps> = ({ ...props }) => {
	const icon = props.iconType && props.iconType === 'withCaret' ? caretIcon : defaultIcon;
	return <img src={icon} />;
};

export default FcCheckIcon;
