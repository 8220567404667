import * as React from 'react';
import styled, { ThemeContext } from 'styled-components';
import { Button, ButtonLink } from 'styleguide/components/Buttons/Button';
import { CardHeader, CardTitle } from 'common/components/Widgets/NewsletterOrder/components/NewsletterCard';
import { MaterialCardInterface, CartItem } from '../interfaces';
import { media } from 'styleguide/helpers';
import { LazyImage } from 'styleguide/components/LazyImage';
import { getLocalizedDate } from 'utils/date';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { PlusOneIcon } from 'styleguide/components/Icons';
import AnimatedFlyIconButton from 'styleguide/components/Buttons/AnimatedFlyIconButton';
import Modal from 'styleguide/components/Modals/Modal';
import MediumHeader from 'styleguide/components/Typography/MediumHeader';

const Card = styled.article`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin: ${({ theme }) => theme.grid.gutterInPx()} 0;
	flex: 0 0 auto;
	width: 100%;

	${media.tablet`
		margin: ${({ theme }) => theme.grid.gutterInPx()};
		width: calc(50% - ${({ theme }) => theme.grid.gutter * 2 + 1}px);

		@supports (flex: 0 0 calc(50% - ${({ theme }) => theme.grid.gutterInPx(2)})) {
			flex: 0 0 calc(50% - ${({ theme }) => theme.grid.gutterInPx(2)});
			width: auto;
		}
	`};

	${media.desktop1200`
		width: calc(100% / 3 - ${({ theme }) => theme.grid.gutter * 2 + 1}px);

		@supports (flex: 0 0 calc(100% / 3 - ${({ theme }) => theme.grid.gutterInPx(2)})) {
			flex: 0 0 calc(100% / 3 - ${({ theme }) => theme.grid.gutterInPx(2)});
			width: auto;
		}
	`};
`;

const Description = styled.p``;

const Time = styled.time`
	font-size: 14px;
	color: ${({ theme }) => theme.colors.shoppingListGrey};
`;

const Footer = styled.footer`
	display: flex;
	justify-content: space-between;
`;

const SingleButton = styled(ButtonLink)`
	flex: 1 0 auto;
	border-radius: 0 0 10px 10px;
`;

const LeftButton = styled(ButtonLink)`
	flex: 1 0 50%;
	border-radius: 0 0 0 10px;
`;

const AnimatedRightButton = styled(AnimatedFlyIconButton)`
	flex: 1 0 50%;
`;

const RightButton = styled(Button)`
	border-radius: 0 0 10px 0;
	width: 100%;
`;

interface Props {
	items: CartItem[];
	onAddToCart: (id: string, quantity: number) => void;
}

const MaterialCard: React.FC<Props & MaterialCardInterface> = ({
	items,
	id,
	title,
	description,
	date,
	previewImage,
	material,
	fileSuffix,
	titleSlug,
	postable,
	showChildDisclaimer,
	maxOrderAmount,
	onAddToCart,
}) => {
	const [disclaimerOpen, setDisclaimerOpen] = React.useState(false);
	const isItemInCart = items.findIndex(item => item.id === id) > -1;
	const theme = React.useContext(ThemeContext);

	const onClick = () => {
		if (!isItemInCart && maxOrderAmount) {
			onAddToCart(id, 1);
		}
	};

	const onDownload = (event: React.MouseEvent) => {
		if (showChildDisclaimer) {
			event.preventDefault();
			setDisclaimerOpen(true);
		}
	};

	const onDisclaimerClose = () => {
		setDisclaimerOpen(false);
	};

	return (
		<Card>
			<CardHeader>
				<CardTitle>
					{title}
					{previewImage && <LazyImage src={`${previewImage.rawUrl}/120x120`} alt={title} />}
				</CardTitle>
				<Description>{description}</Description>
				<Time dateTime={date}>{getLocalizedDate(new Date(date))}</Time>
			</CardHeader>
			<Footer>
				{!postable && (
					<SingleButton
						href={material.rawUrl}
						type={material.mediaType}
						download={`${titleSlug}.${fileSuffix}`}
						target="_blank"
						rel="noopener noreferrer"
						size="medium"
						onClick={onDownload}>
						<FormattedMessage id="materials_search_item_download" />
					</SingleButton>
				)}
				{postable && maxOrderAmount !== null && (
					<>
						<LeftButton
							href={material.rawUrl}
							type={material.mediaType}
							download={`${titleSlug}.${fileSuffix}`}
							size="medium"
							target="_blank"
							rel="noopener noreferrer"
							onClick={onDownload}>
							<FormattedMessage id="materials_search_item_download" />
						</LeftButton>
						<AnimatedRightButton
							onClick={onClick}
							shouldFly={true}
							targetSelector="#cart-dropdown-button"
							animationIcon={<PlusOneIcon />}>
							<RightButton
								size="medium"
								backgroundColor={theme.colors.buttonTertiary}
								backgroundHoverColor={theme.colors.buttonTertiaryHover}
								disabled={isItemInCart}
								onClick={onClick}>
								{isItemInCart && <FormattedMessage id="materials_search_item_order_in_cart" />}
								{!isItemInCart && <FormattedMessage id="materials_search_item_order" />}
							</RightButton>
						</AnimatedRightButton>
					</>
				)}
			</Footer>
			{disclaimerOpen && (
				<Modal onClose={onDisclaimerClose}>
					<MediumHeader>
						<FormattedMessage id="materials_search_child_disclaimer_title" />
					</MediumHeader>
					<p>
						<FormattedHTMLMessage id="materials_search_child_disclaimer_description" />
					</p>
					<ButtonLink
						href={material.rawUrl}
						type={material.mediaType}
						download={`${titleSlug}.${fileSuffix}`}
						size="medium"
						onClick={onDisclaimerClose}>
						<FormattedMessage id="materials_search_child_disclaimer_button_label" />
					</ButtonLink>
				</Modal>
			)}
		</Card>
	);
};

export default MaterialCard;
