import * as React from 'react';
import styled from 'styled-components';

const LoadingWrapper = styled.section`
	margin: 100px auto;
	text-align: center;

	.loading-image {
		animation: spin 2s infinite linear;

		@keyframes spin {
			from {
				transform: rotate(0deg);
			}
			to {
				transform: rotate(359deg);
			}
		}
	}
`;

interface Props {
	className?: string;
}

const LoadingSpinner: React.FC<Props> = ({ className }) => (
	<LoadingWrapper className={className}>
		<svg className="loading-image" width="40" height="40" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M20 4C11.178 4 4 11.178 4 20s7.178 16 16 16 16-7.178 16-16S28.822 4 20 4m0 36C8.972 40 0 31.028 0 20S8.972 0 20 0s20 8.972 20 20-8.972 20-20 20"
				fill="#E6E6E6"
			/>
			<path
				d="M20 40a2 2 0 0 1 0-4c8.822 0 16-7.178 16-16S28.822 4 20 4 4 11.178 4 20a2 2 0 0 1-4 0C0 8.972 8.972 0 20 0s20 8.972 20 20-8.972 20-20 20"
				fill="#009DDB"
			/>
		</svg>
	</LoadingWrapper>
);

export default LoadingSpinner;
