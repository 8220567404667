import { Reducer } from 'redux';
import { loginActions, LoginActions } from './actions';
import { getType } from 'typesafe-actions';
import { LoginModalFormType } from '../types';

const loginErrors = {
	1: 'component_login_login_error_username_password_mismatch',
	2: 'component_login_login_error_username_removed',
	3: 'component_login_login_error_email_required',
	7: 'component_login_login_error_facebook_account',
};

export interface LoginFormsState {
	isLoading: boolean;
	error?: string;
}

export interface RegistrationFormState extends LoginFormsState {
	uniqueUserName?: string;
	uniqueUserNameError?: string;
}

export interface LoginState {
	isLoginOpen: boolean;
	loginForm?: LoginFormsState;
	facebookForm?: LoginFormsState;
	registrationRequestForm?: LoginFormsState;
	registrationForm?: RegistrationFormState;
	forgotPasswordForm?: LoginFormsState;
	loginFormType?: LoginModalFormType;
	resetPasswordForm?: LoginFormsState;
}

export const initialLoginState: LoginState = {
	isLoginOpen: false,
	loginFormType: LoginModalFormType.LOGIN,
};

export const loginReducer: Reducer<LoginState, LoginActions> = (state = initialLoginState, action) => {
	if (action.type === getType(loginActions.openLogin)) {
		return {
			...state,
			isLoginOpen: true,
			loginFormType: action.payload || LoginModalFormType.LOGIN,
			loginForm: state.loginForm
				? {
						...state.loginForm,
						error: '',
				  }
				: undefined,
		};
	}
	if (action.type === getType(loginActions.closeLogin)) {
		return {
			...state,
			isLoginOpen: false,
			loginForm: {
				...state.loginForm,
				isLoading: false,
				error: '',
			},
			registrationForm: {
				...state.registrationForm,
				isLoading: false,
				error: '',
			},
			registrationRequestForm: {
				...state.registrationRequestForm,
				isLoading: false,
				error: '',
			},
			forgotPasswordForm: {
				...state.forgotPasswordForm,
				isLoading: false,
				error: '',
			},
		};
	}
	if (action.type === getType(loginActions.changeLoginFormType)) {
		return {
			...state,
			loginFormType: action.payload || LoginModalFormType.LOGIN,
		};
	}
	if (action.type === getType(loginActions.doLogIn)) {
		return {
			...state,
			loginForm: {
				...state.loginForm,
				isLoading: true,
				error: '',
			},
		};
	}
	if (action.type === getType(loginActions.loginSuccess)) {
		return {
			...state,
			isLoginOpen: false,
			loginForm: {
				...state.loginForm,
				isLoading: false,
				error: '',
			},
		};
	}
	if (action.type === getType(loginActions.loginFail)) {
		let error = loginErrors[action.payload];
		if (!error) {
			error = 'component_login_login_error_login_failed';
		}
		return {
			...state,
			loginForm: {
				...state.loginForm,
				isLoading: false,
				error,
			},
		};
	}
	if (action.type === getType(loginActions.doFacebookLogIn)) {
		return {
			...state,
			facebookForm: {
				...state.facebookForm,
				isLoading: true,
				error: '',
			},
		};
	}
	if (action.type === getType(loginActions.facebookLoginSuccess)) {
		return {
			...state,
			isLoginOpen: false,
			facebookForm: {
				isLoading: false,
				error: '',
			},
		};
	}
	if (action.type === getType(loginActions.facebookLoginFail)) {
		let error = loginErrors[action.payload];
		if (!error) {
			error = 'component_login_login_facebook_failed';
		}
		return {
			...state,
			facebookForm: {
				...state.facebookForm,
				isLoading: false,
				error,
			},
		};
	}

	if (action.type === getType(loginActions.doLogOut)) {
		return {
			isLoginOpen: false,
			loginForm: {
				...state.loginForm,
				isLoading: false,
				error: '',
			},
		};
	}

	if (action.type === getType(loginActions.requestRegisterUser)) {
		return {
			...state,
			registrationRequestForm: {
				...state.registrationRequestForm,
				isLoading: true,
				error: '',
			},
		};
	}

	if (action.type === getType(loginActions.requestRegisterUserSuccess)) {
		return {
			...state,
			isLoginOpen: true,
			registrationRequestForm: {
				...state.registrationRequestForm,
				isLoading: false,
				error: '',
			},
		};
	}

	if (action.type === getType(loginActions.requestRegisterUserError)) {
		return {
			...state,
			registrationRequestForm: {
				...state.registrationRequestForm,
				isLoading: false,
				error: 'component_login_registration_error_username_failed',
			},
		};
	}

	if (action.type === getType(loginActions.registerUser)) {
		return {
			...state,
			registrationForm: {
				...state.registrationForm,
				isLoading: true,
				error: '',
			},
		};
	}

	if (action.type === getType(loginActions.registerUserSuccess)) {
		return {
			...state,
			isLoginOpen: false,
			registrationForm: {
				...state.registrationForm,
				isLoading: false,
				error: '',
			},
		};
	}

	if (action.type === getType(loginActions.getUniqueUserName)) {
		return {
			...state,
			registrationForm: {
				...state.registrationForm,
				uniqueUserName: undefined,
				uniqueUserNameError: '',
				isLoading: false,
				error: '',
			},
		};
	}

	if (action.type === getType(loginActions.getUniqueUserNameSuccess)) {
		return {
			...state,
			registrationForm: {
				...state.registrationForm,
				uniqueUserName: action.payload,
				uniqueUserNameError: '',
				isLoading: false,
				error: '',
			},
		};
	}

	if (action.type === getType(loginActions.getUniqueUserNameError)) {
		return {
			...state,
			registrationForm: {
				...state.registrationForm,
				uniqueUserName: undefined,
				uniqueUserNameError: 'component_login_registration_error_username_unique',
				isLoading: false,
				error: '',
			},
		};
	}

	if (action.type === getType(loginActions.registerUserError)) {
		return {
			...state,
			registrationForm: {
				...state.registrationForm,
				isLoading: false,
				error: 'component_login_registration_error_account_failed',
			},
		};
	}

	if (action.type === getType(loginActions.resetPasswordSuccess)) {
		return {
			...state,
			isLoginOpen: false,
			resetPasswordForm: {
				...state.resetPasswordForm,
				isLoading: false,
				error: '',
			},
		};
	}

	if (action.type === getType(loginActions.resetPasswordError)) {
		return {
			...state,
			resetPasswordForm: {
				...state.resetPasswordForm,
				isLoading: false,
				error: 'component_login_password_reset_error',
			},
		};
	}

	if (action.type === getType(loginActions.forgotPassword)) {
		return {
			...state,
			forgotPasswordForm: {
				...state.forgotPasswordForm,
				isLoading: true,
				error: '',
			},
		};
	}

	if (action.type === getType(loginActions.forgotPasswordSuccess)) {
		return {
			...state,
			isLoginOpen: false,
			forgotPasswordForm: {
				...state.forgotPasswordForm,
				isLoading: false,
				error: '',
			},
		};
	}

	if (action.type === getType(loginActions.forgotPasswordError)) {
		return {
			...state,
			forgotPasswordForm: {
				...state.forgotPasswordForm,
				isLoading: false,
				error: 'component_login_forgot_password_error',
			},
		};
	}

	return state;
};
