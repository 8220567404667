import { default as baseTheme, themeColors as baseColors } from './valio';
import extend from './extend';
import { css } from 'styled-components';

const themeColors = {
	finlandiacheeseBlue: '#003DA5',
	finlandiacheeseBlue35: 'rgba(0, 61, 165, 0.35)',
	finlandiacheeseBlue90: 'rgba(0, 61, 165, 0.9)',
	finlandiacheeseBlue20: 'rgba(0, 61, 165, 0.2)',

	dustyGrey: '#979797',

	white: '#ffffff',
};

const themeFonts = {
	montserrat: 'Montserrat, Arial, sans-serif',
};

const finlandiacheese = extend(baseTheme, {
	colors: {
		adminToolbar: themeColors.finlandiacheeseBlue35,
		actionInfoText: themeColors.finlandiacheeseBlue,
		brandPrimary: themeColors.finlandiacheeseBlue,
		buttonPrimary: themeColors.finlandiacheeseBlue,
		buttonTertiary: themeColors.finlandiacheeseBlue,
		buttonTertiaryHover: themeColors.finlandiacheeseBlue90,

		buttonHighlightText: themeColors.white,
		buttonHighlightTextHover: themeColors.white,
		buttonHighlightBackground: themeColors.finlandiacheeseBlue,
		buttonHighlightBackgroundHover: themeColors.finlandiacheeseBlue,

		buttonOutlineText: themeColors.finlandiacheeseBlue,
		buttonOutlineBackground: themeColors.white,
		buttonOutlineBackgroundHover: themeColors.white,

		dropdownButtonTextClosed: themeColors.finlandiacheeseBlue,

		footerBackground: themeColors.finlandiacheeseBlue,
		framedHeaderBackground: themeColors.finlandiacheeseBlue,
		framedHeaderTitle: baseColors.white,
		heading: themeColors.finlandiacheeseBlue,
		linkText: themeColors.finlandiacheeseBlue,
		linkTextVisited: themeColors.finlandiacheeseBlue,
		navigationHeaderBackground: themeColors.finlandiacheeseBlue,
		navigationHeaderSecondaryBackground: themeColors.finlandiacheeseBlue,
		navigationBackground: themeColors.finlandiacheeseBlue,
		nutrientTitle: themeColors.finlandiacheeseBlue,
		eventFormBackground: themeColors.finlandiacheeseBlue,
		selectedItem: themeColors.finlandiacheeseBlue,
		selectedItemBackground: themeColors.finlandiacheeseBlue20,
		shadowBranded: themeColors.finlandiacheeseBlue35,
		shoppingListBlue: themeColors.finlandiacheeseBlue,
		stepActive: themeColors.finlandiacheeseBlue,
		tabActive: themeColors.finlandiacheeseBlue,
		tabInactive: themeColors.dustyGrey,
		tabInactiveHover: themeColors.dustyGrey,
		infoboxStrong: themeColors.finlandiacheeseBlue,
	},
	fonts: {
		primary: themeFonts.montserrat,
		secondary: themeFonts.montserrat,
		tertiary: themeFonts.montserrat,
		primaryButtons: themeFonts.montserrat,
		secondaryButtons: themeFonts.montserrat,
	},
	typography: {
		heading: css`
			font-family: ${themeFonts.montserrat};
			font-weight: 700;
		`,
		navigation: css`
			font-family: ${themeFonts.montserrat};
			font-weight: 700;
		`,
	},
	styledVariations: {
		heroBannerDefaultSize: 'medium',
		fullWidthHeroBanner: true,
		framedHeaderDefaultVariant: 'finlandiacheese',
		homePageHeroSize: 'large',
		siteLogoHeight: {
			desktop: 72,
			mobile: 45,
		},
	},
});

export default finlandiacheese;
