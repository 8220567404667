import { combineReducers, Reducer } from 'redux';
import { getType } from 'typesafe-actions';
import { productShoppingListReducer, ProductShoppingListActions } from './shopping-list';
import { relatedRecipesActions, RelatedRecipesActions, relatedRecipesReducer } from './product-recipes';
import { relatedProductsActions, RelatedProductsActions, relatedProductsReducer } from './product-related-products';
import { ProductApiContent, ProductState } from '../interfaces';
import { ResourceActions, resourceActions } from 'common/components/Resource/reducers/actions';
import { ResourceType } from 'common/components/Resource/types';

export const productActions = {
	// ...productShoppingListActions,
	...relatedRecipesActions,
	...relatedProductsActions,
};

export type ProductActions = RelatedRecipesActions | RelatedProductsActions | ProductShoppingListActions;

export const initialProductDetailsState: ProductApiContent = {
	id: '',
	name: '',
	heroOverlayColor: 'transparent',
	description: '',
	brands: [],
	groups: [],
	defaultPackageId: '',
	packages: [],
};

export const initialProductState: ProductState = {
	productDetails: initialProductDetailsState,
	shoppingList: { isLoading: false },
	relatedRecipes: { isLoading: false },
	relatedProducts: { isLoading: false },
};

export const productDetailsReducer: Reducer<ProductApiContent, ResourceActions> = (
	state = initialProductDetailsState,
	action
): ProductApiContent => {
	switch (action.type) {
		case getType(resourceActions.setResource):
			if (action.payload.resource.type === ResourceType.product) {
				return action.payload.resource.content;
			}

		default:
			return state;
	}
};

export const productReducer = combineReducers<ProductState>({
	shoppingList: productShoppingListReducer,
	productDetails: productDetailsReducer,
	relatedRecipes: relatedRecipesReducer,
	relatedProducts: relatedProductsReducer,
});
