import * as React from 'react';
import styled from 'styled-components';
import { getOffset } from '../../../helpers/offsets';

const defaultAnimationDuration = 750;

const Wrapper = styled.div``;

export interface AnimatedFlyingState {
	isFlying: boolean;
	left: string;
	top: string;
}

export interface AnimatedFlyIconButtonProps {
	shouldFly: boolean;
	targetSelector?: string;
	animationDuration?: number;
	animationIcon: React.ReactElement<HTMLElement>;
}

const StyledFlyingIndicator = styled('div')<AnimatedFlyingState & Partial<AnimatedFlyIconButtonProps>>`
	position: fixed;
	transition: all ease-in-out ${props => props.animationDuration || defaultAnimationDuration}ms;
	top: ${props => props.top};
	left: ${props => props.left};
	z-index: ${props => props.theme.zIndices.flyingThings};
	${props => (props.isFlying ? `` : `display: none;`)};
`;

class AnimatedFlyIconButton extends React.Component<
	AnimatedFlyIconButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>,
	AnimatedFlyingState
> {
	public static defaultProps = {
		animationDuration: defaultAnimationDuration,
		shouldFly: true,
	};

	public flyingIndicatorRefElement: React.RefObject<any>;

	constructor(props: AnimatedFlyIconButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>) {
		super(props);
		this.flyingIndicatorRefElement = React.createRef<any>();
		this.state = { isFlying: false, top: '', left: '' };
	}

	public render() {
		const childrenWithProps = React.Children.map(this.props.children, (child: React.ReactElement<any>) =>
			React.cloneElement(child, { onClick: this.buttonClicked })
		);

		const { shouldFly, animationDuration, animationIcon, className } = this.props;

		return (
			<Wrapper className={className}>
				{childrenWithProps}

				<StyledFlyingIndicator
					isFlying={this.state.isFlying}
					left={this.state.left}
					top={this.state.top}
					shouldFly={shouldFly}
					animationDuration={animationDuration}
					ref={this.setFlyingIndicatorRef}>
					{animationIcon}
				</StyledFlyingIndicator>
			</Wrapper>
		);
	}

	private setFlyingIndicatorRef = (ref: any) => (this.flyingIndicatorRefElement = ref);

	private buttonClicked = (e: React.MouseEvent<HTMLButtonElement>) => {
		const { onClick, shouldFly } = this.props;

		if (shouldFly) {
			this.tryToFlyIndicator(e);
		}

		if (onClick) {
			onClick(e);
		}
	};

	private tryToFlyIndicator = (e: React.MouseEvent<HTMLButtonElement>) => {
		const { targetSelector } = this.props;
		const target = document.querySelector(targetSelector || '[data-flying-indicator-target]');

		if (target && !this.state.isFlying) {
			this.showFlyingIndicator(e.currentTarget, target);
		}
	};

	private showFlyingIndicator = (source: Element, target: Element) => {
		const indicatorElement = this.flyingIndicatorRefElement as any;

		const sourceOffset = getOffset(source);
		const targetOffset = getOffset(target);

		this.setState({
			isFlying: true,
			left: `${sourceOffset.left + 18}px`,
			top: `${sourceOffset.top + 8}px`,
		});

		// * Made up timeout to fix icon flight on slower devices like cellphones and such
		setTimeout(() => {
			indicatorElement.style.top = `${targetOffset.top + 7}px`;
			indicatorElement.style.left = `${targetOffset.left + 7}px`;
			setTimeout(this.resetState, this.props.animationDuration || defaultAnimationDuration);
		}, 250);
	};

	private resetState = () => {
		this.setState({
			...this.state,
			isFlying: false,
		});

		const indicatorElement = this.flyingIndicatorRefElement as any;
		if (indicatorElement) {
			indicatorElement.removeAttribute('style');
		}
	};
}
export default AnimatedFlyIconButton;
