import { createCustomAction, createStandardAction, getType, ActionType } from 'typesafe-actions';
import { Reducer } from 'redux';
import { ResourceActions, resourceActions } from 'common/components/Resource/reducers/actions';
import { ResourceType } from 'common/components/Resource/types';
import { ProductShoppingListState } from '../interfaces';

export const productShoppingListActions = {
	addToShoppingList: createCustomAction('PRODUCT/ADD_TO_SHOPPING_LIST', type => (productId: string) => ({
		type,
		payload: { productId },
	})),

	addSucceeded: createStandardAction('PRODUCT/ADD_TO_SHOPPING_LIST_SUCCESS')(),

	addFailed: createStandardAction('PRODUCT/ADD_TO_SHOPPING_LIST_FAILED')<string>(),
};

export const productShoppingListReducer: Reducer<
	ProductShoppingListState,
	ProductShoppingListActions | ResourceActions
> = (state = { isLoading: false }, action): ProductShoppingListState => {
	switch (action.type) {
		case getType(resourceActions.setResource):
			if (action.payload.resource.type === ResourceType.product) {
				return {
					...state,
				};
			}

		case getType(productShoppingListActions.addToShoppingList):
			return { ...state, isLoading: true };

		case getType(productShoppingListActions.addSucceeded):
			return {
				...state,
				isLoading: false,
			};

		case getType(productShoppingListActions.addFailed):
			return { ...state, isLoading: false, error: action.payload };

		default:
			return state;
	}
};

export type ProductShoppingListActions = ActionType<typeof productShoppingListActions>;
