import { fetchJson, throwMissingSiteUrlIdError } from 'utils/service';

export interface ArticleSearchResultItem {
	title: string;
	url: string;
}

export interface ArticleSearchResult {
	count: number;
	items: ArticleSearchResultItem[];
}

async function searchArticles(siteUrlId: string, searchText: string, maxResults: number): Promise<ArticleSearchResult> {
	if (!siteUrlId) {
		throwMissingSiteUrlIdError();
	}
	return await fetchJson<ArticleSearchResult>(
		`/api/sites/${siteUrlId}/siteSearch/article?searchWord=${encodeURIComponent(
			searchText
		)}&numberOfItems=${maxResults}`
	);
}

const api = {
	searchArticles,
};

export type ArticleSearchApi = typeof api;
export { api as articleSearchApi };
