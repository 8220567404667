import { fetchJson, throwMissingSiteUrlIdError } from 'utils/service';

export interface RecipeSearchResultItem {
	title: string;
	url: string;
}

export interface RecipeSearchResult {
	count: number;
	items: RecipeSearchResultItem[];
}

async function searchRecipes(
	siteUrlId: string,
	searchText: string,
	maxResults: number,
	isProfessional?: boolean
): Promise<RecipeSearchResult> {
	const isProfessionalParam = isProfessional ? 'true' : 'false';
	if (!siteUrlId) {
		throwMissingSiteUrlIdError();
	}
	return await fetchJson<RecipeSearchResult>(
		`/api/sites/${siteUrlId}/siteSearch/recipe?searchWord=${encodeURIComponent(
			searchText
		)}&numberOfItems=${maxResults}&professional=${isProfessionalParam}`
	);
}

const api = {
	searchRecipes,
};

export type RecipeSearchApi = typeof api;
export { api as recipeSearchApi };
