import { fork, put, call, takeLatest } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { UserApi, userApi } from '../services/services';
import { UserApiShape } from '../interfaces';
import { userActions } from '../reducers/actions';
import { productShoppingListActions } from '../../Product/reducers/shopping-list';
import { recipeShoppingListActions } from '../../Recipe/reducers/shopping-list';

export function* fetchCurrentUser(api: UserApi) {
	try {
		const result: UserApiShape = yield call(api.fetchCurrentUser);

		yield put(userActions.setCurrentUser(result));
	} catch (e) {
		yield put(userActions.fetchCurrentUserError());
	}
}

export function* watchFetchCurrentUserSaga() {
	yield takeLatest(getType(userActions.fetchCurrentUser), fetchCurrentUser, userApi);
}

export function* updateCurrentUserItemsInMemory(api: UserApi) {
	try {
		const result: UserApiShape = yield call(api.fetchCurrentUser);
		yield put(userActions.updateCurrentUserItemsInMemory(result));
	} catch (e) {
		yield put(userActions.fetchCurrentUserError());
	}
}

export function* watchUpdateCurrentUserItemsInMemory() {
	yield takeLatest(getType(userActions.emptyShoppingListSuccess), updateCurrentUserItemsInMemory, userApi);
	yield takeLatest(getType(userActions.deleteShoppingListItemSucceeded), updateCurrentUserItemsInMemory, userApi);
	yield takeLatest(getType(userActions.addShoppingListItemSuccess), updateCurrentUserItemsInMemory, userApi);
	yield takeLatest(getType(productShoppingListActions.addSucceeded), updateCurrentUserItemsInMemory, userApi);
	yield takeLatest(getType(userActions.addRecipeSucceeded), updateCurrentUserItemsInMemory, userApi);
	yield takeLatest(getType(userActions.removeRecipeSucceeded), updateCurrentUserItemsInMemory, userApi);
	yield takeLatest(getType(recipeShoppingListActions.addSucceeded), updateCurrentUserItemsInMemory, userApi);
	yield takeLatest(getType(recipeShoppingListActions.removeSucceeded), updateCurrentUserItemsInMemory, userApi);
}

export function* currentUserSaga() {
	yield fork(watchFetchCurrentUserSaga);
	yield fork(watchUpdateCurrentUserItemsInMemory);
}
