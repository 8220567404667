import * as React from 'react';
import SocialIconWrapper from '../SocialIconWrapper';
import { IconWrapperProps } from '../../../../interfaces';
import { ThemeContext } from 'styled-components';

const InstagramIcon: React.FC<IconWrapperProps & React.SVGAttributes<SVGElement>> = ({ color, ...props }) => {
	const theme = React.useContext(ThemeContext);
	return (
		<SocialIconWrapper alt="Instagram" {...props}>
			<g fill={color ?? theme.colors.socialIcon}>
				<path d="M14.957 0c-4.062 0-4.572.017-6.167.09-1.592.073-2.679.325-3.63.695A7.332 7.332 0 0 0 2.51 2.51 7.33 7.33 0 0 0 .785 5.16C.415 6.11.163 7.198.09 8.79.017 10.385 0 10.895 0 14.957c0 4.062.017 4.571.09 6.166.073 1.592.325 2.68.695 3.63a7.331 7.331 0 0 0 1.725 2.65 7.328 7.328 0 0 0 2.65 1.725c.951.37 2.038.623 3.63.695 1.595.073 2.105.09 6.167.09 4.062 0 4.571-.017 6.166-.09 1.592-.072 2.68-.325 3.63-.695a7.332 7.332 0 0 0 2.65-1.725 7.33 7.33 0 0 0 1.725-2.65c.37-.95.623-2.038.695-3.63.073-1.595.09-2.104.09-6.166s-.017-4.572-.09-6.167c-.072-1.592-.325-2.68-.695-3.63a7.332 7.332 0 0 0-1.725-2.65 7.33 7.33 0 0 0-2.65-1.725c-.95-.37-2.038-.622-3.63-.695C19.528.017 19.02 0 14.957 0m0 2.695c3.993 0 4.466.015 6.043.087 1.459.067 2.25.31 2.778.515a4.636 4.636 0 0 1 1.72 1.119 4.635 4.635 0 0 1 1.118 1.72c.205.527.449 1.319.515 2.777.072 1.577.087 2.05.087 6.044 0 3.993-.015 4.466-.087 6.043-.066 1.459-.31 2.25-.515 2.778a4.637 4.637 0 0 1-1.118 1.72 4.635 4.635 0 0 1-1.72 1.118c-.527.205-1.32.449-2.777.515-1.577.072-2.05.087-6.044.087-3.994 0-4.467-.015-6.044-.087-1.458-.066-2.25-.31-2.777-.515a4.636 4.636 0 0 1-1.72-1.119 4.635 4.635 0 0 1-1.119-1.72c-.205-.526-.448-1.318-.515-2.777-.072-1.577-.087-2.05-.087-6.043 0-3.994.015-4.467.087-6.044.067-1.458.31-2.25.515-2.777a4.636 4.636 0 0 1 1.119-1.72 4.635 4.635 0 0 1 1.72-1.119c.527-.205 1.319-.448 2.777-.515 1.577-.072 2.05-.087 6.044-.087" />
				<path d="M14.957 19.942a4.986 4.986 0 1 1 0-9.97 4.986 4.986 0 0 1 0 9.97m0-12.666a7.68 7.68 0 1 0 0 15.361 7.68 7.68 0 0 0 0-15.36M22.94 5.18a1.795 1.795 0 1 1 0 3.59 1.795 1.795 0 0 1 0-3.59" />
			</g>
		</SocialIconWrapper>
	);
};

export default InstagramIcon;
