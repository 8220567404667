import * as React from 'react';
import styled, { useTheme } from 'styled-components';
import { media } from 'styleguide/helpers/media';
import { generate } from 'shortid';
import { SiteId, addSiteUrlPrefix } from '../App/services';
import MediumHeader from 'styleguide/components/Typography/MediumHeader/MediumHeader';
import ButtonLink from 'styleguide/components/Buttons/Button/ButtonLink';
import { OutlinedButtonLink } from 'styleguide/components/Buttons/OutlinedButton';
import { FormattedMessage } from 'react-intl';
import { useStatusCode } from 'common/contexts/StatusCodeContext';
import { get404LinksConfig } from './404Config';

interface NotFoundWrapperProps {
	useNarrowTopMargin: boolean;
}

interface LinkWrapperProps {
	hasSolidLinks: boolean;
}

const NotFoundWrapper = styled.section<NotFoundWrapperProps>`
	display: block;
	padding: 0 ${({ theme }) => theme.grid.gutterInPx(1)};
	margin: ${({ theme }) => theme.grid.gutterInPx(8)} 0;
	
	> svg {
		margin: auto;
		display: block;
		width: 303px;
		height: 104px;
	}

	> h1 {
		margin: ${({ theme }) => theme.grid.gutterInPx(3.5)} 0 0;
		text-align: center;
	}

	> p {
		margin: ${({ theme }) => theme.grid.gutterInPx(3.5)} 0 ${({ theme }) => theme.grid.gutterInPx(1)};
		text-align: center;
		font-size: 18px;
		line-height: 1.33;
		font-family: ${props => props.theme.fonts.secondary};
	}

	${media.tablet<NotFoundWrapperProps>`
		display: block;
		height: 100%;
		margin-top: ${({ theme, useNarrowTopMargin }) =>
			useNarrowTopMargin ? theme.grid.gutterInPx(8) : theme.grid.gutterInPx(16)};
		
		> p {
			margin: ${({ theme }) => theme.grid.gutterInPx(5)} 0 ${({ theme }) => theme.grid.gutterInPx(1)};
		}
	`}

	${media.desktop900<NotFoundWrapperProps>`
		margin-top: ${({ theme, useNarrowTopMargin }) =>
			useNarrowTopMargin ? theme.grid.gutterInPx(16) : theme.grid.gutterInPx(24)};
	`}
	
	${media.desktop1200<NotFoundWrapperProps>`
		margin-top: ${({ theme, useNarrowTopMargin }) =>
			useNarrowTopMargin ? theme.grid.gutterInPx(16) : theme.grid.gutterInPx(32)};

		> svg {
			width: 464px;
			height: 160px;
		}
	`}	
`;

const NotFoundLinksWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	padding: 0;

	a {
		margin: ${({ theme }) => theme.grid.gutterInPx(1)};
	}
	a:first-child {
		display: flex;
	}
`;

const NotFoundOutlinedLinksWrapper = styled.div<LinkWrapperProps>`
	display: none;

	${media.tablet<LinkWrapperProps>`
		display: ${props => (!props.hasSolidLinks ? 'flex' : 'none')};

		a {
			display: flex;
		}
	`};
`;

const NotFoundSolidLinksWrapper = styled.div<LinkWrapperProps>`
	${media.tablet<LinkWrapperProps>`
		display: ${props => (props.hasSolidLinks ? 'flex' : 'none')};

		a {
			display: ${props => (props.hasSolidLinks ? 'flex' : 'none')};
		}
	`}
`;

interface Props {
	siteId?: SiteId;
	urlPrefix: string;
}

export const FourOhFour: React.FC<Props> = ({ siteId, urlPrefix }) => {
	useStatusCode(404);

	const theme = useTheme();
	const useNarrowTopMargin = siteId !== SiteId.fi;
	const site404Config = siteId && get404LinksConfig(siteId);

	const svgColors = {
		primaryColor: theme.colors.brandPrimary,
		borderColor: theme.colors.component404SvgBorder,
		highlightColorWhite: theme.colors.white,
		accentVeryLight: theme.colors.component404SvgAccentVeryLight,
		accentLight: theme.colors.component404SvgAccentLight,
		accentSpoon: theme.colors.component404SvgAccentSpoon,
	};

	return (
		<NotFoundWrapper useNarrowTopMargin={useNarrowTopMargin}>
			<svg width="464" height="161" viewBox="0 0 464 161" fill="none" xmlns="http://www.w3.org/2000/svg">
				<g clipPath="url(#clip0_146_371)">
					<path
						d="M232.065 157.238C275.186 157.238 310.143 122.281 310.143 79.1597C310.143 36.0383 275.186 1.08142 232.065 1.08142C188.943 1.08142 153.987 36.0383 153.987 79.1597C153.987 122.281 188.943 157.238 232.065 157.238Z"
						fill={svgColors.primaryColor}
					/>
					<path
						d="M232.086 2.16287C247.315 2.16287 262.202 6.67864 274.864 15.1392C287.526 23.5997 297.395 35.625 303.222 49.6943C309.05 63.7636 310.575 79.2452 307.604 94.1811C304.633 109.117 297.3 122.837 286.532 133.605C275.763 144.373 262.044 151.706 247.108 154.677C232.172 157.648 216.69 156.123 202.621 150.296C188.552 144.468 176.526 134.599 168.066 121.937C159.605 109.275 155.09 94.3883 155.09 79.1598C155.09 58.7389 163.202 39.1544 177.641 24.7147C192.081 10.275 211.666 2.16287 232.086 2.16287ZM232.086 3.75512e-05C213.772 -0.00173878 196.024 6.34688 181.866 17.9642C167.707 29.5814 158.016 45.7485 154.441 63.7107C150.867 81.6729 153.632 100.319 162.264 116.471C170.896 132.623 184.862 145.283 201.782 152.292C218.702 159.302 237.529 160.227 255.055 154.912C272.581 149.596 287.721 138.368 297.896 123.14C308.072 107.913 312.652 89.6282 310.857 71.402C309.063 53.1758 301.004 36.1358 288.053 23.1856C280.721 15.8124 271.999 9.96667 262.392 5.98686C252.786 2.00705 242.485 -0.0277945 232.086 3.75512e-05Z"
						fill={svgColors.borderColor}
					/>
					<path
						d="M232.065 140.541C265.965 140.541 293.446 113.06 293.446 79.1597C293.446 45.2598 265.965 17.7784 232.065 17.7784C198.165 17.7784 170.684 45.2598 170.684 79.1597C170.684 113.06 198.165 140.541 232.065 140.541Z"
						fill={svgColors.highlightColorWhite}
						stroke={svgColors.borderColor}
						strokeWidth="3"
						strokeMiterlimit="10"
					/>
					<path
						opacity="0.7"
						d="M275.949 37.3882L253.6 62.0878C251.78 64.0858 249.574 65.6947 247.116 66.8172C244.658 67.9398 241.998 68.5529 239.296 68.6195C233.952 68.7951 228.886 71.0448 225.173 74.8917C217.834 82.231 216.774 93.0812 222.808 99.1155C228.842 105.15 239.693 104.09 247.032 96.7508C250.881 93.0384 253.134 87.9726 253.311 82.6275C253.372 79.9224 253.982 77.2578 255.104 74.7954C256.225 72.333 257.835 70.124 259.836 68.3023L282.906 46.7677C280.861 43.4472 278.533 40.3091 275.949 37.3882Z"
						fill={svgColors.accentVeryLight}
					/>
					<path
						d="M232.087 6.87781C246.376 6.88351 260.343 11.1258 272.222 19.0684C284.101 27.011 293.358 38.2972 298.824 51.5003C304.289 64.7033 305.717 79.2304 302.928 93.2451C300.138 107.26 293.256 120.133 283.151 130.236C273.046 140.34 260.173 147.221 246.158 150.009C232.143 152.798 217.616 151.368 204.413 145.901C191.211 140.435 179.925 131.176 171.984 119.297C164.043 107.417 159.802 93.4493 159.797 79.1597C159.795 69.6661 161.662 60.265 165.294 51.4936C168.926 42.7222 174.251 34.7525 180.964 28.0398C187.678 21.3271 195.648 16.0031 204.42 12.3721C213.192 8.74099 222.593 6.87402 232.087 6.87781ZM232.087 6.15686C217.652 6.15259 203.541 10.4287 191.537 18.4444C179.533 26.4601 170.175 37.8555 164.647 51.1894C159.12 64.5233 157.67 79.197 160.482 93.3548C163.294 107.513 170.24 120.519 180.444 130.728C190.648 140.938 203.65 147.893 217.806 150.713C231.962 153.533 246.636 152.092 259.974 146.572C273.311 141.052 284.712 131.702 292.735 119.702C300.757 107.703 305.042 93.594 305.046 79.1597C305.029 59.8109 297.338 41.2589 283.66 27.5732C269.983 13.8874 251.435 6.18549 232.087 6.15686Z"
						fill={svgColors.highlightColorWhite}
					/>
					<path
						d="M232.086 12.4507C245.28 12.4507 258.178 16.3631 269.148 23.6932C280.118 31.0233 288.669 41.4418 293.718 53.6313C298.767 65.8207 300.088 79.2337 297.514 92.174C294.94 105.114 288.586 117.001 279.257 126.33C269.927 135.66 258.041 142.013 245.101 144.587C232.16 147.161 218.748 145.84 206.558 140.791C194.369 135.742 183.95 127.192 176.62 116.221C169.29 105.251 165.377 92.3535 165.377 79.1597C165.377 61.4674 172.406 44.4997 184.916 31.9893C197.426 19.479 214.394 12.4507 232.086 12.4507ZM232.086 11.7297C218.75 11.7297 205.713 15.6845 194.624 23.0938C183.536 30.5031 174.893 41.0341 169.789 53.3554C164.686 65.6766 163.35 79.2345 165.952 92.3146C168.554 105.395 174.976 117.41 184.406 126.84C193.837 136.27 205.851 142.692 218.932 145.294C232.012 147.896 245.57 146.56 257.891 141.457C270.212 136.353 280.743 127.711 288.152 116.622C295.562 105.533 299.516 92.4961 299.516 79.1597C299.495 61.2826 292.384 44.1437 279.743 31.5027C267.102 18.8617 249.964 11.7507 232.086 11.7297Z"
						fill={svgColors.highlightColorWhite}
					/>
					<path
						opacity="0.7"
						d="M183.783 87.8399C181.253 61.7417 200.574 72.1954 200.502 60.3647C200.3 30.7051 224.012 26.4083 245.2 26.4083C246.707 26.4083 248.2 26.4876 249.678 26.5885C238.081 22.6892 225.521 22.735 213.954 26.719C202.386 30.7029 192.462 38.4011 185.726 48.6141C178.99 58.8271 175.822 70.9813 176.714 83.1829C177.607 95.3845 182.511 106.948 190.661 116.072C186.307 107.615 184.8 97.9764 183.783 87.8399Z"
						fill={svgColors.accentVeryLight}
					/>
					<path
						d="M104.292 157.238C96.3615 157.238 88.2148 157.238 88.2148 147.866V123.901H20.1648C9.46601 123.901 1.08142 116.807 1.08142 107.745C1.08142 101.257 3.75613 95.6693 10.0211 89.1592L83.0024 14.181C90.4281 6.48127 95.8929 2.81165 106.794 2.81165C117.363 2.81165 125.639 10.0932 125.639 19.3934V95.2007H140.649C143.627 95.2007 145.775 95.9217 147.231 97.3636C149.675 99.8148 149.661 103.852 149.646 108.502V110.809C149.646 115.387 149.646 119.338 147.282 121.739C145.84 123.181 143.677 123.901 140.649 123.901H125.668V147.866C125.668 157.238 117.017 157.238 109.353 157.238H104.292ZM88.2148 95.2368V52.701L47.193 95.2368H88.2148Z"
						fill={svgColors.primaryColor}
					/>
					<path
						d="M106.822 3.92199C116.663 3.92199 124.586 10.7133 124.586 19.4223V96.275H140.678C149.077 96.275 148.608 102.216 148.608 109.649C148.608 117.081 149.091 122.813 140.678 122.813H124.586V147.859C124.586 155.789 117.99 156.149 109.353 156.149H104.306C95.7054 156.149 89.3034 155.803 89.3034 147.859V122.813H20.1648C10.3239 122.813 2.14121 116.44 2.14121 107.738C2.14121 100.94 5.25569 95.6333 10.7925 89.9018L83.7666 14.9236C90.7309 7.71412 96.0226 3.88591 106.837 3.88591L106.822 3.92199ZM44.6409 96.275H89.289V49.9903L44.6409 96.275ZM106.822 1.75916C95.5684 1.75916 89.7072 5.70271 82.2165 13.4601L9.24972 88.4383C2.76122 95.1719 0 100.954 0 107.781C0 117.449 8.8532 125.019 20.1865 125.019H87.155V147.902C87.155 158.356 96.6715 158.356 104.321 158.356H109.367C117.117 158.356 126.764 158.356 126.764 147.902V125.019H140.692C144.009 125.019 146.46 124.212 148.089 122.539C150.8 119.821 150.786 115.661 150.771 110.845V108.531C150.771 103.83 150.771 99.3967 148.039 96.621C146.373 94.9484 143.973 94.141 140.692 94.141H126.749V19.4223C126.749 9.51652 117.997 1.75916 106.822 1.75916ZM49.7452 94.1122L87.1334 55.3469V94.1122H49.7452Z"
						fill={svgColors.borderColor}
					/>
					<path
						d="M254.039 64.6977L307.62 14.7218C308.491 13.9076 309.137 12.883 309.497 11.7465C309.857 10.6101 309.918 9.40019 309.675 8.23326C309.396 6.9198 308.742 5.71537 307.793 4.76591C306.843 3.81645 305.639 3.16273 304.325 2.88386C303.153 2.63537 301.936 2.69394 300.793 3.05393C299.65 3.41392 298.619 4.06308 297.801 4.93853L247.825 58.5191C246.003 60.5098 243.797 62.1114 241.34 63.2277C238.883 64.344 236.226 64.9519 233.528 65.0149C228.184 65.1904 223.119 67.4401 219.405 71.2871C212.066 78.6335 211.006 89.4765 217.04 95.5108C223.075 101.545 233.925 100.485 241.264 93.1461C245.114 89.4337 247.366 84.3679 247.544 79.0228C247.601 76.3198 248.206 73.6566 249.323 71.1943C250.439 68.732 252.044 66.5219 254.039 64.6977Z"
						fill={svgColors.accentLight}
						stroke={svgColors.primaryColor}
						strokeWidth="3"
						strokeMiterlimit="10"
					/>
					<path
						d="M229.397 83.7089C229.398 80.4348 230.373 77.2348 232.197 74.5159C234.021 71.7969 236.613 69.6818 239.642 68.4393C237.656 69.0119 235.602 69.315 233.536 69.3405C229.32 69.516 225.338 71.326 222.433 74.3871C219.688 77.0379 217.941 80.5544 217.487 84.3434C217.17 87.6093 218.086 90.5002 220.076 92.49C220.99 93.3698 222.072 94.0573 223.257 94.5117C224.442 94.9662 225.707 95.1784 226.975 95.1359C229.001 95.1181 231.001 94.6735 232.844 93.831C230.599 90.935 229.387 87.3726 229.397 83.7089Z"
						fill={svgColors.accentSpoon}
					/>
					<path
						d="M242.108 67.5742C241.963 67.6391 241.819 67.704 241.675 67.7545L242.173 67.6463L242.108 67.5742Z"
						fill={svgColors.accentSpoon}
					/>
					<path
						d="M305.45 9.09835C305.344 8.60294 305.097 8.1487 304.739 7.7905C304.381 7.43229 303.927 7.18546 303.431 7.07972C303.251 7.03914 303.068 7.01977 302.883 7.02203C302.524 7.02324 302.169 7.09733 301.839 7.23984C301.51 7.38235 301.212 7.59029 300.966 7.85109L247.789 62.3906C247.639 62.5441 247.524 62.7267 247.448 62.927C247.373 63.1273 247.339 63.341 247.35 63.5548C247.361 63.7686 247.416 63.9779 247.511 64.1695C247.606 64.3612 247.74 64.5311 247.904 64.6687C248.199 64.9114 248.572 65.0375 248.954 65.0228C249.335 65.0082 249.698 64.854 249.973 64.5894L304.671 11.5784C305.005 11.2678 305.252 10.876 305.388 10.4412C305.525 10.0065 305.546 9.54377 305.45 9.09835Z"
						fill={svgColors.accentSpoon}
					/>
					<path
						opacity="0.7"
						d="M247.825 113.39C247.505 112.747 247.02 112.201 246.419 111.808C245.818 111.415 245.123 111.188 244.406 111.152C243.689 111.116 242.975 111.272 242.338 111.603C241.701 111.934 241.164 112.429 240.781 113.037C240.623 113.3 240.497 113.58 240.406 113.873C239.397 117.117 238.683 119.85 235.446 119.85C232.209 119.85 232.101 125.221 235.446 125.221C238.791 125.221 244.559 125.524 244.559 128.256C244.559 130.988 250.874 131.601 250.874 124.406C250.874 120.218 249.216 116.101 247.825 113.39Z"
						fill={svgColors.accentVeryLight}
					/>
					<path
						opacity="0.7"
						d="M220.407 36.0112C219.793 36.3913 219.253 36.8798 218.814 37.4531C217.516 39.1834 217.25 41.4832 219.412 42.5646L221.323 43.1126C226.622 44.0065 229.506 46.8903 230.948 51.216C231.668 51.9369 233.11 52.6579 233.831 51.9369C236.715 46.1694 231.668 41.1227 238.878 37.518C238.121 36.389 237.297 35.3057 236.412 34.2738C235.815 33.7862 235.159 33.3768 234.459 33.0554C230.378 31.1953 225.403 32.6588 221.719 35.2903C221.272 35.5282 220.818 35.7733 220.407 36.0112Z"
						fill={svgColors.accentVeryLight}
					/>
					<path
						opacity="0.7"
						d="M236.83 114.717C236.614 120.484 227.963 118.321 225.8 114.717C225.531 114.115 225.456 113.445 225.585 112.799C225.714 112.153 226.041 111.564 226.521 111.112C228.199 110.142 230.188 109.861 232.07 110.326C233.952 110.791 235.58 111.967 236.614 113.606C236.745 113.963 236.818 114.338 236.83 114.717Z"
						fill={svgColors.accentVeryLight}
					/>
					<path
						opacity="0.7"
						d="M195.138 91.113C187.013 90.839 186.905 80.1474 192.254 80.0897C197.222 79.1164 195.441 85.2229 195.996 89.6567C195.968 89.9474 195.877 90.2284 195.729 90.4801C195.58 90.7317 195.379 90.9477 195.138 91.113Z"
						fill={svgColors.accentVeryLight}
					/>
					<path
						d="M417.535 158.997C409.605 158.997 401.451 158.997 401.451 149.625V125.661H333.401C322.702 125.661 314.318 118.567 314.318 109.511C314.318 102.987 316.992 97.4285 323.264 90.9256L396.238 15.9473C403.664 8.24761 409.129 4.58521 420.03 4.58521C430.599 4.58521 438.875 11.8667 438.875 21.1669V96.931H453.885C456.863 96.931 459.018 97.6519 460.467 99.0938C462.919 101.545 462.904 105.582 462.883 110.232V112.539C462.883 117.11 462.883 121.061 460.518 123.469C459.076 124.911 456.913 125.632 453.885 125.632H438.904V149.625C438.904 158.997 430.253 158.997 422.589 158.997H417.535ZM401.451 96.9599V54.4241L360.436 96.9599H401.451Z"
						fill={svgColors.primaryColor}
					/>
					<path
						d="M420.058 5.65942C429.899 5.65942 437.823 12.4507 437.823 21.1525V98.0124H453.914C462.313 98.0124 461.844 103.96 461.844 111.393C461.844 118.826 462.327 124.55 453.914 124.55H437.823V149.625C437.823 157.555 431.226 157.916 422.589 157.916H417.542C408.941 157.916 402.539 157.57 402.539 149.625V124.579H333.401C323.56 124.579 315.377 118.213 315.377 109.511C315.377 102.713 318.499 97.4068 324.029 91.6753L397.003 16.6971C403.96 9.48763 409.259 5.65942 420.073 5.65942H420.058ZM357.891 98.0124H402.532V51.7278L357.891 98.0124ZM420.058 3.49658C408.804 3.49658 402.943 7.43293 395.46 15.1975L322.493 90.1325C316.005 96.8733 313.236 102.655 313.236 109.483C313.236 119.143 322.096 126.713 333.423 126.713H400.391V149.625C400.391 160.079 409.908 160.079 417.557 160.079H422.603C430.354 160.079 440 160.079 440 149.625V126.742H453.928C457.245 126.742 459.696 125.935 461.325 124.269C464.043 121.544 464.029 117.384 464.007 112.568V110.268C464.007 105.575 464.043 101.134 461.275 98.3657C459.609 96.6931 457.209 95.8784 453.928 95.8784H439.985V21.1525C439.985 11.2539 431.233 3.49658 420.058 3.49658ZM362.981 95.8496L400.369 57.0844V95.8496H362.981Z"
						fill={svgColors.borderColor}
					/>
				</g>
				<defs>
					<clipPath id="clip0_146_371">
						<rect width="464" height="160.05" fill={svgColors.highlightColorWhite} />
					</clipPath>
				</defs>
			</svg>

			<MediumHeader tagName="h1" greyed>
				<FormattedMessage id="resource_404_title" />
			</MediumHeader>
			<p>
				<FormattedMessage id="resource_404_description" />
			</p>

			<NotFoundLinksWrapper>
				{site404Config ? (
					<>
						<NotFoundSolidLinksWrapper hasSolidLinks={site404Config.hasSolidLinks}>
							{/* Mobile & Oddlygood */}
							{site404Config.links.map(l => (
								<ButtonLink key={generate()} href={addSiteUrlPrefix(urlPrefix, l.url)}>
									<FormattedMessage id={l.title} />
								</ButtonLink>
							))}
						</NotFoundSolidLinksWrapper>
						{!site404Config.hasSolidLinks && (
							<NotFoundOutlinedLinksWrapper hasSolidLinks={site404Config.hasSolidLinks}>
								{/* Rest */}
								{site404Config.links.map(l => (
									<OutlinedButtonLink key={generate()} href={addSiteUrlPrefix(urlPrefix, l.url)}>
										<FormattedMessage id={l.title} />
									</OutlinedButtonLink>
								))}
							</NotFoundOutlinedLinksWrapper>
						)}
					</>
				) : (
					<NotFoundSolidLinksWrapper hasSolidLinks={false}>
						<ButtonLink href={urlPrefix}>
							<FormattedMessage id="resource_to_home_page" />
						</ButtonLink>
					</NotFoundSolidLinksWrapper>
				)}
			</NotFoundLinksWrapper>
		</NotFoundWrapper>
	);
};

export default FourOhFour;
