import * as React from 'react';
import styled, { css } from 'styled-components';
import { ResponsiveValues, getDisplayValue } from 'common/components/Media';
import { media } from 'styleguide/helpers';

export enum DividerSize {
	desktop = 'desktop',
	desktopIngress = 'desktop-ingress',
	desktopMini = 'desktop-mini',
	mobile = 'mobile',
	full = 'full',
}

export type DividerSizes =
	| DividerSize.desktop
	| DividerSize.desktopIngress
	| DividerSize.desktopMini
	| DividerSize.mobile
	| DividerSize.full;

export enum DividerDirection {
	horizontal = 'horizontal',
	vertical = 'vertical',
}

export type DividerDirections = DividerDirection.horizontal | DividerDirection.vertical;

export interface DividerProps {
	size: DividerSizes | ResponsiveValues<DividerSizes>;
	direction: DividerDirections;
	stroke?: number;
	color?: string;
	isVisible?: ResponsiveValues<boolean>;
}

const getDividerSize = (size?: string) => {
	const dividerWidths = {
		desktop: '1200px',
		'desktop-ingress': '980px',
		'desktop-mini': '300px',
		mobile: '335px',
		full: '100%',
	};
	return size ? dividerWidths[size] : dividerWidths.full;
};

const getSize = (isHorizontal: boolean, stroke: number = 2, size?: string) => {
	return isHorizontal ? `${stroke}px` : getDividerSize(size);
};

const StyledDivider = styled.div<DividerProps>`
	background: ${({ theme, color }) => (color ? color : theme.colors.borderDark)};

	${({ size, direction, isVisible, stroke }) => {
		if (typeof size === 'object') {
			const sizeMap = size as ResponsiveValues<DividerSizes>;

			return css`
				${Object.keys(sizeMap).map(key => {
					if (key === 'default') {
						return css`
							height: ${getSize(direction === 'horizontal', stroke, sizeMap.default)};
							width: ${getSize(direction !== 'horizontal', stroke, sizeMap.default)};
						`;
					}
					return css`
						${media[key]`
							height: ${getSize(direction === 'horizontal', stroke, sizeMap[key])};
							width: ${getSize(direction !== 'horizontal', stroke, sizeMap[key])};
						`};
					`;
				})};
			`;
		}

		const visibilityMap = (isVisible as ResponsiveValues<boolean>) ?? {};

		return css`
			height: ${getSize(direction === 'horizontal', stroke, size)};
			width: ${getSize(direction !== 'horizontal', stroke, size)};

			${Object.keys(visibilityMap).map(key => {
				if (key === 'default') {
					return css`
						display: ${getDisplayValue(visibilityMap.default)};
					`;
				}
				return css`
					${media[key]`
						display: ${getDisplayValue(visibilityMap[key])};
					`};
				`;
			})};
		`;
	}};
`;

const Divider: React.FC<DividerProps & React.HTMLAttributes<HTMLDivElement>> = ({
	size,
	direction,
	isVisible,
	stroke,
	color,
	...rest
}) => {
	return (
		<StyledDivider size={size} direction={direction} isVisible={isVisible} stroke={stroke} color={color} {...rest} />
	);
};

Divider.defaultProps = {
	stroke: 2,
};

export default Divider;
