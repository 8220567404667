import { MediaBreakpoints, media } from 'styleguide/helpers';
import { DisplayType, ToggleType } from './interfaces';
import { css } from 'styled-components';

export const toggleMedia = (
	displayType: DisplayType,
	toggleType: ToggleType,
	breakpoint: keyof MediaBreakpoints
) => css`
	display: ${toggleType === 'show' ? 'none' : displayType};

	${media[breakpoint]`
		display: ${toggleType === 'show' ? displayType : 'none'};
	`};
`;

export const getDisplayValue = (isVisible?: boolean, displayType: DisplayType = 'block') => {
	if (typeof isVisible === 'boolean') {
		return isVisible ? displayType : 'none';
	}
	return displayType;
};
