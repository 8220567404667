import * as React from 'react';
import { StyledButton } from './common';
import { ButtonProps } from '../../../interfaces';

const ButtonLinkComponent = StyledButton('a');

const ButtonLink: React.FC<ButtonProps & React.AnchorHTMLAttributes<HTMLAnchorElement>> = ({ children, ...rest }) => (
	<ButtonLinkComponent {...rest}>{children}</ButtonLinkComponent>
);

ButtonLink.defaultProps = {
	size: 'large',
};

export default ButtonLink;
