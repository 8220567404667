import { Reducer } from 'redux';
import { getType } from 'typesafe-actions';
import { RecipeFrontState } from '../interfaces';
import { resourceActions, ResourceActions } from 'common/components/Resource/reducers/actions';
import { ResourceType } from 'common/components/Resource/types';
import { searchActions, SearchActions } from 'pagetypes/Search/reducers/search-actions';

const initialState: RecipeFrontState = {
	isSearching: false,
	searchResult: {
		count: 0,
		items: [],
	},
};

export const recipeFrontReducer: Reducer<RecipeFrontState, ResourceActions | SearchActions> = (
	state = initialState,
	action
) => {
	switch (action.type) {
		case getType(resourceActions.setResource):
			if (action.payload.resource.type === ResourceType.recipeFront) {
				return {
					...state,
					...action.payload.resource.content,
				};
			}

		case getType(searchActions.searchRecipes):
			return { ...state, isSearching: true };

		case getType(searchActions.searchRecipesSucceeded):
			const {
				result: { count, searchData },
			} = action.payload;
			return {
				...state,
				isSearching: false,
				searchResult: {
					count,
					items: searchData.map(({ name, url }) => ({ title: name, url })),
				},
			};

		default:
			return state;
	}
};
