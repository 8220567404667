import * as React from 'react';
import { IntlProvider } from 'react-intl';
import { connect, MapStateToProps } from 'react-redux';
import { GlobalStyle, themes, defaultTheme } from 'styleguide/themes/theme';
import { RoutingState } from '../Routing/reducers';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import LoginAndRegistrationModal from '../Login/LoginAndRegistrationModal';
import { UserState } from 'pagetypes/User/reducers/reducers';
import {
	getSiteUrlInfoOrDefault,
	getFacebookAppId,
	getSiteUrlIdThrow,
	getThemeForResource,
	WithAppSettings,
} from './services';
import { HeaderState } from '../Dom/reducers';
import { State } from '../../reducers';
import { userActions } from 'pagetypes/User/reducers/actions';
import { appActions } from './reducers';
import router from '../Routing/router';
import AnchorScrollBehavior from './components/AnchorScrollBehavior';
import { LoginState } from '../Login/reducers/reducers';
import { ValioMessages } from 'translations/messages.default';
import { Locales } from 'common/types/locale';
import { localeSelector } from 'common/selectors/localeSelector';
import { navigationActions } from '../Navigation/reducers';
import { LoginModalFormType } from '../Login/types';
import styled, { createGlobalStyle, ThemeProvider, DefaultTheme } from 'styled-components';
import { setInitiallyRenderedPath } from '../../../utils/routing';
import { withWindow } from 'styleguide/helpers';

const AppGlobalStyle = createGlobalStyle`
  body {
    margin: 0;
		-webkit-font-smoothing: antialiased;
		font-family: ${props => props.theme.fonts.secondary};
  }
`;

const AppContainer = styled.div`
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`;

const ContentWrapper = styled.div`
	${props => (!!props.theme.styledVariations.mainContentOffset ? props.theme.styledVariations.mainContentOffset : null)}
	width: 100%;
	flex: 2 0 auto;
	min-height: 400px;

	@media print {
		padding: 0;
		margin-top: 0;
	}
`;
declare global {
	interface Window {
		mobileApp?: boolean;
		mobileFacebookLogin?: (token: string) => void;
	}
}

interface AppProps {
	messages: ValioMessages;
}

interface AppState {
	dialogTypeBasedOnQuery: LoginModalFormType | null;
}

type Props = AppProps & AppStateProps & AppDispatchProps;

class App extends React.Component<AppProps & AppStateProps & AppDispatchProps, AppState> {
	constructor(props: Props, context?: any) {
		super(props, context);

		const { fetchCurrentUser, fetchSettingsAndNavigation, fetchNavigation, settings, routing } = this.props;

		this.state = {
			dialogTypeBasedOnQuery: null,
		};

		const shouldFetchNavigation = withWindow(w => w.serverRendered === false, false);

		if (shouldFetchNavigation) {
			if (settings) {
				const siteUrlId = getSiteUrlIdThrow(routing, settings.sites);
				fetchNavigation({ siteUrlId, routing });
			} else {
				fetchSettingsAndNavigation(routing);
			}
		}

		fetchCurrentUser();
	}

	public componentDidMount() {
		setInitiallyRenderedPath(this.props.routing.pathname);

		const openDialog = this.openDialogBasedOnQuery();
		if (openDialog) {
			this.setState({ dialogTypeBasedOnQuery: openDialog });
		}
	}

	public render() {
		const { messages, settings, locale, login, facebookAppId, siteTheme } = this.props;
		const { dialogTypeBasedOnQuery } = this.state;

		if (!messages || !settings || !locale || !siteTheme) {
			return null;
		}

		return (
			<IntlProvider locale={locale} messages={messages}>
				<ThemeProvider theme={siteTheme}>
					<AppContainer>
						<GlobalStyle />
						<AppGlobalStyle />
						<Header />
						<ContentWrapper>{this.renderPage()}</ContentWrapper>
						<Footer />
						{((login && login.isLoginOpen) || dialogTypeBasedOnQuery !== null) && (
							<LoginAndRegistrationModal
								facebookAppId={facebookAppId ?? null}
								openDialogBasedOnQuery={dialogTypeBasedOnQuery}
							/>
						)}
						<AnchorScrollBehavior />
					</AppContainer>
				</ThemeProvider>
			</IntlProvider>
		);
	}

	private renderPage() {
		const { routing, settings } = this.props;
		if (routing) {
			const siteUrlInfo = getSiteUrlInfoOrDefault(routing, settings ? settings.sites : []);
			const routeResult = router(siteUrlInfo, routing);

			const Page = routeResult.component;
			const pageProps = routeResult.props;

			return <Page {...pageProps} />;
		}

		return null;
	}

	private openDialogBasedOnQuery = (): LoginModalFormType | null => {
		const { routing } = this.props;

		if (routing && routing.query) {
			if (routing.query.showDialog === 'CreateAccount' && routing.query.email && routing.query.hash) {
				return LoginModalFormType.REGISTRATION;
			} else if (routing.query.showDialog === 'ChangePassword' && routing.query.email && routing.query.hash) {
				return LoginModalFormType.RESET_PASSWORD;
			}
		}

		return null;
	};
}

interface AppStateProps extends WithAppSettings {
	routing: RoutingState;
	user: UserState | null;
	header: HeaderState;
	locale: Locales;
	login: LoginState;
	headerTopOffset: number;
	facebookAppId: string | undefined;
	siteTheme: DefaultTheme;
}

const mapStateToProps: MapStateToProps<AppStateProps, AppProps, State> = (state): AppStateProps => {
	const { routing, user, app, dom, login, navigation, resource } = state;
	const locale = localeSelector(state);

	const sites = app.settings?.sites || [];
	const siteThemeCode = sites.length > 0 ? getThemeForResource(routing, sites, resource) : undefined;

	return {
		routing,
		user,
		settings: app.settings,
		header: dom.header,
		login,
		locale,
		headerTopOffset: navigation.topOffset,
		facebookAppId: getFacebookAppId(state),
		siteTheme: themes[siteThemeCode ?? defaultTheme],
	};
};

interface AppDispatchProps {
	fetchCurrentUser: typeof userActions.fetchCurrentUser;
	fetchSettingsAndNavigation: typeof appActions.fetchSettingsAndNavigation;
	fetchNavigation: typeof navigationActions.fetchNavigation;
}

export default connect(mapStateToProps, {
	fetchCurrentUser: userActions.fetchCurrentUser,
	fetchSettingsAndNavigation: appActions.fetchSettingsAndNavigation,
	fetchNavigation: navigationActions.fetchNavigation,
})(App);
