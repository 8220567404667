import * as React from 'react';
import NormalIconWrapper, { NormalIconWrapperProps } from '../NormalIconWrapper';
import { ThemeContext } from 'styled-components';

const ArrowUpIcon: React.FC<NormalIconWrapperProps & React.SVGAttributes<SVGElement>> = ({ color, ...props }) => {
	const theme = React.useContext(ThemeContext);

	return (
		<NormalIconWrapper {...props}>
			<path
				fill={color ?? theme.colors.brandPrimary}
				d="M13 7l-9.707 9.707a1 1 0 1 0 1.414 1.414L13 9.828l8.293 8.293a1 1 0 1 0 1.414-1.414L13 7z"
			/>
		</NormalIconWrapper>
	);
};

export default ArrowUpIcon;
