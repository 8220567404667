import { TotalTime } from '../../interfaces/cardInterfaces';

export function formatTotalTime(time: TotalTime | string) {
	if (typeof time === 'string') {
		return time;
	} else if (time.title) {
		return time.title;
	} else {
		const hour = Math.floor(time.minutes / 60);
		const minute = time.minutes % 60;
		if (hour > 0 && minute > 0) {
			return `${hour} h ${minute} min`;
		} else if (hour > 0) {
			return `${hour} h`;
		} else if (minute > 0) {
			return `${minute} min`;
		} else {
			return '';
		}
	}
}
