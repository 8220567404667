import { getConfig } from 'config/config';
import { trimEnd, trimStart } from './strings';
import { withWindow } from 'styleguide/helpers/window';
import { objectToFormData } from './object';
import { RoutingState } from 'common/components/Routing/reducers';

const credentials =
	withWindow(w => (w.location.href.startsWith('http://localhost') ? 'include' : 'same-origin')) || 'same-origin';

export function joinUrl(baseUrl: string, relativeUrl: string) {
	return trimEnd(baseUrl, '/') + '/' + trimStart(relativeUrl, '/');
}

function getSiteRelativeUrl(relativeUrl: string, baseUrl?: string) {
	if (!relativeUrl) {
		return '';
	}

	if (!baseUrl) {
		return relativeUrl;
	}

	return joinUrl(baseUrl, relativeUrl);
}

export type ErrorType = 'RequestError' | 'ServerError';

export interface ApiErrorAM {
	errorCode: string;
	details: any;
}

export class ApiError extends Error {
	constructor(public type: ErrorType, public status: number, public details?: ApiErrorAM | null) {
		super();
	}
}

async function tryCreateErrorModel(response: Response): Promise<ApiErrorAM | null> {
	try {
		return await response.json();
	} catch {
		return null;
	}
}

async function throwApiError(type: ErrorType, response: Response): Promise<void> {
	const error = await tryCreateErrorModel(response);
	throw new ApiError(type, response.status, error);
}

export function throwMissingSiteUrlIdError(): void {
	throw new ApiError('RequestError', 400, { errorCode: '400', details: 'siteUrlId is null or undefined' });
}

async function verifyResponse(response: Response): Promise<void> {
	if (response.status >= 400 && response.status < 500) {
		await throwApiError('RequestError', response);
	}

	if (response.status >= 500 && response.status <= 599) {
		await throwApiError('ServerError', response);
	}
}

export function getSiteRelativePublicUrl(routing: RoutingState, relativeUrl: string) {
	const baseUrl = routing.protocol + '//' + routing.hostname + (routing.port ? ':' + routing.port : '');
	return getSiteRelativeUrl(relativeUrl, baseUrl);
}

export function getSiteRelativeApiUrl(relativeUrl: string) {
	const config = getConfig();
	const apiBaseUrl = config.apiBaseUrl;
	return getSiteRelativeUrl(relativeUrl, apiBaseUrl ? apiBaseUrl : '/');
}

export async function fetchJson<T>(relativeUrl: string, isAbsolutUrl?: boolean): Promise<T> {
	const config = getConfig();

	const fullUrl = isAbsolutUrl ? relativeUrl : getSiteRelativeApiUrl(relativeUrl);
	const response = await fetch(fullUrl, {
		headers: config.authHeader,
		credentials,
	});

	await verifyResponse(response);

	const data = await response.json();
	return data;
}

export async function postJson(relativeUrl: string, body: any, expectResponse: boolean = true): Promise<any> {
	const config = getConfig();

	const fullUrl = getSiteRelativeApiUrl(relativeUrl);
	const response = await fetch(fullUrl, {
		method: 'POST',
		body: JSON.stringify(body),
		headers: {
			...config.authHeader,
			['Content-Type']: 'application/json',
		},
		credentials,
	});

	await verifyResponse(response);

	if (expectResponse) {
		return await response.json();
	}

	return undefined;
}

export async function uploadFiles(relativeUrl: string, body: object, expectResponse: boolean = true): Promise<any> {
	const config = getConfig();

	const fullUrl = getSiteRelativeApiUrl(relativeUrl);
	const response = await fetch(fullUrl, {
		method: 'POST',
		body: objectToFormData(body),
		headers: {
			...config.authHeader,
		},
		credentials,
	});

	await verifyResponse(response);

	if (expectResponse) {
		return await response.json();
	}

	return undefined;
}

export async function deleteJson(relativeUrl: string, body: any, expectResponse: boolean = false): Promise<any> {
	const config = getConfig();

	const fullUrl = getSiteRelativeApiUrl(relativeUrl);
	const response = await fetch(fullUrl, {
		method: 'DELETE',
		body: JSON.stringify(body),
		headers: {
			...config.authHeader,
			['Content-Type']: 'application/json',
		},
		credentials,
	});

	await verifyResponse(response);

	if (expectResponse) {
		return await response.json();
	}

	return undefined;
}

export async function putJson(relativeUrl: string, body: any, expectResponse: boolean = true): Promise<any> {
	const config = getConfig();

	const fullUrl = getSiteRelativeApiUrl(relativeUrl);
	const response = await fetch(fullUrl, {
		method: 'PUT',
		body: JSON.stringify(body),
		headers: {
			...config.authHeader,
			['Content-Type']: 'application/json',
		},
		credentials,
	});

	await verifyResponse(response);

	if (expectResponse) {
		return await response.json();
	}

	return undefined;
}
