import styled, { css } from 'styled-components';

interface ModalFormProps {
	isLoading?: boolean;
}

const ModalForm = styled.form<ModalFormProps>`
	${props =>
		props.isLoading
			? css`
					opacity: 0.5;
			  `
			: ''};

	& > .form-field + .form-field {
		margin-top: 10px;
	}

	& > .form-field.form-field__remember-login {
		margin-top: 20px;

		label {
			font-size: 15px;
		}
	}

	& > .form-field.form-field__checkbox--small {
		label {
			font-size: 15px;
		}
	}

	& > button {
		width: 100%;
		margin-top: 15px;
	}

	& > p {
		margin: 20px 0 0;
	}

	.form-error {
		margin-top: 20px;
		font-size: 15px;

		.highlighted {
			display: block;
		}
	}

	.divider {
		margin: ${({ theme }) => theme.grid.gutterInPx(3)} 0;
	}
`;

export default ModalForm;
