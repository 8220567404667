import * as React from 'react';
import MaterialCard from './MaterialCard';
import styled from 'styled-components';
import { MaterialCardInterface, CartItem } from '../interfaces';
import { ContentWrapper } from 'common/components/General';
import LoadingSpinner from 'common/components/Loading/LoadingSpinner';
import { media } from 'styleguide/helpers';
import NoResults from 'pagetypes/Search/components/NoResults';

const ResultsWrapper = styled.div`
	background: ${({ theme }) => theme.colors.contentSectionHiglighted};
	width: 100%;
	position: relative;
	padding: ${({ theme }) => theme.grid.gutterInPx(4)} 0;
	margin-top: ${({ theme }) => theme.grid.gutterInPx(-3)};

	${media.desktop`	
		margin-top: ${({ theme }) => theme.grid.gutterInPx(4)};
	`};
`;

const ResultsContentWrapper = styled(ContentWrapper)`
	padding: 0 ${({ theme }) => theme.grid.gutterInPx()};
`;

const ResultList = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin: ${({ theme }) => theme.grid.gutterInPx(-1)} 0;
	width: 100%;

	${media.tablet`
		justify-content: flex-start;
		margin: ${({ theme }) => theme.grid.gutterInPx(-1)};
	`};
`;

interface Props {
	items: CartItem[];
	results: MaterialCardInterface[];
	isLoading: boolean;
	error?: string | null;
	isResultsLoading?: boolean;
	onAddToCart: (id: string, quantity: number) => void;
}

const Results: React.FC<Props> = ({ results, items, isLoading, isResultsLoading, onAddToCart }) => {
	if (!isLoading && !isResultsLoading && results.length === 0) {
		return <NoResults />;
	}

	return (
		<ResultsWrapper>
			<ResultsContentWrapper>
				{isLoading && <LoadingSpinner />}
				{!isLoading && (
					<ResultList>
						{results.map(result => (
							<MaterialCard key={`material-card=${result.id}`} {...result} items={items} onAddToCart={onAddToCart} />
						))}
					</ResultList>
				)}
			</ResultsContentWrapper>
		</ResultsWrapper>
	);
};

export default Results;
