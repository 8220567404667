import { createStandardAction, createCustomAction, ActionType } from 'typesafe-actions';
import { LoginModalFormType } from '../types';

export const loginActions = {
	openLogin: createStandardAction('LOGIN/OPEN_LOGIN')<LoginModalFormType>(),

	closeLogin: createStandardAction('LOGIN/CLOSE_LOGIN')(),

	changeLoginFormType: createStandardAction('LOGIN/CHANGE_LOGIN_FORM_TYPE')<LoginModalFormType>(),

	doLogOut: createStandardAction('LOGIN/DO_LOG_OUT')(),

	doLogIn: createCustomAction('LOGIN/DO_LOG_IN', type => (email: string, password: string, rememberLogin: boolean) => ({
		type,
		payload: { email, password, rememberLogin },
	})),

	loginSuccess: createStandardAction('LOGIN/LOGIN_SUCCESS')<number>(),

	loginFail: createStandardAction('LOGIN/LOGIN_FAIL')<number>(),

	doFacebookLogIn: createStandardAction('LOGIN/DO_FACEBOOK_LOG_IN')<string>(),

	facebookLoginSuccess: createStandardAction('LOGIN/FACEBOOOK_LOGIN_SUCCESS')<number>(),

	facebookLoginFail: createStandardAction('LOGIN/FACEBOOK_LOGIN_FAIL')<number>(),

	requestRegisterUser: createStandardAction('LOGIN/REQUEST_REGISTER_USER')<string>(),

	requestRegisterUserSuccess: createStandardAction('LOGIN/REQUEST_REGISTER_USER_SUCCESS')(),

	requestRegisterUserError: createStandardAction('LOGIN/REQUEST_REGISTER_USER_ERROR')<string>(),

	getUniqueUserName: createStandardAction('LOGIN/GET_UNIQUE_USER_NAME')<string>(),

	getUniqueUserNameSuccess: createStandardAction('LOGIN/GET_UNIQUE_USER_NAME_SUCCESS')<string>(),

	getUniqueUserNameError: createStandardAction('LOGIN/GET_UNIQUE_USER_NAME_ERROR')<string>(),

	registerUser: createCustomAction(
		'LOGIN/REGISTER_USER',
		type => (
			email: string,
			userName: string,
			password: string,
			subscribeToNewsletter: boolean,
			hash: string,
			siteUrlId: string
		) => ({
			type,
			payload: { email, userName, password, subscribeToNewsletter, hash, siteUrlId },
		})
	),

	registerUserSuccess: createStandardAction('LOGIN/REGISTER_USER_SUCCESS')(),

	registerUserError: createStandardAction('LOGIN/REGISTER_USER_ERROR')<string>(),

	forgotPassword: createStandardAction('LOGIN/FORGOT_PASSWORD')<string>(),

	forgotPasswordSuccess: createStandardAction('LOGIN/FORGOT_PASSWORD_SUCCESS')(),

	forgotPasswordError: createStandardAction('LOGIN/FORGOT_PASSWORD_ERROR')<string>(),

	resetPassword: createCustomAction(
		'LOGIN/RESET_PASSWORD',
		type => (email: string, password: string, hash: string) => ({
			type,
			payload: { email, password, hash },
		})
	),

	resetPasswordSuccess: createStandardAction('LOGIN/RESET_PASSWORD_SUCCESS')(),

	resetPasswordError: createStandardAction('LOGIN/RESET_PASSWORD_ERROR')<string>(),
};

export type LoginActions = ActionType<typeof loginActions>;
