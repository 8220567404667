import * as React from 'react';
import SocialIconWrapper from '../SocialIconWrapper';
import { IconWrapperProps } from '../../../../interfaces';

const TwitterIcon: React.FC<IconWrapperProps & React.SVGAttributes<SVGElement>> = ({ color, ...props }) => {
	return (
		<SocialIconWrapper alt="Twitter" {...props}>
			<path
				fill={color}
				d="M18.621 6c1.595 0 2.459.597 3.256 1.328.665 0 1.529-.465 2.06-.664l.532-.333c-.333.864-.665 1.529-1.33 1.994l-.464.332c.996 0 1.66-.399 2.325-.665-.332.665-.864 1.33-1.329 1.728l-.664.532c0 .996 0 1.993-.2 2.79-1.063 4.784-3.72 8.107-7.973 9.502-2.747.833-5.706.62-8.305-.598a9.435 9.435 0 0 1-1.197-.598l-.332-.2h1.33c.398 0 .797 0 1.129-.132.93-.2 1.794-.465 2.458-.93.332-.2.864-.466 1.13-.731a4.252 4.252 0 0 1-3.788-2.99c.4 0 1.529.132 1.794-.133-.531 0-.996-.333-1.328-.532-1.064-.664-1.994-1.86-1.994-3.72l.465.198.864.266c.133 0 .399.133.532.067l-.731-.665a4.585 4.585 0 0 1-.465-5.05c.066.2.265.333.398.466a11.827 11.827 0 0 0 8.04 3.986c-.2-.531-.133-1.462 0-1.993a4.186 4.186 0 0 1 3.257-3.19L18.555 6"
			/>
		</SocialIconWrapper>
	);
};

TwitterIcon.defaultProps = {
	color: '#29A9E0',
};

export default TwitterIcon;
