import { createStandardAction, getType, ActionType } from 'typesafe-actions';
import { combineReducers } from 'redux';
import { RelatedProduct, RelatedProductsState } from '../interfaces';

export const relatedProductsActions = {
	setRelatedProducts: createStandardAction('PRODUCT/SET_RELATED_PRODUCTS')<RelatedProduct[]>(),
};

export const relatedProductsReducer = combineReducers<RelatedProductsState, RelatedProductsActions>({
	isLoading: (state = true, action) => {
		return action.type !== getType(relatedProductsActions.setRelatedProducts);
	},

	products: (state = [], action) => {
		switch (action.type) {
			case getType(relatedProductsActions.setRelatedProducts): {
				return action.payload;
			}
			default:
				return state;
		}
	},
});

export type RelatedProductsActions = ActionType<typeof relatedProductsActions>;
