import { LogoType } from 'styleguide/components/Logos/Logo/Logo';
import { SiteId } from '../App/services';

export interface FooterLinkSection {
	links: Array<{ url: string; title: string; isExternal?: boolean }>;
}
interface FooterLayout {
	showLogo: boolean;
	logo?: LogoType;
	footerTitle?: string;
	linkSections: FooterLinkSection[];
	showCopyright: boolean;
}

const defaultLayout: FooterLayout = {
	showLogo: true,
	showCopyright: true,
	linkSections: [
		{
			links: [
				{
					url: '/terms-of-use/',
					title: 'component_comfooter_link_termsofuse',
				},
			],
		},
		{
			links: [
				{
					url: '/privacy-statement/',
					title: 'component_comfooter_link_privacypolicy',
				},
			],
		},
	],
};

const defaultOGLayout: FooterLayout = {
	...defaultLayout,
	footerTitle: 'component_ogfooter_title',
	showLogo: false,
};

const config: { [key: string]: FooterLayout } = {
	[SiteId.com]: {
		...defaultLayout,
	},
	[SiteId.es]: {
		...defaultLayout,
		linkSections: [
			{
				links: [
					{
						url: '/es/terminos-de-uso/',
						title: 'component_comfooter_link_termsofuse',
					},
					{
						url: '/es/politica-de-privacidad/',
						title: 'applet_feedback_link_privacypolicy',
					},
				],
			},
			{
				links: [
					{
						url: '/es/formulario-de-contacto/',
						title: 'component_comnavigation_contactus',
					},
					{
						url: '/es/cookies-policy/',
						title: 'global_cookie_terms',
					},
				],
			},
		],
	},
	[SiteId.ge]: {
		...defaultLayout,
		linkSections: [
			{
				links: [
					{
						url: '/ge/gamoqenebis-pirobebi/',
						title: 'component_comfooter_link_termsofuse',
					},
					{
						url: '/ge/konpidentsialurobis-politika/',
						title: 'component_comfooter_link_privacypolicy',
					},
				],
			},
			{
				links: [
					{
						url: '/ge/sakontaktoformulari/',
						title: 'component_comnavigation_contactus',
					},
				],
			},
		],
	},
	[SiteId.og]: {
		...defaultOGLayout,
		linkSections: [
			{
				links: [
					{
						url: '/terms-of-use/',
						title: 'component_comfooter_link_termsofuse',
					},
					{
						url: '/cookie-policy/',
						title: 'global_cookie_terms',
					},
				],
			},
			{
				links: [
					{
						url: '/privacy-statement/',
						title: 'component_comfooter_link_privacypolicy',
					},
				],
			},
		],
	},
	[SiteId.ogUK]: {
		...defaultOGLayout,
		linkSections: [
			{
				links: [
					{
						url: '/terms-of-use/',
						title: 'component_comfooter_link_termsofuse',
					},
					{
						url: '/cookie-policy/',
						title: 'global_cookie_terms',
					},
				],
			},
			{
				links: [
					{
						url: '/privacy-statement/',
						title: 'component_comfooter_link_privacypolicy',
					},
				],
			},
		],
	},
	[SiteId.ogUs]: {
		...defaultOGLayout,
		linkSections: [
			{
				links: [
					{
						url: '/us/products/dairy-free-oat-yogurt-alternatives/',
						title: 'component_comfooter_link_dairyfreeyogurtalternatives',
					},
					{
						url: '/us/foodservice/',
						title: 'component_comfooter_link_foodservice',
					},
				],
			},
			{
				links: [
					{
						url: '/us/terms-of-use/',
						title: 'component_comfooter_link_termsofuse',
					},
					{
						url: '/us/privacy-policy/',
						title: 'component_comfooter_link_privacypolicy',
					},
				],
			},
		],
	},
	[SiteId.ogFi]: {
		...defaultOGLayout,
		linkSections: [
			{
				links: [
					{
						url: '/terms-of-use/',
						title: 'og_general_terms_of_use',
					},
					{
						url: '/cookie-policy/',
						title: 'og_cookie_terms',
					},
				],
			},
			{
				links: [
					{
						url: '/privacy-policy/',
						title: 'og_privacy_policy',
					},
				],
			},
		],
	},
	[SiteId.ogSe]: {
		...defaultOGLayout,
		linkSections: [
			{
				links: [
					{
						url: '/terms-of-use/',
						title: 'og_general_terms_of_use',
					},
					{
						url: '/cookie-policy/',
						title: 'og_cookie_terms',
					},
				],
			},
			{
				links: [
					{
						url: '/privacy-policy/',
						title: 'og_privacy_policy',
					},
				],
			},
		],
	},
	[SiteId.ogEe]: {
		...defaultOGLayout,
		linkSections: [
			{
				links: [
					{
						url: '/terms-of-use/',
						title: 'og_general_terms_of_use',
					},
					{
						url: '/cookie-policy/',
						title: 'og_cookie_terms',
					},
				],
			},
			{
				links: [
					{
						url: '/privacy-policy/',
						title: 'og_privacy_policy',
					},
				],
			},
		],
	},
	[SiteId.ogNl]: {
		...defaultOGLayout,
		linkSections: [
			{
				links: [
					{
						url: '/terms-of-use/',
						title: 'component_comfooter_link_termsofuse',
					},
					{
						url: '/cookie-policy/',
						title: 'global_cookie_terms',
					},
				],
			},
			{
				links: [
					{
						url: '/privacy-policy/',
						title: 'component_comfooter_link_privacypolicy',
					},
				],
			},
		],
	},
	[SiteId.ogEs]: {
		...defaultOGLayout,
		linkSections: [
			{
				links: [
					{
						url: '/terminos-de-uso/',
						title: 'global_general_terms_of_use',
					},
					{
						url: '/politica-de-cookies/',
						title: 'global_cookie_terms',
					},
				],
			},
			{
				links: [
					{
						url: '/politica-de-privacidad/',
						title: 'global_privacy_policy',
					},
				],
			},
		],
	},
	[SiteId.ogIe]: {
		...defaultOGLayout,
		linkSections: [
			{
				links: [
					{
						url: '/terms-of-use/',
						title: 'component_comfooter_link_termsofuse',
					},
					{
						url: '/cookie-policy/',
						title: 'global_cookie_terms',
					},
				],
			},
			{
				links: [
					{
						url: '/privacy-statement/',
						title: 'component_comfooter_link_privacypolicy',
					},
				],
			},
		],
	},
	[SiteId.ogPl]: {
		...defaultOGLayout,
		linkSections: [
			{
				links: [
					{
						url: '/warunki-uzytkowania/',
						title: 'component_comfooter_link_termsofuse',
					},
					{
						url: '/polityka-cookie/',
						title: 'global_cookie_terms',
					},
				],
			},
			{
				links: [
					{
						url: '/polityka-prywatnosci/',
						title: 'component_comfooter_link_privacypolicy',
					},
				],
			},
		],
	},
	[SiteId.pl]: {
		...defaultLayout,
		linkSections: [
			{
				links: [
					{
						url: '/polska/warunki-uzytkowania/',
						title: 'component_comfooter_link_termsofuse',
					},
					{
						url: '/polska/polityka-prywatnosci/',
						title: 'applet_feedback_link_privacypolicy',
					},
				],
			},
			{
				links: [
					{
						url: '/polska/opinia/',
						title: 'component_comnavigation_contactus',
					},
					{
						url: '/polska/polityka-cookie/',
						title: 'global_cookie_terms',
					},
				],
			},
		],
	},
	[SiteId.se]: {
		...defaultLayout,
		linkSections: [
			{
				links: [
					{
						url: '/integritetspolicy/',
						title: 'component_comfooter_link_termsofuse',
					},
				],
			},
			{
				links: [
					{
						url: '/kontakta-oss/',
						title: 'component_comnavigation_contactus',
					},
				],
			},
		],
	},
	[SiteId.us]: {
		...defaultLayout,
		linkSections: [
			{
				links: [
					{
						url: '/us/privacy-policy/',
						title: 'component_comfooter_link_privacypolicy',
					},
				],
			},
		],
	},
	[SiteId.mjm]: {
		showLogo: true,
		showCopyright: true,
		logo: 'maitojame',
		linkSections: [
			{
				links: [
					{
						url: '/mediakortti/#a-6bb7fa45-4346-4c4d-b7ad-f6a7f513c824',
						title: 'global_contact_media',
					},
					{
						url: '/mediakortti/',
						title: 'global_mediacard',
					},
					{
						url: 'https://www.valio.fi/',
						title: 'global_sitename_valiofi',
					},
					{
						url: 'https://valma.valio.fi',
						title: 'global_sitename_valma',
					},
				],
			},
			{
				links: [
					{
						url: '/yleiset-kayttoehdot/',
						title: 'global_general_terms_of_use',
					},
					{
						url: '/tietosuojaseloste/',
						title: 'global_privacy_policy',
					},
					{
						url: '/evastekaytannot/',
						title: 'global_cookie_terms',
					},
				],
			},
		],
	},
	[SiteId.majal]: {
		showLogo: true,
		showCopyright: true,
		logo: 'maidonjalostajat',
		linkSections: [
			{
				links: [
					{
						url: '/yleiset-kayttoehdot/',
						title: 'global_general_terms_of_use',
					},
					{
						url: '/evastekaytannot/',
						title: 'global_cookie_terms',
					},
				],
			},
		],
	},
	[SiteId.finc]: {
		...defaultLayout,
		showCopyright: false,
		logo: 'finlandiacheese',
		linkSections: [
			{
				links: [
					{
						url: '/terms-of-use/',
						title: 'component_comfooter_link_termsofuse',
					},
					{
						url: '/privacy-policy/',
						title: 'component_comfooter_link_privacypolicy',
					},
					{
						url: '/cookie-policy/',
						title: 'global_cookie_terms',
					},
				],
			},
			{
				links: [
					{
						url: '/contact-us/',
						title: 'component_comnavigation_contactus',
					},
					{
						url: 'https://www.valio.com/us/join-our-team/',
						title: 'component_comfooter_link_careers',
						isExternal: true,
					},
					{
						url: 'https://www.valio.com/us/',
						title: 'component_comnavigation_title_usa',
						isExternal: true,
					},
					{
						url: 'https://www.valio.com/',
						title: 'component_comnavigation_valiogroup',
						isExternal: true,
					},
				],
			},
		],
	},
	[SiteId.ee]: {
		...defaultLayout,
		linkSections: [
			{
				links: [
					{
						url: '/kasutustingimused/',
						title: 'component_comfooter_link_termsofuse',
					},
				],
			},
			{
				links: [
					{
						url: '/kupsised/',
						title: 'global_cookie_terms',
					},
				],
			},
		],
	},
	[SiteId.eeEn]: {
		...defaultLayout,
		linkSections: [
			{
				links: [
					{
						url: '/en/terms-of-use/',
						title: 'component_comfooter_link_termsofuse',
					},
				],
			},
			{
				links: [
					{
						url: '/en/cookies/',
						title: 'global_cookie_terms',
					},
				],
			},
		],
	},
	[SiteId.eeRu]: {
		...defaultLayout,
		linkSections: [
			{
				links: [
					{
						url: '/ru/usloviia-ispolzovaniia/',
						title: 'component_comfooter_link_termsofuse',
					},
				],
			},
			{
				links: [
					{
						url: '/ru/ispolzovanie-failov-cookie/',
						title: 'global_cookie_terms',
					},
				],
			},
		],
	},
	[SiteId.eeExP]: {
		...defaultLayout,
		linkSections: [
			{
				links: [
					{
						url: '/en/terms-of-use/',
						title: 'component_comfooter_link_termsofuse',
					},
				],
			},
			{
				links: [
					{
						url: '/en/cookies/',
						title: 'global_cookie_terms',
					},
				],
			},
		],
	},
	[SiteId.lv]: {
		...defaultLayout,
		linkSections: [
			{
				links: [
					{
						url: '/lietosanas-noteikumi/',
						title: 'component_comfooter_link_termsofuse',
					},
				],
			},
			{
				links: [
					{
						url: '/sikdatnes/',
						title: 'global_cookie_terms',
					},
				],
			},
		],
	},
	[SiteId.lvRu]: {
		...defaultLayout,
		linkSections: [
			{
				links: [
					{
						url: '/usloviia-ispolzovaniia/',
						title: 'component_comfooter_link_termsofuse',
					},
				],
			},
			{
				links: [
					{
						url: '/cookie/',
						title: 'global_cookie_terms',
					},
				],
			},
		],
	},
	[SiteId.lvEn]: {
		...defaultLayout,
		linkSections: [
			{
				links: [
					{
						url: '/terms-of-use/',
						title: 'component_comfooter_link_termsofuse',
					},
				],
			},
			{
				links: [
					{
						url: '/cookies/',
						title: 'global_cookie_terms',
					},
				],
			},
		],
	},
	[SiteId.lt]: {
		...defaultLayout,
		linkSections: [
			{
				links: [
					{
						url: '/privatumo-politika/',
						title: 'global_privacy_policy',
					},
				],
			},
		],
	},
	[SiteId.ltRu]: {
		...defaultLayout,
		linkSections: [],
	},
};

export const getFooterConfig = (siteId: SiteId): FooterLayout | undefined => {
	return config[siteId];
};
