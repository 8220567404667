import * as React from 'react';
import styled, { css } from 'styled-components';

export type HeaderTagName = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

export type HeaderOrParagraphTagName = HeaderTagName | 'p';

interface MediumHeaderProps extends React.HTMLAttributes<HTMLElement> {
	greyed?: boolean;
	tagName?: HeaderOrParagraphTagName;
	noTextTransform?: boolean;
}

function MediumHeader({ children, tagName, ...rest }: MediumHeaderProps) {
	const HeaderComponent = getComponent(tagName);
	return (
		<>
			<HeaderComponent {...rest}>{children}</HeaderComponent>
		</>
	);
}

function getComponent(tagName?: HeaderOrParagraphTagName) {
	switch (tagName) {
		case 'p':
			return Paragraph;
		case 'h1':
			return Header1;
		case 'h3':
			return Header3;
		case 'h4':
			return Header4;
		case 'h5':
			return Header5;
		case 'h6':
			return Header6;
		case 'h2':
		default:
			return Header2;
	}
}

interface HeaderComponentProps {
	noTextTransform?: boolean;
}

const Header1 = styled.h1<HeaderComponentProps>`
	${p => getCommonHeaderStyles(p.noTextTransform)}
`;

const Header2 = styled.h2<HeaderComponentProps>`
	${p => getCommonHeaderStyles(p.noTextTransform)}
`;

const Header3 = styled.h3<HeaderComponentProps>`
	${p => getCommonHeaderStyles(p.noTextTransform)}
`;

const Header4 = styled.h4<HeaderComponentProps>`
	${p => getCommonHeaderStyles(p.noTextTransform)}
`;

const Header5 = styled.h5<HeaderComponentProps>`
	${p => getCommonHeaderStyles(p.noTextTransform)}
`;

const Header6 = styled.h6<HeaderComponentProps>`
	${p => getCommonHeaderStyles(p.noTextTransform)}
`;

const Paragraph = styled.p<HeaderComponentProps>`
	${p => getCommonHeaderStyles(p.noTextTransform)}
`;

function getCommonHeaderStyles(noTextTransform?: boolean) {
	return css<React.HTMLAttributes<HTMLHeadingElement>>`
		${props => props.theme.typography.heading};
		line-height: 1;
		color: ${({ theme, color }) => color || theme.colors.heading};
		font-size: 24px;
		text-transform: ${noTextTransform ? '' : 'uppercase'};
	`;
}

MediumHeader.defaultProps = {
	greyed: false,
};

export default MediumHeader;
