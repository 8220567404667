import * as React from 'react';
import NormalIconWrapper, { NormalIconWrapperProps } from '../NormalIconWrapper';
import { ThemeContext } from 'styled-components';

const ArrowDownIcon: React.FC<NormalIconWrapperProps & React.SVGAttributes<SVGElement>> = ({ color, ...props }) => {
	const theme = React.useContext(ThemeContext);

	return (
		<NormalIconWrapper {...props}>
			<path
				fill={color ?? theme.colors.brandPrimary}
				d="M13 18.414L3.293 8.707a1 1 0 1 1 1.414-1.414L13 15.586l8.293-8.293a1 1 0 1 1 1.414 1.414L13 18.414z"
			/>
		</NormalIconWrapper>
	);
};

export default ArrowDownIcon;
