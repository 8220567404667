import * as React from 'react';
import { SearchIcon } from '../../../components/Icons';
import IconButton from '../../../components/Buttons/IconButton/IconButton';
import { InputSizes, InputSize } from '../../../types';
import Input, { InputProps } from '../Input';
import styled from 'styled-components';
import { media } from '../../../helpers';
import { ResponsiveValues } from 'common/components/Media';

const SearchInput = styled(Input)<InputProps>`
	.search-input {
		&::placeholder {
			font-size: 15px;

			${media.tablet`
				font-size: 18px;
			`};
		}
		&::-ms-clear {
			display: none;
		}
	}
`;

export interface SearchFieldProps {
	icon?: React.ReactElement<any>;
	hideIcon?: boolean;
	withBlueBorder: boolean;
	fullWidth: boolean;
	inputSize: InputSizes | ResponsiveValues<InputSizes>;
	onButtonClick?: () => void;
	inputRef?: React.RefObject<HTMLInputElement>;
}

const getIconOffset = (inputSize: InputSize) => {
	if (inputSize === InputSize.large) {
		return -22;
	}
	if (inputSize === InputSize.medium) {
		return -14;
	}
	return -6;
};

const SearchField: React.FC<SearchFieldProps & InputProps & React.InputHTMLAttributes<HTMLInputElement>> = ({
	withBlueBorder,
	inputSize,
	fullWidth,
	onButtonClick,
	icon,
	inputRef,
	hideIcon,
	...rest
}) => {
	return (
		<SearchInput
			{...rest}
			aria-label={rest.placeholder}
			inputSize={inputSize}
			inputClassName="search-input"
			ref={inputRef as any}
			icon={hideIcon ? undefined : icon ? <IconButton onClick={onButtonClick} icon={icon} /> : undefined}
			iconPosition="right"
			iconWidth={38}
			iconOffset={getIconOffset}
		/>
	);
};

SearchField.defaultProps = {
	withBlueBorder: false,
	fullWidth: false,
	inputSize: InputSize.small,
	icon: <SearchIcon />,
};

export default SearchField;
