import * as React from 'react';
import styled from 'styled-components';
import CloseIcon from '../../Icons/NormalIcons/CloseIcon';

const FilteredPillElement = ({ removeId, onRemove, ...rest }: PillElementProps) => <span {...rest} />;

const PillElement = styled(FilteredPillElement)<PillElementProps>`
	border-radius: 17px;
	font-weight: 800;
	font-size: 13px;
	line-height: 24px;
	text-align: center;
	padding: 0 12px;
	text-decoration: none;
	white-space: nowrap;
	display: inline-block;
	font-family: ${props => props.theme.fonts.primary};
	background-color: ${props => props.theme.colors.brandPrimaryFeatureBackground};
	color: ${props => props.theme.colors.brandPrimary};

	${props =>
		props.onRemove
			? `
      position: relative;
      padding-right: 35px;

      > svg {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 10px;
        cursor: pointer;
      }
    `
			: ''};
`;

export interface PillElementProps {
	removeId?: string;
	onRemove?: (id: string) => void;
}

const Pill: React.FC<PillElementProps & React.HTMLAttributes<HTMLElement>> = props => {
	const onRemoveClicked = () => {
		if (props.onRemove) {
			props.onRemove(props.removeId || '');
		}
	};

	return (
		<PillElement {...props}>
			{props.children}
			{props.onRemove ? <CloseIcon onClick={onRemoveClicked} size={14} /> : ''}
		</PillElement>
	);
};

export default Pill;
