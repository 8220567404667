import React from 'react';
import styled, { ThemeContext } from 'styled-components';
import {
	FacebookIcon,
	YoutubeIcon,
	TwitterIcon,
	LinkedinIcon,
	InstagramIcon,
	PinterestIcon,
} from 'styleguide/components/Icons';
import { media } from 'styleguide/helpers';
import { SocialUserNameProps } from './interfaces';

const IconGroup = styled.div`
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;

	${media.desktop`
		max-width: 100%;
		justify-content: flex-end;

		a {
			& + a {
				margin-left: 30px;
			}
		}
	`};
`;

export interface ComSocialMediaProps extends SocialUserNameProps {
	socialIconSize: number;
}

export function ComSocialMediaLinks({
	socialIconSize,
	facebookUserName,
	youTubeUserName,
	twitterUserName,
	linkedInUserName,
	instagramUserName,
	pinterestUserName,
}: ComSocialMediaProps) {
	const theme = React.useContext(ThemeContext);

	const renderSection =
		facebookUserName ||
		youTubeUserName ||
		twitterUserName ||
		linkedInUserName ||
		instagramUserName ||
		pinterestUserName;

	return renderSection ? (
		<IconGroup>
			{facebookUserName && (
				<a href={'https://www.facebook.com/' + facebookUserName} rel="noopener">
					<FacebookIcon color={theme.colors.white} size={socialIconSize} />
				</a>
			)}
			{youTubeUserName && (
				<a href={'https://www.youtube.com/user/' + youTubeUserName} rel="noopener">
					<YoutubeIcon color={theme.colors.white} size={socialIconSize} />
				</a>
			)}
			{twitterUserName && (
				<a href={'https://twitter.com/' + twitterUserName} rel="noopener">
					<TwitterIcon color={theme.colors.white} size={socialIconSize} />
				</a>
			)}
			{linkedInUserName && (
				<a href={'https://www.linkedin.com/company/' + linkedInUserName} rel="noopener">
					<LinkedinIcon color={theme.colors.white} size={socialIconSize} />
				</a>
			)}
			{instagramUserName && (
				<a href={'https://instagram.com/' + instagramUserName} rel="noopener">
					<InstagramIcon color={theme.colors.white} size={socialIconSize} />
				</a>
			)}
			{pinterestUserName && (
				<a href={'https://www.pinterest.com/' + pinterestUserName + '/'} rel="noopener">
					<PinterestIcon color={theme.colors.white} size={socialIconSize} />
				</a>
			)}
		</IconGroup>
	) : null;
}
