import * as React from 'react';
import NormalIconWrapper, { NormalIconWrapperProps } from '../NormalIconWrapper';
import { ThemeContext } from 'styled-components';

const MenuIcon: React.FC<NormalIconWrapperProps & React.SVGAttributes<SVGElement>> = ({ color, ...props }) => {
	const theme = React.useContext(ThemeContext);
	return (
		<NormalIconWrapper {...props}>
			<g fill={color ?? theme.colors.brandPrimary}>
				<rect width="24" height="2" x="1" y="6" rx="1" />
				<rect width="24" height="2" x="1" y="12" rx="1" />
				<rect width="24" height="2" x="1" y="18" rx="1" />
			</g>
		</NormalIconWrapper>
	);
};

export default MenuIcon;
