import { DefaultTheme, css } from 'styled-components';
import { media } from 'styleguide/helpers';
import { ProductCardSizes } from 'styleguide/components/Cards/ProductCard/ProductCard';

const GUTTER = 8;

export const themeColors = {
	valioBlue: '#002f6d',
	valioBlue35: 'rgba(0, 47, 109, 0.35)',
	valioBlue90: 'rgba(0, 47, 109, 0.9)',
	valioBlue20: 'rgba(0, 47, 109, 0.2)',
	valioBlueDark: '#00285c',
	lavendar: '#8969aa',
	lavendar10: 'rgba(137, 105, 170, 0.1)',
	chili: '#661a6b',
	turquoise: '#008489',
	turquoise10: 'rgba(0, 132, 137, 0.1)',
	turmeric: '#c25718',
	chive: '#00833f',
	chive10: 'rgba(0, 131, 63, 0.1)',
	chocolate: '#503021',
	flower: '#007fb0',
	flowerHover: '#0087c7',
	flower20: 'rgba(0, 127, 176, 0.2)',
	liquorice: '#23282d',

	grey: '#ccc',
	lightGrey: '#eee',
	middleGrey: '#aaa',
	darkGrey: '#888',
	charcoal: '#333',

	orange: '#f6a800',
	orangeHover: '#ed8813',
	yellow20: 'rgba(246, 168, 0, 0.2)',
	lightGreen: '#e5f2eb',
	alertRed: '#fbecec',
	paleRed: '#dc4040',
	powderBlue: '#b0dcfa',
	powderBlue30: 'rgba(176, 220, 250, 0.3)',
	powderBlue20: 'rgba(176, 220, 250, 0.2)',
	navigationBgBlue: '#f6f8f8',

	black20: 'rgba(0, 0, 0, 0.2)',
	black40: 'rgba(0, 0, 0, 0.4)',

	blueGray: '#5177a3',
	echoBlue: '#a1b6cd',

	_404VeryDark: '#001e3f',
	_404VeryLight: '#ccdeea',
	_404Light: '#91bed8',
	_404White96: '#e4eef4',
	_404White99: '#fdfeff',

	lesson: '#910048',

	white: '#fff',
	white90: 'rgba(255, 255, 255, 0.9)',
};

const themeFonts = {
	proximaNova: 'proxima-nova, "Open Sans", Arial, sans-serif',
	omnesPro: 'omnes-pro, Arial, sans-serif',
};

const themeTextDefinitions = {
	heading: css`
		font-family: ${themeFonts.proximaNova};
		font-weight: 900;
	`,
	button: css`
		font-family: ${themeFonts.proximaNova};
		font-weight: 900;
	`,
	navigation: css`
		font-family: ${themeFonts.proximaNova};
		font-weight: 800;
	`,
	nutrient: css`
		font-size: 15px;
		${media.tablet`
			font-size: 13px;
		`};
	`,
	contactPersonName: css`
		font-size: 17px;
		line-height: 20px;
	`,
};

const valio: DefaultTheme = {
	filters: {
		iconFilter: 'none',
	},
	colors: {
		adminToolbar: themeColors.valioBlue35,

		articleDefaultTag: themeColors.chocolate,

		actionSuccessBackground: themeColors.lightGreen,
		actionSuccessText: themeColors.chive,
		actionInfoBackground: themeColors.yellow20,
		actionInfoText: themeColors.valioBlue,
		actionErrorBackground: themeColors.alertRed,
		actionErrorText: themeColors.paleRed,

		bodyText: themeColors.charcoal,
		bodyTextLight: themeColors.darkGrey,
		bodyBackground: themeColors.white,

		border: themeColors.lightGrey,
		borderDark: themeColors.grey,
		borderHighlight: themeColors.powderBlue,
		borderNavigation: themeColors.lightGrey,

		brandPrimary: themeColors.valioBlue,
		brandPrimaryFeatureBackground: themeColors.powderBlue30,
		brandSecondary: themeColors.flower,
		brandTertiary: themeColors.orange,

		buttonPrimary: themeColors.flower,
		buttonPrimaryHover: themeColors.flowerHover,
		buttonPrimaryBorder: undefined,
		buttonPrimaryBorderHover: undefined,
		buttonSecondary: themeColors.orange,
		buttonSecondaryHover: themeColors.orangeHover,
		buttonSecondaryBorder: undefined,
		buttonSecondaryBorderHover: undefined,
		buttonTertiary: themeColors.valioBlue,
		buttonTertiaryHover: themeColors.valioBlue90,

		buttonHighlightText: themeColors.white,
		buttonHighlightTextHover: themeColors.white,
		buttonHighlightBackground: themeColors.flower,
		buttonHighlightBackgroundHover: themeColors.flowerHover,
		buttonHighlightBorder: undefined,
		buttonHighlightBorderHover: undefined,

		buttonOutlineText: themeColors.flower,
		buttonOutlineTextHover: themeColors.flowerHover,
		buttonOutlineTextSecondary: themeColors.orange,
		buttonOutlineTextSecondaryHover: themeColors.orangeHover,
		buttonOutlineBackground: themeColors.white,
		buttonOutlineBackgroundHover: themeColors.white90,
		buttonOutlineBackgroundSecondary: themeColors.white,
		buttonOutlineBackgroundSecondaryHover: themeColors.white,
		buttonOutlineBorder: themeColors.lightGrey,
		buttonOutlineBorderHover: themeColors.flowerHover,
		buttonOutlineBorderSecondary: themeColors.lightGrey,
		buttonOutlineBorderSecondaryHover: themeColors.orangeHover,

		buttonDisabled: themeColors.grey,
		buttonHoverBackground: themeColors.white90,
		buttonText: themeColors.white,
		buttonHoverText: themeColors.white,

		buttonTextForDarkBackground: themeColors.white,
		buttonTextForLightBackground: themeColors.white,

		contentSection: themeColors.white,
		contentSectionHiglighted: themeColors.lightGrey,

		component404SvgBorder: themeColors._404VeryDark,
		component404SvgAccentVeryLight: themeColors._404VeryLight,
		component404SvgAccentLight: themeColors._404Light,
		component404SvgAccentSpoon: themeColors._404White99,

		componentNoResultsSvgAccentPuddle: themeColors._404White96,
		componentNoResultsSvgAccentWhite99: themeColors._404White99,
		componentNoResultsSvgAccentVeryLight: themeColors._404VeryLight,
		componentNoResultsSvgAccentLight: themeColors._404Light,

		dropdownButtonTextOpen: themeColors.charcoal,
		dropdownButtonTextClosed: themeColors.flower,

		footerBackground: themeColors.valioBlue,
		framedHeaderBackground: themeColors.white,
		framedHeaderTitle: themeColors.valioBlue,

		heading: themeColors.valioBlue,

		imagePlaceHolder: themeColors.lightGrey,

		inputDisabledBackground: themeColors.lightGrey,
		inputDisabledText: themeColors.darkGrey,
		inputInfo: themeColors.chive,
		inputPlaceholder: themeColors.darkGrey,

		linkText: themeColors.flower,
		linkTextHover: themeColors.flowerHover,
		linkTextVisited: themeColors.valioBlue,
		linkTextAlternative: themeColors.chive,

		modalBackground: themeColors.black40,

		navigationHeaderBackground: themeColors.valioBlueDark,
		navigationHeaderSecondaryBackground: themeColors.valioBlue20,
		navigationBackground: themeColors.valioBlue,
		navigationSecondaryBackground: themeColors.flower,
		navigationMenuBackground: themeColors.white,
		navigationHighlight: themeColors.flower,
		navigationText: themeColors.white,
		navigationItemInActive: themeColors.blueGray,
		navigationItemAlternative: themeColors.echoBlue,
		navigationTopBar: themeColors.valioBlue,

		nutrientBackground: themeColors.powderBlue20,
		nutrientTitle: themeColors.valioBlue,

		professionalContentBackground: themeColors.chive10,
		professionalContent: themeColors.chive,

		rating: themeColors.orange,
		ratingUnselected: themeColors.lightGrey,

		recipeCooperation: themeColors.lavendar,
		recipeCooperationBackground: themeColors.lavendar10,
		recipeUser: themeColors.powderBlue,
		recipeUserBackground: themeColors.powderBlue,
		recipeBrandComment: themeColors.powderBlue20,

		eventFormBackground: themeColors.valioBlue,
		eventFormTextPrimary: themeColors.white,
		eventFormTextSecondary: themeColors.echoBlue,
		eventFormSectionBackground: '#0A3876',
		eventFormInputBorder: 'rgba(230, 230, 230, 0.2)',
		eventFormInvalidBackground: themeColors.lavendar,

		selectedItem: themeColors.flower,
		selectedItemBackground: themeColors.flower20,

		shadow: themeColors.black20,
		shadowBranded: themeColors.valioBlue35,

		shoppingListBlue: themeColors.valioBlue,
		shoppingListRed: themeColors.lavendar,
		shoppingListPurple: themeColors.chili,
		shoppingListTurquoise: themeColors.turquoise,
		shoppingListOrange: themeColors.turmeric,
		shoppingListGreen: themeColors.chive,
		shoppingListLightBlue: themeColors.flower,
		shoppingListGrey: themeColors.liquorice,

		socialIcon: themeColors.middleGrey,

		stepActive: themeColors.valioBlue,
		stepComplete: themeColors.flower,
		stepIncomplete: themeColors.flower20,

		tabActive: themeColors.valioBlue,
		tabInactive: themeColors.flower,
		tabInactiveHover: themeColors.flowerHover,

		infoboxStrong: themeColors.valioBlue,
		infoboxMedium: themeColors.powderBlue,
		infoboxLight: themeColors.white,

		white: themeColors.white,
		white90: themeColors.white90,

		widgetZoneFullWidth: themeColors.lightGrey,
		widgetZoneFullWidthHighlighted: themeColors.powderBlue,
	},
	fonts: {
		primary: themeFonts.proximaNova,
		secondary: themeFonts.omnesPro,
		tertiary: themeFonts.omnesPro,
		primaryButtons: themeFonts.proximaNova,
		secondaryButtons: themeFonts.omnesPro,
	},
	zIndices: {
		titleBlockShadowBuster: 10,
		titleBlockTitle: 100,
		popovers: 110,
		tabsNavigation: 120,
		headerWithSearchBox: 130,
		navigation: 140,
		flyingThings: 1000,
		modal: 1200,
	},
	grid: {
		gutter: GUTTER,
		gutterInPx: (modifier: number = 1) => {
			return `${GUTTER * modifier}px`;
		},
	},
	typography: {
		heading: themeTextDefinitions.heading,
		navigation: themeTextDefinitions.navigation,
		button: themeTextDefinitions.button,
		nutrient: themeTextDefinitions.nutrient,
		contactPersonName: themeTextDefinitions.contactPersonName,
	},
	styledVariations: {
		productTitleImage: (useSmallImage: boolean) => css`
			top: ${useSmallImage ? '-200px' : '-200px'};

			${media.tablet`
				top: ${useSmallImage ? '-240px' : '-320px'};
			`};

			${media.desktop`
				top: ${useSmallImage ? '-240px' : '-320px'};
			`};

			${media.desktop1440`
				top: ${useSmallImage ? '-250px' : '-410px'};
			`};
		`,
		productCardImage: (size?: ProductCardSizes) => css`
			width: 100px;
			height: 100px;

			${() => {
				if (size === 'medium') {
					return css`
						${media.desktop`
							width: 140px;
							height: 140px;
						`};
					`;
				}

				if (size === 'large') {
					return css`
						${media.tablet`
							width: 140px;
							height: 140px;
						`};

						${media.desktop`
							width: 200px;
							height: 200px;
						`};
					`;
				}

				return null;
			}}
		`,
		contentLinkDecoration: () => css`
			background-position: 0 1.125em;
		`,
		heroBannerDefaultSize: undefined,
		fullWidthHeroBanner: false,
		mainContentOffset: () => css``,
		textWidgetContentAlignment: () => css``,
		comNavigationFlattenedStyle: () => css``,
	},
};

export default valio;
