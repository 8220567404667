import * as React from 'react';
import FacebookIcon from 'styleguide/components/Icons/SocialIcons/FacebookIcon/FacebookIcon';
import TwitterIcon from 'styleguide/components/Icons/SocialIcons/TwitterIcon/TwitterIcon';
import WhatsappIcon from 'styleguide/components/Icons/SocialIcons/WhatsappIcon/WhatsappIcon';
import PinterestIcon from 'styleguide/components/Icons/SocialIcons/PinterestIcon/PinterestIcon';
import styled, { css, ThemeContext } from 'styled-components';
import { media } from 'styleguide/helpers/media';
import { withWindow } from 'styleguide/helpers/window';
import { MapStateToProps, connect } from 'react-redux';
import { ContentWrapperProps } from '../General';
import { getSiteRelativePublicUrl } from 'utils/service';
import { RoutingState } from '../Routing/reducers';
import { State } from '../../reducers';
import {
	getSite,
	getTwitterUserName,
	getFacebookUserName,
	getPinterestUserName,
	getLinkedInUserName,
	getFacebookAppId,
	getEnableWhatsApp,
	getIsEmailShareEnabled,
} from '../App/services';
import { isNullOrWhiteSpace } from 'utils/strings';
import { LinkedinIcon, LinkIcon, SendToMailIcon, ShareLinkIcon } from 'styleguide/components/Icons';
import { LabeledIconButton } from 'styleguide/components/Buttons/LabeledIconButton';
import Modal from 'styleguide/components/Modals/Modal';
import { FormattedMessage, useIntl } from 'react-intl';
import Alert, { AlertType } from 'styleguide/components/Typography/Alert';

interface SocialLinksDisplayProps {
	clearPositioning?: boolean;
}

const SocialLinkContentWrapper = styled.section<ContentWrapperProps & SocialLinksDisplayProps>`
	display: flex;
	justify-content: flex-start;
	align-items: center;

	${props =>
		props.clearPositioning
			? ''
			: css`
					margin: ${({ theme }) => theme.grid.gutterInPx(1)} 0;
			  `};

	${props =>
		props.isPrintHidden
			? css`
					@media print {
						display: none;
					}
			  `
			: ''};
`;

const ShareSection = styled.section<SocialLinksDisplayProps>`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: space-evenly;
	padding: 10px 0;

	${media.desktop<SocialLinksDisplayProps>`
		padding: 17px 0;
	`};
`;

export const ShareSectionHeading = styled.span`
	font-family: ${props => props.theme.fonts.secondary};
	font-size: 15px;
	color: ${props => props.theme.colors.bodyText};
	margin-right: 5px;
	text-align: center;

	${media.tablet`
		font-size: 18px;
		margin-right: 30px;
	`};
`;

const SocialSectionLink = styled.a<SocialLinksDisplayProps>`
	margin: ${({ theme }) => theme.grid.gutterInPx(1)} 0;
	width: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	text-decoration: none;
	border-radius: 4px;
	border: 1px solid ${({ theme }) => theme.colors.border};
	font-weight: 600;
	font-size: 16px;
	cursor: pointer;

	> svg {
		vertical-align: bottom;
		margin: ${({ theme }) => theme.grid.gutterInPx(1)} ${({ theme }) => theme.grid.gutterInPx(2)}
			${({ theme }) => theme.grid.gutterInPx(1)} ${({ theme }) => theme.grid.gutterInPx(1)};

		${media.tablet`
			margin: ${({ theme }) => theme.grid.gutterInPx(1)} ${({ theme }) => theme.grid.gutterInPx(4)}
			${({ theme }) => theme.grid.gutterInPx(1)} ${({ theme }) => theme.grid.gutterInPx(1)};
		`};

		&.full-scale-icon {
			margin: ${({ theme }) => theme.grid.gutterInPx(1)} ${({ theme }) => theme.grid.gutterInPx(3)}
				${({ theme }) => theme.grid.gutterInPx(1)} ${({ theme }) => theme.grid.gutterInPx(2)};

			${media.tablet`
				margin: ${({ theme }) => theme.grid.gutterInPx(1)} ${({ theme }) => theme.grid.gutterInPx(5)}
				${({ theme }) => theme.grid.gutterInPx(1)} ${({ theme }) => theme.grid.gutterInPx(2)};
			`};
		}
	}
`;

const SocialLinkTextWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: space-between;
	color: ${({ theme }) => theme.colors.bodyText};
`;

const SocialLinkInformationText = styled.span`
	font-weight: 400;
	font-size: 14px;
	max-width: 250px;

	${media.tablet`
		max-width: 400px;
	`};

	${media.desktop`
		max-width: 650px;
	`};
`;

const onPopupOpen = (event: React.MouseEvent<HTMLAnchorElement>) => {
	event.preventDefault();
	withWindow(w => {
		w.open(event.currentTarget.href, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600');
	});
};

interface SocialLinksProps extends SocialLinksDisplayProps {
	title: string;
	url?: string;
	imageUrl?: string;
	heading?: string;
	hideFacebook?: boolean;
	hideTwitter?: boolean;
	hidePinterest?: boolean;
	hideLinkedin?: boolean;
	shareButtonText?: string;
}

const SocialLinks: React.FC<SocialLinksProps & SocialLinksStateProps> = ({
	routing: { pathname, search },
	routing,
	title,
	url,
	imageUrl,
	shareButtonText,
	heading,
	hideFacebook,
	hideTwitter,
	hidePinterest,
	hideLinkedin,
	twitterUserName,
	facebookAppId,
	facebookUserName,
	pinterestUserName,
	linkedinUserName,
	enableWhatsApp,
	isEmailShareEnabled,
	language,
	clearPositioning,
}) => {
	const theme = React.useContext(ThemeContext);

	const intl = useIntl();

	const [showModal, setShowModal] = React.useState(false);
	const [isCopied, setIsCopied] = React.useState(false);

	const handleShareIconClick = () => {
		setShowModal(true);
	};

	const onModalClose = () => {
		setShowModal(false);
	};

	const onCopyClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
		event.preventDefault();

		const textArea = document.createElement('textarea');
		textArea.value = event.currentTarget.href;
		document.body.appendChild(textArea);
		textArea.focus();
		textArea.select();

		withWindow(w => {
			try {
				w.document.execCommand('copy');
				setIsCopied(true);
				w.setTimeout(() => {
					setIsCopied(false);
				}, 5000);
			} catch (e) {
				// ? Show an indicator that text wasn't copied, or just leave as is?
			} finally {
				document.body.removeChild(textArea);
			}
		});
	};

	return (
		<SocialLinkContentWrapper isPrintHidden={true} clearPositioning={clearPositioning}>
			{!isNullOrWhiteSpace(heading) && (
				<ShareSectionHeading className="social-links-heading">{heading}</ShareSectionHeading>
			)}
			<LabeledIconButton icon={<ShareLinkIcon height={14} width={16} />} onClick={handleShareIconClick}>
				{shareButtonText ? shareButtonText : <FormattedMessage id="global_share_title" />}
			</LabeledIconButton>
			{showModal && (
				<Modal onClose={onModalClose} modalTitle={<FormattedMessage id="global_share_title" />}>
					<ShareSection>
						{!hideFacebook && facebookAppId && facebookUserName && (
							<SocialSectionLink
								rel="noopener noreferrer"
								className="ga-share-link ga-share-link--facebook"
								href={`https://www.facebook.com/dialog/share?app_id=${facebookAppId}&display=popup&href=${encodeURIComponent(
									url || getSiteRelativePublicUrl(routing, pathname + search)
								)}&redirect_uri=${encodeURIComponent(getSiteRelativePublicUrl(routing, pathname + search))}`}
								target="_blank"
								onClick={onPopupOpen}>
								<FacebookIcon />
								<SocialLinkTextWrapper>
									<FormattedMessage id="global_share_to_facebook" />
								</SocialLinkTextWrapper>
							</SocialSectionLink>
						)}
						{!hideTwitter && twitterUserName && (
							<SocialSectionLink
								rel="noopener noreferrer"
								className="ga-share-link ga-share-link--twitter"
								href={
									'https://twitter.com/intent/tweet?url=' +
									encodeURIComponent(url || getSiteRelativePublicUrl(routing, pathname + search)) +
									'&text=' +
									encodeURIComponent(title) +
									'&lang=' +
									language +
									'&via=' +
									twitterUserName
								}
								target="_blank"
								onClick={onPopupOpen}>
								<TwitterIcon />
								<SocialLinkTextWrapper>
									<FormattedMessage id="global_share_to_twitter" />
								</SocialLinkTextWrapper>
							</SocialSectionLink>
						)}
						{!hidePinterest && pinterestUserName && (
							<SocialSectionLink
								rel="noopener noreferrer"
								className="ga-share-link ga-share-link--pinterest"
								href={
									'https://www.pinterest.com/pin/create/button/?url=' +
									encodeURIComponent(url || getSiteRelativePublicUrl(routing, pathname + search)) +
									(imageUrl ? '&media=' + imageUrl : '') +
									'&description=' +
									encodeURIComponent(title)
								}
								target="_blank"
								onClick={onPopupOpen}>
								<PinterestIcon />
								<SocialLinkTextWrapper>
									<FormattedMessage id="global_share_to_pinterest" />
								</SocialLinkTextWrapper>
							</SocialSectionLink>
						)}
						{enableWhatsApp && (
							<SocialSectionLink
								rel="noopener noreferrer"
								className="ga-share-link ga-share-link--whatsapp"
								href={
									'whatsapp://send?text=' +
									encodeURIComponent(url || getSiteRelativePublicUrl(routing, pathname + search))
								}>
								<WhatsappIcon />
								<SocialLinkTextWrapper>
									<FormattedMessage id="global_share_to_whatsapp" />
								</SocialLinkTextWrapper>
							</SocialSectionLink>
						)}
						{!hideLinkedin && linkedinUserName && (
							<SocialSectionLink
								rel="noopener noreferrer"
								className="ga-share-link ga-share-link--linkedin"
								href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
									url || getSiteRelativePublicUrl(routing, pathname + search)
								)}`}
								target="_blank"
								onClick={onPopupOpen}>
								<LinkedinIcon color="#0e76a8" size={26} className="full-scale-icon" />
								<SocialLinkTextWrapper>
									<FormattedMessage id="global_share_to_linkedin" />
								</SocialLinkTextWrapper>
							</SocialSectionLink>
						)}
						{isEmailShareEnabled && (
							<SocialSectionLink
								rel="noopener noreferrer"
								className="ga-share-link ga-share-link--email"
								href={`mailto:?body=${encodeURIComponent(title)}%20${encodeURIComponent(
									url || getSiteRelativePublicUrl(routing, pathname + search)
								)}`}
								target="_blank">
								<SendToMailIcon color={theme.colors.brandPrimary} size={26} className="full-scale-icon" />
								<SocialLinkTextWrapper>
									<FormattedMessage id="global_share_with_email" />
								</SocialLinkTextWrapper>
							</SocialSectionLink>
						)}

						<SocialSectionLink
							rel="noopener noreferrer"
							className="ga-share-link ga-share-link--copy"
							href={url || getSiteRelativePublicUrl(routing, pathname + search)}
							onClick={onCopyClick}>
							<LinkIcon color={theme.colors.brandPrimary} size={26} className="full-scale-icon" />
							<SocialLinkTextWrapper>
								<FormattedMessage id="global_share_with_link" />
								<SocialLinkInformationText>
									{url || getSiteRelativePublicUrl(routing, pathname + search)}
								</SocialLinkInformationText>
							</SocialLinkTextWrapper>
						</SocialSectionLink>
					</ShareSection>

					{isCopied && (
						<Alert
							type={AlertType.Success}
							highlightedText={intl.formatMessage({ id: 'global_share_with_link_copied_notification' })}
							className="copied-notification">
							<FormattedMessage id="global_share_with_link_copied_instruction" />
						</Alert>
					)}
				</Modal>
			)}
		</SocialLinkContentWrapper>
	);
};
interface SocialLinksStateProps {
	routing: RoutingState;
	twitterUserName: string | undefined;
	facebookAppId: string | undefined;
	facebookUserName: string | undefined;
	pinterestUserName: string | undefined;
	linkedinUserName: string | undefined;
	enableWhatsApp: boolean;
	isEmailShareEnabled: boolean;
	language: string;
}

const mapStateToProps: MapStateToProps<SocialLinksStateProps, {}, State> = state => {
	const { routing } = state;
	const site = getSite(routing, state.app.settings?.sites ?? []);
	const language = state.resource.language || site?.defaultLanguage || 'FI';
	return {
		routing,
		language,
		facebookAppId: getFacebookAppId(state),
		facebookUserName: getFacebookUserName(state),
		twitterUserName: getTwitterUserName(state),
		pinterestUserName: getPinterestUserName(state),
		linkedinUserName: getLinkedInUserName(state),
		enableWhatsApp: getEnableWhatsApp(state),
		isEmailShareEnabled: getIsEmailShareEnabled(state),
	};
};

export default connect(mapStateToProps)(SocialLinks);
