import { css } from 'styled-components';
import { media } from 'styleguide/helpers';
import extend from './extend';
import { default as baseTheme, themeColors as baseColors } from './valio';

export const themeColors = {
	// Oddlygood 2022
	greenPesto: '#248641',
	greenPesto35: 'rgba(36, 134, 65, 0.35)',
	cream: '#f1e6b2',
	limeGreen: '#cfeb80',
	neutralGray: '#f2f2f2',

	black: '#000000',

	// Block color
	blockRed: '#ab2328',
	blockPink: '#f77594',
	blockPink20: 'rgba(247, 117, 148, 0.2)',
	blockOrange: '#dc582a',
	blockYellow: '#fac93d',
	blockYellow20: 'rgba(250, 201, 61, 0.2)',
	blockBrown: '#473729',
	blockSalmon: '#e7b78a',
	blockBlue: '#00205b',
	blockCyan: '#78d2f7',
	blockPurple: '#582c83',
	blockLavender: '#9091c7',

	// Valio
	darkGreen: '#108141',
	lightGreen: '#88ba32',
	// beige: '#FFDAA2',
	// lightBeige: '#FFE7C1',
	// pink: '#F08589',
	// pinkHover: '#F08589',
	// pink20: 'rgba(240, 133, 137, 0.2)',
	// yellow: '#FFC845',
	// yellow20: 'rgba(255, 200, 69, 0.2)',

	// red: 'rgb(177, 31, 44)',
	// deepBlue: '#0170af',
	// skyBlue: '#8ed4f4',

	_404VeryDark: '#014636',
	_404VeryLight: '#c8dfca',
	_404Light: '#a2c7a4',
	_404White99: '#fdfeff',

	white90: 'rgba(255, 255, 255, 0.9)',
	white: '#ffffff',

	transparent: 'rgba(255,255,255,0)',
};

export const oddlygoodThemeFonts = {
	futuraPTCondensed: 'futura-pt-condensed, sans-serif',
	americanTypewriter: 'itc-american-typewriter-cond, serif',
	omnesPro: 'omnes-pro, Arial, sans-serif',

	futuraPassata: 'FuturaPassata, sans-serif',
	lato: 'Lato, sans-serif',
};

const oddlygood = extend(baseTheme, {
	filters: {
		iconFilter:
			'brightness(0%) saturate(100%) invert(42%) sepia(12%) saturate(2660%) hue-rotate(85deg) brightness(95%) contrast(83%)',
	},
	colors: {
		adminToolbar: themeColors.greenPesto35,

		articleDefaultTag: themeColors.blockYellow,

		actionSuccessBackground: themeColors.limeGreen,
		actionSuccessText: themeColors.greenPesto,
		actionInfoBackground: themeColors.cream,
		actionInfoText: themeColors.greenPesto,
		actionErrorBackground: themeColors.blockPink,
		actionErrorText: themeColors.blockRed,
		bodyTextLight: baseColors.middleGrey,
		bodyBackground: themeColors.cream,

		border: themeColors.cream,
		borderDark: themeColors.blockYellow,
		borderHighlight: themeColors.blockPink,
		borderNavigation: themeColors.cream,

		brandPrimary: themeColors.greenPesto,
		brandPrimaryFeatureBackground: themeColors.lightGreen,
		brandSecondary: themeColors.blockYellow,
		brandTertiary: themeColors.blockPink,

		buttonPrimary: themeColors.greenPesto,
		buttonPrimaryHover: themeColors.darkGreen,
		buttonSecondary: themeColors.greenPesto,
		buttonSecondaryBorder: themeColors.white,
		buttonSecondaryHover: themeColors.white,
		buttonSecondaryBorderHover: themeColors.white,
		buttonSecondaryText: themeColors.white,
		buttonSecondaryHoverText: themeColors.greenPesto,
		buttonTertiary: themeColors.greenPesto,
		buttonTertiaryHover: themeColors.darkGreen,
		buttonHighlightText: themeColors.white,
		buttonHighlightTextHover: themeColors.white,
		buttonHighlightBackground: themeColors.blockPink,
		buttonHighlightBackgroundHover: themeColors.blockRed,
		buttonOutlineText: themeColors.greenPesto,
		buttonOutlineTextHover: themeColors.darkGreen,
		buttonOutlineTextSecondary: themeColors.greenPesto,
		buttonOutlineTextSecondaryHover: themeColors.white,
		buttonOutlineBackground: themeColors.cream,
		buttonOutlineBackgroundHover: themeColors.cream,
		buttonOutlineBackgroundSecondary: themeColors.transparent,
		buttonOutlineBackgroundSecondaryHover: themeColors.greenPesto,
		buttonOutlineBorder: themeColors.cream,
		buttonOutlineBorderHover: themeColors.darkGreen,
		buttonOutlineBorderSecondary: themeColors.greenPesto,
		buttonOutlineBorderSecondaryHover: themeColors.greenPesto,

		chessSquareOddHeader: themeColors.blockBrown,
		chessSquareEvenHeader: themeColors.blockPink,

		contentSection: themeColors.cream,
		contentSectionHiglighted: themeColors.blockSalmon,

		component404SvgBorder: themeColors._404VeryDark,
		component404SvgAccentVeryLight: themeColors._404VeryLight,
		component404SvgAccentLight: themeColors._404Light,
		component404SvgAccentSpoon: themeColors._404White99,

		componentNoResultsSvgAccentPuddle: themeColors._404VeryLight,
		componentNoResultsSvgAccentWhite99: themeColors._404White99,
		componentNoResultsSvgAccentVeryLight: themeColors._404VeryLight,
		componentNoResultsSvgAccentLight: themeColors._404Light,

		dropdownButtonTextClosed: themeColors.greenPesto,

		footerBackground: themeColors.black,
		framedHeaderTitle: themeColors.greenPesto,

		heading: themeColors.greenPesto,

		imagePlaceHolder: themeColors.cream,

		inputInfo: themeColors.blockYellow,
		linkText: themeColors.blockPink,
		linkTextHover: themeColors.blockRed,
		linkTextVisited: themeColors.greenPesto,
		linkTextAlternative: themeColors.blockYellow,

		navigationHeaderBackground: themeColors.greenPesto,
		navigationHeaderSecondaryBackground: themeColors.greenPesto,
		navigationBackground: themeColors.greenPesto,
		navigationSecondaryBackground: themeColors.greenPesto,
		navigationMenuBackground: themeColors.cream,
		navigationHighlight: themeColors.blockPink,
		navigationText: themeColors.white,
		navigationItemInActive: themeColors.greenPesto,
		navigationItemAlternative: themeColors.greenPesto,
		navigationSiteSelectorText: themeColors.white,
		navigationSiteSelectorBackground: themeColors.greenPesto,
		navigationSiteSelectorOpenText: themeColors.greenPesto,
		navigationSiteSelectorOpenBackground: themeColors.white,

		nutrientBackground: themeColors.limeGreen,
		nutrientTitle: themeColors.lightGreen,

		professionalContentBackground: themeColors.blockCyan,
		professionalContent: themeColors.blockBlue,

		rating: themeColors.blockPink,
		ratingUnselected: themeColors.cream,

		recipeCooperation: themeColors.limeGreen,
		recipeCooperationBackground: themeColors.lightGreen,
		recipeUser: themeColors.blockYellow,
		recipeUserBackground: themeColors.cream,
		recipeBrandComment: themeColors.cream,

		eventFormBackground: themeColors.greenPesto,
		eventFormTextPrimary: themeColors.cream,
		eventFormTextSecondary: themeColors.greenPesto,
		eventFormInvalidBackground: themeColors.limeGreen,

		selectedItem: themeColors.blockPink,
		selectedItemBackground: themeColors.blockPink20,
		shadowBranded: themeColors.greenPesto35,
		stepActive: themeColors.greenPesto,
		stepComplete: themeColors.blockYellow,
		stepIncomplete: themeColors.blockYellow20,

		tabActive: themeColors.greenPesto,
		tabInactive: themeColors.blockPink,
		tabInactiveHover: themeColors.blockPink, // pinkHover,
		infoboxStrong: baseColors.valioBlue,
		infoboxMedium: themeColors.blockCyan,
		infoboxLight: themeColors.cream,

		widgetZoneFullWidth: themeColors.cream,
		widgetZoneFullWidthHighlighted: themeColors.blockYellow,
	},
	fonts: {
		primary: oddlygoodThemeFonts.lato,
		secondary: oddlygoodThemeFonts.lato,
		primaryButtons: oddlygoodThemeFonts.lato,
		secondaryButtons: oddlygoodThemeFonts.lato,
	},
	typography: {
		heading: css`
			font-family: ${oddlygoodThemeFonts.futuraPassata} !important;
			font-weight: 700;
			text-transform: uppercase;
		`,
		navigation: css`
			font-family: ${oddlygoodThemeFonts.futuraPassata};
			font-weight: 700;
			text-transform: uppercase;
			font-size: 16px !important;
		`,
	},
	styledVariations: {
		productTitleImage: () => css`
			top: -180px;

			${media.phone`
			top: -240px;
		`};

			${media.tablet`
			top: -400px;
		`};

			${media.desktop`
			top: -380px;
		`};

			${media.desktop1440`
			top: -500px;
		`};

			& > picture {
				display: block;
			}
		`,
		productCardImage: () => css`
			width: 140px;
			height: 140px;

			${media.phone560`
			width: 200px;
			height: 200px;
		`};
		`,
		contentLinkDecoration: () => css`
			background-position: 0 1.175em;
		`,
		textWidgetContentAlignment: () => css`
			text-align: center;
		`,
		comNavigationFlattenedStyle: () => css`
			a {
				color: ${themeColors.greenPesto};
			}

			svg {
				path {
					fill: ${themeColors.greenPesto} !important;
				}
			}

			button {
				background-color: ${themeColors.white};
				color: ${themeColors.greenPesto};
			}
		`,
		siteLogoHeight: {
			desktop: 50,
			mobile: 42,
		},
	},
});

export default oddlygood;
