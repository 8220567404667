import { SiteId } from '../App/services';

type FourOhFourLinksLayout = Array<{ url: string; title: string }>;

interface LinksConfig {
	hasSolidLinks: boolean;
	links: FourOhFourLinksLayout;
}

const defaultConfig: LinksConfig = {
	hasSolidLinks: false,
	links: [
		{
			url: '/',
			title: 'component_404_home',
		},
	],
};

const config: { [key: string]: LinksConfig } = {
	[SiteId.fi]: {
		...defaultConfig,
		links: [
			...defaultConfig.links,
			{
				url: '/tuotteet/haku/',
				title: 'component_404_search',
			},
			{
				url: '/reseptit/',
				title: 'component_404_recipes',
			},
			{
				url: '/ammattilaiset/',
				title: 'component_404_prof',
			},
		],
	},
	[SiteId.com]: {
		...defaultConfig,
		links: [
			...defaultConfig.links,
			{
				url: '/we-are-valio/media-contacts/',
				title: 'component_404_contactus',
			},
		],
	},
	[SiteId.es]: {
		...defaultConfig,
		links: [
			...defaultConfig.links,
			{
				url: '/productos/',
				title: 'component_404_products',
			},
		],
	},
	[SiteId.pl]: {
		...defaultConfig,
		links: [
			...defaultConfig.links,
			{
				url: '/produkty/',
				title: 'component_404_products',
			},
		],
	},
	[SiteId.se]: {
		...defaultConfig,
		links: [
			...defaultConfig.links,
			{
				url: '/produkter/',
				title: 'component_404_products',
			},
			{
				url: '/recept/',
				title: 'component_404_recipes',
			},
		],
	},
	[SiteId.us]: {
		...defaultConfig,
		links: [
			...defaultConfig.links,
			{
				url: '/products/',
				title: 'component_404_products',
			},
		],
	},
	[SiteId.og]: {
		...defaultConfig,
		hasSolidLinks: true,
		links: [
			...defaultConfig.links,
			{
				url: '/products/',
				title: 'component_404_products',
			},
		],
	},
	[SiteId.ogUK]: {
		...defaultConfig,
		hasSolidLinks: true,
		links: [
			...defaultConfig.links,
			{
				url: '/our-products/',
				title: 'component_404_products',
			},
		],
	},
	[SiteId.ogUs]: {
		...defaultConfig,
		hasSolidLinks: true,
		links: [
			...defaultConfig.links,
			{
				url: '/products/dairy-free-oat-yogurt-alternatives/',
				title: 'component_404_products',
			},
			{
				url: '/recipes/',
				title: 'component_404_recipes',
			},
		],
	},
	[SiteId.ogFi]: {
		...defaultConfig,
		hasSolidLinks: true,
		links: [...defaultConfig.links],
	},
	[SiteId.ogSe]: {
		...defaultConfig,
		hasSolidLinks: true,
		links: [...defaultConfig.links],
	},
	[SiteId.ogEe]: {
		...defaultConfig,
		hasSolidLinks: true,
		links: [...defaultConfig.links],
	},
	[SiteId.ogEs]: {
		...defaultConfig,
		hasSolidLinks: true,
		links: [
			...defaultConfig.links,
			{
				url: '/productos/',
				title: 'component_404_products',
			},
		],
	},
	[SiteId.ogIe]: {
		...defaultConfig,
		hasSolidLinks: true,
		links: [
			...defaultConfig.links,
			{
				url: '/products/',
				title: 'component_404_products',
			},
		],
	},
	[SiteId.ogPl]: {
		...defaultConfig,
		hasSolidLinks: true,
		links: [
			...defaultConfig.links,
			{
				url: '/produkty/',
				title: 'component_404_products',
			},
		],
	},
	[SiteId.mjm]: {
		...defaultConfig,
		links: [
			...defaultConfig.links,
			{
				url: '/haku/',
				title: 'component_404_mjm_articles',
			},
		],
	},
	[SiteId.finc]: {
		...defaultConfig,
		links: [
			...defaultConfig.links,
			{
				url: '/products/',
				title: 'component_404_products',
			},
			{
				url: '/recipes/recipe-search/',
				title: 'component_404_recipes',
			},
		],
	},
	[SiteId.ee]: {
		...defaultConfig,
		links: [
			...defaultConfig.links,
			{
				url: '/tooted/',
				title: 'component_404_products',
			},
			{
				url: '/retseptid/',
				title: 'component_404_recipes',
			},
		],
	},
	[SiteId.eeEn]: {
		...defaultConfig,
		links: [
			...defaultConfig.links,
			{
				url: '/products/',
				title: 'component_404_products',
			},
		],
	},
	[SiteId.eeRu]: {
		...defaultConfig,
		links: [
			...defaultConfig.links,
			{
				url: '/produkty/',
				title: 'component_404_products',
			},
		],
	},
	[SiteId.eeExP]: {
		...defaultConfig,
		links: [
			...defaultConfig.links,
			{
				url: '/products/',
				title: 'component_404_products',
			},
		],
	},
	[SiteId.lv]: {
		...defaultConfig,
		links: [
			...defaultConfig.links,
			{
				url: '/produkti/',
				title: 'component_404_products',
			},
			{
				url: '/receptes/',
				title: 'component_404_recipes',
			},
		],
	},
	[SiteId.lvEn]: {
		...defaultConfig,
		links: [
			...defaultConfig.links,
			{
				url: '/products/',
				title: 'component_404_products',
			},
		],
	},
	[SiteId.lvRu]: {
		...defaultConfig,
		links: [
			...defaultConfig.links,
			{
				url: '/produkty/',
				title: 'component_404_products',
			},
		],
	},
	[SiteId.lt]: {
		...defaultConfig,
		links: [
			...defaultConfig.links,
			{
				url: '/produktai/',
				title: 'component_404_products',
			},
			{
				url: '/receptai/',
				title: 'component_404_recipes',
			},
		],
	},
	[SiteId.ltRu]: {
		...defaultConfig,
	},
};

export const get404LinksConfig = (siteId: SiteId): LinksConfig | undefined => config[siteId];
