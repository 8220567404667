import { Reducer } from 'react';
import { NewsArticleState, NewsArchiveApiShape } from './interfaces';
import { createCustomAction, ActionType, getType } from 'typesafe-actions';

export const newsArchiveActions = {
	getArchive: createCustomAction('NEWSARCHIVE/GET', type => (siteUrlId: string) => ({
		type,
		siteUrlId,
	})),
	getArchiveSuccess: createCustomAction('NEWSARCHIVE/GET_SUCCESS', type => ({ years }: NewsArchiveApiShape) => ({
		type,
		payload: { years },
	})),
};

export type NewsArchiveActions = ActionType<typeof newsArchiveActions>;

export const newsArticleReducer: Reducer<NewsArticleState, NewsArchiveActions> = (state, action) => {
	switch (action.type) {
		case getType(newsArchiveActions.getArchive):
			return { ...state, newsArchive: { isLoading: true } };
		case getType(newsArchiveActions.getArchiveSuccess):
			return { ...state, newsArchive: { years: action.payload.years, isLoading: false } };
		default:
			return state;
	}
};
