import * as React from 'react';
import styled from 'styled-components';

const StyledCookingTime = styled.div`
	font-family: ${props => props.theme.fonts.secondary};
	font-size: 13px;
	font-weight: 600;
	line-height: 1.23;
	text-align: right;
	color: #ffffff;
	text-shadow: 0 1px 6px rgba(0, 0, 0, 0.5);
	padding-right: 9px;
`;

const CookingTime: React.FC = ({ children }) => <StyledCookingTime>{children}</StyledCookingTime>;

/** @component */
export default CookingTime;
