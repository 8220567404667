import { fetchJson, postJson, putJson } from 'utils/service';

export interface ProfileApiShape {
	email?: string;
	firstName?: string;
	lastName?: string;
	username?: string;
	newsletter?: boolean;
	familyNewsletter?: boolean;
}

export interface ChangePasswordAMShape {
	oldPassword: string | undefined;
	newPassword: string | undefined;
	confirmPassword: string | undefined;
}

const fetchCurrentProfile = async (): Promise<ProfileApiShape> => {
	return await fetchJson<ProfileApiShape>('/api/v2/profile');
};

const updateContactInfo = async (profile: ProfileApiShape): Promise<ProfileApiShape> => {
	return await putJson('api/v2/profile', profile);
};

const updatePassword = async (changePassword: ChangePasswordAMShape): Promise<any> => {
	return await postJson('api/v2/profile/change-password', changePassword);
};

const profileApi = {
	fetchCurrentProfile,
	updateContactInfo,
	updatePassword,
};

type ProfileApi = typeof profileApi;

export { profileApi, ProfileApi };
