import * as React from 'react';
import NormalIconWrapper, { NormalIconWrapperProps } from '../NormalIconWrapper';
import { ThemeContext } from 'styled-components';

const ArrowLeftIcon: React.FC<NormalIconWrapperProps & React.SVGAttributes<SVGElement>> = ({ color, ...props }) => {
	const theme = React.useContext(ThemeContext);

	return (
		<NormalIconWrapper {...props}>
			<path
				fill={color ?? theme.colors.brandPrimary}
				d="M7.293 12.707L17 3a1 1 0 0 1 1.414 1.414l-8.293 8.293L18.414 21A1 1 0 0 1 17 22.414l-9.707-9.707z"
			/>
		</NormalIconWrapper>
	);
};

export default ArrowLeftIcon;
