import * as React from 'react';
import styled from 'styled-components';
import { media } from 'styleguide/helpers';
import Logo from 'styleguide/components/Logos/Logo';
import { NavigationMenuItemProps } from '../Navigation/interfaces';
import { useIntl } from 'react-intl';
import { SocialUserNameProps } from './interfaces';
import { getFooterConfig, FooterLinkSection } from './footerConfig';
import { addSiteUrlPrefix, SiteId } from '../App/services';
import { ComSocialMediaLinks } from './ComSocialMediaLinks';

const borderColor = 'rgba(255, 255, 255, 0.2)';
const socialIconSize = 28;

const FooterWrapper = styled.footer<{ backgroundMediaUrl?: string }>`
	background-color: ${props => props.theme.colors.footerBackground};

	${({ backgroundMediaUrl }) =>
		backgroundMediaUrl &&
		`
		background-image: url('${backgroundMediaUrl}');
		background-size: contain;
		background-repeat: no-repeat;
		background-position-x: center;
	`};

	@media print {
		display: none;
	}
`;

const FooterContainer = styled.div`
	display: flex;
	flex-flow: row wrap;
	justify-content: flex-start;
	align-content: center;
	padding: 0 ${({ theme }) => theme.grid.gutterInPx(3)} ${({ theme }) => theme.grid.gutterInPx(4)};
	margin: 0 auto;

	${media.desktop`
    max-width: 1200px;
    flex-flow: row nowrap;
    justify-content: space-between;
		padding: 0 ${({ theme }) => theme.grid.gutterInPx(3)} ${({ theme }) => theme.grid.gutterInPx(8)};
	`};
`;

const SectionWrapper = styled.section`
	flex: 0 0 100%;
	display: flex;
	flex-flow: row wrap;
	justify-content: flex-start;
	align-content: center;

	${media.desktop`
		flex: 0 0 50%;
		flex-flow: row nowrap;
	`};
`;

const LeftSectionWrapper = styled(SectionWrapper)``;

const RightSectionWrapper = styled(SectionWrapper)`
	justify-content: flex-end;
	align-content: space-evenly;
	flex-flow: row;
	align-items: center;

	> div + div {
		padding: 0 0 0 ${({ theme }) => theme.grid.gutterInPx(1)};
	}

	${media.desktop`
		align-items: flex-end;
	`}
`;

const LogoWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	flex: 0 0 100%;
	padding-bottom: 26px;
	border-bottom: 1px solid ${borderColor};

	> a {
		max-width: 99px;
	}

	${media.desktop`
		flex: 0 0 99px;
		border-bottom: 0 none;
		padding: 0;
	`}
`;

const LinkListWrapper = styled.div`
	display: flex;
	align-self: center;
	flex-flow: column;
	flex: 1 1 auto;

	> ul:first-of-type {
		padding: 16px 0 0 0;
	}

	${media.desktop`
		flex: 1 1 auto;
		flex-flow: row nowrap;
		> ul:first-of-type {
			margin-left: ${showLogo => (showLogo ? 65 : 0)}px;
			padding: 0;
		}
		> ul:nth-of-type(2) {
			margin-left: 48px;
		}
		> ul:last-of-type {
			border-bottom: 0 none;
			padding: 0;
		}
	`}
`;

const LinkList = styled.ul`
	display: flex;
	flex-direction: column;
	list-style-type: none;
	flex: 0 0 100%;
	padding: 0;
	margin: 0;

	${media.desktop`
		flex: 0 1 100%;
		padding: 0;
		align-self: flex-start;
	`}
`;

const LinkItem = styled.li`
	${media.desktop`
		display: inline-block;
	`}

	> a {
		color: ${({ theme }) => theme.colors.white};
		text-decoration: none;
		font-family: ${({ theme }) => theme.fonts.primary};
		font-size: 14px;
		line-height: 1.4;
		font-weight: 600;
		letter-spacing: 1px;

		&:hover {
			text-decoration: underline;
		}
	}
`;

const CopyrightWrapper = styled.div`
	font-family: ${({ theme }) => theme.fonts.primary};
	font-size: 14px;
	font-weight: 300;
	line-height: 1.43;
	color: ${({ theme }) => theme.colors.white};

	${media.desktop`
		flex: 0 0 196px;
		align-self: flex-end;
	`};
`;

const CopyrightChapter = styled.p`
	${media.desktop`
		margin: 0 0 0.5em 0;
	`};
`;

const FooterTitle = styled.div`
	${({ theme }) => theme.typography.heading};

	color: ${({ theme }) => theme.colors.bodyBackground};
	padding: ${({ theme }) => theme.grid.gutterInPx(6)} 0 ${({ theme }) => theme.grid.gutterInPx(3)};
	font-size: 25px;
	line-height: 1;
	max-width: 80%;
	margin: auto;

	${media.phone660`
		max-width: 440px;
	`};

	${media.tablet`
		max-width: 70%;
		font-size: 36px;
		padding: ${({ theme }) => theme.grid.gutterInPx(12)} 0 ${({ theme }) => theme.grid.gutterInPx(6)};
	`};

	${media.desktop`
		max-width: 870px;
		font-size: 48px;
		padding: ${({ theme }) => theme.grid.gutterInPx(25)} 0 ${({ theme }) => theme.grid.gutterInPx(12)};
	`};
`;

interface FooterProps extends SocialUserNameProps {
	siteId: SiteId;
	siteUrlPrefix: string;
	backgroundMediaUrl?: string;
}

const OgFooter: React.FC<FooterProps> = ({ siteId, siteUrlPrefix, backgroundMediaUrl, ...props }) => {
	const intl = useIntl();

	const renderLink = (link: NavigationMenuItemProps, index: number) => (
		<LinkItem key={index}>
			<a
				href={addSiteUrlPrefix(siteUrlPrefix, link.url)}
				target={link.isExternal ? '_blank' : undefined}
				rel={link.isExternal ? 'noopener noreferrer' : undefined}>
				{intl.formatMessage({ id: link.title })} ›
			</a>
		</LinkItem>
	);

	function renderSection(linkSection: FooterLinkSection, index: number) {
		return <LinkList key={index}>{linkSection.links.map(renderLink)}</LinkList>;
	}

	const footerConfig = getFooterConfig(siteId);
	if (!footerConfig) {
		return null;
	}

	const { showLogo, logo, footerTitle, linkSections, showCopyright } = footerConfig;

	return (
		<FooterWrapper backgroundMediaUrl={backgroundMediaUrl}>
			{footerTitle && (
				<FooterTitle>
					<span>{intl.formatMessage({ id: footerTitle })}</span>
				</FooterTitle>
			)}
			<FooterContainer>
				<LeftSectionWrapper>
					{showLogo && (
						<LogoWrapper>
							<Logo logo={logo} disableScaling={true} logoSize={'99px'} href={siteUrlPrefix} />
						</LogoWrapper>
					)}
					<LinkListWrapper>{linkSections.map(renderSection)}</LinkListWrapper>
				</LeftSectionWrapper>
				<RightSectionWrapper>
					<ComSocialMediaLinks socialIconSize={socialIconSize} {...props} />
					{showCopyright && (
						<CopyrightWrapper>
							<CopyrightChapter>{intl.formatMessage({ id: 'component_comfooter_copyright' })}</CopyrightChapter>
						</CopyrightWrapper>
					)}
				</RightSectionWrapper>
			</FooterContainer>
		</FooterWrapper>
	);
};

export default OgFooter;
