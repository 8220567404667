import * as React from 'react';
import NormalIconWrapper, { NormalIconWrapperProps } from '../NormalIconWrapper';
import { ThemeContext } from 'styled-components';

const FeedbackIcon: React.FC<NormalIconWrapperProps & React.SVGAttributes<SVGElement>> = ({ color, ...props }) => {
	const theme = React.useContext(ThemeContext);

	return (
		<NormalIconWrapper {...props}>
			<path
				fill={color ?? theme.colors.brandPrimary}
				d="M23 1H3C1.346 1 0 2.346 0 4v14c0 1.654 1.346 3 3 3h3a1 1 0 0 0 0-2H3c-.551 0-1-.449-1-1V4c0-.551.449-1 1-1h20c.551 0 1 .449 1 1v14c0 .551-.449 1-1 1H12.086l-4.293 4.293a.999.999 0 1 0 1.414 1.414L12.914 21H23c1.654 0 3-1.346 3-3V4c0-1.654-1.346-3-3-3"
			/>
			<path fill={color} d="M7 10h11a1 1 0 0 0 0-2H7a1 1 0 0 0 0 2M7 14h11a1 1 0 0 0 0-2H7a1 1 0 0 0 0 2" />
		</NormalIconWrapper>
	);
};

export default FeedbackIcon;
