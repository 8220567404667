import * as React from 'react';
import { StarSize, StarSizes } from '../types';
import { ResponsiveValues } from 'common/components/Media';
import styled, { css } from 'styled-components';
import { media } from 'styleguide/helpers';

const responsiveSizeCss = (starSize?: StarSizes) => {
	if (starSize === StarSize.large) {
		return css`
			width: 24px;
			height: 22px;
		`;
	}
	if (starSize === StarSize.medium) {
		return css`
			width: 18px;
			height: 17px;
		`;
	}
	return css`
		width: 12px;
		height: 11px;
	`;
};

interface StarSizeProps {
	starSize: StarSizes | ResponsiveValues<StarSizes>;
}

const StyledSvg = styled.svg<StarSizeProps>`
	${({ starSize }) => {
		if (typeof starSize === 'object') {
			const sizeMap = starSize as ResponsiveValues<StarSizes>;

			return css`
				${Object.keys(sizeMap).map(key => {
					if (key === 'default') {
						return responsiveSizeCss(sizeMap.default);
					}
					return css`
						${media[key]`
							${responsiveSizeCss(sizeMap[key])}
						`};
					`;
				})};
			`;
		}

		return responsiveSizeCss(starSize);
	}};
`;

export interface StarSvgProps extends StarSizeProps {
	firstHalfColor: string;
	lastHalfColor: string;
}

const StarSvg: React.FC<StarSvgProps & React.SVGAttributes<SVGElement>> = ({
	starSize,
	firstHalfColor,
	lastHalfColor,
	...rest
}) => {
	return (
		<StyledSvg
			starSize={starSize}
			xmlns="http://www.w3.org/2000/svg"
			width={24}
			height={22}
			viewBox={`0 0 24 22`}
			{...rest}>
			<g fill="none" fillRule="evenodd">
				<path
					fill={lastHalfColor}
					d="M12 18l-7.053 3.708 1.347-7.854L.587 8.292l7.886-1.146L12 0l3.527 7.146 7.886 1.146-5.707 5.562 1.347 7.854z"
				/>
				<path fill={firstHalfColor} d="M12 18l-7.053 3.708 1.347-7.854L.587 8.292l7.886-1.146L12 0z" />
			</g>
		</StyledSvg>
	);
};

StarSvg.defaultProps = {
	starSize: StarSize.small,
};

export default StarSvg;
