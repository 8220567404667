import * as React from 'react';
import Modal from 'styleguide/components/Modals/Modal/Modal';
import Divider from 'styleguide/components/Divider/Divider';
import { DividerDirection, DividerSize } from 'styleguide/components/Divider/Divider';
import LoginForm, { LoginFormFields } from './components/LoginForm';
import { connect, MapStateToProps } from 'react-redux';
import RegistrationRequestForm, { RegistrationRequestFormFields } from './components/RegistrationRequestForm';
import ForgotPasswordForm, { ForgotPasswordFormFields } from './components/ForgotPasswordForm';
import LoginDialog from './components/LoginDialog';
import RegistrationRequestDialog from './components/RegistrationRequestDialog';
import { LoginModalFormType } from './types';
import FacebookAuth from './components/FacebookAuth';
import { LoginState } from './reducers/reducers';
import { State } from '../../reducers';
import { loginActions } from './reducers/actions';
import RegistrationDialog from './components/RegistrationDialog';
import RegistrationForm, { RegistrationFormFields } from './components/RegistrationForm';
import RegistrationRequestSent from './components/RegistrationRequestSent';
import ResetPasswordDialog from './components/ResetPasswordDialog';
import ResetPasswordForm, { ResetPasswordFormFields } from './components/ResetPasswordForm';
import { useIntl } from 'react-intl';
import { getSiteUrlId } from '../App/services';

interface LoginAndRegistrationProps {
	facebookAppId: string | null | undefined;
	openDialogBasedOnQuery: LoginModalFormType | null;
}

type Props = LoginAndRegistrationProps & LoginAndRegistrationModalStateProps & LoginAndRegistrationModalDispatchProps;

const LoginAndRegistrationModal: React.FC<Props> = ({
	facebookAppId,
	login,
	doLogin,
	closeLogin,
	openLogin,
	requestRegisterUser,
	registerUser,
	resetPassword,
	getUniqueUserName,
	forgotPassword,
	changeLoginFormType,
	siteUrlId,
	openDialogBasedOnQuery,
}) => {
	const intl = useIntl();

	React.useEffect(() => {
		if (openDialogBasedOnQuery) {
			openLogin(openDialogBasedOnQuery);
		}
	}, []);

	const { loginFormType } = login;

	const onClose = () => {
		changeLoginFormType(LoginModalFormType.LOGIN);
		closeLogin();
	};

	const onLoginFormSubmit = (fields: LoginFormFields) => {
		const { email, password, rememberLogin } = fields;
		doLogin(email.value, password.value, rememberLogin.value);
	};

	const onRegistrationRequestFormSubmit = (fields: RegistrationRequestFormFields) => {
		const { email } = fields;
		requestRegisterUser(email.value);
	};

	const onRegistrationFormSubmit = (fields: RegistrationFormFields) => {
		const { email, password, subscribeNewsletter, userName, hash } = fields;
		registerUser(email.value, userName.value, password.value, subscribeNewsletter.value, hash.value, siteUrlId);
	};

	const onResetPasswordFormSubmit = (fields: ResetPasswordFormFields) => {
		const { email, password, hash } = fields;
		resetPassword(email.value, password.value, hash.value);
	};

	const onGetUniqueUserName = (userName: string) => {
		getUniqueUserName(userName);
	};

	const onForgotPasswordFormSubmit = (fields: ForgotPasswordFormFields) => {
		const { email } = fields;
		forgotPassword(email.value);
	};

	const showRegistrationForm = () => {
		changeLoginFormType(LoginModalFormType.REGISTRATION_REQUEST);
	};

	const showForgotPasswordForm = () => {
		changeLoginFormType(LoginModalFormType.FORGOT_PASSWORD);
	};

	if (login && login.isLoginOpen && loginFormType) {
		return (
			<Modal onClose={onClose}>
				{loginFormType === LoginModalFormType.LOGIN && (
					<LoginDialog onCreateLogin={showRegistrationForm}>
						<LoginForm
							onFormSubmit={onLoginFormSubmit}
							onForgotPassword={showForgotPasswordForm}
							disableClearOnSubmit
						/>
						{facebookAppId && <FacebookAuth appId={facebookAppId} />}
					</LoginDialog>
				)}
				{loginFormType === LoginModalFormType.REGISTRATION_REQUEST && (
					<RegistrationRequestDialog mode="form">
						<RegistrationRequestForm onFormSubmit={onRegistrationRequestFormSubmit} />
						<Divider direction={DividerDirection.horizontal} size={DividerSize.full} />
						{facebookAppId && <FacebookAuth appId={facebookAppId} />}
					</RegistrationRequestDialog>
				)}
				{loginFormType === LoginModalFormType.REGISTRATION_REQUEST_SENT && (
					<RegistrationRequestDialog
						mode="confirmation"
						title={intl.formatMessage({ id: 'component_login_check_your_email' })}>
						<RegistrationRequestSent onClose={onClose} />
					</RegistrationRequestDialog>
				)}
				{loginFormType === LoginModalFormType.REGISTRATION && (
					<RegistrationDialog>
						<RegistrationForm
							uniqueUserName={login.registrationForm && login.registrationForm.uniqueUserName}
							uniqueUserNameError={login.registrationForm && login.registrationForm.uniqueUserNameError}
							onFormSubmit={onRegistrationFormSubmit}
							onGetUniqueUserName={onGetUniqueUserName}
						/>
					</RegistrationDialog>
				)}
				{loginFormType === LoginModalFormType.FORGOT_PASSWORD && (
					<LoginDialog onCreateLogin={showRegistrationForm}>
						<ForgotPasswordForm onFormSubmit={onForgotPasswordFormSubmit} />
						{facebookAppId && <FacebookAuth appId={facebookAppId} />}
					</LoginDialog>
				)}
				{loginFormType === LoginModalFormType.RESET_PASSWORD && (
					<ResetPasswordDialog>
						<ResetPasswordForm onFormSubmit={onResetPasswordFormSubmit} />
					</ResetPasswordDialog>
				)}
			</Modal>
		);
	}

	return null;
};

interface LoginAndRegistrationModalStateProps {
	login: LoginState;
	siteUrlId: string;
}

const mapStateToProps: MapStateToProps<LoginAndRegistrationModalStateProps, LoginAndRegistrationProps, State> = ({
	login,
	routing,
	app,
}): LoginAndRegistrationModalStateProps => {
	const sites = (app.settings && app.settings.sites) || [];
	return {
		login,
		siteUrlId: getSiteUrlId(routing, sites) || '',
	};
};

interface LoginAndRegistrationModalDispatchProps {
	openLogin: typeof loginActions.openLogin;
	closeLogin: typeof loginActions.closeLogin;
	doLogin: typeof loginActions.doLogIn;
	changeLoginFormType: typeof loginActions.changeLoginFormType;
	requestRegisterUser: typeof loginActions.requestRegisterUser;
	registerUser: typeof loginActions.registerUser;
	resetPassword: typeof loginActions.resetPassword;
	getUniqueUserName: typeof loginActions.getUniqueUserName;
	forgotPassword: typeof loginActions.forgotPassword;
}

export default connect(mapStateToProps, {
	openLogin: loginActions.openLogin,
	closeLogin: loginActions.closeLogin,
	doLogin: loginActions.doLogIn,
	changeLoginFormType: loginActions.changeLoginFormType,
	requestRegisterUser: loginActions.requestRegisterUser,
	registerUser: loginActions.registerUser,
	resetPassword: loginActions.resetPassword,
	getUniqueUserName: loginActions.getUniqueUserName,
	forgotPassword: loginActions.forgotPassword,
})(LoginAndRegistrationModal);
