import * as React from 'react';
import SocialIconWrapper from '../SocialIconWrapper';
import { IconWrapperProps } from '../../../../interfaces';

const WhatsappIcon: React.FC<IconWrapperProps & React.SVGAttributes<SVGElement>> = ({ color, ...props }) => {
	return (
		<SocialIconWrapper alt="WhatsApp" {...props}>
			<path
				fill={color}
				d="M11.125 21.551c5 3.125 11.063-.438 11.563-5.5.437-4.625-3.125-8.75-8-8.438-5.5.44-8.688 7.188-5.438 12.19a50.25 50.25 0 0 0-.75 2.5c.938-.25 1.75-.626 2.688-.814l-.063.063zM6 24.801l1.563-4.813C4.124 13.74 8.5 6.676 14.313 6.052c5.5-.563 10 3.5 10 9.063 0 5.812-5.5 10.75-11.688 8.75-.5-.125-1.063-.625-1.625-.625-.75-.063-1.75.5-2.5.75-.875.25-1.563.562-2.438.75L6 24.801zm7.313-13.75c.562 1.125 1.375 2.75 0 3.5.687 1.438 1.875 2.5 3.375 3.125.5-.313.812-.813 1.25-1.25.874.375 1.687.75 2.374 1.25.126 1.625-1.25 2.25-2.5 2.188-2.5-.126-5.874-3.188-6.687-5.5-.5-1.376.063-3.938 2.188-3.313z"
			/>
		</SocialIconWrapper>
	);
};

WhatsappIcon.defaultProps = {
	color: '#25D366',
};

export default WhatsappIcon;
