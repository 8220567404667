import { Reducer } from 'redux';
import { UserRecipesApiRecipes } from '../services/cookbook';
import { userActions, UserActions } from './actions';
import { getType } from 'typesafe-actions';
import orderBy from 'lodash/orderBy';
import { UserRecipesShape, UserRecipe } from '../interfaces';

const initialState: UserRecipesShape = {
	isLoading: false,
	recipes: [],
	recipesSent: false,
};

export const userRecipeReducer: Reducer<UserRecipesShape, UserActions> = (state = initialState, action) => {
	switch (action.type) {
		//
		// FETCH USER RECIPES
		//
		case getType(userActions.fetchUserRecipes):
			return { ...state, isLoading: true };

		case getType(userActions.fetchUserRecipesSucceeded):
			return { isLoading: false, recipes: userRecipeRecipesReducer(state.recipes, action) };

		case getType(userActions.fetchUserRecipesError):
			return { ...state, isLoading: false, error: action.payload.error };

		//
		// SEND USER RECIPES
		//
		case getType(userActions.sendUserRecipes):
			return { ...state, recipesSent: true };

		case getType(userActions.sendUserRecipesSucceeded):
			return { ...state, recipesSendSucceeded: true };

		case getType(userActions.sendUserRecipesError):
			return { ...state, error: 'user_profile_recipe_sendmail_error' };

		//
		// REMOVE RECIPE
		//
		case getType(userActions.removeRecipeSucceeded):
			return {
				...state,
				recipes: state.recipes.filter(r => r.id !== action.payload.recipeId),
			};

		case getType(userActions.sortUserRecipes):
			const { sortProperty, sortDirection } = action.payload;

			const recipes = state.recipes.slice(0).sort((a, b) => {
				let localProperty = sortProperty;

				if (sortProperty === 'availableForDownload') {
					const aDownload = !!a.availableForDownload;
					const bDownload = !!b.availableForDownload;
					if (aDownload && !bDownload) {
						return -1;
					} else if (!aDownload && bDownload) {
						return 1;
					}
					localProperty = 'name';
				}

				const aValue = (a[localProperty] as string).toUpperCase();
				const bValue = (b[localProperty] as string).toUpperCase();

				if (aValue < bValue) {
					return sortDirection === 'asc' ? -1 : 1;
				}
				if (aValue > bValue) {
					return sortDirection === 'asc' ? 1 : -1;
				}
				return 0;
			});

			return {
				...state,
				recipes,
			};

		default:
			return state;
	}
};

const userRecipeRecipesReducer: Reducer<UserRecipe[], UserActions> = (state = [], action) => {
	switch (action.type) {
		case getType(userActions.fetchUserRecipesSucceeded):
			const sortedRecipes = orderBy(action.payload.recipes, ['creationTime'], 'desc');
			return sortedRecipes.map(
				(recipe: UserRecipesApiRecipes): UserRecipe => ({
					id: recipe.id,
					url: recipe.url,
					averageRating: recipe.averageRating,
					imageUrl: recipe.imageUrl,
					name: recipe.name,
					recipeTimeInMinutes: recipe.recipeTimeInMinutes,
					userIsCreator: recipe.userIsCreator,
					creationTime: recipe.creationTime,
					availableForDownload: recipe.availableForDownload,
					downloadUrl: recipe.downloadUrl,
				})
			);

		default:
			return state;
	}
};
