import { ImageSource, ImageDimensions } from 'styleguide/interfaces/imgInterfaces';
import { MediaBreakpoints } from 'styleguide/helpers/media';
import { filterNonNull } from './array';
import includes from 'lodash/includes';

function getImageSource(
	image: string | undefined,
	preset: string,
	media: keyof MediaBreakpoints,
	width: number
): ImageSource | undefined {
	if (!image) {
		return undefined;
	}

	const baseUrl = image;
	const url = preset ? `${baseUrl}/${preset}` : baseUrl;

	return {
		url,
		media,
		width,
	};
}

export function getImageSources(
	imageDesktop: string | undefined,
	imageMobile: string | undefined,
	presetSuffix: string
): ImageSource[] {
	const usedImageMobile = imageMobile || imageDesktop;
	return filterNonNull([
		getImageSource(imageDesktop, `1440x1080-${presetSuffix}`, 'desktop1440', 1440),
		getImageSource(imageDesktop, `1000x752-${presetSuffix}`, 'desktop', 1000),
		getImageSource(usedImageMobile, `768x578-${presetSuffix}`, 'tablet', 768),
		getImageSource(usedImageMobile, `375x282-${presetSuffix}`, 'phone', 375),
	]);
}

export function getFileSizeInMb(bytes: number): number {
	if (bytes !== undefined || bytes !== null) {
		return bytes / (1024 * 1024);
	}
	return 0;
}

export function getImageDimensions(file: File): Promise<ImageDimensions> {
	const imageFileType = ['image/jpeg', 'image/png', 'image/bmp', 'image/gif', 'image/tiff', 'image/webp'];

	return new Promise((resolve, reject) => {
		if (includes(imageFileType, file.type)) {
			const fileReader = new FileReader();

			fileReader.onload = () => {
				const result = fileReader.result;
				if (typeof result === 'string') {
					const image = new Image();

					image.onload = () => {
						resolve({
							width: image.width,
							height: image.height,
						});
					};

					image.src = result;
				} else {
					reject('Not an string');
				}
			};

			fileReader.readAsDataURL(file);
		} else {
			reject('Not an image');
		}
	});
}
