import { OrderStatus } from './interfaces';
import { Dictionary } from 'common/interfaces/common';

export const SEARCH_MATERIALS_PAGE_SIZE = 10;
export const ORDER_URL_HASH_BY_STATUS: { [key in OrderStatus]: string | undefined } = {
	SEARCH: undefined,
	CART: '#tilauskori',
	DELIVERY: '#toimitusehdot',
	CONFIRMATION: '#tilaus-valmis',
};

export const ORDER_STATUS_BY_HASH: Dictionary<OrderStatus> = {
	'#tilauskori': 'CART',
	'#toimitusehdot': 'DELIVERY',
	'#tilaus-valmis': 'CONFIRMATION',
};
