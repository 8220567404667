import * as React from 'react';
import NormalIconWrapper, { NormalIconWrapperProps } from '../NormalIconWrapper';
import { useIntl } from 'react-intl';
import { ThemeContext } from 'styled-components';

const CommentIcon: React.FC<NormalIconWrapperProps & React.SVGAttributes<SVGElement>> = ({ color, ...props }) => {
	const theme = React.useContext(ThemeContext);

	const intl = useIntl();
	return (
		<NormalIconWrapper alt={intl.formatMessage({ id: 'icon_comment' })} {...props}>
			<path
				fill={color ?? theme.colors.brandPrimary}
				d="M22.162 22.21c-.186 0-.372-.052-.535-.155l-3.809-2.414a.998.998 0 0 0-.788-.127 16.451 16.451 0 0 1-4.03.496c-3.527 0-6.831-1.076-9.304-3.032C1.313 15.094 0 12.618 0 10.006c0-2.613 1.313-5.089 3.696-6.973C6.169 1.077 9.473 0 13 0c3.528 0 6.832 1.077 9.305 3.033C24.688 4.917 26 7.393 26 10.006c0 2.138-.874 4.182-2.529 5.911-.2.211-.311.492-.309.783v4.51a.999.999 0 0 1-1 1zm-4.885-4.727c.569 0 1.125.162 1.61.468l2.275 1.442V16.7c0-.814.307-1.583.864-2.166C23.318 13.184 24 11.618 24 10.006c0-1.988-1.042-3.907-2.936-5.404C18.943 2.924 16.079 2 13 2c-3.079 0-5.942.924-8.063 2.602C3.043 6.099 2 8.018 2 10.006c0 1.987 1.043 3.906 2.937 5.403C7.058 17.087 9.921 18.01 13 18.01c1.207 0 2.399-.146 3.543-.435.243-.061.49-.092.734-.092z"
			/>
		</NormalIconWrapper>
	);
};

export default CommentIcon;
