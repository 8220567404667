import * as React from 'react';
import injectRoutable, { InjectedRoutableProps } from './Routable';

export interface LinkProps {
	to: string;
}

const Link: React.FC<LinkProps & InjectedRoutableProps & React.AnchorHTMLAttributes<HTMLAnchorElement>> = ({
	to,
	children,
	onLinkClick,
	navigateTo,
	siteUrlId,
	...rest
}) => (
	<a href={to} onClick={onLinkClick} {...rest}>
		{children}
	</a>
);

export default injectRoutable(Link);
