import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { media } from 'styleguide/helpers/media';
import MediumHeader from 'styleguide/components/Typography/MediumHeader/MediumHeader';

const ResetPasswordHeader = styled.header`
	text-align: center;
	margin-bottom: 20px;

	${media.desktop`
		text-align: left;
		padding: 0 25px;
	`};

	.medium-header {
		margin: 0;
	}
`;

const RegistrationWrapper = styled.div``;

const ResetPasswordDialog: React.FC<{}> = ({ children }) => {
	return (
		<>
			<ResetPasswordHeader>
				<MediumHeader className="medium-header">
					<FormattedMessage id="user_reset_password_title" />
				</MediumHeader>
			</ResetPasswordHeader>
			<RegistrationWrapper>{children}</RegistrationWrapper>
		</>
	);
};

export default ResetPasswordDialog;
