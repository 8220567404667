import * as React from 'react';
import { Button } from 'styleguide/components/Buttons/Button';
import styled from 'styled-components';
import { LabeledIconButton } from 'styleguide/components/Buttons/LabeledIconButton';
import { ArrowLeftIcon } from 'styleguide/components/Icons';

const Footer = styled.footer`
	margin: ${({ theme }) => theme.grid.gutterInPx(4)} auto;
	display: flex;
	justify-content: space-between;
`;

interface Props {
	showContinue?: boolean;
	continueDisabled?: boolean;
	continueLabel?: string;
	backButtonLabel: string;
	onBackClick: () => void;
}

const OrderFooter: React.FC<Props> = ({
	showContinue,
	continueLabel,
	continueDisabled,
	backButtonLabel,
	onBackClick,
}) => {
	return (
		<Footer>
			<LabeledIconButton icon={<ArrowLeftIcon />} onClick={onBackClick}>
				{backButtonLabel}
			</LabeledIconButton>
			{showContinue && (
				<Button type="submit" size="medium" disabled={continueDisabled}>
					{continueLabel}
				</Button>
			)}
		</Footer>
	);
};

export default OrderFooter;
