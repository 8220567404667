import * as React from 'react';
import NormalIconWrapper, { NormalIconWrapperProps } from '../NormalIconWrapper';
import { ThemeContext } from 'styled-components';

const ArrowRightIcon: React.FC<NormalIconWrapperProps & React.SVGAttributes<SVGElement>> = ({ color, ...props }) => {
	const theme = React.useContext(ThemeContext);

	return (
		<NormalIconWrapper {...props}>
			<path
				fill={color ?? theme.colors.brandPrimary}
				d="M18.707 12.707L9 22.414A1 1 0 0 1 7.586 21l8.293-8.293-8.293-8.293A1 1 0 1 1 9 3l9.707 9.707z"
			/>
		</NormalIconWrapper>
	);
};

export default ArrowRightIcon;
