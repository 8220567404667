import * as React from 'react';
import * as m2lLogo from './m2l_Logo.svg';

export interface MaidonjalostajatLogoIconProps {
	disableScaling?: boolean;
	logoSize?: string;
	inverted?: boolean;
}

const MaidonjalostajatLogoIcon: React.FC<MaidonjalostajatLogoIconProps> = ({ disableScaling, logoSize }) => {
	return <img src={m2lLogo} alt="Maidonjalostajat" width={disableScaling && logoSize ? logoSize : '100%'} />;
};

export default MaidonjalostajatLogoIcon;
