import { fetchJson } from 'utils/service';

export interface SearchFunctionalityItem {
	slug: string;
	count: number;
}

export interface ProductAspectSearchResult {
	count: number;
	searchFunctionalityData: SearchFunctionalityItem[];
}

async function searchProductAspects(siteUrlId: string): Promise<ProductAspectSearchResult> {
	return await fetchJson<ProductAspectSearchResult>(`/api/sites/${siteUrlId}/search/product`);
}

const api = {
	searchProductAspects,
};

export type ProductAspectSearchApi = typeof api;
export { api as productAspectSearchApi };
