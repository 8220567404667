import * as React from 'react';
import styled from 'styled-components';
import * as artificiallylactosefreelogo from './artificiallyLactoseFreeLogo.svg';
import * as blueswan from './blueSwan.svg';
import * as hyvsuomestaruokaaomastamaastalippumarkingforpackaging from './blueSwan.svg';
import * as butter from './butter.svg';
import * as grainspike from './grainSpike.svg';
import * as heartsign from './heartsign.svg';
import * as hyla from './hyla.svg';
import * as lactosefree from './lactoseFree.svg';
import * as lactosefreelogo from './lactoseFreeLogo.svg';
import * as lesssaltlogo from './LessSaltLogo.svg';
import * as lesssugarlogo from './LessSugarLogo.svg';
import * as lgglogo from './lggLogo.svg';
import * as marine from './marine.svg';
import * as novelty from './novelty.svg';
import * as organic from './organic.svg';
import * as realcream from './realCream.svg';
import * as recycle from './recycle.svg';
import * as recycleuht from './recycleUHT.svg';
import * as shake from './shake.svg';
import * as keyflag from './keyFlag.svg';
import * as fccalciumproteinsource from './fcCalciumProteinSource.png';
import * as fclactosefree from './fcLactoseFree.png';
import * as fcnoartificialhormones from './fcNoArtificialHormones.png';
import * as fcnongmo from './fcNonGMO.png';
import * as kosher from './kosher.png';
import * as koshertriangle from './kosherTriangle.png';
import * as fcaged6months from './fcAged6Months.png';
import * as fcaged7months from './fcAged7Months.png';
import * as fcaged8months from './fcAged8Months.png';
import * as fcaged60days from './fcAged60Days.png';
import * as fcaged100days from './fcAged100Days.png';
import { LazyImage } from '../../LazyImage';

const imageMap = {
	artificiallylactosefreelogo,
	blueswan,
	hyvsuomestaruokaaomastamaastalippumarkingforpackaging,
	butter,
	grainspike,
	heartsign,
	hyla,
	lactosefree,
	lactosefreelogo,
	lesssaltlogo,
	lesssugarlogo,
	lgglogo,
	marine,
	novelty,
	organic,
	realcream,
	recycle,
	recycleuht,
	shake,
	keyflag,
	fccalciumproteinsource,
	fclactosefree,
	fcnoartificialhormones,
	fcnongmo,
	kosher,
	koshertriangle,
	fcaged6months,
	fcaged7months,
	fcaged8months,
	fcaged60days,
	fcaged100days,
};

const PackageMarkWrapper = styled.div<PackageMarkProps>`
	width: ${props => (props.width ? `${props.width}` : '80px')};
	height: ${props => (props.height ? `${props.height}` : '80px')};
	position: relative;

	& > img {
		max-width: 100%;
		max-height: 100%;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	}
`;

export interface PackageMarkProps {
	type: string;
	name: string;
	width?: string;
	height?: string;
}

const PackageMark: React.FC<PackageMarkProps> = ({ ...props }) => {
	return (
		<PackageMarkWrapper {...props}>
			<LazyImage src={imageMap[props.type.toLocaleLowerCase()]} alt={props.name} />
		</PackageMarkWrapper>
	);
};

export default PackageMark;
