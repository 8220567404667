import * as React from 'react';
import SocialIconWrapper from '../SocialIconWrapper';
import { IconWrapperProps } from '../../../../interfaces';

const FacebookIcon: React.FC<IconWrapperProps & React.SVGAttributes<SVGElement>> = ({ color, ...props }) => {
	return (
		<SocialIconWrapper alt="Facebook" {...props}>
			<path
				fill={color}
				d="M12.4 25v-8.4H9v-3.467h3.4V10.2c0-3.333 2.468-5.2 5.334-5.2 1.4 0 2.4.133 2.666.2v3.133h-2.666c-1.6 0-1.934.8-1.934 1.867v2.867h4.667l-.533 3.466H15.8V25h-3.4z"
			/>
		</SocialIconWrapper>
	);
};

FacebookIcon.defaultProps = {
	color: '#3B5997',
};

export default FacebookIcon;
