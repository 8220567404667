import * as React from 'react';
import NormalIconWrapper, { NormalIconWrapperProps } from '../NormalIconWrapper';
import { ThemeContext } from 'styled-components';

const TimeIcon: React.FC<NormalIconWrapperProps & React.SVGAttributes<SVGElement>> = ({ color, ...props }) => {
	const theme = React.useContext(ThemeContext);
	const fillColor = color ?? theme.colors.brandPrimary;
	return (
		<NormalIconWrapper {...props}>
			<path
				fill={fillColor}
				d="M20.778 20.772c-4.29 4.288-11.268 4.29-15.557 0A10.93 10.93 0 0 1 2 12.994c0-2.939 1.144-5.7 3.221-7.779A10.97 10.97 0 0 1 13 2c2.817 0 5.633 1.072 7.778 3.216 4.289 4.29 4.289 11.268 0 15.557m1.414-16.97c-5.068-5.069-13.315-5.07-18.385 0A12.916 12.916 0 0 0 0 12.993c0 3.472 1.352 6.736 3.807 9.192A12.961 12.961 0 0 0 13 25.987c3.329 0 6.658-1.267 9.192-3.8 5.068-5.07 5.068-13.317 0-18.386"
			/>
			<path fill={fillColor} d="M19 12.098h-5V6.996a1 1 0 0 0-2 0v7.102h7a1 1 0 0 0 0-2" />
		</NormalIconWrapper>
	);
};

export default TimeIcon;
