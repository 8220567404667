import * as React from 'react';
import styled from 'styled-components';
import { media } from 'styleguide/helpers/media';
import { EmergencyNotification } from '../Resource/interfaces';
import { FormattedMessage } from 'react-intl';

interface NotificationProps {
	type: EmergencyNotification['type'];
}

const Notification = styled.div<NotificationProps>`
	background: ${props => (props.type === 'warning' ? props.theme.colors.actionErrorText : props.theme.colors.white)};
	padding: 15px 20px;
	font-family: ${props => props.theme.fonts.secondary};
	font-size: 15px;
	line-height: 18px;
	color: ${props => (props.type === 'warning' ? props.theme.colors.white : props.theme.colors.bodyText)};
	width: 100%;
	position: relative;

	${media.tablet`
  	padding: 15px 45px 15px 30px;
	  display: flex;
	  justify-content: flex-start;
  	align-items: flex-start;
  `};

	.emergency-notification-close {
		position: absolute;
		right: 5px;
		top: 5px;

		path {
			fill: ${props => (props.type === 'warning' ? props.theme.colors.white : props.theme.colors.bodyText)};
		}
	}
`;

const NotificationTitle = styled.strong`
	${props => props.theme.typography.heading};
	text-transform: uppercase;
	margin: 0 0 10px;
	display: block;

	${media.tablet`
    display: inline;
	  margin: 0 10px 0 0;
  `};
`;

const NotificationLink = styled.a`
	font-weight: 600;
	color: ${props => props.theme.colors.white};
	text-decoration: none;

	&:before {
		content: ' ';
	}
`;

interface EmergencyNotificationItemProps {
	onDismiss: (id: string) => void;
}

class EmergencyNotificationItem extends React.Component<EmergencyNotificationItemProps & EmergencyNotification> {
	public render() {
		const { type, message, url } = this.props;
		return (
			<>
				<Notification type={type}>
					<NotificationTitle>
						<FormattedMessage id="component_header_emergency_notification_notice" />
					</NotificationTitle>
					<span>
						{message}
						{url && (
							<NotificationLink href={url}>
								<FormattedMessage id="global_read_more" /> ›
							</NotificationLink>
						)}
					</span>
					{/* NOT SUPPORTED YET
					<IconButton
						icon={<CloseIcon color={type === 'warning' ? NormalIconVariant.white : NormalIconVariant.disabled} />}
						backgroundColor={IconButtonBackgroundColor.transparent}
						className="emergency-notification-close"
						onClick={this.onDismiss}
					/>
					*/}
				</Notification>
			</>
		);
	}

	// private onDismiss = () => {
	// 	const { onDismiss, id } = this.props;
	// 	onDismiss(id);
	// };
}

export default EmergencyNotificationItem;
