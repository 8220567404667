import { createStandardAction, getType, ActionType } from 'typesafe-actions';
import { combineReducers } from 'redux';
import { RecipeCardInterface } from 'styleguide/interfaces/cardInterfaces';
import { RelatedRecipesState } from '../interfaces';

export const relatedRecipesActions = {
	setRelatedRecipes: createStandardAction('PRODUCT/SET_RELATED_RECIPES')<RecipeCardInterface[]>(),
};

export const relatedRecipesReducer = combineReducers<RelatedRecipesState, RelatedRecipesActions>({
	isLoading: (state = true, action) => {
		return action.type !== getType(relatedRecipesActions.setRelatedRecipes);
	},

	recipes: (state = [], action) => {
		switch (action.type) {
			case getType(relatedRecipesActions.setRelatedRecipes): {
				return action.payload;
			}
			default:
				return state;
		}
	},
});

export type RelatedRecipesActions = ActionType<typeof relatedRecipesActions>;
