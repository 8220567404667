import { fetchJson } from 'utils/service';
import { YearlyNewsArticles } from './interfaces';

const getNewsArchiveItems = async (siteUrlId: string): Promise<YearlyNewsArticles[]> => {
	return await fetchJson<YearlyNewsArticles[]>(`/api/sites/${siteUrlId}/resourcearchive/NewsArticle`);
};

export const newsArchiveApi = {
	getNewsArchiveItems,
};

export type NewsArchiveApi = typeof newsArchiveApi;
