import { put, call, takeLatest, fork, takeEvery } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { resourceActions } from 'common/components/Resource/reducers/actions';
import { ResourceType } from 'common/components/Resource/types';
import { CommentApi, commentApi } from './services';
import { commentActions } from './reducers';

function* fetchMoreComments(api: CommentApi, { payload }: ReturnType<typeof commentActions.fetchComments>) {
	const { resourceTypeId, skip, pageLength } = payload;
	try {
		const commentShape = yield call(api.fetchComments, resourceTypeId, skip, pageLength);

		yield put(commentActions.setComments(commentShape));
	} catch (e) {
		yield put(commentActions.fetchCommentsFailed(e));
	}
}

export function* watchFetchComments() {
	yield takeLatest(getType(commentActions.fetchComments), fetchMoreComments, commentApi);
}

function* addComment(api: CommentApi, { payload }: ReturnType<typeof commentActions.addComment>) {
	const { resourceId, comment, email } = payload;
	try {
		yield call(api.addComment, resourceId, comment, email);

		yield put(commentActions.addCommentSucceeded());
	} catch (e) {
		yield put(commentActions.addCommentFailed());
	}
}

export function* watchAddComment() {
	yield takeLatest(getType(commentActions.addComment), addComment, commentApi);
}

function* initialFetch(api: CommentApi, { payload }: ReturnType<typeof resourceActions.setResource>) {
	if (payload.resource.type === ResourceType.recipe) {
		yield put(commentActions.fetchComments(payload.resource.id, 0, 3));
	}
}

export function* watchSetResource() {
	yield takeLatest(getType(resourceActions.setResource), initialFetch, commentApi);
}

function* reportComment(api: CommentApi, { payload }: ReturnType<typeof commentActions.reportComment>) {
	const { commentId, reason } = payload;
	try {
		yield call(api.reportComment, commentId, reason);

		yield put(commentActions.reportCommentSucceeded(commentId));
	} catch (e) {
		yield put(commentActions.reportCommentFailed(commentId));
	}
}

function* watchReportCommentSaga() {
	yield takeEvery(getType(commentActions.reportComment), reportComment, commentApi);
}

export function* commentsSaga() {
	yield fork(watchFetchComments);
	// comments are deactivated for now
	// yield fork(watchSetResource);
	yield fork(watchAddComment);
	yield fork(watchReportCommentSaga);
}
