import * as React from 'react';
import { InputSize, InputSizes, InputFontWeights } from '../../../types';
import styled, { css, DefaultTheme, ThemeProps } from 'styled-components';
import ValidationError from '../ValidationError';
import Label from '../Label';
import { InfoIcon } from 'styleguide/components/Icons';
import HelpText from '../HelpText';
import { ResponsiveValues } from 'common/components/Media';
import { media } from 'styleguide/helpers';

export interface StyledFieldProps {
	inputSize?: InputSizes | ResponsiveValues<InputSizes>;
	inputFontWeight?: InputFontWeights;
	borderColor?: 'blue' | 'white';
	isValid?: boolean;
	icon?: React.ReactNode;
	iconPosition?: 'left' | 'right';
	iconOffset?: (inputSize?: InputSize) => number | number;
	iconWidth?: number;
}

const invalid = css`
	border-color: ${props => props.theme.colors.actionErrorText};
	background: ${props => props.theme.colors.actionErrorBackground};
`;

export const getBorder = (props: StyledFieldProps & ThemeProps<DefaultTheme>) => {
	if (props.borderColor === 'white') {
		return props.theme.colors.white;
	}
	if (props.borderColor === 'blue') {
		return props.theme.colors.brandPrimary;
	}
	return props.theme.colors.border;
};

export const getPadding = (theme: DefaultTheme, inputSize?: InputSizes) => {
	if (inputSize === InputSize.medium) {
		return theme.grid.gutter * 2;
	}
	if (inputSize === InputSize.large) {
		return theme.grid.gutter * 3;
	}
	return theme.grid.gutter;
};

interface PaddingForOffset {
	inputSize?: InputSizes;
	iconPosition?: 'left' | 'right';
	iconOffset?: (inputSize?: InputSize) => number | number;
	iconWidth?: number;
}

interface PaddingDefinition {
	padding: number;
	rightPadding: number;
	leftPadding: number;
}

export const getPaddingForOffset = (props: PaddingForOffset & ThemeProps<DefaultTheme>): PaddingDefinition => {
	const { inputSize, iconWidth, iconOffset, iconPosition, theme } = props;

	const padding = getPadding(theme, inputSize);
	let extraPadding = padding * 2;

	if (iconWidth) {
		extraPadding += iconWidth;
	}

	if (iconOffset) {
		const offset = typeof iconOffset === 'function' ? iconOffset(inputSize) : iconOffset;
		extraPadding = extraPadding + offset * 2;
	}

	return {
		padding,
		rightPadding: iconPosition === 'right' ? extraPadding : padding,
		leftPadding: iconPosition === 'left' ? extraPadding : padding,
	};
};

export const getCssPadding = (props: StyledFieldProps & ThemeProps<DefaultTheme>) => {
	const { inputSize } = props;

	if (typeof inputSize === 'object') {
		const sizeMap = inputSize as ResponsiveValues<InputSizes>;

		return css`
			${Object.keys(sizeMap).map(key => {
				const {
					padding: responsivePadding,
					rightPadding: responsiveRightPadding,
					leftPadding: responsiveLeftPadding,
				} = getPaddingForOffset({ ...props, inputSize: sizeMap[key] });
				if (key === 'default') {
					return css`
						padding: ${responsivePadding}px ${responsiveRightPadding}px ${responsivePadding}px
							${responsiveLeftPadding}px;
					`;
				}
				return css`
					${media[key]`
						padding: ${responsivePadding}px ${responsiveRightPadding}px ${responsivePadding}px ${responsiveLeftPadding}px;
					`};
				`;
			})};
		`;
	}

	const { padding, rightPadding, leftPadding } = getPaddingForOffset({ ...props, inputSize });

	return css`
		padding: ${padding}px ${rightPadding}px ${padding}px ${leftPadding}px;
	`;
};

export const getFontWeight = (fontWeight?: InputFontWeights) => {
	if (fontWeight === 'heavy') {
		return 900;
	}

	if (fontWeight === 'medium') {
		return 600;
	}

	return 400;
};

export const getInvalidStyles = (props: StyledFieldProps & ThemeProps<DefaultTheme>) => {
	if (props.isValid) {
		return '';
	}

	return css`
		border-color: ${props.theme.colors.actionErrorText};
		background: ${props.theme.colors.actionErrorBackground};
	`;
};

export const commonInputStyles = css`
	background: ${({ theme }) => theme.colors.white};
	border-radius: 4px;
	font-family: ${({ theme }) => theme.fonts.secondary};
	font-size: 16px;
	line-height: 22px;
	color: ${({ theme }) => theme.colors.bodyText};
	text-align: left;
	box-sizing: border-box;
	width: 100%;
	margin: 0;

	&:hover,
	&:focus,
	&:active {
		border-color: ${({ theme }) => theme.colors.buttonPrimaryHover};
		outline: none;
		box-shadow: none;
	}

	&:focus:invalid {
		${invalid};
	}

	&:disabled {
		background: ${({ theme }) => theme.colors.inputDisabledBackground};
		border-color: ${({ theme }) => theme.colors.border};

		& + div svg path {
			fill: ${({ theme }) => theme.colors.inputDisabledText};
		}
	}

	&::placeholder {
		font-family: ${({ theme }) => theme.fonts.secondary};
		font-size: 16px;
		text-align: left;
		color: ${({ theme }) => theme.colors.inputPlaceholder};
		font-weight: 400;
	}
`;

const InputWrapper = styled.div<{ postfixLabel?: boolean }>`
	display: ${({ postfixLabel }) => (postfixLabel ? 'flex' : 'block')};
	align-items: center;
	position: relative;
`;

const IconWrapper = styled('div')<StyledFieldProps>`
	position: absolute;
	top: 0;
	height: 100%;
	display: flex;
	align-items: center;

	${props => {
		const { iconPosition, iconOffset, inputSize } = props;

		if (typeof inputSize === 'object') {
			const sizeMap = inputSize as ResponsiveValues<InputSizes>;

			return css`
				${Object.keys(sizeMap).map(key => {
					const offset = typeof iconOffset === 'function' ? iconOffset(sizeMap[key]) : iconOffset;

					if (key === 'default') {
						return css`
							${iconPosition}: ${getPadding(props.theme, sizeMap.default) + (offset || 0)}px;
						`;
					}
					return css`
						${media[key]`
							${iconPosition}: ${getPadding(props.theme, sizeMap[key]) + (offset || 0)}px;
						`};
					`;
				})};
			`;
		}
		const defaultOffset = typeof iconOffset === 'function' ? iconOffset(inputSize) : iconOffset;

		return css`
			${iconPosition}: ${getPadding(props.theme, inputSize) + (defaultOffset || 0)}px;
		`;
	}};
`;

const PostfixLabel = styled.label`
	margin-left: ${({ theme }) => theme.grid.gutterInPx(1.5)};
`;

const FieldWrapper = styled('div')<{ inlineLabel?: boolean }>`
	position: relative;
	margin-top: ${props => (props.inlineLabel ? props.theme.grid.gutterInPx() : 0)};
`;

export interface FieldProps extends StyledFieldProps {
	name: string;
	id?: string;
	label?: string;
	inlineLabel?: boolean;
	postfixLabel?: boolean;
	validationError?: string;
	helpText?: string;
}

const Field = React.forwardRef<HTMLDivElement, FieldProps & React.HTMLAttributes<HTMLDivElement>>(
	(
		{
			validationError,
			helpText,
			id,
			name,
			icon,
			iconPosition,
			iconOffset,
			iconWidth,
			inputSize,
			className,
			label,
			inlineLabel,
			postfixLabel,
			children,
			...props
		},
		ref
	) => {
		const inputId = id || name;

		return (
			<FieldWrapper className={`form-field${className ? ` ${className}` : ''}`} inlineLabel={inlineLabel} {...props}>
				{label && !postfixLabel && (
					<Label htmlFor={inputId} inline={inlineLabel}>
						{label}
					</Label>
				)}
				{helpText && (
					<HelpText htmlFor={inputId} className="help-text">
						<InfoIcon />
						{helpText}
					</HelpText>
				)}
				<InputWrapper postfixLabel={postfixLabel}>
					{children}
					{icon && (
						<IconWrapper iconPosition={iconPosition} iconOffset={iconOffset} inputSize={inputSize}>
							{icon}
						</IconWrapper>
					)}
					{label && postfixLabel && <PostfixLabel>{label}</PostfixLabel>}
				</InputWrapper>
				{validationError && <ValidationError htmlFor={inputId}>{validationError}</ValidationError>}
			</FieldWrapper>
		);
	}
);

export default Field;
