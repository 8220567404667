import * as React from 'react';
import styled from 'styled-components';

const PillButtonElement = styled('button')<{ isActive: boolean }>`
	border-radius: 17px;
	font-weight: 800;
	font-size: 13px;
	line-height: 1.85;
	text-align: center;
	padding: 0 12px;
	text-decoration: none;
	white-space: nowrap;
	display: inline-block;
	font-family: ${props => props.theme.fonts.primary};
	cursor: pointer;
	background-color: ${props => (props.isActive ? props.theme.colors.selectedItemBackground : 'transparent')};
	color: ${props => props.theme.colors.brandPrimary};
	border: 1px solid ${props => props.theme.colors.selectedItemBackground};

	:hover {
		background-color: ${props => props.theme.colors.selectedItemBackground};
	}
`;

export interface PillButtonProps {
	isActive?: boolean;
}

const PillButton: React.FC<PillButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>> = ({
	isActive,
	children,
	...rest
}) => (
	<PillButtonElement isActive={!!isActive} {...rest}>
		{children}
	</PillButtonElement>
);

export default PillButton;
