import * as React from 'react';
import NormalIconWrapper, { NormalIconWrapperProps } from '../NormalIconWrapper';

const CalendarPlusIcon: React.FC<NormalIconWrapperProps & React.SVGAttributes<SVGElement>> = ({
	color,
	stroke = '#007FB0',
	...props
}) => {
	return (
		<NormalIconWrapper {...props} fill="none" width="24" height="23" viewBox="0 0 24 23">
			<path d="M12 18V12" stroke={stroke} stroke-width="2" stroke-miterlimit="10" stroke-linecap="square" />
			<path d="M15 15H9" stroke={stroke} stroke-width="2" stroke-miterlimit="10" stroke-linecap="square" />
			<path
				d="M21 3H3C1.89543 3 1 3.89543 1 5V20C1 21.1046 1.89543 22 3 22H21C22.1046 22 23 21.1046 23 20V5C23 3.89543 22.1046 3 21 3Z"
				stroke={stroke}
				stroke-width="2"
				stroke-miterlimit="10"
				stroke-linecap="square"
			/>
			<path d="M6 1V4" stroke={stroke} stroke-width="2" stroke-miterlimit="10" stroke-linecap="square" />
			<path d="M18 1V4" stroke={stroke} stroke-width="2" stroke-miterlimit="10" stroke-linecap="square" />
			<path d="M1 8H23" stroke={stroke} stroke-width="2" stroke-miterlimit="10" stroke-linecap="square" />
		</NormalIconWrapper>
	);
};

export default CalendarPlusIcon;
