import * as React from 'react';
import NormalIconWrapper, { NormalIconWrapperProps } from '../NormalIconWrapper';
import { ThemeContext } from 'styled-components';

const KeyIcon: React.FC<NormalIconWrapperProps & React.SVGAttributes<SVGElement>> = ({ color, ...props }) => {
	const theme = React.useContext(ThemeContext);
	return (
		<NormalIconWrapper {...props}>
			<path
				fill={color ?? theme.colors.brandPrimary}
				d="M5.254 9.323a4.297 4.297 0 0 1 0-6.069A4.278 4.278 0 0 1 8.288 2c1.1 0 2.198.417 3.035 1.255a4.26 4.26 0 0 1 1.257 3.034 4.262 4.262 0 0 1-1.257 3.034 4.299 4.299 0 0 1-6.07 0M23.963 20.55L13.384 9.969a6.244 6.244 0 0 0 1.196-3.68c0-1.68-.654-3.26-1.843-4.449a6.296 6.296 0 0 0-8.897 0 6.298 6.298 0 0 0 0 8.898 6.268 6.268 0 0 0 4.448 1.838 6.281 6.281 0 0 0 3.68-1.194l6.88 6.88-2.286 2.287a.999.999 0 1 0 1.414 1.413l2.286-2.285 1.579 1.579-3.034 3.035a.999.999 0 1 0 1.414 1.413l3.74-3.742a.999.999 0 0 0 0-1.413"
			/>
		</NormalIconWrapper>
	);
};

export default KeyIcon;
