import { Ingredient, IngredientSegment, RecipeNutrient } from './interfaces';
import { IntlShape } from 'react-intl';
import { Locales } from 'common/types/locale';

export const calculateIngredientsTotalWeight = (ingredients: Ingredient[]): number => {
	return ingredients.reduce((memo, ingredient) => memo + ingredient.weight, 0);
};

export const calculateSegmentsTotalWeight = (segments: IngredientSegment[]): number => {
	return segments.reduce((memo, segment) => memo + calculateIngredientsTotalWeight(segment.ingredients), 0);
};

export const renderLocalisedWeight = (
	weight: number,
	numberFormat: object,
	locale: Locales = Locales.fi,
	unit: string = 'kg'
) => {
	return `${renderLocalisedNumber(weight, numberFormat, locale)} ${unit}`;
};

export const renderLocalisedNumber = (value: number, numberFormat: object, locale: Locales = Locales.fi) => {
	return `${new Intl.NumberFormat(locale, numberFormat).format(value)}`;
};

export const getWeightInGrams = (weight: number) => {
	return weight * 1000;
};

export const getWeightInKilograms = (weight: number) => {
	return weight / 1000;
};

export const getLocalisedNutrientsMap = (intl: IntlShape, nutrients: RecipeNutrient[]) => {
	const localisedNutrientMap = {
		energy: intl.formatMessage({ id: 'recipe_nutrient_energy' }),
		protein: intl.formatMessage({ id: 'recipe_nutrient_protein' }),
		carbohydrates: intl.formatMessage({ id: 'recipe_nutrient_carbohydrates' }),
		totalFat: intl.formatMessage({ id: 'recipe_nutrient_fat' }),
		saturatedFat: intl.formatMessage({ id: 'recipe_nutrient_saturated_fat' }),
		dietaryFibre: intl.formatMessage({ id: 'recipe_nutrient_dietary_fibre' }),
		salt: intl.formatMessage({ id: 'recipe_nutrient_salt' }),
		calcium: intl.formatMessage({ id: 'recipe_nutrient_calcium' }),
		vitaminD: intl.formatMessage({ id: 'recipe_nutrient_vitamin_d' }),
	};
	return nutrients.map(nutrientItem => ({
		...nutrientItem,
		nutrient: localisedNutrientMap[nutrientItem.nutrient] || nutrientItem.nutrient,
	}));
};
