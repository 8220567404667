import { createStandardAction, createCustomAction, ActionType } from 'typesafe-actions';
import { ApiError } from 'utils/service';
import { Resource } from '../types';
import { ResourceSendToEmailApiResponse } from '../interfaces';

export const resourceActions = {
	fetchResource: createCustomAction(
		'RESOURCE/FETCH_RESOURCE_REQUEST',
		type => (siteUrlId: string, resourceUrl: string, confirmComment?: string | null) => ({
			type,
			payload: { siteUrlId, resourceUrl, confirmComment },
		})
	),
	setResource: createCustomAction(
		'RESOURCE/FETCH_RESOURCE_SUCCESS',
		type => (siteUrlId: string, resourceUrl: string, resource: Resource) => ({
			type,
			payload: { siteUrlId, resourceUrl, resource },
		})
	),
	fetchResourceFailed: createStandardAction('RESOURCE/FETCH_RESOURCE_FAILURE')<{ error: ApiError }>(),
	setResourcePublicationStatus: createCustomAction(
		'RESOURCE/SET_RESOURCE_PUBLICATION_STATUS',
		type => (resourceId: string, published: boolean) => ({
			type,
			payload: { resourceId, published },
		})
	),
	setResourcePublicationStatusSuccess: createCustomAction(
		'RESOURCE/SET_RESOURCE_PUBLICATION_STATUS_SUCCESS',
		type => (redirectUrl: string) => ({
			type,
			payload: { redirectUrl },
		})
	),
	setResourcePublicationStatusFailed: createStandardAction('RESOURCE/SET_RESOURCE_PUBLICATION_STATUS_FAILURE')(),
	dismissNotification: createStandardAction('RESOURCE/DISMISS_NOTIFICATION')<string>(),
	sendResourceToEmailRequest: createCustomAction(
		'RESOURCE/SEND_TO_EMAIL_REQUEST',
		type => (resourceId: string, senderName: string, senderEmail: string, recipientEmail: string) => ({
			type,
			payload: { resourceId, senderName, senderEmail, recipientEmail },
		})
	),
	sendResourceToEmailSuccess: createCustomAction(
		'RESOURCE/SEND_TO_EMAIL_SUCCESS',
		type => (response: ResourceSendToEmailApiResponse) => ({
			type,
			payload: { result: response.result },
		})
	),
	sendResourceToEmailError: createStandardAction('RESOURCE/SEND_TO_EMAIL_ERROR')(),
};

export type ResourceActions = ActionType<typeof resourceActions>;
