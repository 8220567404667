import * as React from 'react';
import throttle from 'lodash/throttle';
import { Component } from 'react';
import styled, { css, DefaultTheme, withTheme } from 'styled-components';
import Tab, { TabProps } from './Tab';
import { media } from '../../helpers';

const gradientWidth = (theme: DefaultTheme) => theme.grid.gutter * 8;

interface TabsWrapperProps {
	wrapListItems?: boolean;
	isScrollable?: boolean;
	gradientLeft?: boolean;
	gradientRight?: boolean;
}

const getGradientStyles = (position: 'left' | 'right') => css`
	content: '';
	display: block;
	position: absolute;
	width: ${({ theme }) => gradientWidth(theme)}px;
	top: 0;
	bottom: 0;
	left: ${position === 'left' ? '0' : 'auto'};
	right: ${position === 'right' ? '0' : 'auto'};
	background: linear-gradient(to ${position}, rgba(255, 255, 255, 0.01), white 80%);
	pointer-events: none;
	z-index: 100;
`;

const TabsWrapper = styled.div<TabsWrapperProps>`
	display: flex;
	flex-direction: column;
	text-align: center;
	justify-content: ${props => (props.isScrollable ? 'flex-start' : 'center')};

	${media.tablet`
		flex-direction: row;
	`};

	${props => {
		if (props.isScrollable) {
			return css`
				position: relative;
				max-width: 100%;

				&:before {
					${props.gradientLeft ? getGradientStyles('left') : null}
				}

				&:after {
					${props.gradientRight ? getGradientStyles('right') : null}
				}
			`;
		}

		return null;
	}};
`;

const StyledTabs = styled.ul<TabsWrapperProps>`
	list-style: none;
	margin: 0;
	padding: 0;
	display: flex;
	flex-wrap: ${props => (props.wrapListItems ? 'wrap' : 'nowrap')};
	justify-content: ${props => (props.isScrollable ? 'flex-start' : 'center')};

	${props => {
		if (props.isScrollable) {
			return css`
				overflow-x: auto;
				overflow-y: hidden;
				overflow-scrolling: touch;
				scroll-behavior: smooth;
			`;
		}
		return null;
	}};
`;

const TabsHeader = styled.h4`
	${props => props.theme.typography.heading};
	line-height: 1;
	color: ${props => props.theme.colors.brandPrimary};
	font-size: 15px;
	text-transform: uppercase;
	margin: 0 0 10px;

	${media.tablet`
		align-self: center;
		margin: 0 20px 0 0;
	`};
`;

export interface TabsProps extends TabsWrapperProps {
	theme: DefaultTheme;
	tabs: TabProps[];
	header?: string;
	size?: 'large';
	isLowerCase?: boolean;
	/** Use this flag to determine whether this instance of the tabs
	 * should be controlled by external props (e.g. via Redux) or whether
	 * it should use its internal state to track which tab is active.
	 */
	useExternalProps?: boolean;
	onTabClick: (url: string) => void;
	renderAnchorTags?: boolean;
	wrapListItems?: boolean;
}

export interface TabsState {
	activeTabUrl: string;
	gradientLeft?: boolean;
	gradientRight?: boolean;
}

class Tabs extends Component<TabsProps & React.HTMLAttributes<HTMLDivElement>, TabsState> {
	private scrollerRef: React.RefObject<HTMLUListElement>;

	private onScroll = throttle(() => this.gradientToggle(), 250);

	constructor(props: TabsProps) {
		super(props);

		this.scrollerRef = React.createRef();

		let activeTabUrl = !!props.tabs[0] ? props.tabs[0].url : '';
		if (props.useExternalProps) {
			const activeTab = this.props.tabs.find(tab => !!tab.isActive);
			if (activeTab) {
				activeTabUrl = activeTab.url;
			}
		}

		this.state = {
			activeTabUrl,
			gradientLeft: false,
			gradientRight: false,
		};
	}

	public componentDidMount() {
		if (this.scrollerRef.current) {
			this.gradientToggle();
			this.scrollerRef.current.addEventListener('scroll', this.onScroll);
		}
	}

	public componentWillUnmount() {
		if (this.scrollerRef.current) {
			this.scrollerRef.current.removeEventListener('scroll', this.onScroll);
		}
	}

	public render() {
		const { header, className, isScrollable, wrapListItems } = this.props;
		const { gradientLeft, gradientRight } = this.state;

		return (
			<TabsWrapper
				className={className}
				isScrollable={isScrollable}
				gradientLeft={gradientLeft}
				gradientRight={gradientRight}>
				{header && <TabsHeader>{header}</TabsHeader>}
				<StyledTabs isScrollable={isScrollable} ref={this.scrollerRef} wrapListItems={wrapListItems}>
					{this.renderTabs()}
				</StyledTabs>
			</TabsWrapper>
		);
	}

	private renderTabs() {
		const { useExternalProps, isLowerCase, size, renderAnchorTags } = this.props;
		const activeTabUrl = useExternalProps ? '' : this.state.activeTabUrl;
		return this.props.tabs.map(tab => (
			<Tab
				{...tab}
				key={tab.url}
				onClick={this.onTabClick}
				isActive={useExternalProps ? tab.isActive : tab.url === activeTabUrl}
				size={size}
				isLowerCase={isLowerCase}
				renderAnchorTags={renderAnchorTags}
			/>
		));
	}

	private onTabClick = (url: string) => {
		const { useExternalProps, onTabClick } = this.props;
		if (!useExternalProps) {
			this.setState({ activeTabUrl: url });
		}
		onTabClick(url);
	};

	private gradientToggle = () => {
		const { theme } = this.props;
		if (this.scrollerRef.current) {
			const { scrollWidth, offsetWidth, scrollLeft } = this.scrollerRef.current;

			const scrollAmount = scrollWidth - offsetWidth;

			this.setState({
				gradientLeft: scrollLeft > gradientWidth(theme),
				gradientRight: scrollLeft + gradientWidth(theme) < scrollAmount,
			});
		}
	};
}

export default withTheme(Tabs);
