import * as React from 'react';
import InputWithIcon from 'styleguide/components/Inputs/InputWithIcon/InputWithIcon';
import Button from 'styleguide/components/Buttons/Button/Button';
import Alert from 'styleguide/components/Typography/Alert/Alert';
import { AlertType } from 'styleguide/components/Typography/Alert/Alert';
import { injectIntl, WrappedComponentProps, FormattedMessage } from 'react-intl';
import { MapStateToProps, connect } from 'react-redux';
import { InputShape } from '../../Forms/interfaces';
import injectForm, { InjectedFormProps, FormProps } from '../../Forms/Form';
import { validateRequired } from '../../Forms/helpers';
import { State } from '../../../reducers';
import { KeyIcon } from 'styleguide/components/Icons';
import ModalForm from 'common/components/General/ModalForm';
import Input from 'styleguide/components/Inputs/Input';
import Divider, { DividerDirection, DividerSize } from 'styleguide/components/Divider/Divider';

export interface ResetPasswordFormFields {
	email: InputShape<string>;
	hash: InputShape<string>;
	password: InputShape<string>;
	passwordAgain: InputShape<string>;
}

export interface ResetPasswordFormValidators {
	email: Array<(value: string) => string>;
	hash: Array<(value: string) => string>;
	password: Array<(value: string) => string>;
	passwordAgain: Array<(value: string, fields?: ResetPasswordFormFields) => string>;
}

type ResetPasswordFormProps = FormProps<ResetPasswordFormFields>;

type Props = ResetPasswordFormProps &
	InjectedFormProps<ResetPasswordFormFields> &
	WrappedComponentProps &
	RegisterFormStateProps;

class RegistrationForm extends React.Component<Props> {
	public componentDidMount() {
		const { email, hash, onInputChange } = this.props;
		onInputChange(email, 'email');
		onInputChange(hash, 'hash');
	}

	public render() {
		const { onSubmit, fields, intl, isValid, isLoading, error, email, hash } = this.props;
		const { password, passwordAgain } = fields;

		return (
			<ModalForm onSubmit={onSubmit} noValidate>
				{/* <InputWithIcon
					icon={<SendToMailIcon />}
					type="email"
					name="email"
					value={email}
					placeholder={intl.formatMessage({ id: 'forms_email_address' })}
					required={true}
					autoComplete="off"
					pattern={ValidationPatterns.EMAIL}
					disabled
				/>

				<Divider direction={DividerDirection.horizontal} size={DividerSize.full} className="divider" /> */}

				<InputWithIcon
					icon={<KeyIcon />}
					type="password"
					name="password"
					value={password.value}
					validationError={password.touched ? password.error : ''}
					label={intl.formatMessage({ id: 'forms_password' })}
					placeholder={intl.formatMessage({ id: 'forms_password' })}
					helpText={intl.formatMessage({ id: 'forms_password_help_text' })}
					required={true}
					autoComplete="off"
					onChange={this.onChange}
					onBlur={this.onBlur}
				/>
				<InputWithIcon
					icon={<KeyIcon />}
					type="password"
					name="passwordAgain"
					value={passwordAgain.value}
					validationError={passwordAgain.touched ? passwordAgain.error : ''}
					placeholder={intl.formatMessage({ id: 'forms_password_again' })}
					required={true}
					autoComplete="off"
					onChange={this.onChange}
					onBlur={this.onBlur}
				/>

				<Divider direction={DividerDirection.horizontal} size={DividerSize.full} className="divider" />

				<Input type="hidden" name="hash" value={hash} />
				<Input type="hidden" name="emial" value={email} />

				<Button type="submit" disabled={!isValid || isLoading} size="medium">
					<FormattedMessage id="user_reset_password" />
				</Button>

				{error && (
					<Alert
						type={AlertType.Error}
						highlightedText={intl.formatMessage({ id: 'component_login_password_reset_failed' })}
						className="form-error">
						{intl.formatMessage({ id: error })}
					</Alert>
				)}
			</ModalForm>
		);
	}

	private onChange = (event: React.FormEvent<HTMLInputElement>) => {
		const { value, name } = event.currentTarget;
		this.props.onInputChange(value, name);
	};

	private onBlur = (event: React.FormEvent<HTMLInputElement>) => {
		const { value, name } = event.currentTarget;
		this.props.onInputBlur(value, name);
	};
}

const passwordAgainValidator = (value: string, fields?: ResetPasswordFormFields): string => {
	if (fields && fields.password) {
		if (value !== fields.password.value) {
			return 'component_login_registration_validation_password_again_invalid';
		}
	}

	return '';
};

const registrationFields: ResetPasswordFormFields = {
	email: { value: '' },
	hash: { value: '' },
	password: { value: '' },
	passwordAgain: { value: '' },
};

const registrationValidators: ResetPasswordFormValidators = {
	email: [],
	hash: [],
	password: [validateRequired('component_login_validation_password_required')],
	passwordAgain: [validateRequired('component_login_validation_password_again_required'), passwordAgainValidator],
};

interface RegisterFormStateProps {
	isLoading?: boolean;
	error?: string;
	email?: string | string[];
	hash?: string | string[];
}

const mapStateToProps: MapStateToProps<RegisterFormStateProps, {}, State> = ({
	login,
	routing,
}): RegisterFormStateProps => {
	const { registrationForm } = login;

	let email;
	let hash;

	if (routing && routing.query) {
		email = routing.query.email;
		hash = routing.query.hash;
	}

	return {
		isLoading: registrationForm && registrationForm.isLoading,
		error: registrationForm && registrationForm.error,
		email,
		hash,
	};
};

export default connect(mapStateToProps)(
	injectForm(registrationFields, registrationValidators)(injectIntl(RegistrationForm))
);
