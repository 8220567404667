import { call, put, fork, select } from 'redux-saga/effects';
import { takeLatest } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { WeeklyMenuApi, weeklyMenuApi } from './services';
import { weeklyMenuActions } from './reducers';
import { routingActions } from '../Routing/reducers';
import { State } from 'common/reducers';
import { getSiteUrlId } from '../App/services';

const WEEKLY_MENU_URL = '/reseptit/ruokalista/';

function* fetchWeeklyMenu(api: WeeklyMenuApi, { payload }: ReturnType<typeof weeklyMenuActions.requestWeeklyMenu>) {
	try {
		const weeklyMenuResult = yield call(api.getWeeklyMenu, payload.siteUrlId, payload.year, payload.weekNumber);
		yield put(weeklyMenuActions.setWeeklyMenu(weeklyMenuResult));
	} catch (e) {
		yield put(weeklyMenuActions.requestWeeklyMenuFailed(e));
	}
}

function* weeklyMenuHistoryStateChanged(
	api: WeeklyMenuApi,
	{ payload }: ReturnType<typeof routingActions.historyStateChanged>
) {
	const { pathname, query } = payload;
	if (pathname === WEEKLY_MENU_URL) {
		const { routing, sites } = yield select((s: State) => ({ routing: s.routing, sites: s.app.settings!.sites }));
		const siteUrlId = getSiteUrlId(routing, sites)!;
		const year = parseInt(query.vuosi[0], 10);
		const weekNumber = parseInt(query.viikko[0], 10);
		yield put(weeklyMenuActions.requestWeeklyMenu({ siteUrlId, year, weekNumber }));
	}
}

function* watchWeeklyMenuRequests() {
	yield takeLatest(getType(weeklyMenuActions.requestWeeklyMenu), fetchWeeklyMenu, weeklyMenuApi);
}

function* watchWeeklyMenuHistoryStateChanged() {
	yield takeLatest(getType(routingActions.historyStateChanged), weeklyMenuHistoryStateChanged, weeklyMenuApi);
}

export function* weeklyMenuSaga() {
	yield fork(watchWeeklyMenuRequests);
	yield fork(watchWeeklyMenuHistoryStateChanged);
}
