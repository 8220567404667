import * as React from 'react';
import styled from 'styled-components';
import MediumHeader from 'styleguide/components/Typography/MediumHeader/MediumHeader';
import { ButtonLink } from 'styleguide/components/Buttons/Button';
import { FormattedMessage } from 'react-intl';
import { NoSearchResultsIcon } from 'styleguide/components/Icons';

const NotFoundWrapper = styled.section`
	margin-top: 75px;
	> svg {
		display: block;
		margin: auto;
	}

	> h1 {
		margin: ${({ theme }) => theme.grid.gutterInPx(2.5)} 0 0;
		text-align: center;
	}

	> p {
		margin: ${({ theme }) => theme.grid.gutterInPx(1.5)} 0 ${({ theme }) => theme.grid.gutterInPx(6)};
		text-align: center;
		font-size: 18px;
		line-height: 1.33;
		font-family: ${props => props.theme.fonts.secondary};
	}

	> a {
		margin: 0 auto;
		max-width: fit-content;
	}
`;

interface NoResultsProps {
	searchUrl?: string;
	searchKeyword?: string;
	noResultsDescription?: string;
	noResultsButtonLabel?: string;
}

const NoResults: React.FC<NoResultsProps> = ({
	searchKeyword,
	searchUrl,
	noResultsButtonLabel,
	noResultsDescription,
}) => {
	return (
		<NotFoundWrapper>
			<NoSearchResultsIcon />
			<MediumHeader tagName="h1" greyed>
				<FormattedMessage id="search_no_results" />
			</MediumHeader>

			{searchUrl && noResultsButtonLabel && noResultsDescription && searchKeyword && (
				<>
					<p>
						<FormattedMessage id={noResultsDescription} values={{ searchKeyword }} />
					</p>
					<ButtonLink href={searchUrl}>
						<FormattedMessage id={noResultsButtonLabel} />
					</ButtonLink>
				</>
			)}
		</NotFoundWrapper>
	);
};

export default NoResults;
