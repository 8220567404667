import { Reducer } from 'redux';
import { getType, createStandardAction, ActionType } from 'typesafe-actions';
import { ProductAspectSearchResult } from '../services';
import { ProductSearchResult } from 'common/components/GlobalSearch/services/product-search';
import { ProductFrontApiContent } from '../interfaces';
import { ProductSearchActions, productSearchActions } from './product-search-actions';
import { ResourceActions, resourceActions } from 'common/components/Resource/reducers/actions';
import {
	ProductFeatures,
	ProductFeaturePair,
	ProductFeature,
} from 'common/components/Applets/ProductFeatureBrowser/interfaces';

export const productFrontActions = {
	searchProductAspects: createStandardAction('PRODUCT_FRONT/SEARCH_PRODUCT_ASPECTS_REQUEST')<string>(),
	setProductAspects: createStandardAction('PRODUCT_FRONT/SEARCH_PRODUCT_ASPECTS_SUCCESS')<ProductAspectSearchResult>(),
	searchProductAspectsFailed: createStandardAction('PRODUCT_FRONT/SEARCH_PRODUCT_ASPECTS_ERROR')<string>(),
};

export type ProductFrontActions = ActionType<typeof productFrontActions>;

export interface ProductFrontState {
	isSearching: boolean;
	searchResult?: ProductSearchResult;
	totalProductCount?: number;
	aspects: ProductFeatures;
}

const initialState: ProductFrontState = {
	isSearching: false,
	searchResult: {
		count: 0,
		items: [],
	},
	aspects: {
		allergenics: { contains: [], doesNotContain: [] },
		ingredients: { contains: [], doesNotContain: [] },
	},
};

function cloneProductAspectItems(items: ProductFeature[]): ProductFeature[] {
	return items.map(item => ({ title: item.title, slug: item.slug }));
}

function toProductAspectPair(items: ProductFeature[]): ProductFeaturePair {
	return { contains: cloneProductAspectItems(items), doesNotContain: cloneProductAspectItems(items) };
}

function productAspectItemWithCount(
	item: ProductFeature,
	searchResult: ProductAspectSearchResult,
	contains: boolean
): ProductFeature {
	const countItem = searchResult.searchFunctionalityData.find(x => x.slug === item.slug);
	if (countItem === undefined || countItem.count === undefined) {
		return item;
	}
	const count = contains ? countItem.count : searchResult.count - countItem.count;
	return { ...item, count };
}

function productAspectPairWithCounts(
	pair: ProductFeaturePair,
	searchResult: ProductAspectSearchResult
): ProductFeaturePair {
	return {
		contains: pair.contains.map(item => productAspectItemWithCount(item, searchResult, true)),
		doesNotContain: pair.doesNotContain.map(item => productAspectItemWithCount(item, searchResult, false)),
	};
}

function productAspectsWithCounts(aspects: ProductFeatures, searchResult: ProductAspectSearchResult): ProductFeatures {
	return {
		allergenics: productAspectPairWithCounts(aspects.allergenics, searchResult),
		ingredients: productAspectPairWithCounts(aspects.ingredients, searchResult),
	};
}

export const productFrontReducer: Reducer<
	ProductFrontState,
	ProductSearchActions | ResourceActions | ProductFrontActions
> = (state = initialState, action) => {
	switch (action.type) {
		case getType(resourceActions.setResource):
			const content = action.payload.resource.content as ProductFrontApiContent;
			const aspects: ProductFeatures = {
				allergenics: toProductAspectPair((content && content.allergenicFeatures) || []),
				ingredients: toProductAspectPair((content && content.ingredients) || []),
			};
			return { ...state, aspects };
		case getType(productSearchActions.search):
			return { ...state, isSearching: true };
		case getType(productSearchActions.searchSuccess):
			return { ...state, isSearching: false, searchResult: action.payload.searchResult };
		case getType(productFrontActions.searchProductAspects):
			return { ...state };
		case getType(productFrontActions.setProductAspects):
			return {
				...state,
				totalProductCount: action.payload.count,
				aspects: productAspectsWithCounts(state.aspects, action.payload),
			};
		case getType(productFrontActions.searchProductAspectsFailed):
			return { ...state };
		default:
			return state;
	}
};
