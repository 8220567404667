import { createCustomAction, ActionType } from 'typesafe-actions';
import { ProductSearchResult } from 'common/components/GlobalSearch/services/product-search';

export const productSearchActions = {
	search: createCustomAction(
		'PRODUCT_FRONT/SEARCH_REQUEST',
		type => (siteUrlId: string, searchTerm: string, maxResults: number) => ({
			type,
			payload: { siteUrlId, searchTerm, maxResults },
		})
	),

	searchSuccess: createCustomAction(
		'PRODUCT_FRONT/SEARCH_REQUEST_SUCCESS',
		type => (searchResult: ProductSearchResult) => ({
			type,
			payload: { searchResult },
		})
	),
};

export type ProductSearchActions = ActionType<typeof productSearchActions>;
