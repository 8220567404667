export function trimEnd(input: string, trimChar: string) {
	let result = input;
	while (result.length >= 1 && result[result.length - 1] === trimChar) {
		result = result.substring(0, result.length - 1);
	}
	return result;
}

export function trimStart(input: string, trimChar: string) {
	let result = input;
	while (result.length >= 1 && result[0] === trimChar) {
		result = result.substring(1, result.length);
	}
	return result;
}

export function addNonBreakingSpaces(input: string) {
	const matches = input.match(/\((.*?)\)/g);
	let result;
	if (matches !== null) {
		const replacements = matches.map(g => input.replace(g, g.replace(/ /g, '\u00A0')));
		result = replacements.join();
	}
	return result || input;
}

export function isNullOrWhiteSpace(input?: string | null | undefined): boolean {
	return isNullOrUndefined(input) || input?.trim() === '';
}

export function isNullOrUndefined(input?: string | null | undefined) {
	return input === null || input === undefined;
}

export function isEqualCaseInsensitive(stringOne: string, stringTwo: string): boolean {
	return stringOne.toLowerCase() === stringTwo.toLowerCase();
}

export function convertLineBreaks(input: string): string {
	if (!input) {
		return '';
	}
	return input.replace(/\r\n|\r|\n/g, '<br/>');
}
