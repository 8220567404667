import * as React from 'react';
import NormalIconWrapper, { NormalIconWrapperProps } from '../NormalIconWrapper';
import { ThemeContext } from 'styled-components';

const ShoppinglistIcon: React.FC<NormalIconWrapperProps & React.SVGAttributes<SVGElement>> = ({ color, ...props }) => {
	const theme = React.useContext(ThemeContext);
	const fillColor = color ?? theme.colors.brandPrimary;

	return (
		<NormalIconWrapper {...props}>
			<path
				fill={fillColor}
				d="M22 2c1.103 0 2 .897 2 2v20c0 1.103-.897 2-2 2H4c-1.103 0-2-.897-2-2V4c0-1.103.897-2 2-2h3V1a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v1h3zm0 22V4h-3c0 1.103-.897 2-2 2H9c-1.103 0-2-.897-2-2H4v20h18zM9 4h8V2H9v2z"
			/>
			<path
				fill={fillColor}
				d="M8 10a1 1 0 1 0 0 2 1 1 0 0 0 0-2M18 10h-6a1 1 0 0 0 0 2h6a1 1 0 0 0 0-2M8 14a1 1 0 1 0 0 2 1 1 0 0 0 0-2M18 14h-6a1 1 0 0 0 0 2h6a1 1 0 0 0 0-2M8 18a1 1 0 1 0 0 2 1 1 0 0 0 0-2M18 18h-6a1 1 0 0 0 0 2h6a1 1 0 0 0 0-2"
			/>
		</NormalIconWrapper>
	);
};

export default ShoppinglistIcon;
