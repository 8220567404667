import * as React from 'react';
import styled, { css } from 'styled-components';
import { media } from 'styleguide/helpers/media';

const StyledRecipeIndicator = styled.div<RecipeIndicatorProps>`
	position: absolute;
	border-radius: 50%;

	${props =>
		props.itemColour
			? css`
					background: ${props.theme.colors[props.itemColour]};
			  `
			: ''};

	${props =>
		props.showBorder
			? css`
					border: 2px solid ${props.theme.colors.white};
					width: 14px;
					height: 14px;
					left: -1px;
					top: 91px;
					z-index: 1;

					${media.tablet`
  					left: 7px;
          `};
			  `
			: css`
					width: 8px;
					height: 8px;
					left: 0;
					top: 11px;

					${media.tablet`
            top: 18px;
          `};

					@media print {
						postion: relative;
					}
			  `};
`;

export interface RecipeIndicatorProps {
	itemColour?: string;
	showBorder?: boolean;
}

const RecipeIndicator: React.FC<RecipeIndicatorProps & React.HTMLAttributes<HTMLDivElement>> = props => {
	return <StyledRecipeIndicator {...props} />;
};

export default RecipeIndicator;
