import { takeLatest } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { call, put } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { ProductSearchApi, productSearchApi } from '../services/product-search';
import { productSearchActions } from 'pagetypes/ProductFront/reducers/product-search-actions';

function* searchProducts(searchApi: ProductSearchApi, { payload }: ReturnType<typeof productSearchActions.search>) {
	yield delay(200);
	try {
		const response = yield call(searchApi.searchProducts, payload.siteUrlId, payload.searchTerm, payload.maxResults);

		yield put(productSearchActions.searchSuccess(response));
	} catch {
		//
	}
}

export function* productSearchSaga() {
	yield takeLatest(getType(productSearchActions.search), searchProducts, productSearchApi);
}
