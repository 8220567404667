import * as React from 'react';
import { FrontHeader, FrontTitle, ContentWrapper } from 'common/components/General';
import { MapStateToProps, connect } from 'react-redux';
import { State } from 'common/reducers';
import { getSiteUrlPrefixOrDefault, getSiteUrlId } from 'common/components/App/services';
import { getHeroMediaSource } from 'utils/media';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { InputSize } from 'styleguide/types';
import { RoutingState, routingActions } from 'common/components/Routing/reducers';
import Hero from 'styleguide/components/HeroBanners/Hero';
import SearchResults from 'common/components/Navigation/components/SearchResults/SearchResults';
import { replaceToSearch, addToSearch, removeFromSearch } from 'utils/query-string';
import ContentCard from 'common/components/General/ContentCard';
import { SearchType } from 'pagetypes/Search/types';
import { NameAndValue } from 'common/interfaces/common';
import { queryToFilters } from 'pagetypes/Search/utils';
import { MaterialSearchState } from './interfaces';
import { SearchActions } from 'common/components/Search';
import Results from './components/Results';
import MaterialSearchFilters from './components/MaterialSearchFilters';
import InfiniteScrollHelper from 'common/components/InfiniteScroll/InfiniteScrollHelper';
import { SEARCH_MATERIALS_PAGE_SIZE, ORDER_URL_HASH_BY_STATUS } from './constants';
import { materialSearchActions } from './reducers';
import Disclaimer from './components/Disclaimer';
import CartDropdown from './components/CartDropdown';
import styled from 'styled-components';
import { media } from 'styleguide/helpers';
import MaterialSearchOrder from './components/MaterialSearchOrder';
import WidgetZone from 'common/components/Widgets/WidgetZone';
import Modal from 'styleguide/components/Modals/Modal';
import ModalForm from './components/ModalForm';
import ModalConfirmation from './components/ModalConfirmation';

const MaterialSearchContentWrapper = styled(ContentWrapper)`
	padding: 0 ${({ theme }) => theme.grid.gutterInPx()};
`;

const MaterialSearchActions = styled(SearchActions)`
	${media.desktop`
    justify-content: space-between;
	`};
`;

type Props = MaterialsStateProps & MaterialsDispatchProps & WrappedComponentProps;

class MaterialSearch extends React.Component<Props> {
	private infiniteScroll: InfiniteScrollHelper;
	private setSearchTextTimeout: NodeJS.Timer;

	public componentDidMount() {
		const { fetchSecureFilters, searchMaterials, siteUrlId, routing, fetchCart, updateHash } = this.props;
		if (routing.query?.token) {
			fetchSecureFilters(routing.search || '');
		}
		searchMaterials(siteUrlId, routing.search || '', SEARCH_MATERIALS_PAGE_SIZE);
		fetchCart();

		if (routing.hash && routing.hash.length > 0) {
			updateHash();
		}

		this.infiniteScroll = InfiniteScrollHelper.subscribe(this.onInfiniteScroll);
	}

	public componentWillUnmount() {
		this.infiniteScroll.unsubscribeInfiniteScroll();
	}

	public componentDidUpdate(prevProps: Props) {
		const {
			materialSearchState: { results },
			routing,
			searchMaterials,
			siteUrlId,
		} = this.props;

		this.infiniteScroll.unlockInfiniteScroll(prevProps.materialSearchState.results ?? [], results);

		if (prevProps.routing.search !== routing.search) {
			this.infiniteScroll.reset();
			searchMaterials(siteUrlId, routing.search || '', SEARCH_MATERIALS_PAGE_SIZE);
		}
	}

	public render() {
		const {
			title,
			heroImageDesktop,
			heroImageMobile,
			heroImageOffsetY,
			heroMediaType,
			routing,
			siteUrlPrefix,
			selectedFilters,
			intl,
			materialSearchState: { isLoading, isResultsLoading, count, results, error, order, register },
		} = this.props;

		const heroMedia = getHeroMediaSource(heroMediaType, heroImageDesktop, heroImageMobile, 'recipe-front-hero');

		const searchValue = routing.query?.haku;
		const initialSearchTerm = Array.isArray(searchValue) ? searchValue[0] : searchValue;

		return (
			<>
				<Hero
					className="search-hero"
					src={heroMedia.mediaSrc}
					mediaType={heroMedia.mediaType}
					offsetY={heroImageOffsetY}
					size="medium">
					<FrontHeader>
						<FrontTitle>{title}</FrontTitle>
						{order.status === 'SEARCH' && (
							<SearchResults
								id="materials-search"
								placeholder={intl.formatMessage({ id: 'materials_search_placeholder' })}
								showMostPopularKeywords={false}
								mostPopularKeywords={[]}
								onSetSearchText={this.onSetSearchText}
								onClear={this.onSearchClear}
								inputSize={{ default: InputSize.medium, tablet: InputSize.large, desktop: InputSize.large }}
								disableSuggestions={true}
								className="search-results"
								initialSearchTerm={initialSearchTerm}
								siteUrlPrefix={siteUrlPrefix}
							/>
						)}
					</FrontHeader>
				</Hero>

				<ContentCard />

				{order.status === 'SEARCH' && (
					<>
						<MaterialSearchContentWrapper>
							<MaterialSearchActions
								renderFilters={this.renderFilters}
								renderListAction={this.renderListControl}
								renderListDescription={this.renderListDescription}
								resultsCount={count}
								searchType={SearchType.MATERIAL}
								onFilterClick={this.onFilterClick}
								selectedFilters={selectedFilters}
								inlineMobileFilters
							/>
							{register.modalStatus !== 'CLOSED' && (
								<Modal onClose={this.onCloseRegisterModal}>
									{register.modalStatus === 'SHOW_FORM' && (
										<ModalForm
											isLoading={register.isLoading}
											error={register.error}
											onRegisterToMaterialBank={this.onRegisterToMaterialBank}
										/>
									)}
									{register.modalStatus === 'SHOW_CONFIRMATION' && <ModalConfirmation />}
								</Modal>
							)}
						</MaterialSearchContentWrapper>

						<Results
							items={order.items}
							results={results}
							isLoading={isLoading}
							isResultsLoading={isResultsLoading}
							error={error}
							onAddToCart={this.onAddToCart}
						/>
					</>
				)}

				{order.status !== 'SEARCH' && <MaterialSearchOrder />}

				<WidgetZone name="BottomFullWidth" isFullWidth={true} />
			</>
		);
	}

	private renderFilters = () => {
		const {
			materialSearchState: { availableFilters, filterCount, isResultsLoading },
			selectedFilters,
		} = this.props;
		return (
			<MaterialSearchFilters
				filters={availableFilters}
				filterCount={filterCount}
				selectedFilters={selectedFilters}
				isResultsLoading={isResultsLoading}
				onFilterClick={this.onFilterClick}
			/>
		);
	};

	private renderListDescription = () => {
		return <Disclaimer />;
	};

	private renderListControl = () => {
		const {
			materialSearchState: { order },
		} = this.props;
		return <CartDropdown items={order.items} onGoToCart={this.onGoToCart} />;
	};

	private onSetSearchText = (input: string) => {
		const { routing, updateSearch } = this.props;

		if (this.setSearchTextTimeout) {
			clearTimeout(this.setSearchTextTimeout);
		}

		this.setSearchTextTimeout = setTimeout(() => {
			let search = routing.search || '';
			search = replaceToSearch(search, 'haku', encodeURIComponent(input));
			updateSearch(search);
		}, 250);
	};

	private onSearchClear = () => {
		const { routing, updateSearch } = this.props;
		const search = replaceToSearch(routing.search || '', 'haku');
		updateSearch(search);
	};

	private onFilterClick = (filterName: string, filterValue: string, selected: boolean) => {
		const { routing, updateSearch } = this.props;
		const search = (selected ? addToSearch : removeFromSearch)(routing.search || '', filterName, filterValue);
		updateSearch(search);
	};

	private onAddToCart = (id: string, quantity: number) => {
		const { addToCart } = this.props;
		addToCart({ id, quantity });
	};

	private onGoToCart = () => {
		const { updateHash } = this.props;
		updateHash(ORDER_URL_HASH_BY_STATUS.CART);
	};

	private onInfiniteScroll = (pageNumber: number) => {
		const {
			materialSearchState: { results, count },
			loadMoreMaterials,
			siteUrlId,
			routing,
		} = this.props;
		if (results.length === count) {
			return;
		}
		loadMoreMaterials(siteUrlId, routing.search || '', SEARCH_MATERIALS_PAGE_SIZE, pageNumber);
	};

	private onCloseRegisterModal = () => {
		this.props.setRegisterModalStatus('CLOSED');
	};

	private onRegisterToMaterialBank = (email: string) => {
		this.props.registerToMaterialBank(email);
	};
}

interface MaterialsStateProps {
	title?: string;
	heroImageDesktop?: string;
	heroImageMobile?: string;
	heroImageOffsetY: number;
	heroMediaType?: string;
	routing: RoutingState;
	materialSearchState: MaterialSearchState;
	siteUrlPrefix: string;
	siteUrlId: string;
	selectedFilters: NameAndValue[];
}

interface MaterialsDispatchProps {
	fetchSecureFilters: typeof materialSearchActions.fetchSecureFilters.request;
	searchMaterials: typeof materialSearchActions.searchMaterials;
	loadMoreMaterials: typeof materialSearchActions.loadMoreMaterials;
	fetchCart: typeof materialSearchActions.fetchCart.request;
	addToCart: typeof materialSearchActions.addToCart.request;
	setRegisterModalStatus: typeof materialSearchActions.setRegisterModalStatus;
	registerToMaterialBank: typeof materialSearchActions.registerToMaterialBank.request;
	updateSearch: typeof routingActions.updateSearch;
	updateHash: typeof routingActions.updateHash;
}

const mapStateToProps: MapStateToProps<MaterialsStateProps, {}, State> = ({ resource, routing, app }) => {
	const { title, heroImageDesktop, heroImageMobile, heroImageOffsetY, heroMediaType } = resource;
	const sites = (app.settings && app.settings.sites) || [];

	return {
		title,
		heroImageDesktop,
		heroImageMobile,
		heroImageOffsetY,
		heroMediaType,
		routing,
		materialSearchState: resource.content as MaterialSearchState,
		siteUrlId: getSiteUrlId(routing, sites) || '',
		siteUrlPrefix: getSiteUrlPrefixOrDefault(routing, sites, '/'),
		selectedFilters: queryToFilters(routing.query || {}),
	};
};

export default connect(mapStateToProps, {
	fetchSecureFilters: materialSearchActions.fetchSecureFilters.request,
	searchMaterials: materialSearchActions.searchMaterials,
	loadMoreMaterials: materialSearchActions.loadMoreMaterials,
	fetchCart: materialSearchActions.fetchCart.request,
	addToCart: materialSearchActions.addToCart.request,
	setRegisterModalStatus: materialSearchActions.setRegisterModalStatus,
	registerToMaterialBank: materialSearchActions.registerToMaterialBank.request,
	updateSearch: routingActions.updateSearch,
	updateHash: routingActions.updateHash,
})(injectIntl(MaterialSearch));
