import * as React from 'react';
import SocialIconWrapper from '../SocialIconWrapper';
import { IconWrapperProps } from '../../../../interfaces';
import { ThemeContext } from 'styled-components';

const LinkedinIcon: React.FC<IconWrapperProps & React.SVGAttributes<SVGElement>> = ({ color, ...props }) => {
	const theme = React.useContext(ThemeContext);
	return (
		<SocialIconWrapper alt="LinkedIn" {...props}>
			<path
				fill={color ?? theme.colors.socialIcon}
				d="M27.448 0C28.855 0 30 1.114 30 2.484v25.032C30 28.886 28.855 30 27.448 30H2.552C1.145 30 0 28.886 0 27.516V2.484C0 1.114 1.145 0 2.552 0h24.896zM25.16 24.85v-7.566c0-4.052-2.176-5.938-5.08-5.938-2.342 0-3.39 1.28-3.977 2.179v-1.87H11.69c.058 1.239 0 13.195 0 13.195h4.412v-7.368c0-.395.03-.789.146-1.07.319-.788 1.045-1.605 2.264-1.605 1.597 0 2.235 1.21 2.235 2.984v7.059h4.412zM7.042 9.855c1.539 0 2.497-1.014 2.497-2.28-.03-1.294-.958-2.28-2.468-2.28s-2.496.986-2.496 2.28c0 1.266.958 2.28 2.439 2.28h.028zM4.836 24.85h4.412V11.656H4.836V24.85z"
			/>
		</SocialIconWrapper>
	);
};

export default LinkedinIcon;
