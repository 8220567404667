import { ButtonSizes, ButtonFontStyles } from '../../../types';
import { inPixels } from '../../../helpers';
import { ButtonProps, OutlinedButtonProps } from '../../../interfaces';
import { ThemeProps, DefaultTheme } from 'styled-components';

export const getButtonHeight = (size?: ButtonSizes) => {
	switch (size) {
		case 'extra-large':
			return inPixels(60);
		case 'large':
			return inPixels(50);
		case 'small':
			return inPixels(30);
		case 'medium':
		default:
			return inPixels(40);
	}
};

export const getButtonFontSize = (props: ButtonProps) => {
	switch (props.size) {
		case 'extra-large':
			return inPixels(24);
		case 'large':
			if (props.fontStyle === 'secondary') {
				return inPixels(22);
			}
			return inPixels(15);
		case 'small':
			return inPixels(13);
		case 'medium':
		default:
			if (props.fontStyle === 'secondary') {
				return inPixels(18);
			}
			return inPixels(13);
	}
};

export const getButtonPadding = (size?: ButtonSizes) => {
	switch (size) {
		case 'extra-large':
			return inPixels(60);
		case 'large':
			return inPixels(50);
		case 'small':
			return inPixels(12);
		case 'medium':
		default:
			return inPixels(30);
	}
};

export const getButtonVerticalPadding = (size?: ButtonSizes) => {
	switch (size) {
		case 'extra-large':
			return inPixels(10);
		case 'large':
			return inPixels(10);
		case 'small':
			return inPixels(5);
		case 'medium':
		default:
			return inPixels(5);
	}
};

export const getButtonFontStyle = (props: ButtonProps & ThemeProps<DefaultTheme>) => {
	switch (props.fontStyle) {
		case 'secondary':
			return props.theme.fonts.secondaryButtons;
		default:
			return props.theme.fonts.primaryButtons;
	}
};

export const getButtonFontWeight = (buttonFontStyle?: ButtonFontStyles) => {
	switch (buttonFontStyle) {
		case 'secondary':
			return 600;
		default:
			return 900;
	}
};

// Button Colours

export const getButtonColor = (props: ButtonProps & ThemeProps<DefaultTheme>) => {
	if (props.color) {
		return props.color;
	}
	if (props.foregroundColorStyle) {
		if (props.foregroundColorStyle === 'dark') {
			return props.theme.colors.buttonTextForLightBackground;
		} else if (props.foregroundColorStyle === 'light') {
			return props.theme.colors.buttonTextForDarkBackground;
		}
	}

	switch (props.buttonStyle) {
		case 'secondary':
			return props.theme.colors.buttonSecondaryText || props.theme.colors.buttonText;
		case 'primary':
		default:
			return props.theme.colors.buttonText;
	}
};

export const getButtonHoverColor = (props: ButtonProps & ThemeProps<DefaultTheme>) => {
	if (props.hoverColor) {
		return props.hoverColor;
	}
	if (props.foregroundColorStyle) {
		if (props.foregroundColorStyle === 'dark') {
			return props.theme.colors.buttonTextForLightBackground;
		} else if (props.foregroundColorStyle === 'light') {
			return props.theme.colors.buttonTextForDarkBackground;
		}
	}

	switch (props.buttonStyle) {
		case 'secondary':
			return props.theme.colors.buttonSecondaryHoverText || props.theme.colors.buttonHoverText;
		case 'primary':
		default:
			return props.theme.colors.buttonHoverText;
	}
};

export const getButtonBackgroundColor = (props: ButtonProps & ThemeProps<DefaultTheme>) => {
	if (props.disabled) {
		return props.theme.colors.buttonDisabled;
	}
	if (props.backgroundColor) {
		return props.backgroundColor;
	}

	switch (props.buttonStyle) {
		case 'secondary':
			return props.theme.colors.buttonSecondary;
		case 'primary':
		default:
			return props.theme.colors.buttonPrimary;
	}
};

export const getButtonBackgroundHoverColor = (props: ButtonProps & ThemeProps<DefaultTheme>) => {
	if (props.disabled) {
		return props.theme.colors.buttonDisabled;
	}
	if (props.backgroundHoverColor) {
		return props.backgroundHoverColor;
	}

	switch (props.buttonStyle) {
		case 'secondary':
			return props.theme.colors.buttonSecondaryHover;
		case 'primary':
		default:
			return props.theme.colors.buttonPrimaryHover;
	}
};

// Outlined Button Colours

export const getOutlinedButtonColor = (props: OutlinedButtonProps & ThemeProps<DefaultTheme>) => {
	if (props.color) {
		return props.color;
	}

	switch (props.buttonStyle) {
		case 'secondary':
			return props.active ? props.theme.colors.white : props.theme.colors.buttonOutlineTextSecondary;
		case 'primary':
		default:
			return props.active ? props.theme.colors.white : props.theme.colors.buttonOutlineText;
	}
};

export const getOutlinedButtonHoverColor = (props: OutlinedButtonProps & ThemeProps<DefaultTheme>) => {
	if (props.hoverColor) {
		return props.hoverColor;
	}

	switch (props.buttonStyle) {
		case 'secondary':
			return props.active ? props.theme.colors.white : props.theme.colors.buttonOutlineTextSecondaryHover;
		case 'primary':
		default:
			return props.active ? props.theme.colors.white : props.theme.colors.buttonOutlineTextHover;
	}
};

export const getOutlinedButtonBackgroundColor = (props: OutlinedButtonProps & ThemeProps<DefaultTheme>) => {
	if (props.backgroundColor) {
		return props.backgroundColor;
	}

	switch (props.buttonStyle) {
		case 'secondary':
			return props.active ? props.theme.colors.buttonSecondary : props.theme.colors.buttonOutlineBackgroundSecondary;
		case 'primary':
		default:
			return props.active ? props.theme.colors.buttonPrimary : props.theme.colors.buttonOutlineBackground;
	}
};

export const getOutlinedButtonBackgroundHoverColor = (props: OutlinedButtonProps & ThemeProps<DefaultTheme>) => {
	if (props.backgroundHoverColor) {
		return props.backgroundHoverColor;
	}

	switch (props.buttonStyle) {
		case 'secondary':
			return props.active
				? props.theme.colors.buttonSecondaryHover
				: props.theme.colors.buttonOutlineBackgroundSecondaryHover;
		case 'primary':
		default:
			return props.active ? props.theme.colors.buttonPrimaryHover : props.theme.colors.buttonOutlineBackgroundHover;
	}
};

export const getOutlinedButtonBorderColor = (props: OutlinedButtonProps & ThemeProps<DefaultTheme>) => {
	if (props.borderColor) {
		return props.borderColor;
	}

	switch (props.buttonStyle) {
		case 'secondary':
			return props.active ? props.theme.colors.buttonSecondary : props.theme.colors.buttonOutlineBorderSecondary;
		case 'primary':
		default:
			return props.active ? props.theme.colors.buttonPrimary : props.theme.colors.buttonOutlineBorder;
	}
};

export const getOutlinedButtonBorderHoverColor = (props: OutlinedButtonProps & ThemeProps<DefaultTheme>) => {
	if (props.borderHoverColor) {
		return props.borderHoverColor;
	}

	switch (props.buttonStyle) {
		case 'secondary':
			return props.theme.colors.buttonOutlineBorderSecondaryHover;
		case 'primary':
		default:
			return props.theme.colors.buttonOutlineBorderHover;
	}
};

export const getButtonBorderColor = (props: ButtonProps & ThemeProps<DefaultTheme>) => {
	if (props.borderColor) {
		return props.borderColor;
	}

	switch (props.buttonStyle) {
		case 'secondary':
			return props.theme.colors.buttonSecondaryBorder;
		case 'primary':
		default:
			return props.theme.colors.buttonPrimaryBorder;
	}
};

export const getButtonBorderHoverColor = (props: ButtonProps & ThemeProps<DefaultTheme>) => {
	if (props.borderHoverColor) {
		return props.borderHoverColor;
	}

	switch (props.buttonStyle) {
		case 'secondary':
			return props.theme.colors.buttonSecondaryBorderHover;
		case 'primary':
		default:
			return props.theme.colors.buttonPrimaryBorderHover;
	}
};
