import * as React from 'react';
import NormalIconWrapper, { NormalIconWrapperProps } from '../NormalIconWrapper';
import { ThemeContext } from 'styled-components';

const RecyclebinIcon: React.FC<NormalIconWrapperProps & React.SVGAttributes<SVGElement>> = ({ color, ...props }) => {
	const theme = React.useContext(ThemeContext);
	const fillColor = color ?? theme.colors.brandPrimary;

	return (
		<NormalIconWrapper {...props}>
			<path
				fill={fillColor}
				d="M23 4a1 1 0 1 1 0 2H3a1 1 0 0 1 0-2h6V2c0-1.103.897-2 2-2h4c1.103 0 2 .897 2 2v2h6zM11 4h4V2h-4v2zM18 21V11a1 1 0 1 0-2 0v10a1 1 0 1 0 2 0M14 21V11a1 1 0 0 0-2 0v10a1 1 0 0 0 2 0M10 21V11a1 1 0 0 0-2 0v10a1 1 0 0 0 2 0"
			/>
			<path
				fill={fillColor}
				d="M21 8a1 1 0 0 1 1 1v14c0 1.654-1.346 3-3 3H7c-1.654 0-3-1.346-3-3V9a1 1 0 0 1 2 0v14c0 .551.449 1 1 1h12c.552 0 1-.449 1-1V9a1 1 0 0 1 1-1z"
			/>
		</NormalIconWrapper>
	);
};

export default RecyclebinIcon;
