import * as React from 'react';
import Input from 'styleguide/components/Inputs/Input/Input';
import { InputSize } from 'styleguide/types/inputTypes';
import styled from 'styled-components';
import { media } from 'styleguide/helpers/media';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import { InputShape } from 'common/components/Forms/interfaces';
import injectForm, { InjectedFormProps } from 'common/components/Forms/Form';

const StyledAddItemForm = styled.form`
	width: 100%;

	input::placeholder {
		font-size: 13px;

		${media.phone`
			font-size: 15px;
		`};

		${media.tablet`
			font-size: 18px;
		`};
	}

	@media print {
		display: none;
	}
`;

export interface AddItemFields {
	title: InputShape<string>;
}

type Props = {} & InjectedFormProps<AddItemFields> & WrappedComponentProps;

class ShoppingListAddItemForm extends React.Component<Props> {
	public render() {
		const { intl, fields } = this.props;
		const { title } = fields;
		return (
			<StyledAddItemForm onSubmit={this.onFormSubmit} noValidate>
				<Input
					name="title"
					value={title.value}
					inputSize={{ default: InputSize.small, tablet: InputSize.large }}
					borderColor="blue"
					placeholder={intl.formatMessage({ id: 'user_shopping_list_add_item_placeholder' })}
					onChange={this.onChange}
				/>
			</StyledAddItemForm>
		);
	}

	private onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		const { onSubmit, fields } = this.props;
		const title = fields.title.value.trim();
		if (title.length) {
			onSubmit(e);
		} else {
			e.preventDefault();
		}
	};

	private onChange = (event: React.FormEvent<HTMLInputElement>) => {
		const { value, name } = event.currentTarget;
		this.props.onInputChange(value, name);
	};
}

const addItemFields: AddItemFields = {
	title: { value: '' },
};

export default injectForm(addItemFields)(injectIntl(ShoppingListAddItemForm));
