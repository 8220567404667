import * as React from 'react';
import { LabeledIconButtonWrapper, LabeledIconButtonProps } from './common';

const ButtonLink = LabeledIconButtonWrapper('a');

const LabeledIconButtonLink: React.FC<LabeledIconButtonProps & React.AnchorHTMLAttributes<HTMLAnchorElement>> = ({
	icon,
	iconPlacement,
	children,
	...props
}) => {
	const iconProps = {
		size: 18,
	};

	return (
		<ButtonLink {...props} iconPlacement={iconPlacement}>
			{icon && (iconPlacement === 'left' || !iconPlacement) && React.cloneElement(icon, iconProps)}
			{children}
			{icon && iconPlacement === 'right' && React.cloneElement(icon, iconProps)}
		</ButtonLink>
	);
};

LabeledIconButtonLink.defaultProps = {
	buttonStyle: 'primary',
};

export default LabeledIconButtonLink;
