import { Locales } from 'common/types/locale';

const baseMessages = {
	fi: {
		global_resource_load_error_oh_no: 'Voi ei.',
		global_resource_error: 'Palvelussa tapahtui virhe.',
	},
	en: {
		global_resource_load_error_oh_no: 'Oh no.',
		global_resource_error: 'An error occured in the service.',
	},
};

export function getBaseMessages(locale: Locales) {
	if (locale === 'fi') {
		return baseMessages.fi;
	} else {
		return baseMessages.en;
	}
}
