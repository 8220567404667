import * as React from 'react';
import { SupportedNewsletters, Newsletters } from './interfaces';
import { MifuIcon, BakingMuffinsIcon, YoungIcon, ProductsIcon, KidIcon } from 'styleguide/components/Icons';

const config: Newsletters<SupportedNewsletters> = {
	ValioNewsletter: {
		title: 'widget_newsletter_valio_title',
		description: 'widget_newsletter_valio_description',
		icon: <MifuIcon />,
	},
	Baking: {
		title: 'widget_newsletter_baking_title',
		description: 'widget_newsletter_baking_description',
		icon: <BakingMuffinsIcon />,
	},
	FamilyNewsletter: {
		title: 'widget_newsletter_family_title',
		description: 'widget_newsletter_family_description',
		icon: <YoungIcon />,
	},
	NutritionAndHealth: {
		title: 'widget_newsletter_nutrition_title',
		description: 'widget_newsletter_nutrition_description',
		icon: <ProductsIcon />,
	},
	ChildrenNutrition: {
		title: 'widget_newsletter_child_nutrition_title',
		description: 'widget_newsletter_child_nutrition_description',
		icon: <KidIcon />,
	},
};

export default config;
