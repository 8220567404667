import * as React from 'react';
import styled, { ThemeContext } from 'styled-components';
import { media, mediaBreakpoints, withWindow } from '../../helpers';
import { css } from 'styled-components';
import Image from '../../components/PicturesAndVideos/Image';
import throttle from 'lodash/throttle';
import { ImageSource } from '../../interfaces';
import { MediaType, VideoSource } from 'styleguide/types/mediaTypes';
import HeroBackgroundVideo from './components/HeroBackgroundVideo';

const HERO_MAX_IMG_PRESET_HEIGHT = 1080;

export type HeroBannerSize = 'small' | 'medium' | 'large' | undefined;

export interface HeroWrapperProps {
	size?: HeroBannerSize;
	className?: string;
	offsetY?: number;
	fullWidthHeroBanner?: boolean;
}

const getHeroHeight = (props: HeroWrapperProps) => {
	const { size } = props;
	if (size === 'small') {
		return css`
			height: 250px;

			${media.tablet`
			height: 350px;
		`};
		`;
	}
	if (size === 'medium') {
		return css`
			height: 250px;

			${media.tablet`
			height: 450px;
		`};
		`;
	}
	if (size === 'large') {
		return css`
			${media.tablet`
				height: 512px;
			`};
			${media.desktop`
				height: 900px;
			`};
			${media.desktop1440`
				height: 900px;
			`}
		`;
	}
	return css`
		height: 250px;

		${media.tablet`
		height: 512px;
	`};
		${media.desktop`
		height: 450px;
	`};
		${media.desktop1440`
		height: 650px;
	`};
	`;
};

const HeroWrapper = styled('div')<HeroWrapperProps>`
	position: relative;
	width: 100%;
	padding: 0;
	margin: 0 auto;
	max-width: ${props => (props.fullWidthHeroBanner ? 'auto' : `${mediaBreakpoints.desktop1440}px`)};
	min-width: ${mediaBreakpoints.phone320}px;
	max-height: ${props => (props.size === 'large' ? '900' : '650')}px;
	min-height: 250px;

	${props => getHeroHeight(props)};

	@media print {
		display: none;
	}

	&.search-hero {
		height: 274px;

		${media.tablet`
			height: 450px;
		`};
	}
`;

const HeroImageWrapper = styled('div')<HeroWrapperProps>`
	width: 100%;
	height: 100%;
	position: absolute;
	overflow: hidden;
`;

const HeroImage = styled(({ offsetYValue, ...rest }) => <Image {...rest} />)`
	position: absolute;
	left: 50%;
	top: 50%;
	width: 100%;
	min-height: 100%;
	object-fit: cover;
	transform: translate(-50%, -50%);

	${media.desktop<{ offsetYValue: string }>`
		height: auto;
		transform: translate(-50%, ${props => props.offsetYValue});
	`};

	/* IE 11 Hack */
	@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
		width: auto;
		height: auto;
		max-width: 100%;
		min-width: 100%;
		min-height: 100%;
	}
`;

export interface OverlayWrapperProps {
	overlayColor?: string;
}

const OverlayWrapper = styled('div')<OverlayWrapperProps>`
	width: 100%;
	height: 100%;
	opacity: 0.6;
	position: absolute;
	${props =>
		props.overlayColor
			? `
		background-color: ${props.overlayColor};
	`
			: 'display: none;'};
`;

export interface HeroProps {
	src: string | ImageSource[] | VideoSource[] | null;
	mediaType?: MediaType;
}

export type Props = HeroProps & HeroWrapperProps & OverlayWrapperProps;

interface HeroState {
	offsetYValue: string;
}

class Hero extends React.Component<Props, HeroState> {
	public static contextType = ThemeContext;
	public context!: React.ContextType<typeof ThemeContext>;
	public imgRef: React.RefObject<HTMLImageElement>;

	private onResize = throttle(() => {
		this.setState({
			offsetYValue: this.getOffsetY(),
		});
	});

	constructor(props: Props) {
		super(props);

		this.state = {
			offsetYValue: this.getOffsetY(),
		};

		this.imgRef = React.createRef();
	}

	public componentDidMount() {
		const image = this.imgRef.current;
		if (image) {
			image.onload = () => this.onResize();
		}

		if (this.props.offsetY) {
			withWindow(w => {
				w.addEventListener('resize', this.onResize);
			});
		}
	}

	public componentWillUnmount() {
		if (this.props.offsetY) {
			withWindow(w => w.removeEventListener('resize', this.onResize));
		}
	}

	public render() {
		const { children, overlayColor, src, offsetY, size, mediaType, ...rest } = this.props;
		const { offsetYValue } = this.state;
		const hasVideo = mediaType === 'video';
		const hasImage = !hasVideo && ((typeof src === 'string' && src) || (src && src.length > 0));
		const heroBannerSize = size || this.context.styledVariations.heroBannerDefaultSize;

		return (
			<HeroWrapper
				size={heroBannerSize}
				{...rest}
				fullWidthHeroBanner={this.context.styledVariations.fullWidthHeroBanner}>
				<HeroImageWrapper size={size}>
					{hasVideo && <HeroBackgroundVideo sources={src as VideoSource[]} />}
					{hasImage && <HeroImage src={src} offsetYValue={offsetYValue} imgRef={this.imgRef} alt="" />}
				</HeroImageWrapper>
				{overlayColor && !hasVideo && <OverlayWrapper overlayColor={overlayColor} />}
				{children}
			</HeroWrapper>
		);
	}

	private getOffsetY() {
		const { offsetY } = this.props;

		if (!offsetY) {
			return '-50%';
		}

		let windowWidth = mediaBreakpoints.desktop;
		withWindow(w => (windowWidth = w.innerWidth));

		const imageHeight = this.imgRef?.current?.height ?? HERO_MAX_IMG_PRESET_HEIGHT;

		if (windowWidth >= mediaBreakpoints.desktop1440) {
			return `${0 - imageHeight / 2 + offsetY}px`;
		}

		const adjustedOffsetFactor = imageHeight / HERO_MAX_IMG_PRESET_HEIGHT;

		return `${0 - imageHeight / 2 + offsetY * adjustedOffsetFactor}px`;
	}
}

export default Hero;
