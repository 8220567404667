import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { OrderStatus } from '../interfaces';
import styled, { DefaultTheme } from 'styled-components';
import { media } from 'styleguide/helpers';
import { CheckIcon } from 'styleguide/components/Icons';
import { css } from 'styled-components';

type StepState = 'DISABLED' | 'ACTIVE' | 'COMPLETE';

interface StepProps {
	state: StepState;
}

const getStepColor = (theme: DefaultTheme, state: StepState) => {
	if (state === 'ACTIVE') {
		return theme.colors.stepActive;
	}
	if (state === 'COMPLETE') {
		return theme.colors.stepComplete;
	}
	return theme.colors.stepIncomplete;
};

const Steps = styled.ol`
	margin: ${({ theme }) => theme.grid.gutterInPx(4)} auto 0;
	padding: 0;
	list-style: none;
	display: flex;
	justify-content: space-between;
	max-width: 400px;
`;

const Step = styled.li<StepProps>`
	${({ theme }) => theme.typography.heading};
	font-size: 10px;
	line-height: 16px;
	color: ${({ state, theme }) => getStepColor(theme, state)};
	text-transform: uppercase;
	text-align: center;
	flex: 1 0 auto;
	flex: 1 0 calc(100% / 3);
	width: calc(100% / 3);
	white-space: nowrap;
	position: relative;

	${media.tablet`
		font-size: 12px;
	`};

	&:nth-child(2):before,
	&:nth-child(2):after {
		content: '';
		border-top: 2px dashed;
		position: absolute;
		width: 100%;
		height: 2px;
		top: 18px;
		z-index: 1;
	}
	&:nth-child(2):before {
		border-color: ${({ state, theme }) =>
			state !== 'DISABLED' ? theme.colors.stepComplete : theme.colors.stepIncomplete};
		left: -50%;
	}
	&:nth-child(2):after {
		border-color: ${({ state, theme }) =>
			state === 'COMPLETE' ? theme.colors.stepComplete : theme.colors.stepIncomplete};
		left: 50%;
	}
`;

const getStepIndicatorColor = (theme: DefaultTheme, state: StepState) => {
	if (state === 'DISABLED') {
		return theme.colors.stepIncomplete;
	}
	return theme.colors.white;
};

const getStepIndicatorBackground = (theme: DefaultTheme, state: StepState) => {
	if (state === 'ACTIVE') {
		return theme.colors.stepActive;
	}
	if (state === 'COMPLETE') {
		return theme.colors.stepComplete;
	}
	return theme.colors.white;
};

const StepIndicator = styled.span<StepProps>`
	display: block;
	width: 36px;
	height: 36px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	color: ${({ state, theme }) => getStepIndicatorColor(theme, state)};
	font-size: 14px;
	background: ${({ state, theme }) => getStepIndicatorBackground(theme, state)};
	border: 2px solid ${({ state, theme }) => getStepIndicatorColor(theme, state)};
	margin: 0 auto ${({ theme }) => theme.grid.gutterInPx()};
	position: relative;
	z-index: 2;

	span {
		${({ state, theme }) => {
			if (state === 'ACTIVE') {
				return css`
					width: 26px;
					height: 26px;
					border-radius: 50%;
					display: flex;
					justify-content: center;
					align-items: center;
					border: 1px solid ${theme.colors.white};
				`;
			}
			return null;
		}};
	}

	path {
		fill: ${({ theme }) => theme.colors.white};
	}
`;

interface Props {
	status: OrderStatus;
}

const OrderSteps: React.FC<Props> = ({ status }) => {
	let cartState: StepState = 'ACTIVE';
	let deliveryState: StepState = 'DISABLED';
	let confirmationState: StepState = 'DISABLED';

	if (status === 'DELIVERY') {
		cartState = 'COMPLETE';
		deliveryState = 'ACTIVE';
	}
	if (status === 'CONFIRMATION') {
		cartState = 'COMPLETE';
		deliveryState = 'COMPLETE';
		confirmationState = 'ACTIVE';
	}

	return (
		<Steps>
			<Step state={cartState}>
				<StepIndicator state={cartState}>
					{cartState === 'COMPLETE' && <CheckIcon />}
					{cartState !== 'COMPLETE' && <span>1</span>}
				</StepIndicator>
				<FormattedMessage id="materials_search_cart" />
			</Step>
			<Step state={deliveryState}>
				<StepIndicator state={deliveryState}>
					{deliveryState === 'COMPLETE' && <CheckIcon />}
					{deliveryState !== 'COMPLETE' && <span>2</span>}
				</StepIndicator>
				<FormattedMessage id="materials_search_order_status_delivery_step" />
			</Step>
			<Step state={confirmationState}>
				<StepIndicator state={confirmationState}>
					<span>3</span>
				</StepIndicator>
				<FormattedMessage id="materials_search_order_status_confirmation_step" />
			</Step>
		</Steps>
	);
};

export default OrderSteps;
