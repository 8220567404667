import * as React from 'react';
import styled from 'styled-components';
import Select from 'styleguide/components/Inputs/Select/Select';
import { media } from 'styleguide/helpers/media';

export interface SortOrder {
	label: string;
	value: string;
	selected?: boolean | undefined;
}

export type SortDirection = 'asc' | 'desc';

const StyledSort = styled(Select)`
	& > select {
		font-size: 16px;
		line-height: 29px;
		height: 29px;
		padding: 0 20px 0 5px;
	}

	& > svg {
		right: 5px;
	}

	order: 2;
	width: calc(50% - 6px);
	height: 28px;

	${media.tablet`
		margin-left: auto;
    
    & > select {
      font-size: 12px;
	    line-height: 28px;
	    height: 28px;
      padding: 0 40px 0 15px;
    }

    & > svg {
      right: 12px;
    }
  `};

	${media.desktop`
    width: auto;
  `};

	${media.desktop1440`
		margin-right: 30px;
	`};

	& > select {
		width: 100%;
	}

	& > svg {
		width: 18px;
	}
`;

interface SortOrderSelectProps {
	sortOptions: SortOrder[];
	onSortClick: (sort: string) => void;
	value?: string;
}

const SortOrderSelect: React.FC<SortOrderSelectProps & React.SelectHTMLAttributes<HTMLSelectElement>> = ({
	sortOptions,
	onSortClick,
	value,
}) => {
	const onSortChange = (event: React.FormEvent<HTMLSelectElement>) => {
		onSortClick(event.currentTarget.value);
	};

	return (
		<StyledSort name="sortOptions" onChange={onSortChange} defaultValue={value}>
			{sortOptions.map(sortOption => (
				<option key={`sort-option-${sortOption.value}`} value={sortOption.value}>
					{sortOption.label}
				</option>
			))}
		</StyledSort>
	);
};

export default SortOrderSelect;
