import extend from './extend';
import valio from './valio';

const valiobaltia = extend(valio, {
	styledVariations: {
		heroBannerDefaultSize: undefined,
		homePageHeroSize: 'medium',
	},
});

export default valiobaltia;
