import * as React from 'react';

const blurFilter = (
	<filter id="blurFilter" y="-26" height="40" x="-26" width="150">
		<feOffset in="SourceAlpha" dx="0.5" dy="0.5" result="offset2" />
		<feGaussianBlur in="offset2" stdDeviation="0.7" result="blur" />
		<feMerge>
			<feMergeNode in="blur" />
			<feMergeNode in="SourceGraphic" />
		</feMerge>
	</filter>
);

const blurFilterObj = { filter: blurFilter, id: 'blurFilter' };

export default blurFilterObj;
