import * as React from 'react';
import NormalIconWrapper, { NormalIconWrapperProps } from '../NormalIconWrapper';

const CartIcon: React.FC<NormalIconWrapperProps & React.SVGAttributes<SVGElement>> = ({ color, ...props }) => {
	return (
		<NormalIconWrapper {...props}>
			<g clipPath="url(#clip0_6_149)">
				<path
					d="M19.901 12.9192H9.28363L9.04753 12.2719L20.0672 10.9687L21.9825 2.93223H5.53171C4.3248 0.00868809 4.06241 0 3.71695 0H0.655933C0.481969 0 0.315126 0.0686475 0.192115 0.190848C0.0691032 0.313048 0 0.47879 0 0.651607C0 0.824424 0.0691032 0.990167 0.192115 1.11237C0.315126 1.23457 0.481969 1.30321 0.655933 1.30321H3.4065C4.06243 2.5543 6.30572 8.46221 8.21667 13.7706L8.36971 14.205H19.9142C20.0881 14.205 20.255 14.1364 20.378 14.0142C20.501 13.892 20.5701 13.7263 20.5701 13.5534C20.5701 13.3806 20.501 13.2149 20.378 13.0927C20.255 12.9705 20.0881 12.9018 19.9142 12.9018L19.901 12.9192ZM20.3383 4.2311L19.0264 9.77411L8.61024 10.9904C7.5214 7.99739 6.69928 5.81233 6.07833 4.2311H20.3383Z"
					fill="#005670"
				/>
				<path
					d="M9.8915 15.1477C9.40803 15.1477 8.93539 15.2901 8.53341 15.5569C8.13142 15.8238 7.81811 16.203 7.6331 16.6467C7.44808 17.0905 7.39967 17.5787 7.49399 18.0498C7.58831 18.5208 7.82114 18.9535 8.163 19.2931C8.50486 19.6327 8.94043 19.864 9.4146 19.9577C9.88878 20.0514 10.3803 20.0033 10.8269 19.8195C11.2736 19.6357 11.6553 19.3245 11.9239 18.9251C12.1925 18.5258 12.3359 18.0563 12.3359 17.576C12.3359 16.932 12.0784 16.3143 11.6199 15.8589C11.1615 15.4035 10.5398 15.1477 9.8915 15.1477ZM9.8915 18.7011C9.6675 18.7011 9.44851 18.6351 9.26226 18.5115C9.076 18.3879 8.93081 18.2122 8.84509 18.0066C8.75937 17.801 8.73696 17.5748 8.78066 17.3565C8.82436 17.1383 8.93225 16.9378 9.09064 16.7804C9.24903 16.6231 9.45081 16.516 9.67051 16.4725C9.8902 16.4291 10.1179 16.4514 10.3249 16.5365C10.5318 16.6217 10.7087 16.7659 10.8332 16.951C10.9576 17.136 11.0241 17.3535 11.0241 17.576C11.0241 17.8744 10.9048 18.1606 10.6924 18.3716C10.48 18.5826 10.1919 18.7011 9.8915 18.7011Z"
					fill="#005670"
				/>
				<path
					d="M18.13 15.1477C17.6465 15.1477 17.1739 15.2901 16.7719 15.5569C16.37 15.8238 16.0566 16.203 15.8716 16.6467C15.6866 17.0905 15.6382 17.5787 15.7325 18.0498C15.8268 18.5208 16.0596 18.9535 16.4015 19.2931C16.7433 19.6327 17.1789 19.864 17.6531 19.9577C18.1273 20.0514 18.6188 20.0033 19.0654 19.8195C19.5121 19.6357 19.8939 19.3245 20.1625 18.9251C20.4311 18.5258 20.5744 18.0563 20.5744 17.576C20.5733 16.9323 20.3154 16.3154 19.8572 15.8602C19.399 15.405 18.7779 15.1488 18.13 15.1477ZM18.13 18.7011C17.906 18.7011 17.687 18.6351 17.5007 18.5115C17.3145 18.3879 17.1694 18.2122 17.0836 18.0066C16.9979 17.801 16.9754 17.5748 17.0191 17.3565C17.0628 17.1383 17.1707 16.9378 17.3291 16.7804C17.4875 16.6231 17.6893 16.516 17.909 16.4725C18.1287 16.4291 18.3564 16.4514 18.5634 16.5365C18.7703 16.6217 18.9472 16.7659 19.0717 16.951C19.1961 17.136 19.2625 17.3535 19.2625 17.576C19.2625 17.8744 19.1432 18.1606 18.9308 18.3716C18.7184 18.5826 18.4304 18.7011 18.13 18.7011Z"
					fill="#005670"
				/>
			</g>
			<defs>
				<clipPath id="clip0_6_149">
					<rect width="22" height="20" fill="white" />
				</clipPath>
			</defs>
		</NormalIconWrapper>
	);
};

export default CartIcon;
