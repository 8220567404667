import * as React from 'react';
import NormalIconWrapper, { NormalIconWrapperProps } from '../NormalIconWrapper';
import { ThemeContext } from 'styled-components';

const OpenIcon: React.FC<NormalIconWrapperProps & React.SVGAttributes<SVGElement>> = ({ color, ...props }) => {
	const theme = React.useContext(ThemeContext);
	const fillColor = color ?? theme.colors.brandPrimary;
	return (
		<NormalIconWrapper {...props}>
			<path
				d="M19.467 20.467H5.533V6.533h3.8a1.267 1.267 0 000-2.533H4.267C3.567 4 3 4.567 3 5.267v16.466c0 .7.567 1.267 1.267 1.267h16.466c.7 0 1.267-.567 1.267-1.267v-5.066a1.267 1.267 0 00-2.533 0v3.8z"
				fill={fillColor}
			/>
			<path
				d="M21.714 3C22.424 3 23 3.576 23 4.286v6.428a1.286 1.286 0 01-2.571 0v-3.39l-4.234 4.235a1.286 1.286 0 01-1.697.107l-.121-.107a1.286 1.286 0 010-1.818l4.167-4.17h-3.258c-.652 0-1.2-.488-1.277-1.135L14 4.286C14 3.576 14.576 3 15.286 3h6.428z"
				fill={fillColor}
			/>
		</NormalIconWrapper>
	);
};

export default OpenIcon;
