import * as React from 'react';
import * as tipsIcon from '../../../../images/tips-icon.svg';
import { useIntl } from 'react-intl';

const TipsIcon: React.FC = () => {
	const intl = useIntl();
	return <img src={tipsIcon} width="80" height="80" alt={intl.formatMessage({ id: 'icon_tips' })} />;
};

export default TipsIcon;
