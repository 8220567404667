import { createCustomAction, createStandardAction, getType, ActionType } from 'typesafe-actions';
import { Reducer } from 'redux';
import { ResourceActions, resourceActions } from 'common/components/Resource/reducers/actions';
import { ResourceType } from 'common/components/Resource/types';
import { RecipeShoppingListState } from '../interfaces';

export const recipeShoppingListActions = {
	addToShoppingList: createCustomAction(
		'RECIPE/ADD_TO_SHOPPING_LIST',
		type => (recipeId: string, portions?: number) => ({
			type,
			payload: { recipeId, portions },
		})
	),

	addSucceeded: createStandardAction('RECIPE/ADD_TO_SHOPPING_LIST_SUCCESS')<string>(),

	addFailed: createStandardAction('RECIPE/ADD_TO_SHOPPING_LIST_FAILED')<string>(),

	removeFromShoppingList: createCustomAction(
		'RECIPE/REMOVE_FROM_SHOPPING_LIST',
		type => (recipeId: string, refreshShoppingList?: boolean) => ({
			type,
			payload: { recipeId, refreshShoppingList },
		})
	),

	removeSucceeded: createStandardAction('RECIPE/REMOVE_FROM_SHOPPING_LIST_SUCCESS')(),

	removeFailed: createCustomAction('RECIPE/REMOVE_FROM_SHOPPING_LIST_FAILED', type => (error: string) => ({
		type,
		payload: { error },
	})),
};

export const recipeShoppingListReducer: Reducer<
	RecipeShoppingListState,
	RecipeShoppingListActions | ResourceActions
> = (state = { isLoading: false }, action): RecipeShoppingListState => {
	switch (action.type) {
		case getType(resourceActions.setResource):
			if (action.payload.resource.type === ResourceType.recipe) {
				return {
					...state,
					shoppingListRecipeId: action.payload.resource.content.shoppingListRecipeId,
				};
			}

		case getType(recipeShoppingListActions.addToShoppingList):
			return { ...state, isLoading: true };

		case getType(recipeShoppingListActions.addSucceeded):
			return {
				...state,
				isLoading: false,
				shoppingListRecipeId: action.payload,
			};

		case getType(recipeShoppingListActions.addFailed):
			return { ...state, isLoading: false, error: action.payload };

		case getType(recipeShoppingListActions.removeFromShoppingList):
			return { ...state, isLoading: true };

		case getType(recipeShoppingListActions.removeSucceeded):
			return { ...state, isLoading: false, shoppingListRecipeId: null };

		case getType(recipeShoppingListActions.removeFailed):
			return { ...state, isLoading: false, error: action.payload.error };

		default:
			return state;
	}
};

export type RecipeShoppingListActions = ActionType<typeof recipeShoppingListActions>;
