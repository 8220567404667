import * as React from 'react';
import NormalIconWrapper, { NormalIconWrapperProps } from '../NormalIconWrapper';
import { generate } from 'shortid';
import { ThemeContext } from 'styled-components';

export interface ZoomIconProps extends NormalIconWrapperProps {
	hasShadow?: boolean;
}

const ZoomIcon: React.FC<ZoomIconProps & React.SVGAttributes<SVGElement>> = ({ color, hasShadow, ...props }) => {
	const theme = React.useContext(ThemeContext);
	const fillColor = color ?? theme.colors.brandPrimary;

	const blurId = 'zoom-icon-blur-' + generate();
	return (
		<NormalIconWrapper {...props}>
			{hasShadow && (
				<defs>
					<filter x="-29.5%" y="-23.9%" width="159.1%" height="156.5%" filterUnits="objectBoundingBox" id={blurId}>
						<feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1" />
						<feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
						<feColorMatrix
							values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"
							in="shadowBlurOuter1"
							result="shadowMatrixOuter1"
						/>
						<feMerge>
							<feMergeNode in="shadowMatrixOuter1" />
							<feMergeNode in="SourceGraphic" />
						</feMerge>
					</filter>
				</defs>
			)}
			<g filter={hasShadow ? `url(#${blurId})` : undefined}>
				<path
					fill={fillColor}
					d="M12 20a7.948 7.948 0 0 1-5.657-2.343A7.948 7.948 0 0 1 4 12c0-2.137.832-4.146 2.343-5.657A7.948 7.948 0 0 1 12 4c2.137 0 4.146.832 5.657 2.343A7.948 7.948 0 0 1 20 12a7.948 7.948 0 0 1-2.343 5.657A7.948 7.948 0 0 1 12 20m11.707 2.293l-3.964-3.964A9.923 9.923 0 0 0 22 12a9.934 9.934 0 0 0-2.929-7.071A9.934 9.934 0 0 0 12 2a9.934 9.934 0 0 0-7.071 2.929A9.934 9.934 0 0 0 2 12a9.934 9.934 0 0 0 2.929 7.071A9.934 9.934 0 0 0 12 22a9.923 9.923 0 0 0 6.329-2.257l3.964 3.964a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414"
				/>
				<path fill={fillColor} d="M8 11h8a1 1 0 0 1 0 2H8a1 1 0 0 1 0-2z" />
				<path fill={fillColor} d="M13 8v8a1 1 0 0 1-2 0V8a1 1 0 0 1 2 0z" />
			</g>
		</NormalIconWrapper>
	);
};

export default ZoomIcon;
