import * as React from 'react';
import { mediaBreakpoints } from '../../../helpers';
import { ImageProps, ImageSource } from '../../../interfaces';

const getSourceSet = (src: string | ImageSource[]) => {
	if (typeof src === 'string') {
		return undefined;
	}

	return src
		.map(source => {
			return `${source.url} ${source.width}w${source.pixelDensity ? ` ${source.pixelDensity}x` : ''}`;
		})
		.join(',');
};

const sortSources = (a: ImageSource, b: ImageSource) => {
	if (a.width < b.width) {
		return -1;
	}
	if (a.width > b.width) {
		return 1;
	}
	return 0;
};

const getSizes = (src: string | ImageSource[]) => {
	if (typeof src === 'string') {
		return undefined;
	}

	return src
		.sort(sortSources)
		.reverse()
		.map((source, index) => {
			const { media, width, displayWidth } = source;
			if (media === 'phone320') {
				return `(max-width: ${mediaBreakpoints.phone320}px) ${displayWidth || width}px`;
			}
			if (index === src.length - 1 || !media) {
				return `${displayWidth || width}px`;
			}
			return `(min-width: ${mediaBreakpoints[media]}px) ${displayWidth || width}px`;
		})
		.join(',');
};

const getImageSource = (src: string | ImageSource[]) => {
	if (typeof src === 'string') {
		return src;
	}
	const imageSource = src.find(source => source.media === 'desktop' || source.media === 'desktop1440');
	if (imageSource) {
		return imageSource.url;
	}
	return '';
};

const Image: React.FC<ImageProps & React.HTMLAttributes<HTMLImageElement>> = ({ alt, src, imgRef, ...rest }) => {
	if (src) {
		return (
			<img
				alt={alt}
				srcSet={getSourceSet(src)}
				sizes={getSizes(src)}
				src={getImageSource(src)}
				ref={imgRef}
				{...rest}
			/>
		);
	}
	return null;
};

export default Image;
