import * as React from 'react';
import NormalIconWrapper, { NormalIconWrapperProps } from '../NormalIconWrapper';
import { ThemeContext } from 'styled-components';

const PenIcon: React.FC<NormalIconWrapperProps & React.SVGAttributes<SVGElement>> = ({ color, ...props }) => {
	const theme = React.useContext(ThemeContext);

	return (
		<NormalIconWrapper {...props}>
			<path
				fill={color ?? theme.colors.brandPrimary}
				d="M21.48 8.895L17.105 4.52l2.106-2.105 4.375 4.374-2.106 2.106zM7.609 22.767l-5.363.963.986-5.337 1.375-1.376 4.375 4.375-1.373 1.375zM25.707 6.08L19.917.293a1 1 0 0 0-1.413 0l-3.52 3.52a1 1 0 0 0 0 1.414l5.082 5.082-9.67 9.668-4.374-4.375 7.31-7.31a1 1 0 0 0-1.414-1.414L3.9 14.896l-2.302 2.302a.993.993 0 0 0-.276.526L.017 24.788a1 1 0 0 0 1.16 1.166L8.27 24.68a.994.994 0 0 0 .53-.279l2.303-2.302 11.083-11.084 3.52-3.519a1 1 0 0 0 0-1.415z"
			/>
		</NormalIconWrapper>
	);
};

export default PenIcon;
