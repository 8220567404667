import * as React from 'react';
import { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';

export interface LoadingProps {
	visible: boolean;
}

const Loading: React.FC<LoadingProps> = ({ visible }) => {
	return (
		<Fragment>
			{visible && (
				<div>
					<FormattedMessage id="global_loading" />
					...
				</div>
			)}
		</Fragment>
	);
};

export default Loading;
