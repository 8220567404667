import * as React from 'react';
import throttle from 'lodash/throttle';
import endsWith from 'lodash/endsWith';
import { connect, MapStateToProps } from 'react-redux';
import loadable from '@loadable/component';
import { withWindow } from 'styleguide/helpers/window';
import styled from 'styled-components';
import { media, mediaBreakpoints } from 'styleguide/helpers/media';
const Navigation = loadable(() => import(/* webpackChunkName: "navigation-fi" */ '../Navigation/Navigation'));
import EmergencyNotifications from './EmergencyNotifications';
import { noop } from 'redux-saga/utils';
import { getDocumentHeight } from 'utils/document';
import { UserState } from 'pagetypes/User/reducers/reducers';
import { ResourceState } from '../Resource/reducers/reducers';
import { HeaderState, domActions } from '../Dom/reducers';
import { NavigationState, NavigationLayoutType } from '../Navigation/reducers';
import { State } from '../../reducers';
import { resourceActions } from '../Resource/reducers/actions';
const SubNavigation = loadable(() => import(/* webpackChunkName: "navigation" */ '../SubNavigation/SubNavigation'));
import { CmsContentState } from 'pagetypes/CmsContent/interfaces';
import { SecondaryNavigationLinkApiShape } from '../Navigation/services';
import { NavLinkItemProps } from '../Navigation/interfaces';
import injectRoutable, { InjectedRoutableProps } from '../Routing/Routable';
import ProfessionalWebStoreButtons from '../Navigation/components/LeftNavButtonGroup/ProfessionalWebStoreButtons';
const ComNavigation = loadable(() => import(/* webpackChunkName: "navigation-com" */ '../Navigation/ComNavigation'));

declare global {
	interface Window {
		mobileApp?: boolean;
	}
}

interface HeaderWrapperProps {
	hasSecondaryNavigation: boolean;
	useComNavigation: boolean;
}

const HeaderWrapper = styled.header<HeaderWrapperProps>`
	position: sticky;
	top: 0;
	left: 0;
	right: 0;
	z-index: ${props => props.theme.zIndices.navigation};
	pointer-events: none;

	@media print {
		display: none;
	}

	${media.desktop<HeaderWrapperProps>`
		position: ${({ useComNavigation }) => (useComNavigation ? 'sticky' : 'fixed')};
	`};
`;

type Props = {} & HeaderStateProps & HeaderDispatchProps & InjectedRoutableProps & React.HTMLAttributes<HTMLElement>;

class Header extends React.Component<Props, {}> {
	public headerRef: React.RefObject<HTMLElement>;
	private lastScrollTop: number;

	private onScroll = throttle(() => withWindow(this.onScrollWithWindow), 250);

	public componentDidMount() {
		this.headerRef = React.createRef();

		withWindow(w => {
			w.addEventListener('scroll', this.onScroll);
		});
	}

	public componentWillUnmount() {
		withWindow(w => {
			w.removeEventListener('scroll', this.onScroll);
		});
	}

	public render() {
		const {
			resource,
			dismissNotification,
			header: { isFlattened },
			useComNavigation,
			navigation: { secondaryNavigation, layout, isLoading },
		} = this.props;

		const isMobileApp = withWindow(w => w.mobileApp === true);
		const resourceError = resource.error;
		const notifications = (resource.resourceData && resource.resourceData.notifications) || [];

		const hideNotifications = notifications.every(notification => notification.isDismissed === true);

		const displayNotifications = !hideNotifications && notifications.length > 0;
		const hasSecondaryNavigation = !!secondaryNavigation;

		const isProfessionalLayout = layout === 'Professional';

		return (
			<HeaderWrapper
				ref={this.headerRef}
				hasSecondaryNavigation={hasSecondaryNavigation}
				useComNavigation={useComNavigation}>
				{displayNotifications && (
					<EmergencyNotifications notifications={notifications} onDismissNotification={dismissNotification} />
				)}
				{!isLoading && !resourceError && this.renderNavigation(useComNavigation, layout, isMobileApp, !!resourceError)}

				{isProfessionalLayout && (
					<ProfessionalWebStoreButtons onLinkClick={this.props.onLinkClick} isSecondary={true} />
				)}

				{secondaryNavigation && (
					<SubNavigation
						headerIsFlattened={isFlattened}
						color={secondaryNavigation.backgroundColor}
						links={this.createSubNavigationLinks(
							secondaryNavigation.links,
							endsWith(secondaryNavigation.homePageUrl, this.props.routing.pathname),
							resource,
							this.props.routing.pathname,
							resource.isDraft
						)}
						homePageTitle={secondaryNavigation.homepageTitle}
						homePageUrl={secondaryNavigation.homePageUrl}
						layout={layout}
					/>
				)}
			</HeaderWrapper>
		);
	}

	private createSubNavigationLinks = (
		links: SecondaryNavigationLinkApiShape[],
		isHomePageActive: boolean,
		resource: ResourceState,
		pathName: string,
		isDraft: boolean
	): NavLinkItemProps[] => {
		const setActiveLinkByFamily = (snl: NavLinkItemProps[]) => {
			const activeContentFamilyLinks = links.filter(
				l => (resource.content as CmsContentState).contentFamily === l.contentFamily
			);
			if (!isDraft && activeContentFamilyLinks.length === 1) {
				const activeContentFamilyLink = activeContentFamilyLinks[0];
				const link = snl.find(l => l.url === activeContentFamilyLink.url) as NavLinkItemProps;
				link.isActive = true;
			}
		};

		const subNavLinks = links.map(l => ({
			url: l.url,
			title: l.title,
			isActive: isHomePageActive ? false : endsWith(l.url, pathName),
		}));

		if (
			!isHomePageActive &&
			!subNavLinks.some(l => l.isActive === true) &&
			resource.content &&
			(resource.content as CmsContentState).contentFamily
		) {
			setActiveLinkByFamily(subNavLinks);
		}

		return subNavLinks;
	};

	private renderNavigation = (
		useComNavigation: boolean,
		layout: NavigationLayoutType,
		isMobileApp: boolean,
		hasResourceError: boolean
	) => {
		return useComNavigation ? (
			<ComNavigation layout={layout} hasResourceErrors={hasResourceError} />
		) : (
			<Navigation
				isMobileApp={isMobileApp}
				hasResourceError={hasResourceError}
				showSeasonalLinks={layout !== 'Professional'}
				layout={layout}
			/>
		);
	};

	private onScrollWithWindow = (w: Window) => {
		this.setFlattenState(w);
		w.innerWidth < mediaBreakpoints.tablet && this.setMobileVisibility(w);
	};

	private setFlattenState = (w: Window) => {
		const { header, setFlatten } = this.props;
		const state = w.pageYOffset > 0;
		header.isFlattened !== state ? setFlatten(state) : noop();
	};

	private setMobileVisibility = (w: Window) => {
		const { showNavigation, hideNavigation, header } = this.props;

		const scrollPosition = w.scrollY;
		if (scrollPosition === 0) {
			showNavigation();
			return;
		}

		const isProfessionalLayout = this.props.navigation.layout === 'Professional';

		const delta = isProfessionalLayout ? 180 : 100;

		if (Math.abs(this.lastScrollTop - scrollPosition) <= delta) {
			return;
		}

		if (scrollPosition > this.lastScrollTop && scrollPosition > (isProfessionalLayout ? 175 : 75)) {
			!header.isScrolled ? hideNavigation() : noop();
		} else {
			scrollPosition + w.innerHeight < getDocumentHeight()
				? header.isScrolled
					? showNavigation()
					: noop()
				: !header.isScrolled
				? hideNavigation()
				: noop();
		}

		this.lastScrollTop = scrollPosition;
	};
}

interface HeaderStateProps {
	user: UserState | null;
	resource: ResourceState;
	header: HeaderState;
	navigation: NavigationState;
	pathName: string;
	useComNavigation: boolean;
}

const mapStateToProps: MapStateToProps<HeaderStateProps, {}, State> = ({
	user,
	resource,
	dom,
	navigation,
	routing,
}): HeaderStateProps => {
	return {
		user,
		resource,
		header: dom.header,
		navigation,
		pathName: routing.pathname,
		useComNavigation: shouldRenderComNav(navigation.layout),
	};
};

function shouldRenderComNav(layout: NavigationLayoutType) {
	switch (layout) {
		case '':
		case 'ValioFi':
		case 'Professional': {
			return false;
		}
		default:
			return true;
	}
}

interface HeaderDispatchProps {
	dismissNotification: typeof resourceActions.dismissNotification;
	setFlatten: typeof domActions.setFlatten;
	showNavigation: typeof domActions.showNavigation;
	hideNavigation: typeof domActions.hideNavigation;
}

export default connect(mapStateToProps, {
	dismissNotification: resourceActions.dismissNotification,
	setFlatten: domActions.setFlatten,
	showNavigation: domActions.showNavigation,
	hideNavigation: domActions.hideNavigation,
})(injectRoutable(Header));
