import * as React from 'react';
import NormalIconWrapper, { NormalIconWrapperProps } from '../NormalIconWrapper';
import { ThemeContext } from 'styled-components';

const ReplyIcon: React.FC<NormalIconWrapperProps & React.SVGAttributes<SVGElement>> = ({ color, ...props }) => {
	const theme = React.useContext(ThemeContext);
	return (
		<NormalIconWrapper {...props}>
			<path
				fill={color ?? theme.colors.brandPrimary}
				d="M19.5 9.49H4.424l3.783-3.784a.999.999 0 1 0-1.414-1.413l-5.5 5.5a.999.999 0 0 0 0 1.414l5.5 5.5A.993.993 0 0 0 7.5 17a.999.999 0 0 0 .707-1.707L4.404 11.49H19.5c2.206 0 4 1.793 4 4 0 2.205-1.794 4-4 4h-2a1 1 0 0 0 0 2h2c3.31 0 6-2.691 6-6 0-3.31-2.69-6-6-6"
			/>
		</NormalIconWrapper>
	);
};

export default ReplyIcon;
