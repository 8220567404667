import { Reducer, Action, AnyAction } from 'redux';

export function composeReducers<S = any, A extends Action = AnyAction>(
	initialState: S,
	...reducers: Array<Reducer<S, Action>>
): Reducer<S, Action> {
	return (state = initialState, action) => {
		return reducers.reduce((s, r) => r(s, action), state);
	};
}
