import * as React from 'react';
import InputWithIcon from 'styleguide/components/Inputs/InputWithIcon/InputWithIcon';
import SendToMailIcon from 'styleguide/components/Icons/NormalIcons/SendToMailIcon/SendToMailIcon';
import Button from 'styleguide/components/Buttons/Button/Button';
import styled from 'styled-components';
import { media } from 'styleguide/helpers/media';
import Alert from 'styleguide/components/Typography/Alert/Alert';
import { AlertType } from 'styleguide/components/Typography/Alert/Alert';
import { injectIntl, WrappedComponentProps, FormattedMessage } from 'react-intl';
import { MapStateToProps, connect } from 'react-redux';
import { InputShape } from '../../Forms/interfaces';
import injectForm, { InjectedFormProps } from '../../Forms/Form';
import { ValidationPatterns, validateRequired, validateEmail } from '../../Forms/helpers';
import { State } from '../../../reducers';

const StyledRegistrationRequestForm = styled.form`
	padding: 0 20px;
	margin: 0 0 20px;

	.form-error {
		margin-top: 20px;
		font-size: 15px;

		.highlighted {
			display: block;
		}
	}
`;

const FormElementsWrapper = styled.div`
	${media.desktop`
    display: flex;
  `};

	.form-field {
		${media.desktop`
		  flex: 3 0 auto;
    `};
	}

	button {
		width: 100%;
		margin-top: 10px;

		${media.desktop`
      flex: 1 0 auto;
      width: auto;
      margin-top: 0;
      margin-left: 20px;
    `};
	}
`;

export interface RegistrationRequestFormFields {
	email: InputShape<string>;
}

export interface RegistrationRequestFormValidators {
	email: Array<(value: string) => string>;
}

interface RegistrationRequestFormProps {
	fields?: RegistrationRequestFormFields;
}

type Props = RegistrationRequestFormProps &
	InjectedFormProps<RegistrationRequestFormFields> &
	WrappedComponentProps &
	RegisterRequestFormStateProps;

class RegistrationRequestForm extends React.Component<Props> {
	public render() {
		const { onSubmit, fields, intl, isValid, isLoading, error } = this.props;
		const { email } = fields;
		return (
			<StyledRegistrationRequestForm onSubmit={onSubmit}>
				<FormElementsWrapper>
					<InputWithIcon
						icon={<SendToMailIcon />}
						type="email"
						name="email"
						value={email.value}
						validationError={email.touched ? email.error : ''}
						placeholder={intl.formatMessage({ id: 'forms_email_address' })}
						required={true}
						pattern={ValidationPatterns.EMAIL}
						autoComplete="username"
						onChange={this.onChange}
						onBlur={this.onBlur}
					/>
					<Button type="submit" disabled={!isValid || isLoading} size="medium">
						<FormattedMessage id="user_order_credentials" />
					</Button>
				</FormElementsWrapper>
				{error && (
					<Alert
						type={AlertType.Error}
						highlightedText={intl.formatMessage({ id: 'component_login_registration_failed' })}
						className="form-error">
						{intl.formatMessage({ id: error })}
					</Alert>
				)}
			</StyledRegistrationRequestForm>
		);
	}

	private onChange = (event: React.FormEvent<HTMLInputElement>) => {
		const { value, name } = event.currentTarget;
		this.props.onInputChange(value, name);
	};

	private onBlur = (event: React.FormEvent<HTMLInputElement>) => {
		const { value, name } = event.currentTarget;
		this.props.onInputBlur(value, name);
	};
}

const registrationFields: RegistrationRequestFormFields = {
	email: { value: '' },
};

const registrationValidators: RegistrationRequestFormValidators = {
	email: [validateRequired('component_login_validation_email_required'), validateEmail],
};

interface RegisterRequestFormStateProps {
	isLoading?: boolean;
	error?: string;
}

const mapStateToProps: MapStateToProps<RegisterRequestFormStateProps, {}, State> = ({
	login,
}): RegisterRequestFormStateProps => {
	const { registrationRequestForm } = login;
	return {
		isLoading: registrationRequestForm && registrationRequestForm.isLoading,
		error: registrationRequestForm && registrationRequestForm.error,
	};
};

export default connect(mapStateToProps)(
	injectForm(registrationFields, registrationValidators)(injectIntl(RegistrationRequestForm))
);
