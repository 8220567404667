import { RecipeCardInterface } from 'styleguide/interfaces/cardInterfaces';
import { fetchJson, throwMissingSiteUrlIdError } from 'utils/service';
import { RelatedProduct } from './interfaces';

export async function getRelatedRecipes(siteUrlId: string, productId: string): Promise<RecipeCardInterface[]> {
	if (!siteUrlId) {
		throwMissingSiteUrlIdError();
	}
	return await fetchJson<RecipeCardInterface[]>(`/api/sites/${siteUrlId}/products/${productId}/recipes`);
}

export async function getRelatedProducts(siteUrlId: string, productId: string): Promise<RelatedProduct[]> {
	if (!siteUrlId) {
		throwMissingSiteUrlIdError();
	}
	return await fetchJson<RelatedProduct[]>(`/api/sites/${siteUrlId}/products/${productId}/sisters`);
}

export const productRelatedItemsApi = {
	getRelatedRecipes,
	getRelatedProducts,
};

export type ProductRelatedItemsApi = typeof productRelatedItemsApi;
