import * as React from 'react';
import { injectIntl, WrappedComponentProps, FormattedMessage } from 'react-intl';
import styled, { css } from 'styled-components';
import InputWithIcon from 'styleguide/components/Inputs/InputWithIcon/InputWithIcon';
import SendToMailIcon from 'styleguide/components/Icons/NormalIcons/SendToMailIcon/SendToMailIcon';
import Button from 'styleguide/components/Buttons/Button/Button';
import { AlertType } from 'styleguide/components/Typography/Alert/Alert';
import Alert from 'styleguide/components/Typography/Alert/Alert';
import BodyText from 'styleguide/components/Typography/BodyText/BodyText';
import { connect, MapStateToProps } from 'react-redux';
import { InputShape } from '../../Forms/interfaces';
import injectForm, { InjectedFormProps } from '../../Forms/Form';
import { ValidationPatterns, validateRequired, validateEmail } from '../../Forms/helpers';
import { State } from '../../../reducers';

interface StyledForgotPasswordFormProps {
	isLoggingIn?: boolean;
}

const StyledForgotPasswordForm = styled.form<StyledForgotPasswordFormProps>`
	${props =>
		props.isLoggingIn
			? css`
					opacity: 0.5;
			  `
			: ''};

	& > button {
		width: 100%;
		margin-top: 15px;
	}

	& > p {
		margin: 20px 0 0;
	}

	.login-instruction {
		margin: 0 0 20px;
	}

	.form-error {
		margin-top: 20px;
		font-size: 15px;

		.highlighted {
			display: block;
		}
	}
`;

export interface ForgotPasswordFormFields {
	email: InputShape<string>;
}

export interface ForgotPasswordFormValidators {
	email: Array<(value: string) => string>;
}

export interface ForgotPasswordFormProps {
	fields?: ForgotPasswordFormFields;
}

interface ForgotPasswordFormState {
	fields: ForgotPasswordFormFields;
}

type Props = ForgotPasswordFormProps &
	ForgotPasswordFormStateProps &
	InjectedFormProps<ForgotPasswordFormFields> &
	WrappedComponentProps;

class ForgotPasswordForm extends React.Component<Props, ForgotPasswordFormState> {
	public render() {
		const { intl, error, isLoading, fields, onSubmit, isValid } = this.props;
		const { email } = fields;
		return (
			<StyledForgotPasswordForm onSubmit={onSubmit} noValidate>
				<BodyText className="login-instruction">
					<FormattedMessage id="user_forgot_password_instruction" />
				</BodyText>
				<InputWithIcon
					icon={<SendToMailIcon />}
					type="email"
					name="email"
					value={email.value}
					validationError={email.touched ? email.error : ''}
					placeholder={intl.formatMessage({ id: 'forms_email_address' })}
					required={true}
					pattern={ValidationPatterns.EMAIL}
					autoComplete="username"
					onChange={this.onChange}
					onBlur={this.onBlur}
				/>
				<Button type="submit" disabled={!isValid || isLoading} size="medium">
					<FormattedMessage id="forms_send" />
				</Button>
				{error && (
					<Alert
						type={AlertType.Error}
						highlightedText={intl.formatMessage({ id: 'component_login_forgot_password_failed' })}
						className="form-error">
						{intl.formatMessage({ id: error })}
					</Alert>
				)}
			</StyledForgotPasswordForm>
		);
	}

	private onChange = (event: React.FormEvent<HTMLInputElement>) => {
		const { value, name } = event.currentTarget;
		this.props.onInputChange(value, name);
	};

	private onBlur = (event: React.FormEvent<HTMLInputElement>) => {
		const { value, name } = event.currentTarget;
		this.props.onInputBlur(value, name);
	};
}

const forgotPasswordFields: ForgotPasswordFormFields = {
	email: { value: '' },
};

const forgotPasswordValidators: ForgotPasswordFormValidators = {
	email: [validateRequired('component_login_validation_email_required'), validateEmail],
};

interface ForgotPasswordFormStateProps {
	isLoading?: boolean;
	error?: string;
}

const mapStateToProps: MapStateToProps<ForgotPasswordFormStateProps, {}, State> = ({
	login,
}): ForgotPasswordFormStateProps => {
	const { forgotPasswordForm } = login;
	return {
		isLoading: forgotPasswordForm && forgotPasswordForm.isLoading,
		error: forgotPasswordForm && forgotPasswordForm.error,
	};
};

export default connect(mapStateToProps)(
	injectForm(forgotPasswordFields, forgotPasswordValidators)(injectIntl(ForgotPasswordForm))
);
