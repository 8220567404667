import * as React from 'react';
import { IconWrapperProps } from '../../interfaces';

const IconWrapper: React.FC<IconWrapperProps & React.SVGAttributes<SVGElement>> = ({
	size,
	viewBox,
	alt,
	children,
	className,
	...rest
}) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			className={className}
			width={size}
			height={size}
			viewBox={viewBox}
			{...rest}>
			{alt && <title>{alt}</title>}
			{children}
		</svg>
	);
};

export default IconWrapper;
