import { ThemedCssFunction, css, DefaultTheme } from 'styled-components';

export interface MediaBreakpoints {
	desktop: number;
	desktop900: number;
	desktop1100: number;
	desktop1200: number;
	desktop1440: number;
	desktop1600: number;
	phone: number;
	phone320: number;
	phone560: number;
	phone660: number;
	tablet: number;
	height600: number;
	height680: number;
}

export const mediaBreakpoints: MediaBreakpoints = {
	desktop: 1000,
	desktop900: 900,
	desktop1100: 1100,
	desktop1200: 1200,
	desktop1440: 1440,
	desktop1600: 1600,
	phone: 375,
	phone320: 320,
	phone560: 560,
	phone660: 660,
	tablet: 768,
	height600: 600,
	height680: 680,
};

export interface ThemedMedia {
	desktop: ThemedCssFunction<DefaultTheme>;
	desktop900: ThemedCssFunction<DefaultTheme>;
	desktop1100: ThemedCssFunction<DefaultTheme>;
	desktop1200: ThemedCssFunction<DefaultTheme>;
	desktop1440: ThemedCssFunction<DefaultTheme>;
	desktop1600: ThemedCssFunction<DefaultTheme>;
	phone: ThemedCssFunction<DefaultTheme>;
	phone320: ThemedCssFunction<DefaultTheme>;
	phone560: ThemedCssFunction<DefaultTheme>;
	phone660: ThemedCssFunction<DefaultTheme>;
	tablet: ThemedCssFunction<DefaultTheme>;
}

// iterate through the sizes and create a media template
export const media = Object.keys(mediaBreakpoints).reduce((accumulator: ThemedMedia, label: string) => {
	// use em in breakpoints to work properly cross-browser and support users
	// changing their browsers font-size: https://zellwk.com/blog/media-query-units/
	const emSize = mediaBreakpoints[label] / 16;
	accumulator[label] = function(...args: any[]) {
		return css`
			@media (min-width: ${emSize}em) {
				${css.call(this, ...args)};
			}
		`;
	};
	return accumulator;
}, {} as ThemedMedia);
