import { put, call, takeLatest, fork } from 'redux-saga/effects';
import navigationApi, { NavigationApi } from './services';
import { getType } from 'typesafe-actions';
import { navigationActions } from './reducers';
import { routingActions } from '../Routing/reducers';

export function* fetchNavigation(
	api: NavigationApi,
	{ payload }: ReturnType<typeof navigationActions.fetchNavigation>
) {
	try {
		const {
			routing: { pathname },
			siteUrlId,
		} = payload;
		const navigationResult = yield call(api.fetchNavigation, siteUrlId, pathname);
		yield put(navigationActions.setNavigation(navigationResult));
	} catch (e) {
		yield put(navigationActions.fetchFailed(e));
	}
}

export function* watchFetchNavigationSaga() {
	yield takeLatest(getType(navigationActions.fetchNavigation), fetchNavigation, navigationApi);
}

export function* refreshNavigation(
	api: NavigationApi,
	{ payload }: ReturnType<typeof routingActions.updateUrl> | ReturnType<typeof routingActions.historyStateChanged>
) {
	try {
		const { siteUrlId, pathname } = payload;
		const navigationResult = yield call(api.fetchNavigation, siteUrlId, pathname);
		yield put(navigationActions.setNavigation(navigationResult));
	} catch (e) {
		yield put(navigationActions.fetchFailed(e));
	}
}

export function* watchUpdateRoutingSaga() {
	yield takeLatest(
		[getType(routingActions.updateUrl), getType(routingActions.historyStateChanged)],
		refreshNavigation,
		navigationApi
	);
}

export function* navigationSaga() {
	yield fork(watchFetchNavigationSaga);
	yield fork(watchUpdateRoutingSaga);
}
