import * as React from 'react';
import styled, { ThemeContext } from 'styled-components';
import IconButton from 'styleguide/components/Buttons/IconButton/IconButton';
import PlusSignIcon from 'styleguide/components/Icons/NormalIcons/PlusSignIcon/PlusSignIcon';
import MinusSignIcon from 'styleguide/components/Icons/NormalIcons/MinusSignIcon/MinusSignIcon';

const PortionText = styled.div`
	${props => props.theme.typography.heading};
	font-size: 15px;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	padding: 0px 19px 0px 19px;
	color: ${props => props.theme.colors.brandPrimary};
	white-space: nowrap;
	text-align: center;
	flex: 2 0 auto;
`;

const PortionCalculatorWrapper = styled.div`
	display: flex;
	position: relative;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	float: left;

	:after {
		content: '';
		background: ${props => props.theme.colors.border};
		position: absolute;
		bottom: 0;
		height: 1px;
		width: calc(100% - 80px);
		left: calc(40px);
	}

	.adjust-portion {
		/* IE */
		padding: 1px 6px;
		flex: 0 0 38px;
		flex: 0 0 auto;
		width: 38px;
	}

	@media print {
		display: none;
	}
`;

export interface PortionCalculatorProps {
	title: string;
	portionQuantity: number;
	onPortionChange: (increment: number) => void;
}

const PortionCalculator: React.FC<PortionCalculatorProps & React.HTMLAttributes<HTMLDivElement>> = ({
	title,
	portionQuantity,
	onPortionChange,
	...htmlProps
}) => {
	const theme = React.useContext(ThemeContext);

	const increaseOrDecreasePortion = (increment: number) => {
		const newCurrentQuantity = portionQuantity + increment;
		if (newCurrentQuantity > 0) {
			onPortionChange(newCurrentQuantity);
		}
	};

	const increasePortion = () => {
		increaseOrDecreasePortion(1);
	};

	const decreasePortion = () => {
		increaseOrDecreasePortion(-1);
	};

	const minusIcon = <MinusSignIcon color={theme.colors.white} />;
	const plusIcon = <PlusSignIcon color={theme.colors.white} />;

	return (
		<PortionCalculatorWrapper {...htmlProps}>
			<IconButton
				className="adjust-portion"
				icon={minusIcon}
				color={theme.colors.buttonPrimary}
				hoverColor={theme.colors.buttonPrimaryHover}
				onClick={decreasePortion}
				disabled={portionQuantity === 1 && true}
				aria-label="-"
			/>
			<PortionText>{`${portionQuantity} ${title}`}</PortionText>
			<IconButton
				className="adjust-portion"
				icon={plusIcon}
				onClick={increasePortion}
				aria-label="+"
				color={theme.colors.buttonPrimary}
				hoverColor={theme.colors.buttonPrimaryHover}
			/>
		</PortionCalculatorWrapper>
	);
};

export default PortionCalculator;
