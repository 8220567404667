import { RecipeFrontState } from 'pagetypes/RecipeFront/interfaces';
import { ProductFrontState } from 'pagetypes/ProductFront/reducers/reducers';
import { KitchenMenuState } from 'pagetypes/KitchenMenu/reducers';
import { RecipeFrontApiShape } from 'pagetypes/RecipeFront/interfaces';
import { ProductApiShape, ProductApiContent, ProductState } from 'pagetypes/Product/interfaces';
import { ProductFrontApiShape, ProductFrontApiContent } from 'pagetypes/ProductFront/interfaces';
import { KitchenMenuApiShape, KitchenMenuApiContent } from 'pagetypes/KitchenMenu/interfaces';
import { RecipeApiShape, RecipeApiRecipe, ProfessionalRecipeApiShape } from 'pagetypes/Recipe/interfaces';
import { RecipeState, ProfessionalRecipeState } from 'pagetypes/Recipe/reducers/reducers';
import {
	SearchApiRecipe,
	RecipeSearchApiShape,
	SearchState,
	SearchApiProduct,
	ProductSearchApiShape,
	ArticleSearchApiShape,
	SearchApiArticle,
} from 'pagetypes/Search/interfaces';
import { RecipeAspectGroupState } from 'pagetypes/RecipeAspectGroup/reducers';
import { RecipeAspectGroupApiShape, RecipeAspectGroupApiContent } from 'pagetypes/RecipeAspectGroup/interfaces';
import { CmsContentState, CmsContentApiShape } from 'pagetypes/CmsContent/interfaces';
import { BrandContentState, BrandApiShape, BrandApiContentState } from 'pagetypes/Brand/interfaces';
import {
	ProductGroupContentState,
	ProductGroupApiShape,
	ProductGroupApiContentState,
} from 'pagetypes/ProductGroup/interfaces';
import { NewsArticleState, NewsArticleApiShape } from 'pagetypes/NewsArticle/interfaces';
import { HomeApiShape } from 'pagetypes/Home/interfaces';
import { VideoContentApiShape } from 'pagetypes/VideoContent/interfaces';
import { MyContentState, MyContentApiShape } from 'pagetypes/MyContent/interfaces';
import { MaterialSearchState, MaterialSearchApiShape } from 'pagetypes/MaterialSearch/interfaces';
import { EventContentApiShape } from '../../../pagetypes/Event/interfaces';

export enum ResourceType {
	none = 'None',
	home = 'Home',
	video = 'Video',
	brand = 'Brand',
	cmsContent = 'CmsContent',
	event = 'Event',
	myContent = 'MyContent',
	newsArticle = 'NewsArticle',
	lesson = 'Lesson',
	recipe = 'Recipe',
	professionalRecipe = 'ProfessionalRecipe',
	recipeAspectGroup = 'RecipeAspectGroup',
	recipeFront = 'RecipeFront',
	recipeSearch = 'RecipeSearch',
	product = 'Product',
	productFront = 'ProductFront',
	productGroup = 'ProductGroup',
	productSearch = 'ProductSearch',
	articleSearch = 'ArticleSearch',
	kitchenMenu = 'KitchenMenu',
	shoppingList = 'ShoppingList',
	materialSearch = 'MaterialSearch',
}

export type ResourceContent =
	| BrandContentState
	| CmsContentState
	| MyContentState
	| NewsArticleState
	| RecipeAspectGroupState
	| RecipeState
	| ProfessionalRecipeState
	| RecipeFrontState
	| ProductState
	| ProductFrontState
	| ProductGroupContentState
	| KitchenMenuState
	| SearchState
	| MaterialSearchState
	| null
	| undefined;

export type Resource =
	| HomeApiShape
	| VideoContentApiShape
	| BrandApiShape
	| CmsContentApiShape
	| EventContentApiShape
	| MyContentApiShape
	| NewsArticleApiShape
	| RecipeApiShape
	| ProfessionalRecipeApiShape
	| RecipeAspectGroupApiShape
	| RecipeFrontApiShape
	| ProductApiShape
	| ProductFrontApiShape
	| ProductGroupApiShape
	| KitchenMenuApiShape
	| RecipeSearchApiShape
	| ProductSearchApiShape
	| ArticleSearchApiShape
	| MaterialSearchApiShape;

export type ResourceApiContent =
	| BrandApiContentState
	| CmsContentState
	| MyContentState
	| NewsArticleState
	| RecipeApiRecipe
	| RecipeAspectGroupApiContent
	| ProductApiContent
	| ProductFrontApiContent
	| ProductGroupApiContentState
	| KitchenMenuApiContent
	| SearchApiRecipe
	| SearchApiProduct
	| SearchApiArticle
	| MaterialSearchState
	| null;

export type ResourceSendToEmailTypes = 'SENDING' | 'SENT' | 'ERROR';
