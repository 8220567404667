import * as React from 'react';
import NormalIconWrapper, { NormalIconWrapperProps } from '../NormalIconWrapper';
import { useIntl } from 'react-intl';
import { ThemeContext } from 'styled-components';

const SearchIcon: React.FC<NormalIconWrapperProps & React.SVGAttributes<SVGElement>> = ({ color, ...props }) => {
	const theme = React.useContext(ThemeContext);
	const intl = useIntl();
	return (
		<NormalIconWrapper alt={intl.formatMessage({ id: 'icon_search' })} {...props}>
			<path
				fill={color ?? theme.colors.brandPrimary}
				d="M12 20a7.948 7.948 0 0 1-5.657-2.343A7.948 7.948 0 0 1 4 12c0-2.137.832-4.146 2.343-5.657A7.948 7.948 0 0 1 12 4c2.137 0 4.146.832 5.657 2.343A7.948 7.948 0 0 1 20 12a7.948 7.948 0 0 1-2.343 5.657A7.948 7.948 0 0 1 12 20m11.707 2.293l-3.964-3.964A9.923 9.923 0 0 0 22 12a9.934 9.934 0 0 0-2.929-7.071A9.934 9.934 0 0 0 12 2a9.934 9.934 0 0 0-7.071 2.929A9.934 9.934 0 0 0 2 12a9.934 9.934 0 0 0 2.929 7.071A9.934 9.934 0 0 0 12 22a9.923 9.923 0 0 0 6.329-2.257l3.964 3.964a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414"
			/>
		</NormalIconWrapper>
	);
};

export default SearchIcon;
