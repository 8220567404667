import * as React from 'react';
import SmallHeader from 'styleguide/components/Typography/SmallHeader';
import PillFilters from 'pagetypes/Search/components/filters/PillFilters';
import { SearchFilterSection } from 'pagetypes/Search/interfaces';
import { NumberMap, NameAndValue } from 'common/interfaces/common';
import styled from 'styled-components';
import { media } from 'styleguide/helpers';
import { RegisterModalStatus, MaterialSearchState } from '../interfaces';
import { Button } from 'styleguide/components/Buttons/Button';
import { FormattedMessage } from 'react-intl';
import { connect, MapStateToProps } from 'react-redux';
import { materialSearchActions } from '../reducers';
import { State } from 'common/reducers';

const FiltersContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;

	${media.tablet`
		flex-direction: row;
		flex-wrap: wrap;
		padding: ${({ theme }) => theme.grid.gutterInPx(3)};
	`};

	${media.desktop1200`
		flex-wrap: nowrap;
		padding: ${({ theme }) => theme.grid.gutterInPx(3)} 0;
	`};
`;

const FilterSection = styled.section`
	flex: 1 0 50%;
	flex-wrap: wrap;
	text-align: center;

	&:nth-child(n + 2) {
		margin-top: ${({ theme }) => theme.grid.gutterInPx(3)};
	}

	${media.tablet`
		&:nth-child(n + 2) {
			margin-top: 0;
		}
		&:nth-child(n + 3) {
			margin-top: ${({ theme }) => theme.grid.gutterInPx(3)};
		}
	`};

	${media.desktop1200`
		flex: 1 0 25%;
		flex-wrap: nowrap;

		&:nth-child(n + 3) {
			margin-top: 0;
		}
	`};
`;

const PillFilterWrapper = styled.div`
	${media.desktop`
		margin: 0 0 -3px;
	`};

	& > button {
		margin: 3px;
	}

	.login-button {
		display: inline-flex;
	}
`;

const SecureMaterialInfo = styled.p`
	font-size: 14px;
	line-height: 1.2;
	margin: ${({ theme }) => theme.grid.gutterInPx()} auto;
	color: ${({ theme }) => theme.colors.shoppingListGrey};
	padding: 0 10%;

	${media.tablet`
		padding: 0 ${({ theme }) => theme.grid.gutterInPx()};
	`};
`;

interface MaterialSearchFiltersProps {
	filters: SearchFilterSection[];
	filterCount: NumberMap;
	selectedFilters: NameAndValue[];
	onFilterClick: (filterName: string, filterValue: string, selected: boolean) => void;
	isResultsLoading?: boolean;
}

type Props = MaterialSearchFiltersProps & MaterialSearchFiltersStateProps & MaterialSearchFiltersDispatchProps;

const MaterialSearchFilters: React.FC<Props> = ({
	filters,
	filterCount,
	selectedFilters,
	isResultsLoading,
	onFilterClick,
	setRegisterModalStatus,
}) => {
	const onLoginClick = () => {
		setRegisterModalStatus('SHOW_FORM');
	};

	return (
		<FiltersContainer>
			{filters.map(({ title, type, items }, index) => (
				<FilterSection key={`filter-section-${index}`}>
					{filters.length > 1 && (
						<header>
							<SmallHeader>{title}</SmallHeader>
						</header>
					)}
					<PillFilterWrapper>
						{(type !== 'secureMaterial' || (type === 'secureMaterial' && items?.length !== 0)) && (
							<PillFilters
								filters={items}
								selectedFilters={selectedFilters}
								isResultsLoading={isResultsLoading}
								onFilterClick={onFilterClick}
								disableCount
							/>
						)}
						{type === 'secureMaterial' && items?.length === 0 && (
							<>
								<SecureMaterialInfo>
									<FormattedMessage id="materials_search_secure_info" />
								</SecureMaterialInfo>
								<Button size="small" className="login-button" onClick={onLoginClick}>
									<FormattedMessage id="materials_search_login" />
								</Button>
							</>
						)}
					</PillFilterWrapper>
				</FilterSection>
			))}
		</FiltersContainer>
	);
};

interface MaterialSearchFiltersStateProps {
	modalStatus: RegisterModalStatus;
	isLoading: boolean;
	error?: string | null;
}

interface MaterialSearchFiltersDispatchProps {
	setRegisterModalStatus: typeof materialSearchActions.setRegisterModalStatus;
}

const mapStateToProps: MapStateToProps<MaterialSearchFiltersStateProps, MaterialSearchFiltersProps, State> = ({
	resource,
}): MaterialSearchFiltersStateProps => {
	const {
		register: { modalStatus, isLoading, error },
	} = resource.content as MaterialSearchState;

	return {
		modalStatus,
		isLoading,
		error,
	};
};

export default connect(mapStateToProps, {
	setRegisterModalStatus: materialSearchActions.setRegisterModalStatus,
})(MaterialSearchFilters);
