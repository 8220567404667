import { media } from '../../../helpers';
import { ResponsiveValues, getDisplayValue } from 'common/components/Media';
import styled, { css } from 'styled-components';

export interface HighlightCardWrapperProps {
	isSmall?: boolean;
	hasBorder?: boolean;
	print?: 'minimal';
	isVisible?: ResponsiveValues<boolean>;
}

const HighlightCardWrapper = styled('a')<HighlightCardWrapperProps>`
	background-color: ${props => props.theme.colors.white};
	display: flex;
	width: 100%;
	min-height: 92px;
	margin: 0;
	border-radius: 4px;
	box-sizing: border-box;
	text-decoration: none;
	border: ${props => (props.hasBorder ? `solid 1px ${props.theme.colors.border}` : 'none')};

	${media.tablet<HighlightCardWrapperProps>`
		${props => (props.isSmall ? '' : 'min-height: 120px;')}
	`};

	${({ isVisible }) => {
		const visibilityMap = (isVisible as ResponsiveValues<boolean>) ?? {};

		if (Object.keys(visibilityMap).length > 0) {
			return css`
				${Object.keys(visibilityMap).map(key => {
					if (key === 'default') {
						return css`
							display: ${getDisplayValue(visibilityMap.default, 'flex')};
						`;
					}
					return css`
						${media[key]`
							display: ${getDisplayValue(visibilityMap[key], 'flex')};
						`};
					`;
				})};
			`;
		}

		return null;
	}};

	& + & {
		margin-top: 10px;
	}
`;

export default HighlightCardWrapper;
