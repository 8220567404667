import { RoutingState } from './reducers';
import { RoutingResult } from './interfaces';
import Resource from '../Resource/Resource';
import { SiteUrlInfo } from '../App/services';

const router = (siteUrlInfo: SiteUrlInfo, routing: RoutingState): RoutingResult => {
	return {
		component: Resource,
		props: {},
	};
};

export default router;
