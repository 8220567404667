import { postJson, deleteJson, putJson, fetchJson } from 'utils/service';

interface ShoppingListProductApiResponse {
	id: string;
	title: string;
	url: string;
}

async function addProductToShoppingList(productId: string): Promise<ShoppingListProductApiResponse> {
	return await postJson('/api/shoppinglist/product', {
		id: productId,
	});
}

async function removeProductFromShoppingList(productId: string): Promise<any> {
	return await deleteJson('/api/shoppinglist/product', {
		id: productId,
	});
}

async function deleteItemFromShoppingList(itemId: string): Promise<any> {
	return await deleteJson('/api/shoppingList/item', {
		id: itemId,
	});
}

interface ShoppingListRecipeApiResponse {
	id: string;
	title: string;
	portions: number;
	portionUnit: string;
	url: string;
}

async function addRecipeToShoppingList(recipeId: string, portions: number): Promise<ShoppingListRecipeApiResponse> {
	return await postJson('/api/shoppinglist/recipe', {
		recipeId,
		portions,
	});
}

async function removeRecipeFromShoppingList(recipeId: string): Promise<any> {
	return await deleteJson('/api/shoppinglist/recipe', {
		id: recipeId,
	});
}

async function addItemToShoppingList(title: string): Promise<ShoppingListApiItem> {
	return await postJson('/api/shoppingList/item', {
		title,
	});
}

async function toggleItemPickedFromShoppingList(itemId: string, isPicked: boolean): Promise<ShoppingListApiItem> {
	return await putJson('/api/shoppingList/item', {
		id: itemId,
		ignoreState: isPicked,
	});
}

async function emptyShoppingList(): Promise<any> {
	return await deleteJson('/api/shoppingList', {});
}

async function shareShoppingListToEmail(email: string): Promise<any> {
	return await postJson(`/api/email/SendShoppingListEmail?sendSelf=false&email=${email}`, {});
}

export interface ShoppingListApiRecipeItem {
	id: string;
	title: string;
	imageUrl: string;
	portions: number;
	portionUnit: string;
	url: string;
	totalTime: number;
}

export interface ShoppingListApiStoreSection {
	name?: string;
	orderNumber?: number;
	id: string;
}

export interface ShoppingListApiItem {
	id: string;
	category: 'product' | 'recipe' | 'userGenerated';
	ignored: boolean;
	title: string;
	recipe: ShoppingListApiRecipeItem | null;
	quantity: number | null;
	quantityUnit: string | null;
	quantityUnitExtra: string | null;
	quantityString: string;
	orderNumber: number;
	storeSection: ShoppingListApiStoreSection | null;
	segmentName: string | null;
}

export interface ShoppingListApiResponse {
	id?: string | null;
	recipes: ShoppingListApiRecipeItem[];
	items: ShoppingListApiItem[];
}

async function fetchShoppingList(): Promise<ShoppingListApiResponse> {
	return await fetchJson<ShoppingListApiResponse>('/api/shoppingList/');
}

export async function fetchShoppingListById(id: string): Promise<ShoppingListApiResponse> {
	return await fetchJson<ShoppingListApiResponse>(`/api/shoppingList/${id}`);
}

async function changeShoppingListRecipePortion(recipeId: string, portions: number): Promise<ShoppingListApiItem[]> {
	return await putJson('/api/shoppingList/recipe/', { recipeId, portions });
}

export const shoppingListApi = {
	addProductToShoppingList,
	removeProductFromShoppingList,
	deleteItemFromShoppingList,
	addRecipeToShoppingList,
	removeRecipeFromShoppingList,
	fetchShoppingList,
	changeShoppingListRecipePortion,
	addItemToShoppingList,
	toggleItemPickedFromShoppingList,
	emptyShoppingList,
	shareShoppingListToEmail,
};

export type ShoppingListApi = typeof shoppingListApi;
