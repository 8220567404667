import { fork, put, call, takeLatest } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { ResourceApi, resourceApi, ResourceRedirectApiShape } from './services';
import { commentApi } from 'pagetypes/Recipe/services/comment';
import { resourceActions } from './reducers/actions';
import { Resource } from './types';
import { ResourceSendToEmailApiResponse } from './interfaces';

export function* fetchResource(api: ResourceApi, { payload }: ReturnType<typeof resourceActions.fetchResource>) {
	try {
		const { siteUrlId, resourceUrl, confirmComment } = payload;

		const result: Resource = yield call(api.fetchResource, siteUrlId, resourceUrl);

		if (confirmComment) {
			// Post the confirmation first, so that when setRecipe triggers
			// fetching of comments, the confirmed comment will be there.
			try {
				yield call(commentApi.confirmComment, result.id, confirmComment);
			} catch (e) {
				// Let's not crash even if we can't confirm the comment.
			}
		}
		yield put(resourceActions.setResource(siteUrlId, resourceUrl, result));
	} catch (e) {
		yield put(resourceActions.fetchResourceFailed({ error: e }));
	}
}

export function* watchFetchResourceSaga() {
	yield takeLatest(getType(resourceActions.fetchResource), fetchResource, resourceApi);
}

export function* setPublicationStatus(
	api: ResourceApi,
	{ payload }: ReturnType<typeof resourceActions.setResourcePublicationStatus>
) {
	const { resourceId, published } = payload;
	try {
		const result: ResourceRedirectApiShape = yield call(api.setResourcePublicationStatus, resourceId, published);

		yield put(resourceActions.setResourcePublicationStatusSuccess(result.redirectUrl));
	} catch {
		yield put(resourceActions.setResourcePublicationStatusFailed());
	}
}

export function* setPublishStatusSaga() {
	yield takeLatest(getType(resourceActions.setResourcePublicationStatus), setPublicationStatus, resourceApi);
}

export function* sendResourceToEmailSaga(
	api: ResourceApi,
	{ payload }: ReturnType<typeof resourceActions.sendResourceToEmailRequest>
) {
	const { resourceId, senderName, senderEmail, recipientEmail } = payload;
	try {
		const result: ResourceSendToEmailApiResponse = yield call(
			api.sendResourceToEmail,
			resourceId,
			senderName,
			senderEmail,
			recipientEmail
		);
		yield put(resourceActions.sendResourceToEmailSuccess(result));
	} catch {
		yield put(resourceActions.sendResourceToEmailError());
	}
}

export function* watchSendResourceToEmailSaga() {
	yield takeLatest(getType(resourceActions.sendResourceToEmailRequest), sendResourceToEmailSaga, resourceApi);
}

export default function* resourceSaga() {
	yield fork(watchFetchResourceSaga);
	yield fork(setPublishStatusSaga);
	yield fork(watchSendResourceToEmailSaga);
}
