import * as React from 'react';
import { Helmet } from 'react-helmet';
import { connect, MapStateToProps } from 'react-redux';
import { AlertType } from 'styleguide/components/Typography/Alert/Alert';
import Alert from 'styleguide/components/Typography/Alert/Alert';
import styled from 'styled-components';
import Logo from 'styleguide/components/Logos/Logo/Logo';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { ContentWrapper } from 'common/components/General';
import { UserShoppingListShape, userShoppingListInitialState } from './reducers/shopping-list';
import ShoppingListAddItemForm, { AddItemFields } from './components/ShoppingListAddItemForm';
import ShoppingListActions from './components/ShoppingListActions';
import ShoppingListRecipe from './components/ShoppingListRecipe';
import ShoppingListStoreSection from './components/ShoppingListStoreSection';
import { EmailShareFormFields } from './components/ShoppingListEmailShareForm';
import { State } from 'common/reducers';
import { userActions } from './reducers/actions';
import { recipeShoppingListActions } from '../Recipe/reducers/shopping-list';

export const ShoppingListContentWrapper = styled(ContentWrapper)`
	max-width: 700px;
	margin: 0 auto;
`;

const Notification = styled(Alert)`
	margin: 10px auto;

	@media print {
		display: none;
	}
`;

const PrintHeader = styled.h1`
	display: none;

	@media print {
		display: block;
		${props => props.theme.typography.heading};
		font-size: 18pt;
		color: ${props => props.theme.colors.brandPrimary};
		text-align: center;

		img {
			display: block;
			margin: 0 auto 5mm;
			width: 20mm;
		}
	}
`;

type Props = {} & UserShoppingListShape & UserShoppingListDispatchProps & WrappedComponentProps;

interface UserShoppingListState {
	isLinkSharingOpen?: boolean;
}

class UserShoppingList extends React.Component<Props, UserShoppingListState> {
	public state: UserShoppingListState = {
		isLinkSharingOpen: false,
	};

	public componentDidMount() {
		this.props.fetchShoppingList();
	}

	public render() {
		const { isLoading, emailSharing, shoppingListId, recipes, intl } = this.props;
		const { isLinkSharingOpen } = this.state;

		const recipeIndexesById = {};
		recipes.forEach((recipe, index) => {
			recipeIndexesById[recipe.id] = index % 8;
		});

		return (
			<ShoppingListContentWrapper>
				<Helmet>
					<title>{intl.formatMessage({ id: 'user_profile_own_shopping_list' })} - Valio</title>
				</Helmet>
				<PrintHeader>
					<Logo href="" />
					<FormattedMessage id="user_shopping_list" />
				</PrintHeader>
				{isLoading && (
					<div>
						<FormattedMessage id="global_loading" />
						...
					</div>
				)}
				{!isLoading && (
					<>
						{this.renderShoppingListRecipes(recipeIndexesById)}
						{this.renderShoppingListStoreSections(recipeIndexesById)}
						<ShoppingListAddItemForm onFormSubmit={this.onAddItem} />
						<Notification type={AlertType.Info} highlightedText={intl.formatMessage({ id: 'user_shopping_list_hey' })}>
							<FormattedMessage id="user_shopping_list_did_you_notice_tip" />
						</Notification>
						<ShoppingListActions
							isLinkSharingOpen={isLinkSharingOpen}
							shoppingListId={shoppingListId}
							emailSharing={emailSharing}
							onEmptyShoppingList={this.onEmptyShoppingList}
							onEmailShareFormOpen={this.onEmailShareFormOpen}
							onEmailShareFormClose={this.onEmailShareFormClose}
							onEmailShareFormSubmit={this.onEmailShareFormSubmit}
							onLinkShareFormOpen={this.onLinkShareFormOpen}
							onLinkShareFormClose={this.onLinkShareFormClose}
						/>
					</>
				)}
			</ShoppingListContentWrapper>
		);
	}

	private renderShoppingListRecipes(recipeIndexesById: object) {
		const { recipes } = this.props;

		if (recipes.length === 0) {
			return null;
		}

		return recipes.map(recipe => (
			<ShoppingListRecipe
				key={recipe.id}
				{...recipe}
				recipeIndexesById={recipeIndexesById}
				onRecipeRemove={this.onRecipeRemove}
				onRecipePortionChange={this.onRecipePortionChange}
			/>
		));
	}

	private renderShoppingListStoreSections(recipeIndexesById: object) {
		const { storeSections, toggleShoppingListItemPicked } = this.props;

		if (storeSections.length === 0) {
			return null;
		}

		return storeSections.map(storeSection => (
			<ShoppingListStoreSection
				key={storeSection.id}
				{...storeSection}
				recipeIndexesById={recipeIndexesById}
				onItemRemove={this.onItemRemove}
				onToggleItemPicked={toggleShoppingListItemPicked}
			/>
		));
	}

	private onRecipeRemove = (recipeId: string) => {
		this.props.removeRecipeFromShoppingList(recipeId, true);
	};

	private onRecipePortionChange = (recipeId: string, quantity: number) => {
		this.props.changeShoppingListRecipePortion(recipeId, quantity);
	};

	private onItemRemove = (itemId: string) => {
		this.props.deleteShoppingListItem(itemId);
	};

	private onAddItem = (fields: AddItemFields) => {
		this.props.addShoppingListItem(fields.title.value);
	};

	private onEmptyShoppingList = () => {
		this.props.emptyShoppingList();
	};

	private onEmailShareFormOpen = () => {
		this.props.openEmailSharing();
	};

	private onEmailShareFormClose = () => {
		this.props.closeEmailSharing();
	};

	private onEmailShareFormSubmit = (fields: EmailShareFormFields) => {
		const { email } = fields;
		this.props.shareShoppingListToEmail(email.value);
	};

	private onLinkShareFormOpen = () => {
		this.setState({ isLinkSharingOpen: true });
	};

	private onLinkShareFormClose = () => {
		this.setState({ isLinkSharingOpen: false });
	};
}

const mapStateToProps: MapStateToProps<UserShoppingListShape, {}, State> = ({
	shoppingList,
}): UserShoppingListShape => {
	if (!shoppingList) {
		return userShoppingListInitialState;
	}

	return shoppingList;
};

interface UserShoppingListDispatchProps {
	fetchShoppingList: typeof userActions.fetchShoppingList;
	deleteShoppingListItem: typeof userActions.deleteShoppingListItem;
	removeRecipeFromShoppingList: typeof recipeShoppingListActions.removeFromShoppingList;
	changeShoppingListRecipePortion: typeof userActions.changeShoppingListRecipePortion;
	addShoppingListItem: typeof userActions.addShoppingListItem;
	toggleShoppingListItemPicked: typeof userActions.toggleShoppingListItemPicked;
	emptyShoppingList: typeof userActions.emptyShoppingList;
	openEmailSharing: typeof userActions.openEmailSharing;
	closeEmailSharing: typeof userActions.closeEmailSharing;
	shareShoppingListToEmail: typeof userActions.shareShoppingListToEmail;
}

export default connect(mapStateToProps, {
	fetchShoppingList: userActions.fetchShoppingList,
	deleteShoppingListItem: userActions.deleteShoppingListItem,
	removeRecipeFromShoppingList: recipeShoppingListActions.removeFromShoppingList,
	changeShoppingListRecipePortion: userActions.changeShoppingListRecipePortion,
	addShoppingListItem: userActions.addShoppingListItem,
	toggleShoppingListItemPicked: userActions.toggleShoppingListItemPicked,
	emptyShoppingList: userActions.emptyShoppingList,
	openEmailSharing: userActions.openEmailSharing,
	closeEmailSharing: userActions.closeEmailSharing,
	shareShoppingListToEmail: userActions.shareShoppingListToEmail,
})(injectIntl(UserShoppingList));
