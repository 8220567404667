import * as React from 'react';
import styled from 'styled-components';
import { media } from 'styleguide/helpers/media';
import { SearchType } from 'pagetypes/Search/types';
import { useIntl } from 'react-intl';
import includes from 'lodash/includes';

interface StyledSearchResultsCountProps {
	searchType: SearchType;
}

const StyledSearchResultsCount = styled.span<StyledSearchResultsCountProps>`
	font-family: ${props => props.theme.fonts.secondary};
	font-size: 18px;
	color: ${props => props.theme.colors.bodyText};
	line-height: 28px;
	order: ${props => (props.searchType === SearchType.MATERIAL ? 4 : 6)};
	margin-right: 10px;
	margin-top: 11px;

	${media.desktop<StyledSearchResultsCountProps>`
		margin: 0;
    order: ${props => (props.searchType === SearchType.MATERIAL ? 2 : 1)};
		flex: 0 0 auto;
	`};

	${media.desktop1440<{ searchType: SearchType }>`
		margin-left: ${props => (includes([SearchType.ARTICLE, SearchType.MATERIAL], props.searchType) ? 0 : '30px')};
	`};

	span {
		${props => props.theme.typography.heading};
	}
`;

export interface SearchResultsCountProps {
	count: number;
	searchType: SearchType;
}

const SearchResultsCount: React.FC<SearchResultsCountProps> = ({ count, searchType }) => {
	const intl = useIntl();
	const resultsLabel: { singular: string; plural: string } = {
		singular: intl.formatMessage({ id: 'search_results_result' }),
		plural: intl.formatMessage({ id: 'search_results_result_plural' }),
	};
	if (searchType === SearchType.RECIPE) {
		resultsLabel.singular = intl.formatMessage({ id: 'search_results_recipe' });
		resultsLabel.plural = intl.formatMessage({ id: 'search_results_recipe_plural' });
	}
	if (searchType === SearchType.PRODUCT) {
		resultsLabel.singular = intl.formatMessage({ id: 'search_results_product' });
		resultsLabel.plural = intl.formatMessage({ id: 'search_results_product_plural' });
	}
	if (searchType === SearchType.ARTICLE) {
		resultsLabel.singular = intl.formatMessage({ id: 'search_results_article' });
		resultsLabel.plural = intl.formatMessage({ id: 'search_results_article_plural' });
	}

	return (
		<StyledSearchResultsCount searchType={searchType}>
			<span>{count}</span> {count === 1 ? resultsLabel.singular : resultsLabel.plural}
		</StyledSearchResultsCount>
	);
};

export default SearchResultsCount;
