import * as React from 'react';
import NormalIconWrapper, { NormalIconWrapperProps } from '../NormalIconWrapper';

const LocationIcon: React.FC<NormalIconWrapperProps & React.SVGAttributes<SVGElement>> = ({ color, ...props }) => {
	return (
		<NormalIconWrapper {...props} fill="none">
			<path
				d="M21.6673 9.75C21.6673 15.0583 13.0006 23.8333 13.0006 23.8333C13.0006 23.8333 4.33398 15.0583 4.33398 9.75C4.33398 4.225 8.77565 1.08333 13.0006 1.08333C17.2256 1.08333 21.6673 4.225 21.6673 9.75Z"
				stroke="#003878"
				strokeWidth="2"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M15 9.49998C15 10.6046 14.1046 11.5 13 11.5C11.8954 11.5 11 10.6046 11 9.49998C11 8.39542 11.8954 7.49998 13 7.49998C14.1046 7.49998 15 8.39542 15 9.49998Z"
				stroke="#003878"
				strokeWidth="2"
				strokeMiterlimit="10"
				strokeLinecap="square"
			/>
		</NormalIconWrapper>
	);
};

export default LocationIcon;
