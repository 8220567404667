import * as React from 'react';
import NormalIconWrapper, { NormalIconWrapperProps } from '../NormalIconWrapper';
import { ThemeContext } from 'styled-components';

const MinusSignIcon: React.FC<NormalIconWrapperProps & React.SVGAttributes<SVGElement>> = ({ color, ...props }) => {
	const theme = React.useContext(ThemeContext);

	return (
		<NormalIconWrapper alt="-" {...props}>
			<rect fill={color ?? theme.colors.brandPrimary} x="5" y="12" width="16" height="2" rx="1" />
		</NormalIconWrapper>
	);
};

export default MinusSignIcon;
