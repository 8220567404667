import * as React from 'react';
import NormalIconWrapper, { NormalIconWrapperProps } from '../NormalIconWrapper';
import { ThemeContext } from 'styled-components';

const MoveIcon: React.FC<NormalIconWrapperProps & React.SVGAttributes<SVGElement>> = ({ color, ...props }) => {
	const theme = React.useContext(ThemeContext);
	const fillColor = color ?? theme.colors.brandPrimary;

	return (
		<NormalIconWrapper alt="-" {...props}>
			<path
				d="M14.205 2.41c.041.03.082.062.122.099l5.367 4.93c.924.848-.45 2.131-1.374 1.283l-4.93-4.528-4.712 4.3c-.927.846-2.296-.442-1.37-1.288l5.404-4.93c.52-.473 1.177-.278 1.493.134zM14.205 23.59c-.316.412-.974.607-1.493.134l-5.403-4.93c-.927-.846.442-2.134 1.369-1.288l4.713 4.3 4.929-4.528c.923-.848 2.298.435 1.374 1.284l-5.367 4.93c-.04.036-.08.069-.122.098z"
				fill={fillColor}
			/>
			<circle fill={fillColor} cx="13.5" cy="13.5" r="1.5" />
		</NormalIconWrapper>
	);
};

export default MoveIcon;
