import { fetchJson, throwMissingSiteUrlIdError } from 'utils/service';

export interface ProductSearchResultItem {
	title: string;
	url: string;
	slug: string;
}

export interface ProductSearchResult {
	count: number;
	items: ProductSearchResultItem[];
}

async function searchProducts(siteUrlId: string, searchText: string, maxResults: number): Promise<ProductSearchResult> {
	if (!siteUrlId) {
		throwMissingSiteUrlIdError();
	}
	return await fetchJson<ProductSearchResult>(
		`/api/sites/${siteUrlId}/siteSearch/product?searchWord=${encodeURIComponent(
			searchText
		)}&numberOfItems=${maxResults}`
	);
}

async function searchProductsInRecipes(
	siteUrlId: string,
	searchText: string,
	maxResults: number
): Promise<ProductSearchResult> {
	if (!siteUrlId) {
		throwMissingSiteUrlIdError();
	}
	return await fetchJson<ProductSearchResult>(
		`/api/sites/${siteUrlId}/products/used-in-recipes?searchWord=${encodeURIComponent(
			searchText
		)}&domain=valio.fi&numberOfItems=${maxResults}`
	);
}

const api = {
	searchProducts,
	searchProductsInRecipes,
};

export type ProductSearchApi = typeof api;
export { api as productSearchApi };
