import styled, { ThemeProps, DefaultTheme } from 'styled-components';
import { media } from '../../../helpers';

export interface StyledIconButtonProps {
	/** The icon background colour. This accepts only the value 'orange', 'white' or 'transparent' */
	buttonStyle?: 'primary' | 'secondary' | 'white' | 'transparent';
	/** This can be used to override the defaults derived from buttonStyle */
	color?: string;
	/** This can be used to override the defaults derived from buttonStyle */
	hoverColor?: string;
	/** This can be used to override the defaults derived from buttonStyle */
	iconHoverColor?: string;
	/** Boolean value to determine whether to show a drop shadow in desktop breakpoint */
	enableShadowOnDesktop?: boolean;
}

const getColor = (props: StyledIconButtonProps & ThemeProps<DefaultTheme>) => {
	if (props.color) {
		return props.color;
	}

	switch (props.buttonStyle) {
		case 'transparent':
			return 'transparent';
		case 'primary':
			return props.theme.colors.buttonPrimary;
		case 'secondary':
			return props.theme.colors.buttonSecondary;
		default:
			return props.theme.colors.white;
	}
};

const getHoverColor = (props: StyledIconButtonProps & ThemeProps<DefaultTheme>) => {
	if (props.hoverColor) {
		return props.hoverColor;
	}

	switch (props.buttonStyle) {
		case 'transparent':
			return 'none';
		case 'primary':
			return props.theme.colors.buttonPrimaryHover;
		case 'secondary':
			return props.theme.colors.buttonSecondaryHover;
		default:
			return props.theme.colors.white;
	}
};

const getIconHoverColor = (props: StyledIconButtonProps & ThemeProps<DefaultTheme>) => {
	if (props.iconHoverColor) {
		return props.iconHoverColor;
	}

	switch (props.buttonStyle) {
		case 'transparent':
			return props.theme.colors.buttonPrimary;
		case 'primary':
		case 'secondary':
			return props.theme.colors.white;
		default:
			return props.theme.colors.buttonPrimary;
	}
};

export const StyledIconButton = <T extends keyof JSX.IntrinsicElements>(tagName: T) => styled<T>(tagName)<
	StyledIconButtonProps
>`
	outline: none;
	border: 1px solid ${props => getColor(props)};
	background-color: ${props => getColor(props)};
	border-radius: 4px;
	width: 38px;
	height: 38px;
	cursor: pointer;
	padding: 5px;
	${props => (props.enableShadowOnDesktop ? media.desktop`box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1)` : '')};

	&:hover,
	&:focus,
	&:active {
		outline: none;
		background-color: ${props => getHoverColor(props)};
		border-color: ${props => getHoverColor(props)};

		path,
		g {
			fill: ${props => getIconHoverColor(props)};
		}
	}

	& > svg {
		margin: 0 auto;
		flex: 0 0 auto;
	}
`;
