import { Reducer } from 'redux';
import { UserActions, userActions } from './actions';
import { getType } from 'typesafe-actions';
import { FormValidationDisplayError } from 'common/components/Forms/interfaces';
import { ProfileApiShape } from '../services/profile';
import { parseModelStateErrors } from 'utils/object';

export interface ProfileNotifications {
	contactInfoSuccess?: string;
	changePasswordSuccess?: string;
	contactInfoError?: FormValidationDisplayError;
	changePasswordError?: FormValidationDisplayError;
}

export interface ProfileShape {
	isLoading: boolean;
	profile?: ProfileApiShape;
	notifications?: ProfileNotifications;
}

const initialState: ProfileShape = {
	isLoading: false,
};

export const userProfileReducer: Reducer<ProfileShape, UserActions> = (state = initialState, action) => {
	switch (action.type) {
		case getType(userActions.fetchCurrentProfile):
			return { ...state, isLoading: true };

		case getType(userActions.fetchCurrentProfileSuccess):
			return {
				...state,
				isLoading: false,
				profile: {
					...action.payload,
					firstName: action.payload.firstName || '',
					lastName: action.payload.lastName || '',
				},
			};

		case getType(userActions.fetchCurrentProfileError):
			return { ...state, isLoading: false, formError: action.payload };

		case getType(userActions.updateContactInfoSuccess):
			return {
				...state,
				notifications: { contactInfoSuccess: 'user_profile_saved' },
				profile: {
					...action.payload,
				},
			};

		case getType(userActions.updateContactInfoError):
			return {
				...state,
				notifications: { contactInfoError: parseModelStateErrors(action.payload.title, action.payload.details) },
			};

		case getType(userActions.changePasswordSuccess):
			return { ...state, formError: null, notifications: { changePasswordSuccess: 'user_profile_password_changed' } };

		case getType(userActions.changePasswordError):
			return {
				...state,
				notifications: { changePasswordError: parseModelStateErrors(action.payload.title, action.payload.details) },
			};

		default:
			return state;
	}
};
