declare global {
	interface URLSearchParams {
		forEach(callback: (value: string, key: string) => void, thisArg?: any): void;
	}
}

export const parseQuery = (qs: string): { [key: string]: string[] } => {
	const result = {};

	const params = new URLSearchParams(qs);
	params.forEach((value: string, key: string) => {
		const values = params.getAll(key);
		if (values.length === 1) {
			result[key] = values[0];
		} else {
			result[key] = values;
		}
	});
	return result;
};

export const removeKeyFromSearch = (search: string, key: string) => {
	const query = parseQuery(search);
	delete query[key];
	return queryToSearh(query);
};

export const removeFromSearch = (search: string, key: string, value: string): string => {
	const segments = searchWithoutQuestion(search).split('&');
	const removeSegment = `${key}=${value}`;
	const removeIndex = segments.indexOf(removeSegment);
	if (removeIndex > -1) {
		segments.splice(removeIndex, 1);
	}

	if (!segments.length) {
		return '';
	}

	return segments.join('&');
};

export const addToSearch = (search: string, key: string, value: string): string => {
	search = searchWithoutQuestion(search);
	const newSegment = `${key}=${value}`;

	if (!search.length) {
		return newSegment;
	}

	const segments = search.split('&');

	if (segments.indexOf(newSegment) > -1) {
		return search;
	}

	const newSearchSegments = [...segments, newSegment];

	return newSearchSegments.join('&');
};

export const replaceToSearch = (search: string, key: string, value?: string): string => {
	search = searchWithoutQuestion(search);
	const replaceSegment = `${key}=${value}`;
	const hasValue = value !== undefined && value.trim().length > 0;

	if (search.length === 0 && hasValue) {
		return replaceSegment;
	}

	const segments = search.split('&');
	const replaceIndex = segments.findIndex(s => s.startsWith(`${key}=`));

	if (replaceIndex === -1 && hasValue) {
		segments.push(replaceSegment);
	} else {
		if (!hasValue) {
			segments.splice(replaceIndex, 1);
		} else {
			segments[replaceIndex] = replaceSegment;
		}
	}

	return segments.join('&');
};

export const queryToSearh = (query: object): string => {
	const search: string[] = [];
	const keys = Object.keys(query);
	if (keys.length > 0) {
		keys.forEach(key => {
			if (Array.isArray(query[key])) {
				query[key].forEach((value: string) => {
					search.push(`${key}=${value}`);
				});
			} else {
				search.push(`${key}=${query[key]}`);
			}
		});
		return `${search.join('&')}`;
	}
	return '';
};

export const searchWithoutQuestion = (search: string) => {
	if (search === undefined || search === null) {
		return '';
	}
	return search.replace('?', '');
};
