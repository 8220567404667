import * as React from 'react';
import NormalIconWrapper, { NormalIconWrapperProps } from '../NormalIconWrapper';
import { ThemeContext } from 'styled-components';

const PlusSignIcon: React.FC<NormalIconWrapperProps & React.SVGAttributes<SVGElement>> = ({ color, ...props }) => {
	const theme = React.useContext(ThemeContext);
	const fillColor = color ?? theme.colors.brandPrimary;

	return (
		<NormalIconWrapper alt="+" {...props}>
			<path fill={fillColor} d="M6 12h14a1 1 0 0 1 0 2H6a1 1 0 0 1 0-2z" />
			<path fill={fillColor} d="M14 6v14a1 1 0 0 1-2 0V6a1 1 0 0 1 2 0z" />
		</NormalIconWrapper>
	);
};

export default PlusSignIcon;
