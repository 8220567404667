import { call, put, takeLatest, fork } from 'redux-saga/effects';
import { materialSearchActions } from './reducers';
import { getType } from 'typesafe-actions';
import { materialSearchApi, MaterialSearchApi } from './services';
import { routingActions } from 'common/components/Routing/reducers';
import { ORDER_URL_HASH_BY_STATUS } from './constants';

function* fetchSecureFilters(
	api: MaterialSearchApi,
	{ payload }: ReturnType<typeof materialSearchActions.fetchSecureFilters.request>
) {
	try {
		const filters = yield call(api.fetchSecureFilters, payload);
		yield put(materialSearchActions.fetchSecureFilters.success(filters));
	} catch (e) {
		yield put(materialSearchActions.fetchSecureFilters.failure(e));
	}
}

function* watchFetchSecureFilters() {
	yield takeLatest(getType(materialSearchActions.fetchSecureFilters.request), fetchSecureFilters, materialSearchApi);
}

function* doSearchMaterials(
	api: MaterialSearchApi,
	siteUrlId: string,
	query: string,
	pageSize: number,
	pageNumber: number = 1
) {
	try {
		const materials = yield call(api.searchMaterials, siteUrlId, query, pageSize, pageNumber);
		yield put(materialSearchActions.searchMaterialsSucceeded(materials, pageNumber));
	} catch (e) {
		yield put(materialSearchActions.searchMaterialsFailed(e));
	}
}

function* searchMaterials(
	api: MaterialSearchApi,
	{ payload: { siteUrlId, query, pageSize } }: ReturnType<typeof materialSearchActions.searchMaterials>
) {
	yield call(doSearchMaterials, api, siteUrlId, query, pageSize);
}

function* loadMoreMaterials(
	api: MaterialSearchApi,
	{ payload: { siteUrlId, query, pageSize, pageNumber } }: ReturnType<typeof materialSearchActions.loadMoreMaterials>
) {
	yield call(doSearchMaterials, api, siteUrlId, query, pageSize, pageNumber);
}

export function* watchSearchMaterials() {
	yield takeLatest(getType(materialSearchActions.searchMaterials), searchMaterials, materialSearchApi);
}

export function* watchLoadMoreArticles() {
	yield takeLatest(getType(materialSearchActions.loadMoreMaterials), loadMoreMaterials, materialSearchApi);
}

function* fetchCart(api: MaterialSearchApi) {
	try {
		const items = yield call(api.getCart);
		yield put(materialSearchActions.fetchCart.success(items));
		if (items.length === 0) {
			yield put(routingActions.updateHash(ORDER_URL_HASH_BY_STATUS.SEARCH));
		}
	} catch (e) {
		yield put(materialSearchActions.fetchCart.failure(e));
	}
}

export function* watchFetchCart() {
	yield takeLatest(getType(materialSearchActions.fetchCart.request), fetchCart, materialSearchApi);
}

function* addToCart(
	api: MaterialSearchApi,
	{ payload: { id, quantity } }: ReturnType<typeof materialSearchActions.addToCart.request>
) {
	try {
		yield call(api.addToCart, id, quantity);
		yield call(fetchCart, api);
	} catch (e) {
		yield put(materialSearchActions.addToCart.failure(e));
	}
}

export function* watchAddToCart() {
	yield takeLatest(getType(materialSearchActions.addToCart.request), addToCart, materialSearchApi);
}

function* removeFromCart(
	api: MaterialSearchApi,
	{ payload }: ReturnType<typeof materialSearchActions.removeFromCart.request>
) {
	try {
		yield call(api.removeFromCart, payload);
		yield call(fetchCart, api);
	} catch (e) {
		yield put(materialSearchActions.removeFromCart.failure(e));
	}
}

export function* watchRemoveFromCart() {
	yield takeLatest(getType(materialSearchActions.removeFromCart.request), removeFromCart, materialSearchApi);
}

function* updateCartItemQuantity(
	api: MaterialSearchApi,
	{ payload: { id, quantity } }: ReturnType<typeof materialSearchActions.updateCartItemQuantity.request>
) {
	try {
		yield call(api.updateCartItem, id, quantity);
		yield call(fetchCart, api);
	} catch (e) {
		yield put(materialSearchActions.updateCartItemQuantity.failure(e));
	}
}

export function* watchUpdateCartItemQuantity() {
	yield takeLatest(
		getType(materialSearchActions.updateCartItemQuantity.request),
		updateCartItemQuantity,
		materialSearchApi
	);
}

function* orderCart(api: MaterialSearchApi, { payload }: ReturnType<typeof materialSearchActions.orderCart.request>) {
	try {
		yield call(api.orderCart, payload);
		yield put(materialSearchActions.orderCart.success());
		yield put(routingActions.updateHash(ORDER_URL_HASH_BY_STATUS.CONFIRMATION));
	} catch (e) {
		yield put(materialSearchActions.orderCart.failure(e));
	}
}

export function* watchOrderCart() {
	yield takeLatest(getType(materialSearchActions.orderCart.request), orderCart, materialSearchApi);
}

function* registerToMaterialBank(
	api: MaterialSearchApi,
	{ payload }: ReturnType<typeof materialSearchActions.registerToMaterialBank.request>
) {
	try {
		yield call(api.registerToMaterialBank, payload);
		yield put(materialSearchActions.registerToMaterialBank.success());
	} catch (e) {
		yield put(materialSearchActions.registerToMaterialBank.failure(e));
	}
}

export function* watchRegisterToMaterialBank() {
	yield takeLatest(
		getType(materialSearchActions.registerToMaterialBank.request),
		registerToMaterialBank,
		materialSearchApi
	);
}

export default function* materialSearchSaga() {
	yield fork(watchFetchSecureFilters);
	yield fork(watchSearchMaterials);
	yield fork(watchLoadMoreArticles);
	yield fork(watchFetchCart);
	yield fork(watchUpdateCartItemQuantity);
	yield fork(watchAddToCart);
	yield fork(watchRemoveFromCart);
	yield fork(watchOrderCart);
	yield fork(watchRegisterToMaterialBank);
}
