import * as React from 'react';
import styled, { css } from 'styled-components';
import { ResponsiveValues, getDisplayValue } from 'common/components/Media';
import { media } from 'styleguide/helpers';

const BORDER_RADIUS = 3; // px

interface StyledCardWrapperProps {
	isVisible?: ResponsiveValues<boolean>;
}

const StyledCardWrapper = styled.a<StyledCardWrapperProps>`
	width: 100%;
	display: flex;
	flex-direction: column;
	text-decoration: none;
	border-radius: ${BORDER_RADIUS}px;

	${({ isVisible }) => {
		const visibilityMap = (isVisible as ResponsiveValues<boolean>) ?? {};

		if (Object.keys(visibilityMap).length > 0) {
			return css`
				${Object.keys(visibilityMap).map(key => {
					if (key === 'default') {
						return css`
							display: ${getDisplayValue(visibilityMap.default, 'flex')};
						`;
					}
					return css`
						${media[key]`
							display: ${getDisplayValue(visibilityMap[key], 'flex')};
						`};
					`;
				})};
			`;
		}

		return null;
	}};
`;

export interface CardWrapperProps extends StyledCardWrapperProps {
	ref?: React.RefObject<any>;
}

const CardWrapper: React.FC<CardWrapperProps & React.AnchorHTMLAttributes<HTMLAnchorElement>> = ({
	children,
	ref,
	isVisible,
	...rest
}) => (
	<StyledCardWrapper {...rest} isVisible={isVisible} ref={ref}>
		{children}
	</StyledCardWrapper>
);

export default CardWrapper;
