import * as React from 'react';
import { withWindow } from 'styleguide/helpers/window';
import styled from 'styled-components';
import { media } from 'styleguide/helpers/media';
import { LoginStatusResponse } from 'fb';
import { connect } from 'react-redux';
import { loginActions } from '../reducers/actions';
import { FormattedMessage } from 'react-intl';

const FB_JSSDK_ID = 'facebook-jssdk';
const FB_ROOT_ID = 'fb-root';

const FacebookAuthWrapper = styled.div`
	padding: 30px;
	border-radius: 4px;
	border: 1px solid ${props => props.theme.colors.border};

	${media.desktop`
			padding: 0;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			> p {
				max-width: 258px;
			}
		`};

	& > p {
		font-family: ${props => props.theme.fonts.secondary};
		font-size: 15px;
		text-align: center;
		margin: 0 0 15px;

		${media.desktop`
				padding: 0 30px;
			`};
	}
`;

const FacebookLoginButton = styled.button`
	background: #4267b2;
	border-radius: 3px;
	display: flex;
	border: none;
	height: 28px;
	align-items: center;
	padding: 0 6px;
	cursor: pointer;
	margin: 0 auto;

	svg {
		width: 16px;
	}

	span {
		font-family: Helvetica, Arial, sans-serif;
		font-size: 13px;
		line-height: 1;
		letter-spacing: 0.25px;
		margin-left: 6px;
		white-space: nowrap;
		color: #fff;
	}

	&:focus,
	&:active {
		outline: none;
	}
`;

declare global {
	interface Window {
		FB: FBSDK;
	}
}

interface FacebookAuthProps {
	appId: string;
}

interface FacebookAuthState {
	isSdkLoaded: boolean;
	isProcessing: boolean;
	mobileFacebookAuth: boolean;
}

type Props = FacebookAuthProps & FacebookAuthDispatchProps;

class FacebookAuth extends React.Component<Props, FacebookAuthState> {
	public state: FacebookAuthState = {
		isSdkLoaded: false,
		isProcessing: false,
		mobileFacebookAuth: false,
	};

	public componentDidMount() {
		withWindow(w => {
			if (w.mobileApp) {
				w.mobileFacebookLogin = this.mobileFacebookLogin;
			}

			const d = w.document;

			if (d.getElementById(FB_JSSDK_ID)) {
				this.setState({ isSdkLoaded: true });
				return;
			}

			let fbRoot = d.getElementById(FB_ROOT_ID);
			if (!fbRoot) {
				fbRoot = d.createElement('div');
				fbRoot.id = FB_ROOT_ID;
				d.body.appendChild(fbRoot);
			}

			this.initSdk();
		});
	}

	public componentWillUnmount() {
		withWindow(w => {
			if (w.mobileFacebookLogin === this.mobileFacebookLogin) {
				w.mobileFacebookLogin = undefined;
			}
		});
	}

	public render() {
		return (
			<FacebookAuthWrapper className="facebook-login-wrapper">
				<p>
					<FormattedMessage id="component_login_facebook_login_with_fb_possible" />
				</p>
				<FacebookLoginButton onClick={this.onClick}>
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 216 216">
						<path
							fill="#FFFFFF"
							d="M204.1 0H11.9C5.3 0 0 5.3 0 11.9v192.2c0 6.6 5.3 11.9 11.9 11.9h103.5v-83.6H87.2V99.8h28.1v-24c0-27.9 17-43.1 41.9-43.1 11.9 0 22.2.9 25.2 1.3v29.2h-17.3c-13.5 0-16.2 6.4-16.2 15.9v20.8h32.3l-4.2 32.6h-28V216h55c6.6 0 11.9-5.3 11.9-11.9V11.9C216 5.3 210.7 0 204.1 0z"
						/>
					</svg>
					<span>
						<FormattedMessage id="component_login_facebook_login_with_fb" />
					</span>
				</FacebookLoginButton>
				{this.renderMobileAppCommunicationFrame()}
			</FacebookAuthWrapper>
		);
	}

	private mobileFacebookLogin = (token: string) => {
		this.props.doFacebookLogIn(token);
	};

	private renderMobileAppCommunicationFrame = () => {
		const { mobileFacebookAuth } = this.state;
		if (!mobileFacebookAuth) {
			return null;
		}
		return (
			<iframe
				style={{ display: 'none' }}
				className="communication-frame"
				src="https://app/event-dispatch?facebookLogin"
			/>
		);
	};

	private onClick = () => {
		if (withWindow(w => w.mobileApp)) {
			this.setState({ mobileFacebookAuth: true });
			return;
		}

		if (this.state.isSdkLoaded) {
			withWindow(w => {
				w.FB.getLoginStatus(this.checkLoginAfterRefresh);
			});
		}
	};

	private initSdk() {
		withWindow(w => {
			w.fbAsyncInit = () => {
				w.FB.init({
					appId: this.props.appId,
					xfbml: true,
					cookie: true,
					version: 'v3.0',
				});
				this.setState({ isSdkLoaded: true });
			};

			((d, id) => {
				let js;
				if (d.getElementById(id)) {
					return;
				}
				js = d.createElement('script');
				js.id = id;
				js.src = 'https://connect.facebook.net/fi_FI/sdk.js';
				d.body.appendChild(js);
			})(w.document, FB_JSSDK_ID);
		});
	}

	private checkLoginState = (response: LoginStatusResponse) => {
		this.setState({ isProcessing: false });
		const { doFacebookLogIn, facebookLoginFail } = this.props;
		if (response.authResponse) {
			doFacebookLogIn(response.authResponse.accessToken);
		} else {
			facebookLoginFail(0);
		}
	};

	private checkLoginAfterRefresh = (response: LoginStatusResponse) => {
		if (response.status === 'connected') {
			this.checkLoginState(response);
		} else {
			this.doLogin();
		}
	};

	private doLogin() {
		withWindow(w => {
			w.FB.login(this.checkLoginState, {
				scope: 'email',
			});
		});
	}
}

interface FacebookAuthDispatchProps {
	doFacebookLogIn: typeof loginActions.doFacebookLogIn;
	facebookLoginFail: typeof loginActions.facebookLoginFail;
}

export default connect(null, {
	doFacebookLogIn: loginActions.doFacebookLogIn,
	facebookLoginFail: loginActions.facebookLoginFail,
})(FacebookAuth);
