import * as React from 'react';
import Meta from 'common/components/Meta';
import { State } from 'common/reducers';
import { MapStateToProps, connect } from 'react-redux';
import { pushOtherPageLoad } from 'utils/dataLayer';
import { ShoppingListResourceState } from './interfaces';
import { ContentWrapper } from 'common/components/General';
import WidgetZone from 'common/components/Widgets/WidgetZone';
import styled from 'styled-components';
import { media } from 'styleguide/helpers/media';
import Hero from 'styleguide/components/HeroBanners/Hero';
import { getHeroMediaSource } from 'utils/media';
import injectRoutable, { InjectedRoutableProps } from 'common/components/Routing/Routable';
import { getWildcardPart } from 'common/components/App/services';
import {
	fetchShoppingListById,
	ShoppingListApiItem,
	ShoppingListApiRecipeItem,
} from 'pagetypes/User/services/shopping-list';
import SmallHeader from 'styleguide/components/Typography/SmallHeader/SmallHeader';
import { ShoppingListContentWrapper } from 'pagetypes/User/UserShoppingList';
import ShoppingListRecipe from 'pagetypes/User/components/ShoppingListRecipe';
import ShoppingListItem from 'pagetypes/User/components/ShoppingListItem';
import { ShoppingListColours } from 'pagetypes/User/types';
import includes from 'lodash/includes';

const TitleWrapper = styled.div`
	width: 100%;
	height: 100%;
	max-width: 1200px;
	margin: 0 auto;
	position: relative;
`;

const PageTitle = styled.h1`
	${props => props.theme.typography.heading};
	font-size: 40px;
	text-transform: uppercase;
	color: ${props => props.theme.colors.white};
	margin: 0;
	max-width: 640px;
	position: absolute;
	left: 50%;
	bottom: 80px;
	transform: translateX(-50%);

	${media.tablet`
		font-size: 60px;
	`};
`;

type Props = ShoppingListStateProps & InjectedRoutableProps;

function getSectionTitle(item: ShoppingListApiItem): string {
	if (item.storeSection) {
		return item.storeSection.name!;
	} else if (item.category === 'recipe') {
		return 'Muut tuotteet';
	} else {
		return 'Muista myös';
	}
}

function getItemsAndSectionTitles(items: ShoppingListApiItem[]) {
	return items.map(item => ({ item, title: getSectionTitle(item) }));
}

function getGroupStates(items: ShoppingListApiItem[]): GroupState[] {
	const itemsAndTitles = getItemsAndSectionTitles(items);
	return itemsAndTitles.reduce((result, { item, title }) => {
		const group = result.find(r => r.title === title);
		if (group) {
			group.entries.push({ isPicked: false, item });
			return result;
		} else {
			const newGroup = { title, entries: [{ isPicked: false, item }] };
			return [...result, newGroup];
		}
	}, [] as GroupState[]);
}

function renderShoppingList(shoppingList: ShoppingListState, setShoppingList: (value: ShoppingListState) => void) {
	const { recipes, groups } = shoppingList;

	const recipeIndexesById = {};
	recipes.forEach((recipe, index) => {
		recipeIndexesById[recipe.id] = index % 8;
	});

	function renderRecipe(recipe: ShoppingListApiRecipeItem) {
		return <ShoppingListRecipe key={recipe.id} {...recipe} recipeIndexesById={recipeIndexesById} isReadOnly />;
	}

	function renderRecipes() {
		return (
			<>
				<SmallHeader>Kauppalistan sisältämät reseptit</SmallHeader>
				{recipes.map(recipe => renderRecipe(recipe))}
			</>
		);
	}

	function renderEntry(entry: EntryState) {
		const item = entry.item;
		const handleItemClick = (itemId: string, isPicked: boolean) => {
			const newShoppingList = {
				...shoppingList,
				groups: shoppingList.groups.map(g =>
					includes(g.entries, entry)
						? { ...g, entries: g.entries.map(e => (e === entry ? { ...e, isPicked: !e.isPicked } : e)) }
						: g
				),
			};
			setShoppingList(newShoppingList);
		};

		let itemColour;
		if (item.recipe !== null) {
			const recipeIndex = recipeIndexesById[item.recipe.id];
			if (recipeIndex !== undefined) {
				itemColour = ShoppingListColours[recipeIndex];
			}
		}

		return (
			<ShoppingListItem
				key={item.id}
				{...item}
				ignored={entry.isPicked}
				itemColour={itemColour}
				onToggleItemPicked={handleItemClick}
				isReadOnly
			/>
		);
	}

	function renderGroups() {
		return groups.map(group => (
			<StoreSectionWrapper key={group.title}>
				{group.title && (
					<header>
						<SmallHeader className="store-section-header">{group.title}</SmallHeader>
					</header>
				)}
				<ShoppingListItemsWrapper>{group.entries.map(entry => renderEntry(entry))}</ShoppingListItemsWrapper>
			</StoreSectionWrapper>
		));
	}

	return (
		<ContentWrapper>
			{recipes.length > 0 && renderRecipes()}
			{recipes.length > 0 && groups.length > 0 && <hr />}
			{groups.length > 0 && renderGroups()}
		</ContentWrapper>
	);
}

const StoreSectionWrapper = styled.section`
	padding: 20px 10px;
	border-top: 1px solid ${props => props.theme.colors.border};
	width: 100%;

	${media.tablet`
		padding: 20px;
	`};

	@media print {
		padding: 5pt 0;
		width: 50%;

		.store-section-header {
			margin: 0;
			font-size: 12pt;
		}
	}
`;

const ShoppingListItemsWrapper = styled.ul`
	list-style: none;
	margin: 0;
	padding: 0;
`;

export interface RecipeIndicatorProps {
	itemColour?: string;
	showBorder?: boolean;
}

interface EntryState {
	readonly isPicked: boolean;
	readonly item: ShoppingListApiItem;
}

interface GroupState {
	readonly title: string;
	readonly entries: EntryState[];
}

interface ShoppingListState {
	readonly recipes: ShoppingListApiRecipeItem[];
	readonly groups: GroupState[];
}

function ShoppingList(props: Props) {
	const { canonicalUrl, title, leadText, heroImageDesktop, heroImageMobile, heroImageOffsetY, heroMediaType } = props;
	const heroMedia = getHeroMediaSource(heroMediaType, heroImageDesktop, heroImageMobile, 'product-front-hero');
	const shoppingListId = getWildcardPart(props.routing, props.basePath);

	const [shoppingList, setShoppingList] = React.useState<ShoppingListState | undefined>(undefined);

	React.useEffect(() => pushOtherPageLoad(), []);

	React.useEffect(() => {
		const load = async () => {
			if (shoppingListId) {
				const data = await fetchShoppingListById(shoppingListId);
				const state: ShoppingListState = {
					recipes: data.recipes,
					groups: getGroupStates(data.items),
				};
				setShoppingList(state);
			} else {
				setShoppingList(undefined);
			}
		};
		load();
	}, [shoppingListId]);

	return (
		<>
			<Meta canonicalUrl={canonicalUrl} title={title} description={leadText} />
			<ContentWrapper>
				<Hero size="medium" src={heroMedia.mediaSrc} mediaType={heroMedia.mediaType} offsetY={heroImageOffsetY}>
					<TitleWrapper>
						<PageTitle>{title}</PageTitle>
					</TitleWrapper>
				</Hero>
				<WidgetZone name="Main" isFullWidth={true} />
				<ShoppingListContentWrapper>
					{shoppingList && renderShoppingList(shoppingList, setShoppingList)}
				</ShoppingListContentWrapper>
			</ContentWrapper>
			<WidgetZone name="BottomFullWidth" isFullWidth={true} />
		</>
	);
}

interface ShoppingListStateProps {
	title?: string;
	titleHtml?: string;
	basePath: string;
	canonicalUrl: string | undefined;
	leadText?: string;
	layout?: string;
	heroImageDesktop?: string;
	heroImageMobile?: string;
	heroImageOffsetY: number;
	heroMediaType?: string;
}

const mapStateToProps: MapStateToProps<ShoppingListStateProps, {}, State> = ({
	resource,
}: State): ShoppingListStateProps => {
	const {
		title,
		titleHtml,
		basePath,
		canonicalUrl,
		metaDescription,
		layout,
		heroImageDesktop,
		heroImageMobile,
		heroImageOffsetY,
		heroMediaType,
	} = resource as ShoppingListResourceState;
	return {
		title,
		titleHtml,
		basePath,
		canonicalUrl,
		leadText: metaDescription,
		layout,
		heroImageDesktop,
		heroImageMobile,
		heroImageOffsetY,
		heroMediaType,
	};
};

export default connect(mapStateToProps)(injectRoutable(ShoppingList));
