import styled from 'styled-components';
import { media } from 'styleguide/helpers/media';

const SearchFiltersContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;

	${media.desktop`
		flex-direction: row;
		border-bottom: 1px solid ${props => props.theme.colors.border};
    padding: ${props => props.theme.grid.gutterInPx(3)} 0;
    margin: 4px auto 18px;

    > * + * {
      margin-left: 30px;
    }
	`};

	.toggle-filter {
		justify-content: space-between;
		padding: 15px 16px;

		${media.desktop`
			justify-content: flex-start;
			padding: 0;
		`};

		.toggle-label {
			font-family: ${props => props.theme.fonts.primary};
			font-size: 18px;
			line-height: 30px;
			text-transform: uppercase;

			${media.desktop`
				font-family: ${props => props.theme.fonts.secondary};
				font-size: 15px;
				font-weight: 600;
				text-transform: none;
				line-height: 20px;
			`};
		}
	}
`;

export default SearchFiltersContainer;
