import styled from 'styled-components';

export const HelpText = styled.label`
	display: flex;
	margin: ${({ theme }) => theme.grid.gutterInPx()} 0;
	align-items: flex-start;
	color: ${({ theme }) => theme.colors.shoppingListGrey};
	font-size: 13px;

	svg {
		margin-right: ${({ theme }) => theme.grid.gutterInPx()};
		flex: 0 0 auto;
	}
`;
