import * as React from 'react';
import RecipeCardImageBadgeText from './RecipeCardImageBadgeText';
import RecipeCardImageBadgeWrapper from './RecipeCardImageBadgeWrapper';
import { RecipeCreatorTypes } from '../../../../types/recipeTypes';

export interface RecipeCardImageBadgeProps {
	type: RecipeCreatorTypes;
	badgeText: string;
}

const RecipeCardImageBadge: React.FC<RecipeCardImageBadgeProps> = ({ type, badgeText }) => {
	return (
		<RecipeCardImageBadgeWrapper type={type}>
			<RecipeCardImageBadgeText type={type}>{badgeText}</RecipeCardImageBadgeText>
		</RecipeCardImageBadgeWrapper>
	);
};

export default RecipeCardImageBadge;
