import * as React from 'react';
import styled from 'styled-components';
import { VideoSource } from 'styleguide/types/mediaTypes';

const VideoWrapper = styled.div<State>`
	position: relative;
	cursor: pointer;
	width: 100%;

	&:hover {
		svg {
			opacity: ${props => (props.isPlaying ? '0' : '0.75')};
		}
	}

	video {
		margin: 0 auto;
		display: block;
	}

	svg {
		opacity: ${props => (props.isPlaying ? '0' : '1')};
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		pointer-events: none;
		transition: opacity 200ms ease;

		path {
			fill: ${({ theme }) => theme.colors.white};
		}
	}
`;

interface Props {
	sources: VideoSource[];
	hideCustomControls?: boolean;
}

interface State {
	isPlaying: boolean;
}

class InlineVideo extends React.Component<Props & React.VideoHTMLAttributes<HTMLVideoElement>, State> {
	public state: State = {
		isPlaying: false,
	};

	private videoRef: React.RefObject<HTMLVideoElement>;

	constructor(props: Props & React.VideoHTMLAttributes<HTMLVideoElement>) {
		super(props);
		this.videoRef = React.createRef();
	}

	public play() {
		if (this.videoRef.current) {
			this.videoRef.current.play();
		}
	}

	public pause() {
		if (this.videoRef.current) {
			this.videoRef.current.pause();
		}
	}

	public render() {
		const { sources, children, hideCustomControls, ...rest } = this.props;
		const { isPlaying } = this.state;
		return (
			<VideoWrapper isPlaying={isPlaying} onClick={this.toggleVideoPlayback}>
				<video {...rest} ref={this.videoRef} onPlay={this.onPlay} onPause={this.onPause} muted>
					{sources &&
						sources.map((source, index) => (
							<source key={`${index}-${source.src}`} src={source.src} type={`video/${source.type}`} />
						))}
				</video>
				{!this.props.controls && !hideCustomControls && (
					<svg width="80" height="80" xmlns="http://www.w3.org/2000/svg">
						<path d="M23 20l33.333 20L23 60z" fillRule="evenodd" />
					</svg>
				)}
			</VideoWrapper>
		);
	}

	private toggleVideoPlayback = () => {
		const { controls } = this.props;
		if (this.videoRef.current && !controls) {
			const { isPlaying } = this.state;
			if (isPlaying) {
				this.pause();
			} else {
				this.play();
			}
		}
	};

	private onPlay = () => {
		this.setState({ isPlaying: true });
	};

	private onPause = () => {
		this.setState({ isPlaying: false });
	};
}

export default InlineVideo;
