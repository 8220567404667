import { ExceptionDetails } from 'common/interfaces/exception';
import { FormValidationDisplayError } from 'common/components/Forms/interfaces';

// Like ES2017 Object.values, type signature lifted from lib.es2017.d.ts
export function values<T>(o: { [s: string]: T }): T[] {
	return Object.keys(o).map(k => o[k]);
}

export function isEmpty(o: {}): boolean {
	return Object.keys(o).length === 0 && o.constructor === Object;
}

export function parseModelStateErrors(title: string, exceptionDetails?: ExceptionDetails): FormValidationDisplayError {
	const modelState = exceptionDetails && exceptionDetails.modelState ? exceptionDetails.modelState : null;
	const message = modelState
		? Object.keys(modelState)
				.map(key => modelState[key])
				.join(', ')
		: exceptionDetails
		? exceptionDetails.message
		: '';

	return { title, message };
}

export function objectToFormData(object: object): FormData {
	const formData = new FormData();

	for (const [key, value] of Object.entries(object)) {
		formData.append(key, value);
	}

	return formData;
}
