import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { media } from 'styleguide/helpers';

const DisclaimerWrapper = styled.div`
	order: 3;
	width: 100%;
	color: ${({ theme }) => theme.colors.shoppingListGrey};
	text-align: center;
	padding: 0 10%;

	${media.desktop`
		order: 1;
		flex: 1 1 100%;
	`};

	p {
		margin: ${({ theme }) => theme.grid.gutterInPx(2)} 0;
	}

	a {
		color: ${({ theme }) => theme.colors.linkText};

		&:hover {
			color: ${({ theme }) => theme.colors.linkTextHover};
		}
	}
`;

const Disclaimer: React.FC<{}> = () => {
	return (
		<DisclaimerWrapper>
			<p>
				<FormattedMessage
					id="materials_search_paragraph1"
					values={{
						a: (msg: string) => (
							<a href="https://aineistopankki.valio.fi/" target="_blank" rel="noopener noreferrer">
								{msg}
							</a>
						),
						strong: (msg: string) => <strong>{msg}</strong>,
					}}
				/>
			</p>
			<p>
				<FormattedMessage id="materials_search_paragraph2" />
			</p>
			<p>
				<FormattedMessage
					id="materials_search_paragraph3"
					values={{
						strong: (msg: string) => <strong>{msg}</strong>,
					}}
				/>
			</p>
		</DisclaimerWrapper>
	);
};

export default Disclaimer;
