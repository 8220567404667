import { call, put, fork } from 'redux-saga/effects';
import { takeLatest } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { ProductRelatedItemsApi, productRelatedItemsApi } from './services';
import { productActions } from './reducers/reducers';
import { resourceActions } from 'common/components/Resource/reducers/actions';
import { ResourceType } from 'common/components/Resource/types';

function* fetchRelatedRecipes(api: ProductRelatedItemsApi, siteUrlId: string, productId: string) {
	const relatedRecipesResult = yield call(api.getRelatedRecipes, siteUrlId, productId);
	yield put(productActions.setRelatedRecipes(relatedRecipesResult));
}

function* fetchRelatedProducts(api: ProductRelatedItemsApi, siteUrlId: string, recipeId: string) {
	const relatedProductsResult = yield call(api.getRelatedProducts, siteUrlId, recipeId);
	yield put(productActions.setRelatedProducts(relatedProductsResult));
}

function* fetchProductRelatedItems(
	api: ProductRelatedItemsApi,
	{ payload }: ReturnType<typeof resourceActions.setResource>
) {
	const { resource, siteUrlId } = payload;
	if (resource.type === ResourceType.product) {
		yield fork(fetchRelatedRecipes, api, siteUrlId, resource.content.id);
		yield fork(fetchRelatedProducts, api, siteUrlId, resource.content.id);
	}
}

export function* productRelatedItemsSaga() {
	yield takeLatest(getType(resourceActions.setResource), fetchProductRelatedItems, productRelatedItemsApi);
}
