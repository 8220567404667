import * as React from 'react';
import NormalIconWrapper, { NormalIconWrapperProps } from '../NormalIconWrapper';

const AlertIcon: React.FC<NormalIconWrapperProps & React.SVGAttributes<SVGElement>> = ({ color, ...props }) => {
	return (
		<NormalIconWrapper {...props}>
			<path
				d="M12.884 22.767C7.425 22.767 3 18.342 3 12.884 3 7.425 7.425 3 12.884 3c5.458 0 9.883 4.425 9.883 9.884 0 5.458-4.425 9.883-9.883 9.883zm0-1.162a8.72 8.72 0 1 0 0-17.442 8.72 8.72 0 0 0 0 17.442z"
				fill="#D81614"
			/>
			<path
				d="M12.302 9.163a.581.581 0 1 1 1.163 0v3.72a.581.581 0 0 1-1.163 0v-3.72zm0 7.21a.581.581 0 1 1 1.163 0v.464a.581.581 0 0 1-1.163 0v-.465z"
				fill="#D81614"
			/>
		</NormalIconWrapper>
	);
};

export default AlertIcon;
