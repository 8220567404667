import { ResourceType } from 'common/components/Resource/types';
import { Reducer } from 'redux';
import { ResourceActions, resourceActions } from 'common/components/Resource/reducers/actions';
import { getType } from 'typesafe-actions';
import { BrandContentState } from './interfaces';

const brandInitialState: BrandContentState = {
	contentFamily: '',
	leadText: '',
};

export const brandReducer: Reducer<BrandContentState, ResourceActions> = (
	state = brandInitialState,
	action
): BrandContentState => {
	switch (action.type) {
		case getType(resourceActions.setResource):
			if (action.payload.resource.type === ResourceType.brand) {
				const { contentFamily, leadText } = action.payload.resource.content;
				return {
					...state,
					contentFamily,
					leadText,
				};
			}
		default:
			return state;
	}
};
