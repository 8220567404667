import * as React from 'react';
import styled from 'styled-components';
import { ButtonProps } from '../../../interfaces';
import { baseButtonCss, baseFilledButtonCss } from '../common';

export const StyledButton = <T extends 'button' | 'a'>(tagName: T) =>
	styled(tagName)<
		ButtonProps &
			(T extends 'button'
				? React.ButtonHTMLAttributes<HTMLButtonElement>
				: React.AnchorHTMLAttributes<HTMLAnchorElement>)
	>`
		${baseButtonCss};
		${baseFilledButtonCss};
	`;
