import { media } from '../../../../helpers';
import { CardTitle } from '../../Card';
import styled from 'styled-components';

const RecipeCardTitle = styled(CardTitle)`
	${media.phone`
		display: block;
	`};
	word-break: break-word;
	flex: 1 0 36px;
	height: auto;
`;

export default RecipeCardTitle;
