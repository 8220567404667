import { Reducer } from 'redux';
import { getType, createCustomAction, ActionType } from 'typesafe-actions';
import { parseQuery } from 'utils/query-string';

export interface RoutingParams {
	pathname: string;
	search?: string;
	hash?: string;
	preventScroll?: boolean;
	siteUrlId?: string;
}

export const routingActions = {
	updateUrl: createCustomAction(
		'ROUTING/UPDATE_URL',
		type => ({ pathname, search, hash, preventScroll }: RoutingParams, siteUrlId?: string) => ({
			type,
			payload: { siteUrlId, pathname, search, hash, query: parseQuery(search || ''), preventScroll },
		})
	),

	updateSearch: createCustomAction('ROUTING/UPDATE_SEARCH', type => (search: string) => ({
		type,
		payload: { search, query: parseQuery(search) },
	})),

	updateHash: createCustomAction('ROUTING/UPDATE_HASH', type => (hash?: string) => ({
		type,
		payload: { hash },
	})),

	replaceHistoryState: createCustomAction('ROUTING/REPLACE_STATE', type => (routing: RoutingParams) => ({
		type,
		payload: routing,
	})),

	historyStateChanged: createCustomAction(
		'ROUTING/HISTORY_STATE_CHANGED',
		type => ({ pathname, search, hash, preventScroll, siteUrlId }: RoutingParams) => ({
			type,
			payload: { pathname, search, hash, query: parseQuery(search || ''), preventScroll, siteUrlId },
		})
	),
};

export interface RoutingState {
	protocol: string;
	hostname: string;
	port: string;
	pathname: string;
	search?: string;
	hash?: string;
	query?: { [key: string]: string | string[] } | undefined;
}

export const getRoutingStateFromCurrentUrl = (): RoutingState => {
	const hasLocation = typeof location !== 'undefined';
	return {
		protocol: hasLocation ? location.protocol : '',
		hostname: hasLocation ? location.hostname : '',
		port: hasLocation ? location.port : '',
		pathname: hasLocation ? location.pathname : '/',
		search: hasLocation ? location.search : '',
		hash: hasLocation ? location.hash : '',
		query: hasLocation ? parseQuery(location.search) : {},
	};
};

export const initialRoutingState = getRoutingStateFromCurrentUrl();

export const routingReducer: Reducer<RoutingState, RoutingActions> = (state = initialRoutingState, action) => {
	switch (action.type) {
		case getType(routingActions.updateUrl):
		case getType(routingActions.historyStateChanged):
			return {
				...state,
				...action.payload,
			};

		case getType(routingActions.updateSearch):
		case getType(routingActions.updateHash):
			return {
				...state,
				...action.payload,
			};

		default:
			return state;
	}
};

export type RoutingActions = ActionType<typeof routingActions>;
