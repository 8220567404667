import * as React from 'react';
import { ReactNode } from 'react';
import { Helmet } from 'react-helmet';
import LabeledIconButtonLink from 'styleguide/components/Buttons/LabeledIconButton/LabeledIconButtonLink';
import styled from 'styled-components';
import PenIcon from 'styleguide/components/Icons/NormalIcons/PenIcon/PenIcon';
import { media } from 'styleguide/helpers/media';
import { MapStateToProps, connect } from 'react-redux';
import UserRecipeTiles from './components/UserRecipeTiles';
import Loading from 'common/components/Loading/Loading';
import RecipesIntroduction from './components/RecipesIntroduction';
import { UserRecipesShape } from './interfaces';
import { State } from 'common/reducers';
import { userActions } from './reducers/actions';
import { loginActions } from 'common/components/Login/reducers/actions';
import { recipeShoppingListActions } from '../Recipe/reducers/shopping-list';
import SortOrderSelect from 'common/components/SortOrder/SortOrderSelect';
import { LoginModalFormType } from 'common/components/Login/types';
import { injectIntl, WrappedComponentProps, FormattedMessage } from 'react-intl';
import { getSiteUrlPrefixOrDefault } from 'common/components/App/services';
import Alert, { AlertType } from 'styleguide/components/Typography/Alert/Alert';
import { withWindow } from 'styleguide/helpers';
import { UnderlineText } from './components/UnderlineText';

const UserRecipesWrapper = styled.div`
	width: 100%;

	.center-button {
		display: flex;
		justify-content: center;

		button {
			width: 100%;
		}
	}

	${media.tablet`
    padding: 0 30px;
  `};
`;

const SortWrapper = styled.div`
	display: flex;
	flex-flow: row nowrap;
	width: 100%;
	margin-bottom: 30px;
	padding-left: 10px;
	padding-right: 10px;

	${media.tablet`
    margin-bottom: 0;
    padding-left: 30px;
    padding-right: 30px;
  `};
`;

const AlertWrapper = styled.div`
	margin-bottom: 1.5em;
	margin-top: 0.5em;

	& div {
		margin-bottom: 0.5em;
	}
`;

const CreateOwnRecipeWrapper = styled.div`
	border-top: 1px solid ${props => props.theme.colors.border};
	border-bottom: 1px solid ${props => props.theme.colors.border};
	width: 100%;
	padding: 15px;
`;

type UserRecipesType = UserRecipeDispatchProps & UserRecipeStateProps & WrappedComponentProps;
type SortOptionTypes = 'creationTime' | 'name';

class UserRecipes extends React.Component<UserRecipesType> {
	constructor(props: UserRecipesType) {
		super(props);

		const { recipesInMemory, cookbookRecipes } = this.props;

		const isCookbookInSync =
			cookbookRecipes.recipes.every(r => recipesInMemory.findIndex(rm => rm === r.id) > -1) &&
			cookbookRecipes.recipes.length === recipesInMemory.length;

		if (!isCookbookInSync || cookbookRecipes.recipes.length === 0) {
			this.props.fetchUserRecipes();
		}
	}

	public render() {
		const { isLoggedIn, intl, siteUrlPrefix } = this.props;
		const { recipes, isLoading, recipesSendSucceeded, recipesSent, error } = this.props.cookbookRecipes;

		const isMobileApp = withWindow(w => w.mobileApp);

		return (
			<UserRecipesWrapper>
				<Helmet>
					<title>{intl.formatMessage({ id: 'user_profile_own_recipes' })} - Valio</title>
				</Helmet>
				{recipes.length ? (
					<>
						<AlertWrapper>
							<Alert
								type={AlertType.Info}
								highlightedText={intl.formatMessage({
									id: isMobileApp ? 'user_profile_recipe_mobile_title' : 'user_profile_recipe_sendmail_title',
								})}>
								<FormattedMessage
									id={
										isMobileApp ? 'user_profile_recipe_mobile_description' : 'user_profile_recipe_sendmail_description'
									}
									values={{ underline: (chunks: ReactNode) => <UnderlineText>{chunks}</UnderlineText> }}
								/>
								{!isMobileApp && (
									<span>
										&nbsp;<a href="https://www.valio.fi/sovellus/">https://www.valio.fi/sovellus/</a>
									</span>
								)}
							</Alert>
							{error && <Alert type={AlertType.Error}>{intl.formatMessage({ id: error })}</Alert>}
						</AlertWrapper>
						<SortWrapper>{this.renderSort()}</SortWrapper>
						<UserRecipeTiles
							recipes={recipes}
							onRemoveClick={this.onRemoveClick}
							onToShoppingListClick={this.onToShoppingListClick}
							onSendClick={this.onSendClick}
							sendClickDisabled={recipesSent}
							sendSucceeded={recipesSendSucceeded}
						/>
					</>
				) : isLoading === true ? (
					<Loading visible={true} />
				) : (
					<RecipesIntroduction onLoginClicked={this.onLoginClicked} isLoggedIn={isLoggedIn} />
				)}

				{isLoggedIn && (
					<CreateOwnRecipeWrapper>
						<div className="center-button">
							<LabeledIconButtonLink icon={<PenIcon />} buttonStyle="primary" href={`${siteUrlPrefix}reseptit/editori`}>
								<FormattedMessage id="user_profile_create_own_recipe" />
							</LabeledIconButtonLink>
						</div>
					</CreateOwnRecipeWrapper>
				)}
			</UserRecipesWrapper>
		);
	}

	private renderSort = () => {
		const { intl } = this.props;
		const sortOptions = [
			{
				value: 'creationTime',
				label: intl.formatMessage({ id: 'user_profile_recipe_sort_last_added' }),
				selected: true,
			},
			{
				value: 'name',
				label: intl.formatMessage({ id: 'user_profile_recipe_sort_alpabetical' }),
				selected: false,
			},
		];

		return <SortOrderSelect sortOptions={sortOptions} onSortClick={this.onSortChange} value={sortOptions[0].value} />;
	};

	private onSortChange = (input: string) => {
		const value = input as SortOptionTypes;
		const sortDirection = value === 'creationTime' ? 'desc' : 'asc';
		this.props.sortRecipes(value, sortDirection);
	};

	private onLoginClicked = () => {
		this.props.openLogin(LoginModalFormType.LOGIN);
	};

	private onToShoppingListClick = (e: React.MouseEvent<HTMLButtonElement>) => {
		const recipeId = e.currentTarget.value;
		this.props.addToShoppingList(recipeId);
	};

	private onRemoveClick = (e: React.MouseEvent<HTMLButtonElement>) => {
		const recipeId = e.currentTarget.value;
		this.props.cookbookRemoveRecipe(recipeId);
	};

	private onSendClick = (e: React.MouseEvent<HTMLButtonElement>) => {
		this.props.sendUserRecipes();
	};
}

interface UserRecipeStateProps {
	recipesInMemory: string[];
	cookbookRecipes: UserRecipesShape;
	isLoggedIn: boolean;
	siteUrlPrefix: string;
}

const mapStateToProps: MapStateToProps<UserRecipeStateProps, {}, State> = ({
	user,
	app,
	routing,
}): UserRecipeStateProps => {
	let recipesInMemory: string[] = [];
	let cookbookRecipes: UserRecipesShape = { recipes: [] };

	if (user) {
		recipesInMemory = user.recipesInMemory;
		cookbookRecipes = user.cookbookRecipes;
	}
	const sites = (app.settings && app.settings.sites) || [];
	return {
		recipesInMemory,
		cookbookRecipes,
		isLoggedIn: user ? user.isLoggedIn : false,
		siteUrlPrefix: getSiteUrlPrefixOrDefault(routing, sites, '/'),
	};
};

interface UserRecipeDispatchProps {
	fetchUserRecipes: typeof userActions.fetchUserRecipes;
	sendUserRecipes: typeof userActions.sendUserRecipes;
	addToShoppingList: typeof recipeShoppingListActions.addToShoppingList;
	cookbookRemoveRecipe: typeof userActions.removeRecipe;
	openLogin: typeof loginActions.openLogin;
	sortRecipes: typeof userActions.sortUserRecipes;
}

export default connect(mapStateToProps, {
	fetchUserRecipes: userActions.fetchUserRecipes,
	sendUserRecipes: userActions.sendUserRecipes,
	addToShoppingList: recipeShoppingListActions.addToShoppingList,
	cookbookRemoveRecipe: userActions.removeRecipe,
	openLogin: loginActions.openLogin,
	sortRecipes: userActions.sortUserRecipes,
})(injectIntl(UserRecipes));
