import * as React from 'react';
import IconWrapper from '../../IconWrapper';
import { ThemeContext } from 'styled-components';

const NoSearchResultsIcon: React.FC<React.SVGAttributes<SVGElement>> = ({ ...rest }) => {
	const theme = React.useContext(ThemeContext);

	return (
		<IconWrapper
			viewBox="0 0 300 320"
			width="300"
			height="320"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...rest}>
			<g clipPath="url(#clip0_146_697)">
				<path
					d="M79.6624 199.685V54.7803L105.238 29.817L105.407 20.6626L154.053 1.19849V10.4397L182.731 56.1958V200.97L134.633 226.421L79.6624 199.685Z"
					fill={theme.colors.componentNoResultsSvgAccentVeryLight}
				/>
				<path
					d="M153.245 2.40253V10.6622L181.923 56.4182V200.476L134.622 225.505L80.4758 199.175V55.1329L106.047 30.1859L106.215 21.2375L153.245 2.41882V2.40253ZM154.872 0.0162857L152.638 0.905709L105.607 19.7244L104.604 20.1257V21.2104L104.452 29.4917L79.3586 53.9832L78.8651 54.4604V200.216L79.7816 200.661L133.928 226.996L134.67 227.359L135.397 226.969L182.699 201.941L183.567 201.485V55.9627L183.317 55.5668L154.888 10.2066V0L154.872 0.0162857Z"
					fill={theme.colors.brandPrimary}
				/>
				<path
					d="M80.4758 55.1221L134.622 75.66L181.918 56.4345"
					stroke={theme.colors.brandPrimary}
					strokeWidth="1.62698"
					strokeMiterlimit="10"
				/>
				<path
					d="M154.937 10.136L105.504 30.1695"
					stroke={theme.colors.brandPrimary}
					strokeWidth="1.62698"
					strokeMiterlimit="10"
				/>
				<path
					d="M134.622 225.51V75.6598L105.504 30.1694"
					stroke={theme.colors.brandPrimary}
					strokeWidth="1.62698"
					strokeMiterlimit="10"
				/>
				<path
					d="M217.646 319.126C216.019 319.126 214.712 318.28 213.557 317.537C212.991 317.122 212.366 316.793 211.703 316.561C211.161 316.396 210.598 316.314 210.032 316.317C208.373 316.317 206.686 316.946 204.896 317.613C203.107 318.28 201.165 319.002 199.229 319.002C197.832 319.011 196.463 318.605 195.297 317.836C194.755 317.597 194.213 317.342 193.752 317.076C190.997 315.536 189.543 313.627 189.543 311.561C189.543 306.924 197.168 303.204 207.283 302.911C207.624 302.911 207.966 302.911 208.313 302.911H208.514C208.991 302.867 209.49 302.846 209.989 302.846C213.21 302.846 216.312 303.735 219.317 304.592C220.092 304.814 220.862 305.037 221.622 305.237H221.681H221.736C222.31 305.487 222.853 305.78 223.363 306.029C226.112 307.564 227.571 309.473 227.571 311.539C227.571 313.985 225.499 316.225 221.746 317.841C221.204 318.08 220.608 318.307 219.995 318.514C219.272 318.902 218.467 319.112 217.646 319.126V319.126Z"
					fill={theme.colors.white}
				/>
				<path
					d="M209.989 303.643C213.921 303.643 217.766 305.042 221.416 306.008C221.941 306.231 222.453 306.482 222.95 306.762C225.331 308.09 226.747 309.755 226.747 311.561C226.747 313.73 224.707 315.699 221.416 317.12C220.873 317.353 220.282 317.576 219.669 317.782C219.05 318.126 218.355 318.313 217.646 318.324C215.32 318.324 213.611 316.209 211.898 315.781C211.292 315.602 210.664 315.512 210.032 315.515C206.42 315.515 202.814 318.226 199.229 318.226C197.966 318.23 196.732 317.852 195.688 317.142C195.145 316.903 194.603 316.654 194.147 316.388C191.772 315.059 190.351 313.394 190.351 311.583C190.351 307.423 197.835 304.023 207.299 303.746C207.63 303.746 207.96 303.746 208.297 303.746H208.546C209.023 303.703 209.506 303.681 209.983 303.681L209.989 303.643ZM209.983 302.054C209.474 302.054 208.964 302.054 208.476 302.119H208.297C207.944 302.119 207.597 302.119 207.25 302.119C196.517 302.434 188.724 306.415 188.724 311.583C188.724 313.242 189.527 315.672 193.35 317.803C193.833 318.075 194.359 318.346 194.907 318.579C196.198 319.416 197.707 319.854 199.245 319.837C201.328 319.837 203.296 319.105 205.211 318.395C206.93 317.755 208.552 317.153 210.065 317.153C210.538 317.149 211.01 317.216 211.464 317.353H211.502V317.353C212.081 317.571 212.628 317.864 213.129 318.226C214.311 318.991 215.786 319.946 217.657 319.946C218.58 319.936 219.487 319.713 220.309 319.295C220.922 319.083 221.519 318.855 222.072 318.617C226.139 316.86 228.384 314.354 228.384 311.567C228.384 309.907 227.582 307.483 223.758 305.346C223.21 305.042 222.647 304.765 222.072 304.516L221.958 304.468H221.844C221.09 304.267 220.342 304.055 219.55 303.828C216.497 302.954 213.335 302.049 210 302.049L209.983 302.054Z"
					fill={theme.colors.brandPrimary}
				/>
				<path
					d="M111.963 289.499C110.012 289.471 108.07 289.231 106.171 288.783C103.948 288.312 101.798 287.54 99.7826 286.489C97.8031 285.405 96.9299 284.266 97.1848 283.149C97.4668 281.896 99.1047 281.229 101.919 281.229C103.871 281.257 105.813 281.498 107.711 281.95C109.93 282.419 112.074 283.189 114.084 284.239C116.063 285.323 116.936 286.468 116.681 287.579C116.416 288.838 114.778 289.499 111.963 289.499Z"
					fill={theme.colors.white}
				/>
				<path
					d="M101.952 282.042C103.841 282.07 105.721 282.305 107.56 282.742C112.506 283.864 116.237 285.952 115.922 287.4C115.732 288.246 114.198 288.686 111.979 288.686C110.089 288.657 108.206 288.423 106.366 287.986C101.42 286.864 97.6892 284.776 98.0091 283.328C98.199 282.487 99.7283 282.042 101.952 282.042V282.042ZM101.952 280.415C98.6654 280.415 96.8052 281.278 96.4201 282.97C96.0784 284.477 97.0872 285.904 99.4192 287.2C101.496 288.29 103.714 289.089 106.008 289.575C107.964 290.037 109.965 290.285 111.974 290.313C115.261 290.313 117.115 289.456 117.5 287.764C117.847 286.251 116.839 284.83 114.507 283.528C112.428 282.441 110.212 281.642 107.917 281.153C105.962 280.691 103.961 280.443 101.952 280.415Z"
					fill={theme.colors.brandPrimary}
				/>
				<path
					opacity="0.86"
					d="M14.0408 249.947H14.0137C13.0528 249.948 12.1049 249.725 11.2447 249.297C10.3845 248.869 9.63556 248.247 9.05684 247.48C5.97643 243.364 0.813477 234.128 0.813477 218.547C0.813477 202.391 6.28556 193.09 9.53951 188.979C10.5006 187.779 11.8762 186.982 13.3955 186.745C52.0687 182.287 125.749 173.821 127.544 173.772C140.533 173.772 151.103 192.754 151.103 216.019C151.103 239.285 140.549 258.24 127.566 258.272L14.0408 249.947Z"
					fill="url(#paint0_linear_146_697)"
				/>
				<path
					d="M127.566 174.586C133.456 174.586 139.063 178.783 143.364 186.376C147.833 194.267 150.29 204.815 150.29 216.019C150.29 227.224 147.833 237.778 143.364 245.668C139.074 253.261 133.477 257.431 127.599 257.459L14.1331 249.139H14.0138C13.1773 249.134 12.3534 248.936 11.6064 248.559C10.8594 248.183 10.2095 247.639 9.70768 246.97C6.69234 242.935 1.62701 233.867 1.62701 218.525C1.62701 202.619 6.9852 193.491 10.1795 189.462C11.0057 188.431 12.1842 187.744 13.4877 187.531C51.9169 183.1 125.05 174.7 127.566 174.564V174.586ZM127.539 172.937C125.776 172.937 13.2653 185.92 13.2653 185.92C11.5546 186.196 10.0078 187.099 8.92673 188.453C5.80293 192.385 0.0217285 201.914 0.0217285 218.525C0.0217285 234.252 5.22806 243.667 8.42779 247.946C9.08023 248.816 9.92704 249.522 10.9007 250.007C11.8743 250.492 12.9477 250.743 14.0354 250.739L127.561 259.064C141.027 259.064 151.938 239.784 151.938 215.998C151.938 192.211 141.027 172.937 127.561 172.937H127.539Z"
					fill={theme.colors.brandPrimary}
				/>
				<g opacity="0.63">
					<path
						opacity="0.63"
						d="M132.865 238.982C135.755 239.323 140.945 240.126 147.356 241.102C150.224 234.052 151.922 225.375 151.922 216.014C151.922 192.233 141.01 172.953 127.544 172.953C114.078 172.953 103.167 192.233 103.167 216.014C103.108 223.828 104.379 231.594 106.925 238.982C107.044 238.982 122.853 237.805 132.865 238.982Z"
						fill="url(#paint1_linear_146_697)"
					/>
					<path
						opacity="0.63"
						d="M122.251 258.055C123.933 258.727 125.727 259.077 127.539 259.085L122.251 258.055Z"
						fill={theme.colors.componentNoResultsSvgAccentPuddle}
					/>
				</g>
				<path
					d="M127.539 174.586C133.439 174.586 139.058 178.772 143.364 186.376C147.833 194.267 150.289 204.815 150.289 216.019C150.289 227.224 147.833 237.778 143.364 245.668C139.058 253.261 133.439 257.459 127.539 257.459C121.638 257.459 116.02 253.272 111.714 245.668C107.25 237.778 104.788 227.229 104.788 216.019C104.788 204.81 107.25 194.267 111.714 186.376C116.02 178.783 121.638 174.586 127.539 174.586ZM127.539 172.959C114.073 172.959 103.161 192.238 103.161 216.019C103.161 239.8 114.073 259.086 127.539 259.086C141.005 259.086 151.916 239.806 151.916 216.019C151.916 192.233 141.005 172.959 127.539 172.959Z"
					fill={theme.colors.brandPrimary}
				/>
				<path
					d="M244.074 304.457C229.469 304.457 222.809 300.85 217.945 298.22C215.054 296.652 212.771 295.416 209.739 295.416C209.272 295.417 208.805 295.444 208.34 295.497C206.285 295.736 203.725 296.788 200.747 298.003C195.411 300.199 188.773 302.927 180.54 302.927C174.613 302.927 168.815 301.539 162.817 298.675C130.24 283.143 106.605 277.465 95.3409 277.329C83.4585 277.188 77.7966 275.99 76.4083 273.322C75.7521 272.052 76.0287 270.567 77.2597 268.766C80.2968 264.33 113.243 260.002 122.685 258.847C120.09 256.837 117.676 254.603 115.472 252.171C110.445 246.699 107.76 239.426 107.652 239.117L107.283 238.108L108.368 238.027C108.438 238.027 115.553 237.485 123.184 237.485C126.953 237.449 130.721 237.63 134.47 238.027C136.997 238.32 141.205 238.965 146.531 239.773C162.801 242.252 187.406 245.999 202.635 245.999C208.253 245.999 215.694 245.115 223.569 244.177C233.547 242.989 244.86 241.644 255.734 241.644C269.65 241.644 280.252 243.879 288.149 248.478C299.299 254.986 299.912 263.305 298.453 269.162C296.49 277.085 289.472 285.692 279.2 292.775C269.249 299.635 257.648 303.968 248.168 304.364C246.753 304.429 245.397 304.457 244.074 304.457Z"
					fill={theme.colors.white}
				/>
				<path
					d="M123.184 238.309C126.921 238.28 130.656 238.461 134.372 238.851C144.421 240.034 182.243 246.829 202.635 246.829C215.336 246.829 236.183 242.49 255.734 242.49C267.573 242.49 278.935 244.085 287.731 249.21C317.781 266.705 276.505 302.401 248.141 303.583C246.72 303.645 245.365 303.674 244.074 303.67C220.922 303.67 218.303 294.63 209.739 294.63C209.241 294.63 208.743 294.659 208.248 294.716C202.331 295.394 193.307 302.141 180.557 302.141C175.388 302.141 169.612 301.056 163.202 297.97C140.896 287.33 112.224 276.744 95.4114 276.543C78.5993 276.342 74.7542 273.983 77.9919 269.254C81.2296 264.525 124.941 259.433 124.941 259.433C124.941 259.433 121.074 257.035 116.134 251.65C111.193 246.265 108.46 238.84 108.46 238.84C108.46 238.84 115.51 238.298 123.211 238.298L123.184 238.309ZM123.211 236.671C115.553 236.671 108.406 237.213 108.335 237.213L106.166 237.376L106.903 239.394C107.017 239.708 109.756 247.133 114.892 252.724C116.699 254.705 118.637 256.563 120.695 258.283C116.475 258.825 109.474 259.769 102.369 260.94C79.0494 264.78 77.3248 267.253 76.6035 268.31C75.2097 270.35 74.906 272.161 75.7033 273.696C77.2652 276.711 82.9596 277.996 95.3463 278.143C106.524 278.278 130.028 283.929 162.481 299.407C168.593 302.325 174.504 303.746 180.557 303.746C188.952 303.746 195.671 300.986 201.073 298.762C203.98 297.569 206.496 296.533 208.432 296.311C208.866 296.262 209.303 296.237 209.739 296.235C212.565 296.235 214.658 297.368 217.56 298.946C222.5 301.62 229.263 305.281 244.074 305.281C245.408 305.281 246.786 305.281 248.212 305.194C257.833 304.793 269.596 300.405 279.667 293.458C290.101 286.267 297.238 277.487 299.25 269.368C300.763 263.251 300.14 254.535 288.556 247.789C280.535 243.119 269.796 240.847 255.734 240.847C244.811 240.847 233.499 242.197 223.471 243.385C215.623 244.323 208.204 245.202 202.635 245.202C187.45 245.202 161.917 241.313 146.65 238.987C141.319 238.173 137.105 237.533 134.562 237.235C130.779 236.838 126.977 236.657 123.173 236.693L123.211 236.671Z"
					fill={theme.colors.brandPrimary}
				/>
				<path
					d="M203.199 277.487C203.199 278.956 201.821 280.285 199.587 281.25C199.218 281.413 198.822 281.559 198.405 281.7C196.078 282.91 194.608 280.703 193.139 280.339C189.462 279.255 185.785 283.647 182.146 281.25C181.799 281.098 181.461 280.928 181.132 280.741C179.505 279.84 178.561 278.712 178.561 277.487C178.561 274.667 183.632 272.356 190.047 272.172C190.329 272.172 190.59 272.172 190.893 272.172C193.898 271.895 196.859 272.996 199.614 273.728C199.988 273.891 200.335 274.059 200.655 274.238C202.239 275.111 203.199 276.245 203.199 277.487Z"
					fill={theme.colors.componentNoResultsSvgAccentPuddle}
				/>
				<path
					d="M161.662 263.869C166.736 263.869 170.849 262.665 170.849 261.179C170.849 259.693 166.736 258.489 161.662 258.489C156.588 258.489 152.475 259.693 152.475 261.179C152.475 262.665 156.588 263.869 161.662 263.869Z"
					fill={theme.colors.componentNoResultsSvgAccentPuddle}
				/>
				<path
					d="M209.468 255.023C209.164 254.914 208.909 254.7 208.748 254.42C208.586 254.139 208.53 253.811 208.588 253.493C208.646 253.175 208.814 252.888 209.064 252.683C209.313 252.477 209.628 252.367 209.951 252.371C225.678 252.702 254.308 245.641 265.637 249.036C280.708 253.548 292.948 264.818 286.598 270.789C280.247 276.76 275.909 261.38 269.607 261.515C258.76 261.743 253.917 281.115 246.704 272.904C231.752 255.929 221.199 259.14 209.468 255.023Z"
					fill={theme.colors.componentNoResultsSvgAccentPuddle}
				/>
				<path
					d="M12.468 216.41C13.1568 211.779 15.0549 208.454 17.2839 208.454C19.2851 208.454 21.0151 211.117 21.8503 215"
					stroke={theme.colors.componentNoResultsSvgAccentWhite99}
					strokeWidth="1.08465"
					strokeMiterlimit="10"
					strokeLinecap="round"
				/>
				<path
					opacity="0.6"
					d="M16.5247 226.459C15.6027 228.601 14.3554 229.925 12.9182 229.925C11.481 229.925 10.2391 228.601 9.31714 226.459"
					stroke={theme.colors.componentNoResultsSvgAccentWhite99}
					strokeWidth="1.08465"
					strokeMiterlimit="10"
				/>
				<path
					d="M6.80623 235.76C5.28771 231.872 4.34949 226.481 4.34949 220.515"
					stroke={theme.colors.componentNoResultsSvgAccentWhite99}
					strokeWidth="1.08465"
					strokeMiterlimit="10"
					strokeLinecap="round"
				/>
				<path
					d="M11.7468 206.046C13.1786 203.031 15.0008 201.225 16.9965 201.225C21.5846 201.225 25.2996 210.835 25.2996 222.685C25.3041 224.678 25.1973 226.669 24.9796 228.65"
					stroke={theme.colors.componentNoResultsSvgAccentWhite99}
					strokeWidth="1.08465"
					strokeMiterlimit="10"
					strokeLinecap="round"
				/>
				<path
					opacity="0.83"
					d="M20.7277 185.763C28.1792 190.036 33.6567 204.424 33.6567 221.519C33.6861 227.399 32.9922 233.26 31.5905 238.971"
					stroke={theme.colors.brandPrimary}
					strokeOpacity="0.44"
					strokeWidth="1.08465"
					strokeMiterlimit="10"
				/>
				<path
					opacity="0.45"
					d="M25.0663 185.763C32.5178 190.036 36.9107 204.424 36.9107 221.519C36.9401 227.399 36.2461 233.26 34.8444 238.971"
					stroke={theme.colors.brandPrimary}
					strokeOpacity="0.44"
					strokeWidth="1.08465"
					strokeMiterlimit="10"
				/>
				<path
					d="M106.936 239.025C102.467 239.507 95.1186 242.751 89.4188 242.68C80.9476 242.582 67.3949 239.22 49.5252 239.025C31.6556 238.83 14.274 238.884 8.94836 240.527C9.94357 242.714 11.1631 244.791 12.5874 246.726C13.4379 247.793 14.5066 248.665 15.722 249.286L119.849 256.878C114.474 253.733 109.919 247.344 106.936 239.025Z"
					fill="url(#paint2_linear_146_697)"
				/>
				<path
					d="M20.7657 250.435C12.0126 250.435 4.87012 235.641 4.87012 217.956C4.87012 200.27 11.9637 185.926 20.7169 185.926"
					stroke={theme.colors.brandPrimary}
					strokeWidth="1.62698"
					strokeMiterlimit="10"
				/>
				<path
					d="M107.272 239.063C106.231 239.177 105.173 239.063 103.823 239.404C102.695 239.692 101.654 240.554 100.385 240.901C99.1155 241.248 97.8682 241.031 96.6642 241.33C95.4603 241.628 94.3322 242.414 93.1499 242.626C91.8592 242.837 90.5739 242.425 89.4242 242.414C87.1301 242.414 84.4293 242.658 81.4031 242.279C78.9844 241.98 76.3975 241.064 73.5286 240.684C71.0393 240.353 68.3168 240.559 65.5022 240.267C62.9695 239.995 60.3501 239.22 57.5571 239.046C54.9919 238.889 52.3019 239.323 49.5252 239.296C46.8732 239.263 44.1779 238.705 41.4771 238.699C38.7763 238.694 36.0538 239.242 33.429 239.28C30.6577 239.28 27.9406 238.808 25.37 238.878C22.4902 238.954 19.8003 239.6 17.349 239.735C14.1764 239.909 11.3834 239.594 9.27917 239.887"
					stroke={theme.colors.brandPrimary}
					strokeWidth="1.62698"
					strokeMiterlimit="10"
				/>
				<g opacity="0.15">
					<path
						opacity="0.15"
						d="M4.05658 217.956C4.05658 209.278 5.73236 201.106 8.78024 194.95C10.4072 191.631 12.365 189.104 14.4964 187.439L13.4876 187.553C12.1842 187.765 11.0056 188.453 10.1794 189.484C6.98514 193.513 1.62695 202.64 1.62695 218.547C1.62695 233.889 6.69229 242.952 9.70762 246.992C10.2095 247.661 10.8593 248.205 11.6063 248.581C12.3533 248.958 13.1773 249.156 14.0137 249.161H14.133L14.9953 249.226C8.61754 244.513 4.05658 232.273 4.05658 217.956Z"
						fill={theme.colors.brandPrimary}
					/>
				</g>
				<path
					opacity="0.6"
					d="M108.395 182.037L33.4181 191.224C35.1343 195.107 36.4534 199.154 37.3554 203.302L101.784 199.506C103.036 193.366 105.268 187.468 108.395 182.037V182.037Z"
					fill={theme.colors.white}
				/>
				<path
					opacity="0.81"
					d="M100.244 214.512C100.244 212.758 100.303 211.03 100.423 209.327C92.7487 209.229 54.0754 208.785 38.4456 209.327C38.6806 211.015 38.8613 212.738 38.9879 214.495C54.4876 215.314 91.3224 218.492 100.417 219.946C100.302 218.174 100.244 216.363 100.244 214.512Z"
					fill={theme.colors.white}
				/>
			</g>
			<defs>
				<linearGradient
					id="paint0_linear_146_697"
					x1="75.9582"
					y1="173.772"
					x2="75.9582"
					y2="258.272"
					gradientUnits="userSpaceOnUse">
					<stop stopColor={theme.colors.componentNoResultsSvgAccentLight} />
					<stop offset="0.73" stopColor={theme.colors.white} />
				</linearGradient>
				<linearGradient
					id="paint1_linear_146_697"
					x1="151.136"
					y1="190.123"
					x2="104.599"
					y2="236.666"
					gradientUnits="userSpaceOnUse">
					<stop stopColor={theme.colors.componentNoResultsSvgAccentLight} />
					<stop offset="0.5" stopColor={theme.colors.white} />
				</linearGradient>
				<linearGradient
					id="paint2_linear_146_697"
					x1="63.9891"
					y1="253.478"
					x2="65.5672"
					y2="235.402"
					gradientUnits="userSpaceOnUse">
					<stop stopColor={theme.colors.componentNoResultsSvgAccentLight} />
					<stop offset="0.37" stopColor={theme.colors.white} />
				</linearGradient>
				<clipPath id="clip0_146_697">
					<rect width="299.847" height="319.94" fill={theme.colors.white} />
				</clipPath>
			</defs>
		</IconWrapper>
	);
};

export default NoSearchResultsIcon;
