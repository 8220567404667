import * as React from 'react';
import NormalIconWrapper, { NormalIconWrapperProps } from '../NormalIconWrapper';
import { ThemeContext } from 'styled-components';

const SendToMailIcon: React.FC<NormalIconWrapperProps & React.SVGAttributes<SVGElement>> = ({ color, ...props }) => {
	const theme = React.useContext(ThemeContext);
	return (
		<NormalIconWrapper {...props}>
			<path
				fill={color ?? theme.colors.brandPrimary}
				d="M2 21V6.321l9.673 9.143c.378.357.852.536 1.327.536.474 0 .949-.179 1.327-.536L24 6.321 24.001 21H2zM22.501 5l-9.454 9.011L3.514 5h18.987zM24 3H2C.897 3 0 3.897 0 5v16c0 1.103.897 2 2 2h22c1.103 0 2-.897 2-2V5c0-1.103-.897-2-2-2z"
			/>
		</NormalIconWrapper>
	);
};

export default SendToMailIcon;
