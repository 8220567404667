import * as React from 'react';
import { Component } from 'react';
import { RecipeCardInterface } from '../../../interfaces';
import styled from 'styled-components';
import { RecipeCreatorType } from '../../../types';
import RecipeCardImageBadge from './RecipeCardImageBadge';
import RecipeCardInfo from './RecipeCardInfo';
import RecipeCardTitle from './RecipeCardTitle';
import RecipeCardOverlay from './RecipeCardOverlay';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { LazyImage } from '../../LazyImage';
import { CardWrapper, CardImage } from '../Card';
import ValioOrganicIcon from '../../Icons/MiscIcons/ValioOrganicIcon';
import NoveltyIcon from '../../Icons/AspectGroupIcons/NoveltyIcon';
import { formatTotalTime } from '../utils';
import Image from '../../PicturesAndVideos/Image';
import { ResponsiveValues } from 'common/components/Media';
import { ContentThemeContext } from 'common/contexts/ContentThemeContext';

const RecipeCardInfoGradient = styled.div`
	position: absolute;
	width: 100%;
	height: 25%;
	bottom: 0;
	background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
`;

interface RecipeCardOwnProps {
	useLazyImage?: boolean;
	hideRecipeInfo?: boolean;
	isVisible?: ResponsiveValues<boolean>;
}

type RecipeCardProps = RecipeCardOwnProps &
	RecipeCardInterface &
	WrappedComponentProps &
	React.AnchorHTMLAttributes<HTMLAnchorElement>;

class RecipeCard extends Component<RecipeCardProps> {
	public static defaultProps = {
		rating: 0,
		totalTime: { minutes: 0 },
		recipeName: '',
		type: RecipeCreatorType.valio,
		badgeText: '',
		useLazyImage: true,
	};

	public render() {
		const {
			url,
			rating,
			totalTime,
			imageUrl,
			recipeName,
			type,
			isOrganic,
			isNovelty,
			className,
			useLazyImage,
			hideRecipeInfo,
			isVisible,
			language,
			...rest
		} = this.props;
		const showBadge = this.shouldShowBadge();

		let image = <Image src={imageUrl} alt={recipeName} />;
		if (useLazyImage) {
			image = <LazyImage src={imageUrl} alt={recipeName} />;
		}

		return (
			<ContentThemeContext.Consumer>
				{contentTheme => (
					<CardWrapper
						{...rest}
						href={url}
						className={`recipe-card ${className ? className : ''}`}
						isVisible={isVisible}>
						<CardImage className="recipe-card-image-wrapper" image={image}>
							<RecipeCardOverlay showBadge={showBadge}>
								{showBadge && (
									<RecipeCardImageBadge type={type || RecipeCreatorType.valio} badgeText={this.getBadgeText()} />
								)}
								<RecipeCardInfoGradient />
								{!hideRecipeInfo && (
									<RecipeCardInfo
										showRating={rating !== null && type === RecipeCreatorType.valio}
										rating={rating}
										cookingTime={formatTotalTime(totalTime)}
									/>
								)}
								{isOrganic && <ValioOrganicIcon className="organic-icon" />}
								{isNovelty && <NoveltyIcon className="novelty-icon" language={language} />}
							</RecipeCardOverlay>
						</CardImage>
						<RecipeCardTitle className="recipe-card-title" color={contentTheme.color}>
							{recipeName}
						</RecipeCardTitle>
					</CardWrapper>
				)}
			</ContentThemeContext.Consumer>
		);
	}

	private shouldShowBadge(): boolean {
		const { badgeText, type } = this.props;
		return (
			Boolean(badgeText) ||
			(type !== null && type !== RecipeCreatorType.valio && type !== RecipeCreatorType.countrySites) ||
			false
		);
	}

	private getBadgeText() {
		const { badgeText, type, intl } = this.props;
		if (badgeText) {
			return badgeText;
		}
		if (type === RecipeCreatorType.user) {
			return intl.formatMessage({ id: 'recipe_type_user' });
		}
		if (type === RecipeCreatorType.cooperation) {
			return intl.formatMessage({ id: 'recipe_type_cooperation' });
		}
		return '';
	}
}

export default injectIntl(RecipeCard);
