import * as React from 'react';
import { connect, MapStateToProps } from 'react-redux';
import { Helmet } from 'react-helmet';
import { State } from '../../reducers';
import { getFacebookAppId, getTwitterUserNameWithAtSign } from '../App/services';

export interface MetaProps {
	canonicalUrl?: string;
	title?: string;
	description?: string;
	image?: string;
}

const Meta: React.FC<MetaProps & MetaStateProps> = ({
	canonicalUrl,
	title,
	description,
	image,
	facebookAppId,
	twitterUserName,
}) => {
	if (!canonicalUrl) {
		return null;
	}

	return (
		<Helmet>
			<link rel="canonical" href={canonicalUrl} />
			<meta name="twitter:card" content="summary" />
			{twitterUserName && <meta name="twitter:site" content={twitterUserName} />}
			<meta property="og:title" content={title} />
			<meta property="og:type" content="website" />
			<meta property="og:description" content={description || ''} />
			<meta property="og:url" content={canonicalUrl} />
			{image && <meta property="og:image" content={`${image}/og-image`} />}
			{image && <meta property="og:image:width" content="1200" />}
			{image && <meta property="og:image:height" content="900" />}
			<meta property="og:image:alt" content={title} />
			{facebookAppId && <meta property="fb:app_id" content={facebookAppId} />}
		</Helmet>
	);
};

interface MetaStateProps {
	facebookAppId: string | undefined;
	twitterUserName: string | undefined;
}

const mapStateToProps: MapStateToProps<MetaStateProps, {}, State> = (state: State): MetaStateProps => {
	return {
		facebookAppId: getFacebookAppId(state),
		twitterUserName: getTwitterUserNameWithAtSign(state),
	};
};

export default connect(mapStateToProps)(Meta);
