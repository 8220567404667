import * as React from 'react';
import SearchResultList from './SearchResultList';
import SearchCategoryTitle from './SearchCategoryTitle';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { NavLinkItemProps } from '../../interfaces';

const SearchResultGroup = styled.div`
	&:not(:first-child) {
		margin-top: 10px;
	}
`;

export interface MostPopularKeywordsProps {
	mostPopularKeywords: NavLinkItemProps[];
	itemClicked: (item: NavLinkItemProps) => void;
}

const MostPopularKeywords: React.FC<MostPopularKeywordsProps> = ({ mostPopularKeywords, itemClicked }) => {
	return (
		<SearchResultGroup>
			<SearchCategoryTitle>
				<FormattedMessage id="search_most_searched" />
			</SearchCategoryTitle>
			<SearchResultList items={mostPopularKeywords} itemClicked={itemClicked} />
		</SearchResultGroup>
	);
};

export default MostPopularKeywords;
