import * as React from 'react';
import OrderFooter from './OrderFooter';
import { useIntl, FormattedMessage } from 'react-intl';
import { Order } from '../interfaces';
import styled from 'styled-components';
import MediumHeader from 'styleguide/components/Typography/MediumHeader';
import { Cart, Item, ItemLink } from './OrderCart';
import { media } from 'styleguide/helpers';

const Wrapper = styled.div`
	width: 100%;

	.medium-header {
		margin: ${({ theme }) => theme.grid.gutterInPx(5)} 0 0;
		text-align: left;

		&:first-child {
			margin: 0;
		}
	}
`;

const ItemDetail = styled(ItemLink)`
	color: ${({ theme }) => theme.colors.bodyText};
	flex: 1 1 auto;
	width: auto;

	&:hover {
		color: ${({ theme }) => theme.colors.bodyText};
	}

	&:last-child {
		text-align: right;
	}
`;

const DeliveryInformation = styled.div`
	background: ${({ theme }) => theme.colors.contentSectionHiglighted};
	margin-top: ${({ theme }) => theme.grid.gutterInPx(3)};
	padding: ${({ theme }) => theme.grid.gutterInPx(3)};
	border-radius: 4px;
	font-size: 16px;
	line-height: 22px;

	${media.tablet`
		font-size: 20px;
		line-height: 28px;
		display: flex;
	`};
`;

const DeliveryColumn = styled.div`
	margin-top: ${({ theme }) => theme.grid.gutterInPx()};

	${media.tablet`
		margin-top: 0;
		margin-left: ${({ theme }) => theme.grid.gutterInPx(3)};
		flex: 1 1 auto;
		flex: 1 1 calc(50% - ${({ theme }) => theme.grid.gutterInPx(3)});
		width: calc(50% - ${({ theme }) => theme.grid.gutterInPx(3)});

		&:first-child {
			margin-left: 0;
		}
	`};
`;

interface Props {
	order: Order;
	onClearOrder: () => void;
}

const OrderConfirmation: React.FC<Props> = ({ order, onClearOrder }) => {
	const intl = useIntl();

	const {
		items,
		delivery: { data: deliveryData },
	} = order;
	const {
		firstName,
		lastName,
		streetAddress: address,
		zipCode: postCode,
		city,
		email,
		phoneNumber: phone,
		companyName: company,
	} = deliveryData ?? {};

	const onBackClick = () => {
		onClearOrder();
	};

	return (
		<Wrapper>
			<MediumHeader className="medium-header">
				<FormattedMessage id="materials_search_confirmation_title" />
			</MediumHeader>

			<Cart>
				{items.map(({ id, title, quantity }) => (
					<Item key={`cart-item-${id}`}>
						<ItemDetail as="span">{title}</ItemDetail>
						<ItemDetail as="span">
							{quantity} <FormattedMessage id="unit_pcs" />
						</ItemDetail>
					</Item>
				))}
			</Cart>

			<MediumHeader className="medium-header">
				<FormattedMessage id="materials_search_delivery_title" />
			</MediumHeader>

			<DeliveryInformation>
				<DeliveryColumn>
					{firstName} {lastName}
					<br />
					{address}
					<br />
					{postCode} {city}
				</DeliveryColumn>
				<DeliveryColumn>
					{email}
					<br />
					{phone}
					<br />
					{company}
				</DeliveryColumn>
			</DeliveryInformation>

			<OrderFooter
				backButtonLabel={intl.formatMessage({ id: 'materials_search_order_back_to_search' })}
				onBackClick={onBackClick}
			/>
		</Wrapper>
	);
};

export default OrderConfirmation;
