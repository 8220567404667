import * as React from 'react';
import { IconWrapperProps } from '../../../interfaces';
import IconWrapper from '../IconWrapper';

const SocialIconWrapper: React.FC<IconWrapperProps & React.SVGAttributes<SVGElement>> = ({ children, ...rest }) => {
	return <IconWrapper {...rest}>{children}</IconWrapper>;
};

SocialIconWrapper.defaultProps = {
	size: 40,
	viewBox: '0 0 30 30',
};

export default SocialIconWrapper;
