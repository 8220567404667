import { put, call, takeLatest, takeEvery, fork } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { RecipeApi, recipeApi } from '../services/services';
import { recipeRatingActions } from '../reducers/recipe-rating';
import { scaleActions } from '../reducers/scale';
import { portionNutrientsActions } from '../reducers/portion-nutrients';
import { getWeightInGrams } from '../utils';

function* updateRating(api: RecipeApi, { payload }: ReturnType<typeof recipeRatingActions.updateRating>) {
	const { recipeId, rating } = payload;
	try {
		const ratingResult = yield call(api.addRating, recipeId, rating);

		yield put(recipeRatingActions.ratingSaved(ratingResult.averageRating, ratingResult.ratingCount));
	} catch (e) {
		yield put(recipeRatingActions.updateRatingError(e));
	}
}

function* watchUpdateRatingSaga() {
	yield takeEvery(getType(recipeRatingActions.updateRating), updateRating, recipeApi);
}

function* changePortion(api: RecipeApi, { payload }: ReturnType<typeof scaleActions.scaleRecipe>) {
	const { recipeId, quantity, weight, siteUrlId } = payload;
	try {
		const portionResult = yield call(api.changePortion, siteUrlId, recipeId, quantity, weight);
		yield put(scaleActions.updateScaledRecipe(portionResult, quantity, weight));
		if (weight) {
			yield put(portionNutrientsActions.fetchScaledNutrients(recipeId, getWeightInGrams(weight)));
		}
	} catch (e) {
		yield put(scaleActions.scaleFailed(e));
	}
}

function* watchChangePortionSaga() {
	yield takeLatest(getType(scaleActions.scaleRecipe), changePortion, recipeApi);
}

function* changePortionNutrients(
	api: RecipeApi,
	{ payload }: ReturnType<typeof portionNutrientsActions.fetchScaledNutrients>
) {
	const { recipeId, portionWeight } = payload;
	try {
		const result = yield call(api.updateNutrients, recipeId, portionWeight);
		yield put(portionNutrientsActions.updateScaledNutrients(result));
	} catch (e) {
		yield put(portionNutrientsActions.scaledNutrientsFailed(e));
	}
}

function* watchChangePortionNutrientsSaga() {
	yield takeLatest(getType(portionNutrientsActions.fetchScaledNutrients), changePortionNutrients, recipeApi);
}

export default function* recipeSaga() {
	yield fork(watchUpdateRatingSaga);
	yield fork(watchChangePortionSaga);
	yield fork(watchChangePortionNutrientsSaga);
}
