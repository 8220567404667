import * as React from 'react';
import * as maitoJaMeLogo from './mameLogo.svg';

export interface MaitoJaMeLogoIconProps {
	disableScaling?: boolean;
	logoSize?: string;
	inverted?: boolean;
}

const MaitoJaMeLogoIcon: React.FC<MaitoJaMeLogoIconProps> = ({ disableScaling, logoSize }) => {
	return <img src={maitoJaMeLogo} alt="MaitoJaMe" width={disableScaling && logoSize ? logoSize : '100%'} />;
};

export default MaitoJaMeLogoIcon;
