import * as React from 'react';
import styled from 'styled-components';
import { media } from 'styleguide/helpers/media';
import CloseIcon from 'styleguide/components/Icons/NormalIcons/CloseIcon/CloseIcon';
import IconButton from 'styleguide/components/Buttons/IconButton/IconButton';
import RecipeIndicator, { RecipeIndicatorProps } from './ShoppingListRecipeIndicator';
import { ShoppingListApiItem } from '../services/shopping-list';

interface ItemNameProps {
	isPicked: boolean;
}

const ItemName = styled.span<ItemNameProps>`
	color: ${props => props.theme.colors.bodyText};
	/* IE */
	text-decoration: ${props => (props.isPicked ? `line-through` : 'none')};
	text-decoration: ${props => (props.isPicked ? `line-through ${props.theme.colors.brandPrimary}` : 'none')};
	cursor: pointer;
`;

const ItemQuantity = styled.span`
	text-align: right;
	margin-left: 10px;

	${media.tablet`
		white-space: nowrap;
	`};
`;

const ItemWrapper = styled.li<RecipeIndicatorProps & { isReadOnly?: boolean }>`
	font-family: ${props => props.theme.fonts.secondary};
	font-size: 18px;
	width: 100%;
	position: relative;
	display: flex;
	justify-content: space-between;
	min-height: 40px;
	padding: ${({ isReadOnly }) => (isReadOnly ? '0 15px' : '0 40px 0 15px')};

	${media.tablet`
		line-height: 40px;
	`};

	@media print {
		font-size: 11pt;
		line-height: 13pt;
		padding: 0 0 0 15pt;
		min-height: auto;

		&:before {
			content: '';
			width: 6pt;
			height: 6pt;
			background: ${props => props.theme.colors[props.itemColour || 'shoppingListBlue']};
			border-radius: 50%;
			margin-left: -15pt;
			margin-right: 6pt;
			margin-top: 3pt;
		}
	}

	.recipe-indicator {
		flex: 0 0 8px;

		@media print {
			display: none;
		}
	}

	.close-icon {
		position: absolute;
		right: 0;
		top: -4px;
		flex: 0 0 20px;

		svg {
			width: 20px;
		}

		${media.tablet`
			top: 2px;
		`};

		@media print {
			display: none;
		}
	}

	${ItemName} {
		flex: 1 1 440px;
		flex: 1 1 75%;
	}

	${ItemQuantity} {
		flex: 1 1 145px;
		flex: 1 1 25%;
	}
`;

interface ShoppingListItemProps {
	isReadOnly?: boolean;
	onItemRemove?: (itemId: string) => void;
	onToggleItemPicked: (itemId: string, isPicked: boolean) => void;
}

type Props = ShoppingListItemProps & RecipeIndicatorProps & ShoppingListApiItem;

class ShoppingListItem extends React.Component<Props> {
	public render() {
		const { title, quantityString, itemColour, ignored, isReadOnly } = this.props;
		return (
			<ItemWrapper itemColour={itemColour} isReadOnly={isReadOnly}>
				{itemColour && <RecipeIndicator itemColour={itemColour} className="recipe-indicator" />}
				<ItemName isPicked={ignored} onClick={this.onToggleItemPicked}>
					{title}
				</ItemName>
				{quantityString && <ItemQuantity>{quantityString}</ItemQuantity>}
				{!isReadOnly && <IconButton icon={<CloseIcon />} className="close-icon" onClick={this.onItemRemove} />}
			</ItemWrapper>
		);
	}

	private onItemRemove = () => {
		const { onItemRemove, id } = this.props;
		if (onItemRemove) {
			onItemRemove(id);
		}
	};

	private onToggleItemPicked = () => {
		const { onToggleItemPicked, id, ignored } = this.props;
		onToggleItemPicked(id, !ignored);
	};
}

export default ShoppingListItem;
