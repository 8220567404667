import { getType } from 'typesafe-actions';
import { call, put, fork, takeLatest } from 'redux-saga/effects';
import { NewsArchiveApi, newsArchiveApi } from './services';
import { newsArchiveActions } from './reducers';

function* doGetNewsArchive(siteUrlId: string, api: NewsArchiveApi) {
	const newsArchiveResult = yield call(api.getNewsArchiveItems, siteUrlId);
	yield put(newsArchiveActions.getArchiveSuccess(newsArchiveResult));
}

function* getNewsArchive(api: NewsArchiveApi, { siteUrlId }: ReturnType<typeof newsArchiveActions.getArchive>) {
	yield call(doGetNewsArchive, siteUrlId, api);
}

export function* watchGetNewsArchive() {
	yield takeLatest(getType(newsArchiveActions.getArchive), getNewsArchive, newsArchiveApi);
}

export default function* newsArchiveSaga() {
	yield fork(watchGetNewsArchive);
}
