import { DefaultTheme, createGlobalStyle } from 'styled-components';
import valio from './valio';
import oddlygood from './oddlygood';
import valiocom from './valiocom';
import valiobaltia from './valiobaltia';
import finlandiacheese from './finlandiacheese';
import maitojame from './maitojame';
import maidonjalostajat from './maidonjalostajat';
import aimo from './aimo';
import valiooddlygood from './valiooddlygood';

import matterbold from './fonts/Matter-Bold.otf';
import matterregular from './fonts/Matter-Regular.otf';
import national2 from './fonts/National2Compressed-Bold.otf';
import futuraPassata from './fonts/FuturaPassata.otf';

export type Theme =
	| 'valio'
	| 'oddlygood'
	| 'valiocom'
	| 'finlandiacheese'
	| 'valiobaltia'
	| 'maitojame'
	| 'maidonjalostajat'
	| 'aimo'
	| 'valiooddlygood';

export const defaultTheme: Theme = 'valio';

export const themes: { [key in Theme]: DefaultTheme } = {
	valio,
	oddlygood,
	valiocom,
	finlandiacheese,
	valiobaltia,
	maitojame,
	maidonjalostajat,
	aimo,
	valiooddlygood,
};

export const GlobalStyle = createGlobalStyle`
	* {
		box-sizing: border-box;
	}
	html {
		background-color: ${({ theme }) => theme.colors.bodyBackground};
	}
	@font-face {
		font-family: 'NationalCompressed2Bold';
		src: url(${national2}) format('opentype');
		font-weight: bold;
		font-style: normal;
	}
	@font-face {
		font-family: 'Matter Regular';
		src: url(${matterregular}) format('opentype');
		font-weight: normal;
		font-style: normal;
	}
	@font-face {
		font-family: 'Matter Bold';
		src: url(${matterbold}) format('opentype');
		font-weight: bold;
		font-style: normal;
	}		
	@font-face {
		font-family: 'FuturaPassata';
		src: url(${futuraPassata}) format('opentype');
		font-weight: normal;
		font-style: normal;
	}		
`;
