import * as React from 'react';
import styled from 'styled-components';
import * as aimoLogo from './aimologo.svg';
import * as aimoLogoInverted from './aimologoInverted.svg';
import { media } from '../../../helpers/media';

export interface AimoLogoIconProps {
	disableScaling?: boolean;
	logoSize?: string;
	inverted?: boolean;
	noBorder?: boolean;
}

interface AimoLogoWrapperProps {
	noBorder?: boolean;
	inverted?: boolean;
}

const AimoLogoWrapper = styled.div<AimoLogoWrapperProps>`
	background-color: ${({ noBorder, inverted, theme }) =>
		inverted ? theme.colors.brandPrimary : noBorder ? 'inherit' : 'white'};
	padding: 4px 8px 4px 8px;
	${({ noBorder }) =>
		!noBorder &&
		media.desktop`
		padding: 8px 16px 8px 16px;
	`};
	${({ noBorder }) => noBorder && 'padding: 0px;'};
	border-radius: 5px;
`;

const AimoLogoIcon: React.FC<AimoLogoIconProps> = ({ disableScaling, logoSize, noBorder, inverted }) => {
	return (
		<AimoLogoWrapper noBorder={noBorder} inverted={inverted}>
			<img
				src={inverted ? aimoLogoInverted : aimoLogo}
				alt="Valio Aimo"
				width={disableScaling && logoSize ? logoSize : '100%'}
			/>
		</AimoLogoWrapper>
	);
};

export default AimoLogoIcon;
