export enum ValidationPatterns {
	EMAIL = "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$",
	MOBILE = '^0{1}[0-9]{3,10}$',
}

export const validateRequired = (error?: string) => {
	return (value: string) => {
		return value.trim().length > 0 ? '' : error || 'forms_validation_required';
	};
};

export const validateEmail = (value: string) => {
	const re = new RegExp(ValidationPatterns.EMAIL, 'i');
	return re.test(value) ? '' : 'forms_validation_email_check';
};

export const validateMobile = (value: string) => {
	const re = new RegExp(ValidationPatterns.MOBILE, 'i');
	return re.test(value) ? '' : 'forms_validation_phone_check';
};

export const validateEmailOrMobile = (value: string) => {
	const emailRe = new RegExp(ValidationPatterns.EMAIL, 'i');
	const mobileRe = new RegExp(ValidationPatterns.MOBILE, 'i');

	const isValidEmail = emailRe.test(value);
	const isValidMobile = mobileRe.test(value);

	if (!isValidEmail && !isValidMobile) {
		return 'forms_validation_email_or_phone_required';
	}

	return '';
};

export const validateTerms = (value: string) => {
	if (!value) {
		return 'forms_validation_accept_terms';
	}

	return '';
};
