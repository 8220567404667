import * as React from 'react';
import InputWithIcon from 'styleguide/components/Inputs/InputWithIcon/InputWithIcon';
import SendToMailIcon from 'styleguide/components/Icons/NormalIcons/SendToMailIcon/SendToMailIcon';
import Button from 'styleguide/components/Buttons/Button/Button';
import Alert from 'styleguide/components/Typography/Alert/Alert';
import { AlertType } from 'styleguide/components/Typography/Alert/Alert';
import { injectIntl, WrappedComponentProps, FormattedMessage } from 'react-intl';
import { MapStateToProps, connect } from 'react-redux';
import { InputShape, FormValidationError } from '../../Forms/interfaces';
import injectForm, { InjectedFormProps, FormProps } from '../../Forms/Form';
import { ValidationPatterns, validateRequired, validateTerms } from '../../Forms/helpers';
import { State } from '../../../reducers';
import { KeyIcon, UserIcon } from 'styleguide/components/Icons';
import ModalForm from 'common/components/General/ModalForm';
import Input from 'styleguide/components/Inputs/Input';
import Checkbox from 'styleguide/components/Inputs/Checkbox';
import Divider, { DividerDirection, DividerSize } from 'styleguide/components/Divider/Divider';

export interface RegistrationFormFields {
	email: InputShape<string>;
	hash: InputShape<string>;
	userName: InputShape<string>;
	password: InputShape<string>;
	passwordAgain: InputShape<string>;
	subscribeNewsletter: InputShape<boolean>;
	terms: InputShape<boolean>;
}

export interface RegistrationFormValidators {
	email: Array<(value: string) => FormValidationError | string>;
	hash: Array<(value: string) => FormValidationError | string>;
	userName: Array<
		(value: string, fields?: RegistrationFormFields, props?: RegistrationFormProps) => FormValidationError | string
	>;
	password: Array<(value: string) => FormValidationError | string>;
	passwordAgain: Array<(value: string, fields?: RegistrationFormFields) => FormValidationError | string>;
	subscribeNewsletter: Array<(value: string) => FormValidationError | string>;
	terms: Array<(value: string) => FormValidationError | string>;
}

export interface RegistrationFormExternalValidators {
	userName: (value: string, fields: RegistrationFormFields) => string;
}

interface RegistrationFormProps extends FormProps<RegistrationFormFields> {
	uniqueUserName?: string;
	uniqueUserNameError?: string;
	onGetUniqueUserName: (userName: string) => void;
}

type Props = RegistrationFormProps &
	InjectedFormProps<RegistrationFormFields> &
	WrappedComponentProps &
	RegisterFormStateProps;

class RegistrationForm extends React.Component<Props> {
	public componentDidMount() {
		const { email, hash, onInputChange } = this.props;
		onInputChange(email, 'email');
		onInputChange(hash, 'hash');
	}

	public componentDidUpdate(prevProps: Props) {
		const { uniqueUserName, uniqueUserNameError, fields, onInputBlur } = this.props;
		if (prevProps.uniqueUserName !== uniqueUserName || prevProps.uniqueUserNameError !== uniqueUserNameError) {
			onInputBlur(fields.userName.value, 'userName');
		}
	}

	public render() {
		const { onSubmit, fields, intl, isValid, isLoading, error, email, hash } = this.props;
		const { userName, password, passwordAgain, subscribeNewsletter, terms } = fields;

		return (
			<ModalForm onSubmit={onSubmit} noValidate>
				<InputWithIcon
					icon={<SendToMailIcon />}
					type="email"
					name="email"
					value={email}
					placeholder={intl.formatMessage({ id: 'forms_email_address' })}
					required={true}
					autoComplete="off"
					pattern={ValidationPatterns.EMAIL}
					disabled
				/>

				<Divider direction={DividerDirection.horizontal} size={DividerSize.full} className="divider" />

				<InputWithIcon
					icon={<UserIcon />}
					type="text"
					name="userName"
					value={userName.value}
					validationError={userName.touched ? userName.error : ''}
					label={intl.formatMessage({ id: 'user_username' })}
					placeholder={intl.formatMessage({ id: 'user_username' })}
					helpText={intl.formatMessage({ id: 'user_username_help_text' })}
					required={true}
					autoComplete="off"
					onChange={this.onChange}
					onFocus={this.onFocus}
					onBlur={this.onUserNameBlur}
				/>
				<InputWithIcon
					icon={<KeyIcon />}
					type="password"
					name="password"
					value={password.value}
					validationError={password.touched ? password.error : ''}
					label={intl.formatMessage({ id: 'forms_password' })}
					placeholder={intl.formatMessage({ id: 'forms_password' })}
					helpText={intl.formatMessage({ id: 'forms_password_help_text' })}
					required={true}
					autoComplete="off"
					onChange={this.onChange}
					onBlur={this.onBlur}
				/>
				<InputWithIcon
					icon={<KeyIcon />}
					type="password"
					name="passwordAgain"
					value={passwordAgain.value}
					validationError={passwordAgain.touched ? passwordAgain.error : ''}
					placeholder={intl.formatMessage({ id: 'forms_password_again' })}
					required={true}
					autoComplete="off"
					onChange={this.onChange}
					onBlur={this.onBlur}
				/>

				<Divider direction={DividerDirection.horizontal} size={DividerSize.full} className="divider" />

				<Checkbox
					name="subscribeNewsletter"
					checked={subscribeNewsletter.value}
					value="1"
					onChange={this.onSubscribeNewsletterChange}
					className="form-field__checkbox--small">
					<FormattedMessage id="user_register_order_valio_newsletter" />
				</Checkbox>

				<Checkbox
					name="terms"
					checked={terms.value}
					value="on"
					onChange={this.onTermsChange}
					validationError={terms.touched ? terms.error : ''}
					className="form-field__checkbox--small">
					<FormattedMessage
						id="component_login_registration_terms_read_accepted"
						values={{
							a: (msg: string) => (
								<a href="/kayttoehdot/" target="_blank">
									{msg}
								</a>
							),
							b: (msg: string) => (
								<a href="/rekisteriseloste/henkilorekisterinrekisteriseloste/" target="_blank">
									{msg}
								</a>
							),
						}}
					/>
				</Checkbox>

				{/* Olen lukenut ja hyväksyn palvelun [https://www.valio.fi/kayttoehdot/](käyttöehdot) ja [https://www.valio.fi/rekisteriseloste/henkilorekisterinrekisteriseloste/](rekisteriselosteen) */}
				<Input type="hidden" name="hash" value={hash} />

				<Button type="submit" disabled={!isValid || isLoading} size="medium">
					<FormattedMessage id="user_register" />
				</Button>

				{error && (
					<Alert
						type={AlertType.Error}
						highlightedText={intl.formatMessage({ id: 'component_login_registration_failed' })}
						className="form-error">
						{intl.formatMessage({ id: error })}
					</Alert>
				)}
			</ModalForm>
		);
	}

	private onChange = (event: React.FormEvent<HTMLInputElement>) => {
		const { value, name } = event.currentTarget;
		this.props.onInputChange(value, name);
	};

	private onFocus = (event: React.FormEvent<HTMLInputElement>) => {
		const { value, name } = event.currentTarget;
		this.props.onInputFocus(value, name);
	};

	private onBlur = (event: React.FormEvent<HTMLInputElement>) => {
		const { value, name } = event.currentTarget;
		this.props.onInputBlur(value, name);
	};

	private onUserNameBlur = (event: React.FormEvent<HTMLInputElement>) => {
		const { onGetUniqueUserName } = this.props;
		const { value } = event.currentTarget;
		onGetUniqueUserName(value);
		this.onBlur(event);
	};

	private onSubscribeNewsletterChange = (event: React.FormEvent<HTMLInputElement>) => {
		const { checked } = event.currentTarget;
		this.props.onInputChange(checked, 'subscribeNewsletter');
	};

	private onTermsChange = (event: React.FormEvent<HTMLInputElement>) => {
		const { checked } = event.currentTarget;
		this.props.onInputChange(checked, 'terms', true);
	};
}

const passwordAgainValidator = (value: string, fields?: RegistrationFormFields): string => {
	if (fields && fields.password) {
		if (value !== fields.password.value) {
			return 'component_login_registration_validation_password_again_invalid';
		}
	}

	return '';
};

const userNameValidator = (
	value: string,
	fields: RegistrationFormFields | undefined,
	props: RegistrationFormProps | undefined
): FormValidationError | string => {
	const { uniqueUserName, uniqueUserNameError } = props ?? {};
	const userName = fields?.userName;

	if (uniqueUserNameError) {
		return uniqueUserNameError;
	}

	if (userName?.touched && uniqueUserName && uniqueUserName !== value && !userName.focused) {
		return {
			id: `component_login_registration_validation_username_already_used`,
			values: {
				uniqueUserName,
			},
		};
	}

	return '';
};

const registrationFields: RegistrationFormFields = {
	email: { value: '' },
	hash: { value: '' },
	userName: { value: '' },
	password: { value: '' },
	passwordAgain: { value: '' },
	subscribeNewsletter: { value: false },
	terms: { value: false },
};

const registrationValidators: RegistrationFormValidators = {
	email: [],
	hash: [],
	userName: [validateRequired('component_login_registration_validation_username'), userNameValidator],
	password: [validateRequired('component_login_validation_password_required')],
	passwordAgain: [validateRequired('component_login_validation_password_again_required'), passwordAgainValidator],
	subscribeNewsletter: [],
	terms: [validateTerms],
};

interface RegisterFormStateProps {
	isLoading?: boolean;
	error?: string;
	email?: string | string[];
	hash?: string | string[];
}

const mapStateToProps: MapStateToProps<RegisterFormStateProps, {}, State> = ({
	login,
	routing,
}): RegisterFormStateProps => {
	const { registrationForm } = login;

	let email;
	let hash;

	if (routing && routing.query) {
		const emailRegExp = new RegExp(ValidationPatterns.EMAIL, 'i');
		email = emailRegExp.test(routing.query.email as string) ? routing.query.email : '';
		hash = routing.query.hash;
	}

	return {
		isLoading: registrationForm && registrationForm.isLoading,
		error: registrationForm && registrationForm.error,
		email,
		hash,
	};
};

export default connect(mapStateToProps)(
	injectForm(registrationFields, registrationValidators)(injectIntl(RegistrationForm))
);
