import { fetchJson, throwMissingSiteUrlIdError } from 'utils/service';
import { trimStart } from 'utils/strings';
import { ensureResourceUrl } from '../Resource/services';
import { NavigationLayoutType } from './reducers';
import { WithContentFamily } from '../../types/contentFamily';

export interface NavigationItem {
	title: string;
	url: string;
	isActive?: boolean;
	isExternal?: boolean;
}

export interface NavigationItemWithLinks extends NavigationItem {
	links: NavigationItem[];
	isSeasonal?: boolean;
	backgroundColor?: string;
	icon?: string;
}

export interface SecondaryNavigationLinkApiShape extends WithContentFamily {
	title: string;
	url: string;
}

export interface SecondaryNavigationApiShape {
	homepageTitle: string;
	homePageUrl: string;
	backgroundColor: string;
	links: SecondaryNavigationLinkApiShape[];
}

export interface NavigationShape {
	layout: NavigationLayoutType;
	primaryNavigation: NavigationItemWithLinks[];
	secondaryNavigation: SecondaryNavigationApiShape;
}

async function fetchNavigation(siteUrlId: string, resourceUrl: string): Promise<NavigationShape> {
	if (!siteUrlId) {
		throwMissingSiteUrlIdError();
	}
	const resourcePart = trimStart(ensureResourceUrl(resourceUrl), '/');
	const response = await fetchJson<NavigationShape>(`/api/sites/${siteUrlId}/navigation/${resourcePart}`);

	return response;
}

export interface NavigationApi {
	fetchNavigation: (siteUrlId: string, resourceUrl: string) => Promise<NavigationShape>;
}

const navigationApi: NavigationApi = {
	fetchNavigation,
};

export default navigationApi;
