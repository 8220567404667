import { fork, put, call, takeLatest } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { ProfileApi, profileApi, ProfileApiShape } from '../services/profile';
import { userActions } from '../reducers/actions';

export function* fetchCurrentProfile(api: ProfileApi) {
	try {
		const result: ProfileApiShape = yield call(api.fetchCurrentProfile);

		yield put(userActions.fetchCurrentProfileSuccess(result));
	} catch (e) {
		yield put(userActions.fetchCurrentProfileError(e));
	}
}

export function* watchFetchCurrentProfileSaga() {
	yield takeLatest(getType(userActions.fetchCurrentProfile), fetchCurrentProfile, profileApi);
}

export function* watchUpdateContactInfoSaga() {
	yield takeLatest(getType(userActions.updateContactInfo), updateContactInfo, profileApi);
}

export function* updateContactInfo(api: ProfileApi, { payload }: ReturnType<typeof userActions.updateContactInfo>) {
	try {
		const result: ProfileApiShape = yield call(api.updateContactInfo, payload);
		yield put(userActions.updateContactInfoSuccess(result));
	} catch (e) {
		const message = e.details ? e.details.message : '';
		yield put(userActions.updateContactInfoError({ title: 'user_profile_saved_error', details: message }));
	}
}

export function* changePassword(api: ProfileApi, { payload }: ReturnType<typeof userActions.changePassword>) {
	try {
		yield call(api.updatePassword, payload);
		yield put(userActions.changePasswordSuccess());
	} catch (e) {
		yield put(userActions.changePasswordError({ title: 'user_profile_password_changed_error', details: e.details }));
	}
}

export function* watchChangePasswordSaga() {
	yield takeLatest(getType(userActions.changePassword), changePassword, profileApi);
}

export function* currentProfileSaga() {
	yield fork(watchFetchCurrentProfileSaga);
	yield fork(watchUpdateContactInfoSaga);
	yield fork(watchChangePasswordSaga);
}
