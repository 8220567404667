import { NameAndValue } from 'common/interfaces/common';
import { SearchFilterItem, SearchFilterSection, SelectedSearchFilter } from './interfaces';
import { AUTHOR_FILTER_MAPPINGS, CONTEXT_FILTER_MAPPINGS, COOKINGPREFERENCE_FILTER_MAPPINGS } from './constants';
import { IntlShape } from 'react-intl';
import { SearchFilterName } from './types';

export const queryToFilters = (query: object): NameAndValue[] => {
	const filters: NameAndValue[] = [];
	Object.keys(query).forEach(key => {
		const value = query[key];
		if (typeof value === 'string') {
			const item = { name: key, value };
			filters.push(item);
		} else if (Array.isArray(value)) {
			const values = value as string[];
			const items = values.map(v => ({ name: key, value: v }));
			filters.push(...items);
		}
	});
	return filters;
};

export const findSelectedFilter = (name: string, value: string) => {
	return (filter: NameAndValue): boolean => filter.name === name && filter.value === value;
};

export const isFilterSelected = (item: SearchFilterItem, selectedFilters: NameAndValue[]): boolean => {
	const { name, value } = item;
	return selectedFilters.find(findSelectedFilter(name, value)) !== undefined;
};

export const getSelectedItems = (items: SearchFilterItem[], selectedFilters: NameAndValue[]): SearchFilterItem[] => {
	let selectedChildren: SearchFilterItem[] = [];
	if (items && items.length > 0) {
		selectedChildren = items.reduce((memo, currentItem) => {
			if (isFilterSelected(currentItem, selectedFilters)) {
				if (currentItem.items.length > 0) {
					return [...memo, currentItem].concat(getSelectedItems(currentItem.items, selectedFilters));
				}
				return [...memo, currentItem];
			}
			return memo;
		}, [] as SearchFilterItem[]);
	}
	return selectedChildren;
};

export const findFilterItem = (
	query: NameAndValue,
	availableFilters: SearchFilterSection[]
): SearchFilterItem | undefined => {
	for (const section of availableFilters) {
		if (section.items) {
			const item = findNestedItem(section.items, query.name, query.value);
			if (item) {
				return item;
			}
		}
	}
	return undefined;
};

export const findFilterGroupItem = (
	intl: IntlShape,
	query: NameAndValue,
	availableFilters: SearchFilterSection[]
): SelectedSearchFilter | undefined => {
	for (const section of availableFilters) {
		if (section.groups) {
			const group = section.groups.find(g => g.name === query.name);
			if (group) {
				const item = group.items.find(i => i.value === query.value);
				if (item) {
					const prefix =
						group.name.indexOf('-eisisalla') > -1
							? intl.formatMessage({ id: 'product_does_not_contain' }).toLowerCase()
							: intl.formatMessage({ id: 'product_contains' }).toLowerCase();
					return {
						...item,
						title: `${prefix}: ${item.title}`,
						name: group.name,
					};
					break;
				}
			}
		}
	}
	return undefined;
};

export const findNestedItem = (
	items: SearchFilterItem[],
	name: string,
	value: string
): SearchFilterItem | undefined => {
	const item = items.find(i => i.name === name && i.value === value);
	if (item) {
		return item;
	}

	for (const i of items) {
		if (i.items && i.items.length > 0) {
			const foundItem: SearchFilterItem | undefined = findNestedItem(i.items, name, value);
			if (foundItem) {
				return foundItem;
			}
		}
	}

	return undefined;
};

export const parseProductSearchFilterToAvailableFilter = (searchFilter: SearchFilterSection): SearchFilterSection => {
	if (searchFilter.items) {
		return {
			...searchFilter,
			items: searchFilter.items.map(item => parseAvailableFiltersWithParent(item)),
		};
	}
	return searchFilter;
};

export const parseAvailableFiltersWithParent = (
	searchFilterItem: SearchFilterItem,
	parent?: NameAndValue
): SearchFilterItem => {
	return {
		...searchFilterItem,
		parent,
		items: searchFilterItem.items.map((item: SearchFilterItem) =>
			parseAvailableFiltersWithParent(item, { name: searchFilterItem.name, value: searchFilterItem.value })
		),
	};
};

export const getDisplayedSelectedFilters = (
	intl: IntlShape,
	selectedFilters: NameAndValue[],
	availableFilters: SearchFilterSection[],
	otherFilters: SelectedSearchFilter[],
	selectedProductSlug?: string,
	selectedProductTitle?: string,
	ingredientsParameterName?: string
): SelectedSearchFilter[] => {
	const displayedFilters: SelectedSearchFilter[] = [];

	// Handle regular filters
	selectedFilters.forEach(selectedFilter => {
		const foundFilterItem = findFilterItem(selectedFilter, availableFilters);
		if (foundFilterItem) {
			const { items, name, value, title } = foundFilterItem;
			const selectedChildren = getSelectedItems(items, selectedFilters);
			if (selectedChildren.length === 0) {
				displayedFilters.push({ name, value, title });
			}
		}
		const foundFilterGroupItem = findFilterGroupItem(intl, selectedFilter, availableFilters);
		if (foundFilterGroupItem) {
			const { name, value, title } = foundFilterGroupItem;
			displayedFilters.push({ name, value, title });
		}
	});

	// Handle recipe product filter
	const recipeProduct = selectedFilters.find(f => f.name === ingredientsParameterName);
	if (recipeProduct && recipeProduct.value === selectedProductSlug) {
		const { name, value } = recipeProduct;
		displayedFilters.push({ name, value, title: selectedProductTitle || '' });
	} else if (recipeProduct) {
		const { name, value } = recipeProduct;
		displayedFilters.push({ name, value, title: value });
	}

	// Handle recipe cooking preference filter
	const cookingPreference = selectedFilters.filter(
		f => f.name === SearchFilterName.difficulty || f.name === SearchFilterName.maxTime
	);
	cookingPreference.forEach(({ name, value }) => {
		const preferenceTitle = COOKINGPREFERENCE_FILTER_MAPPINGS[value];
		if (preferenceTitle) {
			displayedFilters.push({ name, value, title: preferenceTitle });
		}
	});

	// Handle recipe author filter
	const recipeAuthor = selectedFilters.find(f => f.name === SearchFilterName.author);
	if (recipeAuthor) {
		const { name, value } = recipeAuthor;
		const authorTitle = AUTHOR_FILTER_MAPPINGS[value];
		if (authorTitle) {
			displayedFilters.push({ name, value, title: authorTitle });
		}
	}

	// Handle professional recipe recipe type filter
	const context = selectedFilters.find(f => f.name === SearchFilterName.context);
	if (context) {
		const { name, value } = context;
		const contextTitle = CONTEXT_FILTER_MAPPINGS[value];
		if (context) {
			displayedFilters.splice(0, 0, {
				name,
				value,
				title: contextTitle,
			});
		}
	}

	const largePackage = selectedFilters.find(f => f.name === SearchFilterName.bigPackaging);
	if (largePackage) {
		const { name, value } = largePackage;
		if (value === 'nayta') {
			displayedFilters.push({ name, value, title: 'search_filter_package_industrial_sized' });
		} else if (value === 'piilota') {
			displayedFilters.push({ name, value, title: 'search_filter_package_consumer_sized' });
		}
	}

	const takeAway = selectedFilters.find(f => f.name === SearchFilterName.takeaway);
	if (takeAway) {
		const { name, value } = takeAway;
		displayedFilters.push({ name, value, title: 'search_filter_package_take_away' });
	}

	const theme = selectedFilters.find(f => f.name === SearchFilterName.themes);
	if (theme) {
		const { name, value } = theme;
		displayedFilters.push({ name, value, title: 'search_chef_of_the_year_cooperation' });
	}

	const extraFilters = otherFilters.filter(
		other => !displayedFilters.some(disp => disp.name === other.name && disp.value === other.value)
	);

	displayedFilters.push(...extraFilters);

	return displayedFilters;
};

export const getFilterItemAncestors = (
	availableFilters: SearchFilterSection[],
	filterItem: SearchFilterItem,
	parents: SearchFilterItem[] = []
): SearchFilterItem[] => {
	if (filterItem.parent) {
		const parentFilterItem = findFilterItem(filterItem.parent, availableFilters);
		if (parentFilterItem) {
			return getFilterItemAncestors(availableFilters, parentFilterItem, [...parents, parentFilterItem]);
		}
	}

	return parents;
};
