import * as React from 'react';
import { StyledOutlinedButton } from './common';
import { OutlinedButtonProps } from '../../../interfaces';

const ButtonLink = StyledOutlinedButton('a');

const OutlinedButtonLink: React.FC<OutlinedButtonProps & React.AnchorHTMLAttributes<HTMLAnchorElement>> = ({
	children,
	...rest
}) => <ButtonLink {...rest}>{children}</ButtonLink>;

export default OutlinedButtonLink;
