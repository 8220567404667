import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import styled, { css } from 'styled-components';
import { media } from 'styleguide/helpers/media';
import MediumHeader from 'styleguide/components/Typography/MediumHeader/MediumHeader';

interface DialogInnerProps {
	mode: 'form' | 'confirmation';
}

const RegistrationHeader = styled.header<DialogInnerProps>`
	text-align: center;
	margin-bottom: 20px;

	${media.desktop<DialogInnerProps>`
		${props => {
			if (props.mode === 'form') {
				return css`
					text-align: left;
					padding: 0 25px;
				`;
			}
			return null;
		}}
	`};

	.medium-header {
		margin: 0;
	}
`;

const RegistrationWrapper = styled.div<DialogInnerProps>`
	${({ mode, theme }) => {
		if (mode === 'confirmation') {
			return css`
				p {
					text-align: center;
				}

				button {
					margin: ${theme.grid.gutterInPx(3)} auto 0;
				}
			`;
		}
		return null;
	}}

	.facebook-login-wrapper {
		padding: 30px;
		margin-top: 20px;

		${media.tablet`
			margin: 30px 20px 0;
		`};
	}
`;

interface Props extends DialogInnerProps {
	title?: string;
}

const RegistrationRequestDialog: React.FC<Props> = ({ title, mode, children }) => {
	return (
		<>
			<RegistrationHeader mode={mode}>
				<MediumHeader className="medium-header">
					{title ? title : <FormattedMessage id="user_register_as_user" />}
				</MediumHeader>
			</RegistrationHeader>
			<RegistrationWrapper mode={mode}>{children}</RegistrationWrapper>
		</>
	);
};

export default RegistrationRequestDialog;
