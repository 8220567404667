import * as React from 'react';
import { useContext } from 'react';

const statusCodeProps = {
	// tslint:disable-next-line:no-empty
	setStatus: (status: number | undefined) => {},
};

export const StatusCodeContext = React.createContext(statusCodeProps);

export const useStatusCode = (status: number) => {
	const { setStatus } = useContext(StatusCodeContext);

	setStatus(status);
};
