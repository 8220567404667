import * as React from 'react';
import NormalIconWrapper, { NormalIconWrapperProps } from '../NormalIconWrapper';
import { ThemeContext } from 'styled-components';

const PrintIcon: React.FC<NormalIconWrapperProps & React.SVGAttributes<SVGElement>> = ({ color, ...props }) => {
	const theme = React.useContext(ThemeContext);

	return (
		<NormalIconWrapper {...props}>
			<path
				fill={color ?? theme.colors.brandPrimary}
				d="M10 11a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm-4 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm18 5c0 1.103-.897 2-2 2h-1v-3H5v3H4c-1.103 0-2-.897-2-2v-6c0-1.103.897-2 2-2h18c1.103 0 2 .897 2 2v6zM7 24v-7h12l.001 7H7zM7 6V2h12v4H7zm15 0h-1V2c0-1.103-.897-2-2-2H7C5.897 0 5 .897 5 2v4H4c-2.206 0-4 1.794-4 4v6c0 2.206 1.794 4 4 4h1v4c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2v-4h1c2.206 0 4-1.794 4-4v-6c0-2.206-1.794-4-4-4z"
			/>
		</NormalIconWrapper>
	);
};

export default PrintIcon;
