import styled from 'styled-components';

const ValidationError = styled.label`
	font-family: ${props => props.theme.fonts.secondary};
	font-size: 15px;
	line-height: 1;
	color: ${props => props.theme.colors.actionErrorText};
	text-align: center;
	margin-top: 8px;
	display: block;
`;

export default ValidationError;
