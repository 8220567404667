import * as React from 'react';
import styled, { css } from 'styled-components';
import { inPixels, media } from '../../../helpers';
import ValioLogoIcon from './LogoIcon';
import AimoLogoIcon from './AimoLogoIcon';
import OddlyGoodLogoIcon from './OddlyGoodLogoIcon';
import MaitoJaMeLogoIcon from './MaitoJaMeLogoIcon';
import FinlandiaCheeseLogoIcon from './FinlandiaCheeseLogoIcon';
import MaidonjalostajatLogoIcon from './MaidonjalostajatLogoIcon';

const DEFAULT_SIZE = 120;

const LogoWrapper = styled('a')<LogoProps>`
	transition: all ease-out 0.5s;
	pointer-events: auto;
	${props => {
		if (props.hasSecondaryNavigation) {
			return css`
				width: ${inPixels(60)};
			`;
		} else {
			return css`
				width: ${props.disableScaling && props.logoSize ? props.logoSize : inPixels(80)};
			`;
		}
	}};

	${media.phone320`
    z-index: ${props => props.theme.zIndices.navigation};
  `};

	${media.tablet<LogoProps>`
    z-index: 2;
    ${props => {
			if (props.hasSecondaryNavigation) {
				return css`
					width: ${inPixels(60)};
				`;
			} else {
				return css`
					width: ${props.disableScaling && props.logoSize ? props.logoSize : inPixels(80)};
				`;
			}
		}};
  `};

	${media.desktop<LogoProps>`
    width: ${props => (props.logoSize ? props.logoSize : inPixels(DEFAULT_SIZE))};
  `};
`;

const logos = {
	valio: ValioLogoIcon,
	aimo: AimoLogoIcon,
	oddlygood: OddlyGoodLogoIcon,
	maitojame: MaitoJaMeLogoIcon,
	finlandiacheese: FinlandiaCheeseLogoIcon,
	maidonjalostajat: MaidonjalostajatLogoIcon,
};

export type LogoType = 'valio' | 'oddlygood' | 'maitojame' | 'finlandiacheese' | 'aimo' | 'maidonjalostajat';

export interface LogoProps {
	disableScaling?: boolean;
	inverted?: boolean;
	hasSecondaryNavigation?: boolean;
	logoSize?: string;
	href?: string;
	logo?: LogoType;
}

const Logo: React.FC<LogoProps & React.AnchorHTMLAttributes<HTMLAnchorElement>> = props => {
	const { disableScaling, logoSize, hasSecondaryNavigation, logo, inverted, ...rest } = props;

	const LogoIcon = logo ? logos[logo] : logos.valio;

	return (
		<LogoWrapper
			disableScaling={disableScaling}
			logoSize={logoSize}
			hasSecondaryNavigation={hasSecondaryNavigation}
			{...rest}>
			<LogoIcon inverted={inverted} disableScaling={disableScaling} logoSize={logoSize} />
		</LogoWrapper>
	);
};

Logo.defaultProps = {
	logo: 'valio',
	disableScaling: false,
	logoSize: inPixels(DEFAULT_SIZE),
	href: '',
};

export default Logo;
