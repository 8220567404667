import * as React from 'react';
import styled from 'styled-components';
import { HTMLAttributes, useEffect, useRef } from 'react';

export const ItemListContainer = styled.div`
	position: absolute;
	background: white;
	z-index: ${props => props.theme.zIndices.popovers + 1};
	border: 1px solid ${props => props.theme.colors.buttonPrimary};
	border-radius: 0 0 4px 4px;
	border-top: none;
	width: 100%;
	box-sizing: border-box;
`;

export const ItemList = styled.ul`
	padding: ${props => props.theme.grid.gutterInPx()} ${props => props.theme.grid.gutterInPx(2)};
	margin: 0;
	list-style: none;
	max-height: 30vh;
	overflow: auto;
`;

export const Item = styled.li`
	& + & {
		border-top: 1px solid ${props => props.theme.colors.border};
	}
`;

interface OptionProps extends HTMLAttributes<HTMLButtonElement> {
	isSelected?: boolean;
	value?: any;
}

export function Option({ children, isSelected, ...rest }: OptionProps) {
	const ref = useRef<HTMLButtonElement>(null);
	useEffect(() => {
		if (isSelected && ref.current) {
			ref.current.scrollIntoView();
		}
	}, [isSelected]);
	return (
		<OptionButton ref={ref} isSelected={isSelected} {...rest}>
			{children}
		</OptionButton>
	);
}

const OptionButton = styled('button')<OptionProps>`
	background: transparent;
	border: none;
	padding: ${props => props.theme.grid.gutterInPx(1)} 0;
	font-family: ${props => props.theme.fonts.secondary};
	font-size: 14px;
	line-height: 20px;
	color: ${({ isSelected, theme }) => (isSelected ? theme.colors.buttonPrimary : theme.colors.bodyText)};
	display: block;
	text-align: left;
	width: 100%;
	cursor: pointer;

	&:focus,
	&:active {
		outline: none;
	}
`;
