import * as React from 'react';
import styled from 'styled-components';
import MediumHeader from 'styleguide/components/Typography/MediumHeader/MediumHeader';
import BodyText from 'styleguide/components/Typography/BodyText/BodyText';
import Button from 'styleguide/components/Buttons/Button/Button';
import { InputSize } from 'styleguide/types/inputTypes';
import { media } from 'styleguide/helpers/media';
import Alert from 'styleguide/components/Typography/Alert/Alert';
import { AlertType } from 'styleguide/components/Typography/Alert/Alert';
import InputWithIcon from 'styleguide/components/Inputs/InputWithIcon/InputWithIcon';
import SendToMailIcon from 'styleguide/components/Icons/NormalIcons/SendToMailIcon/SendToMailIcon';
import { injectIntl, WrappedComponentProps, FormattedMessage } from 'react-intl';
import injectForm, { InjectedFormProps } from 'common/components/Forms/Form';
import { ValidationPatterns, validateEmail, validateRequired } from 'common/components/Forms/helpers';
import { InputShape } from 'common/components/Forms/interfaces';

const StyledEmailShareForm = styled.form`
	.form-header {
		margin: 0 0 20px;
		text-align: center;
	}

	& > p {
		text-align: center;
	}

	.form-error {
		margin-top: 20px;
		font-size: 15px;

		.highlighted {
			display: block;
		}
	}

	.form-close-button {
		margin-top: ${({ theme }) => theme.grid.gutterInPx(2)};
	}
`;

const FieldsWrapper = styled.div`
	text-align: center;

	${media.tablet`
  	display: flex;
    text-align: left;
  `};

	.form-field {
		${media.tablet`
  		flex: 2 0 auto;
    `};

		input::placeholder {
			font-size: 16px;

			${media.phone`
        font-size: 18px;
      `};
		}
	}

	button {
		margin: 20px auto 0;
		width: 100%;

		${media.tablet`
  		margin: 0 0 0 20px;
      width: auto;
    `};
	}
`;

export interface ShoppingListEmailShareFormProps {
	error?: string;
	isLoading?: boolean;
	isShared?: boolean;
}

type Props = ShoppingListEmailShareFormProps & InjectedFormProps<EmailShareFormFields> & WrappedComponentProps;

class ShoppingListEmailShareForm extends React.Component<Props> {
	public render() {
		const { fields, intl, isValid, onSubmit, isLoading, error, isShared } = this.props;
		const { email } = fields;
		return (
			<StyledEmailShareForm onSubmit={onSubmit} noValidate>
				<MediumHeader className="form-header">
					<FormattedMessage id="user_shopping_list_share_email_title" />
				</MediumHeader>
				<BodyText>
					<FormattedMessage id="user_shopping_list_share_email_description" />
				</BodyText>
				<FieldsWrapper>
					<InputWithIcon
						icon={<SendToMailIcon />}
						type="email"
						name="email"
						value={email.value}
						validationError={email.touched && !email.focused && email.error ? email.error : ''}
						placeholder={intl.formatMessage({ id: 'forms_email_address' })}
						required={true}
						autoComplete="off"
						pattern={ValidationPatterns.EMAIL}
						onChange={this.onChange}
						onFocus={this.onFocus}
						onBlur={this.onBlur}
						inputSize={InputSize.medium}
					/>
					<Button type="submit" disabled={!isValid || isLoading}>
						<FormattedMessage id="forms_send" />
					</Button>
				</FieldsWrapper>
				{error && (
					<Alert
						type={AlertType.Error}
						highlightedText={intl.formatMessage({ id: 'user_shopping_list_share_error' })}
						className="form-error">
						{intl.formatMessage({ id: error })}
					</Alert>
				)}
				{isShared && (
					<Alert
						type={AlertType.Success}
						highlightedText={intl.formatMessage({ id: 'user_shopping_list_share_success' })}
						className="form-error"
					/>
				)}
			</StyledEmailShareForm>
		);
	}

	private onChange = (event: React.FormEvent<HTMLInputElement>) => {
		const { value, name } = event.currentTarget;
		this.props.onInputChange(value, name, true);
	};

	private onFocus = (event: React.FormEvent<HTMLInputElement>) => {
		const { value, name } = event.currentTarget;
		this.props.onInputFocus(value, name);
	};

	private onBlur = (event: React.FormEvent<HTMLInputElement>) => {
		const { value, name } = event.currentTarget;
		this.props.onInputBlur(value, name);
	};
}

export interface EmailShareFormFields {
	email: InputShape<string>;
}

export interface EmailShareFormValidators {
	email: Array<(value: string) => string>;
}

const emailShareFormFields: EmailShareFormFields = {
	email: { value: '' },
};

const emailShareFormValidators: EmailShareFormValidators = {
	email: [validateRequired('component_login_validation_email_required'), validateEmail],
};

export default injectForm(emailShareFormFields, emailShareFormValidators)(injectIntl(ShoppingListEmailShareForm));
