import extend from './extend';
import valio from './valio';

const valiocom = extend(valio, {
	styledVariations: {
		heroBannerDefaultSize: 'small',
	},
});

export default valiocom;
