import * as React from 'react';
import styled from 'styled-components';
import { withWindow, media } from '../../../helpers';
import { ArrowDownIcon } from '../../Icons';
import ValidationError from '../ValidationError';
import Select from '../Select';
import Label from '../Label';
import { ItemListContainer, ItemList, Item, Option } from '../ItemList/ItemList';
import DropdownButton from './DropdownButton';
import { Ref } from 'react';

export interface NameAndValue {
	name: string;
	value: string;
}

const DropdownField = styled.div`
	position: relative;
	margin-top: ${props => props.theme.grid.gutterInPx()};

	.item-list {
		margin-top: -1px;
	}

	/* Mobile uses native select */
	.dropdown-select-wrapper {
		height: auto;

		select {
			font-family: ${props => props.theme.fonts.secondary};
			font-size: 16px;
			line-height: 24px;
			font-weight: 600;
			text-transform: none;
			height: auto;
			padding: ${({ theme }) => theme.grid.gutterInPx(2)};
			padding-right: ${({ theme }) => theme.grid.gutterInPx(7)};
			overflow: hidden;
			margin: 0;
		}

		& > svg {
			width: 24px;
			height: 24px;
		}
	}
`;

const DesktopVisible = styled.div`
	display: none;
	${media.desktop`
		display: block;
	`};
`;

const DesktopHidden = styled.div`
	display: block;
	${media.desktop`
		display: none;
	`};
`;

interface Props {
	label?: string;
	name: string;
	id?: string;
	items: NameAndValue[];
	placeholder?: string;
	selectedValue?: string;
	className?: string;
	disabled?: boolean;
	validationError?: string;
	inlineLabel?: boolean;
	onChange: (value: string) => void;
	forwardedRef?: Ref<any>;
}

interface OpenState {
	isOpen: boolean;
}

interface State extends OpenState {
	selectedItem: NameAndValue;
}

class Dropdown extends React.Component<Props, State> {
	private wrapperRef: React.RefObject<HTMLDivElement>;

	constructor(props: Props) {
		super(props);

		this.state = {
			isOpen: false,
			selectedItem: this.getInitialSelectedItem(),
		};

		this.wrapperRef = React.createRef();
	}

	public componentDidMount() {
		withWindow(w => {
			w.addEventListener('click', this.onDocumentClick);
		});
	}

	public componentDidUpdate(prevProps: Props, prevState: State) {
		const { selectedItem } = this.state;
		if (prevState.selectedItem.value !== selectedItem.value) {
			this.onChange();
		}
	}

	public componentWillUnmount() {
		withWindow(w => {
			w.removeEventListener('click', this.onDocumentClick);
		});
	}

	public render() {
		const {
			name,
			forwardedRef,
			id,
			label,
			disabled,
			inlineLabel,
			className,
			placeholder,
			validationError,
		} = this.props;
		const { isOpen, selectedItem } = this.state;

		const inputId = id || name;

		return (
			<div className={`form-field${className ? ` ${className}` : ''}`}>
				<DropdownField ref={this.wrapperRef}>
					{label && (
						<Label htmlFor={inputId} inline={inlineLabel}>
							{label}
						</Label>
					)}
					<DesktopVisible>
						<>
							<DropdownButton
								name={name}
								id={inputId}
								type="button"
								value={selectedItem.value}
								isOpen={isOpen}
								disabled={disabled}
								onClick={this.toggleOpen}
								onKeyDown={this.onKeyDown}>
								{selectedItem.name}
								<ArrowDownIcon />
							</DropdownButton>
							{isOpen && this.renderItems()}
						</>
					</DesktopVisible>
					<DesktopHidden>
						<Select
							name={name}
							ref={forwardedRef}
							value={selectedItem ? selectedItem.value : undefined}
							className="dropdown-select-wrapper"
							onChange={this.onMobileChange}>
							{placeholder && <option>{placeholder}</option>}
							{this.renderOptions()}
						</Select>
					</DesktopHidden>
				</DropdownField>
				{validationError && <ValidationError htmlFor={inputId}>{validationError}</ValidationError>}
			</div>
		);
	}

	private getInitialSelectedItem(): NameAndValue {
		const { selectedValue, placeholder, items } = this.props;

		if (selectedValue) {
			const selectedItem = items.find(item => item.value === selectedValue);
			if (selectedItem) {
				return selectedItem;
			}
		}

		if (placeholder) {
			return {
				value: '',
				name: placeholder,
			};
		}

		return items[0];
	}

	private renderItems() {
		const { items } = this.props;
		const { selectedItem } = this.state;
		return (
			<ItemListContainer>
				<ItemList className="item-list">
					{items.map(({ name, value }) => (
						<Item key={`item-${value}`}>
							<Option value={value} isSelected={selectedItem.value === value} onClick={this.onSelectOption}>
								{name}
							</Option>
						</Item>
					))}
				</ItemList>
			</ItemListContainer>
		);
	}

	private renderOptions() {
		const { items } = this.props;
		return items.map(({ name, value }) => (
			<option key={`option-${value}`} value={value}>
				{name}
			</option>
		));
	}

	private toggleOpen = () => {
		this.setState({ isOpen: !this.state.isOpen });
	};

	private open() {
		this.setState({ isOpen: true });
	}

	private close() {
		this.setState({ isOpen: false });
	}

	private getSelectedIndex() {
		const { items } = this.props;
		const { selectedItem } = this.state;

		return items.findIndex(item => item.value === selectedItem.value);
	}

	private selectNext() {
		this.setSelectedItem(this.getSelectedIndex() + 1);
	}

	private selectPrevious() {
		this.setSelectedItem(this.getSelectedIndex() - 1);
	}

	private selectFirstItemStartingWith(letter: string) {
		const { items } = this.props;
		const indexOfFirstItemStartingWithLetter = items
			.map(item => item.name[0].toLowerCase())
			.indexOf(letter.toLowerCase());
		this.setSelectedItem(indexOfFirstItemStartingWithLetter);
	}

	private setSelectedItem(index: number) {
		const { items } = this.props;
		let selectedItem;

		if (index < 0) {
			selectedItem = items[0];
		} else if (index > items.length - 1) {
			selectedItem = items[items.length - 1];
		} else {
			selectedItem = items[index];
		}

		this.setState({ selectedItem });
	}

	private onSelectOption = (event: React.MouseEvent<HTMLButtonElement>) => {
		const { items } = this.props;
		const selectedItem = items.find(item => item.value === event.currentTarget.value);
		if (selectedItem) {
			this.setState({ selectedItem, isOpen: false });
		}
	};

	private onChange() {
		const { onChange } = this.props;
		const { selectedItem } = this.state;
		onChange(selectedItem.value);
	}

	private onMobileChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
		const { items } = this.props;
		const selectedItem = items.find(item => item.value === event.currentTarget.value);
		if (selectedItem) {
			this.setState({ selectedItem, isOpen: false });
		}
	};

	private onKeyDown = (event: React.KeyboardEvent<HTMLButtonElement>) => {
		const { isOpen } = this.state;

		switch (event.key) {
			case 'Down':
			case 'ArrowDown':
				event.preventDefault();
				if (!isOpen) {
					this.open();
					break;
				}
				this.selectNext();
				break;
			case 'Up':
			case 'ArrowUp':
				event.preventDefault();
				if (!isOpen) {
					this.open();
					break;
				}
				this.selectPrevious();
				break;
			case 'Enter':
				if (isOpen) {
					event.preventDefault();
					this.close();
				}
				break;
			case 'Tab':
			case 'Esc':
			case 'Escape':
				this.close();
				break;
			default:
				this.selectFirstItemStartingWith(event.key);
				break;
		}
	};

	private onDocumentClick = (event: MouseEvent) => {
		if (this.wrapperRef.current && event.target && this.wrapperRef.current.contains(event.target as Node)) {
			return;
		}

		this.close();
	};
}

export default Dropdown;
